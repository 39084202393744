//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {};
import smoothscroll from 'smoothscroll-polyfill';
import { gotoFullScreenIndex } from '@/components/grid/row/pureFullScreen/index.js';
import ClientOnly from 'vue-client-only';
import { debounce } from '@jz/utils';

export default {
    components: { ClientOnly },
    // 4个props属性
    /**
     *  isSystem  用户选择了系统的定义的按钮还是自定义按钮
     *  iconType  系统按钮的类型   有6种   0 - 5
     *  btnColor  系统按钮颜色  只有前两种系统按钮能自定义颜色
     *  userDefinedBtn    用户选择了自定义图片按钮，这个属性保存这个图片的Url
     */
    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState(['manageMode', 'device', 'LS']),
        title() {
            return this.LS.rbarBackTop;
        },
        state() {
            return this.$store.state;
        },
        backToTopData() {
            return this.$store.state.responsiveInfo.pattern.backToTop;
        },
        defaultUserImg() {
            return this.state.resImageRoot + '/image/rimage/jz/imgnot.jpg';
        },

        iconType() {
            return this.backToTopData.btnStyleSelect;
        },

        btnColor() {
            let backToTopData = this.backToTopData;
            return backToTopData.btnColorType === 0 ? this.state.responsiveInfo.themeColor : backToTopData.btnColor;
        },

        isSystem() {
            return this.backToTopData.btnStyle === 0;
        },

        userDefinedBtnUrl() {
            return this.backToTopData.btnUrl;
        },

        isDisplayBtn() {
            let siteVer = this.state.siteVer || 10;
            let isNotXFullScreen = this.state.fullScreen !== 2;
            // 目前标准版以上的版本号为 30  -  52    分销中级班以及以上是   130 - 160
            let allowed = (siteVer >= 30 && siteVer <= 52) || (siteVer >= 130 && siteVer <= 160);
            return !!(isNotXFullScreen && allowed && !this.backToTopData.hide && this.state.backToTop.scrollYValue);
        },
    },
    data() {
        return {};
    },
    mounted() {
        //初始化判断
        Vue.nextTick(() => {
            this.handleScroll();
        });
        //绑定滚动事件

        $(window).off('scroll.backTop').on('scroll.backTop', debounce(this.handleScroll, 50));
        $('#jzPreviewContent').off('scroll.backTop').on('scroll.backTop', debounce(this.handleScroll, 50)); // 页面初始化的时候调用一次
    },
    destroy() {
        $(window).off('scroll.backTop');
        $('#jzPreviewContent').off('scroll.backTop');
    },

    methods: {
        handleScroll() {
            let sTop = window.pageYOffset || $('#jzPreviewContent').scrollTop(); // 每次滚动页面更新sTop的值
            this.$store.commit('backToTop/setScrollYValue', sTop);
        },
        // 点击返回顶部按钮的时候，页面自动滚动到顶部
        jumpToTop() {
            if (this.isFullScreen) {
                gotoFullScreenIndex(0);
                return;
            }
            this.backToTop();

            $('.edit_tool_shortcut').hide();
        },
        backToTop() {
            let $jzPreviewContent = $('#jzPreviewContent');
            let $html = $('html'); //  电脑视图下滚动的是html
            let $body = this.manageMode ? $jzPreviewContent : $('body');
            // 如果当前已经是顶部了，就不需要再滚动了， 直接隐藏掉
            if ($html.scrollTop() === 0 && $body.scrollTop() === 0 && $jzPreviewContent.scrollTop() === 0) {
                return;
            }
            // kick off the polyfill!
            // fix ios 没有平滑滚动效果
            smoothscroll.polyfill();
            if (window.scrollTo) {
                //平滑滚动
                if (this.manageMode && this.device === 'mobi') {
                    $body[0].scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                } else {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
                return;
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
