var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        web_col_content_empty: _vm.moduleIds.length == 0 && _vm.manageMode,
        web_col_content: _vm.manageMode,
      },
    },
    [
      _vm._l(_vm.moduleIds, function (id) {
        return [
          _c("Project", {
            key: id,
            attrs: {
              id: id,
              "module-index": _vm.moduleIndex,
              rowid: _vm.rowId,
              projectid: id,
            },
          }),
        ]
      }),
      _vm._v(" "),
      _vm.manageMode && !_vm.moduleIds.length && !_vm.isMobi && _vm.designAuth
        ? _c("div", { staticClass: "web_col_content_empty_tips" }, [
            _c(
              "div",
              {
                staticClass: "web_col_content_empty_tips_btn",
                on: { click: _vm.addModule },
              },
              [
                _c("i", { staticClass: "web_col_content_empty_tips_icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "web_col_content_empty_tips_text" }, [
                  _vm._v("添加模块"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "web_col_content_empty_tips_label" }, [
              _vm._v("可拖动模块进来"),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }