//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SubmitFormCss',
    props: ['module'],
    data() {
        return {
            themeColor: window._store.state.responsiveInfo.themeColor,
        };
    },
    computed: {
        isPc() {
            return this.device == 'pc';
        },
        device() {
            return window._store.state.device;
        },
        isLinkToForm() {
            return this.module.isLinkToForm || false;
        },
        privatePattern() {
            return this.module.privatePattern || {};
        },
        pc() {
            return this.privatePattern.pc || {};
        },
        btnPattern() {
            return this.isLinkToForm ? this.module.other?.btnPattern : this.pc;
        },
        moduleId() {
            return this.module.id;
        },
        btnStyleId() {
            return this.btnPattern.bs;
        },
        isOldStyle() {
            return this.btnStyleId == -1;
        },
        isStyle5() {
            // 样式6
            return this.btnStyleId == 5;
        },
        isStyle2() {
            return this.btnStyleId == 2;
        },
        btnBorderRadius() {
            return this.btnPattern.br;
        },
        btnBackgroundType() {
            return this.btnPattern.bb;
        },
        btnBackgroundSetting() {
            return this.btnPattern.bbs || {};
        },
        btnFontSetting() {
            return this.btnPattern.bf || {};
        },
        classPrefix() {
            return this.isLinkToForm ? `.onlineForm${this.moduleId}` : `#module${this.moduleId}`;
        },
    },
    updated() {
        this.$nextTick(() => {
            this.$emit('update', this.$el.innerText);
        });
    },
    methods: {
        backgroundColorCss(backgroundColor, hover = false) {
            if (backgroundColor.indexOf('linear-gradient') > -1) {
                return `background-image: ${backgroundColor}; color: transparent;`;
            } else {
                if (hover && this.isStyle2) {
                    return `background-color: ${window.Comm.burnColor(backgroundColor, 5)}`;
                } else {
                    return `background-color: ${backgroundColor}`;
                }
            }
        },
    },
};
