var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mobi_tabpack_list_arrow_wrap mobi_tabpack_list_arrow_wrap_right",
      on: { click: _vm.arrowRightHandler },
    },
    [
      _c(
        "svg",
        {
          staticClass: "mobi_tabpack_list_arrow_right",
          attrs: { viewBox: "0 0 16 16" },
        },
        [
          _c("path", {
            attrs: {
              d: "M10.85,8.15l-4-4a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7L9.79,8.5,6.15,12.15a.5.5,0,1,0,.7.7l4-4a.48.48,0,0,0,0-.7Z",
              fill: "currentColor",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }