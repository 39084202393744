var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { css: false },
      on: { enter: _vm.animEnter, leave: _vm.animLeave },
    },
    [
      _vm.value
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "dom-portal",
                  rawName: "v-dom-portal",
                  value: _vm.getDomEl,
                  expression: "getDomEl",
                },
              ],
              staticClass: "p_popup_layer",
              attrs: { ontouchmove: "return false" },
            },
            [
              _c("div", {
                ref: "mask",
                staticClass: "popup_mask",
                on: { click: _vm.onClose },
              }),
              _vm._v(" "),
              _c(
                "div",
                { ref: "content", staticClass: "popup_content" },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }