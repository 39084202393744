import request from '@jz/request';

export function updateNewsGroup(params) {
    return request.get('/rajax/news_h.jsp?cmd=getWafNotCk_updateGroup', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function submitNewsComment(data) {
    return request.post('/ajax/newsComment_h.jsp?cmd=addWafCk_submitComment', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getNewsListByPagination(data) {
    return request.post('/rajax/news_h.jsp?cmd=getWafNotCk_getList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function delNews(data = {}) {
    return request.post('/ajax/news_h.jsp?cmd=delWafCk_del', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setNewsListToModule(data = {}) {
    return request.post('/rajax/news_h.jsp?cmd=setWafCk_setNewsList', { data }).then(({ data = {} }) => {
        return data;
    });
}
