//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { isNewModuleAfter20210707, getStyleDataByDevice } = Comm;
import { logDog } from '@/site/shared/log/index.js';
import { genCommFontStyle, genCommFontStyleForText } from './utils.js';
import { editOnlineForm } from '@shared/manage/onlineForm/index.js';
const manageUse = {};
export default {
    props: ['item', 'form', 'module'],
    data() {
        return {
            widthForTip: 0,
            heightForTip: 0,
            resizeForTip: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        ...Vuex.mapState('manage', ['editPanel']),
        isLinkToForm() {
            return !!this.module.isLinkToForm;
        },
        itemStyle() {
            const styleObj = {};

            if (this.isLinkToForm) {
                if (!this.isMobi && this.item.id === 'submit') {
                    styleObj['textAlign'] = this.module.other.btnPattern.bt ? 'center' : '';
                }
                return styleObj;
            }

            if (!this.manageMode || this.isLinkToForm) {
                return;
            }

            if (!this.isMobi && this.style.sl) {
                //单行
                styleObj['display'] = 'block';
            }

            if (!this.isMobi && this.style.w) {
                //宽度
                styleObj['width'] = `${this.style.w * 100}%`;
            }

            if (!this.isMobi && this.item.id === 'submit') {
                styleObj['textAlign'] = this.module.privatePattern.pc.bt ? 'center' : '';
            }

            return styleObj;
        },
        itemClasses() {
            const classes = {};

            classes[`formitem_${this.item.id}`] = true;

            if (this.manageMode) {
                classes['formitem_active'] = this.resizeForTip || this.isActiveEditorItem;
                classes['formitem_active_chosen'] = this.isActiveEditorItem;
            }

            return classes;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        style() {
            const fis = this.module.privatePattern.pc.fis[this.form.id] || {};
            return fis[this.item.id] || {};
        },
        isActiveEditorPanel() {
            if (this.module.isLinkToForm) {
                return false;
            }
            return (
                this.manageMode &&
                this.editPanel.active &&
                this.editPanel.type === 'onlineForm' &&
                this.editPanel.data.moduleId === this.module.id
            );
        },
        isActiveEditorItem() {
            if (this.module.isLinkToForm) {
                return false;
            }
            return (
                this.isActiveEditorPanel &&
                this.editPanel.data.itemId === this.item.id &&
                this.editPanel.data.state === 'edit'
            );
        },
        pattern() {
            return this.module.privatePattern.pc;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        titleStyle() {
            if (this.isLinkToForm) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.itt,
                mobiData: this.module.privatePattern.mobi.itt,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            return genCommFontStyle(font);
        },
        titleTextStyle() {
            if (this.isLinkToForm) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.itt,
                mobiData: this.module.privatePattern.mobi.itt,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            return genCommFontStyleForText(font);
        },
    },
    watch: {
        resizeForTip(val) {
            if (val) {
                $(this.$el).parents('.form_container').addClass('form_editing');
            } else {
                $(this.$el).parents('.form_container').removeClass('form_editing');
            }
        },
    },
    methods: {
        setWidth(val) {
            const fis = this.module.privatePattern.pc.fis;
            if (!fis[this.form.id]) {
                Vue.set(fis, this.form.id, {});
            }
            const formStyle = fis[this.form.id];
            if (!formStyle[this.item.id]) {
                Vue.set(formStyle, this.item.id, {});
            }
            const itemStyle = formStyle[this.item.id];
            Vue.set(itemStyle, 'w', val);
        },
        resetInit() {
            this.startX = 0;
            this.moveX = 0;
            this.orgWidth = 0;
            this.parWidth = $(this.$el).parent().width();
        },
        resizeHandlerMouseDown(e) {
            if (this.isLinkToForm) {
                return false;
            }
            this.resetInit();
            this.startX = e.pageX;
            this.resizeForTip = true;

            this.orgWidth = this.style.w || 1;

            this.updateResizeTip();
            document.addEventListener('mousemove', this.mousemove);
            document.addEventListener('mouseup', this.mouseup);
        },
        mousemove(e) {
            if (this.isLinkToForm) {
                return false;
            }
            this.moveX = this.startX - e.pageX;
            let realyWidth = this.orgWidth - Math.floor(this.moveX) / this.parWidth;

            if (realyWidth < 0) {
                realyWidth = 0;
            }
            if (realyWidth > 1) {
                realyWidth = 1;
            }
            // 计算辅助线 需要对比的 元素节点
            var compareForms = $(this.$el).parent().find('.form_item_wrap').not($(this.$el)[0]);
            // 辅助线垂直对齐
            JZ.moduleAlignLine.resize.init($(this.$el), ['right', 'left'], compareForms);
            this.setWidth(realyWidth);

            this.updateResizeTip();
            this.$refs.tooltipResizeHandler.updatePopper();
        },
        updateResizeTip() {
            if (this.isLinkToForm) {
                return false;
            }
            this.widthForTip = Math.round(parseFloat(window.getComputedStyle(this.$refs.formItem).width)) + 'px';
            this.heightForTip = Math.round(parseFloat(window.getComputedStyle(this.$refs.formItem).height)) + 'px';
        },
        mouseup() {
            if (this.isLinkToForm) {
                return false;
            }

            JZ.moduleAlignLine.commFunc.hideAlignLine();

            this.resizeForTip = false;
            document.removeEventListener('mousemove', this.mousemove);
            document.removeEventListener('mouseup', this.mouseup);

            if (this.manageMode) {
                if (this.isActiveEditorPanel) {
                    logDog(200742, 15);
                } else {
                    logDog(200742, 10);
                }
            }
        },
        chosenFormItem() {
            if (this.manageMode) {
                if (this.isActiveEditorPanel) {
                    logDog(200742, 13);
                } else {
                    logDog(200742, 8);
                }
            }
            if (!this.manageMode || !this.isActiveEditorPanel || this.isLinkToForm) {
                return;
            }
            if (this.item.hideToolBar) {
                return;
            }
            editOnlineForm(this.module.id, this.item.id);
        },
        dblClickHandler(e) {
            if (this.manageMode) {
                if (this.isActiveEditorPanel) {
                    logDog(200742, 14);
                } else {
                    logDog(200742, 9);
                }
            }
            if (!this.manageMode) {
                return;
            }
            if (this.item.hideToolBar) {
                return;
            }
            e.stopPropagation();
            $(document).mousedown();
            editOnlineForm(this.module.id, this.item.id);
        },
        contextmenuHandler() {
            if (this.manageMode) {
                if (this.isActiveEditorPanel) {
                    logDog(200742, 17);
                } else {
                    logDog(200742, 12);
                }
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
