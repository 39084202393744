//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['module', 'form', 'value', 'itemInputStyle'],
    data() {
        return {
            random: Math.random() * 1000,
        };
    },
    computed: {
        validateCodeSrc() {
            if (!this.form) {
                return `/validateCode.jsp?${this.random}&vCodeId=${this.module.id}&validateCodeRegType=3`;
            } else {
                return `/validateCode.jsp?${this.random}&vCodeId=${this.module.id}.${this.form.id}&validateCodeRegType=3`;
            }
        },
    },
    methods: {
        refreshValidateCode() {
            this.random = Math.random() * 1000;
        },
    },
};
