var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flvModuleComponent", {
    tag: "component",
    staticClass: "module_content_detail",
    attrs: { id: _vm.id, module: _vm.module },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }