export const outsideclick = {
    bind(el, binding) {
        function documentHandler(e) {
            if (el.contains(e.target)) {
                return false;
            }
            if (binding.expression) {
                binding.value(e);
            }
        }
        var isBubble = binding.modifiers.bubble;
        var useCapture = !isBubble;
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler, useCapture);
    },
    unbind(el) {
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};
