//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {
    mixins: [],
};
import MultiLanguage from './menus/index.vue';

const MARGIN_LEFT_PERCENT_DEFAULT = 0.5;
const MARGIN_RIGHT_PERCENT_DEFAULT = 0.5;

import { fillLanguageData } from './utils.js';

export default {
    components: {
        MultiLanguage,
    },
    mixins: [...manageUse.mixins],
    props: {
        rowid: Number,
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'sections']),
        ...Vuex.mapGetters(['info']),
        pattern() {
            return this.info.pattern.multiLanguage;
        },
        currentLan() {
            return this.sections.multiLanguage.currentLan;
        },
        list() {
            return this.sections.multiLanguage.openLanInfoList;
        },
        mobiFontSetting() {
            return this.pattern.mf || {};
        },
        fontSetting() {
            return this.isMobi && this.mobiFontSetting.y == 1 ? this.pattern.mf : this.pattern.f;
        },
        margin() {
            return this.isMobi ? this.pattern.mm : this.pattern;
        },
        isMobi() {
            return this.device == 'mobi';
        },

        closeMargin() {
            if (this.isMobi) {
                if (!this.pattern.h && this.isMobiFirstInit()) {
                    return true;
                }
            } else {
                if (!this.pattern.h && this.isFirstInit()) {
                    return true;
                }
            }
            return false;
        },
        renderType() {
            return this.pattern.rt;
        },
        renderStyle() {
            return this.pattern.rs;
        },
        renderArea() {
            return this.pattern.ra;
        },
        customRenderText() {
            return this.pattern.rte;
        },
        renderTextList() {
            if (this.customRenderText === 1) {
                return fillLanguageData(this.pattern.rtl, this.list);
            } else {
                return this.list;
            }
        },
        activeLanguage() {
            if (this.customRenderText === 1) {
                return this.renderTextList.find((item) => item.lcid === this.$store.state.lcid);
            } else {
                return this.currentLan;
            }
        },
        wrapClasses() {
            return {
                J_onlyInFooter: this.renderArea == 1,
                J_onlyInHeader: this.renderArea == 0,
                expansion_style: this.renderStyle === 1,
            };
        },
    },
    methods: {
        isFirstInit() {
            return (
                MARGIN_LEFT_PERCENT_DEFAULT + MARGIN_RIGHT_PERCENT_DEFAULT == 1 &&
                this.pattern.ml == MARGIN_LEFT_PERCENT_DEFAULT &&
                this.pattern.mr == MARGIN_RIGHT_PERCENT_DEFAULT
            );
        },
        isMobiFirstInit() {
            var mMargin = this.margin;
            return (
                MARGIN_LEFT_PERCENT_DEFAULT + MARGIN_RIGHT_PERCENT_DEFAULT == 1 &&
                mMargin.ml == MARGIN_LEFT_PERCENT_DEFAULT &&
                mMargin.mr == MARGIN_RIGHT_PERCENT_DEFAULT
            );
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
