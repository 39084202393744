//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message } from '@shared/manage/componMessage/index.js';
import { getAjaxUrl } from '@/site/shared/index.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
import { decodeHtml } from '@/components/utils.js';
import { mapState } from 'vuex';
export default {
    name: 'ModuleNewsComment',
    props: ['cmd', 'newsId', 'moduleId'],
    data() {
        return {
            commentsInfo: {},
            disableStyle: {
                cursor: 'auto',
                color: '#ccc',
            },
            allowStyle: {
                cursor: 'pointer',
            },
            paginationList: [],
            jumpPage: 1,
            isLoading: false,
        };
    },
    computed: {
        ...mapState(['realNameAuth', 'manageMode', 'isMaterialLazyLoad']),
        ls() {
            return this.$store.state.LS;
        },
    },
    mounted() {
        this.getNewsComments();
        eventBus.$on('getCommentsByPage', (page, cb) => {
            this.getNewsCommentsAjax(page, cb);
        });
    },
    beforeDestroy() {
        eventBus.$off('getCommentsByPage');
    },
    methods: {
        addComment(event) {
            this.$emit('addComment', event);
        },
        getNewsComments(page = 1) {
            // 如果是处于当前页 或者输入的页数超出总页数  或者小于一    就不需要发送请求了
            if (
                this.commentsInfo.curPage === page ||
                this.commentsInfo.pageSum < page ||
                page < 1 ||
                typeof page != 'number'
            ) {
                this.jumpPage = 1;
                return;
            }
            this.getNewsCommentsAjax(page);
        },
        getNewsCommentsAjax(page = 1, callback) {
            this.isLoading = true;
            $.ajax({
                url: Site.addRequestPrefix({
                    newPath: '/rajax/news_h.jsp',
                    oldPath: getAjaxUrl('rajax/news_h.jsp'),
                }),
                methods: 'get',
                data: {
                    cmd: this.cmd,
                    newsId: this.newsId,
                    moduleId: this.moduleId,
                    m2pageno_2: page,
                },
            }).then(
                (comments) => {
                    this.isLoading = false;
                    try {
                        // 每次请求后都清空分页的页码
                        this.paginationList = [];
                        comments = JSON.parse(comments);
                        var curPage = comments.data.curPage,
                            pageSum = comments.data.pageSum;
                        var viewStart = curPage - 2 <= 0 ? 1 : curPage - 2;
                        var viewEnd = curPage + 2 >= pageSum ? pageSum : curPage + 2;

                        //判断是否应该显示首页
                        if (Math.abs(curPage - 1) > 2 && 1 != viewStart) {
                            this.paginationList.push(1);
                        }

                        //判断是否应该显示首页的多页提示 '...'
                        if (viewStart > 2) {
                            this.paginationList.push('...');
                        }

                        //循环显示翻页数字
                        for (var j = viewStart; j <= viewEnd; j++) {
                            this.paginationList.push(j);
                        }

                        //判断是否应该显示末页的多页提示 '...'
                        if (viewEnd < pageSum - 1) {
                            this.paginationList.push('...');
                        }

                        //判断是否应该显示末页
                        if (Math.abs(curPage - pageSum) > 2 && pageSum != viewEnd) {
                            this.paginationList.push(pageSum);
                        }

                        callback && callback(); // 用于对外的回调函数
                    } catch (error) {
                        comments = {};
                    }
                    this.commentsInfo = comments.data || {};
                },
                () => {
                    this.isLoading = false;
                    Message.warning({
                        message: '网络繁忙，请刷新后重试',
                        autoHide: false,
                    });
                }
            );
        },
        handlePageChange(type) {
            let page = this.commentsInfo.curPage || 1;
            // page表示当前页, type表示点击了上一页还是下一页
            if (type === 'next') {
                if (this.commentsInfo.pageSum > page) {
                    this.getNewsComments(page + 1);
                }
            } else if (type === 'prev') {
                if (page > 1) {
                    this.getNewsComments(page - 1);
                }
            }
        },
        decodeHtml,
        getLevelStyle(item) {
            //自定义图标
            if (item.levelIconCus) {
                return item.levelPath;
            } else {
                return { color: item.levelColor };
            }
        },
        getLevelClass(item) {
            if (item.levelIconCus) {
                return 'news_reader_level_cus';
            } else {
                return item.levelClass;
            }
        },
    },
};
