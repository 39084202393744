//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getTextAlignStyle, getStyleDataByDevice, extend, getWrapStyle, getComputedTextColor } = Comm;
const encodeHtmlStr = (html) => {
    return html && html.replace
        ? html
              .replace(/&/g, '&amp;')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/\\/g, '&#92;')
              .replace(/'/g, '&#39;')
              .replace(/"/g, '&quot;')
              .replace(/\n/g, '<br/>')
              .replace(/\r/g, '')
        : html;
};
export default {
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        text: {
            type: String,
            default: '',
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            htmlText: '',
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'device']),
        show() {
            var isShow = this.text !== '';
            return isShow && !this.hide;
        },
        descClass() {
            const _class = {
                jz_photo_desc_layer_amimate: this.showPhotoAnimateLayer,
            };
            if (this.descStyle === 1) {
                _class.photo_desc_style2 = true;
            } else {
                _class.photo_desc = true;
            }
            return _class;
        },
        style() {
            const descAlign = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.da,
                mobiData: this.module.privatePattern.mobi.da,
                isMobi: this.device === 'mobi',
            });
            const _style = {
                opacity: this.show ? 1 : 0,
                ...(this.descStyle === 0 ? {} : getTextAlignStyle(descAlign)),
            };
            if (this.showPhotoAnimateLayer) {
                _style['width'] = '100%';
            }
            return _style;
        },
        showPhotoAnimateLayer() {
            var photoAnimate = this.module.commProp.pa,
                animateType = photoAnimate.t;
            return this.device == 'pc' && animateType == 5;
        },
        descStyle() {
            return this.device === 'mobi' ? this.module.privatePattern.mobi.pds : this.module.privatePattern.pc.pds;
        },
        textStyle() {
            if (this.descStyle === 0) {
                return {};
            }
            const style = {};
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.pf,
                mobiData: this.module.privatePattern.mobi.pf,
                isMobi: this.device === 'mobi',
                isTextNotExtendFontSize: true,
            });

            let fontStyle = {};
            let textColorStyle = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);

                if (this.isHoverContent) {
                    textColorStyle = getComputedTextColor(font.hc || '#000');
                }
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.pl,
                mobiData: this.module.privatePattern.mobi.pl,
                isMobi: this.device === 'mobi',
            });
            extend(style, getWrapStyle(wrapData));

            style['white-space'] = 'pre-wrap';

            return style;
        },
    },

    created() {
        this.$watch(
            () => this.text,
            () => {
                this.htmlText = encodeHtmlStr(this.text);
            },
            {
                immediate: true,
            }
        );
    },
};
