//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { encodeHtmlStr } from '@/components/utils.js';
import { manageComponent } from './mixin';
import { mapGetters } from 'vuex';

export default {
    name: 'TimelineItemTime',
    mixins: [manageComponent],
    inject: ['module'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['isMobi']),
        pattern() {
            return this.module.privatePattern;
        },
        htmlValue() {
            return encodeHtmlStr(this.value).replace(/\n/g, '<br>');
        },
    },
};
