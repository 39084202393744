//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIsOpenRemoveUrlArg } from '@/site/shared/cookie/index.js';
import { Comm } from '../../utils';
const { getComputedBg, createUrlArgsOnclickStr } = Comm;
const manageUse = {};
export default {
    name: 'Footnav',
    data() {
        return {
            subNavList: [],
            showSubs: false,
            subIndex: -1,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'colId']),
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        footNav() {
            return this.info.pattern.footNav;
        },
        navList() {
            return this.footNav.navs.filter((nav, index) => {
                return index < 5 && nav.show;
            });
        },
        widthByLength() {
            return 's_footnav__item-' + this.navList.length;
        },
        bigIconData() {
            if (!this.navList.length) {
                return {};
            }
            if (this.navList.length < 3) {
                return this.navList[this.navList.length - 1];
            }
            if (this.navList.length == 3) {
                return this.navList[1];
            }
            return this.navList[2];
        },
        bigIconDataIndex() {
            if (!this.navList.length) {
                return 0;
            }
            if (this.navList.length < 3) {
                return this.navList.length - 1;
            }
            if (this.navList.length == 3) {
                return 1;
            }
            return 2;
        },
        bigIconHtml() {
            return this.bigIconData.isPic || typeof window == 'undefined' ? '' : '&#x' + this.bigIconData.fontPic;
        },
        styleType() {
            return this.footNav.s;
        },
        styleClass() {
            return 's_footnav__style-' + this.styleType;
        },
        contentTypeClass() {
            return this.styleType < 2 ? 's_footnav__contentType-' + this.footNav.conts : '';
        },
        centerListClass() {
            return this.navList.length < 2 ? 's_footnav__list-center' : '';
        },
        bigIconStyle() {
            if (this.footNav.c != 'default') {
                return {
                    'background-color': this.footNav.c,
                };
            }
            return {};
        },
        bgStyle() {
            return getComputedBg(this.footNav.bg);
        },
        wrapBgStyle() {
            if (this.styleType == 1) {
                return getComputedBg(this.footNav.bg);
            }
            return {};
        },
        bdStyle() {
            let bd = this.footNav.bd;
            if (!bd.y || this.styleType == 3 || this.styleType == 1) {
                return {};
            }
            let lineStyle = null;
            if (bd.s == 0) {
                lineStyle = 'solid';
            } else if (bd.s == 1) {
                lineStyle = 'dotted';
            } else {
                lineStyle = 'dashed';
            }
            return {
                'border-style': lineStyle,
                'border-top-width': bd.w + 'px',
                'border-color': bd.c,
            };
        },
        whiteCircleBd() {
            let bd = this.footNav.bd;
            if (!bd.y || this.styleType == 3) {
                return {};
            }
            let lineStyle = null;
            if (bd.s == 0) {
                lineStyle = 'solid';
            } else if (bd.s == 1) {
                lineStyle = 'dotted';
            } else {
                lineStyle = 'dashed';
            }
            return {
                border: `${bd.w}px ${lineStyle} ${bd.c}`,
            };
        },
        leftStyle() {
            if (this.styleType == 1 && this.footNav.spt.y && this.footNav.spt.w) {
                return {
                    transform: `translateX(calc(-50% + ${this.footNav.spt.w / 2}px))`,
                };
            }
            return {};
        },
    },
    watch: {
        footNav: {
            deep: true,
            handler() {
                this.showSubs = false;
            },
        },
    },
    mounted() {
        if (!this.manageMode) {
            Site.addQrCode('.s_footnav__wrap');
        }
    },
    methods: {
        getBgStyle(nav) {
            if (nav.isPic) {
                return {
                    'background-image': `url(${nav.picPath})`,
                };
            }
            return {};
        },
        getFontPic(nav) {
            return nav.isPic || typeof window == 'undefined' ? '' : '&#x' + nav.fontPic;
        },
        specialNavWidth(index) {
            let len = this.navList.length;
            if (this.styleType == 1) {
                if (len == 2) {
                    return {
                        width: '33.33333%',
                        'max-width': '33.33333%',
                    };
                } else if (len == 4) {
                    if (index <= 2) {
                        return {
                            width: '20%',
                            'max-width': '20%',
                        };
                    }
                } else {
                    return {};
                }
            }
        },
        needHideIcon(index) {
            let len = this.navList.length;
            if (this.styleType == 1) {
                if (len < 3 && index == len - 1) {
                    return 's_footnav__pic-opacity';
                }
                if (len == 3 && index == 1) {
                    return 's_footnav__pic-opacity';
                }
                if (len > 3 && index == 2) {
                    return 's_footnav__pic-opacity';
                }
            }
            return '';
        },
        showContent(index) {
            let len = this.navList.length;
            if (this.styleType == 1 && this.footNav.conts) {
                if (len < 3 && index == len - 1) {
                    return false;
                }
                if (len == 3 && index == 1) {
                    return false;
                }
                if (len > 3 && index == 2) {
                    return false;
                }
            }

            return true;
        },
        hideFirstName(index) {
            if (this.styleType == 2 && !index) {
                return false;
            }
            return true;
        },
        hideNotFirstIcon(index) {
            if (this.styleType == 2 && index) {
                return false;
            }
            return true;
        },
        genSubNav(e, nav, index) {
            if (nav.children && nav.children.length) {
                e.preventDefault();
                if (this.showSubs && index == this.subIndex) {
                    this.showSubs = false;
                    return;
                }
                const SUB_LIST_WIDTH = 108; // 90是二级导航的宽度
                let target = e.currentTarget; //点击的导航
                if ($(target).hasClass('s_footnav__bigicon')) {
                    target = $('.s_footnav__item').get(index);
                }
                let tgOffset = target.getBoundingClientRect(); //目标导航
                let wrapOffset = $(this.$refs.wrap)[0].getBoundingClientRect(); //导航容器
                let $subList = $(this.$refs.subList); //二级导航
                let $triangle = $(this.$refs.triangle); //箭头
                $triangle.css('left', ''); //还原
                let resultLeft = tgOffset.left - wrapOffset.left + (tgOffset.width - SUB_LIST_WIDTH) / 2;
                let resultTop = '';
                // 兼容二级栏目超出视图的兼容处理,兼容left
                if (this.styleType != 3 && SUB_LIST_WIDTH > tgOffset.width) {
                    if (resultLeft < 0) {
                        resultLeft = 0;
                        $triangle.css('left', tgOffset.width / 2 + 'px');
                    } else if (this.styleType != 3 && resultLeft + SUB_LIST_WIDTH > wrapOffset.width) {
                        resultLeft = wrapOffset.width - SUB_LIST_WIDTH;
                        $triangle.css('left', tgOffset.width / 2 + SUB_LIST_WIDTH - tgOffset.width + 'px');
                    }
                }

                // 兼容样式一，中间位置的二级导航,兼容top
                if (this.styleType == 1 && index == this.bigIconDataIndex) {
                    if (!this.footNav.conts) {
                        resultTop = -22 + 'px';
                    } else {
                        resultTop = -12 + 'px';
                    }
                }
                // 兼容分割线影响定位
                if (index != 0 && this.footNav.spt.y) {
                    resultLeft += this.footNav.spt.w / 2;
                }
                $subList.css({
                    left: resultLeft + 'px',
                    top: resultTop,
                });
                this.subNavList = nav.children;
                this.showSubs = true;
                this.subIndex = index;

                this.$nextTick(() => {
                    Site.addQrCode();
                });
            }
        },
        // 主题
        themeClass(nav) {
            if (this.styleType == 3) {
                return 'jz_theme_font_color';
            }
            if (nav.link.type == 100 && nav.link.id == this.colId) {
                return 'jz_theme_font_color';
            }
        },
        // 导航主题
        activeNavTheme(nav) {
            if (
                ((nav.link.type == 100 && nav.link.id == this.colId) || this.styleType == 3) &&
                this.footNav.c != 'default'
            ) {
                return {
                    color: this.footNav.c,
                };
            }
            return {};
        },
        splitStyle(index) {
            let spt = this.footNav.spt;
            if (!spt.y || !index) {
                return {};
            }
            let lineStyle = null;
            if (spt.s == 0) {
                lineStyle = 'solid';
            } else if (spt.s == 1) {
                lineStyle = 'dotted';
            } else {
                lineStyle = 'dashed';
            }
            return {
                'border-left': `${spt.w}px ${lineStyle} ${spt.c}`,
            };
        },
        textStyle() {
            let textOption = this.footNav.its;
            let fontStyle = {
                'font-family': `${textOption.f}`,
                'font-size': `${textOption.s}px`,
                'font-weight': `${textOption.w ? 'bold' : 'normal'}`,
                'text-decoration': `${textOption.d ? 'underline' : 'none'}`,
                'font-style': `${textOption.i ? 'italic' : 'normal'}`,
                color: `${textOption.c}`,
            };

            return textOption.y ? fontStyle : {};
        },
        iconStyle() {
            let icon = this.footNav.icon;
            let iconColor = {
                color: icon.cs.c,
            };
            return icon.y ? iconColor : {};
        },
        activeTextStyle(nav) {
            let textOption = this.footNav.ats;
            let fontStyle = {
                'font-family': `${textOption.f}`,
                'font-size': `${textOption.s}px`,
                'font-weight': `${textOption.w ? 'bold' : 'normal'}`,
                'text-decoration': `${textOption.d ? 'underline' : 'none'}`,
                'font-style': `${textOption.i ? 'italic' : 'normal'}`,
                color: `${textOption.c}`,
            };
            return textOption.y && nav.link.type == 100 && nav.link.id == this.colId ? fontStyle : {};
        },
        activeIconStyle(nav) {
            let icon = this.footNav.aicon;
            let iconColor = {
                color: icon.cs.c,
            };
            return icon.y && nav.link.type == 100 && nav.link.id == this.colId ? iconColor : {};
        },
        showChildIcon(nav, index) {
            if (this.styleType == 3) {
                return false;
            }
            if (nav.children && nav.children.length) {
                if (this.styleType == 2) {
                    return index != 0;
                }
                return this.footNav.conts == 1;
            }
            return false;
        },
        getHref(nav) {
            if ((nav.children && nav.children.length) || !nav.link.jumpUrl) {
                return 'javascript:;';
            }
            return nav.link.jumpUrl;
        },
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            const _openRemoveUrlArgs = this.$isServer ? this.info._openRemoveUrlArgs : getIsOpenRemoveUrlArg();
            return createUrlArgsOnclickStr(reqArgs, _openRemoveUrlArgs, this.hashRemoved);
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
