var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      on: {
        mouseover: _vm.photoMouseover,
        mouseleave: _vm.photoMouseleave,
        touchstart: _vm.photoMouseover,
        touchend: _vm.photoMouseleave,
      },
    },
    [
      _c("div", { staticClass: "photo_display_wrap", style: _vm.photoWidth }, [
        _vm.displayList.length
          ? _c(
              "div",
              { staticClass: "photo_display_area", style: _vm.photoHeight },
              [
                _c(
                  "ul",
                  {
                    ref: "photoList",
                    staticClass: "photo_display_list",
                    style: _vm.photoListStyle,
                  },
                  [
                    _vm._l(_vm.displayList, function (photoItem, index) {
                      return [
                        _c(
                          "li",
                          {
                            key: photoItem.pid + index,
                            class: {
                              activedPic:
                                _vm.moduleStyle !== 3 &&
                                (_vm.currentPicIndex === index ||
                                  (_vm.prePicIndex === index &&
                                    _vm.outActivePicFlag)),
                              jz_page_moveLeftIn:
                                _vm.moduleStyle !== 3 &&
                                _vm.currentPicIndex === index &&
                                _vm.inAnimateFlag &&
                                _vm.animateDir == "left",
                              jz_page_moveLeftOut:
                                _vm.moduleStyle !== 3 &&
                                _vm.prePicIndex === index &&
                                _vm.outAnimateFlag &&
                                _vm.animateDir == "left",
                              jz_page_moveRightIn:
                                _vm.moduleStyle !== 3 &&
                                _vm.currentPicIndex === index &&
                                _vm.inAnimateFlag &&
                                _vm.animateDir == "right",
                              jz_page_moveRightOut:
                                _vm.moduleStyle !== 3 &&
                                _vm.prePicIndex === index &&
                                _vm.outAnimateFlag &&
                                _vm.animateDir == "right",
                            },
                            style: _vm.photoListItemStyle,
                            attrs: { "data-id": photoItem.pid },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "hovermenu",
                                    rawName: "v-hovermenu",
                                    value: {
                                      hovermenu: _vm.hovermenu,
                                      args: {
                                        moduleId: _vm.module.id,
                                        photoId: photoItem.pid,
                                        delHidden: _vm.usePhotoGroup,
                                      },
                                    },
                                    expression:
                                      "{\n                                hovermenu,\n                                args: {\n                                    moduleId: module.id,\n                                    photoId: photoItem.pid,\n                                    delHidden: usePhotoGroup,\n                                },\n                            }",
                                  },
                                ],
                                staticClass: "photoItemWrap",
                                attrs: { "has-desc": !!photoItem.desc },
                                on: { click: _vm.photoSlide },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.noLink
                                        ? "javascript: void(0);"
                                        : photoItem.link.url,
                                      target: _vm.noLink
                                        ? "_self"
                                        : _vm.linkTarget(photoItem.link.ib),
                                      rel: _vm.linkNofollow(photoItem.link.nf),
                                      onclick: _vm.jumpOnclickStr(photoItem),
                                    },
                                  },
                                  [
                                    _c("ImageComponent", {
                                      directives: [
                                        {
                                          name: "lazyload2",
                                          rawName: "v-lazyload2",
                                          value: {
                                            id: _vm.module.id,
                                            src: photoItem.psrc,
                                            carousel: true,
                                            naturalWidth: photoItem.w,
                                            naturalHeight: photoItem.h,
                                          },
                                          expression:
                                            "{\n                                        id: module.id,\n                                        src: photoItem.psrc,\n                                        carousel: true,\n                                        naturalWidth: photoItem.w,\n                                        naturalHeight: photoItem.h,\n                                    }",
                                        },
                                      ],
                                      staticClass: "photo_item",
                                      style: _vm.photoStyle(photoItem),
                                      attrs: {
                                        src: photoItem.psrc,
                                        webp: _vm.webpOptOpen,
                                        alt: photoItem.desc,
                                        "img-effects": _vm.isMobi
                                          ? { type: 0 }
                                          : _vm.isNotSwitchImgEffect
                                          ? _vm.imgOptsComputed
                                          : _vm.getImgComponetOption(
                                              _vm.module
                                            ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("photo-description", {
                                      attrs: {
                                        module: _vm.module,
                                        text: photoItem.desc,
                                        hide: !_vm.model.cmdd,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.moduleStyle === 1
                  ? [
                      _c("a", {
                        staticClass: "arrow prev",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.prev.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("a", {
                        staticClass: "arrow next",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.next.apply(null, arguments)
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleStyle === 3
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "photo_mask prev",
                          on: { click: _vm.prev },
                        },
                        [_c("a", { staticClass: "arrow prev" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "photo_mask next",
                          on: { click: _vm.next },
                        },
                        [_c("a", { staticClass: "arrow next" })]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.moduleStyle === 2 && _vm.photoList.length
        ? _c(
            "ul",
            { staticClass: "dot_list" },
            _vm._l(_vm.photoList, function (photoItem, index) {
              return _c("li", {
                key: photoItem.pid,
                staticClass: "select_photo",
                class: index == _vm.currentPicIndex ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.selectPhoto(index)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.moduleStyle === 0 && _vm.photoList.length
        ? _c("div", { staticClass: "jz_scroll_wrap" }, [
            _c(
              "ul",
              { ref: "previewList", staticClass: "photo_list_area jz_scroll" },
              _vm._l(_vm.photoList, function (photoItem, index) {
                return _c(
                  "li",
                  {
                    key: photoItem.pid,
                    staticClass: "select_photo",
                    class: index == _vm.currentPicIndex ? "active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.selectPhoto(index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "photo_mask" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "photo_item" },
                      [
                        _c("ImageComponent", {
                          directives: [
                            {
                              name: "lazyload2",
                              rawName: "v-lazyload2",
                              value: { id: _vm.module.id, src: photoItem.psrc },
                              expression:
                                "{ id: module.id, src: photoItem.psrc }",
                            },
                          ],
                          staticClass: "photo_select_item",
                          attrs: {
                            src: photoItem.psrc,
                            webp: _vm.webpOptOpen,
                            alt: photoItem.desc,
                            responsive: false,
                            quality: "low",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.currentPicIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }