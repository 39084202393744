//
//
//
//
//
//
//
//
//
//
//

import { jumpSearchPage } from '@/components/modules/shared/siteSearch/index.js';
import SearchBox from '@/components/modules/common/searchBox/index.vue';
export default {
    name: 'SiteSearch',
    style: 29,
    components: {
        SearchBox,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleSearch(moduleId, keyword) {
            jumpSearchPage(moduleId, keyword);
        },
        handleMobiSearch() {
            this.$refs.siteSearch.mobiCreatePopup();
        },
    },
};
