var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "timeline_text_horizontal_arrow jz_theme_border_color jz_theme_font_color",
      style: _vm.style,
    },
    [
      _c("svg", { class: "icon_" + _vm.type }, [
        _c("use", { attrs: { "xlink:href": "#icon_" + _vm.type } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }