//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { editBanner } from '@/manage/grid/banner/index.js';
import { banner } from '@/components/grid/banner/index.js';
const manageUse = {};
import JzBannerBtn from './jzBannerBtn.vue';
import { Comm } from '../../utils.js';
import { getVideoResourceHandler } from '@/site/mediaResourceHandler';
import videoMuteBtn from './videoMuteBtn.vue';
export default {
    name: 'jz-banner-content',
    components: {
        JzBannerBtn,
        videoMuteBtn,
    },
    provide: {
        hasDefaultImg: false, // 横幅里的图片模块不需要懒加载, 否则计算高度会错误. @author Jy, @date 2019/05/27
    },
    props: {
        fullScreenInnerInfo: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data: function () {
        return {
            hasInitEvent: false,
            activeRow: false, // 当前屏
            rowOutAnimate: false, //上一个通栏出场动画
            timerFlag: true, // 控制动画是否走入初始类的锁  因为动画结束后需要移除类 不能再赋值上原本的类
            rowInAnimate: false,
            changeFUrl: false, // 是否改变视频地址
            mountedFlag: false,
            isVideoMute: true,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'openRemoveUrlArgs', 'manageMode', 'isJzCdnFlowLimit']),
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        ...Vuex.mapState('manage', ['isShowSetBar', 'props']),
        props() {
            return this.props;
        },
        bannerList() {
            return this.info.pattern.independent.self &&
                this.info.pattern.independent.selfBanner &&
                this.info.pattern.independent.open
                ? this.info.pattern.independent.banner
                : this.info.banner;
        },
        muteBtn() {
            return this.bannerList.vpb;
        },
        isPc() {
            return this.device == 'pc';
        },
        flvUrl() {
            // eslint-disable-next-line vue/no-async-in-computed-properties
            !this.$isServer &&
                Vue.nextTick(() => {
                    const bannerFlv = document.getElementById('jz_banner_flv');
                    !!bannerFlv && this.changeFUrl && bannerFlv.load();
                });
            return this.bannerList.fUrl;
        },
        flvSrc() {
            return this.isJzCdnFlowLimit ? '' : this.flvUrl;
        },
        btnStyle() {
            return this.bannerList.s;
        },
        showFlv() {
            return this.bannerList.t == 1 && this.isPc;
        },
        isMixedBanner() {
            return this.bannerList.t === 2;
        },
        bannerZoneSize() {
            if (!this.manageMode) {
                return;
            }
            var _style = {};
            if (this.bannerList.pst > 0) {
                _style.width = this.bannerList.pws * 100 + '%';
            }
            if (!this.$isServer) {
                // eslint-disable-next-line vue/no-async-in-computed-properties
                Vue.nextTick(banner.fixBannerOverflowHeight);
            }
            return this.isPc ? _style : '';
        },
        bannerHeightSize() {
            var _style = {};
            if (this.manageMode) {
                this.isShowSetBar;
            }

            //全屏通栏样式，从父组件计算传入
            let fullScreenBannerHeightSize = this.fullScreenInnerInfo.bannerHeightSize;
            if (fullScreenBannerHeightSize) {
                return fullScreenBannerHeightSize;
            }
            if (!this.manageMode) {
                return;
            }
            if (this.isPc) {
                if (this.bannerList.pst > 0) {
                    _style.paddingBottom = this.bannerList.phs * 100 + '%';
                    _style.height = 0;
                } else {
                    let bannerFirstList = this.picData[0],
                        natureWidth = bannerFirstList.width || 0,
                        natureHeight = bannerFirstList.height || 0;

                    if (natureWidth > 0 && natureHeight > 0) {
                        _style.paddingBottom = (natureHeight / natureWidth) * 100 + '%';
                    }
                }
            } else {
                if (this.bannerList.mbst > 0) {
                    _style.paddingBottom = this.bannerList.mbhs * 100 + '%';
                    _style.height = 0;
                } else {
                    if (this.bannerList.mopb > 0) {
                        // 不跟随pc横幅
                        let bannerFirstList = this.picData[0],
                            natureWidth,
                            natureHeight,
                            rate;

                        natureWidth = bannerFirstList.width || 0;
                        natureHeight = bannerFirstList.height || 0;

                        if (natureWidth > 0 && natureHeight > 0) {
                            rate = (natureHeight / natureWidth) * 100;
                            _style.paddingBottom = rate + '%';
                        }
                    } else {
                        if (this.bannerList.pst > 0) {
                            let rate = this.bannerList.phs * 100;
                            rate = rate > 26.04 ? rate : 26.04;
                            _style.paddingBottom = rate + '%';
                        } else {
                            let bannerFirstList = this.picData[0],
                                natureWidth,
                                natureHeight,
                                rate;

                            natureWidth = bannerFirstList.width || 0;
                            natureHeight = bannerFirstList.height || 0;

                            if (natureWidth > 0 && natureHeight > 0) {
                                rate = (natureHeight / natureWidth) * 100;
                                if (this.bannerList.mopb == 0) {
                                    // mobi横幅跟随pc端的时候，默认的高度，最小值是，768屏幕下对应尺寸200，转化成比例是200/768=26.04%
                                    rate = rate > 26.04 ? rate : 26.04;
                                }
                                _style.paddingBottom = rate + '%';
                            }
                        }
                    }
                }
            }

            if (!this.$isServer) {
                // eslint-disable-next-line vue/no-async-in-computed-properties
                Vue.nextTick(banner.fixBannerOverflowHeight);
            }
            return _style;
        },

        bannerFlvSize() {
            if (!this.manageMode) {
                return;
            }

            var _style = {};
            this.isShowSetBar;
            //全屏通栏样式，从父组件计算传入
            let fullScreenBannerFlvSize = this.fullScreenInnerInfo.bannerFlvSize;
            if (fullScreenBannerFlvSize) {
                return fullScreenBannerFlvSize;
            }
            if (this.bannerList.fst > 0) {
                _style.paddingBottom = this.bannerList.fhs * 100 + '%';
            }

            if (!this.$isServer) {
                // eslint-disable-next-line vue/no-async-in-computed-properties
                Vue.nextTick(banner.fixBannerOverflowHeight);
            }
            return _style;
        },
        picDataPc() {
            //使用电脑的图片列表
            return this.isPc || this.bannerList.mopb == 0;
        },
        picData() {
            if (this.picDataPc) {
                if (this.isMixedBanner) {
                    if (!this.isPc) {
                        const filteredBannerList = this.bannerList.nmbl.filter((b) => b.st !== 1);
                        if (filteredBannerList.length === 0) {
                            return [this.bannerList.bl[0]];
                        }
                        return filteredBannerList;
                    }
                    return this.bannerList.nmbl;
                }
                return this.bannerList.bl;
            } else {
                return this.bannerList.mbl;
            }
        },
        bannerPicLen() {
            return this.picData.length || 1;
        },
        bannerScale() {
            return this.isPc ? this.bannerList.ps : this.bannerList.mbs;
        },
    },
    watch: {
        showFlv(value) {
            Vue.nextTick(() => {
                if (!this.$isServer) {
                    banner.fixBannerOverflowHeight();
                }
                value || (this.arrowSwitchController && this.arrowSwitchController.update(0));
                !!document.getElementById('jz_banner_flv') && document.getElementById('jz_banner_flv').play();
            });
        },
        picData() {
            Vue.nextTick(() => {
                this.arrowSwitchController && this.arrowSwitchController.update();
            });
        },
        picDataPc() {
            Vue.nextTick(() => {
                this.arrowSwitchController && this.arrowSwitchController.update(0);
            });
        },
        isPc(isPc) {
            var that = this;
            if (!that.hasInitEvent && !isPc && that.bannerList.t == 1) {
                Vue.nextTick(function () {
                    that.arrowSwitchController.setup();
                    banner.init();
                    that.hasInitEvent = true;
                });
            }
        },
        'bannerList.fUrl'() {
            // 控制视频是否需要重新加载 因为在管理态初始为移动端时 切换成pc 去load会报错
            this.changeFUrl = true;
        },
    },
    mounted() {
        this.arrowSwitchController = banner.arrowSwitch();
        this.arrowSwitchController.setup();
        banner.init();

        if (this.showFlv && this.isJzCdnFlowLimit) {
            const VideoResourceHandler = getVideoResourceHandler();
            VideoResourceHandler.add(this.$refs.VideoWrapper);
        }
    },
    methods: {
        toggleMute() {
            const video = this.showFlv ? this.$refs.VideoWrapper : this.$refs.VideoWrapperMixed[0];
            if (!video) {
                return;
            }
            if (video.muted) {
                video.muted = false;
                this.isVideoMute = false;
            } else {
                video.muted = true;
                this.isVideoMute = true;
            }
        },
        fixBannerStyle(status = 'update') {
            if (this.showFlv) {
                this.bannerFlvSize = this.updateBannerStyle(status);
            } else {
                this.bannerHeightSize = this.updateBannerStyle(status);
            }
        },
        jumpOnclickStr(item) {
            const { reqArgs } = item;
            return Comm.createUrlArgsOnclickStr(reqArgs, this.openRemoveUrlArgs, this.hashRemoved);
        },
        bannerPicStyle: function (picList) {
            if (!this.manageMode) {
                return;
            }
            if (picList.st === 1) {
                return {};
            }
            var _style = {};

            if (this.bannerScale != 2) {
                _style.backgroundSize = 'cover';
            }
            _style.backgroundPosition = '50% 50%';
            _style.backgroundImage = 'url(' + picList.psrc + ')';

            return _style;
        },
        prev() {},
        next() {},
        editBanner() {
            editBanner();
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
