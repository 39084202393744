var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item_type_validateCode" }, [
    _c("input", {
      staticClass:
        "validateCode_input input_style jz_focus jz_theme_focus_border_color",
      style: _vm.itemInputStyle,
      attrs: { maxlength: "4" },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "validateCode_img",
      attrs: { alt: " ", src: _vm.validateCodeSrc },
      on: { click: _vm.refreshValidateCode },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }