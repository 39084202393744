import { fullNavManage } from '@/manage/system/navManage/index.js';
/**
 * @function name - getRowBelong
 * @description 获取行的类型 base Root
 * @kind Root
 * @date 2020-07-27
 * @param id {Number} 行id
 * @param flag {Boolean} 是否将横幅通栏归入rowIds
 */
export const getRowBelong = function (id, flag = false) {
    let dict = ['headerRowIds', 'footerRowIds', 'rowIds'],
        i,
        match,
        _row = window._store.state.rows['row' + id];

    for (i = 0; i < dict.length; i++) {
        match = window._store.state.responsiveInfo[dict[i]].indexOf(id);

        if (match > -1) {
            return {
                rows: window._store.state.responsiveInfo[dict[i]],
                index: match,
                name: dict[i],
            };
        }
    }

    if (flag) {
        match = _row && _row.type === 5;

        if (match) {
            return {
                rows: window._store.state.responsiveInfo['rowIds'],
                index: window._store.state.responsiveInfo['rowIds'].indexOf(id),
                name: 'bannerRowIds',
            };
        }
    }

    if (fullNavManage.getAppendRowId() === id) {
        return {
            rows: window._store.state.responsiveInfo['rowIds'],
            index: window._store.state.responsiveInfo['rowIds'].indexOf(id),
            name: 'fullNavRowIds',
        };
    }

    let navColList = window._store.state.sections.navColList || [];
    for (let j = 0; j < navColList.length; j++) {
        let rIds = navColList[j].rIds || [];
        if (rIds.includes(id)) {
            return {
                rows: rIds,
                index: rIds.indexOf(id),
                name: 'NavItemFreeLayoutRowIds',
            };
        }
    }
    return null;
};

export function setCopyRowAnchorName(info) {
    try {
        var anchor = info.organ;

        if (anchor.at == 1) {
            anchor.an = anchor.an + '(1)'; // 需要重置掉复制新导航栏目

            anchor.adc = 0;
        }
    } catch (e) {
        console.log(e);
    }
}
