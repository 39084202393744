// JZ
import * as InitSectionObserver from './initSectionObserver';
import * as InitSite from './initSite';
import { banner } from './banner';
import * as Utils from './utils';
import * as ModuleOnlineMap from './moduleOnlineMap';
import * as Flv from './flv';
import * as MsgBoard from './msgBoard';
import * as MsgBoardCommentCompon from './msgBoardCommentCompon.js';
import * as ProductNavModule from './productNavModule';
import * as SectionMemberLogin from './sectionMemberLogin';
import * as SectionMultiLanguage from './sectionMultiLanguage';
import * as GridHeader from './gridHeader';
import * as Old from './old';
import * as WelcomePage from './welcomePage';
import { productList } from '@shared/visitor/productList/index.js';
import { initCarouselRowsEffects } from '@shared/visitor/carouselRowsEffects/index.js';
import { initResAnimation } from '../components/modules/animation/index.js';
import { triggerServiceNumber } from '@/components/modules/shared/triggerServiceNumber/index.js';
// Site
import * as Site from './site';

import JzFdpLog from './fdpLog';

function registerModules(namespace, modules) {
    modules.forEach((m) => Object.assign(namespace, m));
}

import * as Wx from './wx';

function initSideModuleAction(selector, width, side) {
    const dir = side === 1 ? 'left' : 'right';
    $(selector)
        .on('mouseenter', function () {
            $(this).css(dir, `${0}px`);
        })
        .on('mouseleave', function () {
            $(this).css(dir, `-${width}px`);
        });
}

export function injectFunctionsToWindow() {
    const JZModules = [
        { productList, initCarouselRowsEffects, initResAnimation, initSideModuleAction },
        InitSectionObserver,
        InitSite,
        {
            banner,
        },
        Utils,
        ModuleOnlineMap,
        Flv,
        MsgBoard,
        MsgBoardCommentCompon,
        ProductNavModule,
        SectionMemberLogin,
        SectionMultiLanguage,
        GridHeader,
        Old._Jz,
        Wx,
        WelcomePage,
    ];
    if (!window.JZ) {
        window.JZ = {};
    }
    registerModules(window.JZ, JZModules);

    // Site
    const SiteModules = [Site, Old._Site];
    if (!window.Site) {
        window.Site = {};
    }
    registerModules(window.Site, SiteModules);

    window.JZ.JzFdpLog = JzFdpLog;

    if (!window.Mobi) {
        window.Mobi = {};
    }
    window.Mobi.triggerServiceNumber = triggerServiceNumber;
}
