export default {
    computed: {
        itemName() {
            return this.module.style === 39 ? '栏目' : '分类';
        },
        name() {
            return this.itemName;
        },
        activeButtonText() {
            return `${this.isSelectGroupAll ? '添加' : '选择'}${this.itemName}`;
        },
        buttonText() {
            return `管理${this.itemName}`;
        },
    },
};
