//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { photoSettingMixin } from '../photoSettingMixin';
import { setQueryString, Comm } from '../../utils';
import { decodeHtml } from '@/components/utils.js';
const manageUse = {};
const {
    getStyleDataByDevice,
    extend,
    getComputedTextColor,
    bindFlag,
    getWrapStyle,
    createUrlArgsOnclickStr,
    getBitMemory,
} = Comm;
import { ImageComponent } from '@jz/biz-shared';
import { imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
import { getComputedBg } from '@/shared/manage/utils';
import { getComponentByStyle } from './components/index.js';
import TopIcon from './topIcon.vue';

const constants = {
    DEFAULT_TOP_MARGIN: 24,
    DEFAULT_HORIZONAL_MARGIN: 20,
};

export default {
    components: {
        ImageComponent,
        TopIcon,
    },
    mixins: [photoSettingMixin],
    props: {
        newsList: {
            type: Array,
            default: () => [],
        },
        module: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const data = {
            getLoading: false,
            isHoverContent: false,
        };
        return data;
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'colId']),
        ...Vuex.mapGetters(['hashRemoved']),
        themeColor() {
            return this.$store.state.responsiveInfo.themeColor;
        },
        imgOptsComputed,
        newsGroupList() {
            // 管理态在vuex初始化的
            return this.$store.state.manage?.newsGroupList ?? [];
        },
        flagIsBlank: bindFlag(0x1),
        flagDate() {
            if (this.isMobi && !this.isDisplayDefaultInMobi) {
                return this.flagMobiDate;
            }
            return this.flagPcDate;
        },
        flagGroup() {
            if (this.isMobi && !this.isDisplayDefaultInMobi) {
                return this.flagMobiGroup;
            }
            return this.flagPcGroup;
        },
        flagAbstract() {
            if (this.isMobi && !this.isDisplayDefaultInMobi) {
                return this.flagMobiAbstract;
            }
            return this.flagPcAbstract;
        },
        flagTitle: bindFlag(0x8),

        flagPcDate: bindFlag(0x2),
        flagMobiDate: bindFlag(0x80),
        flagPcGroup: bindFlag(0x4),
        flagMobiGroup: bindFlag(0x100),
        flagPcAbstract: bindFlag(0x40),
        flagMobiAbstract: bindFlag(0x200),
        isDisplayDefaultInMobi() {
            return this.mobiPrivatePattern.psc === 0;
        },
        dateStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pdf,
                mobiData: this.mobiPrivatePattern.pdf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        classifyStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pcf,
                mobiData: this.mobiPrivatePattern.pcf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        titleStyle() {
            const pcTitleFont = this.pcPrivatePattern.ptf;
            const font = getStyleDataByDevice({
                pcData: pcTitleFont,
                mobiData: this.mobiPrivatePattern.ptf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            const alignMap = {
                0: 'left',
                1: 'center',
            };
            if (this.isStyle2 && pcTitleFont.y && alignMap[pcTitleFont.ta2]) {
                if (alignMap[pcTitleFont.ta2]) {
                    fontStyle.textAlign = alignMap[pcTitleFont.ta2];
                }
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ptl,
                mobiData: this.mobiPrivatePattern.ptl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },
        styleTwoTextAlign() {
            if (this.isStyle2 && this.pcPrivatePattern.ta === 1) {
                return { textAlign: 'center' };
            }
            return {};
        },
        abstractStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.paf,
                mobiData: this.mobiPrivatePattern.paf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pal,
                mobiData: this.mobiPrivatePattern.pal,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },
        target() {
            return this.flagIsBlank ? '_self' : '_blank';
        },
        privatePattern() {
            return this.module.privatePattern[this.device];
        },
        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },
        isStyle2() {
            return this.module.prop0 === 1;
        },
        customSpace() {
            return this.module.prop8 !== 0;
        },
        itemSpaceStyle() {
            const horizonalSpace = this.module.blob0?.mh ?? NaN;
            const verticalSpace = this.module.blob0?.mv ?? NaN;
            if (this.isStyle2) {
                return {
                    padding: `${verticalSpace}px ${horizonalSpace}px`,
                };
            } else {
                return {
                    'padding-top': `${verticalSpace}px`,
                    'padding-bottom': `${verticalSpace}px`,
                };
            }
        },
        topIconSetting() {
            return this.pcPrivatePattern.ti || {};
        },
        imgBoxStyle() {
            const style = {};
            var type = this.module.prop0;

            let pattern = this.model;

            if (pattern.pmt === 1) {
                //自定义图片间距
                style['padding-left'] = `${parseInt(pattern.pmh / 2)}px`;
                style['padding-right'] = `${parseInt(pattern.pmh / 2)}px`;
                style['padding-top'] = `${parseInt(pattern.pmv / 2)}px`;
                style['padding-bottom'] = `${parseInt(pattern.pmv / 2)}px`;
            }
            if (type == 0 || type == 1) {
                return style;
            }
            if (this.privatePattern.ppwt == 1) {
                if (this.isMobi) {
                    style.flexShrink = 0;
                }
                style.width = this.privatePattern.ppw * 100 + '%';
                return style;
            }
            return style;
        },
        itemLineStyle() {
            if (!this.manageMode) return {};

            let style = {};

            // bg
            if (this.device === 'mobi') {
                if (this.isStyle2) {
                    if (this.mobiPrivatePattern.dbg.y === 0) {
                        // 默认取PC端的数据
                        Object.assign(style, this.computedBg(this.pcPrivatePattern.dbg));
                    } else {
                        Object.assign(style, this.computedBg(this.mobiPrivatePattern.dbg));
                    }
                }
            } else {
                Object.assign(style, this.computedBg(this.privatePattern.dbg));
            }

            const type = this.module.prop0;

            if (this.device === 'pc' && this.customSpace) {
                if (!this.isStyle2) {
                    Object.assign(style, this.itemSpaceStyle);
                }
            }

            // 每行个数
            if (this.device === 'pc' && this.isStyle2) {
                const size = this.privatePattern.ppln;
                const [horizonalSpace, verticalSpace] = this.itemSpace;
                Object.assign(style, {
                    width: `calc(100% / ${size} - ${horizonalSpace}px)`,
                    marginTop: `${verticalSpace}PX`,
                    marginLeft: `${horizonalSpace / 2}px`,
                    marginRight: `${horizonalSpace / 2}px`,
                });
            }

            if (this.device == 'mobi' && type == 1) {
                if (this.privatePattern.pplnt == 1) {
                    Object.assign(style, {
                        width: `${100 / this.privatePattern.ppln - 3.6458}%`,
                    });
                }
            }

            return style;
        },
        itemSpace() {
            if (this.module.prop8 === 0) {
                // 默认
                return [constants.DEFAULT_HORIZONAL_MARGIN, constants.DEFAULT_TOP_MARGIN];
            } else {
                const horizonalSpace = this.module.blob0?.mh ?? constants.DEFAULT_HORIZONAL_MARGIN;
                const verticalSpace = this.module.blob0?.mv ?? constants.DEFAULT_TOP_MARGIN;
                return [horizonalSpace, verticalSpace];
            }
        },
        hoverStyle() {
            if (this.device === 'mobi' || !this.manageMode) {
                return;
            }

            return this.computedBg(this.privatePattern.sbg);
        },
        disabledHoverStyle() {
            return this.device === 'mobi';
        },
        itemInnerStyle() {
            if (!this.manageMode) return {};
            return {};
        },
        moduleStyleType() {
            return this.module.prop0;
        },
        moduleStyleComponent() {
            return getComponentByStyle(this.moduleStyleType);
        },
        isRenderModuleStyle() {
            return [4].includes(this.moduleStyleType);
        },
        isMultiColumn() {
            return this.device === 'pc' && this.module.privatePattern.pc.column > 1;
        },
        wrapperClass() {
            if (this.isMultiColumn) {
                return ['news_list_multi_columns', `news_list_multi_columns-${this.module.privatePattern.pc.column}`];
            }
            return [];
        },
        isRecommendNewsList() {
            return this.module.id === 14;
        },
        isSmartRecommend() {
            return this.isRecommendNewsList && this.module.newsSelectType === 2;
        },
    },
    methods: {
        computedBg(data = {}) {
            if (data.y === 0) {
                // 默认
                return {};
            }
            return getComputedBg(data);
        },
        handleItemMouseEnter(item) {
            this.stopWatchDataInManageMode();
            this.$set(item, 'hovering', true);
        },
        handleItemMouseLeave(item) {
            this.stopWatchDataInManageMode();
            this.$set(item, 'hovering', false);
        },
        stopWatchDataInManageMode() {
            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
        },
        getTitleHoverColor(item) {
            let style = {};
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ptf,
                mobiData: this.mobiPrivatePattern.ptf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            if (item.hovering && !this.isMobi) {
                if (font.y != 0) {
                    style = getComputedTextColor(font.hc || '#000');
                } else {
                    if (!this.$isServer) {
                        style.color = this.themeColor;
                    }
                }
            }
            return style;
        },
        getHoverColor(item, key) {
            let style = {};
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern[key],
                mobiData: this.mobiPrivatePattern[key],
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            if (item.hovering && !this.isMobi) {
                if (font.y != 0) {
                    style = getComputedTextColor(font.sc || '#212121'); // 改用 sc 而不用 hc，是因为除了 title 外，选中颜色的功能是后续迭代的，为了兼容。
                }
            }
            return style;
        },
        setQueryString(url, key, val, flag = 0) {
            //外链直接返回url，不用加上fromColId
            if (getBitMemory(flag, 0x80)) {
                return url;
            }
            return setQueryString(url, key, val);
        },
        imgWrapStyle(newsItemIndex) {
            var style = {};
            var type = this.module.prop0;

            if (!this.isShowPic(newsItemIndex)) {
                return style;
            }
            if (type != 0 && this.privatePattern.ppht == 1) {
                style.paddingTop = this.privatePattern.pph * 100 + '%';
            }
            //上下图文样式手机高度跟随pc mobi视图 默认保留原先旧逻辑
            if (
                this.device == 'mobi' &&
                this.privatePattern.ppht == 0 &&
                type == 1 &&
                this.pcPrivatePattern.ppht == 1
            ) {
                style.paddingTop = this.pcPrivatePattern.pph * 100 + '%';
            }
            return style;
        },
        imgStyle() {
            var style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }
            return style;
        },
        itemLineClass(newsItemIndex) {
            var classes = {},
                type = this.module.prop0;

            if (this.device != 'mobi' && type == 1) {
                if (this.privatePattern.pplnt == 1) {
                    classes.news_list_item_line_num = true;
                    classes['news_list_item_line_num' + this.privatePattern.ppln] = true;
                }
            }

            if (this.pcPrivatePattern.pplnt == 1) {
                if (this.pcPrivatePattern.ppln == 1 || this.pcPrivatePattern.ppln == 3) {
                    classes['news_list_item_mobi_full'] = true;
                }
            }

            classes.news_list_item_line_pic = this.isShowPic(newsItemIndex);
            if (this.isMultiColumn) {
                classes['news_list_column_item'] = true;
            }
            return classes;
        },
        isShowPic(newsItemIndex) {
            switch (this.module.prop0) {
                case 1:
                case 2:
                    return true;
                case 3:
                    return newsItemIndex < (this.device === 'mobi' ? 1 : this.pcPrivatePattern.column);
            }
            return false;
        },
        decodeHtml,
        getItemTarget(item) {
            // todo
            if (!this.manageMode) return this.target;
            return Fai.getUrlParam(item.url, 'groupId') === Fai.getUrlParam(location.href, 'groupId')
                ? '_self'
                : this.target;
        },
        groups(newsItem) {
            if (newsItem.groups) {
                return newsItem.groups;
            }
            if (!Array.isArray(this.newsGroupList)) {
                return [];
            }
            return newsItem.groupIds.map((groupId) => this.newsGroupList.find((item) => item.id === groupId));
        },
        onclickStr(item) {
            const { ndReqArgs } = item;
            return createUrlArgsOnclickStr(ndReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
