export function createLinkWithSideEffect(url, el) {
    if (url.indexOf('Site.hoverQrCode(') === -1) {
        //服务器渲染保护
        if (typeof $ !== 'undefined') {
            Vue.nextTick(() => {
                const $btn = $(el);
                $btn.attr('onmouseover', '').off('mouseover.qrcode').off('mouseleave.qrcode').off('click.qrcode');
            });
        }
        return url;
    } else {
        //服务器渲染保护
        if (typeof $ !== 'undefined') {
            Vue.nextTick(() => {
                const $btn = $(el);
                $btn.attr('onmouseover', url.slice(url.indexOf('Site.hoverQrCode(')));
            });
        }
        return 'javascript: void 0';
    }
}
