//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MobiRightArrowIcon',
    methods: {
        arrowRightHandler(e) {
            this.$emit('arrowRight', e);
        },
    },
};
