import { CarouselRowsEffects } from './CarouselRowsEffects';

const CarouselRowsEffectsMap = CarouselRowsEffects.CarouselRowsEffectsMap;

const hasCarouselRowsEffect = (rowId = 0) => CarouselRowsEffectsMap.has(`carouselRowsEffect${rowId}`);
const getCarouselRowsEffect = (rowId = 0) => CarouselRowsEffectsMap.get(`carouselRowsEffect${rowId}`);
const deleteCarouselRowsEffect = (rowId = 0) => CarouselRowsEffectsMap.delete(`carouselRowsEffect${rowId}`);

const initCarouselRowsEffects = (options = { id: 0 }) => {
    const { id } = options;
    if (hasCarouselRowsEffect(id)) {
        // eslint-disable-next-line no-console
        console.warn(`carouselRowsEffect${id} 已经存在`);
        return getCarouselRowsEffect(id);
    }
    const CarouselRowsEffect = new CarouselRowsEffects(options);
    CarouselRowsEffectsMap.set(`carouselRowsEffect${id}`, CarouselRowsEffect);
    return CarouselRowsEffect;
};

export {
    CarouselRowsEffectsMap,
    CarouselRowsEffects,
    initCarouselRowsEffects,
    getCarouselRowsEffect,
    hasCarouselRowsEffect,
    deleteCarouselRowsEffect,
};
