var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "s_multi_language_container",
      class: "s_multi_language_" + _vm.currentLan.lanCode,
    },
    [
      _c(_vm.multiLanguageStyleComponent, {
        tag: "component",
        attrs: {
          "is-mobi": _vm.isMobi,
          "mobi-icon-color": _vm.mobiIconColor,
          "lan-list": _vm.list,
          "current-lan": _vm.currentLan,
          "icon-visible": _vm.shouldRenderIcon,
          "text-visible": _vm.shouldRenderText,
          "area-type": _vm.renderArea,
          "create-font-style": _vm.createFontStyle,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }