//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIsOpenRemoveUrlArg } from '@/site/shared/cookie/index.js';
import { setQueryString, Comm } from '../../utils';
const { imageSettingtMixin, createUrlArgsOnclickStr } = Comm;
export default {
    name: 'UnitSearchResultProduct',
    mixins: [imageSettingtMixin],
    inject: ['module'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        other: {
            type: Object,
            default: () => ({}),
            require: true,
        },
    },
    data() {
        return {
            moduleId: 'search_product',
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'colId', 'LS', 'isMaterialLazyLoad']),
        ...Vuex.mapGetters(['hashRemoved']),
        isMobi() {
            return this.device === 'mobi';
        },
        pageCount() {
            const total = this.product.total || 0;
            return total % this.other.pageSize === 0
                ? total / this.other.pageSize
                : Math.ceil(total / this.other.pageSize);
        },
        noPicStyle() {
            return this.module.prop0 === 0;
        },
        model() {
            return this._mixin_model;
        },
        productStyle() {
            if (this.noPicStyle || this.isMobi || !this.manageMode) {
                return {};
            }
            return this._mixin_itemStyle;
        },
        productSizeStyle() {
            if (this.noPicStyle || this.isMobi || !this.manageMode) {
                return {};
            }
            return this._mixin_itemSizeStyle;
        },
        picItemStyle() {
            return this._mixin_picStyle;
        },
        someProductHasCategory() {
            return this.list.some((item) => Array.isArray(item.groups) && item.groups.length > 0);
        },
    },
    methods: {
        setQueryString(url, key, val) {
            return setQueryString(url, key, val);
        },
        uniqueId(id) {
            return `${id}` + this.module.prop0;
        },
        createOnclickStr(item) {
            if (this.$isServer) {
                return null;
            }
            return createUrlArgsOnclickStr(item.pdReqArgs, getIsOpenRemoveUrlArg(), this.hashRemoved);
        },
        genPicItemStyle(item) {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.picPath}')`;
            }

            return { ...this.picItemStyle, ...style };
        },
    },
};
