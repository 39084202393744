const editNav = function () {
    if (window._store.state.manage.nav.refreshNavSetting) {
        window._store.commit('manage/nav/setRefreshNavSetting', false);
    }

    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'nav',
        active: true,
    });
};

const manageNav = function () {
    Fai.popupWindowVersionTwo.createPopupWindow({
        title: '管理栏目',
        frameSrcUrl: 'manage_v2/nav.jsp?_showHideCol=false&_fromResponsive=true&ram=' + Math.random(),
        width: '820',
        height: '678',
        saveBeforePopup: false,
        version: 2,
    });
    Fai.logDog(200797, 0);
};

const addNav = function () {
    Fai.popupWindowVersionTwo.createPopupWindow({
        title: '添加栏目',
        frameSrcUrl:
            'manage_v2/navColEdit.jsp?orderType=undefined&mid=undefined&parentId=undefined&model=jsAdd&fromNavTool=true&add=true&fromResponsive=true&ram=' +
            Math.random(),
        width: '820',
        height: '640',
        callArgs: {},
        saveBeforePopup: false,
        version: 2,
    });
};

const editFixNavStyle = function () {
    sessionStorage.setItem('navFixBtnClick', 1); // Nav/index.vue 置为0, 仅用于判断来自哪里点击，方便切换面板

    // Nav/index.vue 置为0, 仅用于判断来自哪里点击，方便切换面板
    if (!window._store.state.manage.nav.refreshNavSetting) {
        window._store.commit('manage/nav/setRefreshNavSetting', true);
    }

    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'nav',
        active: true,
    });
};
class FullNav {
    constructor() {}
    changeEditStatus(isEdit) {
        window._store.commit('manage/nav/setIsFullNavEdit', isEdit);
    }
    enterEdit() {
        this.changeEditStatus(true);
        this.changeRowDisableAdd(true);
    }
    exitEdit() {
        this.changeEditStatus(false);
        this.changeRowDisableAdd(false);
    }
    isEdit() {
        return window._store.state.manage.nav.isFullNavEdit;
    }
    changeRowDisableAdd(disabled) {
        JZ.changeRowDisableAdd(disabled);
    }
    getAppendRowId() {
        return window._store.state.responsiveInfo.pattern.nav.rl[
            window._store.state.responsiveInfo.pattern.nav.rl.length - 1
        ]?.id;
    }
}
const fullNavManage = new FullNav();
export { editNav, manageNav, addNav, editFixNavStyle, fullNavManage };
