//
//
//
//
//
//

import { Comm } from '../../utils';
const { isNewModuleAfter20210707 } = Comm;
import firstStyleComponent from './firstStyleComponent.vue';
import secondStyleComponent from './secondStyleComponent.vue';
import thirdStyleComponent from './thirdStyleComponent.vue';

export default {
    name: 'IconCombination',
    style: 33,
    components: {
        firstStyleComponent,
        secondStyleComponent,
        thirdStyleComponent,
    },
    props: ['module'],
    computed: {
        ...Vuex.mapState(['device']),
        moduleStyle() {
            return this.module.prop0;
        },
        renderComponent() {
            let component = null;
            switch (this.moduleStyle) {
                case 1:
                    component = 'firstStyleComponent';
                    break;
                case 2:
                    component = 'secondStyleComponent';
                    break;
                case 3:
                    component = 'thirdStyleComponent';
                    break;
            }
            return component;
        },
        classes() {
            const classes = [];
            if (this.isNewModuleAfter20210707) {
                classes.push('icon_combination_new_module_after_20210707');
            }
            return classes;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
    },
    mounted() {
        Site.addQrCode('.icon_combination');
    },
};
