var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "online_service_wechat" }, [
    _vm.path
      ? _c("img", {
          staticClass: "online_service_wechat_content",
          attrs: { src: _vm.path, alt: "在线客服微信二维码" },
        })
      : _c("div", {
          staticClass:
            "online_service_wechat_content online_service_wechat--default",
        }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "online_service_wechat_text", style: _vm.textStyle },
      [_vm._v(_vm._s(_vm.text))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }