//
//
//
//
//
//
//
//
//
//
//
//

//验证码，这个会比较特别，接口跟其他表单项不一样
export default {
    props: ['module', 'form', 'value'],
    data() {
        return {
            random: Math.random() * 1000,
        };
    },
    computed: {
        validateCodeSrc() {
            return `/validateCode.jsp?${this.random}&validateCodeRegType=3`;
        },
    },
    methods: {
        refreshValidateCode() {
            this.random = Math.random() * 1000;
        },
    },
};
