//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {
    components: {},
};
const MARGIN_TOP_DEFAULT = 15;
const MARGIN_LEFT_PERCENT_DEFAULT = 0.5;
const MARGIN_RIGHT_PERCENT_DEFAULT = 0.5;
export default {
    components: {
        ...manageUse.components,
    },
    props: ['rowid'],
    data() {
        return {
            memberLoginInstance: null,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'LS', 'sections']),
        ...Vuex.mapGetters(['info']),
        pattern() {
            return this.info.pattern.memberLogin;
        },
        signupUrl() {
            return this.sections.signupUrl;
        },
        loginUrl() {
            return this.sections.loginUrl;
        },
        innerFeatures() {
            if (!this.memberLoginInstance) {
                return [];
            }
            let innerFeatures = this.memberLoginInstance.getFeatures();
            return innerFeatures;
        },
    },
    watch: {
        'pattern.h'() {
            this.$nextTick(this.resetMargin);
        },
    },
    mounted() {
        this.resetMargin();
        this.initMemberLoginManage();
    },
    methods: {
        initMemberLoginManage() {
            if (!this.manageMode) {
                return;
            }
            const info = this.info;
            let memberLoginInstance = new MemberLoginManage({
                pattern: info.pattern.memberLogin,
            });
            memberLoginInstance.registerFeatures();
            this.memberLoginInstance = memberLoginInstance;

            this.$watch(
                () => this.device,
                () => this.setFeatureData(),
                {
                    immediate: true,
                }
            );
        },
        setFeatureData() {
            const memberLoginInstance = this.memberLoginInstance;
            let innerFeatures = memberLoginInstance.getFeatures();
            for (let i in innerFeatures) {
                if (memberLoginInstance[`set${innerFeatures[i].name}`]) {
                    memberLoginInstance[`set${innerFeatures[i].name}`](this, this.device);
                }
            }
        },
        isFirstInit() {
            return (
                MARGIN_LEFT_PERCENT_DEFAULT + MARGIN_RIGHT_PERCENT_DEFAULT == 1 &&
                this.pattern.ml == MARGIN_LEFT_PERCENT_DEFAULT &&
                this.pattern.mr == MARGIN_RIGHT_PERCENT_DEFAULT
            );
        },
        isErrorMargin() {
            return (this.pattern.ml === 1 && this.pattern.mr === 0) || (this.pattern.ml === 0 && this.pattern.mr === 1);
        },
        resetMargin() {
            if (!this.pattern.h && (this.isFirstInit() || this.isErrorMargin())) {
                const $webColContent = $(this.$el).parent();
                const webColContentWidth = $webColContent.width();
                const moduleWidth = $(this.$el).width();
                const marginLeft = (webColContentWidth - moduleWidth) / 2;
                const marginLeftPercent = marginLeft / webColContentWidth;
                const marginTop = MARGIN_TOP_DEFAULT;
                const marginTopPercent = marginTop / webColContentWidth;

                this.pattern.ml = this.pattern.mr = marginLeftPercent;
                this.pattern.mt = marginTopPercent;
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
