import { isPlainObject } from '@jz/utils';
import { AnimationApis, ANIMATION_TYPE_LIST, ANIMATION_TYPE } from '@jz/biz-shared';
import {
    getPhotoGroupAnimationA,
    getPhotoGroupAnimationB,
    getListPicAnimationA,
    getListPicAnimationB,
    getListPicAnimationC,
    getListPicAnimationD,
    getCarouselAnimation,
    getOnlineMsgAnimation,
    getOnlineFormAnimation,
    getRichAnimation,
    getProductListAnimation,
    getNewsListAnimation,
    getIconCombinationAnimation,
} from './animationDomList/index';
import { JzFdpLog } from '@/site/shared/log/index.js';
/**
 * @function name - getResAnimationConfig
 * @description 获取转换后的模块数据可供AnimationManage使用的数据
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param options {Object}
 */

const getResAnimationConfig = (options) => {
    if (!isPlainObject(options)) return;
    const config = {};
    config['contentTerminal'] = 'zsy';
    config['manageMode'] = window._store.state.manageMode;
    config['Monitor'] = window.Monitor;
    config['JzFdpLog'] = JzFdpLog;
    return AnimationApis.getAnimationConfig(options, config);
};

/**
 * @function name - getModuleTypeForAnimation
 * @description 获得对应模块的类型
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param id {Number}
 * @returns {Number}
 * @example
 * getModuleTypeForAnimation( 888) => Number
 */
export const getModuleTypeForAnimation = function (id) {
    var $moduleDom = $('#module' + id),
        moduleType = -1,
        tmpClass = '',
        supportMergeAnimateStyleArr = []; // 图文展示-1、轮播多图-2、列表多图-3、图册目录-15、产品展示-16、文章列表-17、在线表单-21、在线留言-23 图标组合-33 支持动画组合。

    // 图文展示-1、轮播多图-2、列表多图-3、图册目录-15、产品展示-16、文章列表-17、在线表单-21、在线留言-23 图标组合-33 支持动画组合。
    supportMergeAnimateStyleArr = [1, 2, 3, 15, 16, 17, 21, 23, 33];

    if (!$moduleDom.length) {
        return moduleType;
    }

    $.each(supportMergeAnimateStyleArr, function (index, val) {
        /* iterate through array or object */
        tmpClass = 'jz_module_style_' + val;

        if ($moduleDom.hasClass(tmpClass)) {
            moduleType = index;
            return false;
        }
    });
    return moduleType;
};

/**
 * @function name - getModuleStructureTypeForAnimation
 * @description 获得模块的不同结构
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param moduleType {Number}
 * @param id {Number}
 * @returns {String}
 * @example
 * getModuleStructureTypeForAnimation(1, 888) => ''
 */
export const getModuleStructureTypeForAnimation = function (moduleType, id) {
    var $moduleDom = $('#module' + id),
        structureType = -1,
        structureArr = ['a', 'b', 'c', 'd', 'e', 'f'],
        // 对应模块的12345样式
        tmpArr = [],
        $dom;

    switch (moduleType) {
        case 0:
            // 图文展示
            $dom = $moduleDom.find('.module_rich_content');
            tmpArr = ['rich_img_right', 'rich_img_none'];

            for (let i = 0; i < tmpArr.length; i++) {
                if ($dom.hasClass(tmpArr[i])) {
                    structureType = moduleType + structureArr[i + 1];
                    break;
                }
            }

            if (structureType == -1) {
                structureType = moduleType + 'a';
            }

            break;

        case 1:
            // 轮播多图
            tmpArr = ['carousel_small_pic', 'carousel_arrow_pic', 'carousel_dot_pic', 'carousel_three_pic'];
            $dom = $moduleDom.find('.module_content_detail');

            for (let i = 0; i < tmpArr.length; i++) {
                if ($dom.hasClass(tmpArr[i])) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 2:
            // 列表多图
            tmpArr = [
                'list_photos_list_style',
                'list_photos_slider_style',
                'list_photos_row_resp_style',
                'list_photos_swipe_style',
            ];
            $dom = $moduleDom.find('.module_list_photos_content');

            for (let i = 0; i < tmpArr.length; i++) {
                if ($dom.hasClass(tmpArr[i])) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 3:
            // 图册目录
            tmpArr = ['photo_group_basic_style', 'photo_group_marquee_style', 'photo_group_slider_style'];
            $dom = $moduleDom.find('.module_content_detail');

            for (let i = 0; i < tmpArr.length; i++) {
                if ($dom.hasClass(tmpArr[i])) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 4:
            // 产品展示
            $dom = $moduleDom.find('.product_list_content');

            for (let i = 0; i < 4; i++) {
                if ($dom.hasClass('jz_product_list_' + i)) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 5:
            // 文章列表
            $dom = $moduleDom.find('.module_content_detail');

            for (let i = 0; i < 4; i++) {
                if ($dom.hasClass('module_news_list_style' + i)) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 6:
            // 在线表单
            $dom = $moduleDom.find('.m_form');

            for (let i = 0; i < 3; i++) {
                if ($dom.hasClass('s_' + i)) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 7:
            // 在线留言
            $dom = $moduleDom.find('.m_msg_board');

            for (let i = 0; i < 3; i++) {
                if ($dom.hasClass('s_' + i)) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        case 8:
            // 图标组合
            tmpArr = ['first_style', 'second_style', 'third_style'];
            $dom = $moduleDom.find('.style_container');

            for (let i = 0; i < 3; i++) {
                if ($dom.hasClass(tmpArr[i])) {
                    structureType = moduleType + structureArr[i];
                    break;
                }
            }

            break;

        default:
            break;
    }

    return structureType;
};

/**
 * @function name - getContentDomSeqList
 * @description 获取内容序列
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param moduleType {Number}
 * @param order {Number}
 * @param id {Number}
 * @returns {Array} dom数组
 * @example
 * getContentDomSeqList(1,0,888) => []
 */
export const getContentDomSeqList = function (moduleType, order, id) {
    var domSeqList = [],
        structureType = getModuleStructureTypeForAnimation(moduleType, id),
        typeA = moduleType + 'a',
        typeB = moduleType + 'b',
        typeC = moduleType + 'c',
        typeD = moduleType + 'd';

    switch (moduleType) {
        case 0:
            // 图文展示
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                    domSeqList = getRichAnimation(id);
                    break;

                default:
                    break;
            }

            break;

        case 1:
            // 轮播多图
            switch (structureType) {
                case typeA:
                    domSeqList = getCarouselAnimation(id);
                    break;

                case typeB:
                case typeC:
                case typeD:
                default:
                    break;
            }

            break;

        case 2:
            // 列表多图
            switch (structureType) {
                case typeA:
                    domSeqList = getListPicAnimationA(id, order);
                    break;

                case typeB:
                    domSeqList = getListPicAnimationB(id);
                    break;

                case typeC:
                    domSeqList = getListPicAnimationC(id, order);
                    break;

                case typeD:
                    domSeqList = getListPicAnimationD(id, order);
                    break;

                default:
                    break;
            }

            break;

        case 3:
            // 图册目录
            switch (structureType) {
                case typeA:
                    domSeqList = getPhotoGroupAnimationA(id, order);
                    break;

                case typeB:
                case typeC:
                    domSeqList = getPhotoGroupAnimationB(id);
                    break;

                default:
                    break;
            }

            break;

        case 4:
            // 产品展示
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                case typeD:
                    domSeqList = getProductListAnimation(id, structureType == typeC ? 0 : order);
                    break;

                default:
                    break;
            }

            break;

        case 5:
            // 文章列表
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                case typeD:
                    domSeqList = getNewsListAnimation(id, structureType != typeB ? 0 : order);
                    break;

                default:
                    break;
            }

            break;

        case 6:
            // 在线表单
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                    domSeqList = getOnlineFormAnimation(id);
                    break;

                default:
                    break;
            }

            break;

        case 7:
            // 在线留言
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                    domSeqList = getOnlineMsgAnimation(id);
                    break;

                default:
                    break;
            }

            break;

        case 8:
            // 图标组合
            switch (structureType) {
                case typeA:
                case typeB:
                case typeC:
                    domSeqList = getIconCombinationAnimation(id, order);
                    break;

                default:
                    break;
            }

            break;

        default:
            break;
    }

    return domSeqList;
};

const handleDomSeqList = (contentAnimation) => {
    contentAnimation.el.length &&
        contentAnimation.el.forEach((elementArray) => {
            elementArray.length &&
                elementArray.forEach((elementItem) => {
                    $(elementItem).addClass('animateModule');
                });
        });
};

const resContentAnimationHandler = (id, config) => {
    var moduleType = getModuleTypeForAnimation(id),
        animationConfig = config;
    if (animationConfig.t === 0 || typeof animationConfig.t == 'undefined') return;
    animationConfig.t = typeof animationConfig.t != 'undefined' ? animationConfig.t : 0;
    animationConfig.r = typeof animationConfig.r != 'undefined' ? animationConfig.r : 0;
    animationConfig.s = typeof animationConfig.s != 'undefined' ? animationConfig.s : 0.6;
    animationConfig.d = typeof animationConfig.d != 'undefined' ? animationConfig.d : 0.6;
    animationConfig.o = typeof animationConfig.o != 'undefined' ? animationConfig.o : 0;
    animationConfig.mt = moduleType;
    animationConfig.playAnimateDelay = animationConfig.o === 0 ? 0.1 : animationConfig.s;
    // 将内容序列装入list
    animationConfig.el = getContentDomSeqList(moduleType, animationConfig.o, id);
    handleDomSeqList(animationConfig);
};

const resConfigHandler = (config, options) => {
    const { el } = options;
    ANIMATION_TYPE_LIST.forEach((key) => {
        const animationConfig = config[key];
        switch (key) {
            case ANIMATION_TYPE.CONTENT:
                animationConfig.beforeAnimationStart = (val) => {
                    return new Promise((resolve) => {
                        const { idSelector } = config;
                        let _module = $(`#${idSelector}`);
                        _module.find('.module_content').removeClass(' jz_hidden_content_Animate');
                        resolve(val);
                    });
                };
                resContentAnimationHandler(config['id'], animationConfig);
                break;
            // eslint-disable-next-line no-duplicate-case
            case ANIMATION_TYPE.ADMISSION:
            case ANIMATION_TYPE.EMPHASIS:
            default:
                if (el) {
                    animationConfig.el = el;
                }
                break;
        }
    });
};

/**
 * @function name - initResAnimation
 * @description 初始化AnimationManage实例
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param options {Object}
 * @example
 * initResAnimation({id:888,commprop:{}});
 */
export const initResAnimation = (options) => {
    const { id } = options;

    if (AnimationApis.hasAnimationManageMap(id)) {
        refreshOneResAnimationEl(id);
        return;
    }

    const config = getResAnimationConfig(options);
    resConfigHandler(config, options);
    return AnimationApis.initAnimationManage(config);
};

/**
 * @function name - refreshOneResAnimation
 * @description 重置动画数据 主要是因为tab标签模块 v-html插入 之前初始化的el会找不到
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param options {Object} 模块数据
 * @example
 * refreshOneResAnimation({id:888,commprop:{}});
 */
export const refreshOneResAnimation = (_module = {}) => {
    const { id } = _module;
    const moduleType = getModuleTypeForAnimation(id);
    let animationManage = AnimationApis.getAnimationManage(id);

    if (animationManage) {
        ANIMATION_TYPE_LIST.forEach((key) => {
            const animationConfig = animationManage.animation[key] || {};

            if (animationConfig && animationConfig.type > 0) {
                switch (key) {
                    case ANIMATION_TYPE.CONTENT:
                        animationConfig.el = getContentDomSeqList(moduleType, animationConfig.order, id);

                        if (
                            animationManage.animationVms[ANIMATION_TYPE.CONTENT] &&
                            animationManage.animationVms[ANIMATION_TYPE.CONTENT].length
                        ) {
                            animationManage.animationVms[ANIMATION_TYPE.CONTENT].forEach((value) => {
                                value.animationCount = 0;
                            });
                        }

                        animationManage.setAnimation();
                        animationManage.setAnimationElId();
                        break;

                    case ANIMATION_TYPE.EMPHASIS:
                    case ANIMATION_TYPE.ADMISSION:
                        animationManage.moduleAnimationStop();
                        animationConfig.el = $(`#module${id}`)[0];

                        if (animationManage.animationVms[ANIMATION_TYPE.EMPHASIS]) {
                            animationManage.animationVms[ANIMATION_TYPE.EMPHASIS].animationCount = 0;
                            animationManage.animationVms[ANIMATION_TYPE.EMPHASIS].animationState = 'pending';
                        }

                        animationManage.animationVms[ANIMATION_TYPE.ADMISSION] &&
                            (animationManage.animationVms[ANIMATION_TYPE.ADMISSION].animationCount = 0);
                        animationManage.setAnimation();
                        animationManage.setAnimationElId();
                    // eslint-disable-next-line no-fallthrough

                    // eslint-disable-next-line no-fallthrough
                    default:
                        break;
                }
            }
        });
    }
};
export const refreshOneResAnimationEl = (id) => {
    let animationManage = AnimationApis.getAnimationManage(id);
    const moduleType = getModuleTypeForAnimation(id);

    if (animationManage) {
        ANIMATION_TYPE_LIST.forEach((key) => {
            const animationConfig = animationManage.animation[key] || {};

            if (animationConfig && animationConfig.type > 0) {
                switch (key) {
                    case ANIMATION_TYPE.CONTENT:
                        animationConfig.el = getContentDomSeqList(moduleType, animationConfig.order, id);
                        animationManage.setAnimation();
                        animationManage.setAnimationElId();
                        break;

                    default:
                        animationConfig.el = $(`#module${id}`)[0];
                        animationManage.setAnimation();
                        animationManage.setAnimationElId();
                        break;
                }
            }
        });
    }
};

/**
 * @function name - refreshResAnimation
 * @description 暴露的重置动画数据方法
 * @author mikey.chuhui
 * @date 2021-03-05
 * @param options {Object} 模块数据集
 * @example
 * refreshResAnimation({999:{id:88,commProp:{}}});
 */

export const refreshResAnimation = (modules) => {
    if (isPlainObject(modules)) {
        Object.keys(modules).forEach((key) => {
            refreshOneResAnimation({ id: parseInt(key) });
        });
    }
};
export const refreshResAnimateModuleClassName = (id) => {
    let animationManage = AnimationApis.getAnimationManage(id);

    if (animationManage) {
        ANIMATION_TYPE_LIST.forEach((key) => {
            const animationConfig = animationManage.animation[key] || {};

            if (animationConfig && animationConfig.type > 0) {
                switch (key) {
                    case ANIMATION_TYPE.CONTENT:
                        handleDomSeqList(animationConfig);
                        break;

                    case ANIMATION_TYPE.ADMISSION:
                        $(animationConfig.el).addClass('animateModule');
                    // eslint-disable-next-line no-fallthrough

                    // eslint-disable-next-line no-fallthrough
                    default:
                        break;
                }
            }
        });
    }
};
