//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'sectionFooter',
    props: {
        rowid: Number,
        id: Number,
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapGetters(['info']),
        ...Vuex.mapState(['resImageRoot', 'oem', 'sections', 'beianCode', 'hasDomainSupportIpv6', 'isDemo', 'device']),
        showAdminLine() {
            return (
                (this.isOpenSupport && this.isOpenAdmin && !this.oem) ||
                (!this.sections.oemHiddenSupport && this.isOpenSupport && this.isOpenAdmin && this.oem)
            );
        },
        pattern() {
            return this.info.pattern || {};
        },
        footer() {
            return this.pattern.footer || {};
        },
        font() {
            return this.footer.font || {};
        },
        copyright() {
            return this.footer.copyright || {};
        },
        isOpenCopyright() {
            return this.copyright.type == 1;
        },
        isOpenSupport() {
            return this.footer.support == 1 && this.sections.supportText;
        },
        isOpenRecordation() {
            return this.footer.recordation == 1;
        },
        hasBeiAn() {
            return this.sections.hasBeiAn || this.icpRecord;
        },
        isOpenAdmin() {
            return this.footer.admin == 1;
        },
        isOpenIpv6Identy() {
            return this.footer.ipv6Identy == 1 && this.hasDomainSupportIpv6;
        },
        policeRecordImg() {
            return `/image/rimage/fromSite/v2/national_emblem.png`;
        },
        isOpenPoliceRecord() {
            return this.footer.mpsRecordation;
        },
        mpsRecordationSetting() {
            return this.footer.mpsRecordationSetting;
        },
        recordationSettingHtml() {
            return this.recordationSettingHtmlIsEmpty ? 'javascript:;' : this.mpsRecordationSetting.html;
        },
        recordationSettingHtmlIsEmpty() {
            return this.mpsRecordationSetting.html == null || this.mpsRecordationSetting.html == '';
        },
        icpRecord() {
            return this.footer.icpRecord || '';
        },
        beianCodeIsEmpty() {
            if (this.icpRecord) {
                return false;
            }
            return this.beianCode == null || this.beianCode == '';
        },
        isOemDemo() {
            return this.oem && this.isDemo;
        },
        isCN() {
            return this.$store.state.lcid === 2052;
        },
    },
    watch: {
        device() {
            this.$nextTick(() => {
                this.updateFooterFontCss(this.font);
            });
        },
    },
    mounted() {
        this.updateFooterFontCss(this.footer.font);
    },
    methods: {
        updateFooterFontCss(font = {}) {
            let styleEl = document.getElementById('footerFontStyle');
            if (styleEl == null) {
                styleEl = document.createElement('style');
                styleEl.id = 'footerFontStyle';
                document.head.appendChild(styleEl);
            }
            let css = this.getFontStyle(font);
            let hoverFontCss = this.device == 'pc' && font.y ? this.getColorStyle(font.sc) : '';
            let cssHtml = `.s_footer, .s_footer a { ${css}} \n .s_footer a:hover { ${hoverFontCss} }`;
            styleEl.innerHTML = cssHtml;
        },
        getFontStyle(font) {
            let fontStyle = '';
            if (font.y) {
                fontStyle = `
                    font-size: ${font.s}px; 
                    font-family: ${font.f}; 
                    font-style: ${font.i == 1 ? 'italic' : 'normal'}; 
                    font-weight: ${font.w == 1 ? 'bold' : 'normal'}; 
                    text-decoration: ${font.d == 1 ? 'underline' : 'none'};`;
                fontStyle += this.getColorStyle(font.c);
            }
            return fontStyle;
        },
        getColorStyle(color) {
            if (color == null) return '';
            return color.indexOf('linear-gradient') > -1
                ? `background-image: ${color}; color: transparent; -webkit-text-fill-color: transparent; -webkit-background-clip: text; `
                : `color: ${color} !important;`;
        },
    },
};
