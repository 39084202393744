//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../utils.js';

import { canAddFixTopClass, updateFixTopWebTitleStyle, FIX_TOP_TEMP_CLASS } from '@/site/shared/fixTopStyle/index.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';

import SiteTitle from './SiteTitle.vue';
const manageUse = {
    components: {},
};
export default {
    name: 'sectionWebsiteTitle',
    components: {
        ...manageUse.components,
        SiteTitle,
    },
    inject: ['isSysRow', 'rowNewMobiResponsiveConversion', 'rowNewResponsiveConversion'],
    props: {
        rowid: {
            type: [Number, String],
        },
    },
    data() {
        return {
            clickSum: 0,
            timer: null,
            editable: false,
            titleInstance: null,
        };
    },
    computed: {
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        ...Vuex.mapState('manage/editPanel', ['moduleEditorActiveId']),
        ...Vuex.mapState(['responsiveInfo']),
        ...Vuex.mapState([
            'device',
            'manageMode',
            'newResponsiveConversion',
            'templateNewResponsiveConversion',
            'openRemoveUrlArgs',
        ]),
        ...Vuex.mapState('header', {
            atTop: (state) => state.scrollToTop === true,
            isOpenFixedTop: (state) => state.isOpenFixedTop,
        }),
        pattern() {
            return this.info.pattern.title;
        },
        margin() {
            return this.pattern;
        },
        isSetLink() {
            return this.content.indexOf('</a>') > 0 && this.content.indexOf('href') > 0;
        },
        titleInfo() {
            return this.info.title;
        },
        isMobi() {
            return this.device == 'mobi';
        },
        projectid() {
            return 153;
        },
        contentStyle() {
            var pattern = this.pattern,
                style = {};

            //拖拽修改过高度
            if (!this.isMobi && pattern.lht == 1) {
                style.height = pattern.lh + 'px';
            }

            return style;
        },
        //fixme
        wrapClasses() {
            const classes = {};
            classes['jz_project__edit'] = this.manageMode && this.moduleEditorActiveId === this.projectid;
            if (this.titleInfo.rf === 1) {
                classes['responsive_fontSize'] = true;
            }

            return classes;
        },
        wrapStyle() {
            const style = {};
            return Object.assign(style, this.wrapMarginStyle);
        },
        wrapMarginStyle() {
            if (this.device == 'mobi') {
                return {};
            }

            var margin = this.margin;
            let unit = '%';

            if (this.isNewPcResponsiveMargin) {
                unit = 'vw';
            }

            let _marginTop = `${margin.mt * 100}${unit}`,
                _marginRight = `${margin.mr * 100}%`,
                _marginBottom = `${margin.mb * 100}${unit}`,
                _marginLeft = `${margin.ml * 100}%`;
            return {
                marginTop: _marginTop,
                marginRight: _marginRight,
                marginBottom: _marginBottom,
                marginLeft: _marginLeft,
            };
        },
        isNewPcResponsiveMargin() {
            return (
                !this.isMobi &&
                (this.newResponsiveConversion ||
                    (this.isSysRow && this.templateNewResponsiveConversion) ||
                    this.rowNewResponsiveConversion)
            );
        },
        /*------------jz-project end-----------------*/
        showLogoMargin() {
            return this.titleInfo.lt == 2 || this.titleInfo.lt == 3;
        },
        logoRate() {
            var rate = 100 / 86; // 默认logo的宽高比
            if (this.info.pattern.mLogo.t && this.isMobi && this.info.pattern.mLogo.fs) {
                return this.info.pattern.mLogo.lw / this.info.pattern.mLogo.lh;
            }

            if (this.titleInfo.lpid) {
                rate = this.titleInfo.lw / this.titleInfo.lh;
            }
            if (this.onFixedTopLogoMode && !this.equalFixedTopLogo) {
                rate = this.titleInfo.cflw / this.titleInfo.cflh;
            }

            return rate;
        },
        jumpOnclickStr() {
            return Comm.createUrlArgsOnclickStr(
                this.titleInfo.ll.reqArgs || '',
                this.openRemoveUrlArgs,
                this.hashRemoved
            );
        },
        logoContainerStyle() {
            var pattern = this.pattern,
                useWidth = 0,
                defaultMarginValue = 10, // 默认间距
                defaultMobiHeaderValue = 30, // 默认间距
                marginValue = 0, // 默认间距
                style = {};
            //
            const isCustomLogo = this.info.pattern.mLogo.t;
            if (this.isMobi) {
                style.width =
                    (isCustomLogo ? this.info.pattern.mLogo.s : defaultMobiHeaderValue) * this.logoRate + 'px';
                if (isCustomLogo) {
                    style['flex-shrink'] = 0;
                }
            } else {
                //拖拽修改过高度
                if (pattern.lht == 1) {
                    useWidth = pattern.lh <= this.titleInfo.lh ? pattern.lh : this.titleInfo.lh;
                    style.width = useWidth * this.logoRate + 'px';
                } else {
                    style.width = pattern.lh * this.logoRate + 'px';
                }
                if (this.showLogoMargin) {
                    marginValue = pattern.lmt == 1 ? pattern.lm : defaultMarginValue;
                    this.titleInfo.lt == 2
                        ? (style.marginRight = marginValue + 'px')
                        : (style.marginLeft = marginValue + 'px');
                }
            }

            return style;
        },
        canReszie() {
            // 非纯标题或者是被拖拽了高度的标题栏
            return this.titleInfo.lt != 0 || (this.titleInfo.lt == 0 && this.pattern.lht != 0);
            //return true;
        },
        logoTarget() {
            return this.titleInfo.ll.ib ? '_blank' : '_self';
        },
        fontList: {
            get() {
                return this.titleInfo.prop3 || [];
            },
            set(fontList) {
                this.titleInfo.prop3 = fontList;
            },
        },
        mobiSettingPattern() {
            return this.pattern.ms || {};
        },
        logoVisible() {
            let visible = this.titleInfo.lt != 0;
            // 手机视图下logo单独隐藏

            if (this.isMobi && this.responsiveInfo.pattern.displayContent.type) {
                return this.responsiveInfo.pattern.displayContent.logo;
            }

            return visible;
        },
        innerFeatures() {
            if (!this.titleInstance) {
                return [];
            }
            let innerFeatures = this.titleInstance.getFeatures();
            return innerFeatures;
        },
        isShowTitle() {
            return this.isMobi && this.info.pattern.displayContent.type
                ? this.info.pattern.displayContent.title
                : this.titleInfo.lt != 1;
        },
        content: {
            get() {
                return this.titleInfo.content;
            },
            set(value) {
                this.titleInfo.content = value;
            },
        },
        onFixedTopLogoMode() {
            if (this.isMobi) {
                return false;
            }
            return this.isOpenFixedTop && !this.atTop && this.titleInfo.cfl === 1 && this.titleInfo.cflUrl !== '';
        },
        equalFixedTopLogo() {
            return this.titleInfo.cflUrl === this.titleInfo.lp;
        },
        fixedLogoSrc() {
            if (this.isMobi) {
                return this.logoSrc;
            }
            return this.onFixedTopLogoMode ? this.titleInfo.cflUrl : this.titleInfo.lp;
        },
        logoSrc() {
            return this.isMobi && this.info.pattern.mLogo.t && this.info.pattern.mLogo.fs
                ? this.info.pattern.mLogo.p
                : this.titleInfo.lp;
        },
        isShowWebsiteTitle() {
            return this.isMobi ? (this.info.pattern.displayContent.type ? true : !this.pattern.h) : !this.pattern.h;
        },
        independentMobiClass() {
            return this.logoVisible && this.info.pattern.displayContent.type ? 'independent_mobi_logo' : '';
        },
    },
    watch: {
        'pattern.fts': {
            deep: true,
            immediate: false,
            handler() {
                if (this.manageMode) {
                    if (canAddFixTopClass('title')) {
                        $(this.$refs['websiteTitle']).addClass(FIX_TOP_TEMP_CLASS);
                        updateFixTopWebTitleStyle(true);
                    }
                }
            },
        },
    },
    created() {
        if (this.manageMode) {
            Site.initFontFace(this.fontList);
            this.initMobiTitleFont();
        }
    },
    mounted() {
        if (this.manageMode) {
            this.initTitleManage();

            const fontList = this.fontList;
            if (Array.isArray(fontList)) {
                const oldFontListResIds = fontList.map((e) => e.resId);
                this.titleInfo.oldFontListResIds = oldFontListResIds;
            } else {
                this.titleInfo.oldFontListResIds = [];
            }
        }

        if (canAddFixTopClass('title')) {
            $(this.$refs['websiteTitle']).addClass(FIX_TOP_TEMP_CLASS);
        }
        let orContentHeight = null;
        let featureListener = `f-MarginResize${this.projectid}`;
        eventBus.$off(`${featureListener}.start`).$on(`${featureListener}.start`, (data) => {
            if (this.canReszie && data.type === 'rightBottom') {
                orContentHeight = this.pattern.lh || 0;
            }
        });
        eventBus.$off(`${featureListener}.move`).$on(`${featureListener}.move`, (data) => {
            if (this.canReszie && data.type === 'rightBottom') {
                let realyContent = orContentHeight + Math.floor(data.moveY);
                //非模块minHeight 40；
                let minHeight = 40;

                if (realyContent > minHeight) {
                    this.pattern.lh = realyContent;
                }
                this.pattern.lht = 1;
            }
        });
    },
    destroy() {
        let featureListener = `f-MarginResize${this.projectid}`;
        eventBus.$off(`${featureListener}.start`);
        eventBus.$off(`${featureListener}.start`);
    },
    methods: {
        initTitleManage() {
            const info = this.$store.state.responsiveInfo;
            const titleInstance = new TitleManage({
                title: info.title,
                pattern: info.pattern.title,
            });
            titleInstance.registerFeatures();
            this.titleInstance = titleInstance;

            this.$watch(
                () => this.device,
                () => this.setFeatureData(),
                {
                    immediate: true,
                }
            );
        },
        setFeatureData() {
            const titleInstance = this.titleInstance;
            let innerFeatures = titleInstance.getFeatures();
            for (let i in innerFeatures) {
                if (titleInstance[`set${innerFeatures[i].name}`]) {
                    titleInstance[`set${innerFeatures[i].name}`](this, this.device);
                }
            }
        },
        handleTitleJump() {
            const jump = () => {
                if (!this.isSetLink && !this.editable) {
                    window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp';
                }
            };
            if (!this.manageMode) {
                jump();
                return;
            }

            // 点击的时候，先不做跳转，判断用户是不是双击
            this.clickSum++;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                jump();
            }, 300);
            if (this.clickSum >= 2) {
                this.clickSum = 0;
                // 当双击的时候，阻止页面跳转
                clearTimeout(this.timer);
            }
        },
        handleEditStatus(editable) {
            this.editable = editable;
            if (!editable) {
                const $editor = $(this.$refs.fkeditor.$el);
                const $fontsEl = $editor.find('*[style*=font-family], font');
                Site.checkFontFaceChange($fontsEl);
                const fontList = Site.mergeFonts($editor);
                this.fontList = fontList;
            }
        },
        initMobiTitleFont() {
            const { t, fs } = this.mobiSettingPattern;
            if (t != 1 || fs <= 0) return;
            const $mobiStyle = $('#mobiTitleFontStyle');
            if ($mobiStyle.length > 0) {
                $mobiStyle.remove();
            }
            $('head').append(`
    			<style id="mobiTitleFontStyle">
    				.jz_screen_mobi .jz_website_title.jz_website_title .fkeditor-wrap * {
    					font-size: ${fs}px !important;
    				}
    			</style>
    		`);
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
