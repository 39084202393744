//
//
//
//
//
//
//

import { LAYOUT, STYLE } from '@/components/modules/timeline/constants';
import TextVertical from './textVertical.vue';
import TextVerticalCross from './textVerticalCross.vue';
import TextHorizontal from './textHorizontal.vue';
import Carousel from './carousel.vue';
const manageUse = {
    components: {},
};

export default {
    name: 'Timeline',
    components: { ...manageUse.components },
    provide() {
        return {
            module: this.module,
        };
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        ...Vuex.mapGetters(['isMobi']),
        moduleStyle() {
            return this.module.prop0;
        },
        timelineItems() {
            return this.module.prop1;
        },
        moduleLayout() {
            return this.module.prop2;
        },
        styleComponent() {
            const component =
                {
                    [STYLE.ONE]: {
                        [LAYOUT.ONE]: TextVertical,
                        [LAYOUT.TWO]: this.isMobi ? TextVertical : TextVerticalCross,
                    },
                    [STYLE.TWO]: {
                        [LAYOUT.ONE]: TextHorizontal,
                        [LAYOUT.TWO]: TextHorizontal,
                    },
                    [STYLE.THREE]: {
                        [LAYOUT.ONE]: Carousel,
                        [LAYOUT.TWO]: Carousel,
                    },
                }[this.moduleStyle]?.[this.moduleLayout] ?? null;

            return component;
        },
    },
    watch: {
        moduleStyle() {
            this.module.prop2 = 0;
        },
    },
};
