import { render, staticRenderFns } from "./jzBanner.vue?vue&type=template&id=50862866&"
import script from "./jzBanner.vue?vue&type=script&lang=js&"
export * from "./jzBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/faier/git/res/responsive/v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50862866')) {
      api.createRecord('50862866', component.options)
    } else {
      api.reload('50862866', component.options)
    }
    module.hot.accept("./jzBanner.vue?vue&type=template&id=50862866&", function () {
      api.rerender('50862866', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/grid/banner/jzBanner.vue"
export default component.exports