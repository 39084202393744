import { Message } from '@shared/manage/componMessage/index.js';
import { logDog } from '@/site/shared/log/index.js';
import { delNews as delNewsRequest, setNewsListToModule } from '@api/news';
import { createFnBeforeCheckSaveBar } from '@shared/manage/popupChangedBodyWindow/index.js';
import { recordModuleAndRefreshPage } from '@shared/manage/scrollTop/index.js';

/**
 * @function name - selectNewsListItem
 * @kind Root
 * @description 选择某个文章列表模块中的文章
 * @param [moduleId =0] {number} 模块id
 */

export const selectNewsListItem = (moduleId = 0) => {
    Fai.popupWindow({
        title: '直接添加',
        frameSrcUrl:
            'rmanage/newsList.jsp?id=' +
            moduleId +
            '&colId=' +
            window._store.state.colId +
            '&extId=' +
            window._store.state.extId +
            '&ram=' +
            Math.random(),
        width: '684',
        height: '537',
        saveBeforePopup: false,
        version: 2,
        closeNoComfirm: true,
    });
};

/**
 * @function name - delNews
 * @kind Root
 * @description 删除某篇文章
 * @param [newsId =0] {number} 文章id
 */
export const delNews = (newsId = 0) => {
    return new Promise((resolve, reject) => {
        Message.warning('正在删除……');
        delNewsRequest({
            id: newsId,
        })
            .then((data) => {
                if (data.success) {
                    Message.warning('删除成功');
                    resolve();
                } else {
                    Message.warning(data.msg);
                    reject();
                }
            })
            .catch(() => {
                Message.warning('系统繁忙，请稍候重试');
                reject();
            });
    });
};

/**
 * @function name - editNews
 * @kind Root
 * @description 编辑某个文章
 * @param [newsId =0] {number} 文章id
 */
export const editNews = (newsId = 0) => {
    logDog(200762, 3);
    return new Promise((resolve) => {
        createFnBeforeCheckSaveBar(() => {
            Fai.popupWindow({
                title: '修改文章',
                frameSrcUrl: 'manage_v2/newsEdit.jsp?_isResponsive=true&id=' + newsId,
                width: '920',
                height: '705',
                saveBeforePopup: false,

                closeFunc(result) {
                    if (!result) {
                        return;
                    }

                    const { newDataList = [] } = $.parseJSON(result);

                    if (newDataList.length < 1) {
                        return;
                    }
                    resolve();
                },

                callArgs: 'newsList',
                version: 2,
            });
        })();
    });
};

/**
 * @function name - addNews
 * @kind Root
 * @description 添加文章
 * @returns {Promise}
 */
export const addNews = (...groupIds) => {
    const filteredGroupIds = groupIds.filter((e) => !!e);
    return new Promise((resolve) => {
        const action = () => {
            Fai.popupWindow({
                title: '添加文章',
                frameSrcUrl: `manage_v2/newsEdit.jsp?_isResponsive=true${
                    groupIds.length ? '&mGroupTypeIds=' + JSON.stringify(filteredGroupIds) : ''
                }`,
                width: '920',
                height: '705',
                saveBeforePopup: false,

                closeFunc(result) {
                    if (!result) {
                        return;
                    }

                    const { newDataList = [] } = $.parseJSON(result);

                    if (newDataList.length < 1) {
                        return;
                    }
                    resolve(newDataList);
                },

                callArgs: 'newsList',
                version: 2,
            });
        };
        createFnBeforeCheckSaveBar(action)();
    }).catch(() => {});
};

/**
 * @function name - delNewsListItem
 * @kind Root
 * @description 删除某个文章列表模块内的某个文章
 * @param [moduleId =0] {number} 模块id
 * @param [newsId =0] {number} 文章id
 */
export const delNewsListItem = (moduleId = 0, newsId = 0) => {
    const module = window._store.state.modules['module' + moduleId];

    if (typeof module !== 'object') {
        Message.warning('该模块不存在！');
        return;
    }

    if (module.style !== 17) {
        Message.warning('该模块不是文章列表模块！');
        return;
    }

    const _delNews = () => {
        new window.Vue().$createDialog({
            content: '确定将该文章放入回收站吗？',
            confirmButton: {
                text: '确 定',
            },
            cancelButton: {
                text: '取 消',
            },

            onConfirm() {
                delNews(newsId).then(() => {
                    recordModuleAndRefreshPage(moduleId);
                });
            },
        });
    };

    logDog(200762, 4);
    createFnBeforeCheckSaveBar(_delNews)();
};

/**
 * @function name - delNewsResultItem
 * @kind Root
 * @description 删除文章筛选模块内的某个文章
 * @param [newsId =0] {number} 文章id
 */
export const delNewsResultItem = (newsId = 0) => {
    const module = window._store.state.modules['module3'];

    if (typeof module !== 'object') {
        Message.warning('该模块不存在！');
        return;
    }

    if (module.style !== 5) {
        Message.warning('该模块不是文章筛选模块！');
        return;
    }

    const _delNews = () => {
        delNews(newsId).then(() => {
            recordModuleAndRefreshPage(3);
        });
    };

    new window.Vue().$createDialog({
        content: '确定将该文章放入回收站吗？',
        confirmButton: {
            text: '确 定',
        },
        cancelButton: {
            text: '取 消',
        },

        onConfirm() {
            createFnBeforeCheckSaveBar(_delNews)();
        },
    });
};

/**
 * 保存文章列表模块的文章id
 * @param { Number } moduleId - 模块id
 * @param { Number[] } newsIdList - 文章id列表
 */
export function saveNewsListToModule(moduleId, newsIdList = []) {
    return new Promise((resolve, reject) => {
        setNewsListToModule({
            moduleId,
            newsIdList: JSON.stringify(newsIdList),
        })
            .then((res) => {
                if (res.success) {
                    recordModuleAndRefreshPage(module.id);
                } else {
                    Message.warning(res.msg);
                }
            })
            .then(resolve)
            .catch(reject);
    });
}
