//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        itemList: Array,
        value: {
            type: Array,
            default: () => [],
        },
        module: Object,
        requiredIcon: Boolean,
    },
    data() {
        return {
            //管理态不能修改item的value，所以要用一个临时的值储存，跟siteFormData分开
            manageVal: [],
        };
    },
    computed: {
        currentVal() {
            return this.value;
        },
    },
    methods: {
        isChecked(item) {
            return this.currentVal.includes(item);
        },
        toggleItem(item) {
            let newVal = this.currentVal.slice();
            if (this.isChecked(item)) {
                newVal = newVal.filter((oldItem) => oldItem !== item);
            } else {
                newVal.push(item);
            }

            this.$emit('input', newVal);
        },
    },
};
