//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ProductNavModule } from '@shared/visitor/productNav/index.js';
import { Comm } from '@/components/utils.js';
import emptyTipsMixin from './emptyTipsMixin.js';
const manageUse = {
    components: {},
};
import { deepExtendClone } from '@jz/utils';

//获取多级列表
function getMultiLevelList(list, parentId) {
    parentId = parentId || 0;
    return list
        .filter(function (item) {
            //过滤其他元素，只保留当前级
            return item.parentId === parentId;
        })
        .map(function (item) {
            //获取子元素
            item.children = getMultiLevelList(list, item.id);
            return item;
        });
}
const { getComputedTextColor, isNewModuleAfter20210707, getStyleDataByDevice, bindFlag, extend } = Comm;

export default {
    name: 'moduleProductNav',
    components: {
        ...manageUse.components,
    },
    mixins: [emptyTipsMixin],
    props: ['id', 'module'],
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'colId']),
        openType: bindFlag(0x1), // false: 新窗口 true: 当前窗口

        moduleClasses() {
            const classes = {
                [`module_category_nav_style${this.style}`]: true,
            };
            if (this.isNewModuleAfter20210707) {
                classes.new_module_after_20210707 = true;
            }
            return classes;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        style() {
            return this.module.prop0;
        },
        showTips() {
            return this.displayList.length === 0;
        },
        isSelectGroupAll() {
            return this.module.prop2 === 0;
        },
        normalizationDisplayList() {
            try {
                const listName = this.isSelectGroupAll ? 'displayListAll' : 'displayList';
                const displayList = this.module[listName].filter((item) => item.id !== 0);
                return this.isSelectGroupAll && !this.module.style === 39
                    ? displayList
                    : displayList.filter((item) => item.check);
            } catch (error) {
                console.error(error);
                return [];
            }
        },

        displayList() {
            return getMultiLevelList(deepExtendClone(this.normalizationDisplayList));
        },
        isOnlyOneLevel() {
            return !this.normalizationDisplayList.some((item) => item.parentId !== 0);
        },
        pattern() {
            return this.module.privatePattern[this.device];
        },
        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },
        pcPrivatePattern() {
            return this.module.privatePattern.pc;
        },
        fontPattern() {
            return this.pattern['cft'] || {};
        },
        isNewModule() {
            return this.module.isNewModule;
        },
        linePattern() {
            return this.isMobi && this.mobiPrivatePattern.cl.t === 0 && this.isNewModule
                ? this.pcPrivatePattern.cl
                : this.pattern.cl;
        },
        lineHeightPatten() {
            return this.isMobi && this.mobiPrivatePattern.clht === 0 && this.isNewModule
                ? this.pcPrivatePattern.clh
                : this.pattern.clh;
        },
        lineHeightPattenType() {
            return this.isMobi && this.mobiPrivatePattern.clht === 0 && this.isNewModule
                ? this.pcPrivatePattern.clht
                : this.pattern.clht;
        },
        itemStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};

            if (this.lineHeightPattenType === 1) {
                style['line-height'] = `${this.lineHeightPatten}px`;
            }

            if (this.linePattern.t === 1 && (this.style === 0 || this.isMobi)) {
                const borderStyle = ['solid', 'dotted', 'dashed'][this.linePattern.s];
                style['border-bottom'] = `${this.linePattern.w}px ${borderStyle} ${this.linePattern.c}`;
            }

            return style;
        },

        linkLineHeightAndBorder() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            if (this.lineHeightPattenType === 1) {
                style['line-height'] = `${this.lineHeightPatten}px`;
            }
            if (this.linePattern.t === 1 && (this.style === 0 || this.isMobi)) {
                const borderStyle = ['solid', 'dotted', 'dashed'][this.linePattern.s];
                style['border-bottom'] = `${this.linePattern.w}px ${borderStyle} ${this.linePattern.c}`;
            }
            return style;
        },
        algin() {
            let al = this.pattern.al;
            // 手机视图，如果选择默认，则使用pc的数据
            if (this.isMobi && this.module.privatePattern.mobi.al === 0) {
                al = this.module.privatePattern.pc.al;
            }
            return al;
        },
        alginStyle() {
            const style = {};
            if (this.style === 1 || !this.isOnlyOneLevel) {
                return style;
            }

            style['text-align'] = ['', 'left', 'center'][this.algin];

            return style;
        },
        firstLinkStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            extend(style, this.linkLineHeightAndBorder);

            return style;
        },
        secondLinkStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            extend(style, this.linkLineHeightAndBorder);

            return style;
        },
        thirdLinkStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            extend(style, this.linkLineHeightAndBorder);

            return style;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        fontColor() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};

            //样式一那个点的颜色

            if (this.fontPattern.y === 1) {
                extend(style, getComputedTextColor(getFirstGradientColor(this.fontPattern.c)));
            }

            return style;
        },
        fontColorV2() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.cff,
                mobiData: this.module.privatePattern.mobi.cff,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            if (font.y === 1) {
                extend(style, getComputedTextColor(getFirstGradientColor(font.c)));
            }
            if (!this.isNewModuleAfter20210707 && font.y === 0) {
                extend(style, this.fontColor);
            }
            Comm.extend(style, this.alginStyle);

            return style;
        },
        fontHoverStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            if (this.isMobi) {
                return style;
            }

            //样式一那个点的颜色

            if (this.fontPattern.y === 1) {
                extend(style, getComputedTextColor(getFirstGradientColor(this.fontPattern.hc)));
            }

            return style;
        },
        fontHoverStyleV2() {
            if (!this.manageMode) {
                return {};
            }
            const style = {};
            if (this.isMobi) {
                return style;
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.cff,
                mobiData: this.module.privatePattern.mobi.cff,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            if (font.y === 1) {
                extend(style, getComputedTextColor(getFirstGradientColor(font.hc)));
            }

            if (!this.isNewModuleAfter20210707 && font.y === 0) {
                extend(style, this.fontHoverStyle);
            }
            return style;
        },

        lib() {
            return this.module.prop2 == 0 ? -1 : this.module.prop4;
        },
        splitLineStyle() {
            const style = {};
            if (!this.manageMode) {
                return {};
            }

            if (this.linePattern.t === 1 && this.style === 1) {
                const borderStyle = ['solid', 'dotted', 'dashed'][this.linePattern.s];
                style['border-right'] = `${this.linePattern.w}px ${borderStyle} ${this.linePattern.c}`;
            }

            return style;
        },
    },
    mounted() {
        this.controller = new ProductNavModule(this.module.id);
    },
    methods: {
        clickAddHandler() {
            manageCategoryNav(this.module.id, this.module.style, this.module.style === 39, true);
        },
        clickManageHandler() {
            manageCategoryNav(this.module.id, this.module.style, this.module.style === 39, false);
        },
        clickSelectGroupHandler() {
            editModule(this.id);
        },
        url(item) {
            const { id: groupId, href } = item;
            if (this.manageMode && this.module.style === 18) {
                return (
                    Site.preprocessUrl({ path: '', oldPath: '' }) +
                    `pr.jsp?groupId=${groupId}&libId=${this.module.prop4}`
                );
            }
            return href;
        },
        hasChildren(item) {
            return item.children && item.children.length > 0;
        },
        listHasChildren(list) {
            for (const item of list) {
                if (this.hasChildren(item)) {
                    return true;
                }
            }
            return false;
        },
        childrenList(item) {
            return item.children || [];
        },
        getLinkTarget(item) {
            return (this.module.style === 39 ? item.target : !this.openType) ? '_blank' : '';
        },
        getSelectClass(item) {
            return this.module.style === 39 && item.id === this.colId ? 'category_select_item' : '';
        },
    },
};
