var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search_result_news", class: _vm.newsClassName },
    [
      _vm.other.showResultType
        ? _c(
            "div",
            { staticClass: "search_result_title jz_theme_font_color" },
            [_vm._v(_vm._s(_vm.LS.searchResultArticle))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "news_list_wrap" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: item.id,
                  staticClass:
                    "news_list_item_line news_list_item_line_pic jz_hover jz_theme_font_hover_color",
                  class: { result_calc_item: !_vm.noPicStyle },
                  style: _vm.newsItemStyle,
                },
                [
                  _c("a", {
                    staticClass: "news_list_item_link",
                    attrs: {
                      href: _vm.setQueryString(
                        item.url,
                        "fromColId",
                        _vm.colId
                      ),
                      target: "_blank",
                      onclick: _vm.createOnclickStr(item),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "news_list_item_inner" }, [
                    !_vm.noPicStyle
                      ? _c("div", { staticClass: "news_list_img_box" }, [
                          _c("div", {
                            directives: [
                              {
                                name: "lazyload2",
                                rawName: "v-lazyload2",
                                value: { id: _vm.module.id, src: item.imgPath },
                                expression:
                                  "{ id: module.id, src: item.imgPath }",
                              },
                            ],
                            staticClass:
                              "search_result_news_img result_pic_item_padding--top result_pic_item--scale",
                            style: _vm.genPicItemStyle(item),
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "news_list_item_content" }, [
                      _c("div", { staticClass: "news_list_item_header" }, [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "result_highlight_node news_list_item_title jz_theme_font_hover_color jz_hover",
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.decodeHtml(item.title)) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "news_list_item_date" }, [
                          _vm._v(_vm._s(_vm.decodeHtml(item.dateStr))),
                        ]),
                      ]),
                      _vm._v(" "),
                      item.summary
                        ? _c("div", { staticClass: "news_list_item_summery" }, [
                            _vm._v(_vm._s(_vm.decodeHtml(item.summary))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "news_list_item_ext_line" }, [
                        _c("div", { staticClass: "news_list_item_group" }, [
                          _vm.noPicStyle &&
                          _vm.other.showClassification &&
                          item.groups.length > 0
                            ? _c(
                                "div",
                                { staticClass: "category-wrapper" },
                                [
                                  _vm._l(item.groups, function (groupItem) {
                                    return [
                                      groupItem
                                        ? _c(
                                            "a",
                                            {
                                              key: groupItem.id,
                                              staticClass: "category-item",
                                              attrs: {
                                                href: _vm.setQueryString(
                                                  groupItem.url,
                                                  "fromColId",
                                                  _vm.colId
                                                ),
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.decodeHtml(
                                                      groupItem.name
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.noPicStyle && index < _vm.list.length - 1
                ? _c("div", {
                    key: index,
                    staticClass: "news_list_parting_line jz_parting_line",
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news_list_item_arrow_link" }, [
      _c("div", { staticClass: "news_list_item_arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }