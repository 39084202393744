//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    model: {
        prop: 'keyword',
        event: 'input',
    },
    props: {
        keyword: {
            type: String,
        },
        placeholder: {
            type: String,
        },
    },
    data() {
        return {};
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e.target.value);
        },
        emit() {
            this.$emit('handleEnter');
        },
        handleClearKeyword() {
            this.$emit('input', '');
        },
        cancel() {
            history.back();
        },
    },
    computed: {
        ...Vuex.mapState(['LS']),
    },
};
