//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import analysisQs from '@/site/shared/analysisQs/index.js';
import { openSiteForm } from '@shared/visitor/form/index.js';
import { Comm } from '../../utils';
const { bindFlag, decodeHtmlAttr, decodeUrl, createUrlArgsOnclickStr } = Comm;
import { getProductResultByPagination, getPropNameByLib } from '@api/product';
const manageUse = {};
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
export default {
    name: 'ProductResult',
    style: 10,
    components: {
        PaginationComponent,
    },
    props: ['id', 'module'],
    data() {
        const groupList = this.module.groupList,
            newConditionSelectLists = this.module.newConditionSelectLists,
            propNameList = this.module.propNameList || [],
            mobiSelectedPropList = this.module.mobiSelectedPropList,
            showPirceSet = this.module.showPirceSet,
            keywordCond = this.module.keywordCond,
            choiceCurrencyVal = this.module.choiceCurrencyVal,
            decodeCurrencyVal = this.module.decodeCurrencyVal,
            productResultSearchTip = this.module.productResultSearchTip,
            productResultFilterPriceSubmit = this.module.productResultFilterPriceSubmit,
            noResultFound = this.module.noResultFound,
            productResultSearch = this.module.productResultSearch,
            moduleName = this.module.name;
        const data = {
            groupList,
            newConditionSelectLists,
            propNameListByLib: [],
            propNameList,
            propsNamesByLib: [],
            propNameMap: {},
            mobiSelectedPropList,
            showList: this.module.prop1.s,
            showPirceSet,
            keywordCond,
            choiceCurrencyVal,
            productResultSearchTip,
            productResultFilterPriceSubmit,
            noResultFound,
            productResultSearch,
            moduleName,
            links: this.module.showLibs ? this.module.showLibs : [],
            resultLibId: this.module.showLibId ? this.module.showLibId : 0, //展示一个产品库
            productList: this.module.list || [],
            currentPage: 1,
            getLoading: false,
            arrowSwitch: false,
            decodeCurrencyVal,
            mobiFilterShow: false,
        };
        return data;
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'LS', 'isMaterialLazyLoad']),
        ...Vuex.mapGetters(['hashRemoved']),
        formBtnText() {
            return this.module.globalProductFormText;
        },
        flagPagination: bindFlag(0x4),
        hidePropNameSetting: bindFlag(0x20),
        paginationPageSize() {
            return this.module.prop2;
        },
        paginationStyleIndex() {
            return this.module.prop4;
        },
        propOrderCondLists() {
            let list = this.module.propOrderCondLists || [];
            return list.filter((item) => {
                return item.fieldKey == 'mallPrice' ? this.showPirceSet && !item.hide : !item.hide;
            });
        },
        list() {
            return this.module.list
                .map(({ id }) => this.productList.find((product) => product.id == id))
                .filter((product) => typeof product !== 'undefined');
        },
        ...Vuex.mapState(['device']),
        type() {
            return this.module.prop0;
        },
        model() {
            return this.module.privatePattern[this.device];
        },
        pcModel() {
            return this.module.privatePattern['pc'];
        },
        isShowProductName() {
            return this.pcModel.spn;
        },
        isNameWrap() {
            return this.pcModel.nl;
        },
        textEllipsisClass() {
            return this.isNameWrap ? '' : 'text-ellipsis';
        },
        textAlignTypeClass() {
            let textAlignType = this.pcModel.ta;
            if (this.module.prop0 == 1 || textAlignType == 0) {
                return '';
            }
            return textAlignType == 1 ? 'result_prop_align-left' : 'result_prop_align-center';
        },
        productStyle() {
            if (!this.manageMode) {
                return;
            }
            var style = {};
            if (this.device === 'pc') {
                var rate = '25%',
                    defaultMargin = '';
                defaultMargin = '20px';
                if (this.module.prop0 === 3) {
                    defaultMargin = '22px';
                }
                rate = this.module.prop0 == 1 ? '100%' : '25%';
                if (this.model.pmt == 1) {
                    style.margin = this.model.pmv / 2 + 'px ' + this.model.pmh / 2 + 'px';
                    defaultMargin = this.model.pmh + 'px';
                }
                if (this.module.prop0 !== 1) {
                    if (this.model.rlt == 1) {
                        rate = Math.floor((100 * 100) / this.model.rl) / 100 + '%';
                        if (this.model.pmt == 1) {
                            style.width = 'calc(' + rate - (this.model.pmv + 'px') + ')';
                        }
                    }
                }
                style.width = 'calc(' + rate + ' - ' + defaultMargin + ')';
            }

            if (this.device === 'mobi') {
                if (this.module.prop0 === 0) {
                    // 样式一支持单独设置手机每行个数
                    if (this.model.rlt === 1) {
                        style.width = `${100 / this.model.rl - 3.6}%`;
                    }
                }
            }

            return style;
        },
        productImgSize() {
            var style = {};
            if (this.module.prop0 == 0) {
                //手机高度跟电脑
                if (!this.manageMode) {
                    return;
                }
                if (this.module.privatePattern.pc.pht == 1) {
                    style.paddingBottom = Math.floor(this.module.privatePattern.pc.ph * 10000) / 100 + '%';
                }
            } else {
                //手机高度不跟电脑
                if (this.notComputedStyle) {
                    return;
                }
                if (this.model.pst == 1) {
                    // 样式三 图片高度
                    style.width = Math.floor(this.model.psw * 10000) / 100 + '%';
                    style.paddingBottom = Math.floor(this.model.psh * 10000) / 100 + '%';
                }
            }
            return style;
        },
        productPropSize() {
            if (this.notComputedStyle) {
                return;
            }
            var style = {};
            if (this.module.prop0 != 0) {
                if (this.model.pst == 1) {
                    style.width = 'calc( 100% - ' + Math.floor(this.model.psw * 10000) / 100 + '% - 20px )';
                }
            }
            return style;
        },

        paginationPageCount() {
            const totalSize = this.module.totalSize;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },

        flagIsBlank: bindFlag(0x1),
        hideFilter: bindFlag(0x2),
        propsHideFilter: bindFlag(0x8),
        imgJump() {
            return this.flagIsBlank ? '_self' : '_blank';
        },
        moduleClasses() {
            const classes = {
                [`jz_product_result_${this.type}`]: true,
                mobi_fliter_panel_activity: this.mobiFilterShow,
            };

            if (this.type == 0) {
                if (this.pcModel.rlt == 1 && (this.pcModel.rl == 1 || this.pcModel.rl == 3)) {
                    classes['product_result_mobi_full'] = true;
                }
            }

            return classes;
        },
        isNonePicStyle() {
            return this.module.prop0 === 3;
        },
        notComputedStyle() {
            return this.device !== 'pc' || !this.manageMode;
        },
        propList() {
            return this.module.finalShowPropListByLib;
        },
        paramterLibId() {
            return this.module.blob0.paramterLibId;
        },
        conListHeigth() {
            if (this.module.enVer) {
                if (this.links.length && this.arrowSwitch) {
                    return 'filter_condition_list_height';
                }
                if (this.links.length && !this.arrowSwitch) {
                    return 'filter_condition_list_fixheight';
                }
            }
            return '';
        },
        choosePlib() {
            if (this.arrowSwitch) {
                return 'plib_content';
            }
            return '';
        },
        mobiPlibName() {
            if (this.links.length > 0) {
                let linkObj = this.links.find((item) => {
                    return item.lid == this.resultLibId;
                });
                if (!linkObj) {
                    return '';
                }
                return linkObj.name;
            }
            return '';
        },
        priceFilterParamVisible() {
            try {
                return this.module.prop3.s[`lib_${this.resultLibId}`].indexOf(11) !== -1; // 11是价格参数Id
            } catch (error) {
                return false;
            }
        },
        priceSetVisible() {
            return this.showPirceSet && this.priceFilterParamVisible;
        },
        productGroupList() {
            return this.module.groupList;
        },
        flagHideFormButton: Comm.bindFlag(0x10),
        isShowFormButton() {
            return this.module.openProductForm && !this.flagHideFormButton;
        },
        sortType() {
            return this.module.blob0.customSort.sortType;
        },
        sortKey() {
            return this.module.blob0.customSort.sortKey;
        },
    },
    watch: {
        // 检测pageSize改变重新获取数据
        paginationPageSize() {
            this.handlePaginationPageChange(this.currentPage);
        },
        paginationStyleIndex() {
            this.currentPage = 1;
            this.handlePaginationPageChange(this.currentPage);
        },
        flagPagination() {
            this.currentPage = 1;
            this.handlePaginationPageChange(this.currentPage);
        },
        sortType() {
            this.currentPage = 1;
            this.handlePaginationPageChange(this.currentPage);
            this.changeSortCond();
        },
        sortKey() {
            this.currentPage = 1;
            this.handlePaginationPageChange(this.currentPage);
            this.changeSortCond();
        },
        arrowSwitch: {
            handler() {
                this.loadPropNamesByLib();
            },
        },
        'module.prop3': {
            deep: true,
            handler() {
                this.loadPropNamesByLib();
            },
        },
        device(val) {
            if (val === 'mobi') {
                this.getPropNameByLib();
            }
        },
    },
    created() {
        if (this.device === 'mobi' && !this.$isServer) {
            this.getPropNameByLib();
        }
        if (this.device === 'pc') {
            this.setPropNameMap();
            this.loadPropNamesByLib();
        }
    },
    mounted() {},
    methods: {
        changeSortCond() {
            const list = this.module.propOrderCondLists;
            if (Array.isArray(list)) {
                list.forEach((item) => {
                    if (item.fieldKey === this.sortKey) {
                        item.select = true;
                        item.desc = this.sortType === 0;
                    } else {
                        item.select = false;
                    }
                });
            }
        },
        filterSaveClick() {
            var minVal = this.$refs.resultPirceMin.value,
                maxVal = this.$refs.resultPirceMax.value,
                proFilterCond = this.module.proFilterCond,
                $selects = $('.filter_condition_item_wrap .mobi_fliter_cond_select'),
                $initSelectLid = $('.filter_item_plib'),
                $selectLid = $('.filter_item_plib .mobi_fliter_cond_select'),
                minValInt,
                maxValInt,
                tmpPirce,
                priceArea = '',
                tmpCondList,
                condList;
            if (!proFilterCond) {
                proFilterCond = {};
            }

            if (!proFilterCond.props) {
                tmpCondList = [];
                proFilterCond.props = tmpCondList;
            }

            var tmpProFilterCond = $.extend(true, {}, proFilterCond);
            tmpProFilterCond.props = [];
            condList = tmpProFilterCond.props;

            if (minVal || maxVal) {
                minValInt = parseInt(minVal || 0);
                maxValInt = parseInt(maxVal);

                if (minVal > maxVal && !isNaN(minValInt) && !isNaN(maxValInt)) {
                    tmpPirce = minValInt;
                    minValInt = maxValInt;
                    maxValInt = tmpPirce;
                }

                if (minVal) {
                    priceArea += minValInt;
                } else {
                    priceArea += '0,' + maxValInt;
                }
                if (minVal && maxVal) {
                    priceArea += ',' + maxValInt;
                }
                priceArea = '[' + priceArea + ']';

                // isExist = false;
                // $.each(condList, function(i, n){
                // 	if (n.k == 11){
                // 		n.v = priceArea;
                // 		isExist = true;
                // 	}
                // });
                // if (!isExist){
                var propCond = {};
                propCond.k = 11;
                propCond.v = priceArea;
                condList.push(propCond);
                // }
            }
            if ($selects.length > 0) {
                $.each($selects, function (i, n) {
                    var $item = $(n).find('span'),
                        _k = parseInt($item.attr('_k')),
                        _v = $item.attr('_v');

                    // isExist = false;
                    // $.each(condList, function(i, n){
                    // 	if (n.k == _k){
                    // 		n.v = _v;
                    // 		isExist = true;
                    // 	}
                    // });
                    // if (!isExist){
                    var propCond = {};
                    propCond.k = _k;
                    propCond.v = _v;
                    condList.push(propCond);
                    // }
                });
            }

            // 产品库
            if ($initSelectLid.length > 0) {
                tmpProFilterCond.lid = parseInt($initSelectLid.attr('initLid'));
            }
            if ($selectLid.length > 0) {
                tmpProFilterCond.lid = parseInt($selectLid.attr('lid'));
            }
            let pfcPrefix = window._store.state.openRemoveUrlArgs ? '' : 'pr.jsp';
            if (window._store.state.manageMode) {
                location.href = pfcPrefix + '?pfc=' + Fai.encodeUrl($.toJSON(tmpProFilterCond)) + location.hash;
            } else {
                Fai.top.location.href =
                    pfcPrefix + '?pfc=' + Fai.encodeUrl($.toJSON(tmpProFilterCond) + Fai.top.location.hash);
            }
        },
        filterResetClick() {
            $('.mobi_fliter_cond_select').removeClass('mobi_fliter_cond_select');
            $('.filter_pirce_area input').val('');
        },
        filterPirceClick() {
            var minVal = this.$refs.resultPirceMin.value,
                maxVal = this.$refs.resultPirceMax.value,
                proFilterCond = this.module.proFilterCond,
                winLocation = location,
                minValInt,
                maxValInt,
                tmpPirce,
                priceArea = '',
                tmpCondList;

            if (!(minVal || maxVal)) {
                return;
            }
            minValInt = parseInt(minVal || 0);
            maxValInt = parseInt(maxVal);

            if (minVal > maxVal && !isNaN(minValInt) && !isNaN(maxValInt)) {
                tmpPirce = minValInt;
                minValInt = maxValInt;
                maxValInt = tmpPirce;
            }
            if (minVal) {
                priceArea += minValInt;
            } else {
                priceArea += '0,' + maxValInt;
            }
            if (minVal && maxVal) {
                priceArea += ',' + maxValInt;
            }
            priceArea = '[' + priceArea + ']';

            if (!proFilterCond) {
                proFilterCond = {};
            }

            if (!proFilterCond.props) {
                tmpCondList = [];
                proFilterCond.props = tmpCondList;
            }

            var tmpProFilterCond = $.extend(true, {}, proFilterCond);
            var condList = tmpProFilterCond.props;
            var isExist = false;
            $.each(condList, function (i, n) {
                if (n.k == 11) {
                    n.v = priceArea;
                    isExist = true;
                }
            });
            if (!isExist) {
                var propCond = {};
                propCond.k = 11;
                propCond.v = priceArea;
                condList.push(propCond);
            }

            let pfcPrefix = window._store.state.openRemoveUrlArgs ? '' : 'pr.jsp';
            winLocation.href = pfcPrefix + '?pfc=' + Fai.encodeUrl($.toJSON(tmpProFilterCond)) + winLocation.hash;
        },
        fliterResultConditonWrapClick() {
            // 点击到灰色背景块，收起面板
            if (!this.mobiFilterShow) {
                return;
            }
            this.mobiFilterShow = false;
            $('body').removeClass('jz_body_no_scroll');
        },
        mobiFilterClick() {
            if (this.mobiFilterShow) {
                return;
            }
            this.mobiFilterShow = true;
            $('body').addClass('jz_body_no_scroll');
        },
        searchBtnClick() {
            var searchContent = this.$refs.result_rearch_input.value,
                proFilterCond = this.module.proFilterCond;
            var _winLocation = location;
            searchContent = Fai.encodeUrl($.trim(searchContent));
            let pfcPrefix = window._store.state.openRemoveUrlArgs ? '' : 'pr.jsp';
            if (!searchContent) {
                _winLocation.href = pfcPrefix + Fai.encodeUrl($.toJSON(proFilterCond));
            }
            if (proFilterCond) {
                _winLocation.href =
                    pfcPrefix + '?keywordCond=' + searchContent + '&pfc=' + Fai.encodeUrl($.toJSON(proFilterCond));
            } else {
                _winLocation.href = pfcPrefix + '?keywordCond=' + searchContent;
            }
        },
        goAllResult(url) {
            window.open(url, '_self');
        },
        isPriceVisible(productItem = {}) {
            // 判断prop中价格allowed属性，限制访客查看权限
            let notAllowed = false;
            const propListByLib = this.propList[`lib_${productItem.lid}`];
            propListByLib.some((item) => {
                if (item.fieldKey == 'mallPrice' && !item.allowed) {
                    notAllowed = true;
                    return true;
                }
            });
            if (notAllowed) {
                return false;
            }
            return !!productItem.pirceValue;
        },
        loadPropNamesByLib() {
            let propIdOrderList = this.module.prop3.o[`lib_${this.resultLibId}`];
            const visibleIds = this.module.prop3.s[`lib_${this.resultLibId}`];
            let arr = [];
            Object.keys(this.propNameMap).forEach((propId) => {
                propId = parseInt(propId, 10);
                const prop = this.propNameMap[propId];
                if (
                    prop &&
                    prop.name &&
                    visibleIds.indexOf(propId) !== -1 &&
                    propId !== 11 // 11是价格参数, 不应该展示, 已有固定位置的价格区间展示
                ) {
                    const sort = propIdOrderList.findIndex((o) => o === propId);
                    prop.sort = sort === -1 ? 9999 : sort; // 9999表示排序在最后
                    prop.type = propId;
                    arr.push(prop);
                }
            });
            arr.sort((a, b) => a.sort - b.sort);
            const newPropList = arr.filter((item) => item.type !== 12); // 暂时过滤掉市场价参数
            this.propsNamesByLib = newPropList;
        },
        setPropNameMap() {
            for (let prop of this.propNameList) {
                if (prop && prop.propValueList) {
                    const id = Array.isArray(prop.propValueList) ? prop.propValueList[0]['k'] : void 0;
                    this.propNameMap[id] = prop;
                }
            }
        },
        openForm(productInfo) {
            const { productFormId } = productInfo;
            if (!productFormId || productFormId < 0) return;
            openSiteForm?.(productFormId);
        },
        handlePaginationPageChange(page) {
            if (this.getLoading) return;
            this.getLoading = true;
            let pageSize = 300;
            if (this.flagPagination) {
                pageSize = this.paginationPageSize;
            }
            this.getProductResultByPagination(page, pageSize)
                .then((res) => {
                    this.getLoading = false;
                    const newList = res.data;
                    if (this.paginationStyleIndex == 2 && this.currentPage !== 1) {
                        // 如果是加载更多样式，则需要插入数组
                        this.productList = this.productList.concat(newList);
                    } else {
                        this.productList = newList;
                    }
                })
                .catch(() => {
                    this.getLoading = false;
                });
        },
        getProductResultByPagination(page = 1, pageSize = 300) {
            // qs为了处理静态化的问题
            const qs = this.module.qs || '';
            return new Promise((resolve, reject) => {
                const data = {
                    page,
                    pageSize,
                    moduleId: this.id,
                    ...this.analysisQs(decodeURIComponent(qs)),
                    sortKey: this.sortKey,
                    sortType: this.sortType,
                };
                getProductResultByPagination(data)
                    .then((data) => (data.success ? resolve(data) : reject(data)))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        analysisQs(qs) {
            return analysisQs(qs);
        },
        addPageGetParam(willAddObj) {
            const pageParamObj = {};
            if (location.search) {
                const pageParamArr = location.search.replace('?', '').split('&');
                pageParamArr.forEach((e) => {
                    const [key, value] = e.split('=');
                    if (value) pageParamObj[key] = value;
                });
            }
            const paramObj = {
                ...pageParamObj,
                ...willAddObj,
            };
            let paramStr = '';
            for (const key in paramObj) {
                if (Object.prototype.hasOwnProperty.call(paramObj, key)) {
                    paramStr += `&${key}=${paramObj[key]}`;
                }
            }
            return paramStr.replace('&', '?');
        },
        doubleDecodeUrl(url) {
            return decodeHtmlAttr(decodeUrl(url));
        },
        groupName(item) {
            if (!item.id) {
                //不是分类
                return item.groupName;
            }
            const group = this.productGroupList.find((group) => group.id === item.id);

            if (group) {
                return group.grpupName;
            } else {
                //没找到分类
                return item.groupName;
            }
        },
        setModuleTitle() {
            sessionStorage['moduleTitle'] = this.moduleName;
        },
        refreshLink() {
            /* eslint-disable-next-line no-undef */
            let val = $(select)[0].options[$(select)[0].selectedIndex].value;
            let linkObj = this.links.find((item) => {
                return item.lid == val;
            });
            window.location.href = linkObj.link;
        },
        swithArrow(val) {
            this.arrowSwitch = !!val;
        },
        changelibName(lid) {
            this.resultLibId = lid;
            this.arrowSwitch = false;
            Vue.nextTick(() => {
                $('.filter_condition_item_wrap .mobi_fliter_cond_select').removeClass('mobi_fliter_cond_select');
            });
        },
        getPropNameByLib() {
            getPropNameByLib({
                moduleId: this.module.id,
                pfc: $.toJSON(this.module.proFilterCond),
                groupId: Fai.getUrlParam(location.href, 'groupId'),
            })
                .then((result) => {
                    if (!result.success) {
                        Fai.ing('系统错误');
                        return;
                    }
                    this.propNameListByLib = result.list;
                    this.setPropNameMap();
                    this.loadPropNamesByLib();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onclickStr(item) {
            const { pdReqArgs } = item;
            return createUrlArgsOnclickStr(pdReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        isShowPrice(item) {
            const showList = this.showList['lib_' + item.lid];
            return this.isPriceVisible(item) && showList.includes(11);
        },
        isShowMarketPrice(item) {
            const showList = this.showList['lib_' + item.lid];
            const propListShow = this.propList['lib_' + item.lid]?.find((p) => p.id === 12);
            return this.isPriceVisible(item) && showList.includes(12) && propListShow;
        },
        formatPrice(item) {
            if (item.actualPrice) {
                return item.actualPrice;
            } else {
                return /\./.test(`${item.mallPrice}`) ? item.mallPrice.toFixed(2) : item.mallPrice.toFixed(2);
            }
        },
        formatMarketPrice(item) {
            return /\./.test(`${item.mallMarketPrice}`)
                ? item.mallMarketPrice.toFixed(2)
                : item.mallMarketPrice.toFixed(2);
        },
        hovermenu: manageUse.hovermenu || function () {},

        isLinkProduct(productType) {
            return productType == 4;
        },
        genPicItemStyle(item) {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.picPath}')`;
            }

            return { ...style };
        },
    },
};
