var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowVideoBg
    ? _c("div", { staticClass: "jz_web_row--video-bg" }, [
        _c("video", {
          ref: "VideoWrapper",
          staticClass: "jz_web_row--video",
          attrs: {
            src: _vm.src,
            "data-original": _vm.rowBackground.vpath,
            loop: "loop",
            autoplay: "autoplay",
            muted: "muted",
          },
          domProps: { muted: true },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }