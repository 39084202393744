import { refreshOneResAnimationEl } from './animation/index.js';
import { digGet } from '@jz/utils';
import { AnimationApis } from '@jz/biz-shared';
export const animationMixin = {
    data() {
        return {
            lazyLoaded: false,
        };
    },
    computed: {
        ...Vuex.mapState('manage', ['editPanel']),
    },
    methods: {
        refreshOneResAnimation() {
            if (this.manageMode && !this.lazyLoaded && digGet(this, 'module.commProp.cal[0].t')) {
                this.$store.dispatch('manage/pauseDataWatch');
                // 管理态 做了懒加载 所以内容动画的话 需要重新refresh一下
                const aniamationManageVm = AnimationApis.getAnimationManage(this.module.id);

                if (digGet(aniamationManageVm, 'animationVms.csontent.length') > 0 || this.editPanel.active) {
                    this.lazyLoaded = true;
                } else {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            refreshOneResAnimationEl(this.module.id);
                        }, 100);
                    });
                }
            }
        },
    },
};
