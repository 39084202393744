//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MobiLeftArrowIcon',
    methods: {
        arrowLeftHandler(e) {
            this.$emit('arrowLeft', e);
        },
    },
};
