//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        itemList: Array,
        value: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
    },
    data() {
        return {
            //管理态不能修改item的value，所以要用一个临时的值储存，跟siteFormData分开
            manageVal: '',
        };
    },
    computed: {
        currentVal() {
            return this.value;
        },
    },
    methods: {
        selectItem(item) {
            this.$emit('input', item);
        },
    },
};
