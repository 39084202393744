/* eslint-disable */
(function ($) {
    var instanceNumber = 0;

    $.fn.uploadify = function (opts) {
        var itemTemp =
            '\
        <div id="${fileID}" class="uploadify-queue-item">\
            <div class="uploadify-progress">\
                <div class="uploadify-progress-bar"></div>\
            </div>\
            <span class="up_filename">${fileName}</span>\
            <span class="uploadbtn">上传</span>\
            <span class="delfilebtn">删除</span>\
        </div>\
        ';

        var defaults = {
            fileTypeExts: '', //允许上传的文件类型，格式'*.jpg;*.doc'
            uploader: '', //文件提交的地址
            auto: false, //是否开启自动上传
            method: 'post', //发送请求的方式，get或post
            multi: false, //是否允许选择多个文件
            formData: {}, //发送给服务端的参数，格式：{key1:value1,key2:value2}
            fileObjName: 'filedata', //在后端接受文件的参数名称
            fileSizeLimit: 2048, //允许上传的文件大小，单位KB
            showUploadedPercent: true, //是否实时显示上传的百分比，如20%
            showUploadedSize: false, //是否实时显示已上传的文件大小，如1M/2M
            buttonText: '', //上传按钮上的文字
            removeTimeout: 1000, //上传完成后进度条的消失时间
            itemTemplate: itemTemp, //上传队列显示的模板
            breakPoints: false, //是否开启断点续传
            isBurst: true, //是否分片,由于表单上传的断点续传有问题,先关掉分片的逻辑。
            fileSplitSize: 1024 * 1024, //断点续传的文件块大小，单位Byte，默认1M
            isResizeImg: true, //是否压缩图片 默认开启
            isConcurrent: false, //文件上传是否并行
            isSvgJudge: false, //是否对svg做判断操作
            isSvgToPng: false, //是否要Svg转成Png
            getFileSizeUrl: '', //获取文件大小url
            getUploadSizeParam: null, //获取上传大小的额外参数
            fileSizeLimitTips: '单个文件大小不能超过', //超出文件大小的文字提示
            getUploadedSize: function getFileUploadedSize(file) {
                var defaultsParam = {
                    fileMd5: file.md5,
                    fileSplitSize: option.fileSplitSize,
                    fileName: file.name,
                    totalSize: file.size,
                };
                var data = $.extend(defaultsParam, option.getUploadSizeParam);
                //类型：function，自定义获取已上传文件的大小函数，用于开启断点续传模式，可传入一个参数file，即当前上传的文件对象，需返回number类型
                var size = 0;
                $.ajax({
                    url: option.getFileSizeUrl,
                    async: false,
                    type: 'post',
                    dataType: 'json',
                    data: data,
                    success: function (data) {
                        if (data.success) {
                            size = data.size;
                        } else {
                            size = -1;
                            option.msgInfo('error', data.msg);
                        }
                    },
                });
                return size;
            },
            msgInfo: function (type, msg) {
                Fai.ing(msg);
            },
            saveUploadedSize: null, //类型：function，自定义保存已上传文件的大小函数，用于开启断点续传模式，可传入两个参数：file：当前上传的文件对象，value：已上传文件的大小，单位Byte
            saveInfoLocal: false, //用于开启断点续传模式，是否使用localStorage存储已上传文件大小
            onUploadStart: null, //上传开始时的动作
            onUploadProgress: null, //上传中的动作
            onUploadSuccess: null, //上传成功的动作
            onUploadComplete: null, //上传完成的动作
            onUploadError: null, //上传失败的动作
            onInit: null, //初始化时的动作
            onCancel: null, //删除掉某个文件后的回调函数，可传入参数file
            onSvgSelect: null, //选择svg  在onSelect前执行
            onSelect: null, //选择文件后执行的动作，可传入参数files，文件列表
        };

        var option = $.extend(defaults, opts);

        var uploadMethods = {
            //格式
            mimetypeMap: {
                zip: ['application/x-zip-compressed'],
                jpg: ['image/jpg'],
                svg: ['image/svg+xml'],
                jpeg: ['image/jpeg'],
                png: ['image/png'],
                gif: ['image/gif'],
                bmp: ['image/bmp'],
                doc: ['application/msword'],
                xls: ['application/vnd.ms-excel'],
                docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
                ppt: ['application/vnd.ms-powerpoint '],
                pptx: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
                mp3: ['audio/mp3'],
                mp4: ['video/mp4'],
                pdf: ['application/pdf'],
                exe: ['application/x-msdownload'],
                html: ['text/html'],
                ico: ['image/x-icon'],
                txt: ['text/plain'],
                flv: ['.flv'],
                swf: ['application/x-shockwave-flash'],
                //image/vnd.adobe.photoshop
                psd: ['.psd'],
                //application/illustrator
                ai: ['.ai'],
            },
            setUploader: function (url) {
                option.uploader = url;
            },
            //将文件的单位由bytes转换为KB或MB，若第二个参数指定为true，则永远转换为KB
            formatFileSize: function (size, byKB) {
                if (size > 1024 * 1024 && !byKB) {
                    size = (Math.round((size * 100) / (1024 * 1024)) / 100).toString() + 'MB';
                } else {
                    size = (Math.round((size * 100) / 1024) / 100).toString() + 'KB';
                }
                return size;
            },
            //根据文件序号获取文件
            getFile: function (index, files) {
                for (var i = 0; i < files.length; i++) {
                    if (files[i].index == index) {
                        return files[i];
                    }
                }
                return false;
            },
            //根据后缀名获得文件的mime类型
            getMimetype: function (name) {
                return this.mimetypeMap[name];
            },
            getFileTypes: function (str) {
                var result = [];
                var arr1 = str.split(';');
                for (var i = 0, len = arr1.length; i < len; i++) {
                    result.push(arr1[i].split('.').pop());
                }
                return result;
            },
            //根据配置的字符串，获得上传组件accept的值
            getAcceptString: function (str) {
                var self = this;
                var types = this.getFileTypes(str);
                var result = [];
                for (var i = 0, len = types.length; i < len; i++) {
                    var mime = self.getMimetype(types[i]);
                    if (mime) {
                        result.push(mime);
                    }
                }
                return result.join(',');
            },
            statble: 90,
            uploadProgress: function (tmpoptions) {
                if (fileObj.fileFilter[0]) {
                    if (tmpoptions.status == 'start') {
                        var tr2 =
                            '\
                        <div id="progressPanel' +
                            tmpoptions.id +
                            '" class="mission">\
                            <div id="progress' +
                            tmpoptions.id +
                            '" class="progress" style="width:1%;"></div>\
                            <div class="progressTextPanel">\
                                <div class="progressText">' +
                            tmpoptions.title +
                            '</div>\
                                <div class="progressPercent">0%</div>\
                            </div>\
                        </div>\
                        ';

                        if ($('#progressPanel' + tmpoptions.id).length < 1) {
                            $('#img_' + tmpoptions.id).append(tr2);
                        }
                        this.statble = 90;
                    } else if (tmpoptions.status == 'ing') {
                        var tmp = tmpoptions.percent;
                        $('#progressPanel' + tmpoptions.id + ' .progressText').text(tmpoptions.title);
                        var fileName = fileObj.fileFilter[0].name;
                        var fileType = uploadMethods.getFileTypes(fileName)[0].toLowerCase();
                        var mime =
                            uploadMethods.getMimetype(fileType) == null ? '' : uploadMethods.getMimetype(fileType)[0];

                        if (tmpoptions.percent > this.statble && tmpoptions.percent != 100) {
                            var size = Math.random() / 5;
                            //进度条取整不保留两位小数。toFixed(2)
                            this.statble += parseFloat(size.toFixed(0));
                            this.statble = parseFloat(this.statble.toFixed(0));
                            tmp = this.statble;
                        }
                        if (mime.indexOf('image') >= 0) {
                            $('#progress' + tmpoptions.id).css('width', tmp + '%');
                            $('#progressPanel' + tmpoptions.id + ' .progressPercent').html(tmp + '%');
                        } else {
                            $('#progress' + tmpoptions.id).css('width', tmpoptions.percent + '%');
                            $('#progressPanel' + tmpoptions.id + ' .progressPercent').html(tmpoptions.percent + '%');
                        }
                        $('#progress' + tmpoptions.id).attr('width', tmpoptions.percent);

                        if (mime.indexOf('image') >= 0) {
                            if (tmpoptions.percent > 99) {
                                this.dynamicProgress(tmpoptions.id);
                            }
                        }
                    } else if (tmpoptions.status == 'end') {
                        $('#progressPanel' + tmpoptions.id + ' .progressText').text(tmpoptions.title);
                        $('#progressBody_' + tmpoptions.id).remove();
                        $('#progressWrap_' + tmpoptions.id).remove();
                    } else if (tmpoptions.status == 'error') {
                        $('#progress' + tmpoptions.id).addClass('progressError');
                    }
                }
            },
            dynamicProgress: function (id) {
                if (this.statble == 90) {
                    return;
                }
                this.statble += 0.5;
                if (this.statble > 100) {
                    return;
                }
                this.statble = parseFloat(this.statble.toFixed(2));
                $('#progress' + id).css('width', this.statble + '%');
                $('#progressNum' + id).html(this.statble + '%');
                setTimeout(function () {
                    this.dynamicProgress(id);
                }, 1000).bind(this);
            },
            //判断是否png，jpg，jpeg
            isResizeImg: function (file) {
                if (
                    file.type != '' &&
                    ~'image/jpeg,image/jpg,image/png'.indexOf(file.type) &&
                    file.size > 4 * 1024 * 1024
                ) {
                    return true;
                }
                return false;
            },
            //判断是否png，jpg，jpeg小于4M不压缩
            isNoResizeImg: function (file) {
                if (~'image/jpeg,image/jpg,image/png'.indexOf(file.type) && file.size <= 4 * 1024 * 1024) {
                    return true;
                }
                return false;
            },
            //判断是否图片类型（png、jpg、svg）
            isImg: function (file) {
                if (
                    ~'image/jpeg,image/jpg,image/png,image/svg+xml'.indexOf(file.type) &&
                    file.type != undefined &&
                    file.type != ''
                ) {
                    return true;
                }
                return false;
            },
            //压缩上传，只压缩jpg，jpeg，png
            resizeImg: function (formData, maxWidth, maxHeight, originalFile, xhr) {
                var self = this;
                var jic = {
                    compress: function (source_img_obj, quality, output_type) {
                        var cvs = document.createElement('canvas');
                        //naturalWidth真实图片的宽度
                        cvs.width = source_img_obj.naturalWidth;
                        cvs.height = source_img_obj.naturalHeight;
                        var scale = cvs.width / cvs.height;
                        cvs.width = maxWidth;
                        cvs.height = maxWidth / scale;
                        var ctx = cvs
                            .getContext('2d')
                            .drawImage(
                                source_img_obj,
                                0,
                                0,
                                source_img_obj.naturalWidth,
                                source_img_obj.naturalHeight,
                                0,
                                0,
                                maxWidth,
                                maxWidth / scale
                            );
                        var newImageData = cvs.toDataURL(output_type, quality / 100);
                        var result_image_obj = new Image();
                        result_image_obj.src = newImageData;
                        return result_image_obj;
                    },
                };
                var reader = new FileReader();
                reader.readAsDataURL(originalFile);
                reader.onload = function (e) {
                    // var tmpBase64Url = e.target.result;
                    var i = document.getElementById('imgCut');
                    var img = document.createElement('img');
                    img.id = 'imgCut_' + originalFile.index;
                    img.src = event.target.result;
                    i.appendChild(img);
                    img.onload = function (e) {
                        var quality = 90;
                        //i.src = jic.compress(i,quality,originalFile.type).src;
                        var blob = dataURLtoBlob(jic.compress(img, quality, originalFile.type).src);
                        if (blob.size < originalFile.size) {
                            originalFile.source = blob;
                            formData.totalSize = blob.size;
                        } else {
                            formData.totalSize = originalFile.size;
                        }
                        if (formData.totalSize > option.fileSizeLimit) {
                            var limitSize = option.fileSizeLimit / 1024 / 1024;
                            //目前此判断无作用，压缩后还超过5M的话，让后端返回错误
                            self.msgInfo('warning', option.fileSizeLimitTips + limitSize + 'MB');
                        } else {
                            //重新复制totalsize，用户后端检测
                            formData.initSize = 0;
                            formData.complete = true;
                            fileObj.sendBlob(fileObj.url, xhr, originalFile, formData);
                        }
                        i.removeChild(img);
                    };
                };

                function dataURLtoBlob(dataurl) {
                    var arr = dataurl.split(','),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new Blob([u8arr], { type: mime });
                }
            },
            genUploadUrl: function (data) {
                var url = option.uploader;
                for (const key in data) {
                    url += '&' + key + '=' + data[key];
                }
                fileObj.url = url;
            },
        };

        var fileObj = null;
        this.each(function () {
            var _this = $(this);
            //先添加上file按钮和上传列表
            instanceNumber++;
            // var instanceNumber = $('.uploadify').length + 1;
            var inputStr =
                '<input id="select_btn_' +
                instanceNumber +
                '" class="selectbtn" style="display:none;" type="file" name="fileselect[]"';
            inputStr += option.multi ? ' multiple' : '';
            inputStr += ' accept="';
            if (option.fileTypeExts == null || option.fileTypeExts == '' || option.fileTypeExts == '*') {
                inputStr += '*.*';
            } else {
                inputStr += uploadMethods.getAcceptString(option.fileTypeExts);
            }

            inputStr += '"/>';
            inputStr +=
                '<a id="file_upload_' + instanceNumber + '-button" href="javascript:void(0)" class="uploadify-button">';
            inputStr += option.buttonText;
            inputStr += '</a>';
            var uploadFileListStr = '<div id="file_upload_' + instanceNumber + '-queue" class="uploadify-queue"></div>';
            _this.append(inputStr);
            //$("#content").append(uploadFileListStr);
            _this.addClass('uploadify');
            //创建文件对象
            fileObj = {
                uploadParam: option.post_params,
                uploadAllowed: true,
                instanceNumber: instanceNumber,
                fileInput: _this.find('.selectbtn'), //html file控件
                //uploadFileList : _this.find('.uploadify-queue'),
                uploadFileList: $(document).find('.uploadify-queue'),
                url: option.uploader, //ajax地址
                fileFilter: [], //过滤后的文件数组
                maxSize: 0, //最大容量
                nowStatSize: 0, //上传成功后的容量
                uploadOver: false, //一次上传是否真正结束，用于断点续传的情况
                init: function () {
                    //文件选择控件选择
                    if (this.fileInput.length > 0) {
                        this.fileInput.change(function (e) {
                            fileObj.funGetFiles(e);
                        });
                    }

                    //点击上传按钮时触发file的click事件
                    _this.find('.uploadify-button').on('click', function () {
                        _this.find('.selectbtn').trigger('click');
                    });

                    option.onInit && option.onInit();
                },
                //获取选择文件，file控件
                funGetFiles: function (e) {
                    var self = this;
                    // 获取文件列表对象
                    var files = e.target.files;

                    var paramObj = {
                        showTip: false,
                    };
                    var tmpDeferArr = [];
                    var tmpFileArr = [];
                    var showTip = false;
                    for (var itemIndex = 0; itemIndex < files.length; itemIndex++) {
                        (function (index) {
                            var nowFile = files[index];
                            if (/svg/.test(nowFile.type) && option.isSvgJudge) {
                                option.onSvgSelect && option.onSvgSelect(tmpFileArr, tmpDeferArr, nowFile, paramObj);
                            } else {
                                tmpFileArr.push(nowFile);
                            }
                        })(itemIndex);
                    }

                    $.when
                        .apply($, tmpDeferArr)
                        .done(function () {
                            if (paramObj.showTip && paramObj.tipText) {
                                // option.msgInfo("warning",'svg内形状过于复杂，上传失败');
                                option.msgInfo('warning', paramObj.tipText);
                            }

                            files = tmpFileArr;
                            if (files.length > 0) {
                                //过滤文件 筛选不符合的文件
                                files = self.filter(files);
                                for (var i = 0, len = files.length; i < len; i++) {
                                    self.fileFilter.push(files[i]);
                                }
                                if (self.fileFilter.length == 0) {
                                    return;
                                }
                                self.funDealFiles(files);
                                return self;
                            }
                        })
                        .fail(function () {
                            console.log('err');
                        });
                },
                //选择文件组的过滤方法
                filter: function (files) {
                    var arr = [];
                    var typeFlag = false;
                    var typeArray = uploadMethods.getFileTypes(option.fileTypeExts);
                    if (option.fileTypeExts == '' || option.fileTypeExts == '*.*' || option.fileTypeExts == null) {
                        typeFlag = true;
                    }
                    if (typeArray.length > 0) {
                        //解决多次选择同一个超出大小限制不出现提示的问题
                        var tmpCount = 0;
                        for (var i = 0, len = files.length; i < len; i++) {
                            var thisFile = files[i];
                            if (option.isResizeImg && uploadMethods.isImg(thisFile)) {
                                if (uploadMethods.isResizeImg(thisFile)) {
                                    if (thisFile.size > option.fileSizeLimit) {
                                        var limitSize = option.fileSizeLimit / 1024 / 1024;
                                        option.msgInfo('warning', option.fileSizeLimitTips + limitSize + 'MB');
                                        tmpCount++;
                                        continue;
                                    }
                                }
                                //判断是否png，jpg，jpeg小于4M不压缩 不压缩的图片大小不能超过2M
                                else if (!uploadMethods.isNoResizeImg(thisFile)) {
                                    if (thisFile.size > 2 * 1024 * 1024) {
                                        option.msgInfo('warning', '单个gif,bmp文件大小不能超过2MB');
                                        tmpCount++;
                                        continue;
                                    }
                                }
                            } else if (thisFile.size > option.fileSizeLimit) {
                                var limitSize = option.fileSizeLimit / 1024 / 1024;
                                option.msgInfo('warning', option.fileSizeLimitTips + limitSize + 'MB');
                                tmpCount++;
                                continue;
                            }

                            var filename = thisFile.name;
                            var str_split = filename.indexOf('.');
                            var suffix = '';
                            if (str_split != -1) {
                                suffix = filename.substring(str_split + 1);
                            }

                            if (option.siteFree && suffix == 'apk') {
                                option.msgInfo('error', '上传失败：您的版本目前不支持上传apk文件。');
                                continue;
                            }

                            if (!typeFlag) {
                                if ($.inArray(thisFile.name.split('.').pop().toLowerCase(), typeArray) >= 0) {
                                    arr.push(thisFile);
                                } else {
                                    option.msgInfo('warning', LS.mobiFormSubmitFileUploadNotAllow);
                                }
                            } else {
                                arr.push(thisFile);
                            }
                        }
                        if (tmpCount === files.length && tmpCount != 0) {
                            //清除文件选择框中的已有值
                            fileObj.fileFilter = [];
                            fileObj.fileInput.val('');
                        }
                    }
                    return arr;
                },
                //选中文件的处理与回调
                funDealFiles: function (files) {
                    var fileCount = $(document).find('.uploadify-queue .uploadify-queue-item').length; //队列中已经有的文件个数
                    for (var i = 0, len = files.length; i < len; i++) {
                        var thisFile = files[i];
                        files[i].index = ++fileCount;
                        files[i].id = 'fileupload_' + instanceNumber + '_' + files[i].index;

                        files[i].md5 = $.md5(
                            files[i].name + files[i].size + files[i].type + files[i].lastModified + new Date().getTime()
                        );

                        // 不走分片的 请从外部传入参数控制
                        if (option.isBurst) {
                            //分片规则
                            if (files[i].size >= 0 && files[i].size < (20 << 10) << 10) {
                                //大于100M小于500M 分片10M
                                option.fileSplitSize = (5 << 10) << 10;
                            } else if (files[i].size >= (20 << 10) << 10 && files[i].size < (50 << 10) << 10) {
                                option.fileSplitSize = (5 << 10) << 10;
                            } else if (files[i].size >= (50 << 10) << 10 && files[i].size < (100 << 10) << 10) {
                                option.fileSplitSize = (8 << 10) << 10;
                            } else if (files[i].size >= (100 << 10) << 10 && files[i].size < (500 << 10) << 10) {
                                option.fileSplitSize = (10 << 10) << 10;
                            } else {
                                option.fileSplitSize = (15 << 10) << 10;
                            }
                        }
                    }

                    //执行选择回调
                    this.funSelect(files);
                    return this;
                },
                //文件选择后
                funSelect: function (files) {
                    if (option.onSelect && !option.onSelect(files)) {
                        fileObj.fileFilter = [];
                        fileObj.fileInput.val('');
                        return;
                    }
                    var filesLength = files.length,
                        batchesArr = [],
                        curBatchesIndex = 0;
                    if (option.isConcurrent) {
                        this.maxSize = option.getMaxSize(); //最大容量
                        this.nowStatSize = option.getStatSize(); //上传成功后的容量
                        if (option.inBatches && filesLength > option.inBatches) {
                            //分批上传 --->非断点续传<---
                            batchesArr = splitArr(option.inBatches, files); //分批后数组
                            var firstBatches = batchesArr[0];

                            option.batchesCount = 0;
                            for (var i = 0; i < firstBatches.length; i++) {
                                selectImg(firstBatches[i], funUploadFileCallback);
                            }
                        } else {
                            for (var i = 0; i < filesLength; i++) {
                                selectImg(files[i]);
                            }
                        }
                    } else {
                        selectImg(files[0]);
                    }
                    function splitArr(N, Q) {
                        var R = [],
                            F;
                        for (F = 0; F < Q.length; ) R.push(Q.slice(F, (F += N)));
                        return R;
                    }

                    function funUploadFileCallback() {
                        //上一批已经传输ok了，开始下一批次
                        if (option.batchesCount == option.inBatches) {
                            setTimeout(function () {
                                option.batchesCount = 0;
                                curBatchesIndex++;
                                var curBatches = batchesArr[curBatchesIndex];
                                if (curBatches) {
                                    for (var i = 0; i < curBatches.length; i++) {
                                        curBatches[i] && selectImg(curBatches[i], funUploadFileCallback);
                                    }
                                }
                            }, 2000);
                        }
                    }

                    function selectImg(file, callback) {
                        var uploadedSize = fileObj.funGetUploadedSize(file);
                        if (uploadedSize == -1) {
                            fileObj.fileInput.val('');
                            return;
                        }
                        //toFixed(2) -->不保留小数
                        var initWidth = ((uploadedSize / file.size) * 100).toFixed(0);
                        var tmpoptions = {
                            status: 'start',
                            id: file.id,
                            title: '准备上传...',
                            percent: initWidth,
                            size: file.size,
                        };
                        uploadMethods.uploadProgress(tmpoptions);
                        if (option.breakPoints) {
                            tmpoptions = {
                                status: 'ing',
                                id: file.id,
                                title: '上传中...',
                                percent: initWidth,
                            };
                            uploadMethods.uploadProgress(tmpoptions);
                            fileObj.funUploadFile(file, uploadedSize, callback);
                        }
                    }
                },
                //文件上传
                funUploadFile: function (file, uploadedSize, callback) {
                    var xhr = null;
                    var originalFile = file; //保存原始为切割的文件
                    var complete = false; //是否最后一块分片
                    var thisfile = $(document).find('#fileupload_' + instanceNumber + '_' + file.index);

                    try {
                        xhr = new XMLHttpRequest();
                    } catch (e) {
                        xhr = ActiveXobject('Msxml12.XMLHTTP');
                    }

                    if (option.breakPoints) {
                        var fileName = file.name,
                            fileId = file.id,
                            fileIndex = file.index,
                            fileMd5 = file.md5; //先保存原来的文件名称
                        var fileSize = file.size;

                        //对文件进行切割，并保留原来的信息
                        if (fileSize >= option.fileSplitSize) {
                            //如果文件本身小于分片大小 不切割
                            file = originalFile.slice(uploadedSize, uploadedSize + option.fileSplitSize);
                            complete = false;
                            fileObj.lastSplit = false;

                            file.name = fileName;
                            file.id = fileId;
                            file.index = fileIndex;
                            file.md5 = fileMd5;
                        } else {
                            complete = true;
                            fileObj.lastSplit = true;
                        }
                    }

                    if (xhr.upload && uploadedSize !== false) {
                        // 上传中
                        xhr.upload.addEventListener(
                            'progress',
                            function (e) {
                                option.onUploadProgress && option.onUploadProgress(e, file, originalFile);
                                fileObj.onProgress(file, e.loaded, originalFile.size);
                            },
                            false
                        );

                        // 文件上传成功或是失败
                        xhr.onreadystatechange = function () {
                            //监控
                            xhr.newStateChange && xhr.newStateChange();
                            var batchesSuccess = true;
                            if (xhr.readyState == 4) {
                                originalFile.uploadOver = true;
                                if (xhr.status == 200) {
                                    var returnData = JSON.parse(xhr.responseText);
                                    //将文件块数据更新到本地记录
                                    if (returnData.success) {
                                        if (option.breakPoints) {
                                            //更新已上传文件大小，保存到本地
                                            uploadedSize += option.fileSplitSize;
                                            fileObj.funSaveUploadedSize(
                                                originalFile,
                                                uploadedSize >= fileSize ? fileSize : uploadedSize
                                            );
                                            //继续上传其他片段
                                            if (option.isResizeImg && uploadMethods.isResizeImg(originalFile)) {
                                                fileObj.regulateView(originalFile, xhr.responseText);
                                            } else {
                                                if (uploadedSize < fileSize) {
                                                    originalFile.uploadOver = false;
                                                    batchesSuccess = false;
                                                    if (fileObj.uploadAllowed) {
                                                        if (uploadedSize + option.fileSplitSize >= fileSize) {
                                                            //如果文件本身小于分片大小 不切割
                                                            file = originalFile.slice(uploadedSize, fileSize);
                                                            complete = true;
                                                        } else {
                                                            file = originalFile.slice(
                                                                uploadedSize,
                                                                uploadedSize + option.fileSplitSize
                                                            );
                                                            complete = false;
                                                        }

                                                        file.name = fileName;
                                                        file.id = fileId;
                                                        file.index = fileIndex;
                                                        // file.size = fileSize;

                                                        option.formData.fileMd5 = originalFile.md5;
                                                        option.formData.totalSize = originalFile.size;
                                                        option.formData.complete = complete;
                                                        option.formData.initSize = uploadedSize;
                                                        setTimeout(
                                                            fileObj.sendBlob(fileObj.url, xhr, file, option.formData),
                                                            2000
                                                        );
                                                    }
                                                } else {
                                                    fileObj.regulateView(originalFile, xhr.responseText);
                                                }
                                            }
                                        } else {
                                            fileObj.regulateView(originalFile, xhr.responseText);
                                        }
                                    } else {
                                        //上传失败
                                        option.msgInfo('error', '文件：' + file.name + '  ' + returnData.msg);
                                        var tmpoptions = {
                                            status: 'end',
                                            id: file.id,
                                        };
                                        uploadMethods.uploadProgress(tmpoptions);
                                        originalFile.uploadOver = true;
                                        fileObj.regulateView(originalFile, xhr.responseText);
                                        option.onUploadError && option.onUploadError(originalFile, xhr.responseText);
                                    }
                                } else {
                                    //重试一次
                                    if (originalFile.uploadOver) {
                                        option.onUploadError && option.onUploadError(originalFile, xhr.responseText);
                                        option.msgInfo(
                                            'error',
                                            '服务繁忙，文件' + file.name + '上传失败，请稍候重试。'
                                        );
                                    } else {
                                        option.onUploadError && option.onUploadError(originalFile, xhr.responseText);
                                    }
                                }

                                if (originalFile.uploadOver) {
                                    option.onUploadComplete && option.onUploadComplete(originalFile, xhr.responseText);
                                    //清除文件选择框中的已有值
                                    fileObj.fileInput.val('');
                                    uploadMethods.statble = 90;
                                }
                                if (option.inBatches && 'batchesCount' in option && batchesSuccess) {
                                    option.batchesCount++;
                                }
                                if (callback && typeof callback == 'function') {
                                    callback();
                                }
                            }
                        };

                        option.onUploadStart && option.onUploadStart(file, originalFile);
                        option.formData.fileMd5 = file.md5;
                        option.formData.totalSize = originalFile.size;
                        option.formData.complete = complete;
                        option.formData.initSize = uploadedSize;
                        fileObj.uploadAllowed = true; //重置允许上传为true
                        // 文件上传之前改变url
                        uploadMethods.genUploadUrl(fileObj.uploadParam);
                        if (option.isResizeImg && uploadMethods.isResizeImg(file) && option.resizeImg) {
                            uploadMethods.resizeImg(
                                option.formData,
                                option.resizeImg.maxWidth,
                                option.resizeImg.maxHeight,
                                originalFile,
                                xhr
                            );
                        } else {
                            fileObj.sendBlob(fileObj.url, xhr, file, option.formData);
                        }
                    }
                },
                //发送文件块函数
                sendBlob: function (url, xhr, file, formdata) {
                    //由于异步上传图片，超容量限制从后端移到前端
                    if (option.isConcurrent) {
                        //如果 准备上传的容量 大于 最大容量
                        if (fileObj.nowStatSize + formdata.totalSize > fileObj.maxSize) {
                            var data = '{"success":false,"msg":"上传资源已超过容量限制，请升级版本。"}';
                            var tmpoptions = { status: 'end', id: file.id };
                            uploadMethods.uploadProgress(tmpoptions);
                            file.uploadOver = true;
                            fileObj.regulateView(file, data);
                            option.onUploadComplete && option.onUploadComplete(file, data);
                            //清除文件选择框中的已有值
                            fileObj.fileInput.val('');
                            uploadMethods.statble = 90;
                            return;
                        }
                        fileObj.nowStatSize += file.size;
                    }

                    xhr.open(option.method, url, true);
                    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                    var fd = new FormData();
                    fd.append(option.fileObjName, file, file.name);
                    if (option.isResizeImg && uploadMethods.isResizeImg(file) && !!file.source) {
                        fd.append('resizeImg', file.source, file.name);
                    }
                    if (option.isSvgToPng) {
                        fd.append('pngStr', file.pngStr);
                    }

                    if (formdata) {
                        for (const key in formdata) {
                            fd.append(key, formdata[key]);
                        }
                    }

                    xhr.send(fd);
                },
                regulateView: function (originalFile, responseText) {
                    var continueUploader = function () {
                        if (fileObj.fileFilter.length != 0) {
                            var nowFile = fileObj.fileFilter[0];
                            //重新获取文件大小
                            var uploadedSize = fileObj.funGetUploadedSize(nowFile);
                            var initWidth = (uploadedSize / nowFile.size) * 100;
                            var tmpoptions = {
                                status: 'start',
                                id: nowFile.id,
                                title: '准备上传...',
                                percent: initWidth,
                                size: nowFile.size,
                            };
                            uploadMethods.uploadProgress(tmpoptions);
                            tmpoptions = {
                                status: 'ing',
                                id: nowFile.id,
                                title: '上传中...',
                                percent: initWidth,
                            };
                            uploadMethods.uploadProgress(tmpoptions);
                            fileObj.funUploadFile(nowFile, uploadedSize);
                        }
                    };
                    if (originalFile.uploadOver) {
                        // thisfile.find('.uploadify-progress-bar').css('width', '100%');
                        // option.showUploadedSize && thisfile.find('.uploadedsize').text(thisfile.find('.totalsize').text());
                        // option.showUploadedPercent && thisfile.find('.up_percent').text('100%');
                        fileObj.fileFilter[0] &&
                            option.onUploadSuccess &&
                            option.onUploadSuccess(originalFile, responseText);
                        //非图片
                        if (!option.isConcurrent) {
                            //继续上传第二个
                            fileObj.funDeleteFile(originalFile.index);
                            setTimeout(continueUploader, 1500);
                        }
                    }
                },
                onProgress: function (file, loaded, total) {
                    var eleProgress = $('body').find('#progressWrap_' + file.id);
                    var thisLoaded = loaded;
                    //根据上一次触发progress时上传的大小，得到本次的增量
                    var lastLoaded = eleProgress.attr('lastLoaded') || 0;
                    loaded -= parseInt(lastLoaded);
                    if (loaded < 0) {
                        loaded = 0;
                    }
                    var progressBar = $('#progress' + file.id);
                    var oldWidth = option.breakPoints ? parseFloat(progressBar.attr('width')) : 0;

                    // 分片每次请求的数值加起来是超过文件总量的，这样会导致百分比显示问题。
                    // 所以这里不用每次请求的量了，而是用分片的量来递增百分比，分片的量总和是跟文件大小相等的。
                    if (loaded < file.size) {
                        return;
                    }
                    var oldLoaded = parseFloat(progressBar.attr('old-loaded') || (oldWidth / 100) * total);
                    var nowLoaded = oldLoaded + file.size;
                    progressBar.attr('old-loaded', nowLoaded);
                    var percent = ((nowLoaded / total) * 100).toFixed(0);
                    percent = percent >= 100 ? 100 : percent;

                    //进度条取整不保留两位小数。toFixed(2)
                    // var percent = (loaded / total * 100 + oldWidth).toFixed(0);
                    // percent = percent > 100 ? 99 : percent;//校正四舍五入的计算误差
                    //记录本次触发progress时已上传的大小，用来计算下次需增加的数量
                    if (thisLoaded < option.fileSplitSize) {
                        eleProgress.attr('lastLoaded', thisLoaded);
                    } else {
                        eleProgress.removeAttr('lastLoaded');
                    }
                    const tmpoptions = {
                        status: 'ing',
                        id: file.id,
                        title: '上传中...',
                        percent: percent,
                    };
                    uploadMethods.uploadProgress(tmpoptions);
                },
                //获取当前进度条的宽度，返回字符串如90%
                funGetProgressWidth: function (index) {
                    //	var eleProgressBar = _this.find('#fileupload_'+instanceNumber+'_'+index+' .uploadify-progress-bar');
                    //	return eleProgressBar.get(0).style.width || '';
                },
                //获取已上传的文件片大小，当开启断点续传模式
                funGetUploadedSize: function (file) {
                    if (option.getUploadedSize) {
                        return option.getUploadedSize(file);
                    } else {
                        if (option.saveInfoLocal) {
                            return parseInt(localStorage.getItem(file.name)) || 0;
                        }
                    }
                },
                funSaveUploadedSize: function (file, value) {
                    if (option.saveUploadedSize) {
                        option.saveUploadedSize(file, value);
                    } else {
                        if (option.saveInfoLocal) {
                            localStorage.setItem(file.name, value);
                        }
                    }
                },
                //删除对应的文件
                funDeleteFile: function (index) {
                    var copies = this.fileFilter.slice();
                    for (var i = 0; i < copies.length; i++) {
                        var file = copies[i];
                        if (file.index == index) {
                            if (option.breakPoints) {
                                this.uploadAllowed = false;
                            }
                            this.fileFilter.splice(i, 1);
                            $(document)
                                .find('#fileupload_' + instanceNumber + '_' + index)
                                .fadeOut();
                            fileObj.fileInput.val('');
                            option.onCancel && option.onCancel(file);
                            break;
                        }
                    }
                    return this;
                },
            };

            //初始化文件对象
            fileObj.init();
        });

        return {
            instanceID: fileObj.instanceNumber,
            genUploadUrl: uploadMethods.genUploadUrl,
            fileObj: fileObj,
            changeParam: function (obj) {
                $.extend(fileObj.uploadParam, obj);
            },
            changeFormParam: function (obj) {
                $.extend(option.formData, obj);
            },
            changeInputType: function (instanceID, type) {
                var input = $('#select_btn_' + instanceID);
                var acceptStr = uploadMethods.getAcceptString(type);
                input.attr('accept', acceptStr);
            },
            stop: function () {
                fileObj.uploadAllowed = false;
                var copies = fileObj.fileFilter.slice();
                for (var i = 0; i < copies.length; i++) {
                    copies[i].uploadOver = false;
                    fileObj.funDeleteFile(++i);
                }
                fileObj.fileFilter = [];
            },
            upload: function (fileIndex) {
                if (fileIndex === '*') {
                    var len = (len = fileObj.fileFilter.length);

                    for (var i = 0, len = fileObj.fileFilter.length; i < len; i++) {
                        fileObj.funUploadFile(fileObj.fileFilter[i]);
                    }
                } else {
                    var file = getFile(fileIndex, fileObj.fileFilter);
                    file && fileObj.funUploadFile(file);
                }
            },
            cancel: function (fileIndex) {
                (fileIndex == undefined || fileIndex == '*') && (fileIndex = '*'); //暂时先取消全部
                if (fileIndex == '*') {
                    for (var i = 0, len = fileObj.fileFilter.length; i < len; i++) {
                        fileObj.funDeleteFile(++i);
                    }
                } else {
                    fileObj.funDeleteFile(fileIndex);
                }
            },
            disable: function (instanceID, canStop) {
                //默认全部uploadBtn disable
                if (instanceID) {
                    $('#file_upload_' + instanceID + '-button').off('click');
                } else {
                    var parent = $('.uploadify');
                    parent.find('.uploadify-button').off('click');
                }
            },
            enable: function (instanceID) {
                //点击上传按钮时触发file的click事件
                var $uploadBtn = '';
                if (instanceID) {
                    $uploadBtn = $('#file_upload_' + instanceID + '-button');
                } else {
                    var parent = $('.uploadify');
                    $uploadBtn = parent.find('.uploadify-button');
                }
                $uploadBtn.off('click').on('click', function () {
                    $(this).prev('.selectbtn').trigger('click');
                });
            },
        };
    };
})(jQuery);
