//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { bindFlag } = Comm;
import { CarouselPhotos } from '@shared/visitor/carouselPhotos/index.js';
import { USE_NEW_CAROUSEL } from './constants.js';
import basicPicCompon from './basicPicCompon.vue';
import { animationMixin } from '../animationMixin';
import { photoGroupList } from '@shared/manage/photoGroup/index.js';

export default {
    name: 'CarouselPhotos',
    style: 2,
    components: {
        basicPicCompon,
    },
    mixins: [animationMixin],
    props: ['module'],
    data() {
        let data = {
            controller: {},
        };
        const isServer = typeof window === 'undefined';
        if (isServer || !window._store.state.manageMode) {
            data.usePhotoGroupList = this.sort(this.module.usePhotoGroupList);
        }
        return data;
    },
    asyncComputed: {
        usePhotoGroupList: {
            lazy: true,
            get() {
                return photoGroupList.load([this.module.prop4]);
            },
            then(list) {
                // 屏蔽触发网站的保存
                this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                if (!list) list = [];
                const photoGroup = list.find((photoGroup) => photoGroup.id === this.module.prop4);
                this.refreshOneResAnimation();
                if (photoGroup) {
                    return photoGroup.photoList.map(function (photo) {
                        return {
                            pid: photo.pic,
                            psrc: photo.picPath,
                            w: photo.width,
                            h: photo.height,
                            desc: photo.basic,
                            link: {
                                url: photo.url ? photo.url : null,
                                reqArgs: photo.reqArgs,
                                // ib: true,
                            },
                        };
                    });
                } else {
                    return [
                        {
                            pid: '',
                            psrc: '',
                            w: 0,
                            h: 0,
                            desc: '',
                            link: {
                                url: null,
                                // ib: true,
                            },
                        },
                    ];
                }
            },
            default: [],
        },
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        usePhotoGroup: bindFlag(0x1),
        model() {
            const device = this.device;
            const pattern = this.module.privatePattern[device];
            return pattern;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        style() {
            return this.module.prop0;
        },
        photoList() {
            if (!this.usePhotoGroup) {
                return this.module.prop1;
            } else {
                return this.usePhotoGroupList;
            }
        },
        privatePattern() {
            const device = this.device;
            const pattern = this.module.privatePattern[device];
            if (device !== 'pc') {
                //非pc的情况下混合pc pattern
                const pcPattern = this.module.privatePattern['pc'];
                return Object.assign({}, pcPattern, pattern);
            }
            return pattern;
        },
    },
    watch: {
        style() {
            this.$nextTick(() => {
                !USE_NEW_CAROUSEL && this.controller.update();
            });
        },
        photoList() {
            //图片列表更变
            this.$nextTick(() => {
                !USE_NEW_CAROUSEL && this.controller.update();
            });
            !USE_NEW_CAROUSEL && this.controller.resetAutoPlay();
        },
        usePhotoGroup() {
            //切换图片来源导致图片列表更变
            !USE_NEW_CAROUSEL && (this.controller._selectVal = 0);
            !USE_NEW_CAROUSEL && (this.controller._prevSelect = 0);
            !USE_NEW_CAROUSEL && this.controller.update();
            !USE_NEW_CAROUSEL && this.controller.resetAutoPlay();
        },
        'module.prop4': function () {
            //切换图片来源导致图片列表更变
            !USE_NEW_CAROUSEL && (this.controller._selectVal = 0);
            !USE_NEW_CAROUSEL && (this.controller._prevSelect = 0);
            this.$nextTick(() => {
                !USE_NEW_CAROUSEL && this.controller.update();
            });
            !USE_NEW_CAROUSEL && this.controller.resetAutoPlay();
        },
        'model.pct': function () {
            this.updateAutoPlay();
        },
        'model.pcst': function () {
            this.updateAutoPlay();
        },
        'model.pcswt': function () {
            this.updateAutoPlay();
        },
    },
    mounted() {
        if (this.photoList.length != 0 && !this.manageMode) {
            !USE_NEW_CAROUSEL &&
                (CarouselPhotos[this.module.id] = this.controller = new CarouselPhotos(this.module.id, this.module));
        } else {
            !USE_NEW_CAROUSEL &&
                (CarouselPhotos[this.module.id] = this.controller = new CarouselPhotos(this.module.id));
        }
    },
    methods: {
        updateAutoPlay() {
            if (USE_NEW_CAROUSEL) {
                this.$refs.carousel.stopAutoPlay();
                this.$refs.carousel.autoPlay();
            } else {
                this.controller.autoPlay();
            }
        },
        sort(list = []) {
            const photoGroup = list.find((photoGroup) => photoGroup.id === this.module.prop4);

            if (photoGroup) {
                return photoGroup.photoList.map(function (photo) {
                    return {
                        pid: photo.pic,
                        psrc: photo.picPath,
                        w: photo.width,
                        h: photo.height,
                        desc: photo.basic,
                        link: {
                            url: photo.url ? photo.url : null,
                            reqArgs: photo.reqArgs,
                            // ib: true,
                        },
                    };
                });
            } else {
                return [
                    {
                        pid: '',
                        psrc: '',
                        w: 0,
                        h: 0,
                        desc: '',
                        link: {
                            url: null,
                            // ib: true,
                        },
                    },
                ];
            }
        },
    },
};
