//
//
//
//
//

import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
export default {
    props: ['projectid'],
    computed: {
        id() {
            return this.projectid;
        },
        ...Vuex.mapState(['device']),
        modules() {
            return window._store.state.modules;
        },
        isMobi() {
            return this.device === 'mobi';
        },

        module() {
            return this.modules['module' + this.id];
        },

        pcPrivatePattern() {
            return this.module.privatePattern.pc;
        },
    },
    mounted() {
        if (!this.isMobi) {
            let featureListener = `f-MarginResize${this.id}`;
            let orContentHeight = null;
            eventBus.$off(`${featureListener}.start`).$on(`${featureListener}.start`, (data) => {
                if (data.type === 'rightBottom') {
                    orContentHeight = this.pcPrivatePattern.pch || 0;
                }
            });
            eventBus.$off(`${featureListener}.move`).$on(`${featureListener}.move`, (data) => {
                if (data.type === 'rightBottom') {
                    let realyContent = orContentHeight + Math.floor(data.moveY);
                    //普通模块最小的minHeight 30；
                    let minHeight = 40;
                    if (orContentHeight + Math.floor(data.moveY) > minHeight) {
                        this.pcPrivatePattern.pch = realyContent;
                    }
                    this.pcPrivatePattern.pcht = 1;
                }
            });
        }
    },
    destroy() {
        let featureListener = `f-MarginResize${this.id}`;
        eventBus.$off(`${featureListener}.start`);
    },
};
