//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClientOnly from 'vue-client-only';
import { loadScript, removeScript, insertScript, executeScripts } from './utils.js';
import { decodeHtml } from '@/components/utils.js';

import { Message } from '@shared/manage/componMessage/index.js';
import { getDetachedCode } from '@api/pluginCode';
let manageUse = {
    components: {},
};

export default {
    name: 'PluginCode',
    style: 28,
    components: {
        ...manageUse.components,
        ClientOnly,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            detachedCode: '',
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'safeMode', 'allowScript', 'oem', 'siteVer']),
        code() {
            return this.module.content || '';
        },
        insertLocation() {
            return this.module.prop0 || 0;
        },
        insertLocationText() {
            return `当前插件代码已添加在${
                ['默认位置', '<head>标签', '网站底部'][this.insertLocation]
            }，当前模块位置不显示。`;
        },
        initalState() {
            return this.module.initalState || {};
        },
        verProm() {
            return this.initalState.verProm || '';
        },
        updateUrlView() {
            return this.initalState.updateUrlView || '';
        },
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        safe_Mode() {
            return (this.$isServer ? this.initalState.safeMode : this.safeMode) || false;
        },
    },
    watch: {
        'module.content': function () {
            this.getDetachedCode(this.renderCode);
        },
        'module.prop0': function () {
            this.getDetachedCode(this.renderCode);
        },
    },
    mounted() {
        if (this.code !== '' && this.detachedCode === '') {
            this.getDetachedCode(this.renderCode);
        } else {
            this.renderCode();
        }
    },
    methods: {
        onModuleEdit() {
            if (!this.manageMode) {
                return;
            }
            editModule(this.module.id);
        },
        getDetachedCode(cb) {
            // 防止 watcher 在同一个 nextTick 内重复执行
            if (!this._renderCodeTriggered) {
                this._renderCodeTriggered = true;
                getDetachedCode({
                    code: this.code,
                    insertLocation: this.insertLocation,
                    options: $.toJSON({
                        _allowScript: this.allowScript,
                        isOemFreeDomain: this.initalState.isOemFreeDomain,
                        _originalSafeMode: this.safeMode,
                        _siteVer: this.siteVer,
                    }),
                    moduleId: this.module.id,
                })
                    .then((data) => {
                        if (data.success) {
                            this.detachedCode = decodeHtml(data.data);
                            cb();
                        }
                    })
                    .catch(() => {
                        Message.warning('系统繁忙');
                    });

                this.$nextTick(() => {
                    this._renderCodeTriggered = false;
                });
            }
        },
        renderCode() {
            if (!this.allowScript || this.safe_Mode) return;
            // 使用后台处理后的代码
            const code = this.detachedCode;
            removeScript(this.module.id);
            code && loadScript(code, $('#module' + this.module.id + ' .detachedCode')[0]);
            switch (this.insertLocation) {
                // 管理态是由 innerHTML 插入脚本的所以需要强制执行脚本，访客态由于是 SSR 不需要强制执行
                // 访客态ssr需激活后才执行 不然有一些第三方代码插入dom的 激活之前插入 激活的时候 会跟ssr不一致 导致插入失败
                case 0:
                    executeScripts(code);
                    break;

                case 1:
                    insertScript(code, document.head, this.module.id);
                    break;
                case 2:
                    insertScript(code, document.body, this.module.id);
                    break;
            }
        },
    },
};
