/* eslint-disable no-inner-declarations */
/**
 * --------------------------------------------------------------------------------------
 * Constants 交叉观察器的配置数据 这里只放交叉观察器相关的内部函数跟数据
 * --------------------------------------------------------------------------------------
 */
import { digGet } from '@jz/utils';
import { InterSectionObserApi } from '@jz/biz-shared';
import { checkSiteHash, hasHash } from './initSite';
import { jumpToAnchor } from './utils';
import { banner } from './banner';
import { getCarouselRowsEffect } from '@shared/visitor/carouselRowsEffects/index.js';
import { LAZY_IMG_DEFAULT_DATA, _checkObserver, sectionObserver } from '@/site/shared/sectionObserver/index.js';
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import { device } from '@/site/shared/device/index.js';

import { store } from '@/store/store.js';

const isServer = typeof window === 'undefined';
const isTopPage = !isServer && Fai.top === window;
const lazyImgStack = [];
let resetJumpFlag = false;
const MODULE_CAROUSEL_PHOTOS_DEFAULT_DATA = {
        id: 2,
        observerClass: '.module_carousel_photos_content',
        observerName: 'observer2',
        isUnobserve: false, // 执行完回调是否解除监听
    },
    CAROUSEL_ROW_DEFAULT_DATA = {
        id: 3,
        observerClass: '.jz_web_row--carousel',
        observerName: 'observer3',
        isUnobserve: false, // 执行完回调是否解除监听
    },
    // 不使用 jz_img_lazyload 的原因是.jz_rowbg_lazyload该逻辑属于过渡方案。访客态通栏迟早会废弃，当自适应重构完成后管理态和访客态统一后，该代码就将被删除了。
    // 通栏背景 管理态 和 访客态 是两套逻辑。
    ROW_BG_DEFAULT_DATA = {
        id: 4,
        observerClass: `.jz_web_row_line.jz_rowbg_lazyload,.jz_web_row.jz_rowbg_lazyload`,
        // observerClass: `.jz_web_content .jz_web_row_line,.jz_web_content .jz_web_row`,
        observerName: 'observer4',
        isUnobserve: false, // 执行完回调是否解除监听
    },
    VIDEO_DEFAULT_DATA = {
        id: 5,
        observerClass: `.video_lazy_load`,
        observerName: 'observer5',
        isUnobserve: false, // 执行完回调是否解除监听
    };

/**
 * @function name - _unobserve
 * @description 取消元素的监听
 * @author mikey.chuhui
 * @private
 * @date 2020-03-18
 * @example
 * _unobserve({ el: 'module324', ...LAZY_IMG_DEFAULT_DATA });
 * // => 将会取消监听id为module324的元素的图片懒加载
 * @param options {opt} - {el, id, observerName} el可以是dom对象也可以是dom元素id值
 */

const _unobserve = ({ el, id, observerName }) => {
    if (typeof el === 'string') {
        el = document.getElementById(el);
    }
    if (!_checkObserver(observerName)) return;
    sectionObserver?.()?.unobserve?.(el, id);
};

/** ------- 图片懒加载 内部函数 -------*/
/**
 * @function name - resetJumpToAnchor
 * @description 重新跳转锚点
 * @private
 * @author  mackie.chuhui
 * @param original {String} - 图片地址
 * @example
 * resetJumpToAnchor('')
 */

let resetJumpToAnchor = (original = '', { colId, position, colUrl }) => {
    if (resetJumpFlag) {
        //  如果重新跳转过锚点则不再跳转
        resetJumpToAnchor = (original) => original;
        return;
    }
    let current = lazyImgStack.indexOf(original);
    current !== -1 && lazyImgStack.splice(current, 1);
    if (!lazyImgStack.length) {
        hasHash() && (resetJumpFlag = true) && checkSiteHash();
        original == '' && (resetJumpFlag = true) && jumpToAnchor(colId, position, colUrl);
    }
    original == '' && current !== -1 && lazyImgStack.length && resetJumpToAnchor(original, { colId, position, colUrl });
};

const _isImageLoadedOrLoading = (target) => {
    return target?.attr('data-loaded') || target?.hasClass('lazy_2_loading');
};

/**
 * 找出 numbers 中第一个大于等于 num 的元素
 * @param {number[]} numbers
 * @param {number} num
 * @returns {number}
 */
function findUpperBound(numbers, num) {
    let left = 0,
        right = numbers.length - 1;
    while (left < right) {
        let mid = Math.floor((left + right) / 2);
        if (numbers[mid] > num) {
            right = mid;
        } else {
            left = mid + 1;
        }
    }
    return numbers[right];
}

let thumbLogs = [];

/**
 * 根据元素的渲染区域宽高比与原图的宽高比生成缩略图
 * 支持的缩略图大小:
 *  60x60,100x100,160x160,200x200,300x300,400x400,450x450,500x500,600x600,700x700,800x800,900x900,1000x1000,1500x1500,2000x2000
 * @param {HTMLElement} element
 * @param {string} originalSrc
 * @param {number} naturalWidth
 * @param {number} naturalHeight
 */
function getThumbPath(element, originalSrc, naturalWidth, naturalHeight) {
    if (
        window._store.state.imageUseThumb === false ||
        window._store.state.manageMode === true ||
        isSupportImageService(originalSrc) === false
    ) {
        return originalSrc;
    }

    if (!naturalWidth || !naturalHeight) {
        return originalSrc;
    }

    let thumbLog = {
        jz_content_terminal: '自适应',
        jz_free_text_0: originalSrc,
        jz_free_text2: `${naturalWidth}x${naturalHeight}`,
    };

    let containerRect = element.getBoundingClientRect();
    let containerRatio = containerRect.width / containerRect.height;
    if (containerRect.width === 0 || containerRect.height === 0) {
        thumbLog['jz_free_bool1'] = false;
        thumbLog['jz_free_text3'] = '0x0';
        thumbLogs.push(thumbLog);
        // 找不到 Rect, 不做缩略图
        return originalSrc;
    }

    if (containerRect.width < 2 && containerRect.height < 2) {
        // 懒加载 gif 图， 获取其父元素的宽高
        containerRect = element.parentElement.getBoundingClientRect();
        containerRatio = containerRect.width / containerRect.height;
        // 只拿一层的父元素宽高对比，避免耗费性能
        if (containerRect.width < 2 && containerRect.height < 2) {
            thumbLog['jz_free_bool1'] = false;
            thumbLog['jz_free_text3'] = '1x1';
            thumbLogs.push(thumbLog);
            return originalSrc;
        }
    }

    const imageRatio = naturalWidth / naturalHeight;
    let thumbWidth, thumbHeight;
    if (imageRatio > containerRatio) {
        // 以容器高为基准
        thumbHeight = containerRect.height;
        thumbWidth = Math.ceil((naturalWidth * containerRect.height) / naturalHeight);
    } else {
        // 以容器宽为基准
        thumbWidth = containerRect.width;
        thumbHeight = Math.ceil((naturalHeight * containerRect.width) / naturalWidth);
    }

    if (Number.isNaN(thumbWidth) || Number.isNaN(thumbHeight)) {
        thumbLog['jz_free_bool1'] = false;
        thumbLog['jz_free_text3'] = `${containerRect.width}x${containerRect.height}`;
        thumbLogs.push(thumbLog);
        return originalSrc;
    }

    // 有一些设计感较强的网站清晰度要求很高，使用两倍缩略图更清晰
    thumbWidth *= 2;
    thumbHeight *= 2;

    const availableThumbSize = [60, 100, 160, 200, 300, 400, 450, 500, 600, 700, 800, 900, 1000, 1500, 2000];

    const max = Math.max(thumbWidth, thumbHeight);

    if (max > availableThumbSize[availableThumbSize.length - 1]) {
        return originalSrc;
    }

    thumbWidth = thumbHeight = findUpperBound(availableThumbSize, max);

    if (thumbWidth >= naturalWidth) {
        // 超过原图宽度则用原图
        return originalSrc;
    }

    thumbLog['jz_free_bool1'] = true;
    thumbLog['jz_free_text1'] = `${thumbWidth}x${thumbHeight}`;
    thumbLog['jz_free_text3'] = `${containerRect.width}x${containerRect.height}`;
    thumbLogs.push(thumbLog);

    return originalSrc.replace(/\.(jpg|png|jpeg|webp)/, (_, $1) => `!${thumbWidth}x${thumbHeight}.${$1}`);
}

/**
 * @function name - _loadImg
 * @description 图片加载
 * @private
 * @author  mackie.chuhui
 * @param elem  {String|Object} - 监听得元素
 * @example
 * _loadImg($(img)[0])
 * // => 加载挂载了懒加载功能的图片
 * @return  {Boolean}- isLoaded
 */

const _loadImg = function (elem, src = '') {
    let target = $(elem),
        isLoaded = _isImageLoadedOrLoading(target);
    if (isLoaded) return isLoaded; // 已经加载成功则不再加载图片
    const original = target.attr(LAZY_IMG_DEFAULT_DATA.data_attribute) || src;
    const naturalWidth = target.attr('natural-width');
    const naturalHeight = target.attr('natural-height');
    const webpOptOpen = window._store.state.webpOptOpen; // 是否启用图片加速
    const imagePath = img2Webp(getThumbPath(elem, original, naturalWidth, naturalHeight), webpOptOpen);

    lazyImgStack.push(original);
    target.addClass('lazy_2_loading');

    const setImage = (picUrl) => {
        if (target.is('img')) {
            target.attr('src', picUrl);
        } else {
            target.css('background-image', "url('" + picUrl + "')");
        }
        target.removeClass('lazy_2_loading');
        target.attr('data-loaded', true); // 加载成功标志
        var timer = setTimeout(function () {
            // target.attr('data-loaded', true); // 加载成功标志
            if (target.parents('.jz_banner').length) {
                banner.fixBannerOverflowHeight();
            }
            resetJumpToAnchor(original, {}); // 图片加载完再执行一次锚点跳转 避免图片过大导致锚点位置不对
            clearTimeout(timer);
            timer = null;
        }, 300);
    };
    $('<img />')
        .one('load', () => setImage(imagePath))
        .one('error', () => {
            // eslint-disable-next-line no-console
            console.error(`图片懒加载错误：图片地址：${imagePath}, 可能是不支持webp，已加载原图片`);
            setImage(original);
        })
        .attr('src', imagePath);
    return true;
};

/**
 * @function name - _getModuleId
 * @description 获取模块id
 * @private
 * @author mikey.chuhui
 * @date 2020-08-10
 * @param elem {Object} jq Dom对象
 * @example
 * _getModuleId($("#module89"))
 * // => 89
 * @returns {Number}
 */

const _getModuleId = function (elem) {
    return window._store.state.manageMode
        ? parseInt(elem.attr('projectid'))
        : elem.attr('id')
        ? parseInt(elem.attr('id').match(/\d/g).join(''))
        : 0;
};

/**
 * @function name - _lazyImgObserve
 * @description 图片懒加载监听执行函数
 * @private
 * @author mikey.chuhui
 * @date 2020-01-19
 * @param val {Object} - val相交元素数据
 * @returns {Promise}
 */

const _lazyImgObserve = function (val) {
    return new Promise((reslove) => {
        let target = $(val.target);
        if (val && val.isIntersecting && target) {
            let picPath = target.attr(LAZY_IMG_DEFAULT_DATA.data_attribute),
                isLoaded = _isImageLoadedOrLoading(target);
            if (picPath && !isLoaded) {
                const carouselModule = target.parents('.module_carousel_photos_content');
                if (carouselModule.length) {
                    // 加载轮播模块图片
                    let imgArray = [],
                        $imgList = carouselModule.find(LAZY_IMG_DEFAULT_DATA.observerClass),
                        imgLen = $imgList.length;
                    if (imgLen >= 2) {
                        $imgList.each(function (index, $img) {
                            if ($img == val.target) {
                                if (imgLen == 2) {
                                    imgArray = imgArray.concat(Array.from($imgList));
                                } else {
                                    imgArray.push($img);
                                    const prevIndex = index - 1,
                                        nextIndex = index + 1,
                                        $prevImg = $imgList.eq(prevIndex),
                                        $nextImg = $imgList.eq(nextIndex);
                                    $nextImg.length && !_isImageLoadedOrLoading($nextImg) && imgArray.push($nextImg[0]);
                                    $prevImg.length && !_isImageLoadedOrLoading($prevImg) && imgArray.push($prevImg[0]);
                                }
                            }
                        });
                        imgArray.forEach((targetImg) => {
                            _loadImg(targetImg);
                        });
                    } else {
                        _loadImg(val.target);
                        imgArray.push(val.target);
                    }
                    reslove({ val, imgArray });
                } else {
                    // 加载图片
                    _loadImg(val.target);
                }
            }
            reslove({ val, imgArray: [val.target] });
        }
    });
};

/**
 * @function name - unobserveLazyloadImage
 * @description - 取消图片懒加载监听 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @example
 * unobserveLazyloadImage($(img)[0])
 * // => 取消$(img)[0]的图片懒加载监听
 * unobserveLazyloadImage([$(img)[0],$(img)[1]])
 * // => 取消$(img)[0],$(img)[1]的图片懒加载监听
 * @param el {Object|Array} - dom对象或者dom对象数组
 */

const unobserveLazyloadImage = (el) => {
    if (Array.isArray(el)) {
        el.forEach((value) => {
            _unobserve({ el: value, ...LAZY_IMG_DEFAULT_DATA });
        });
    } else {
        _unobserve({ el, ...LAZY_IMG_DEFAULT_DATA });
    }
};

/**
 * @function name - _imgFnish
 * @description - 图片懒加载监听执行结束回调
 * @author mikey.chuhui
 * @date 2020-03-18
 * @param options  {Object} {val,imgArray=[]}
 */

const _imgFnish = function ({ imgArray }) {
    if (imgArray.length) {
        unobserveLazyloadImage(imgArray);
    }
};

/** ------- 图片懒加载 内部函数 -------*/

/**
 * @function name carouselAutoPlay
 * @description 自动轮播
 * @private
 * @author mikey.chuhui
 * @date 2020-08-10
 * @param moduleId {Number} 模块id
 */

let carouselAutoPlay = function (moduleId) {
    // 100ms ->200ms 放在首屏时，会先执行carouselAutoPlay， 后执行组件监听
    setTimeout(() => {
        _voidVm.$emit(`autoPlay${moduleId}`);
        carouselAutoPlay = (moduleId) => {
            setTimeout(() => {
                _voidVm.$emit(`autoPlay${moduleId}`);
            }, 500);
        };
    }, 500);
};

/**
 * @function name carouselStopAutoPlay
 * @description 暂停自动轮播
 * @private
 * @author mikey.chuhui
 * @date 2020-08-10
 * @param moduleId {Number} 模块id
 */

let carouselStopAutoPlay = function (moduleId) {
    setTimeout(() => {
        _voidVm.$emit(`stopAutoPlay${moduleId}`);
        carouselStopAutoPlay = (moduleId) => {
            setTimeout(() => {
                _voidVm.$emit(`stopAutoPlay${moduleId}`);
            }, 100);
        };
    }, 100);
};

/**
 * @function name _carouselPhotosObserve
 * @description 轮播多图 监听回调
 * @private
 * @author mikey.chuhui
 * @date 2020-08-07
 * @param val {*}
 */

const _carouselPhotosObserve = function (val) {
    return new Promise((reslove) => {
        if (val && val.target) {
            //拿到相交模块得id
            const target = $(val.target);
            const moduleId = _getModuleId(target);

            if (val.isIntersecting && moduleId) {
                // 进入视图
                carouselAutoPlay(moduleId);
                reslove({ val });
            } else if (moduleId && !val.isIntersecting) {
                // 离开视图
                carouselStopAutoPlay(moduleId);
                reslove({ val });
            }
        }
    });
};

/**
 * @function name _carouselRowObserve
 * @description 轮播通栏 监听回调
 * @private
 * @author mikey.chuhui
 * @date 2021-10-12
 * @param val {*}
 */

const _carouselRowObserve = (val) => {
    return new Promise((reslove) => {
        if (val && val.target) {
            //拿到相交模块得id
            const target = $(val.target);
            const id = parseInt(target?.attr('id')?.match(/\d/g)?.join(''));
            const carouselRowEffect = getCarouselRowsEffect(id);

            if (val.isIntersecting && target) {
                // 进入视图
                target.find(LAZY_IMG_DEFAULT_DATA.observerClass).each(function (index, item) {
                    const target = $(item);
                    const isLoaded = target.attr('data-loaded');
                    if (!isLoaded && !target.hasClass('lazy_2_loading')) {
                        _loadImg(item);
                    }
                });
                if (carouselRowEffect && carouselRowEffect.isCarousel) {
                    carouselRowEffect.isAuto && carouselRowEffect.autoPlay();
                }
                reslove({ val });
            } else {
                if (carouselRowEffect && carouselRowEffect.isCarousel) {
                    carouselRowEffect.isAuto && carouselRowEffect.stopAutoPlay();
                }
                reslove({ val });
            }
        }
    });
};

/**
 * @function name _rowBgObserve
 * @description 通栏 监听回调
 * @private
 * @author mikey.chuhui
 * @date 2021-10-12
 * @param val {*}
 */

const _rowBgObserve = (val) => {
    return new Promise((reslove) => {
        if (val && val.target) {
            //拿到相交模块得id
            let target = $(val.target);
            let rowId = '';
            const device = window._store.state.device;
            if (target.hasClass('jz_web_row_line')) {
                let webRow = target.find('.jz_web_row:first-child');
                if (device == 'mobi') {
                    target = webRow;
                    rowId = target.attr('id').slice(3);
                } else {
                    rowId = webRow.attr('id').slice(3);
                }
            } else {
                rowId = target.attr('id').slice(3);
            }
            if (val.isIntersecting && target) {
                // 进入视图
                const isLoaded = target.attr('data-loaded');
                if (!isLoaded && !target.hasClass('lazy_2_loading')) {
                    let rowPicInfo = window._store.state.rowPics[rowId];
                    if (!rowPicInfo) {
                        return;
                    }
                    let srcOriginal = rowPicInfo[device];
                    // if(!srcOriginal.length && device == "mobi"){
                    //     srcOriginal = rowPicInfo["pc"] || "";
                    // }
                    if (!srcOriginal.length) {
                        return;
                    }
                    _loadImg(target, srcOriginal);
                }
                reslove({ val });
            }
        }
    });
};
// 监听device变化，值变更则触发
/**
 * @function name - updateLazyloadByDevice
 * @description 访客态根据device的变化,更新通栏的背景。
 * @author mikey.joman
 * @private
 * @date 2022-04-06
 * @example
 */
function updateLazyloadByDevice() {
    if (!store.state.manageMode) {
        device.on(device.EVENTS.ON_CHANGE, () => {
            $(ROW_BG_DEFAULT_DATA.observerClass).each(function (_, item) {
                //强制更新图片
                let target = $(item);
                let rowId = '';
                const device = window._store.state.device;
                if (target.hasClass('jz_web_row_line')) {
                    let webRow = target.find('.jz_web_row:first-child');
                    if (device == 'mobi') {
                        target.css('background-image', '');
                        target = webRow;
                        rowId = target.attr('id').slice(3);
                    } else {
                        webRow.css('background-image', '');
                        rowId = webRow.attr('id').slice(3);
                    }
                } else {
                    rowId = target.attr('id').slice(3);
                }
                if (target.length && window._store.state.rowPics[rowId]) {
                    let srcOriginal = window._store.state.rowPics[rowId][device] || '';
                    if (!srcOriginal.length && device == 'mobi') {
                        srcOriginal = window._store.state.rowPics[rowId]['pc'];
                    }
                    if (srcOriginal) {
                        srcOriginal = `url(${srcOriginal})`;
                    }
                    target.css('background-image', srcOriginal);
                }
            });
        });
    }
}
isTopPage && updateLazyloadByDevice();
/**
 * --------------------------------------------------------------------------------------
 * 交叉观察器的配置数据
 * --------------------------------------------------------------------------------------
 */

const LAZY_IMG_DATA = {
        ...LAZY_IMG_DEFAULT_DATA,
        runCallBack: _lazyImgObserve, // 执行回调必须返回一个promise 监听器的执行函数 执行结束后 finishCallBack
        finishCallBack: _imgFnish,
    },
    MODULE_CAROUSEL_PHOTOS = {
        ...MODULE_CAROUSEL_PHOTOS_DEFAULT_DATA,
        runCallBack: _carouselPhotosObserve,
    },
    CAROUSEL_ROW_DATA = {
        ...CAROUSEL_ROW_DEFAULT_DATA,
        runCallBack: _carouselRowObserve,
    },
    ROW_BG_DATA = {
        ...ROW_BG_DEFAULT_DATA,
        runCallBack: _rowBgObserve,
    },
    VIDEO_DATA = {
        ...VIDEO_DEFAULT_DATA,
        runCallBack: (val) => {
            return new Promise(() => {
                let target = $(val.target);
                if (target[0].tagName !== 'VIDEO') {
                    target = target.find('video').eq(0);
                }
                if (val && val.isIntersecting && target) {
                    let videoPath = target.attr('data-original') ?? '';
                    let posterPath = target.attr('data-poster');

                    if (target.attr('src') !== videoPath) {
                        target.attr('src', videoPath);
                    }

                    if (target.attr('poster') !== posterPath) {
                        const posterEl = target.siblings('.vjs-poster').eq(0);
                        if (posterEl) {
                            posterEl.css('backgroundImage', "url('" + posterPath + "')").removeClass('vjs-hidden');
                        } else {
                            target.attr('poster', posterPath);
                        }
                    }
                }
            });
        },
    };

/**
 * -----------------------------------------------------------------
 * author mackie 20191111 光棍节
 * 图片Lazyload 2.0 IntersectionObserver API
 * 自适应没有进行图片压缩，导致图片尺寸较大，加载时间较长，影响宽带成本
 * webP格式 同时提供了有损压缩与无损压缩（可逆压缩）的图片文件格式
 * 示例列表多图 由于IE不支持这个api 需要对ie兼容处理
 * 文档：https://developer.mozilla.org/zh-CN/docs/Web/API/IntersectionObserver
 *
 * 指令示例
 * 如果是ssr模块 需要加上:src-original="src"
 * v-lazyload2="{src}"
 *
 * -----------------------------------------------------------------
 */
/** ------- 图片懒加载 暴露函数 -------*/

/**
 * @function name - isSupportWebp
 * @description 检查是否支持webp格式图片 base JZ
 * @example
 * isSupportWebp()
 * @return {Boolean}
 */

let isSupportWebp = () => {
    var supportWebp = false;

    try {
        supportWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } catch (err) {
        console.log(err);
    }

    isSupportWebp = () => supportWebp;

    return supportWebp;
};

/**
 * 判断路径是否获得图片服务的支持
 * 图片服务功能:
 *  1. webp
 *  2. 裁剪
 * 只有文件上传的图片才能获得支持
 * 素材库、前端静态文件不支持
 * @param {string} path
 * @returns
 */
function isSupportImageService(path = '') {
    const PATH_REG = /(\.alydev-faiusr\.faisco\.net)|(\.jzaliusr\.com)/;
    return (
        PATH_REG.test(path) &&
        !/(\/materialLib\/)/.test(path) &&
        !path.includes(window._store.state.resRoot) &&
        /![\d]+x[\d]+/.test(path) === false // 已经存在缩略图则不支持
    );
}

/**
 *
 * @function name - img2Webp
 * @description 转成webp格式 base JZ
 * @param path  {String} - 图片地址
 * @param webpOptOpen {boolean} - 启用图片加速
 * @example
 * img2Webp('//1.s140i.aaadns.com.mackie.dev.cc/2/AIwBCAAQAhgAIKjXmfIFKNP81FAw9AM49wI.jpg', true)
 * // => //1.s140i.aaadns.com.mackie.dev.cc/2/AIwBCAAQAhgAIKjXmfIFKNP81FAw9AM49wI.jpg.webp
 * @return  {String} - src地址
 */

let img2Webp = function (path = '', webpOptOpen) {
    if (isSupportWebp() && webpOptOpen) {
        if (isSupportImageService(path)) {
            path = path.replace(
                /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/,
                function (match, key1, key2, key3) {
                    if (key1) {
                        // 匹配图片结尾，没有带版本号
                        return key1 + '.webp';
                    } else if (key2) {
                        // 匹配图片结尾，有带版本号，如 png?v=2018
                        return match.replace(key2, key2 + '.webp');
                    } else if (key3) {
                        // 匹配图片结尾，有带版本号，如 png?v=2019
                        return match.replace(key3, key3 + '.webp');
                    }
                }
            );
        }
        img2Webp = (path = '') => {
            if (isSupportImageService(path)) {
                path = path.replace(
                    /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/,
                    function (match, key1, key2, key3) {
                        if (key1) {
                            // 匹配图片结尾，没有带版本号
                            return key1 + '.webp';
                        } else if (key2) {
                            // 匹配图片结尾，有带版本号，如 png?v=2018
                            return match.replace(key2, key2 + '.webp');
                        } else if (key3) {
                            // 匹配图片结尾，有带版本号，如 png?v=2018
                            return match.replace(key3, key3 + '.webp');
                        }
                    }
                );
            }
            return path;
        };
    } else {
        img2Webp = (path = '') => path;
    }

    return path;
};

/** ------- 图片懒加载 暴露函数 -------*/

/** ------- 动画 暴露函数 ------- */

/**
 * @function name - initAnimationObserver
 * @description 动画监听函数 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @example
 * initAnimationObserver($(div)[0])
 * // => 监听$(div)[0]动画
 * @param [el] {Object|Array} - dom对象或者dom对象数组
 */

const initAnimationObserver = InterSectionObserApi.initAnimationObserver;

/**
 * @function name - animationUnobserve
 * @description 解除动画监听函数 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @example
 * animationUnobserve($(div)[0])
 * 解除$(div)[0]动画监听
 * @param [el] {Object} - dom对象
 */

const animationUnobserve = InterSectionObserApi.animationUnobserve;

/**
 * @function name - isObserveEl
 * @description 判断元素是否被监听函数 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @param el {Object} - dom对象
 * @param id {Number} - 监听器id  例如图片懒加载监听器 0
 * @example
 * isObserveEl($(img)[0], 0)
 * @returns {Boolean}
 */

const isObserveEl = InterSectionObserApi.isObserveEl;

/** ------- 动画 暴露函数 ------- */

/**
 * @function name - getModuleCommPropAttr
 * @description 获取模块数据函数 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @param [moduleId] {Number} - 模块id
 * getModuleCommPropAttr(213)
 * @returns {Object}
 */

const getModuleCommPropAttr = (moduleId) => {
    return digGet(window._store.state.modules, [`module${moduleId}`, 'commProp']);
};

/**
 * @name name - _sectionObserverData
 * @description 监听器数据 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * Object
 */
let observeDdata = [LAZY_IMG_DATA, MODULE_CAROUSEL_PHOTOS, CAROUSEL_ROW_DATA, VIDEO_DATA];
if (isTopPage && !store.state.manageMode) {
    observeDdata.push(ROW_BG_DATA);
}
const _sectionObserverData = {
    // 监听器数据
    componentList: observeDdata,
};

/**
 * @function name - initSectionObserver
 * @description 初始化所有的交叉观察器
 * 目前有图片懒加载，动画，轮播多图自动轮播
 * base JZ
 * @author mikey.chuhui
 * @date 2020-01-19
 * @param [options] {Object} - 监听器实例的对象
 * @example
 * initSectionObserver(_sectionObserverData)
 * @returns {Object}
 * SectionObserver类 在res\site\js\rjs\partition\r_plugins.js\IntersectionObserver.js
 */

const initSectionObserver = (options) => {
    bindLogEvents();

    InterSectionObserApi.SectionObserver.use(options.componentList || []);
    return InterSectionObserApi.SectionObserverMap.get('interSectionObserver1');
};

function bindLogEvents() {
    $(window).on('beforeunload', () => {
        const logs = thumbLogs.map((log) => ({
            event: 'jz_tpsltsysj',
            properties: log,
        }));
        jzRequest
            .post('/ajax/log_h.jsp', {
                data: {
                    cmd: 'wafNotCk_logJZFdpListTrack',
                    fdpList: JSON.stringify(logs),
                },
            })
            .catch((value) => {
                console.log(value);
            });
    });
}

export {
    isSupportWebp,
    img2Webp,
    initAnimationObserver,
    animationUnobserve,
    isObserveEl,
    getModuleCommPropAttr,
    _sectionObserverData,
    initSectionObserver,
    unobserveLazyloadImage,
    resetJumpToAnchor,
    _loadImg,
};
