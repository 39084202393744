var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "module_content_detail icon_combination",
      class: _vm.classes,
    },
    [
      _c(_vm.renderComponent, {
        tag: "component",
        attrs: { module: _vm.module },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }