import request from '@jz/request';

export function getAuthCode(params) {
    return request
        .post('/api/guest/visitorsRealName/sendMobilePhoneVerifyCode', {
            params,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function checkVisitorsRealName(messageType) {
    return request
        .post('/api/guest/visitorsRealName/checkVisitorsRealName', {
            params: {
                messageType,
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function validateAuthCode(phoneNumber, authCode) {
    return request
        .post('/api/guest/visitorsRealName/checkMobilePhoneVerifyCode', {
            params: {
                phoneNumber,
            },
            data: {
                verifyCode: authCode,
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCaptchaAccessKey(captchaType) {
    return request
        .get('/api/guest/visitorsRealName/getCaptchaAccessKey', {
            params: {
                captchaType,
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}
