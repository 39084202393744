var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.serv.show && _vm.isMobiShow,
          expression: "serv.show && isMobiShow",
        },
        {
          name: "hovermenu",
          rawName: "v-hovermenu",
          value: _vm.hovermenu,
          expression: "hovermenu",
        },
      ],
      class: ["s_spserv__wrap", _vm.styleClass, _vm.specialBottomClass],
      style: _vm.dragStyle,
    },
    [
      _vm.styleType != 2
        ? _vm._l(_vm.serv.list, function (item, index) {
            return _c(
              "a",
              {
                key: index,
                class: ["s_spserv__item"],
                style: _vm.selfThemeBg(index),
                attrs: {
                  href: _vm.getJumpUrl(item.link.jumpUrl),
                  target: _vm.getTarget(item),
                  "data-content": item.content,
                  onclick: _vm.jumpOnclickStr(item),
                  rel: _vm.isScriptHref(item.link.jumpUrl) ? "nofollow" : "",
                },
                on: _vm._d({}, [
                  _vm.deviceEnterEvent,
                  function ($event) {
                    return _vm.setCurIndex($event, index)
                  },
                  _vm.deviceLeaveEvent,
                  function ($event) {
                    return _vm.setCurIndex($event, -1)
                  },
                ]),
              },
              [
                _c("div", {
                  staticClass: "s_spserv__pic icon-",
                  style: [
                    _vm.getBgUrl(item),
                    _vm.selfThemeFont(index),
                    _vm.whiteColor(index),
                  ],
                  domProps: { innerHTML: _vm._s(_vm.getFontPic(item)) },
                }),
                _vm._v(" "),
                _vm.styleType == 1
                  ? _c(
                      "span",
                      {
                        class: ["s_spserv__name s_spserv__line-2"],
                        style: [_vm.nameStyle, , _vm.selfThemeFont(index)],
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.name) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.serv.hs &&
                ((_vm.styleType == 1 && item.content.length) ||
                  _vm.styleType != 1 ||
                  item.servType == 5)
                  ? _c("hover-area", {
                      class: [_vm.blockHover(index), _vm.fixedDragClass],
                      attrs: { item: item, serv: _vm.serv },
                      on: { changeCurIndex: _vm.resetCuIndex },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        : [
            _c(
              "div",
              {
                class: ["s_spserv__term s_spserv__term-concat"],
                style: _vm.themeFontColor,
                on: _vm._d({}, [
                  _vm.deviceEnterEvent,
                  function ($event) {
                    return _vm.setCurIndex($event, _vm.contactMe)
                  },
                  _vm.deviceLeaveEvent,
                  function ($event) {
                    return _vm.setCurIndex($event, -1)
                  },
                ]),
              },
              [
                _c("div", {
                  staticClass: "s_spserv__concatpic icon-",
                  domProps: { innerHTML: _vm._s(_vm.iconCode) },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "s_spserv__concatwe" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.LS.contact_us) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "s_spserv__hoverarea",
                      _vm.blockHover(_vm.contactMe),
                      _vm.fixedDragClass,
                    ],
                    style: _vm.bgStyle,
                  },
                  [
                    _c("div", { staticClass: "s_spserv_closewrap" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "s_spserv__close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setCurIndex($event, -1)
                            },
                          },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon_material_close" },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.filterWxList, function (item, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "s_spserv__servterm",
                          attrs: {
                            href: _vm.getJumpUrl(item.link.jumpUrl),
                            target: _vm.getTarget(item),
                            "data-content": item.content,
                            onclick: _vm.jumpOnclickStr(item),
                            rel: _vm.isScriptHref(item.link.jumpUrl)
                              ? "nofollow"
                              : "",
                          },
                          on: _vm._d({}, [
                            _vm.deviceEnterEvent,
                            function ($event) {
                              return _vm.setInnerCurIndex($event, index)
                            },
                            _vm.deviceLeaveEvent,
                            function ($event) {
                              return _vm.setInnerCurIndex($event, -1)
                            },
                          ]),
                        },
                        [
                          _c("div", {
                            staticClass: "s_spserv__areapic icon-",
                            style: [_vm.getBgUrl(item)],
                            domProps: {
                              innerHTML: _vm._s(_vm.getFontPic(item)),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "s_spserv__contextwrap" }, [
                            item.name.length
                              ? _c(
                                  "span",
                                  {
                                    class: ["s_spserv__ib s_spserv__termname"],
                                    style: _vm.selfInnerThemeFont(index),
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.name) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.content.length
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "s_spserv__ib s_spserv__termcontext",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.content) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.wxServList, function (wxServ, index) {
              return [
                _vm.wxServList.length
                  ? _c(
                      "a",
                      {
                        key: index,
                        class: ["s_spserv__term s_spserv__term-wx"],
                        style: _vm.themeFontColor,
                        attrs: {
                          href: _vm.getJumpUrl(wxServ.link.jumpUrl),
                          target: _vm.getTarget(wxServ),
                          "data-content": wxServ.content,
                          onclick: _vm.jumpOnclickStr(wxServ),
                          rel: _vm.isScriptHref(wxServ.link.jumpUrl)
                            ? "nofollow"
                            : "",
                        },
                        on: _vm._d({}, [
                          _vm.deviceEnterEvent,
                          function ($event) {
                            return _vm.setCurIndex($event, _vm.wxQrcode - index)
                          },
                          _vm.deviceLeaveEvent,
                          function ($event) {
                            return _vm.setCurIndex($event, -1)
                          },
                        ]),
                      },
                      [
                        _c("div", {
                          staticClass: "s_spserv__wxicon icon-",
                          style: _vm.getBgUrl(wxServ),
                          domProps: {
                            innerHTML: _vm._s(_vm.getFontPic(wxServ)),
                          },
                        }),
                        _vm._v(" "),
                        _c("hover-area", {
                          class: [
                            _vm.blockHover(_vm.wxQrcode - index),
                            _vm.fixedDragClass,
                          ],
                          attrs: { item: wxServ, serv: _vm.serv },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }