//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {
    components: {},
};
import { initJumpWxApp } from '@/site/wx.js';
import { linkTarget, linkNofollow } from './utils.js';
import { createLinkWithSideEffect } from '../jumpLink';
import { Comm } from '../../utils.js';
import { initModuleTransformTrack } from '@/components/transformTrack.js';
export default {
    name: 'ButtonModule',
    style: 8,
    components: {
        ...manageUse.components,
    },
    props: ['module'],

    data() {
        return {
            isContentHover: false,
            render: true,
            singleClick: false,
        };
    },

    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        ...Vuex.mapState('manage/editPanel', ['moduleEditorActiveId']),
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        isMobi() {
            return this.device === 'mobi';
        },
        emptyText() {
            return !this.module.content;
        },
        jumpOnclickStr() {
            if (this.manageMode && !this.singleClick) {
                return null;
            }
            const { reqArgs = '' } = this.module.prop3 || {};
            return Comm.createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },

        edit: {
            get() {
                return this.moduleEditorActiveId === this.module.id;
            },

            set(val) {
                window._store.commit('manage/editPanel/moduleEditorActive', val ? this.module.id : null);
            },
        },

        style() {
            return this.module.prop0;
        },

        afterClass() {
            if (this.style == 5) {
                return {
                    jz_theme_bg_color: true,
                };
            }

            return {};
        },
        moduleClasses() {
            var classes = {}; //跟随主题类

            switch (this.style) {
                case 0:
                    classes.bg = true;
                    break;

                case 1:
                    classes.bg = true;
                    classes.border = true;
                    classes.hover = true;
                    classes.fontHoverColor = true;
                    break;

                case 2:
                    classes.bg = true;
                    break;

                case 3:
                    classes.hoverBorder = true;
                    classes.hover = true;
                    classes.fontHoverColor = true;
                    break;

                case 4:
                    classes.hoverBg = true;
                    classes.hover = true;
                    classes.border = true;
                    classes.fontColor = true;
                    break;

                case 5:
                    //classes.hoverBg = true;
                    //classes.hover = true;
                    classes.border = true;
                    classes.fontColor = true;
                    break;
            }

            let responsive_height = this.module.privatePattern[this.device].lock === 1;

            if (this.isMobi && this.module.privatePattern.mobi.pcht === 0 && this.module.privatePattern.pc.pcht === 1) {
                responsive_height = this.module.privatePattern.pc.lock === 1;
            }

            return {
                jz_theme_bg_color: classes.bg,
                jz_hover: classes.hover,
                jz_theme_bg_hover_color: classes.hoverBg,
                jz_theme_border_hover_color: classes.hoverBorder,
                jz_theme_border_color: classes.border,
                jz_theme_font_color: classes.fontColor,
                jz_theme_font_hover_color: classes.fontHoverColor,
                [`module_button_style${this.style}`]: true,
                button_icon_in_left: this.module.privatePattern.pc.pi.d === 1,
                responsive_height,
            };
        },

        afterStyle() {
            const style = {};

            if (!this.manageMode) {
                return;
            }

            this.info.themeColor;

            if (this.module.privatePattern.pc.pbg.y == 1) {
                style.background = this.module.privatePattern.pc.phbg.c;
            } else {
                if (this.style == 2) {
                    // libs comm 的方法
                    style.background = Comm.burnColor(this.info.themeColor, 5);
                }
            }

            return style;
        },

        buttonStyle() {
            if (!this.manageMode) {
                return;
            }

            this.info.themeColor;
            const style = calcButtonStyle(this.module.privatePattern.pc);
            this.edit && (style['cursor'] = 'text');
            return style;
        },

        finalRenderPattern() {
            const pcPattern = this.module.privatePattern.pc;
            const mobiPattern = this.module.privatePattern.mobi;
            if (this.isMobi) {
                if ((mobiPattern.pft?.y ?? 0) === 0) {
                    // 默认使用PC
                    return pcPattern;
                } else {
                    return mobiPattern;
                }
            } else {
                return pcPattern;
            }
        },

        buttonTextStyle() {
            if (!this.manageMode) {
                if (this.module.pattern.pc.rf !== 0) {
                    return {};
                }
                // 这里要让适应宽度的css匹配到，所以要把fontSize写出来
                const style = this.finalRenderPattern;
                if (style.pft.y === 1) {
                    return { fontSize: `${style.pft.s}px` };
                }
                return {};
            }

            this.info.themeColor;

            let style = calcButtonTextStyle(this.finalRenderPattern);

            if (this.isContentHover && !this.isMobi) {
                style = {
                    ...style,
                    ...calcButtonTextHoverStyle(this.module.privatePattern.pc, this.style),
                };
            }

            return style;
        },

        buttonHoverStyle() {
            if (!this.manageMode) {
                return;
            }

            const style = calcButtonHoverStyle(this.module.privatePattern.pc, this.style);
            const hoverBg = this.module.privatePattern.pc.phbg.c;
            const isTransparent = ['rgba(255,255,255,0)', 'transparent', 'none'].includes(hoverBg.replace(/\s/g, '')); //兼容选中颜色为透明的情况

            if (isTransparent) {
                style.background = 'transparent';
            }

            return style;
        },

        isJumpWxApp() {
            return this.module.blob0.jumpMode == 1;
        },

        link() {
            if (this.isJumpWxApp) {
                return this.manageMode ? 'javascript:Root.Message.warning("请在微信端打开链接");' : 'javascript:;';
            }

            if (this.module.prop3 && this.module.prop3.url) {
                const { url } = this.module.prop3;
                this.$nextTick(() => {
                    createLinkWithSideEffect(url, this.$refs['button']);
                });

                if (url.indexOf('Site.hoverQrCode(') === -1) {
                    return url;
                } else {
                    return 'javascript: void 0';
                }
            } else {
                return null;
            }
        },

        linkTarget() {
            if (this.isJumpWxApp) {
                return null;
            }

            const isBlank =
                this.link !== 'javascript: void 0' &&
                this.module.prop3 &&
                this.module.prop3.url &&
                this.module.prop3.ib; // 地址不存在 或者 不能跳转的 不新标签打开

            return linkTarget(isBlank);
        },

        linkNofollow() {
            return linkNofollow(this.module.prop3.nf);
        },

        btnPattern() {
            return this.module.privatePattern.pc || {};
        },

        btnIconSetting() {
            return this.btnPattern.pi || {};
        },

        isIcon() {
            return this.btnIconSetting.ft == 0;
        },

        btnIconImgPath() {
            return this.btnIconSetting.fp;
        },

        bntIconPath() {
            return `&#x${this.btnIconSetting.fi}`;
        },

        showIcon() {
            return (
                this.btnIconSetting.t == 1 &&
                this.btnIconSetting.fi != '' &&
                (!this.module.isIE || !this.module.showInIELess10)
            );
        },

        btnIconStyle() {
            let style = {};

            if (this.manageMode) {
                style['font-size'] = `${this.btnIconSetting.is}px`;

                if (!this.isEmptyColor(this.btnIconSetting.dc)) {
                    style['color'] = `${this.btnIconSetting.dc}`;
                }
            }

            return style;
        },

        btnIconTextStyle() {
            let style = {};

            if (this.manageMode) {
                let style1 = this.buttonTextStyle;
                Object.assign(style, style1);
                style['max-width'] = `calc(100% - ${this.btnIconSetting.is + 6}px)`;
            }

            return style;
        },
    },

    mounted() {
        this.initJumpWxApp();
        this.initIconHoverColor();

        if (!this.manageMode) {
            initModuleTransformTrack(this.module.id, this.module.style, this.module.blob0.tts);
            return;
        }

        const $module = $(this.$refs.button);
        $module.dblclick((e) => {
            if (this.device === 'mobi') return;
            clearTimeout(timer);
            e.preventDefault();
            this.edit = true;

            if (this.showIcon) {
                $module.find('.module_button_text').css('text-overflow', 'initial');
            }
        });
        $module.mousedown((e) => {
            if (this.edit) e.stopPropagation();
        });
        $(document).mousedown(() => {
            if (this.edit) {
                this.edit = false;
                window._store.commit('manage/editPanel/editPanelActiveChange', false);
                if (this.showIcon) {
                    $module.find('.module_button_text').css('text-overflow', '');
                }
            }
        }); //双击防跳转

        let timer = null;
        let jump = false;
        $module.click((e) => {
            if (this.edit) {
                e.preventDefault();
                e.stopPropagation();
                return;
            }

            if (jump) {
                jump = false;
                return;
            }

            jump = false;
            e.preventDefault();
            e.stopPropagation();
            clearTimeout(timer);
            timer = setTimeout(() => {
                this.singleClick = true;
                this.$nextTick(() => {
                    jump = true;
                    $module[0].click();
                    this.singleClick = false;
                });
            }, 200);
        });
    },

    created() {
        if (this.manageMode) {
            const fontData = this.module.prop5;

            if (fontData.useFontFace) {
                Site.initFontFace([fontData]);
            }
        }
    },

    methods: {
        handleTextBlur() {
            // 如果是fontface字体，重新加载
            const $editor = $(this.$refs.editor.$el);
            const text = $editor.text();

            if (this.showIcon) {
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                });
            }

            let fontFamily = $editor.css('font-family') || '';
            const reg = /fontface__(.*?)__(\d+)__(\d+)/;
            const regData = reg.exec(fontFamily);

            if (regData && regData[0]) {
                fontFamily = regData[0];
            }

            const { flag } = Site.analysisCustomFontFaceFamily(fontFamily);
            if (flag !== 'fontface') return;

            if (Site.isSupportFontCache()) {
                const { id, random } = Site.analysisCustomFontFaceFamily(fontFamily);
                this.module.prop5 = {
                    useFontFace: true,
                    fontFamily,
                    id: random,
                    substring: text,
                    fontId: +id,
                };
                return;
            } // 不兼容fontcache逻辑，重置style

            Site.resetHeadFontFace(fontFamily, text, (newFontFamily) => {
                const { random, id } = Site.analysisCustomFontFaceFamily(newFontFamily);
                this.module.prop5 = {
                    useFontFace: true,
                    fontFamily: newFontFamily,
                    id: random,
                    substring: text,
                    fontId: +id,
                };
                this.module.privatePattern.pc.pft.f = newFontFamily;
            });
        },

        initJumpWxApp() {
            if (this.manageMode) return;

            if (this.isJumpWxApp) {
                initJumpWxApp(this.module.id, this.module.blob0.jumpWxAppData);
            }
        },

        initIconHoverColor() {
            if (this.manageMode) {
                updateCssStyleById({
                    id: 'btnModule',
                    css: [
                        {
                            cls: `#module${this.module.id} .module_button_content:hover .module_button_icon`,
                            key: 'color',
                            value: this.isEmptyColor(this.btnIconSetting.sc)
                                ? ''
                                : `${this.btnIconSetting.sc} !important`,
                        },
                    ],
                });
            }
        },

        isEmptyColor(str) {
            return str == 'rgba(255, 255, 255, 0)' || str == '';
        },
    },
};
