import request from '@jz/request';

export function setFileDownloadPath(data) {
    return request.post('/rajax/site_h.jsp?cmd=setWafCk_setFileDownloadPath', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getFileDownloadPath(data) {
    return request.post('/rajax/site_h.jsp?cmd=getWafNotCk_getFilePath', { data }).then(({ data = {} }) => {
        return data;
    });
}
