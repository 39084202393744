//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getComputedBg } from '../../../manage/designer/initContext/utils';
import { Comm } from '../../utils.js';
import Project from '../../Project.vue';

const manageComponents = {};

// 列组件
export default {
    name: 'jz-web-col',
    components: {
        Project,
        ...manageComponents,
    },
    inject: ['isSysRow', 'rowNewResponsiveConversion', 'rowNewMobiResponsiveConversion'],
    props: ['col', 'rowid', 'row', 'carouselIndex', 'cloneModuleId', 'carouselCols'],
    data() {
        return {
            colManageInstance: null,
        };
    },
    computed: {
        ...Vuex.mapState('row', ['rowEditing']),
        ...Vuex.mapState('manage/auth', ['designAuth', 'moduleManageAuth']),
        ...Vuex.mapState([
            'device',
            'rowCol',
            'manageMode',
            'newResponsiveConversion',
            'newMobiResponsiveConversion',
            'templateNewResponsiveConversion',
            'templateNewMobiResponsiveConversion',
        ]),
        ...Vuex.mapGetters(['info', 'isFullScreen']),
        funcPanel() {
            return this.$store.state.manage.funcPanel;
        },
        isCarouselRow() {
            // 是否是轮播通栏
            return this.$store.getters.isCarouselRow(this.row.id);
        },
        index() {
            return this?.cols?.indexOf(this.col);
        },
        cols() {
            if (this.isCarouselRow) {
                return this.curRowPattern?.['pc']?.e?.rbs?.[this.carouselIndex]?.cols || {};
            }
            return this.row.cols;
        },
        mIds() {
            var curMIds = [];
            var mIds = this.col.mIds,
                id = null;
            for (var i = 0; i < mIds.length; i++) {
                id = mIds[i];
                if (id) {
                    if (Comm.isSectionId(id)) {
                        //部件id
                        curMIds.push(id);
                    } else {
                        //模块id
                        const _module = this.$store.state.modules['module' + id];
                        if (_module) {
                            if (!(this.$store.state.colId === 2 && _module.style === 34)) {
                                curMIds.push(id);
                            }
                        } else {
                            if (this.manageMode) {
                                delModuleData(id);
                            }
                        }
                    }
                }
            }
            // return curMIds.filter(e => e !== 2421);

            return curMIds;
        },
        type() {
            return this.col.type;
        },
        rIds() {
            return this.col.rIds;
        },
        rows() {
            const curRows = [];
            for (const id of this.rIds) {
                const row = this.$store.state.rows['row' + id];
                row && curRows.push(row);
            }
            return curRows;
        },
        curRowPattern() {
            return this.$store.state.rows[`row${this.rowid}`].pattern;
        },
        colOrder() {
            // //mobi专用，改组件还没有做到服务器渲染，所以设备限制放在css做
            return this.col.pattern.mo;
        },
        classes() {
            var classes = ['jz_web_col', 'jz_web_col' + this.index];
            if (this.isSysModuleCol) {
                classes.push('jz_col_sys_module');
            } else if (this.isNavCol) {
                classes.push('jz_col_sys_section');
            }
            this.isEmpty && classes.push('jz_col_empty');
            if (this.cols.length > 1) {
                classes.push('J_multi_cols');
            }
            // 判断是否是行嵌套
            if (this.isNestedRow) {
                classes.push('J_col_row');
            }
            if (this.checkImageScaleClass()) {
                // 图片模块列等高缩放
                classes.push('jz_col_module_image_contour');
            }
            classes.push('col_mobi_order_' + this.colOrder);

            classes = classes.reduce((o, c) => {
                o[c] = true;
                return o;
            }, {});
            return Object.assign(classes, this.classesEditing);
        },
        colPattern() {
            return this.col.pattern;
        },
        classesEditing() {
            let colClass = 'jz_col_' + this.colPattern.g;
            if (this.colPattern.g.toString().indexOf('.') !== -1) {
                colClass = 'jz_col_' + this.colPattern.g.toString().replace('.', '_');
            }
            return {
                [colClass]: this.colPattern.g,
            };
        },
        isSysModuleCol() {
            if (this.row.type == 2) {
                if (this.cols.length == this.index + 1) {
                    return true;
                }
            }
            return false;
        },
        isNavCol() {
            return this.mIds.includes(150);
        },
        isTitleCol() {
            return this.mIds.includes(153);
        },
        isMultiLanguageCol() {
            return this.mIds.includes(154);
        },
        isMemberLoginCol() {
            return this.mIds.includes(151);
        },
        isFooterCol() {
            return this.mIds.includes(152);
        },
        isEmpty() {
            if (this.type == 0) {
                return this.mIds.length == 0;
            } else if (this.type == 1) {
                return this.rows.length == 0;
            }
            return false;
        },
        pattern() {
            return this.col.pattern;
        },
        colStyle() {
            if (!this.manageMode) {
                return;
            }
            //如果mobi默认，就取pc的数据
            var pattern = this.pattern,
                bg = pattern.bg,
                border = pattern.b,
                shadow = pattern.bs,
                mobiBorder = pattern.mb2,
                bGstyle = getComputedBg(bg),
                borderStyleList = ['solid', 'dotted', 'dashed'],
                style = {},
                unit =
                    (this.newResponsiveConversion ||
                        (this.isSysRow && this.templateNewResponsiveConversion) ||
                        this.rowNewResponsiveConversion) &&
                    !this.isMobi
                        ? 'vw '
                        : '% ';
            if (shadow == 1 && !this.isMobi) {
                style.boxShadow = '0 0 12px 0 rgba(0,0,0,0.15)';
            }

            if (border.y == 1) {
                style.border = 'none';
            } else if (border.y == 2) {
                let borderStr = border.w + 'px ' + borderStyleList[border.s] + ' ' + border.c;
                border.t && (style.borderTop = borderStr);
                border.r && (style.borderRight = borderStr);
                border.b && (style.borderBottom = borderStr);
                border.l && (style.borderLeft = borderStr);
            }

            if (!this.isMobi || pattern.mbg.y == 0) {
                // 如果是手机设置了独立设置则手机不输出电脑样式
                Comm.extend(style, bGstyle);
            }

            if (pattern.mt || pattern.mr || pattern.mb || pattern.ml) {
                let _outerWidth = 1,
                    mobiClientWidth = 1;
                if (this.isMobi) {
                    if (
                        this.newMobiResponsiveConversion ||
                        (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                        this.rowNewMobiResponsiveConversion
                    ) {
                        // 新得边距换算逻辑
                        unit = 'vw ';
                        mobiClientWidth = 375;
                        _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                    }
                }
                style.padding = `${((pattern.mt * mobiClientWidth) / _outerWidth) * 100}${unit}${pattern.mr * 100}% ${
                    ((pattern.mb * mobiClientWidth) / _outerWidth) * 100
                }${unit}${pattern.ml * 100}%`;
            }

            if (!this.isMobi && pattern.m) {
                if (pattern.m.mt || pattern.m.mr || pattern.m.mb || pattern.m.ml) {
                    style.margin = `${pattern.m.mt * 100}${unit}${pattern.m.mr * 100}% ${pattern.m.mb * 100}${unit}${
                        pattern.m.ml * 100
                    }%`;
                }
            }

            if (this.isMobi) {
                let _outerWidth = 1,
                    mobiClientWidth = 1;
                if (
                    this.newMobiResponsiveConversion ||
                    (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                    this.rowNewMobiResponsiveConversion
                ) {
                    // 新得边距换算逻辑
                    unit = 'vw ';
                    mobiClientWidth = 375;
                    _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                }
                const mbgStyle = getComputedBg(pattern.mbg);
                if (mbgStyle.background !== '') {
                    Comm.extend(style, mbgStyle);
                }
                if (pattern.mbs == 1) {
                    style.boxShadow = '0 0 12px 0 rgba(0,0,0,0.15)';
                }

                if (mobiBorder.y == 1) {
                    style.border = 'none';
                } else if (mobiBorder.y == 2) {
                    let borderStr = mobiBorder.w + 'px ' + borderStyleList[mobiBorder.s] + ' ' + mobiBorder.c;

                    style.borderTop = mobiBorder.t ? borderStr : 'none';
                    style.borderRight = mobiBorder.r ? borderStr : 'none';
                    style.borderBottom = mobiBorder.b ? borderStr : 'none';
                    style.borderLeft = mobiBorder.l ? borderStr : 'none';
                }

                if (pattern.mm.t == 1) {
                    style.padding = `${((pattern.mm.mt * mobiClientWidth) / _outerWidth) * 100}${unit}${
                        pattern.mm.mr * 100
                    }% ${((pattern.mm.mb * mobiClientWidth) / _outerWidth) * 100}${unit}${pattern.mm.ml * 100}%`;
                }

                if (pattern.mm2.t == 1) {
                    style.margin = `${((pattern.mm2.mt * mobiClientWidth) / _outerWidth) * 100}${unit} 0 ${
                        ((pattern.mm2.mb * mobiClientWidth) / _outerWidth) * 100
                    }${unit} 0`;
                }
            }
            return style;
        },
        isMobi() {
            return this.device == 'mobi';
        },
        isMultiCols() {
            return this.cols.length > 1;
        },
        // 判断是否是行嵌套
        isNestedRow() {
            return this.$store.getters.isNestRow(this.row.id);
        },
        isFooterOrHeaderRows() {
            return (
                this.$store.state.responsiveInfo.footerRowIds.indexOf(this.row.id) !== -1 ||
                this.$store.state.responsiveInfo.headerRowIds.indexOf(this.row.id) !== -1
            );
        },
        memberLogin() {
            return this.info.pattern.memberLogin;
        },
        title() {
            return this.info.pattern.title;
        },
        multiLanguage() {
            return this.info.pattern.multiLanguage;
        },
        nav() {
            return this.info.pattern.nav;
        },
        footer() {
            return this.info.pattern.footer;
        },
        features() {
            return this.colManageInstance?.getFeatures() ?? [];
        },
        colResizableData() {
            return {
                col: this.col,
                rowid: this.rowid,
                row: this.row,
                isEmpty: this.isEmpty,
                index: this.index,
                cols: this.carouselCols,
            };
        },
        canAddMaterial() {
            if (this.isFullScreen) {
                return !this.isMultiCols && !this.isFooterOrHeaderRows && !this.isNestedRow;
            }
            return !this.isMultiCols && !this.isNestedRow;
        },
    },
    mounted() {
        if (this.manageMode && this.moduleManageAuth) {
            this.initColManageInstance();
        }
        this.addRowColId();
    },
    methods: {
        initColManageInstance() {
            this.colManageInstance = new ColManage();
            this.colManageInstance.registerFeatures();
            this.$watch(
                () => this.colResizableData,
                () => {
                    this.colManageInstance.setColResizableData(this.colResizableData);
                },
                { immediate: true }
            );
        },
        checkImageScaleClass() {
            // 图片列等高缩放
            let scaleClass = false,
                mIdsLen = 1,
                moduleIndex = 0;
            if (this.rowid === 3) {
                // 顶部特殊处理
                let headerType = this.isNavCol
                    ? this.nav.hide
                    : this.isTitleCol
                    ? this.title.h
                    : this.isMultiLanguageCol
                    ? this.multiLanguage.h
                    : this.memberLogin.h;
                headerType && (mIdsLen = 2);
            } else if (this.rowid === 4) {
                // 底部处理
                let footerType =
                    this.isFooterCol && (this.footer.support || this.footer.copyright.type || this.footer.admin);
                footerType && (mIdsLen = 2);
            }
            if (
                this.mIds.length > mIdsLen ||
                !!this.$store.state.fullScreen ||
                this.device === 'mobi' ||
                !this.isMultiCols
            )
                return scaleClass;

            let moduleId = this.mIds[moduleIndex],
                module = this.$store.state.modules[`module${moduleId}`];
            if (!module) return scaleClass;
            let styleId = module['style'];
            if (styleId !== 7) return scaleClass;
            let ist = module['prop2'];
            scaleClass = ist === 1;
            return scaleClass;
        },
        addMaterial() {
            this.$store.commit('manage/funcPanel/funcPanelChange', {
                active: true,
                type: 'module',
                second: 'MaterialAddPanel',
                showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
            });
            this.$store.commit('manage/material/setMaterialInsertRowId', this.rowid);
            Root.vm && Root.vm.$refs && Root.vm.$refs.jzHideSetBar && Root.vm.$refs.jzHideSetBar.handleChange(true);
        },
        addModule() {
            if (this.rowEditing) {
                return;
            }

            this.$store.commit('manage/funcPanel/funcPanelChange', {
                active: true,
                type: 'module',
                second: undefined,
                showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
            });
            global._temporary.addModuleArea = {
                rowId: this.rowid,
                colIndex: this.index,
            };
            Root.vm && Root.vm.$refs && Root.vm.$refs.jzHideSetBar && Root.vm.$refs.jzHideSetBar.handleChange(true);
        },
        projectKey(id) {
            if (!this.$store.state.modules || !this.$store.state.modules['module' + id]) {
                return id;
            } else {
                // let style = this.$store.state.modules['module' + id].style;
                // if (style == 20) {
                // 	return 'web_col_' + Math.random();
                // } else {
                return id;
                // }
            }
        },
        changePanelEditCol(e) {
            if (this.manageMode) {
                e.stopPropagation();
            }
            Root.changePanelEditCol && Root.changePanelEditCol(this.rowid, this.index);
            Root.changePanelEditRow && Root.changePanelEditRow(this.row.id);
        },
        addRowColId() {
            if (this.type == 1) {
                this.rows.forEach((value) => {
                    if (!this.rowCol.includes(value.id)) {
                        this.$store.commit('addRowCol', value.id);
                    }
                });
            }
        },
    },
};
