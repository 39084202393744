var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_news_result_content",
      class: _vm.classes,
    },
    [
      _vm.filterNewsList.length > 0
        ? [
            _c(
              "div",
              { staticClass: "news_result_wrap" },
              [
                _vm._l(_vm.filterNewsList, function (item, index) {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hovermenu",
                            rawName: "v-hovermenu",
                            value: { hovermenu: _vm.hovermenu, args: item.id },
                            expression: "{ hovermenu, args: item.id }",
                          },
                        ],
                        key: item.id,
                        staticClass:
                          "news_result_item_line jz_hover jz_theme_font_hover_color",
                        class: _vm.itemLineClass,
                        style: _vm.itemLineStyle,
                      },
                      [
                        _c("a", {
                          staticClass: "news_result_item_link",
                          attrs: {
                            href: item.url,
                            target: _vm.target,
                            onclick: _vm.onclickStr(item),
                          },
                        }),
                        _vm._v(" "),
                        _vm.module.prop0 != 0
                          ? _c("div", { staticClass: "news_result_img_box" }, [
                              _c("div", {
                                directives: [
                                  {
                                    name: "lazyload2",
                                    rawName: "v-lazyload2",
                                    value: {
                                      id: _vm.module.id,
                                      src: item.imgPath,
                                    },
                                    expression:
                                      "{ id: module.id, src: item.imgPath }",
                                  },
                                ],
                                staticClass: "news_result_img",
                                style: _vm.genImgStyle(item),
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "news_result_item_content" }, [
                          _c(
                            "div",
                            { staticClass: "news_result_item_header" },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "news_result_item_title jz_theme_font_hover_color jz_hover",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.decodeHtml(item.title)) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showDate
                                ? _c(
                                    "span",
                                    { staticClass: "news_result_item_date" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.decodeHtml(item.dateStr))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showSummary
                            ? _c(
                                "div",
                                { staticClass: "news_result_item_summery" },
                                [_vm._v(_vm._s(_vm.decodeHtml(item.summary)))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showGroup || _vm.module.prop0 == 2
                            ? _c(
                                "div",
                                { staticClass: "news_result_item_ext_line" },
                                [
                                  _vm.showGroup
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "news_result_item_group",
                                        },
                                        [
                                          _vm._l(
                                            item.groups,
                                            function (groupItem, index) {
                                              return [
                                                groupItem
                                                  ? _c(
                                                      "a",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "news_result_item_group_item",
                                                        attrs: {
                                                          href: groupItem.url,
                                                          target:
                                                            _vm.getItemTarget(
                                                              groupItem
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.decodeHtml(
                                                                groupItem.name
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.module.prop0 == 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "news_result_item_arrow_link",
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "news_result_item_arrow",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    index < _vm.filterNewsList.length - 1 &&
                    _vm.module.prop0 != 2
                      ? _c("div", {
                          staticClass:
                            "news_result_parting_line jz_parting_line",
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : [
            _c("div", { staticClass: "jz_empty_tips" }, [
              _c("i", { staticClass: "jz_empty_tips__img" }),
              _vm._v(" "),
              _c("div", { staticClass: "jz_empty_tips__text" }, [
                _vm._v(_vm._s(_vm.noResult)),
              ]),
            ]),
          ],
      _vm._v(" "),
      _vm.flagPagination
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.getNewsList,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }