export const getOnlineFormAnimation = function (id) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        j = 0,
        $dom;

    $dom = $module.find('.form_item_wrap');
    if ($dom.length < 0) {
        return;
    }

    $dom.each(function () {
        domSeqList[j++] = Array.from($(this));
    });

    return domSeqList;
};
