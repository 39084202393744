//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message } from '@shared/manage/componMessage/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { getPreviewSize, previewFile } from '@jz/biz-shared';
import { isBrowserEnv } from '@jz/utils';
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
export default {
    name: 'FileUpload',
    components: {
        ImageMainViewer,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: [Object, Array],
            default: () => ({}),
        },
        fileSizeLimit: {
            type: Number,
            default: 50,
        },
        contentId: {
            type: Number,
            default: 0,
        },
        requiredIcon: Boolean,
        logEventConfig: {
            type: Object,
            default: () => ({
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-在线表单',
                    jz_version: isBrowserEnv() ? window._store.state.jzVersion : 0,
                },
            }),
        },
    },

    data() {
        return {
            uploadPreviewFileList: [],
            showPcViewer: false,
            showMobiViewer: false,
            zIndex: 9999,
            imageIndex: 0,
            sumbitFileList: [],
            upload: null,
        };
    },

    computed: {
        ...Vuex.mapState(['manageMode', 'device', 'siteVer', 'oem', 'LS']),
        siteFormSubmitFileUploadTips() {
            return this.LS.siteFormSubmitFileUploadTips;
        },
        noteTips() {
            return this.LS.js_note;
        },

        uploadBtnClass() {
            const _class = {};
            _class['upload_btn--disable'] = this.uploadPreviewFileList.length >= this.fileNumLimit;
            return _class;
        },

        previewFileListClass() {
            let result = 'preview_file_list--1';

            switch (this.uploadPreviewFileList?.length) {
                case 0:
                case 1:
                    result = 'preview_file_list--1';
                    break;

                case 2:
                    result = 'preview_file_list--2';
                    break;

                default:
                    result = 'preview_file_list--3';
                    break;
            }

            return result;
        },

        uploadFileBtnText() {
            return this.LS.js_upload_file;
        },

        previewSrcList() {
            return this.uploadPreviewFileList.filter((item) => item.fileType === 'image');
        },

        fileLimitSize() {
            let fileSize = this.item.size;
            let fileLimitSize = this?.fileSizeLimit || 50;

            if (this.oem) {
                if (this.siteVer <= 120) {
                    fileLimitSize = 10;
                }
            } else {
                if (this.siteVer <= 10) {
                    fileLimitSize = 1;
                }
            }

            if (fileSize > fileLimitSize) {
                return fileLimitSize;
            } else {
                return fileSize;
            }
        },

        fileNumLimit() {
            return this.item?.fileNumLimit ?? 1;
        },

        fileLimitTips() {
            return this.format(this.LS.siteFormUploadTips ?? '', this.fileNumLimit, this.fileLimitSize);
        },

        isMobi() {
            return this.device == 'mobi';
        },
    },

    mounted() {
        this.initUpload();
    },

    methods: {
        eqStatus(status, target) {
            return status === target;
        },

        isSuccess(status) {
            return this.eqStatus(status, 'success');
        },

        isError(status) {
            return this.eqStatus(status, 'error');
        },

        isLoading(status) {
            return this.eqStatus(status, 'loading');
        },

        progressBgStyle(percent) {
            return {
                width: `${percent}%`,
            };
        },

        initUpload() {
            const that = this;
            var fileSize = this.fileLimitSize;
            var fileTypeList = '*.*';

            if (!this.item.isAllFile) {
                //自定义类型
                fileTypeList = ((this.item.dftl || '') + (this.item.cftl || ''))
                    .split(' ')
                    .filter((item) => item)
                    .map((item) => `*.${item}`)
                    .join(';');
            }

            const { fileNumLimit = 1 } = this.item;
            var advance_setting = {
                siteFree: false,
                //是否为免费版
                updateUrlViewRes: '',
                //升级目标版本URL
                auto: true,
                fileTypeExts: fileTypeList,
                multi: false,
                fileSizeLimit: fileSize * 1024 * 1024,
                fileSizeLimitTips: this.siteFormSubmitFileUploadTips,
                fileSplitSize: 20 * 1024 * 1024,
                breakPoints: true,
                saveInfoLocal: false,
                showUploadedPercent: false,
                //是否实时显示上传的百分比，如20%
                showUploadedSize: false,
                removeTimeout: 9999999,
                post_params: {
                    app: 21,
                    type: 0,
                    fileUploadLimit: fileSize,
                    isSiteForm: true,
                    pieceUpload: true, // 开启断点续传
                },
                isBurst: true,
                //表单先不分片,因为断点续传后台还不支持。到时可以了再去掉    -- 开启分片
                isDefinedButton: true,
                buttonText: '',
                // uploader:"/ajax/commUpsiteimg_h.jsp?cmd=mobiUpload&_TOKEN="+$('#_TOKEN').attr('value'),
                uploader: '/ajax/advanceUpload.jsp?cmd=_mobiupload',
                //调用genUploadUrl 可改变url
                onUploadSuccess: (file, text) => {
                    var data = jQuery.parseJSON(text);

                    if (data.success) {
                        successMessage(that.format(this.LS.js_upload_success ?? '', file.name), true);
                        var tmp_file_id = data.id; //返回临时文件ID

                        var file_name = data.name; //返回文件名

                        var file_type = data.type; //返回文件类型

                        var file_id = data.fileId; //返回文件ID

                        var file_width = data.width || 0; //宽

                        var file_height = data.height || 0; //高

                        const fileItem = {};
                        const md5 = file?.md5 ?? file?.id;

                        if (that.uploadPreviewFileList.filter((fileDataItem) => fileDataItem.key === md5)?.length) {
                            fileItem['tmpFileId'] = tmp_file_id;
                            fileItem['tmpFileName'] = file_name;
                            fileItem['fileId'] = file_id;
                            fileItem['fileWidth'] = file_width;
                            fileItem['fileHeight'] = file_height;
                            fileItem['fileType'] = file_type;
                        }

                        that.uploadPreviewFileList.forEach((fileDataItem) => {
                            if (fileDataItem.key === md5) {
                                fileDataItem.percent = 100;
                                fileDataItem.status = 'success';
                            }
                        });

                        if (that.uploadPreviewFileList.length <= fileNumLimit) {
                            if (
                                !that.sumbitFileList?.filter((fileDataItem) => fileDataItem.fileId === file_id)?.length
                            ) {
                                // 提交数据不存在改文件
                                that.sumbitFileList.push(fileItem);
                            } else {
                                // 提交数据存在改文件
                                that.sumbitFileList.forEach((item, index) => {
                                    if (item.fileId === file_id) {
                                        that.sumbitFileList.splice(index, 1, fileItem);
                                    }
                                });
                            }
                        }

                        this.$emit('input', that.sumbitFileList);
                    } else {
                        warningMessage(LS.files + ':' + file.name + '，' + data.msg, true);
                        that.uploadPreviewFileList.forEach((fileDataItem) => {
                            if (fileDataItem.key === file.md5) {
                                fileDataItem.status = 'error';
                            }
                        });
                    }
                },

                onUploadError(file, repText) {
                    $('#progressBody_ ' + file.id).remove();
                    $('#progressWrap_' + file.id).remove();
                    warningMessage('文件：' + file.name + '  ' + JSON.parse(repText).msg, true);
                    that.uploadPreviewFileList.forEach((fileDataItem) => {
                        if (fileDataItem.key === file.md5) {
                            fileDataItem.status = 'error';
                        }
                    });
                },

                onSelect() {
                    if (this.manageMode) {
                        Message.warning('当前为管理状态，文件上传无效。');
                        return false;
                    } // 上传之前检查是否超过限制

                    return true;
                },

                onUploadStart(file, originalFile) {
                    previewFile(originalFile, (e, fileUrl, fileOtherData) => {
                        let previewFileData = {};

                        if (
                            !that.uploadPreviewFileList?.filter((fileDataItem) => fileDataItem.key === originalFile.md5)
                                ?.length
                        ) {
                            previewFileData = {
                                src: fileUrl,
                                // 幻灯片key
                                key: originalFile.md5,
                                ...fileOtherData,
                                title: originalFile.name,
                                // 幻灯片key
                                size: originalFile.size,
                                type: originalFile.type,
                                percent: 0,
                                status: 'loading',
                                originalFile,
                            };
                            that.uploadPreviewFileList.length < fileNumLimit &&
                                that.uploadPreviewFileList.push(previewFileData);
                        } else {
                            that.uploadPreviewFileList?.filter((fileDataItem) => {
                                if (fileDataItem.key === originalFile.md5) {
                                    fileDataItem.percent = 0;
                                    fileDataItem.status = 'loading';
                                    previewFileData = fileDataItem;
                                }
                            });
                        }
                    });
                    $('#progressBody_ ' + file.id).remove();
                    $('#progressWrap_' + file.id).remove();
                },

                msgInfo(type, msg) {
                    if (type === 'warning') {
                        warningMessage(msg, true);
                    }
                },

                onUploadProgress(e, file, originalFile) {
                    that.uploadPreviewFileList.forEach((fileDataItem) => {
                        if (fileDataItem.key === file.md5) {
                            fileDataItem.percent = ((e.loaded / originalFile.size) * 100).toFixed(0);
                        }
                    });
                },
            };
            this.upload = $(this.$refs.uploadBtn2).uploadify(advance_setting);
        },

        fileItemId(previewFileData) {
            const fileItemId = `${previewFileData.key}_${this.contentId}_${this.item.id}`;
            return fileItemId;
        },

        delUploadFile(key) {
            try {
                let curFile = null;
                this.uploadPreviewFileList.forEach((item, index) => {
                    if (item.key === key) {
                        curFile = this.uploadPreviewFileList.splice(index, 1);
                        curFile?.[0]?.status === 'loading' && this.upload?.cancel?.(curFile?.[0]?.originalFile?.index);
                        this.sumbitFileList.splice(index, 1);
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },

        retryUpload(key) {
            try {
                const previewFileData = this.uploadPreviewFileList.filter((item) => item?.key === key)?.[0] ?? {};
                this.upload?.fileObj?.funUploadFile?.(previewFileData?.originalFile);
            } catch (error) {
                console.log(error);
            }
        },

        currentFileIcon(fileType = 'file') {
            return `#icon-${fileType}`;
        },

        fileIconClass(fileType = 'file') {
            return `preview_file_icon--${fileType}`;
        },

        previewSize(size = 0) {
            return getPreviewSize(size);
        },

        openPhotoSlidesshow(key) {
            let initialIndex = 0;
            this.previewSrcList.forEach((value, index) => {
                if (value.key === key) {
                    initialIndex = index;
                }
            });
            this.initialIndex = initialIndex;

            if (this.isMobi) {
                this.showMobiViewer = true;
            } else {
                this.showPcViewer = true;
            }
        },

        closeViewer() {
            this.showPcViewer = false;
            this.showMobiViewer = false;
        },

        format() {
            var s = arguments[0];

            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp('\\{' + i + '\\}', 'gm');
                s = s.replace(reg, arguments[i + 1]);
            }

            return s;
        },

        uploadClick() {
            if (this?.uploadPreviewFileList?.length >= this?.fileNumLimit) {
                return;
            }

            $(this.$refs.uploadBtn2).find('a').click();
        },
    },
};
