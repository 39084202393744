var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.manageMode || _vm.hasChild
    ? _c(
        "div",
        {
          ref: "freeLayout",
          class: [
            "s_freelayout__wrap",
            "" + (!_vm.isPc ? "s_freelayout__wrap-mobi" : ""),
            "" +
              (_vm.navItem.showFreeLayout ? "s_freelayout__wrap-active" : ""),
          ],
          attrs: { id: "s_freelayout__" + _vm.navItem.id },
          on: {
            mouseout: function ($event) {
              return _vm.mouseoutFreelayout($event)
            },
          },
        },
        [
          _vm.manageMode && _vm.isPc
            ? [
                !_vm.isEditFreeLayout
                  ? _c(
                      "div",
                      {
                        staticClass: "s_freelayout__entry",
                        on: { click: _vm.openEdit },
                      },
                      [_vm._v("编辑导航")]
                    )
                  : _c("div", { staticClass: "s_freelayout__editbtns" }, [
                      _c(
                        "div",
                        {
                          staticClass: "s_freelayout__editbtn",
                          on: { click: _vm.addModule },
                        },
                        [_vm._v("添加模块")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "s_freelayout__editline" }, [
                        _vm._v("|"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "s_freelayout__editbtn",
                          on: { click: _vm.addMaterial },
                        },
                        [_vm._v("添加素材")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "s_freelayout__editline" }, [
                        _vm._v("|"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "s_freelayout__editbtn",
                          on: { click: _vm.editRow },
                        },
                        [_vm._v("编辑通栏")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "s_freelayout__editline" }, [
                        _vm._v("|"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "s_freelayout__editbtn",
                          on: { click: _vm.closeEdit },
                        },
                        [_vm._v("退出编辑")]
                      ),
                    ]),
                _vm._v(" "),
                _vm.isEditFreeLayout
                  ? _c("div", {
                      staticClass: "s_freelayout__mask",
                      on: {
                        click: function ($event) {
                          return _vm.closeEdit($event, true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.manageMode && _vm.isEditFreeLayout) || _vm.hasChild
            ? _c("jz-web-rows", {
                staticClass: "s_freelayout__row",
                class: [_vm.noEventClass],
                attrs: { ids: _vm.rowIds, content: true },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.manageMode && !_vm.hasChild && !_vm.isEditFreeLayout && _vm.isPc
            ? _c("div", { staticClass: "s_freelayout__empty" }, [
                _vm._v(
                  "\n        当前模块无内容访客态将不显示，选择\n        "
                ),
                _c(
                  "span",
                  {
                    staticClass: "s_freelayout__addMatText",
                    on: { click: _vm.addMaterial_openEdit },
                  },
                  [_vm._v("添加素材")]
                ),
                _vm._v("\n        开始编辑\n    "),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }