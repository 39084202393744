import { asyncLoad } from '@jz/utils';
// 检测js是否已经加载
export const checkScriptLoad = function (jsSrc) {
    if (typeof jsSrc == 'undefined' || jsSrc.length < 1) {
        return;
    }

    var scripts = document.scripts,
        len = scripts.length - 1,
        src = '',
        result = false,
        i;

    for (i = len; i >= 0; i--) {
        src = scripts[i].getAttribute('src') || '';

        if (src == jsSrc) {
            result = true;
            break;
        }
    }

    return result;
}; // 检测js是否加载，未加载则执行下载的操作

// 检测js是否加载，未加载则执行下载的操作
export const demandLoadJs = function (jsSrc, callback) {
    if (typeof jsSrc == 'undefined' || jsSrc.length < 1) {
        return;
    }
    asyncLoad.loadJs(jsSrc).then(() => {
        callback();
    });
};

// 检测css是否已经加载
export const checkCssLoad = function (cssSrc) {
    if (typeof cssSrc == 'undefined' || cssSrc.length < 1) {
        return;
    }

    var styleSheets = document.styleSheets,
        len = styleSheets.length - 1,
        src = '',
        result = false,
        i;

    for (i = len; i >= 0; i--) {
        src = styleSheets[i].href || '';
        if (src == cssSrc) {
            result = true;
            break;
        }
    }

    return result;
};

/**
 *
 * @param {String} src
 * @param {Number} maxWait  - ms
 * @returns
 */
export function scriptOnload(src, maxWait = 0) {
    if (checkScriptLoad(src) === false) return Promise.reject(false);

    return new Promise((resolve, reject) => {
        let timeoutId = null;
        if (maxWait !== 0) {
            timeoutId = setTimeout(() => {
                reject(`加载超过${maxWait / 1000}秒，标记失败`);
            }, maxWait);
        }

        const scriptEl = Array.from(document.scripts).find((script) => script.getAttribute('src') === src);
        if (scriptEl) {
            scriptEl.addEventListener('load', () => {
                resolve(true);
                window.clearTimeout(timeoutId);
            });
            scriptEl.addEventListener('error', (err) => {
                reject(`加载失败: ${err}`);
                window.clearTimeout(timeoutId);
            });
        } else {
            reject(false);
            window.clearTimeout(timeoutId);
        }
    });
}
