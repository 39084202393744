var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDemoPreviewSection
    ? _c("section", { staticClass: "demo-preview-section" }, [
        _c("div", { staticClass: "section-content" }, [
          _c("div", { staticClass: "section-pagination" }, [
            _c(
              "a",
              {
                staticClass: "prev-button pagination-link",
                class: {
                  disabled: _vm.templateData.pagination.prevLink === "",
                },
                attrs: { href: _vm.templateData.pagination.prevLink },
                on: {
                  click: function ($event) {
                    return _vm.handleChangePage(
                      _vm.templateData.pagination.prevLink,
                      $event
                    )
                  },
                },
              },
              [_vm._v("\n                上一个\n            ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "pagination-page" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.templateData.pagination.currentIndex) +
                  "/" +
                  _vm._s(_vm.templateData.pagination.total) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "next-button pagination-link",
                class: {
                  disabled: _vm.templateData.pagination.nextLink === "",
                },
                attrs: { href: _vm.templateData.pagination.nextLink },
                on: {
                  click: function ($event) {
                    return _vm.handleChangePage(
                      _vm.templateData.pagination.nextLink,
                      $event
                    )
                  },
                },
              },
              [_vm._v("\n                下一个\n            ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "section-device" },
            _vm._l(_vm.devices, function (device) {
              return _c(
                "div",
                {
                  key: device.name,
                  staticClass: "device",
                  class: { active: _vm.activeDeviceName === device.name },
                },
                [
                  _c("div", {
                    staticClass: "device-icon",
                    class: ["device-" + device.name],
                    on: {
                      click: function ($event) {
                        return _vm.deviceClickHandler(device.name)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "device-hover-tips" }, [
                    _vm._v(_vm._s(device.tips)),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "section-qrcode" }, [
            _c("div", { staticClass: "qrcode-icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "qrcode-hover-tips" }, [
              _c("img", {
                attrs: { src: _vm.templateData.QRCodeImage, alt: "模板二维码" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "section-actions" }, [
            _c(
              "a",
              {
                staticClass: "use-template-button",
                attrs: {
                  href: _vm.templateData.useTemplateLink,
                  target: "_blank",
                  title:
                    "点击复制网站，让您立即拥有和模板一样的网站，复制后您还可以随意更换主题、修改横幅、编辑内容等。",
                },
                on: { click: _vm.useTemplate },
              },
              [_c("span", { staticClass: "button-text" }, [_vm._v("使用模板")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "more-template-button",
                attrs: {
                  href: _vm.templateData.moreTemplateLink,
                  target: "_blank",
                  title: "点击查看更多模板网站",
                },
                on: { click: _vm.moreTemplateClickHandler },
              },
              [_vm._v("更多模板")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }