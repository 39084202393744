//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getFontCss } from '../cssUtils';
import {
    STYLE_THREE_SLIDE_ITEM_LENGTH,
    STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH,
} from '@/components/modules/timeline/constants';

export default {
    name: 'ModuleStyles',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        moduleStyle() {
            return this.module.prop0;
        },
        dotColor() {
            if (this.pcPattern.nc.y === 1) {
                return this.pcPattern.nc.c;
            }
            return '';
        },
        lineColorCss() {
            let css = '';
            if (this.pcPattern.lc.y !== 1 || !this.pcPattern.lc.c) {
                return '';
            }
            if (this.moduleStyle === 2) {
                css = `${this.id} .timeline_ruler { background-color: ${this.pcPattern.lc.c}; }`;
            } else {
                css = `${this.id} .timeline_item_line { background-color: ${this.pcPattern.lc.c}; }`;
            }
            return css;
        },
        timelineSDotCss() {
            const getCss = (c) => {
                return `${this.id} .timeline_s_dot { background-color: ${c}; }`;
            };
            if (this.pcPattern.nc.y === 1) {
                return getCss(this.pcPattern.nc.c);
            }
            return '';
        },
        timelineSDotActiveCss() {
            const getCss = (c) => {
                return `${this.id} .timeline_s_dot--active { background-color: ${c}; }`;
            };
            if (this.pcPattern.nc.y === 1) {
                return getCss(this.pcPattern.nc.sc);
            }
            return '';
        },
        moduleLayout() {
            return this.module.prop2;
        },
        picSizeCss() {
            let defaultHeightCss = '';
            // 布局2 电脑视图图片比例是 1:0.3 ，手机视图图片比例是 16:9
            if (this.moduleLayout === 1 && this.pcPattern.picSize.y === 0) {
                defaultHeightCss += `${this.pcId} .timeline_item_image { padding-top: 30%; }`;
            }
            // 如果mobi选默认就继承pc的
            if (this.mobiPattern.picSize.y === 0 && this.pcPattern.picSize.y === 1) {
                defaultHeightCss += `${this.mobiId} .timeline_item_image { padding-top: ${
                    this.pcPattern.picSize.h * 100
                }%; }`;
            }
            const getCss = (id, pattern) => {
                let css = '';
                if (pattern.picSize.y === 1) {
                    const { w, h } = pattern.picSize;
                    let widthCss = `${id} .timeline_c_image_wrap { width: ${w * 100}%; }`;
                    if (this.moduleLayout === 1 || id.includes('jz_screen_mobi')) {
                        widthCss = '';
                    }
                    css = widthCss;

                    css += `${id} .timeline_item_image { padding-top: ${h * 100}%; }`;
                }
                return css;
            };
            return `
                ${defaultHeightCss}
                ${getCss(this.id, this.pcPattern)}
                ${getCss(this.mobiId, this.mobiPattern)}
            `;
        },
        picScaleCss() {
            const getBackgroundSize = (pattern, id, v) => {
                if (!v) {
                    return '';
                }
                return `${id} .timeline_item_image { background-size: ${v}; }`;
            };
            const map = {
                0: '',
                1: 'contain',
                2: 'initial',
            };
            const mobiMap = {
                0: 'cover',
                1: 'contain',
                2: 'initial',
            };
            return `
                ${getBackgroundSize(this.pcPattern, this.id, map[this.pcPattern.scale])}
                ${getBackgroundSize(this.mobiPattern, this.mobiId, mobiMap[this.mobiPattern.scale])}
            `;
        },
        timeFontCss() {
            return getFontCss({
                pcFont: this.pcPattern.timets,
                mobiFont: this.mobiPattern.timets,
                className: `${this.id} .timeline_item_time`,
            });
        },
        titleFontCss() {
            let css = getFontCss({
                pcFont: this.pcPattern.titlets,
                mobiFont: this.mobiPattern.titlets,
                className: `${this.id} .timeline_item_title`,
                needTextAlign: this.moduleStyle !== 0,
                alignKey: 'ta2',
            });
            if (this.pcPattern.titlets.y === 1 && this.pcPattern.titlets.ta2 !== -1) {
                css += `${this.id} .timeline_item_title { width: 100%; }`;
            }
            if (this.mobiPattern.titlets.y === 1 && this.mobiPattern.titlets.ta2 !== -1) {
                css += `.jz_screen_mobi ${this.id} .timeline_item_title { width: 100%; }`;
            }
            return css;
        },
        timeActiveFontCss() {
            const getActiveCss = (fontPattern, id) => {
                if (fontPattern.y === 0 || !fontPattern.hc) {
                    return '';
                }
                return `${id} .module_content .timeline_s_time--active { color: ${fontPattern.hc} }`;
            };
            return `
                ${getActiveCss(this.pcPattern.timets, this.id)}
                ${getActiveCss(this.mobiPattern.timets, this.mobiId)}
            `;
        },
        descFontCss() {
            let css = getFontCss({
                pcFont: this.pcPattern.dts,
                mobiFont: this.mobiPattern.dts,
                className: `${this.id} .timeline_item_desc`,
                needTextAlign: this.moduleStyle !== 0,
                alignKey: 'ta2',
            });
            if (this.pcPattern.dts.y === 1 && this.pcPattern.dts.ta2 !== -1) {
                css += `${this.id} .timeline_item_desc { width: 100%; }`;
            }
            if (this.mobiPattern.dts.y === 1 && this.mobiPattern.dts.ta2 !== -1) {
                css += `.jz_screen_mobi ${this.id} .timeline_item_desc { width: 100%; }`;
            }
            return css;
        },
        slideItemLengthOnePage() {
            return this.isMobi ? STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH : STYLE_THREE_SLIDE_ITEM_LENGTH;
        },
        timelineItemsLength() {
            return this.module.prop1.length;
        },
        rulerWidth() {
            const getCss = (isMobi) => {
                const itemWidth = 100 / (isMobi ? STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH : STYLE_THREE_SLIDE_ITEM_LENGTH);
                const width = itemWidth * this.timelineItemsLength;
                return `${isMobi ? this.mobiId : this.pcId} .timeline_ruler { width: ${width < 100 ? 100 : width}%; }`;
            };
            return `${getCss(false)} ${getCss(true)}`;
        },
    },
};
