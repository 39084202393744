//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LocationList from './LocationList';
export default {
    name: 'CurrentPosition',
    components: { LocationList },
    props: ['module'],
    style: 34,
    data() {
        return {
            hoverTitleFlag: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device']),
        location() {
            return this.module.locationData;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        model() {
            return this.module.privatePattern[this.device];
        },
        hoverTitleModel() {
            return this.hoverTitleFlag && !this.isMobi;
        },
        pcPrivatePattern() {
            return this.module.privatePattern['pc'];
        },
        title() {
            return this.module.name;
        },
        titleFont() {
            return this.isMobi && this.model?.cltf.y == 0 && this.pcPrivatePattern?.cltf.y !== 0
                ? this.pcPrivatePattern?.cltf
                : this.model?.cltf.y == 0
                ? {}
                : this.model?.cltf;
        },
        otherFont() {
            return this.isMobi && this.model?.clof.y == 0 && this.pcPrivatePattern?.clof.y !== 0
                ? this.pcPrivatePattern?.clof
                : this.model?.clof.y == 0
                ? {}
                : this.model?.clof;
        },
        currentFont() {
            return this.isMobi && this.model?.cllf.y == 0 && this.pcPrivatePattern?.cllf.y !== 0
                ? this.pcPrivatePattern?.cllf
                : this.model?.cllf.y == 0
                ? {}
                : this.model?.cllf;
        },
        titleStyle() {
            if (!this.titleFont.y) return {};
            const _style = {
                fontFamily: this.titleFont.f,
                fontSize: this.titleFont.s + 'px',
                fontWeight: this.titleFont.w ? 'bold' : 'normal',
                fontStyle: this.titleFont.i === 1 ? 'italic' : 'inherit',
                ...this.getComputedTextlinearGradientColor(
                    this.hoverTitleModel ? this.titleFont.sc : this.titleFont.c,
                    true,
                    false
                ),
                textDecoration: this.titleFont.d === 1 ? 'underline' : 'none',
            };
            return _style;
        },
        moduleClass() {
            const _moduleClass = [];
            let str = 'module_current_position_content--',
                _center = 'center',
                _end = 'right';
            // mobi  默认联动pc数据
            const align =
                this.isMobi && this.model?.align == 0 && this.pcPrivatePattern?.align !== 0
                    ? this.pcPrivatePattern?.align
                    : this.model?.align;
            switch (align) {
                case 2:
                    _moduleClass.push([str + _center]);
                    break;
                case 3:
                    _moduleClass.push([str + _end]);
                    break;

                default:
                    break;
            }
            return _moduleClass;
        },
    },
    methods: {
        getComputedTextlinearGradientColor(color, useNew = true, needImportant = true) {
            let obj = {};
            let important = needImportant ? '!important' : '';
            // 进行颜色格式化
            if (!useNew) {
                let colorCssKeys = ['background-image', 'color', '-webkit-background-clip', '-webkit-text-fill-color'];
                colorCssKeys.forEach((key) => {
                    obj[key] = '';
                });
            } else {
                if (color != null && color != '') {
                    if (color.indexOf('linear-gradient') > -1) {
                        obj['background-image'] = `${color} ${important}`;
                        obj['-webkit-background-clip'] = `text ${important}`;
                        obj['-webkit-text-fill-color'] = `transparent ${important}`;
                        obj['color'] = '';
                    } else {
                        obj['color'] = `${color} ${important}`;
                        obj['background-image'] = `none ${important}`;
                        obj['-webkit-background-clip'] = `unset ${important}`;
                        obj['-webkit-text-fill-color'] = `unset ${important}`;
                    }
                }
            }
            return obj;
        },
        titleMouseenterHandler() {
            this.hoverTitleFlag = true;
        },
        titleMouseleaveHandler() {
            this.hoverTitleFlag = false;
        },
        getCurrentFontStyle(idx) {
            if (idx !== this.location.length - 1) {
                return this.otherFont;
            } else {
                return this.currentFont;
            }
        },
    },
};
