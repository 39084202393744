import { context } from '@jz/utils';

if (!context?._modulesMap) {
    context._modulesMap = new Map();
}
class Module {
    static find(moduleId) {
        return context._modulesMap.get(moduleId);
    }
    static delete(moduleId) {
        return context._modulesMap.delete(moduleId);
    }

    constructor(moduleId, conf) {
        if (Fai.isNull(moduleId)) {
            throw new Error('\n\n大佬请传一下模块ID，谢谢!\n'.repeat(6));
        }
        context._modulesMap.set(moduleId, this);
        this.$el = $(`#module${moduleId}`);
        this.moduleId = moduleId;
        this.conf = conf; //用户自己设定的conf

        if (!window._store.state.manageMode) {
            return;
        }
        this.conf = this.conf || window._store.state.modules[`module${this.moduleId}`];
    }
}

export { Module };
