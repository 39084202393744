//
//
//
//

import FullScreenRow from './FullScreenRow.vue';
export default {
    name: 'jz-web-row-entry',
    props: [
        'row',
        'hideToolBar', //工具条中使用，误删
        'index',
        'cloneModuleId',
        'rowsLength',
    ],
    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        wrapComponent() {
            return this.isFullScreen ? 'FullScreenRow' : 'jz-web-row';
        },
    },
    components: {
        FullScreenRow,
    },
};
