//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getComputedBg } = Comm;
export default {
    props: ['serv', 'item'],
    data() {
        return {
            isIESupport: false,
        };
    },
    computed: {
        styleType() {
            return this.serv.s;
        },
        getContext() {
            let servName = this.item.name,
                servContent = this.item.content;
            if (this.styleType == 1) {
                return servContent;
            }
            return servName.length && servContent.length ? `${servName}：${servContent}` : `${servName}${servContent}`;
        },
        getWxServBg() {
            let wxPath = this.item.link.wxPath;
            return wxPath && wxPath.length
                ? {
                      'background-image': `url(${wxPath})`,
                  }
                : {};
        },
        getWxAreaClass() {
            return this.item.servType === '5' ? 's_spserv__hoverarea-wx' : '';
        },
        bgStyle() {
            return getComputedBg(this.serv.bg);
        },
        contentStyle() {
            let textOption = this.serv.cont;
            let fontStyle = {
                'font-family': `${textOption.f}`,
                'font-weight': `${textOption.w ? 'bold' : 'normal'}`,
                'text-decoration': `${textOption.d ? 'underline' : 'none'}`,
                'font-style': `${textOption.i ? 'italic' : 'normal'}`,
                color: `${textOption.c}`,
            };
            return textOption.y ? fontStyle : {};
        },
        ieNoWrapClass() {
            return this.isIESupport ? 'ie-nowrap' : 'not-ie-hoverarea';
        },
    },
    created() {
        this.getIsIe();
    },
    methods: {
        emitChangeCurIndex(e) {
            e.preventDefault();
            this.$emit('changeCurIndex', e, -1);
        },
        getIsIe() {
            if (this.$isServer) {
                return;
            }
            this.isIESupport = Fai.isIE() || Fai.isEdge();
        },
    },
};
