//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getComputedBg } from '../../manage/designer/initContext/utils.js';
const HEADER_FIXED_TOP = 1;
const HEADER_FIXED_BACKGROUND_TYPE_DEFAULT = 0;
import { fullScreenMixin } from '../grid/row/fullScreenMixin.js';
import { initDomMutationObserver } from '@/site/shared/mutationObserver/index.js';
import SectionWebsiteTitle from '../system/sectionWebsiteTitle.vue';
import SectionMultiLanguage from '../system/multiLanguage/index.vue';
import SectionMemberLogin from '../system/sectionMemberLogin/index.vue';
import SectionNav from '../system/nav/index.vue';
const manageUse = {};
export default {
    name: 'grid-mobi-header',
    components: {
        SectionWebsiteTitle,
        SectionMultiLanguage,
        SectionMemberLogin,
        SectionNav,
    },
    mixins: [fullScreenMixin],
    provide() {
        return {
            isSysRow: true,
            rowNewMobiResponsiveConversion:
                this.newMobiResponsiveConversion || this.templateNewMobiResponsiveConversion, // 这是为了注入给自定义模块的 因为这里是顶部 所以拿这个的
            rowNewResponsiveConversion: false, // pc的 直接为false
        };
    },
    data() {
        return {
            hideHeaderFlag: false,
            headerDomMutation: null,
        };
    },
    computed: {
        ...Vuex.mapState([
            'responsiveInfo',
            'newMobiResponsiveConversion',
            'templateNewMobiResponsiveConversion',
            'sections',
        ]),
        headerPattern() {
            return this.responsiveInfo.pattern.header;
        },
        isHeaderLevitate() {
            // 是否悬浮 mobi暂时不开启悬浮定位同步pc 不知道后面会不会同步所以先这样写
            return this.isFullScreen && this.rollingScreen.topType === 1 && false;
        },
        headerNotFixed() {
            return this.isFullScreen && !this.rollingScreen.isFixedTop && !this.isHeaderLevitate;
        },
        isActiveFullPage() {
            return this.isFullScreen && !this.rollingScreen.isFixedTop && this.rollingScreenRowInfo.curIndex === 0;
        },
        isHideHeaderContent() {
            return this.isFullScreen && !this.rollingScreen.isFixedTop && this.rollingScreenRowInfo.curIndex !== 0;
        },
        headerContentClass() {
            const classObj = {
                g_header_content__levitate: this.isHeaderLevitate,
                g_header_content_not_fixed: this.headerNotFixed,
                active_page: this.isActiveFullPage,
                hide_header_content: this.hideHeaderFlag,

                g_mobi_header__fixed: this.isHeaderFixedTop,
                g_mobi_header__fixed_bg_def: this.isHeaderFixedBackgroundDefault,
                g_mobi_nav_position__right: this.navPositionIsRight,
                g_mobi_nav_style__1: this.hasThemeBg,
                g_mobi_header_content_position__left: [4, 5].includes(this.header.mobiStyleNew),
                g_mobi_header_content_position__right: [6, 7].includes(this.header.mobiStyleNew),
                g_mobi_header__rightbar_left: [6, 7].includes(this.header.mobiStyleNew),
                g_mobi_header__rightbar_right: [4, 5].includes(this.header.mobiStyleNew),
                g_mobi_header__rightbar_postion__left: [2, 3, 6, 7].includes(this.header.mobiStyleNew),
                g_mobi_header__rightbar_postion__right: [0, 1, 4, 5].includes(this.header.mobiStyleNew),
                g_mobi_header_hide_nav: !this.navHide,
                g_mobi_header_member_lan_hide: !(
                    this.renderMultiLanguageCore && this.responsiveInfo.pattern.multiLanguage.md != 1
                ),
                g_mobi_header_language_hide: !this.mobiMultiLanguageShow,
            };
            classObj[`${this.rollingScreenRowInfo.outAnimateClass}`] = this.rowOutAnimate;
            classObj[`${this.rollingScreenRowInfo.inAnimateClass}`] = this.rowInAnimate;
            return classObj;
        },
        mobiBgStyle() {
            if (!this.headerPattern.mobiBg.y) {
                return {};
            }
            return getComputedBg(this.headerPattern.mobiBg);
        },
        isHeaderFixedTop() {
            return this.header.fixedTop === HEADER_FIXED_TOP || (this.isFullScreen && this.rollingScreen.isFixedTop);
        },
        hasThemeBg() {
            return [1, 3, 5, 7].includes(this.header.mobiStyleNew);
        },
        header() {
            return this.pattern.header || {};
        },
        navPositionIsRight() {
            return [2, 3, 4, 5].includes(this.header.mobiStyleNew);
        },
        pattern() {
            return this.info.pattern || {};
        },
        isHeaderFixedBackgroundDefault() {
            return this.fixedBackground.y === HEADER_FIXED_BACKGROUND_TYPE_DEFAULT;
        },
        fixedBackground() {
            return this.header.fixedBackground || {};
        },
        renderMultiLanguageCore() {
            return (
                // 开启的多语言大于1
                this.sections.multiLanguage.showLan &&
                // 如果pc设置了导航位置在底部，就要隐藏mobi顶部导航
                this.responsiveInfo.pattern.multiLanguage.ra === 0 &&
                (this.responsiveInfo.pattern.displayContent.type ? true : !this.responsiveInfo.pattern.multiLanguage.h)
            );
        },
        gridMobiHeaderStyle() {
            if (this.pattern.mLogo.t) {
                return {
                    height: `${this.pattern.mLogo.s + 5 + 5}px`,
                };
            } else {
                return {};
            }
        },
        navPattern() {
            return this.info.pattern.nav;
        },
        navHide() {
            let pattern = this.info.pattern;
            if (
                pattern.independent &&
                pattern.independent.hideNav &&
                pattern.independent.open &&
                pattern.independent.self
            ) {
                return !pattern.independent.hideNav;
            }
            return !this.navPattern.hide;
        },
        mobiHeaderContent() {
            return { ...this.gridMobiHeaderStyle, ...this.mobiBgStyle };
        },
        modeHeaderRightBarStyle() {
            if (this.pattern.mLogo.t) {
                return {
                    ['line-height']: `${this.pattern.mLogo.s + 5 + 5}px`,
                };
            } else {
                return { ['line-height']: `50px` };
            }
        },
        isMemberLoginShow() {
            return (
                (this.responsiveInfo.pattern.displayContent ? true : !this.responsiveInfo.pattern.memberLogin.h) &&
                this.responsiveInfo.pattern.memberLogin.md != 1
            );
        },
        mobiMemeberLoginShow() {
            if (this.info.pattern.displayContent.type) {
                return this.info.pattern.displayContent.memberLogin;
            } else {
                return !this.pattern.h;
            }
        },
        mobiMultiLanguageShow() {
            let visible = false;
            if (this.isMobi) {
                if (this.responsiveInfo.pattern.displayContent.type) {
                    visible = this.responsiveInfo.pattern.displayContent.languageNav;
                } else {
                    visible = !this.pattern.h;
                }
            }
            return visible;
        },
        mobiTitleWidthStyle() {
            let style = {};
            let width = 0;
            if (this.isMobi) {
                if (this.navHide) {
                    // 存在导航情况
                    width += [0, 1, 2, 3].includes(this.header.mobiStyleNew) ? 85 : 60;
                    if (this.mobiMemeberLoginShow && this.mobiMultiLanguageShow) {
                        // 登录和多语言同时存在
                        width += [0, 1, 2, 3].includes(this.header.mobiStyleNew) ? 85 : 80;
                    } else if (this.mobiMemeberLoginShow || this.mobiMultiLanguageShow) {
                        // 登录和多语言存在一个
                        width += [0, 1, 2, 3].includes(this.header.mobiStyleNew) ? 30 : 45;
                    }
                } else {
                    if (this.mobiMemeberLoginShow && this.mobiMultiLanguageShow) {
                        // 登录和多语言同时存在
                        width += [0, 1, 2, 3].includes(this.header.mobiStyleNew) ? 170 : 100;
                    } else if (this.mobiMemeberLoginShow || this.mobiMultiLanguageShow) {
                        // 登录和多语言存在一个
                        width += [0, 1, 2, 3].includes(this.header.mobiStyleNew) ? 120 : 70;
                    }
                }
                if (width == 0) {
                    style['width'] = '100%';
                } else {
                    style['width'] = `calc(100% - ${width}px)`;
                }
            }
            return style;
        },
    },
    watch: {
        isHideHeaderContent(val) {
            // 暂时先延时处理 不做动画---(不能做动画处理 不然衔接会很奇怪)
            switch (this.pattern.rollingScreen.type) {
                case 1:
                    this.hideHeaderFlag = val;
                    break;
                case 2:
                    this.hideHeaderFlag = val;
                    break;
                default:
                    break;
            }
        },
    },
    mounted() {
        const headerContent = this.$refs.headerContent;
        this.$store.commit('header/changeHeaderStyleStatus', {
            status: false,
        });
        if (typeof headerContent !== 'undefined') {
            this.isFullScreen &&
                (this.headerDomMutation = initDomMutationObserver({
                    node: headerContent,
                    id: jzUtils.createRandomId(),
                    callback: () => {
                        const _height = parseFloat(getComputedStyle(headerContent).height);
                        if (_height !== this.headerStyleStatus.headerHeight) {
                            this.$store.commit('header/changeHeaderStyleStatus', {
                                status: true,
                                headerHeight: 51,
                            });
                        }
                    },
                }));
        }
    },
    created() {
        this.hideHeaderFlag = this.isHideHeaderContent;
    },
    beforeDestroy() {
        this?.headerDomMutation?.disconnect();
    },
    methods: {
        hovermenu: manageUse.hovermenu || function () {},
    },
};
