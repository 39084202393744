//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'LocationList',
    props: {
        location: {
            type: Object,
            default() {
                return {};
            },
        },
        hideArrow: {
            type: Boolean,
        },
        isMobi: {
            type: Boolean,
        },
        fontStyle: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            hoverTitleFlag: false,
        };
    },
    computed: {
        hoverTitleModel() {
            return this.hoverTitleFlag && !this.isMobi && this.fontStyle.hc;
        },
        titleStyle() {
            if (!this.fontStyle.y) return {};
            const _style = {
                fontFamily: this.fontStyle.f,
                fontSize: this.fontStyle.s + 'px',
                fontWeight: this.fontStyle.w ? 'bold' : 'normal',
                fontStyle: this.fontStyle.i === 1 ? 'italic' : 'inherit',
                ...this.getComputedTextlinearGradientColor(this.hoverTitleModel ? this.fontStyle.hc : this.fontStyle.c),
                textDecoration: this.fontStyle.d === 1 ? 'underline' : 'none',
            };
            return _style;
        },
    },
    methods: {
        getComputedTextlinearGradientColor(color) {
            let obj = {};
            if (color != null && color != '') {
                if (color.indexOf('linear-gradient') > -1) {
                    obj['background-image'] = `${color}`;
                    obj['-webkit-background-clip'] = 'text';
                    obj['-webkit-text-fill-color'] = 'transparent';
                    obj['color'] = '';
                } else {
                    obj['color'] = `${color}`;
                    obj['background-image'] = 'none';
                    obj['-webkit-background-clip'] = 'unset';
                    obj['-webkit-text-fill-color'] = 'unset';
                }
            }
            return obj;
        },
        titleMouseenterHandler() {
            this.hoverTitleFlag = true;
        },
        titleMouseleaveHandler() {
            this.hoverTitleFlag = false;
        },
    },
};
