//
//
//
//
//
//

import DynamicCSS from './components/DynamicCSS';
import ModuleStyles from './module/ModuleStyles';

export default {
    name: 'CssStyles',
    components: { DynamicCSS, ModuleStyles },
    data() {
        return {};
    },
    methods: {
        update(result) {
            this.$emit('update', result);
        },
    },
};
