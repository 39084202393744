//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import horizontalExpansion from './horizontalExpansionStyle.vue';
import dropdownStyle from './dropdownStyle.vue';
import modalStyle from './modalStyle.vue';
import {
    getComputedTextlinearGradientColor,
    canAddFixTopClass,
    FIX_TOP_TEMP_CLASS,
} from '@/site/shared/fixTopStyle/index.js';

export default {
    name: 'MultiLanguage',
    components: {
        horizontalExpansion,
        dropdownStyle,
        modalStyle,
    },
    props: ['list', 'currentLan', 'renderType', 'isCore', 'renderStyle', 'renderArea', 'fontSetting'],
    data() {
        return {
            languagePanelApp: '',
            deviceVisitorMode: null, // 访客态device
        };
    },
    computed: {
        ...Vuex.mapGetters(['info']),

        ...Vuex.mapState(['device', 'manageMode']),
        headerPattern() {
            return this.info.pattern.header;
        },
        shouldRenderIcon() {
            return this.renderType == 'rich' || this.renderType == 'icon';
        },
        shouldRenderText() {
            return this.renderType == 'rich' || this.renderType == 'text';
        },
        isMobi() {
            return this.device == 'mobi';
        },
        mobiIconColor() {
            if (this.headerPattern.mobiIconType) {
                return this.headerPattern.mobiIconColor;
            }
            return '';
        },
        multiLanguageStyleComponent() {
            let style = this.renderStyle;
            const isTopPosition = this.renderArea === 0;
            if (this.isMobi && style === 1 && isTopPosition) {
                style = 0;
            }
            const componentNames = ['dropdownStyle', 'horizontalExpansion', 'modalStyle'];
            return componentNames[style];
        },
    },
    created() {
        this.createFontStyle = this.createFontStyleCreator();
    },
    mounted() {
        this.updateVisitorDevice();
        this.watchVisitorDevice();
        if (canAddFixTopClass('language')) {
            $('#gridHeader .s_multi_language_container').addClass(FIX_TOP_TEMP_CLASS);
        }
    },
    methods: {
        updateVisitorDevice() {
            this.deviceVisitorMode = Comm.getDevice();
        },
        watchVisitorDevice() {
            $(window).on('resize.multiLanguage', () => {
                this.updateVisitorDevice();
            });
        },
        createFontStyleCreator() {
            let _this = this;
            return function (selected = false) {
                const font = _this.fontSetting;
                if (!font || font.y === 0) {
                    return {};
                }
                let style = {
                    fontFamily: font.f,
                    fontSize: font.s + 'px',
                    fontWeight: font.w ? 'bold' : 'normal',
                    fontStyle: font.i === 1 ? 'italic' : 'inherit',
                    // 'color': selected ? font.hc : font.c,
                    ...getComputedTextlinearGradientColor(selected ? font.hc : font.c, true, false),
                    textDecoration: font.d === 1 ? 'underline' : 'none',
                };
                if (_this.isMobi && font.i === 1) {
                    style['padding-right'] = '5px'; // 还是要看设置的字体多大...
                }
                return style;
            };
        },
    },
};
