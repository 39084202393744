//
//
//
//
//
//
//
//
//
//
//

import { checkZoom } from '@/components/utils';
export default {
    props: ['value', 'isLinkToForm'],
    computed: {
        getDomEl() {
            return this.isLinkToForm ? '.p_popup_win .popup_content.link_popup' : '#jzWebContainer';
        },
        checkZoom() {
            return checkZoom();
        },
        LayerTouchMove() {
            return this.checkZoom ? '' : 'return false';
        },
        contentTouchMove() {
            return this.checkZoom ? 'return false' : '';
        },
    },
    beforeDestroy() {
        //v-dom-portal会导致组件销毁的时候节点不销毁
        //可是在指令的unbind内修改又会导致value等于false的时候马上把节点移除掉导致结束动画不播放
        $(this.$el).remove();
    },
    methods: {
        onClose() {
            this.$emit('input', false);
        },
        animEnter(el, done) {
            $(this.$refs.mask).fadeIn(300, done);
            setTimeout(() => {
                $(this.$refs.content).addClass('active');
            }, 20);
        },
        animLeave(el, done) {
            $(this.$refs.mask).fadeOut(300, done);
            $(this.$refs.content).removeClass('active');
        },
    },
};
