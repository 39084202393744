//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PopupLayerComponent from './popupLayerComponent.vue';
import SelectComponent from './selectComponent.vue';

export default {
    components: {
        SelectComponent,
        PopupLayerComponent,
    },
    props: {
        item: Object,
        value: {
            type: Object,
            default: () => ({
                province: {},
                city: {},
                county: {},
            }),
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            manageVal: {
                province: {},
                city: {},
                county: {},
            },
            mobiShow: false,
            oldTouchY: 0, //上一次的位置
            speedTouchY: 0, //移动速度
            moveTimer: null,
            currentPage: 0,
            isLinkToForm: this.module.isLinkToForm,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'LS']),
        placeholder() {
            return this.LS.siteFormPleaseSelect;
        },
        currentVal() {
            if (!this.manageMode) {
                return this.value;
            } else {
                return this.manageVal;
            }
        },
        provinceList() {
            try {
                /* eslint-disable-next-line no-undef */
                return site_cityUtil.getProvince();
            } catch (e) {
                return [];
            }
        },
        cityList() {
            try {
                this.currentVal.city = {}; //列表更新时要清空旧值
                /* eslint-disable-next-line no-undef */
                return site_cityUtil.getCities(this.currentVal.province.id);
            } catch (e) {
                return [];
            }
        },
        countyList() {
            try {
                this.currentVal.county = {}; //列表更新时要清空旧值
                /* eslint-disable-next-line no-undef */
                return site_cityUtil.getCounty(this.currentVal.city.id);
            } catch (e) {
                return [];
            }
        },
        mobiValue() {
            return (
                (this.currentVal.province.name || '') +
                (this.currentVal.city.name || '') +
                (this.currentVal.county.name || '')
            );
        },
        mobiList() {
            switch (this.currentPage) {
                case 0:
                    return this.provinceList;
                case 1:
                    return this.cityList;
                case 2:
                    return this.countyList;
            }
            return [];
        },
        showCountySelect() {
            return !this.currentVal.city.id || this.countyList.length;
        },
    },
    methods: {
        showMobiSelect() {
            this.mobiShow = true;
            this.currentPage = 0;
        },
        selectMobiItem(item) {
            switch (this.currentPage) {
                case 0:
                    this.selectProvince(item);
                    this.selectMobiPage(1);
                    break;
                case 1:
                    this.selectCity(item);
                    this.selectMobiPage(2);
                    if (this.currentPage == 1) {
                        //没有切换到县，说明没有县级，直接关闭弹窗
                        this.mobiShow = false;
                    }
                    break;
                case 2:
                    this.selectCounty(item);
                    this.mobiShow = false;
                    break;
            }
        },
        selectMobiPage(page) {
            if (page == 1 && !this.currentVal.province.name) {
                this.selectMobiPage(0);
                return;
            }
            if (page == 2 && (!this.currentVal.city.name || !this.showCountySelect)) {
                this.selectMobiPage(1);
                return;
            }
            this.currentPage = page;
        },
        selectProvince(item) {
            this.currentVal.province = item;
            this.$emit('input', this.currentVal);
        },
        selectCity(item) {
            this.currentVal.city = item;
            this.$emit('input', this.currentVal);
        },
        selectCounty(item) {
            this.currentVal.county = item;
            this.$emit('input', this.currentVal);
        },
        onTouchStart(e) {
            clearInterval(this.moveTimer);

            this.oldTouchY = this.getTouchEventY(e);

            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('mousemove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
            window.addEventListener('mouseup', this.onTouchEnd);
        },
        onTouchMove(e) {
            const touchY = this.getTouchEventY(e);
            const $list = $(this.$refs.list);

            this.speedTouchY = this.oldTouchY - touchY;

            $list.scrollTop($list.scrollTop() + this.speedTouchY);

            this.oldTouchY = touchY;
        },
        onTouchEnd() {
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
            window.removeEventListener('mouseup', this.onTouchEnd);

            const $list = $(this.$refs.list);

            //惯性滑动
            let speed = this.speedTouchY;
            this.moveTimer = setInterval(() => {
                speed *= 0.8;
                $list.scrollTop($list.scrollTop() + speed);
                if (Math.abs(speed) < 1) {
                    clearInterval(this.moveTimer);
                }
            }, 1000 / 60);
        },
        getTouchEventY(e) {
            if (e.changedTouches && e.changedTouches[0]) {
                return e.changedTouches[0].pageY;
            } else {
                return e.pageY;
            }
        },
    },
};
