//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fillLanguageData } from './utils.js';
import MultiLanguage from './menus/index.vue';

const manageUse = {
    mixins: [],
};

export default {
    components: {
        MultiLanguage,
    },
    mixins: [...manageUse.mixins],
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'sections', 'responsiveInfo', 'lcid']),
        multiLanguage() {
            return this.sections.multiLanguage;
        },
        list() {
            return this.multiLanguage.openLanInfoList;
        },
        pattern() {
            return this.responsiveInfo.pattern.multiLanguage;
        },
        currentLan() {
            return this.multiLanguage.currentLan;
        },
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        renderType() {
            return this.pattern.rt;
        },
        renderStyle() {
            return this.pattern.rs;
        },
        renderArea() {
            return this.pattern.ra;
        },
        mobiFontSetting() {
            return this.pattern.mf || {};
        },
        fontSetting() {
            return this.isMobi && this.mobiFontSetting.y == 1 ? this.pattern.mf : this.pattern.f;
        },
        multiLanguageVisible() {
            let visible = false;
            if (this.isMobi) {
                if (this.responsiveInfo.pattern.displayContent.type) {
                    visible = this.responsiveInfo.pattern.displayContent.languageNav;
                } else {
                    visible = !this.pattern.h;
                }
            }
            return visible;
        },
        customRenderText() {
            return this.pattern.rte;
        },
        renderTextList() {
            if (this.customRenderText === 1) {
                return fillLanguageData(this.pattern.rtl, this.list);
            } else {
                return this.list;
            }
        },
        activeLanguage() {
            if (this.customRenderText === 1) {
                return this.renderTextList.find((item) => item.lcid === this.lcid);
            } else {
                return this.currentLan;
            }
        },
        multiLanguageClass() {
            return {
                expansion_style: this.renderStyle === 1,
            };
        },
    },
};
