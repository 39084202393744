var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_news_list_content",
      class: _vm.classes,
      style: _vm.styles,
      attrs: { leninrow: _vm.newsInRow },
    },
    [
      _c(
        "div",
        [
          _c("news-list", {
            staticClass: "news_list_wrap",
            style: _vm.newsWrapStyle,
            attrs: { module: _vm.module, "news-list": _vm.filterNewsList },
            on: { "item-click": _vm.setHistoryCurrentPage },
          }),
          _vm._v(" "),
          !_vm.filterNewsList.length && _vm.manageMode
            ? _c("empty-tips", {
                attrs: {
                  "is-mobi": _vm.isMobi,
                  name: "文章",
                  "active-button-text": _vm.activeButtonText,
                  "button-text": _vm.buttonText,
                },
                on: {
                  "click-active-button": _vm.clickActiveButtonHandler,
                  "click-button": _vm.clickButtonHandler,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.flagPagination && !_vm.isCarouselStyle
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.handlePaginationChanged,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }