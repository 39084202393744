//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {
    components: {},
};
import {
    getComputedBg,
    getComputedTextColor,
    getComputedShadow,
    getComputedBorder,
} from '@/manage/designer/initContext/utils';
import { banner } from '@/components/grid/banner/index.js';
import { Comm, decodeHtmlStr } from '../utils.js';

import ModuleButtonFrame from './ModuleButtonFrame.vue';
import ModuleImageFrame from './ModuleImageFrame.vue';
import ModuleSiteSearchFrame from './ModuleSiteSearchFrame.vue';
import ModuleFrame from './ModuleFrame.vue';
import Image from './image/index.vue';
import MemberCenter from './memberCenter/moduleMemberCenter.vue';
import MemberSignup from './memberSignup/moduleMemberSignup.vue';
import MsgBoard from './msgBoard/index.vue';
import NewsDetail from './newsDetail/index.vue';
// import NewsGroup from './newsGroup/index.vue';
import OnlineMap from './onlineMap/index.vue';
// import ProductNav from './productNav/index.vue';
import Text from './text/index.vue';
import ProductDetail from './productDetail/index.vue';
import MemberLogin from './memberLogin/index.vue';
import Tabpack from './tabpack/index.vue';
import NewsList from './newsList/index.vue';
import SiteSearch from './siteSearch/index.vue';
import ListPhotos from './listPhotos/index.vue';
import Button from './button/index.vue';
import OnlineService from './onlineService/index.vue';
import OnlineForm from './onlineForm/index.vue';
import CustomSearch from './customSearch/index.vue';
import DynamicNumber from './dynamicNumber/index.vue';
import FileDownload from './fileDownload/index.vue';
import PluginCode from './pluginCode/index.vue';
import Iframe from './iframe/index.vue';
import Rich from './rich/index.vue';
import CarouselPhotos from './carouselPhotos/index.vue';
import NewsResult from './newsResult/index.vue';
import ProductResult from './productResult/index.vue';
import PhotoGroup from './photoGroup/index.vue';
import PhotoGroupDetail from './photoGroupDetail/index.vue';
import Timeline from './timeline/index.vue';
import FriendLink from './friendLink/index.vue';
import ProductList from './productList/index.vue';
import Flv from './flv/index.vue';
import SysMsgBoard from './moduleMsgBoard/index.vue';
import CustomSearchResult from './customSearchResult/index.vue';
import CurrentPosition from './currentPosition/index.vue';
import IconCombination from './iconCombination/index.vue';
import SearchResult from './searchResult/index.vue';
import categoryNav from './categoryNav/index.vue';
/*
    SYS = 0;						// 系统特殊
    RICH = 1; 						// 图文模块
    CAROUSEL_PHOTOS = 2;			// 轮播多图模块
    LIST_PHOTOS = 3;				// 列表多图模块
    NEWS_DETAIL = 4;				// 文章详情模块
    NEWS_RESULT = 5;				// 文章筛选模块
    TEXT = 6;						// 常用模块-文本
    IMAGE = 7;						// 常用模块-图片
    BUTTON = 8;						// 常用模块-按钮
    PRODUCT_DETAIL = 9;				// 产品详情
    PRODUCT_RESULT = 10;			// 产品筛选
    MEMBER_SIGNUP = 11;				// 会员注册
    MEMBER_LOGIN = 12;				// 会员登录
    MEMBER_CENTER = 13;				// 会员中心
    NEWS_GROUP = 14;				// 文章导航
    PHOTO_GROUP = 15;				// 图册目录
    PRODUCT_LIST = 16;				// 产品展示
    NEWS_LIST = 17;					// 文章列表
    PRODUCT_NAV = 18;				// 产品导航
    ONLINE_MAP = 19;				// 在线地图
    MODULE_FLV = 20;				// 视频模块
    ONLINE_FORM = 21;				// 在线表单
    MSG_BOARD = 22;					// 留言版
    MODULE_MSG_BOARD = 23;			// 留言版模块
    CUSTOM_SEARCH = 24;				// 信息查询
    SYS_CUSTOM_SEARCH_RESULT = 25;	// 信息查询结果
    IFRAME = 26;					// 嵌入代码模块
    FILE_DOWNLOAD = 27;				// 文件下载
    PLUGIN_CODE = 28;				// 插件代码
    SITE_SEARCH = 29;               // 全站搜索
    SYS_SEARCH_RESULT = 30;	        // 全站搜索结果
    ONLINE_SERVICE = 31; 		    // 在线客服
    TABPACK = 32; 		            // 横向标签
    ICON_COMBINATION = 33;	        // 图标组合
    CURRENT_LOCATION = 34;	        // 当前位置
*/
function getModuleComponent(style) {
    switch (style) {
        case 1:
            return Rich;
        case 2:
            return CarouselPhotos;
        case 3:
            return ListPhotos;
        case 4:
            return NewsDetail;
        case 5:
            return NewsResult;
        case 6:
            return Text;
        case 7:
            return Image;
        case 8:
            return Button;
        case 9:
            return ProductDetail;
        case 10:
            return ProductResult;
        case 11:
            return MemberSignup;
        case 12:
            return MemberLogin;
        case 13:
            return MemberCenter;
        case 14:
            return categoryNav;
        case 15:
            return PhotoGroup;
        case 16:
            return ProductList;
        case 17:
            return NewsList;
        case 18:
            return categoryNav;
        case 19:
            return OnlineMap;
        case 20:
            return Flv;
        case 21:
            return OnlineForm;
        case 22:
            return SysMsgBoard;
        case 23:
            return MsgBoard;
        case 24:
            return CustomSearch;
        case 25:
            return CustomSearchResult;
        case 26:
            return Iframe;
        case 27:
            return FileDownload;
        case 28:
            return PluginCode;
        case 29:
            return SiteSearch;
        case 30:
            return SearchResult;
        case 31:
            return OnlineService;
        case 32:
            return Tabpack;
        case 33:
            return IconCombination;
        case 34:
            return CurrentPosition;
        case 35:
            return DynamicNumber;
        case 36:
            return PhotoGroupDetail;
        case 37:
            return Timeline;
        case 38:
            return FriendLink;
        case 39:
            return categoryNav;
    }
    return 'unknown';
}

const gtSvg = {
    template:
        '<svg viewBox="0 0 16 16"><path d="M10.85,8.15l-4-4a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7L9.79,8.5,6.15,12.15a.5.5,0,1,0,.7.7l4-4a.48.48,0,0,0,0-.7Z" fill="currentColor"/></svg>',
};
/*
    :id="moduleId" module最外层需要在单独生成组件，用于对模块进行个性化处理，比如图片的，feature
    或marginResize中原先HeightObject的处理。
*/
export default {
    name: 'jz-module',
    components: {
        ...manageUse.components,
        ModuleButtonFrame,
        ModuleImageFrame,
        ModuleSiteSearchFrame,
        ModuleFrame,
        gtSvg,
    },
    props: ['id', 'rowid', 'moduleIndex', 'projectid'],

    data() {
        return {
            isInitAnimate: false,
            moduleId: `module${this.projectid}`,
            isShowSideModule: false,
            isShowModule: true,
            timers: [],
            isDoing: false,
            isHoverTool: false,
            isShowContextMenu: false,
            editPanelActive: false,
            moduleInstance: null,
        };
    },

    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'rows', 'colId', 'LS']),
        modules() {
            return this.$store.state.modules;
        },
        canShowModule() {
            if (this.isRecommendProductModule) {
                return this.module.recommendProductV2Visible;
            } else if (this.isRecommendNewsListModule) {
                return this.module.newsList?.length > 0;
            } else {
                //首页不显示当前位置
                return !(this.colId == 2 && this.module.style === 34);
            }
        },
        isMobi() {
            return this.device === 'mobi';
        },
        pattern() {
            return this.module.pattern[this.device];
        },

        modeuleEl() {
            return document.getElementById(`module${this.id}`);
        },

        module() {
            return this.modules['module' + this.id];
        },
        wrapStyle() {
            let style = {};

            if (this.moduleStatus === 1) {
                const lf = this.ls.lf;
                style = {
                    position: 'fixed',
                    left: `${lf.l * 100}%`,
                    top: `${lf.t * 100}%`,
                    width: `${lf.w}px`,
                    height: `${lf.h === 0 ? `` : `${lf.h}px`}`,
                    'z-index': 11,
                };
                // 侧停
            } else if (this.moduleStatus === 2) {
                const sf = this.ls.sf;
                style = {
                    position: 'fixed',
                    [this.isDoing ? '' : 'transition']: `${sf.s === 1 ? 'left' : 'right'} 0.8s`,
                    [this.isDoing ? '' : 'will-change']: `${sf.s === 1 ? 'left' : 'right'}`,
                    [sf.s === 1 ? 'left' : 'right']: `${
                        this.isShowSideModule ||
                        this.isDoing ||
                        this.isHoverTool ||
                        this.isShowContextMenu ||
                        this.editPanelActive
                            ? 0
                            : -sf.w
                    }px`,
                    [sf.s !== 1 ? 'left' : 'right']: `auto`,
                    top: `${sf.t * 100}%`,
                    width: `${sf.w}px`,
                    height: `${sf.h === 0 ? `` : `${sf.h}px`}`,
                    'z-index': 11,
                };
            }
            if (!this.manageMode) {
                return style;
            }
            return Object.assign(style, this.moduleBorderStyle, this.moduleShadowStyle);
        },
        moduleBorderStyle() {
            // 手机视图，如果选择默认，则使用pc的数据
            if (this.isMobi && this.moduleMobiBorder.y === 0) {
                return getComputedBorder(this.modulePcBorder);
            }

            return getComputedBorder(this.moduleBorder);
        },
        moduleBorder() {
            return this.isMobi ? this.moduleMobiBorder : this.modulePcBorder;
        },
        moduleShadowStyle() {
            // 手机视图，如果选择默认，则使用pc的数据
            if (this.isMobi && this.moduleMobiShadow.t === 0) {
                return getComputedShadow(this.modulePcShadow);
            }

            return getComputedShadow(this.moduleShadow);
        },
        moduleShadow() {
            return this.isMobi ? this.moduleMobiShadow : this.modulePcShadow;
        },
        modulePcBorder() {
            return this.modulePcPattern.b || {};
        },

        moduleMobiBorder() {
            return this.moduleMobiPattern.b || {};
        },

        modulePcShadow() {
            return this.modulePcPattern.s || {};
        },

        moduleMobiShadow() {
            return this.moduleMobiPattern.s || {};
        },

        moduleClass() {
            const moduleClasses = [];

            moduleClasses.push(`jz_module_style_${this.module.style}`);

            if (this.recommendModule) {
                moduleClasses.push(`recommend_module`);
            }
            if (this.module.pattern.pc.rf === 1) {
                moduleClasses.push('responsive_fontSize');
            }

            this.module.type > 1 && !this.recommendModule && moduleClasses.push('jz_sys_module');

            this.isLayoutModule && moduleClasses.push('layout_module');

            return moduleClasses;
        },

        recommendModule() {
            // return false;
            return [13, 14].includes(this.module.type);
        },

        isRecommendProductModule() {
            return this.module.type == 13;
        },

        isRecommendNewsListModule() {
            return this.module.type == 14;
        },

        actualFrame() {
            let nowFrame = [7, 8];
            // 因为上面dblclick.native.stop的native需要统一作用在组件上，这里div改成一个组件
            // 如果有管理态逻辑。要写在JzModule 或者像按钮和图片新增frame自己实现

            return nowFrame.includes(this.module.style)
                ? `module-${Comm.getModuleStyleName(this.module.style).replace('Module', '')}-frame`
                : 'module-frame';
        },
        actualCompon() {
            const { style } = this.module;
            let moduleCompon = getModuleComponent(style);
            if (moduleCompon === 'unknown') {
                moduleCompon =
                    this.module && this.module.style ? 'module-' + Comm.getModuleStyleName(this.module.style) : '';
            }
            return moduleCompon;
        },

        newsResulutFlagTitle: Comm.bindFlag(0x8),

        isNewsResultModule() {
            return this.module.style == 5 && this.module.type == 3;
        },

        bannerType() {
            if (this.isSideModule) {
                return false;
            }

            // 文章详情模块默认显示标题栏
            if (this.isNewsResultModule && !this.newsResulutFlagTitle) {
                return true;
            }

            var device = this.device,
                pcBannerType = this.module.pattern.pc.by,
                mobiBannerType = this.module.pattern.mobi.by;

            if (device == 'pc') {
                return pcBannerType == 0;
            } else {
                return mobiBannerType == 0;
            }
        },

        title() {
            // 根据筛选来确定模块标题
            //文章筛选结果
            if (this.isNewsResultModule && !this.newsResulutFlagTitle) {
                if (this.module.bannerTitle) {
                    return this.module.bannerTitle;
                }
            }

            return this.module.name;
        },

        bannerStyle() {
            if (!this.manageMode) {
                return;
            }
            var device = this.device,
                pcBg = this.module.pattern.pc.bb,
                mobiBg = this.module.pattern.mobi.bb,
                pcHeightType = this.module.pattern.pc.bht,
                mobiHeightType = this.module.pattern.mobi.bht,
                pcHeight = this.module.pattern.pc.bh,
                mobiHeight = this.module.pattern.mobi.bh,
                bg = Comm.getDisplayData(device, pcBg, mobiBg),
                heightType = Comm.getDisplayData(device, pcHeightType, mobiHeightType),
                height = device == 'mobi' && mobiHeightType != 0 ? mobiHeight : pcHeight,
                pcFont = this.module.pattern.pc.bf,
                mobiFont = this.module.pattern.mobi.bf,
                alignDict = ['left', 'center', 'flex-end', 'justify'],
                font = Comm.getDisplayData(device, pcFont, mobiFont),
                bGstyle = {},
                style = {};

            if (heightType == 1) {
                style.height = height + 'px';
            }

            if (font.ta === 3) {
                // 解决字体自定义2端对齐
                font.y && (style.justifyContent = alignDict[0]);
            } else {
                font.y && (style.justifyContent = alignDict[font.ta]);
            }

            bGstyle = getComputedBg(bg);
            Comm.extend(style, bGstyle);
            return style;
        },

        titleStyle() {
            if (!this.manageMode) {
                return;
            }
            //如果mobi默认，就取pc的数据
            var device = this.device,
                pcFont = this.module.pattern.pc.bf,
                mobiFont = this.module.pattern.mobi.bf,
                alignDict = ['left', 'center', 'right', 'justify'],
                font = Comm.getDisplayData(device, pcFont, mobiFont),
                fontStyle = {},
                textColorStyle = {},
                style = {};
            fontStyle = font.y && {
                fontSize: font.s + 'px',
                fontFamily: font.f,
                fontStyle: font.i == 1 ? 'italic' : 'normal',
                fontWeight: font.w == 1 ? 'bold' : 'normal',
                textDecoration: font.d == 1 ? 'underline' : 'none',
                textAlign: alignDict[font.ta],
            };
            textColorStyle = font.y && getComputedTextColor(font.c);
            Comm.extend(style, fontStyle);
            Comm.extend(style, textColorStyle);
            return style;
        },

        hasContentHeight() {
            // 按钮
            const hasContentHeightModuleIds = [8];
            // 全站搜索框在移动端不能调整高度
            if (this.device === 'pc') hasContentHeightModuleIds.push(29);
            return hasContentHeightModuleIds.includes(this.module.style);
        },

        pcPrivatePattern() {
            return this.module.privatePattern.pc;
        },

        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },

        modulePcPattern() {
            return this.module?.pattern?.pc ?? {};
        },

        moduleMobiPattern() {
            return this.module?.pattern?.mobi ?? {};
        },

        modulePcBg() {
            return this.modulePcPattern.bg || {};
        },

        moduleMobiBg() {
            return this.moduleMobiPattern.bg || {};
        },

        moduleBg() {
            return this.isMobi ? this.moduleMobiBg : this.modulePcBg;
        },

        moduleBackgroundStyle() {
            if (!this.manageMode) {
                return;
            }
            // 手机视图，如果选择默认，则使用pc的数据
            if (this.isMobi && this.moduleMobiBg.y === 0) {
                return getComputedBg(this.modulePcBg);
            }

            return getComputedBg(this.moduleBg);
        },

        contentStyle() {
            if (!this.manageMode) {
                if (this.contentHeight.boxSizing === 'border-box') {
                    return this.contentHeight;
                }
                return;
            }
            const contentStyle = Object.assign({}, this.contentHeight, this.moduleBackgroundStyle);

            return contentStyle;
        },

        contentHeight() {
            let heightStyle = {};

            if (!this.hasContentHeight) {
                return heightStyle;
            }

            const privatePattern = this.device === 'pc' ? this.pcPrivatePattern : this.mobiPrivatePattern; //拖拽修改过高度

            const moduleStatus = this.module.commProp.ls.st;
            if (privatePattern.pcht == 1) {
                if (this.module.style === 8 && privatePattern.lock === 1 && moduleStatus !== 1 && moduleStatus !== 2) {
                    heightStyle = {
                        paddingTop: privatePattern.ratio * 100 + '%',
                        boxSizing: 'border-box',
                        height: 'auto',
                    };
                } else {
                    heightStyle = {
                        height: privatePattern.pch + 'px',
                    };
                }
            } else {
                if (this.device === 'mobi' && this.pcPrivatePattern.pcht === 1) {
                    if (
                        this.module.style === 8 &&
                        this.pcPrivatePattern.lock === 1 &&
                        moduleStatus !== 1 &&
                        moduleStatus !== 2
                    ) {
                        heightStyle = {
                            paddingTop: this.pcPrivatePattern.ratio * 100 + '%',
                            boxSizing: 'border-box',
                            height: 'auto',
                        };
                    } else {
                        heightStyle = {
                            height: this.pcPrivatePattern.pch + 'px',
                        };
                    }
                }
            }

            return heightStyle;
        },

        canSort() {
            return this.device == 'pc';
        },

        decodeTitle() {
            return decodeHtmlStr(this.title);
        },

        moduleContentClass() {
            const moduleClasses = [];

            return moduleClasses;
        },
        innerFeatures() {
            if (!this.moduleInstance) {
                return [];
            }
            let innerFeatures = this.moduleInstance.getFeatures();
            return innerFeatures;
        },

        commProp() {
            return this.module.commProp;
        },

        ls() {
            return this.commProp.ls;
        },

        moduleStatus() {
            return this.ls.st;
        },
        layoutStatusModuleIds() {
            return this.$store.state.responsiveInfo.layoutStatusModuleIds;
        },

        isLayoutModule() {
            return this.layoutStatusModuleIds.includes(this.projectid);
        },
        isLockModule() {
            return this.moduleStatus === 1;
        },
        isSideModule() {
            return this.moduleStatus === 2;
        },

        moduleWrapStyle() {
            const moduleWrapStyle = {};

            if (this.isLayoutModule) {
                moduleWrapStyle.height = '100%';
            }

            // 锁定和侧停(非可以调整背景颜色的模块)默认背景颜色为填充
            if (this.isLayoutModule && ![7, 8].includes(this.module.style) && this.moduleBg.y === 0) {
                Object.assign(moduleWrapStyle, {
                    'background-color': '#fff',
                });
            }

            return moduleWrapStyle;
        },
        sideBtnClass() {
            return `side_btn_${this.ls.sf.s === 1 ? 'left' : 'right'}`;
        },
        bannerMoreStyle() {
            if (!this.manageMode) {
                return;
            }
            //如果mobi默认，就取pc的数据
            var pcFont = this.module.pattern.pc.mf,
                font = pcFont,
                fontStyle = {},
                textColorStyle = {},
                style = {};
            fontStyle = font.y && {
                fontSize: font.s + 'px',
                fontFamily: font.f,
                fontStyle: font.i == 1 ? 'italic' : 'normal',
                fontWeight: font.w == 1 ? 'bold' : 'normal',
                textDecoration: font.d == 1 ? 'underline' : 'none',
            };
            textColorStyle = font.y && getComputedTextColor(font.c);
            Comm.extend(style, fontStyle);
            Comm.extend(style, textColorStyle);
            style['--moreActiveColor'] = font.y && getComputedTextColor(font.hc).color;
            return style;
        },
        moreType() {
            return this.commProp.more.moreType;
        },
        moreUrl() {
            return this.commProp.more.link.url;
        },
        moreRel() {
            return this.commProp.more.link.nf ? 'nofollow' : '';
        },
        moreTarget() {
            return this.commProp.more.link.ib ? '_blank' : '_self';
        },
    },
    watch: {
        module: {
            deep: true,

            handler(val) {
                const row = this.$store.state.rows[`row${this.rowid}`];
                if (row && row.type === 5) {
                    Vue.nextTick(() => {
                        banner.fixBannerOverflowHeight();
                    });
                }
            },
        },
    },

    mounted() {
        var commProp = this.module.commProp,
            al = commProp.al,
            cal = commProp.cal,
            pa = commProp.pa,
            hasPhotoAnimate = commProp.pa.t != 0,
            that = this;

        if (this.module.id >= 300 || [13, 14].includes(this.module.id)) {
            // 推荐产品同产品展示一样
            // 非新模块，才增加模块动画的类
            al.forEach((list) => {
                if (list.t != 0 && !that.isInitAnimate && !list.runed) {
                    let hasAnimationManage = bizShared.AnimationApis.getAnimationManage(this.module.id); // 这里用jq加类是因为执行动画时，会用jq去掉类
                    // 如果用vue加类，用jq去掉类，会导致vue拿到缓存的类，导致去掉的类重新加上去

                    let haveConfig = hasAnimationManage; // 拖拽会重新渲染组件，所以需要判断是否已经拥有该配置，如果有了就不应该加上这个类。

                    if (!haveConfig) {
                        $('#module' + this.module.id).addClass('animateModule');
                        that.isInitAnimate = true;
                    }

                    return false;
                }
            });
            cal.forEach((list) => {
                if (list.t != 0) {
                    let hasAnimationManage = bizShared.AnimationApis.getAnimationManage(this.module.id); // 拖拽会重新渲染组件，所以需要判断是否已经拥有该配置，如果有了就不应该加上这个类。

                    if (!hasAnimationManage) {
                        $('#module' + this.module.id)
                            .find('.module_content')
                            .addClass('jz_hidden_content_Animate');
                    } //moduleClasses.push('jz_hidden_content_Animate');

                    return false;
                }
            });
        }

        hasPhotoAnimate &&
            jzUtils.run(
                {
                    name: 'photoAnimation.subscribe',
                    callMethod: true,
                    base: JZ,
                },
                {
                    id: this.module.id,
                    style: this.module.style,
                    photoAnimate: pa,
                }
            );
        this.isInitAnimate = true;
        this.$nextTick(() => {
            if (this.projectid == this.module.id) {
                // 轮播通栏里的行嵌套的模块id 不能跟真正展示的模块一样
                if ($(this.$el).parents('.jz_web_row--carousel_item_clone').length) {
                    this.moduleId += 0;
                }
            }
        });
        if (this.manageMode) {
            Vue.nextTick(() => {
                if (this.isLayoutModule) {
                    this.initLayoutModuleAction();
                    Root._voidVm.$on('v-hovermenu.mounted', this.onHovermenuMounted);
                    Root._voidVm.$on('v-hovermenu.beforeDestroy', this.onHovermenuBeforeDestroy);
                    Root._voidVm.$on('editPanelActive', this.editModulePanelAction);
                }
            });

            let $jzModule = this.$el;
            $jzModule && $jzModule.addEventListener('transitionend', this.emmitSiteModuleTransitionEnd);

            this.initModuleInstance();
        }

        this.fixLockedModulePosition();
    },
    beforeDestroy() {
        let $jzModule = this.$el;
        $jzModule && $jzModule.removeEventListener('transitionend', this.emmitSiteModuleTransitionEnd);

        if (!this.manageMode) {
            return;
        }
        Root._voidVm.$off('v-hovermenu.mounted', this.onHovermenuMounted);
        Root._voidVm.$off('v-hovermenu.beforeDestroy', this.onHovermenuBeforeDestroy);
        Root._voidVm.$off('showContextMenu', this.editContextMenu);
        Root._voidVm.$off('editPanelActive', this.editModulePanelAction);
        this.destroyLayoutModuleAction();
    },
    methods: {
        fixLockedModulePosition() {
            if (!this.isLockModule || this.isMobi) {
                return;
            }
            const $module = $(this.$el);
            if ($module.position().left + $module.width() > window.innerWidth) {
                // 超出右边屏幕，修复到最右边
                $module.css({ left: 'auto', right: 0 });
            }
        },
        initModuleInstance() {
            const moduleInstance = new ModuleManage(this.module);
            moduleInstance.registerFeatures();
            this.moduleInstance = moduleInstance;
            this.$watch(
                () => {
                    const watchData = [this.device, this.moduleIndex];
                    const row = this.$store.state.rows[`row${this.rowid}`];
                    if (row) {
                        watchData.push(this.$store.getters.isRowOpenVerticalAlign(row));
                    }
                    return watchData;
                },
                () => this.setFeatureData(),
                {
                    immediate: true,
                }
            );
        },
        setFeatureData() {
            if (!this.manageMode) {
                return;
            }
            const moduleInstance = this.moduleInstance;
            let innerFeatures = moduleInstance.getFeatures();
            for (let i in innerFeatures) {
                if (moduleInstance[`set${innerFeatures[i].name}`]) {
                    moduleInstance[`set${innerFeatures[i].name}`](this, this.device);
                }
            }
        },
        emmitSiteModuleTransitionEnd() {
            if (!this.manageMode) {
                return;
            }
            Root._voidVm.$emit('v-hovermenu.refreshPosition', this.$el);
        },
        moduleEdit() {
            if (!this.manageMode) {
                return;
            }
            // 位于编辑通栏模式
            if (this.$store.state.row.rowEditing) {
                return;
            } //双击无效，稍后在看

            var styleName = Comm.getModuleStyleName(this.module.style),
                moduleId = this.module.id;

            switch (styleName) {
                case 'buttonModule': {
                    if (this.device === 'mobi') {
                        editMobiModule(moduleId);
                    } else {
                        buttonModuleEdit(moduleId);
                    }
                    break;
                }

                case 'textModule': //Root.textModuleEdit(moduleId);;break;

                // eslint-disable-next-line no-fallthrough
                case 'imageModule':
                    break;

                default:
                    editModule(moduleId);
            }
        },
        showSideModule(val, moduleId) {
            if (moduleId && moduleId !== this.module.id) {
                return;
            }

            if (val) {
                this.timers.forEach((timer) => clearTimeout(timer));
                this.isShowSideModule = true;
            } else {
                const timer = setTimeout(() => {
                    this.isShowSideModule = false;
                }, 300);
                this.timers.push(timer);
            }
        },
        initDraggable() {
            if (!this.manageMode) {
                return;
            }
            $(this.modeuleEl).draggable({
                containment: 'window',
                cursor: 'move',
                axis: 'x,y',
                scroll: false,
                distance: 3,
                start: () => {
                    // 处理拖拽按钮时的跳转问题
                    $(this.modeuleEl).css({
                        'pointer-events': 'none',
                    });

                    this.isDoing = true;
                },
                stop: (_, { position: { left, top } }) => {
                    // 处理拖拽按钮时的跳转问题 click会在stop后再触发 setTimeout处理
                    setTimeout(() => {
                        $(this.modeuleEl).css({
                            'pointer-events': 'auto',
                        });
                    }, 300);

                    this.isDoing = false;
                    const { ls } = this.module.commProp;
                    const { lf, sf } = ls;

                    const containerWidth = window.innerWidth;
                    const containerHeight = window.innerHeight;

                    // 锁定
                    if (ls.st === 1) {
                        lf.l = left / containerWidth;
                        lf.t = top / containerHeight;
                        // 侧停
                    } else if (ls.st === 2) {
                        const $module = $(this.modeuleEl);

                        // 侧停左右规律
                        // 看中间两边划分多少 -> 看left 和 right 谁大
                        {
                            const { left, top, width } = $module[0].getBoundingClientRect();

                            // 判断左边距和右边距
                            sf.s = left <= containerWidth - left - width ? 1 : 2;

                            // 处理模块拖拽停靠位置, 直接停在对应位置
                            sf.t = top / containerHeight;
                        }
                        Vue.nextTick(() => {
                            $(this.modeuleEl).css(sf.s !== 1 ? 'left' : 'right', `auto`);
                        });
                    }
                },
            });
        },
        initResizable() {
            if (!this.manageMode) {
                return;
            }
            $(this.modeuleEl).resizable({
                handles: 'nw, n, ne, e, sw, s , se, w',
                zIndex: 1001,
                maxWidth: 990,
                minWidth: 30,
                start: () => {
                    this.isDoing = true;
                },
                stop: (_, { size: { width, height }, position: { left, top } }) => {
                    this.isDoing = false;
                    const { ls } = this.module.commProp;
                    const { lf, sf } = ls;

                    const containerWidth = window.innerWidth;
                    const containerHeight = window.innerHeight;

                    // 锁定
                    if (ls.st === 1) {
                        lf.h = height;
                        lf.w = width;

                        lf.l = left / containerWidth;
                        lf.t = top / containerHeight;
                    } else if (ls.st === 2) {
                        sf.h = height;
                        sf.w = width;
                    }
                },
            });
        },
        destroyDraggable() {
            if (!this.manageMode) {
                return;
            }
            if ($(this.modeuleEl).data('ui-draggable')) {
                // error to call destroy if not there
                $(this.modeuleEl).draggable('destroy');
            }
        },
        destroyResizable() {
            if (!this.manageMode) {
                return;
            }
            if ($(this.modeuleEl).data('ui-resizable')) {
                // error to call destroy if not there
                $(this.modeuleEl).resizable('destroy');
            }
        },
        initLayoutModuleAction() {
            if (!this.manageMode) {
                return;
            }
            this.initDraggable();
            this.initResizable();
        },
        destroyLayoutModuleAction() {
            if (!this.manageMode) {
                return;
            }
            this.destroyDraggable();
            this.destroyResizable();
        },
        hideLockModule() {
            this.isShowModule = false;
        },
        onHovermenuMounted(menus) {
            if (!this.manageMode) {
                return;
            }
            if (menus.type !== 'module') {
                return;
            }
            let id = menus.data.id;
            this.showSideModule(true, id);
            this.editSideModule(true, id);
        },
        onHovermenuBeforeDestroy(menus) {
            if (!this.manageMode) {
                return;
            }
            if (menus.type !== 'module') {
                return;
            }
            let id = menus.data.id;
            this.showSideModule(false, id);
            this.editSideModule(false, id);
        },
        editSideModule(isShow, id) {
            if (!this.manageMode) {
                return;
            }
            if (id === this.module.id) {
                this.isHoverTool = isShow;
            }
        },
        editContextMenu(isShow, id) {
            if (!this.manageMode) {
                return;
            }
            if (id === this.module.id) {
                this.isShowContextMenu = isShow;
            }
        },
        editModulePanelAction({ active, type, panelId }) {
            if (!this.manageMode) {
                return;
            }
            if (['module', 'smallEditPanel'].includes(type) && panelId === this.module.id) {
                this.editPanelActive = active;
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
