import SubmitFormCss from './submitFormCss.vue';

const regA = /<i>/g;
const regB = /<\/i>/g;
const regC = /<!---->/g;

export const updateSubmitFormStyle = (module, id = 'modulestyle') => {
    // 渲染数据用的节点（不挂载）
    const dataEl = document.createElement('div');
    // 挂载样式用的节点（挂载到head）
    const mountEl = document.createElement('style');

    // 挂载样式节点
    mountEl.id = `${id}-${module.id}`;
    document.head.appendChild(mountEl);

    let submitFormApp = new window.Vue({
        el: dataEl,
        render(h) {
            return h(SubmitFormCss, {
                on: {
                    update,
                },
                props: {
                    module,
                },
            });
        },
    });

    update(formatCss(submitFormApp.$el.innerText));

    // 样式更新
    function update(result) {
        mountEl.innerHTML = formatCss(result);
    }
};

function formatCss(str) {
    return str.replace(regA, '').replace(regB, '').replace(regC, '');
}
