var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "jz_web_header g_header",
      class: {
        g_header__fixed: _vm.isFixedBackgroundCurrent,
        g_header__static: !_vm.isFixedBackgroundCurrent,
      },
      style: _vm.headerStyle,
      attrs: { id: "gridHeader" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          ref: "headerContent",
          staticClass: "header_content g_header_content",
          class: _vm.headerContentClass,
          style: _vm.headerContentStyle,
        },
        [_c("jz-web-rows", { attrs: { ids: _vm.headerRowIds } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }