//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
        maxlength: Number,
        inputTextStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            inputText: '',
        };
    },
    created() {
        console.log;
    },
    methods: {
        inputItemText(e) {
            var value = e.target.value;
            this.inputText = value;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.inputText);
        },
    },
};
