var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { class: "star_warp_" + _vm.index }, [
    _c(
      "i",
      { staticClass: "faisco-icons-S000054 comment_start", class: _vm.classes },
      [
        _vm.curIndx < 6
          ? _c("commentFiveStar", {
              attrs: {
                index: _vm.curIndx,
                star: _vm.star,
                "creat-comment": _vm.creatComment,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }