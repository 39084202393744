//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logDog } from '@/site/shared/log/index.js';
export default {
    props: ['list'],
    data() {
        return {
            dragOptions: {
                filter: '.fixed_item',
                preventOnFilter: false,
                fallbackTolerance: 1,
                handle: '.form_item',
                forceFallback: true,
                supportPointer: false,
                checkMove(e) {
                    //如果没有这个属性，绑定的事件是pointerdown，然后给resize句柄加的mousedown就没有效果 (*/ω＼*)
                    return !$(e.related).hasClass('fixed_item');
                },
            },
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
    },
    methods: {
        onSortStart() {
            $(this.$el).addClass('form_editing');
        },
        onSortEnd() {
            $(this.$el).removeClass('form_editing');
            logDog(200746, 15);
        },
    },
};
