//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'FileView',
    props: {
        fileItem: {
            type: Object,
            default() {
                return {};
            },
        },
        imgStyle: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    methods: {
        emitImgClick() {
            this.$emit('imgClick');
        },
    },
};
