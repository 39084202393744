//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logDog } from '@/site/shared/log/index.js';
export default {
    props: ['item', 'module'],
    data() {
        return {
            widthForTip: 0,
            heightForTip: 0,
            resizeForTip: false,
            formItemIsActive: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        itemStyle() {
            const styleObj = {};

            if (!this.isMobi && this.style.w) {
                //宽度
                styleObj['width'] = `${this.style.w * 100}%`;
            }

            return styleObj;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        style() {
            const fis = this.module.privatePattern.pc.fis || {};
            return fis[this.item.id] || {};
        },
        pattern() {
            return this.module.privatePattern.pc;
        },
    },
    watch: {
        resizeForTip(val) {
            if (val) {
                $(this.$el).parents('.form_container').addClass('form_editing');
            } else {
                $(this.$el).parents('.form_container').removeClass('form_editing');
            }
        },
    },
    methods: {
        setWidth(val) {
            const formStyle = this.module.privatePattern.pc.fis;
            if (!formStyle[this.item.id]) {
                Vue.set(formStyle, this.item.id, {});
            }
            const itemStyle = formStyle[this.item.id];
            Vue.set(itemStyle, 'w', val);
        },
        resetInit() {
            this.startX = 0;
            this.moveX = 0;
            this.orgWidth = 0;
            this.parWidth = $(this.$el).parent().width();
        },
        resizeHandlerMouseDown(e) {
            var defaultWidth = 0.3333;
            this.resetInit();
            this.startX = e.pageX;
            this.resizeForTip = true;
            this.formItemIsActive = true;

            if (this.item.id == 'validateCode') {
                defaultWidth = 1;
            }
            this.orgWidth = this.style.w || defaultWidth;

            this.updateResizeTip();
            document.addEventListener('mousemove', this.mousemove);
            document.addEventListener('mouseup', this.mouseup);
        },
        mousemove(e) {
            this.moveX = this.startX - e.pageX;
            let realyWidth = this.orgWidth - Math.floor(this.moveX) / this.parWidth;

            if (realyWidth < 0) {
                realyWidth = 0;
            }
            if (realyWidth > 1) {
                realyWidth = 1;
            }

            this.setWidth(realyWidth);

            this.updateResizeTip();
            this.$refs.tooltipResizeHandler.updatePopper();
        },
        updateResizeTip() {
            this.widthForTip = Math.round(parseFloat(window.getComputedStyle(this.$refs.formItem).width)) + 'px';
            this.heightForTip = Math.round(parseFloat(window.getComputedStyle(this.$refs.formItem).height)) + 'px';
        },
        mouseup() {
            this.resizeForTip = false;
            this.formItemIsActive = false;
            logDog(200746, 14);
            document.removeEventListener('mousemove', this.mousemove);
            document.removeEventListener('mouseup', this.mouseup);
        },
    },
};
