//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        text: {
            type: String,
            default: '',
        },
        hide: {
            type: Boolean,
            default: false,
        },
        module: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...Vuex.mapState(['device']),
        show() {
            return this.text !== '' && !this.hide;
        },
        style() {
            return {
                opacity: this.show ? 1 : 0,
            };
        },
        showPhotoAnimateLayer() {
            var photoAnimate = this.module.commProp.pa,
                animateType = photoAnimate.t;

            return this.device == 'pc' && animateType == 5;
        },
    },
};
