//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logFdpRequest } from '@/site/shared/log/index.js';
import { isBrowserEnv } from '@jz/utils';
export default {
    name: 'ImageMainViewer',
    props: {
        onLog: {
            type: Function,
            default: isBrowserEnv() ? logFdpRequest : () => {},
        },
        logEventConfig: {
            type: Object,
            default() {
                return {};
            },
        },
        showPcViewer: {
            type: Boolean,
            default: false,
        },
        showMobiViewer: {
            type: Boolean,
            default: false,
        },
        urlList: {
            type: Array,
            default: () => [],
        },
        zIndex: {
            type: Number,
            default: 2000,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        initialIndex: {
            type: Number,
            default: 0,
        },
        appendToBody: {
            type: Boolean,
            default: true,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        maxScale: {
            type: Number,
            default: 4,
        },
        minScale: {
            type: Number,
            default: 0.2,
        },
        isMobi: {
            // 假设是ssr模块 用了 请传入该参数
            type: Boolean,
            default: false,
        },
        manageMode: {
            type: Boolean,
            default: false,
        },
        appendToContainerId: {
            type: String,
            default: 'jzWebContainer',
        },
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['contextMenuDisabled']),
    },
};
