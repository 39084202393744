//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ImageComponent } from '@jz/biz-shared';
import { getImgComponetOption, imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
import { carouselMixin } from './mixins/carousel.js';
import { patternMixin } from './mixins/pattern.js';
import { titleMixin } from './mixins/title.js';
import { picMixin } from './mixins/picSetting.js';
import { linkMixin } from './mixins/link.js';
const manageUse = {};


export default {
    name: 'CarouselNewsList',
    components: {
        ImageComponent,
    },
    mixins: [patternMixin, titleMixin, carouselMixin, picMixin, linkMixin],
    props: {
        newsList: {
            type: Array,
            default() {
                return [];
            },
        },
        module: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        filterNewsList() {
            return this.newsList.slice(0, 8);
        },
        isNotSwitchImgEffect() {
            return this.module?.commProp?.pa?.t != 6;
        },
        imgEffects() {
            if (this.isMobi) {
                return { type: 0 };
            } else {
                return this.isNotSwitchImgEffect ? this.imgOptsComputed() : this.getImgComponetOption(this.module);
            }
        },
        carouselWrapClass() {
            let classList = [];
            if (this.isMobi) {
                classList.push('carousel_mobi_wrap');
            } else {
                classList.push('carousel_pc_wrap');
            }
            return classList;
        },
    },
    methods: {
        imgOptsComputed,
        getImgComponetOption,
        photoItemClass(index) {
            let classList = [];
            if (this.currentPicIndex == index || (this.prePicIndex === index && this.outActivePicFlag)) {
                classList.push('activedPic');
            }
            if (this.animateDir == 'left') {
                if (this.currentPicIndex === index && this.inAnimateFlag) {
                    classList.push('jz_page_moveLeftIn');
                }
                if (this.prePicIndex === index && this.outAnimateFlag) {
                    classList.push('jz_page_moveLeftOut');
                }
            } else if (this.animateDir == 'right') {
                if (this.currentPicIndex === index && this.inAnimateFlag) {
                    classList.push('jz_page_moveRightIn');
                }
                if (this.prePicIndex === index && this.outAnimateFlag) {
                    classList.push('jz_page_moveRightOut');
                }
            }

            return classList;
        },
        hovermenu: (manageUse && manageUse.hovermenu) || function () {},
    },
};
