export default {
    namespaced: true,
    state: () => ({
        // 改变量获取已经废弃，请使用store.state.responsiveInfo.pattern.backToTop，谢谢
        isHideBackTop: false,
        isSystemBackTopBtn: 0,
        backTopType: 0,
        backTopBtnColor: '',
        isDefaultBtnColor: 0,
        btnUrl: '',
        //废弃end
        scrollYValue: 0,
    }),
    mutations: {
        setBackToTopData(state, payload) {
            state.isHideBackTop = payload.isHideBackTop;
            if (!state.isHideBackTop) {
                state.isSystemBackTopBtn = payload.isSystemBackTopBtn;
                state.backTopType = payload.backTopType;
                state.backTopBtnColor = payload.backTopBtnColor;
                state.isDefaultBtnColor = payload.isDefaultBtnColor;
                state.btnUrl = payload.btnUrl;
            }
        },
        setScrollYValue(state, val) {
            state.scrollYValue = val;
        },
    },
};
