import CssStyles from './CssStyles.vue';
import { createNewStoreToSsr } from '@/store/store.js';
import { initStore } from '@/store/index.js';
import Vue from 'vue';

export const createCssStylesStore = (data) => {
    const store = createNewStoreToSsr();
    initStore(store, data);

    return store;
};

export const createCssStylesApp = ({ store, update = () => {} }) => {
    const app = new Vue({
        components: {
            CssStyles,
        },
        store,
        render: (h) =>
            h(CssStyles, {
                on: { update },
            }),
    });

    return app;
};
