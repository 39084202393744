//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Pack',
    props: {
        moduleIds: {
            type: Array,
            default: () => [],
        },
        moduleIndex: {
            type: Number,
            default: 0,
        },
        rowId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        ...Vuex.mapGetters(['isMobi']),
        ...Vuex.mapState('manage/auth', ['designAuth']),
    },
    methods: {
        addModule() {
            this.$emit('addModule');
        },
    },
};
