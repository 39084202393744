//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { extend, getStyleDataByDevice, isNewModuleAfter20210707, bindFlag, getWrapStyle } = Comm;
import { Message } from '@shared/manage/componMessage/index.js';
import { videoEndAndReplay, dialogVideo } from './flv.js';
import { decodeHtml } from '@/components/utils.js';

/*
 * @Author: Mackie.zhengchuhui
 * @Date: 2019-12-05 10:17:22
 * @Last Modified by: mikey.chuhui
 * @Last Modified time: 2022-02-21 16:55:25
 */
import { browser } from '@jz/utils';
import { getFlvData, getFlvVideoImg } from '@api/flv/index.js';
import { getComputedBg } from '@/shared/manage/utils';

import { getVideoResourceHandler } from '@/site/mediaResourceHandler.js';

import { isSupportWebp } from '@/site/shared/webp/index.js';

export default {
    props: ['id', 'module'],

    data() {
        return {
            lazyLoad: false,
            controller: null,
            isHoverContent: false,
            isSupportWebp: true,
            intersectionObserver: null,
        };
    },

    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'isJzCdnFlowLimit', 'webpOptOpen', 'isMaterialLazyLoad']),
        randomId() {
            /**
             * 	by mackie
             * 	之前是为了视频拖拽的时候能初始化成功 所以是在模块渲染的key上做手脚，导致视频模块在编辑通栏时会重新渲染
             *  视频模块的特殊性  为了不影响通栏编辑时 影响模块 导致重新渲染 因此在video的id做变化
             * 	以前为了拖拽视频模块 导致视频不会被videojs初始化， 因为videojs 初始化的实例是以传入的id命名的
             *  而他们提供的dispose销毁视频实例的方法只是将videoJs.players 里面的实例置为null  判断这个实例是否存在
             * 	只是判断该属性是否存在，所以当我们销毁的时候 视频重新挂载 其实那个实例还在 也就导致我们的视频无法初始化
             * 	所以不能通过销毁的方法让他重新初始化。暂时这么处理，如果改造源码的话，1个是从实例命名入手让我们可以自定义，但是
             * 	这个跟我们在外面这样改动，是一样的，第二，就是改变他的销毁方式。只能在内部改造销毁的方式，外部是不能通过销毁videoJs.players
             * 	来达到目的，因为内部判断调的方法是原型上的getPlayers方法 而这个方法拿的整个类的静态属性，是没有暴露出来的。
             *
             * */
            return Math.floor(0 + Math.random() * (this.id + 100 - 0));
        },

        flvClass() {
            return 'online_flv_' + this.id;
        },

        embedClass() {
            return 'embed_' + this.id;
        },

        videoNetUrl() {
            return this.module.prop5.nu;
        },

        describe() {
            return this.module.prop5.d;
        },

        innerIframeUrl() {
            const _innerIframeUrl = this.module.innerIframeUrl;
            let url = '';
            let urlStr;

            if (!_innerIframeUrl) {
                return '';
            }

            let urlArr = _innerIframeUrl.split('/');

            let urlLen = urlArr.length;
            if (this.module.prop0 === 1) return _innerIframeUrl;

            if (_innerIframeUrl.includes('v.youku.com') && _innerIframeUrl.includes('.html')) {
                const splitString = _innerIframeUrl.split('id_')[1];

                if (splitString) {
                    urlStr = splitString.split('.html')[0];
                }

                url = `//player.youku.com/embed/${urlStr}`;
                return url;
            } else if (_innerIframeUrl.includes('player.youku.com')) {
                if (_innerIframeUrl.includes('.swf')) {
                    urlStr = urlArr[urlLen - 2];
                } else {
                    urlStr = urlArr[urlLen - 1];
                }

                url = `//player.youku.com/embed/${urlStr}`;
                return url;
            } else if (this.isQQvideo(_innerIframeUrl)) {
                urlStr = urlArr[urlLen - 1];

                if (!urlStr) {
                    return '';
                }

                var regExp = /\.html$/g;

                if (!regExp.test(urlStr)) {
                    const splitString = urlArr[urlLen - 1].split('?')[1];

                    if (splitString) {
                        urlStr = splitString.split('=')[1];
                    } else {
                        // 防止链接有问题, split报错
                        urlStr = 'error';
                    }
                } else {
                    urlStr = urlArr[urlLen - 1].split('.html')[0];
                }

                url = `//v.qq.com/txp/iframe/player.html?vid=${urlStr}&tiny=0&auto=0`;
                return url;
            }

            return this.module.innerIframeUrl;
        },

        flvId() {
            return 'new_direction_video_' + this.id;
        },

        isMobi() {
            return this.device == 'mobi';
        },

        isAndroid() {
            return this.module.isAndroid;
        },

        model() {
            return this.module.privatePattern[this.device];
        },

        publicModel() {
            return this.module.privatePattern['pc'];
        },

        mobiModel() {
            return this.module.privatePattern.mobi;
        },

        onlineFlvStyle() {
            if (!this.manageMode) {
                return;
            }
            var style = {};
            this.model.pst == 1 ? (style.paddingBottom = this.model.psh * 100 + '%') : '';
            return style;
        },

        posterImg() {
            if (this.flagUploadPost) {
                if (this.webpOptOpen && this.isSupportWebp) {
                    return `${this.module.cusPosterUrl}.webp`;
                } else {
                    return this.module.cusPosterUrl;
                }
            } else {
                return this.module.pUrl;
            }
        },

        posterImgThumb() {
            if (this.flagUploadPost) {
                if (this.webpOptOpen && this.isSupportWebp) {
                    return `${this.posterImg}.webp`;
                } else {
                    return this.posterImg;
                }
            } else {
                if (this.module.prop0 == 0) {
                    return this.posterImg;
                }
            }

            return '';
        },

        descStyle() {
            if (!this.manageMode) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.publicModel.pf,
                mobiData: this.mobiModel.pf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            }); // pc跟mobi公用样式

            var pcFont = font,
                alignDict = ['left', 'center', 'right', 'justify'],
                fontStyle = {},
                style = {};
            fontStyle = pcFont.y && {
                textAlign: alignDict[pcFont.ta],
            };
            extend(style, fontStyle);
            return style;
        },

        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },

        descTextStyle() {
            if (!this.manageMode) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.publicModel.pf,
                mobiData: this.mobiModel.pf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = this.getComputedTextColor(font.c);

                if (this.isHoverContent && this.isNewModuleAfter20210707 && !this.isMobi) {
                    textColorStyle = this.getComputedTextColor(font.hc || '#000');
                }
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.publicModel.pl,
                mobiData: this.mobiModel.pl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },

        useVideo() {
            // 直接播放
            // &直接添加或者是 网络地址并且是mp4且不是主流视频网站 不是安卓
            if (!this.module.isFrame && this.isAndroid && this.module.prop0 == 0) return false;
            return (
                this.flagDisplayPopup &&
                (this.module.prop0 == 0 ||
                    (this.module.prop0 != 0 &&
                        this.module.isMp4 &&
                        !this.module.isMainVedioDomain &&
                        !this.module.isFrame))
            );
        },

        useIframe() {
            return (
                this.flagDisplayPopup &&
                (this.module.prop0 != 0 || this.module.isFrame) &&
                /.youku|.qq|.iqiyi|.bilibili/.test(this.innerIframeUrl)
            );
        },

        showFlvUrl() {
            if (this.lazyLoad === true || this.isJzCdnFlowLimit === true) {
                return undefined;
            } else {
                return this.displayFlvUrl;
            }
        },

        displayFlvUrl() {
            // 切换视频播放的地址
            return this.module.prop0 != 0 && this.module.isMp4 && !this.module.isMainVedioDomain && !this.module.isFrame
                ? this.videoNetUrl
                : this.module.url;
        },

        flagUploadPost: bindFlag(0x1),
        // 是否有片头图片
        flagDisplayPopup: bindFlag(0x2),
        // 播放方式：弹窗播放/直接播放
        flagLoopPlayPopup: bindFlag(0x4),
        // 循环播放：是/否 by mackie 2019-0904
        flagAutoStartPopup: bindFlag(0x8),
        // 自动播放：是/否 by mackie 2019-0904
        flagHideShowControlPopup: bindFlag(0x10),

        // 隐藏控件：是/否 by mackie 2019-0904
        controls() {
            return this.flagAutoStartPopup && this.flagLoopPlayPopup ? !this.flagHideShowControlPopup : true;
        },

        noAllowVideoJs() {
            return this.module.noAllowVideoJs;
        },

        pattern() {
            return this.module.pattern[this.device];
        },
        videoPosterImgStyle() {
            let style = {};
            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${this.posterImgThumb}')`;
            }
            return style;
        },
    },
    watch: {
        'module.url': function (val) {
            // 直接添加的视频路径
            this.lazyLoad = false;
            Vue.nextTick(() => {
                if (this.module.prop0 == 0) {
                    if (this.flagDisplayPopup) {
                        // 直接播放
                        var tmpDom = document.getElementById('new_direction_video_' + this.id + '_html5_api');

                        if (!tmpDom) {
                            this.initVideo();
                        } else {
                            $(tmpDom).attr('src', val);
                            tmpDom.load();
                        }
                    }
                }
            });
        },

        posterImg(url) {
            // 头图 ： 手动更改
            Vue.nextTick(() => {
                var videoPoster = $('.online_flv_' + this.module.id + ' .vjs-poster');

                if (videoPoster.length) {
                    if (url) {
                        videoPoster.css('backgroundImage', "url('" + url + "')").removeClass('vjs-hidden');
                    } else if (this.module.prop0 == 0 && !this.flagUploadPost) {
                        videoPoster.addClass('vjs-hidden');
                    }
                }
            });
        },

        flagUploadPost(flag) {
            if (this.module.prop0 == 0) {
                if (flag) {
                    this.module.pUrl = this.module.prop3 ? this.module.cusPosterUrl : '';
                } else {
                    // 本地添加的视频，头图是默认时，需要发送请求拿头图
                    this.getFlvVideoImg();
                }
            }
        },

        useVideo(flag) {
            if (!flag) {
                // 更改播放方式的时候的处理
                // 有iframe的情况下，销毁视频窗口
            } else {
                Vue.nextTick(() => {
                    if (this.controller) {
                        this.upDateVideoPopup(this.controller);
                    } else {
                        this.initVideo();
                    }
                });
            }
        },

        flagDisplayPopup(flag) {
            Vue.nextTick(() => {
                if (flag) {
                    this.fixDefaultFlvSize();
                } else {
                    // 高度为默认时 切换播放方式 为弹窗播放
                    this.model.pst == 0 && $('.online_flv_' + this.id).css('padding-bottom', '');
                }
            });
        },

        'module.prop0': function (val) {
            if (val != 0) {
                this.module.isMp4 = this.videoNetUrl.toLocaleLowerCase().indexOf('.mp4') != -1;
            } else {
                Vue.nextTick(() => {
                    this.flagDisplayPopup && this.fixDefaultFlvSize();
                });
            }

            this.getNetFlvData();
        },

        videoNetUrl() {
            this.getNetFlvData();
        },

        flagLoopPlayPopup() {
            Vue.nextTick(() => {
                this.controller && this.upDateVideoPopup(this.controller);
            });
        },

        flagAutoStartPopup() {
            Vue.nextTick(() => {
                this.controller && this.upDateVideoPopup(this.controller);
            });
        },

        flagHideShowControlPopup() {
            Vue.nextTick(() => {
                this.controller && this.upDateVideoPopup(this.controller);
            });
        },
        'pattern.bg': {
            immediate: true,
            deep: true,
            handler() {
                if (!this.manageMode) {
                    return;
                }
                const bg = this.pattern.bg || {};
                let background = {
                    background: '#000',
                };
                if (bg.y === 1) {
                    background = {
                        background: 'none',
                    };
                }
                if (bg.y === 2) {
                    background = getComputedBg(bg);
                }
                this.$nextTick(() => {
                    $(`.${this.flvClass} .video-js, .${this.flvClass} video`).css(background);
                });
            },
        },
    },

    mounted() {
        this.isSupportWebp = isSupportWebp();
        Vue.nextTick(() => {
            this.disposeVideo(); // 切换到动画的tab时 该组件会重新挂载 所以这里检查是否有videojs 初始化

            this.useVideo && !this.useIframe && this.initVideo(); // 直接播放 并且不是iframe的时候才初始化

            videoEndAndReplay(this.id);
        });

        if (this.isJzCdnFlowLimit) {
            try {
                const VideoResourceHandler = getVideoResourceHandler();
                VideoResourceHandler.add(this.$refs.VideoWrapper.querySelector('.direct_play_flv'));
            } catch (error) {
                console.error(error);
            }
        }

        this.initLazyLoad();
    },

    beforeDestroy() {
        this.disposeVideo();
        this.intersectionObserver && this.intersectionObserver.disconnect();
    },

    methods: {
        initLazyLoad() {
            const el = this.$refs.VideoWrapper.querySelector('.video_lazy_load');
            if (!el) {
                return;
            }

            const onLoad = (target) => {
                if (!target) return;
                let videoPath = target.attr('data-original') ?? '';
                let posterPath = target.attr('data-poster');
                if (target.attr('src') !== videoPath) {
                    target.attr('src', videoPath);
                }
                target[0].play();

                if (target.attr('poster') !== posterPath) {
                    const posterEl = target.siblings('.vjs-poster').eq(0);
                    if (posterEl) {
                        posterEl.css('backgroundImage', "url('" + posterPath + "')").removeClass('vjs-hidden');
                    } else {
                        target.attr('poster', posterPath);
                    }
                }
            };

            // 场景: 编辑通栏 -> 更改视频模块位置

            // 如果 el 已经在可视区，则立即加载视频
            if ($(el).is(':visible') && el.getBoundingClientRect().top < window.innerHeight) {
                onLoad($(el));
            }
        },
        isQQvideo(src) {
            return src.includes('v.qq.com') || src.includes('static.video.qq.com') || src.includes('imgcache.qq.com');
        },

        refreshFlvModule() {
            // 视频模块重置
            var that = this;
            var $video = $(`#new_direction_video_${this.id}`);

            // 需要处理轮播通栏克隆节点的情况，避免初始化错节点
            const $el =
                $video.parents('.jz_web_row--carousel_item_clone').length <= 0
                    ? $video
                    : $(`.jz_web_row--carousel_item:not('.jz_web_row--carousel_item_clone')`).find(
                          `#new_direction_video_${this.id}`
                      );

            if ($el.length === 0) return;

            this.controller = Fai.top
                .videojs($el[0], {
                    controlBar: {
                        volumeMenuButton: {
                            inline: false,
                        },
                        currentTimeDisplay: true,
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                    },
                })
                .ready(function () {
                    that.upDateVideoPopup(this);
                });
        },

        upDateVideoPopup(myPlayer) {
            // 更新视频播放方式
            const isWeiXin = browser.isWeChat();
            myPlayer.loop(this.flagLoopPlayPopup); //循环播放 by mackie 2019-08-21
            // this.fixDefaultFlvSize();

            if (this.flagLoopPlayPopup && this.flagAutoStartPopup) {
                //隐藏控件 by mackie 2019-08-21
                myPlayer.controls(this.controls);
            } else {
                myPlayer.controls(this.controls);
            }

            if (this.flagAutoStartPopup) {
                if (this.isMobi && !this.isAndroid) {
                    $('html').one('touchstart', function () {
                        myPlayer.play();
                    });

                    if (isWeiXin) {
                        $(document).ready(() => {
                            this.wxVideoAutoPlay(myPlayer);
                        });
                    }
                }

                myPlayer.autoplay(this.flagAutoStartPopup);
                myPlayer.play();
                myPlayer.muted(this.flagAutoStartPopup);
            } else {
                myPlayer.pause();
                myPlayer.autoplay(this.flagAutoStartPopup);
                myPlayer.muted(this.flagAutoStartPopup);
            }
        },

        wxVideoAutoPlay(myVideo) {
            // 微信端自动播放
            myVideo.play();
            document.addEventListener(
                'WeixinJSBridgeReady',
                function () {
                    myVideo.play();
                },
                false
            );
        },

        dialogVideo() {
            dialogVideo(this.id);
        },

        getNetFlvData() {
            if (this.module.prop0 == 0) {
                return;
            }

            getFlvData({
                videoType: this.module.prop0,
                netUrl: this.videoNetUrl,
            })
                .then((data) => {
                    if (data.success) {
                        this.$store.dispatch('manage/pauseDataWatch');
                        this.module.isFrame = data.flv.isFrame;
                        this.module.isMainVedioDomain = data.flv.isMainVedioDomain;
                        this.module.innerIframeUrl = decodeHtml(data.flv.videoNetUrl);
                    } else {
                        Message.warning(data.msg, true);
                    }
                })
                .catch(() => {
                    Fai.ing('系统繁忙，请稍候重试', false);
                });
        },

        getFlvVideoImg() {
            if (this.module.prop0 != 0 || this.flagUploadPost) {
                return;
            }

            var _this = this;

            getFlvVideoImg({
                flvUrl: this.module.url,
            })
                .then((data) => {
                    if (data.success) {
                        _this.module.pUrl = data.flv.videoImgIdUrl;
                        _this.module.prop5.vid = data.flv.videoImgId;
                    } else {
                        Message.warning('获取视频头图失败，请稍后重试', false);
                    }
                })
                .catch(() => {
                    Message.warning('获取视频头图失败，请稍后重试', false);
                });
        },

        changeHttp() {
            var href = location.href;

            // eslint-disable-next-line @jz/no-use-http-prefix
            if (href.indexOf('https://') == 0) {
                // eslint-disable-next-line @jz/no-use-http-prefix
                href = 'http://' + href.substring(8);
            }

            location.href = href;
        },

        fixDefaultFlvSize() {
            if (this.model.pst == 1) {
                return;
            }

            var id = this.id;

            if ($('#new_direction_video_' + id).length) {
                if (!this.controller) return;
                var H = this.controller.videoHeight();
                var W = this.controller.videoWidth(); // Fai.top.videojs('#new_direction_video_' + id).on('canplay', function () {

                $('.online_flv_' + id).css('padding-bottom', (H / W) * 100 + '%'); // });
            }
        },

        initVideo() {
            var videoPlayer = $('#' + this.flvId).get(0);

            if (typeof videoPlayer != 'undefined') {
                this.refreshFlvModule();
            }
        },

        disposeVideo() {
            var videoPlayer = $('#' + this.flvId).get(0);

            if (typeof videoPlayer != 'undefined') {
                this.controller && this.controller.dispose && this.controller.dispose();
            }
        },

        getComputedTextColor(color) {
            var textColorStyle = {};

            if (color) {
                if (color.indexOf('linear-gradient') > -1 && !this.module.isIe) {
                    textColorStyle.backgroundImage = color;
                    textColorStyle.color = 'transparent';
                } else {
                    textColorStyle.backgroundImage = '';
                    textColorStyle.color = color;
                }
            }

            return textColorStyle;
        },
    },
};
