export const getPhotoGroupAnimationA = function (id, order) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        j = 0,
        lenInRow = 4,
        $dom;

    $dom = $module.find('.photo_display_area li');
    if ($dom.length < 0) {
        return;
    }
    if (order == 1) {
        // 同时
        let tmpArr = [];
        lenInRow = parseInt($module.find('.module_list_photos_content').attr('leninrow') || lenInRow);
        $.each($dom, function (index, val) {
            tmpArr.push($(val)[0]);
            if ((index != 0 && (index + 1) % lenInRow == 0) || index == $dom.length - 1) {
                // 每行作为一个整体push进domSeqList
                !!tmpArr.length && (domSeqList[j++] = tmpArr);
                tmpArr = [];
            }
        });
    } else {
        // 依次
        $dom.each(function () {
            domSeqList[j++] = Array.from($(this));
        });
    }

    return domSeqList;
};

export const getPhotoGroupAnimationB = function (id) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        $dom;

    $dom = $module.find('.photo_display_area li');
    if ($dom.length > 0) {
        $dom.each(function () {
            domSeqList.push(Array.from($(this)));
        });
    }

    return domSeqList;
};
