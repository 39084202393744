//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { showTopManageFrame } from '@/manage/shared/managementSystem/index.js';
export default {
    props: ['price', 'payTxt', 'supportMultiple', 'onlySupportWxPay', 'onlySupportAliPay', 'showPayAlert', 'formId'],
    methods: {
        goPaySetting() {
            showTopManageFrame('manageFrameSitePaySettingV2');
        },
    },
};
