//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { encodeHtmlStr } from '@/components/utils.js';
import { manageComponent } from './mixin';

export default {
    name: 'TimelineItemDesc',
    mixins: [manageComponent],
    inject: ['module'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        htmlValue() {
            return encodeHtmlStr(this.value).replace(/\n/g, '<br>');
        },
    },
};
