//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable vue/no-mutating-props */
import { Comm } from '../../utils';
const { bindFlag, isNewModuleAfter20210707 } = Comm;
import { manageNewsGroup } from '@shared/manage/newsGroup/index.js';
import newsList from './list.vue';
import { animationMixin } from '../animationMixin';
import { paginationMixin } from '../paginationMixin';
import { getNewsListByPagination } from '@api/news/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import { selectNewsListItem, addNews, saveNewsListToModule } from '@shared/manage/newsList/index.js';
import { getComputedBg } from '@/shared/manage/utils';
let manageUse = {
    components: {},
};
export default {
    name: 'NewsList',
    components: {
        newsList,
        PaginationComponent,
        ...manageUse.components,
    },
    mixins: [animationMixin, paginationMixin],
    style: 17,
    props: ['id', 'module'],
    data() {
        const data = {
            currentPage: 1,
            sortKeys: ['default', 'title', 'date', 'views'],
            sortTypes: ['desc', 'asc'],
            getLoading: false,
        };

        return data;
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        ...Vuex.mapState('manage/material', ['materialFactory']),
        newsList: {
            get() {
                return this.module.newsList || [];
            },
            set(value) {
                this.$set(this.module, 'newsList', value);
            },
        },
        activeButtonText() {
            return this.flagSelectType ? '选择分类' : '添加文章';
        },
        buttonText() {
            return this.flagSelectType ? '管理分类' : '选择文章';
        },
        isMobi() {
            return this.device == 'mobi';
        },
        flagIsBlank: bindFlag(0x1),
        flagSelectType: bindFlag(0x10),
        // 是否需要分页
        flagPagination: bindFlag(0x20),
        showPagination() {
            return this.flagPagination;
        },
        paginationPageSize() {
            return this.module.prop2;
        },
        paginationStyleIndex() {
            return this.module.prop4;
        },
        pcPrivatePattern() {
            return this.module.privatePattern['pc'];
        },
        paginationPageCount() {
            const totalSize = this.module.totalSize;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },
        modulePcPattern() {
            return this.module?.pattern?.pc ?? {};
        },
        moduleMobiPattern() {
            return this.module?.pattern?.mobi ?? {};
        },
        modulePcBg() {
            return this.modulePcPattern.bg || {};
        },
        moduleMobiBg() {
            return this.moduleMobiPattern.bg || {};
        },
        mobiDefaultBg() {
            return this.module.privatePattern.mobi?.dbg || {};
        },
        pcDefaultBg() {
            return this.module.privatePattern.pc?.dbg || {};
        },
        moduleBg() {
            return this.isMobi ? this.moduleMobiBg : this.modulePcBg;
        },
        classes() {
            const classes = ['module_news_list_style' + this.module.prop0];
            if (this.isNewModuleAfter20210707) {
                classes.push('new_list_new_module_after_20210707');
            }
            return classes;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        styles() {
            const styles = {};
            if (this.isMobi) {
                // 手机默认，根据pc视图
                if (this.moduleMobiBg.y === 0 && this.modulePcBg.y === 2) {
                    styles.background = 'none';
                }
                if (this.moduleMobiBg.y === 2) {
                    styles.background = 'none';
                }
            } else {
                if (this.modulePcBg.y === 2) {
                    styles.background = 'none';
                }
            }
            return styles;
        },
        newsInRow() {
            let len = 4; // 默认4个
            if (this.pcPrivatePattern.pplnt == 1) {
                len = this.pcPrivatePattern.ppln;
            }
            return len;
        },
        newsIdList() {
            return this.module.prop3;
        },
        newsGroupIdList() {
            return this.module.prop1;
        },
        sortKey() {
            try {
                return this.sortKeys[this.module.blob0.sortKey || 0];
            } catch (error) {
                return this.sortKeys[0];
            }
        },
        sortType() {
            try {
                return this.sortTypes[this.module.blob0.sortType || 0];
            } catch (error) {
                return this.sortTypes[0];
            }
        },
        allNewsVisible() {
            return this.module.blob0.av === 1;
        },
        visibleNewsCount() {
            return this.module.prop6;
        },
        filterNewsList() {
            const originNewsList = this.newsList;
            const nowTime = new Date().getTime();
            return originNewsList.filter(
                (value) => new Date(value.date).getTime() <= nowTime || !value.publishRegularly
            );
        },
        newsWrapStyle() {
            if (this.module.prop0 !== 1 && this.device === 'mobi') {
                // 非样式2
                if (this.mobiDefaultBg.y === 0) {
                    return this.computedBg(this.pcDefaultBg);
                } else {
                    return this.computedBg(this.mobiDefaultBg);
                }
            }
            return {};
        },
        isCarouselStyle() {
            return this.module.prop0 == 4;
        },
        moduleStyle() {
            return this.module.prop0;
        },
        isRecommendNewsList() {
            return this.module.id === 14;
        }
    },
    watch: {
        paginationStyleIndex() {
            this.resetPaginationList();
        },
        flagIsBlank() {
            this.flagPagination && this.resetPaginationList();
        },
    },
    mounted() {
        if (this.manageMode) {
            this.$set(this.module, 'forceUpdateNewsList', false);
            this.$watch(
                () => {
                    return JSON.stringify([
                        this.newsGroupIdList,
                        this.newsIdList,
                        this.flagSelectType,
                        this.module.blob0.sortKey,
                        this.module.blob0.sortType,
                        this.paginationPageSize,
                        this.showPagination,
                        this.module.forceUpdateNewsList,
                        this.module.blob0.av,
                        this.module.prop6,
                    ]);
                },
                debounce(() => {
                    this.getNewsList(1);
                    this.resetPaginationList();
                }, 200)
            );
        }
    },
    methods: {
        handlePaginationChanged(page) {
            this.getNewsList(page).then(() => {
                if (this.paginationStyleIndex !== 2) {
                    this.backToModuleTop();
                }
            });
        },
        handlePaginationPageChange(page) {
            this.getNewsList(page);
        },
        computedBg(data = {}) {
            if (data.y === 0) {
                // 默认
                return {};
            }
            return getComputedBg(data);
        },
        forceUpdateNewsListData() {
            this.module.forceUpdateNewsList = !this.module.forceUpdateNewsList;
        },
        stopWatchDataInManageMode() {
            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
        },
        resetPaginationList() {
            this.currentPage = 1;
            this.removeHistoryCurrentPage();
        },
        getNewsList(page) {
            if (this.getLoading) return;
            this.getLoading = true;
            let paginationPageSize = this.paginationPageSize;
            if (!this.showPagination) {
                page = 1;
                paginationPageSize = this.module.blob0.av === 1 ? 300 : this.module.prop6;
            }
            if (this.isCarouselStyle) {
                page = 1;
                paginationPageSize = 8;
            }
            return this.getNewsListByPagination(page, paginationPageSize)
                .then((res) => {
                    this.getLoading = false;
                    if (res.success) {
                        this.stopWatchDataInManageMode();
                        const newList = res.list;
                        if (this.paginationStyleIndex == 2) {
                            if (page == 1) {
                                this.newsList = newList;
                            } else {
                                // 如果是加载更多样式，则需要插入数组
                                let currentNewsIdList = this.newsList.map((item) => item.id);
                                this.newsList = this.newsList.concat(
                                    newList.filter((item) => {
                                        return !currentNewsIdList.includes(item.id);
                                    })
                                );
                            }
                        } else {
                            this.newsList = newList;
                        }
                        this.module.totalSize = res.total;
                    } else {
                        console.error(res.msg);
                    }
                })
                .catch(() => {
                    this.getLoading = false;
                });
        },
        getNewsListByPagination(page = 1, pageSize = 300) {
            const data = {};
            if (this.flagSelectType) {
                data.useGroup = true;
                data.isFromNewsList = true;
                data.groupIdList = $.toJSON(this.newsGroupIdList);
            } else {
                data.idList = $.toJSON(this.newsIdList);
            }
            data.sortKey = this.module.blob0.sortKey;
            data.sortType = this.module.blob0.sortType;

            if (this.isRecommendNewsList) {
                if (this.module.seed != 0) {
                    data.seed = this.module.seed;
                }
                data.newsSelectType = this.module.newsSelectType;
                data.nwId = this.module.newsId;
            }

            return new Promise((resolve, reject) => {
                getNewsListByPagination({
                    page,
                    pageSize,
                    fromMid: this.module.id,
                    ...data,
                })
                    .then((data) => (data.success ? resolve(data) : reject(data)))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        clickActiveButtonHandler() {
            this.flagSelectType ? this.clickSelectNewsGroupHandler() : this.clickAddNewsHandler();
        },
        clickButtonHandler() {
            this.flagSelectType ? this.clickManageNewsGroupHandler() : this.clickSelectNewsHandler();
        },
        clickAddNewsHandler() {
            addNews().then((newNewsList = []) => {
                const newsIdList = this.module.prop3.concat(newNewsList.map(({ id }) => id));
                saveNewsListToModule(this.module.id, newsIdList);
            });
        },
        clickSelectNewsHandler() {
            selectNewsListItem(this.id);
        },
        clickSelectNewsGroupHandler() {
            this.manageMode && editModule(this.id);
        },
        clickManageNewsGroupHandler() {
            manageNewsGroup({
                moduleId: this.module.id,
            });
        },
    },
};
