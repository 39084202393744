var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item_type_desc" }, [
    _c("h4", { staticClass: "primary_title", style: _vm.titleTextStyle }, [
      _vm._v(_vm._s(_vm.item.headline)),
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "description" }, [_vm._v(_vm._s(_vm.item.input))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }