//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timelineMixin } from './mixin';
import { LAYOUT, STYLE_THREE_SLIDE_ITEM_LENGTH, STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH } from './constants';
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { mapState, mapGetters } from 'vuex';
import { browser } from '@jz/utils';

export default {
    name: 'TimelineCarousel',
    components: { ImageMainViewer },
    mixins: [timelineMixin],
    data() {
        return {
            scrollIndex: 0,
            slideTranslatePercent: 0,
            previewSrcList: [],
            showMobiViewer: false,
            showPcViewer: false,
            imageIndex: 0,
            swiperInstance: null,
            isCompatGap: false,
        };
    },
    computed: {
        ...mapGetters(['isMobi']),
        ...mapState(['device', 'manageMode']),
        useSwiper() {
            return !this.manageMode && this.isMobi;
        },
        swiperSlideClass() {
            if (!this.useSwiper) {
                return [];
            }
            return ['swiper-slide'];
        },
        swiperWrapClass() {
            if (!this.useSwiper) {
                return [];
            }
            return ['swiper-wrapper'];
        },
        swiperContainerClass() {
            if (!this.useSwiper) {
                return [];
            }
            return ['swiper', `timelineCarouselSwiper${this.module.id}`];
        },
        pattern() {
            return this.module.privatePattern;
        },
        pcPattern() {
            return this.pattern.pc;
        },

        slideItemLengthOnePage() {
            return this.isMobi ? STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH : STYLE_THREE_SLIDE_ITEM_LENGTH;
        },
        timelineItemsLength() {
            return this.timelineItems.length;
        },
        slideTranslateStyle() {
            if (this.isMobi) {
                return;
            }
            return {
                transform: `translateX(-${this.slideTranslatePercent}%)`,
            };
        },
        scrollStyle() {
            const s = {};
            if (this.scrollIndex > 0 && !this.useSwiper) {
                s.transform = `translateX(-${this.scrollIndex * 100}%)`;
            }
            return s;
        },
        prevDisabled() {
            return this.scrollIndex <= 0;
        },
        nextDisabled() {
            return this.scrollIndex >= this.timelineItemsLength - 1;
        },
        moduleLayout() {
            return this.module.prop2;
        },
        isColumnStyle() {
            if (this.isMobi) {
                return true;
            }
            return this.moduleLayout === LAYOUT.TWO;
        },
    },
    watch: {
        isMobi() {
            this.resetData();
            this.resetSwiper();
            this.setCompatClass();
        },
        timelineItemsLength() {
            this.resetData();
        },
        scrollIndex() {
            this.calcSlideTranslatePercent();
            this.calcSlideScrollLeft();
        },
    },
    mounted() {
        this.initSwiperInMobi();
        this.setCompatClass();
    },
    methods: {
        setCompatClass() {
            if (this.isMobi) {
                this.isCompatGap = false;
                return;
            }
            if (browser.is360Chrome() || browser.isSogou()) {
                this.isCompatGap = true;
            }
        },
        async resetSwiper() {
            if (this.useSwiper) {
                await this.$nextTick();
                this.initSwiperInMobi();
            } else {
                this.destroySwiper();
            }
        },
        destroySwiper() {
            this.swiperInstance?.destroy();
            if (this.fixSwiperWrapWidth) {
                window.removeEventListener('resize', this.fixSwiperWrapWidth);
            }
        },
        calcSlideScrollLeft() {
            if (!this.isMobi) {
                return;
            }
            const $item = $(`#module${this.module.id} .timeline_slide_item_time`).children().eq(this.scrollIndex);
            const $scrollWrap = $(`#module${this.module.id} .timeline_slide_scroll`);
            const scrollLeft = $scrollWrap.scrollLeft();
            const scrollWrapWidth = $scrollWrap.width();
            const visibleMax = scrollLeft + scrollWrapWidth;
            const itemScrollLeft = $item.position().left;
            const itemWidth = 100 / this.slideItemLengthOnePage;

            // 在左边
            if (itemScrollLeft < scrollLeft) {
                $scrollWrap.animate({ scrollLeft: ((itemWidth * this.scrollIndex) / 100) * scrollWrapWidth }, 300);
            }
            // 在右边
            if (itemScrollLeft + $item.width() > visibleMax) {
                $scrollWrap.animate(
                    {
                        scrollLeft:
                            ((itemWidth * (this.scrollIndex - this.slideItemLengthOnePage + 1)) / 100) *
                            scrollWrapWidth,
                    },
                    300
                );
            }
        },
        handleTimeClick(index) {
            if (this.useSwiper) {
                this.swiperInstance.slideTo(index);
            }
            this.scrollIndex = index;
        },
        async initSwiperInMobi() {
            if (this.manageMode || !this.isMobi) {
                return;
            }
            const [swiperModule] = await Promise.all([
                import(/* webpackChunkName: "swiperJs" */ 'swiper'),
                import(/* webpackChunkName: "swiperCss" */ 'swiper/css'),
            ]);
            this.swiperInstance = new swiperModule.Swiper(`.timelineCarouselSwiper${this.module.id}`, {});
            this.swiperInstance.on('slideChangeTransitionStart', ({ activeIndex }) => {
                this.scrollIndex = activeIndex;
            });
            this.fixSwiperWrapWidth = () => {
                $(`#module${this.module.id} .timeline_carousel`).css('width', '');
                if (!this.isMobi) {
                    return;
                }
                setTimeout(() => {
                    // 修复swiper分屏时，忽略父盒子宽度的小数点，导致其他屏的图片显示了出来
                    const swiperWrapEl = document.querySelector(`#module${this.module.id} .timeline_c_scroll`);
                    const swiperItemEl = document.querySelector(`#module${this.module.id} .timeline_c_item`);
                    const swiperWrapWidth = window.getComputedStyle(swiperWrapEl).width;
                    const swiperItemWidth = window.getComputedStyle(swiperItemEl).width;
                    if (swiperWrapWidth !== swiperItemWidth) {
                        $(`#module${this.module.id} .timeline_carousel`).css('width', swiperItemWidth);
                    }
                }, 500);
            };
            this.fixSwiperWrapWidth();
            window.addEventListener('resize', this.fixSwiperWrapWidth);
        },
        closeViewer() {
            this.showMobiViewer = false;
            this.showPcViewer = false;
        },
        handleImageClick(item) {
            const {
                link: { url },
                psrc,
            } = item.pic;
            if (url && url !== 'javascript:void(0)') {
                return;
            }
            this.previewSrcList = [{ src: psrc, description: '' }];
            if (this.isMobi) {
                this.showMobiViewer = true;
            } else {
                this.showPcViewer = true;
            }
        },
        resetData() {
            this.scrollIndex = 0;
            this.slideTranslatePercent = 0;
        },
        calcSlideTranslatePercent() {
            if (this.isMobi) {
                return;
            }
            const itemWidth = 100 / this.slideItemLengthOnePage;
            const showRangeLeft = Math.round(this.slideTranslatePercent / itemWidth);
            // 哪5个在可视区(showRangeLeft, showRangeLeft + 5]
            const whichIndex = this.scrollIndex + 1;
            if (whichIndex <= showRangeLeft || whichIndex > showRangeLeft + this.slideItemLengthOnePage) {
                if (whichIndex - showRangeLeft < this.slideItemLengthOnePage) {
                    // 左边超出
                    this.slideTranslatePercent -= itemWidth;
                } else {
                    // 右边超出
                    this.slideTranslatePercent += itemWidth;
                }
            }
        },
        onPrev() {
            if (this.prevDisabled) {
                return;
            }
            this.scrollIndex--;
        },
        onNext() {
            if (this.nextDisabled) {
                return;
            }
            this.scrollIndex++;
        },
    },
};
