//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { successMessage, warningMessage, createDialog } from '@/site/shared/dialog/index.js';

import { Message } from '@shared/manage/componMessage/index.js';
import MemberSignupCaptcha from './MemberSignupCaptcha.vue';
import { getMobileCode, addMember, loginMember, proceedAfterMail, sendMemberActiveMail } from '@/api/member/index.js';
import { context as globalContext } from '@jz/utils';
import { memberFileUpload } from '@/site/utils.js';

export default {
    name: 'ModuleMemberSignup',
    components: {
        MemberSignupCaptcha,
    },
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        id: Number,
    },
    data() {
        return {
            photoValidating: false,
            timeCount: 0,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'isDemo', 'LS']),
        displayList() {
            return this.data.displayList.filter((item) => {
                return this.isShownItem(item.fieldKey);
            });
        },
        noRemark() {
            return this.data.noRemark;
        },
        data() {
            return this.module.data || {};
        },
    },
    mounted() {
        this.initFileUpload();
    },
    methods: {
        initFileUpload() {
            this.displayList.forEach((item) => {
                const { pt } = item;
                if (pt !== 2) {
                    return;
                }
                const { fileSizeLimit, fileUpInfo, id } = item;
                memberFileUpload({
                    moduleId: this.module.id,
                    fileSizeLimit,
                    fileUpInfo,
                    propId: id,
                });
            });
        },
        authCodeClick() {
            if (this.manageMode) {
                Message.warning('您当前处理网站管理状态，可点击右上方“预览”查看效果。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                getSignMobileCode(this.id);
            }
        },
        protocalClick() {
            const signupProtocalText = this.$refs.signupProtocal.innerText;
            openProtocalPage(signupProtocalText.substring(1, signupProtocalText.length - 1));
        },
        signupClick() {
            if (this.manageMode) {
                Message.warning('您当前处理网站管理状态，可点击右上方“预览”查看效果。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                memberSignupSubmit({
                    moduleId: this.id,
                    signUpbyMobile: this.data.signUpbyMobile,
                    signUpbyMail: this.data.signUpbyMail,
                    skipUrl: this.data.skipUrl,
                });
            }
        },
        isShownItem(fieldKey) {
            if (this.data.signUpbyMobile) {
                if ('mobile' == fieldKey) {
                    return false;
                } else {
                    return true;
                }
            } else if (this.data.signUpbyMail) {
                if ('email' == fieldKey) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        getFileUploadTips(displayItem) {
            let fileUpInfoStr = displayItem.pc;
            if (!fileUpInfoStr) {
                return '';
            }
            let fileUpInfo = JSON.parse(fileUpInfoStr);
            return fileUpInfo.fup.tt || '';
        },
        getMustClass(displayItem) {
            return this.getRequired(displayItem)
                ? ['J_isCheckUAI', 'J_userAddItem', 'member_file_input']
                : ['J_userAddItem', 'member_file_input'];
        },
        getItemLimit(displayItem) {
            let fieldKey = displayItem.fieldKey;
            //11手机号码， 50 MemberDef.Limit.FIELD_MAXLEN
            return 'mobile' == fieldKey ? 11 : 50;
        },
        getRequired(displayItem) {
            return displayItem.flag & 0x2;
        },
        getPlaceholder(displayItem) {
            return displayItem.pp ? displayItem.pp : displayItem.name;
        },
        getFileUploadItemClass(displayItem) {
            return displayItem.pt == 2 ? 'm_member_signup_item-file' : '';
        },
    },
};

const LS = globalContext.LS || {};

/**
 * 获取短信验证码
 * @param {Number} moduleId 模块id
 */
function getSignMobileCode(moduleId) {
    const { valid, info } = _checkGetMobileCodeParam(moduleId);
    if (!valid) {
        return;
    }

    // 获取验证码按钮是否禁用
    const disabled = $('#module' + moduleId)
        .find('.J_memberSignupMsg')
        .attr('data-disabled');
    if (disabled == 'true') {
        return;
    }

    new Promise((resolve, reject) => {
        getMobileCode({
            mobile: info.mobile,
            mobileCt: info.mobileCt,
            validateCode: info.captcha,
        })
            .then((data) => {
                data.success ? resolve(data) : reject(data);
            })
            .catch(reject);
    })
        .then(() => {
            successMessage(LS.sendMobileCodeSuc, true);

            _getSignMobileCodeCountDown(moduleId);
        })
        .catch(({ rt, msg } = {}) => {
            if (msg) {
                warningMessage(msg, true);
                return;
            }

            switch (rt) {
                case -401:
                    warningMessage(LS.memberSignupRegisterCaptchaNotMatch, true);
                    _showSignupCaptcha(moduleId);
                    break;
                case -2:
                    warningMessage(LS.argsError, true);
                    break;
                case 2:
                    warningMessage(LS.memberDialogSendMobileCodeErr, true);
                    break;
                case -4:
                case 8:
                    warningMessage(LS.getMobileOneMin, true);
                    break;
                case -8:
                    warningMessage(LS.getMobileHalfHour, true);
                    break;
                case 3:
                    warningMessage(LS.memberDialogMobileMoneyErr, true);
                    break;
                case 9:
                    warningMessage(LS.memberDialogSendMobileCodeLimit, true);
                    break;
                case 101:
                    warningMessage(LS.mobileSetErr, true);
                    break;
                case -6:
                    warningMessage(LS.mobileHasSigned, true);
                    break;
                case 23:
                    warningMessage(LS.mobileNationTplErr, true);
                    break;
                case -301:
                    warningMessage(LS.memberDialogMobileMoneyErr, true);
                    break;
                default:
                    warningMessage(LS.getMobileRefresh, true);
            }
        });
}

/**
 * 获取手机验证码的倒计时
 * @param {Number} moduleId 模块id
 */
function _getSignMobileCodeCountDown(moduleId) {
    const $memberSignupMsg = $('#module' + moduleId).find('.J_memberSignupMsg');
    let cTime = $memberSignupMsg.attr('data-c-time') || 0;
    let downTime = cTime > 0 ? 1800 : 60;
    cTime++;
    $memberSignupMsg.attr('data-c-time', cTime);
    $memberSignupMsg.attr('data-disabled', 'true');

    const timer = setInterval(() => {
        downTime--;
        $memberSignupMsg.html(LS.reGetMsg + downTime);
        if (downTime < 1) {
            $memberSignupMsg.attr('data-disabled', 'false');
            $memberSignupMsg.html($memberSignupMsg.attr('data-text'));
            clearInterval(timer);
        }
    }, 1000);
}

/**
 * 刷新验证码图片
 * @param {Number}moduleId 模块id
 */
function changeCaptchaImg(moduleId) {
    $('#module' + moduleId)
        .find('.J_memberSignupCaptchaImg')
        .attr('src', `/validateCode.jsp?${parseInt(Math.random() * 1000)}`);
}

/**
 * 检验手机参数
 * @param {Number} moduleId 模块id
 * @returns {Object} bar
 * @returns {Boolean} bar.valid 是否通过检验
 * @returns {Object} bar.info 获取短信验证码需要的数据
 */
function _checkGetMobileCodeParam(moduleId) {
    const result = {
        valid: false,
        info: {},
    };

    const $module = $('#module' + moduleId);
    const mobile = $module.find('#mobile').val();
    if (!Fai.isNationMobile(mobile)) {
        warningMessage(LS.mobileNumRegular, true);
        return result;
    }

    const showCaptcha = $module.find('.J_memberSignupCaptcha').is(':visible');
    const captcha = showCaptcha ? $module.find('#memberSignupCaptcha').val() : '';
    if (showCaptcha && !captcha) {
        warningMessage(LS.memberInputCaptcha, true);
        return result;
    }
    const mobileCt = $module.find('#mobileCt').val();

    result.valid = true;
    result.info.mobile = mobile;
    result.info.mobileCt = mobileCt;
    result.info.captcha = captcha;
    return result;
}

/**
 * 打开会员注册协议页面，若屏幕宽度大于768px，用弹窗形式打开；否则跳转到该页面
 */
function openProtocalPage(title) {
    const frameSrcUrl = 'rmanage/mProtocol.jsp';

    if ($(window).width() > 768) {
        title = Fai.encodeHtml(title);
        Fai.popupWindow({
            title,
            width: 600,
            height: 550,
            frameScrolling: false,
            frameSrcUrl,
            popUpWinZIndex: 9999,
            left: $(window).scrollLeft() + $(window).width() / 2 - 600 / 2,
            top: window._store.state.manageMode
                ? $(window).height() / 2 - 550 / 2
                : $(window).scrollTop() + $(window).height() / 2 - 550 / 2,
            version: 2,
        });
    } else {
        window.location.href = frameSrcUrl + '?_mobi=true'; // _mobi标志为true，通知该页面显示顶部
    }
}

/**
 * 执行会员注册请求操作
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {Boolean} foo.signUpbyMobile 是否通过手机号注册
 * @param {Boolean} foo.signUpbyMail 是否通过邮箱注册
 */
function memberSignupSubmit({ moduleId, signUpbyMobile, signUpbyMail, skipUrl }) {
    // 进行检测
    const { checkResult, info, captcha } = _checkSignupValid({
        moduleId,
        signUpbyMobile,
        signUpbyMail,
    });
    if (!checkResult) {
        return;
    }

    // // 新验证码组件
    // window.initFaitest({
    // 	captcha_server: 'http://cv.aaa.cn',
    // 	monitor_server: '//www.fff.com',
    // 	force_fallback: true,
    // 	bss: 1,
    // 	bssKey: 'xxx'
    // }, (checkSign, fallbacked) => {
    // 	_sendSignupRequest({moduleId, info, captcha, checkSign, fallbacked});
    // });

    _sendSignupRequest({ info, captcha, skipUrl });
}

// function _sendSignupRequest({moduleId, info, captcha, checkSign, fallbacked}) {
function _sendSignupRequest({ moduleId, info, captcha, skipUrl }) {
    // 禁用登录按钮
    $('#memberSignupButton').prop('disabled', true);

    // 发起注册请求
    new Promise((resolve, reject) => {
        addMember({
            info: $.toJSON(info),
            validateCode: captcha,
        })
            .then((data) => {
                data.success ? resolve(data) : reject(data);
            })
            .catch((e) => {
                reject(e);
            });
    })
        .then((data = {}) => {
            JZ.MemberFileUtil.saveMemberFile();
            // 启用登录按钮
            $('#memberSignupButton').prop('disabled', false);

            if (data.active) {
                _showSignupActiveEmailMsg({
                    emailName: info.email,
                    acctName: data.acct,
                });
            } else {
                if (data.rt == 0) {
                    window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=-303';
                } else {
                    return {
                        acct: data.acct,
                        pwd: info.pwd,
                    };
                }
            }
        })
        .then(({ acct = '', pwd = '' }) => {
            _sendMemberLoginRequest({ acct, pwd }, skipUrl);
        })
        .catch((data = {}) => {
            if ($.isEmptyObject(data)) {
                return;
            }
            // 启用登录按钮
            $('#memberSignupButton').prop('disabled', false);

            _showSignupErrorMsg({ data, info, moduleId });
        });
}

/**
 * 发送会员登录请求
 * @param {Object} foo
 * @param {String} foo.acct 账号名
 * @param {String} foo.pwd 密码
 */
function _sendMemberLoginRequest({ acct = '', pwd = '' }, skipUrl) {
    return new Promise(() => {
        loginMember({
            acct: acct,
            pwd: pwd,
        })
            .then(async (data) => {
                if (data.success) {
                    await bizShared?.clueReuqestVm?.authRecordLoginOther();
                    if (skipUrl) {
                        window.location.href = skipUrl;
                    } else {
                        const returnUrl = _getReturnUrl();
                        window.location.href =
                            returnUrl || Site.preprocessUrl({ path: '', oldPath: '' }) + 'mCenter.jsp';
                    }
                } else {
                    window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp';
                }
            })
            .catch(() => {
                window.location.href =
                    Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=-1&acct=' + Fai.encodeUrl(acct);
            });
    });
}

/**
 * 失败信息弹窗
 * @param {Object} foo
 * @param {Object} foo.data 后台返回的数据
 * @param {Object} foo.info 注册需要的数据
 * @param {Number} foo.moduleId 模块id
 */
function _showSignupErrorMsg({ data = {}, info = {}, moduleId = 0 }) {
    switch (data.rt) {
        case 0:
            warningMessage(data.msg || LS.memberSignupRegisterError, true);
            break;
        case -4:
            warningMessage(`${LS.memberSignupRegisterLimit}。`, true);
            break;
        case -6:
            warningMessage(`${LS.memberAcctExist}。`, true);
            break;
        case -8:
            warningMessage(`${LS.mobileHasSigned}。`, true);
            break;
        case -10:
            warningMessage(`${LS.memberAcctContainsIllegalChar}。`, true);
            break;
        case -20:
            warningMessage(data.msg, true);
            break;
        case -28:
            warningMessage(`${LS.memberRegisterLimit}。`, true);
            break;
        case -401:
            warningMessage(`${LS.memberSignupRegisterCaptchaNotMatch}。`, true);
            break;
        case -601:
            warningMessage(`${LS.reGetMobileCode}。`, true);
            break;
        case -1001:
            _showSignupBindingMsg({
                name: info.email,
                acctName: data.acct,
                info,
                moduleId,
            });
            break;
        case -1002:
            _showSignupBindingMsg({
                name: info.mobile,
                acctName: data.acct,
                info,
                moduleId,
            });
            break;
        default:
            warningMessage(data.msg || LS.memberSignupRegisterError, true);
            break;
    }

    if (data.needCode) {
        _showSignupCaptcha(moduleId);
    }
}

/**
 * 显示激活码输入框
 * @param {Number} moduleId 模块id
 */
function _showSignupCaptcha(moduleId) {
    $('#module' + moduleId)
        .find('.J_memberSignupCaptcha')
        .show();
}

/**
 * 已绑定账号弹窗
 * @param {Object} foo
 * @param {String} foo.name 注册时填写的邮箱/手机
 * @param {String} foo.acctName 账号名
 * @param {Object} foo.info 注册需要的数据
 * @param {Number} foo.moduleId 模块id
 */
function _showSignupBindingMsg({ name = '', acctName = '', info = {}, moduleId = 0 }) {
    createDialog({
        content: Fai.format(LS.boundAccount, name),
        confirmButtonText: LS.memberDialogLoginInstantly,
        cancelButtonText: LS.reReg,
        onConfirm() {
            window.location.href =
                Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?acct=' + Fai.encodeUrl(acctName);
        },
        onCancel() {
            _showSignupReregisterMsg({ name, acctName, info, moduleId });
        },
        showClose: true,
    });
}

/**
 * 重新注册弹窗
 * @param {Object} foo
 * @param {String} foo.name 注册时填写的邮箱/手机
 * @param {String} foo.acctName 账号名
 * @param {Object} foo.info 注册需要的数据
 * @param {Number} foo.moduleId 模块id
 */
function _showSignupReregisterMsg({ name = '', acctName = '', info = {}, moduleId = 0 }) {
    createDialog({
        content: Fai.format(LS.unbindAccount, name),
        confirmButtonText: LS.memberSignupConfirm,
        cancelButtonText: LS.cancel,
        onConfirm() {
            new Promise((resolve, reject) => {
                proceedAfterMail({
                    info: $.toJSON(info),
                })
                    .then((data) => {
                        data.success ? resolve(data) : reject(data);
                    })
                    .catch(() => {
                        warningMessage(LS.memberSignupRegisterError, true);
                    });
            })
                .then((data) => {
                    if (data.active) {
                        _showSignupActiveEmailMsg({
                            emailName: info.email,
                            acctName,
                        });
                    } else {
                        if (data.rt == 0) {
                            window.location.href =
                                Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=-303';
                        } else {
                            _sendMemberLoginRequest({
                                acct: acctName,
                                pwd: info.pwd,
                            });
                        }
                    }
                })
                .catch((data) => {
                    changeCaptchaImg(moduleId);

                    // 清空激活码输入框
                    $('#memberSignupCaptcha').val('');

                    _showSignupErrorMsg({ data, info, moduleId });
                });
        },
        showClose: true,
    });
}

/**
 * 激活邮箱弹窗
 * @param {Object} foo
 * @param {String} foo.acctName 账号名
 * @param {String} foo.emailName 邮箱名
 */
function _showSignupActiveEmailMsg({ emailName = '', acctName = '' }) {
    // 注册完执行一次发送激活邮件
    _sendSignupActiveEmailRequest({ emailName, acctName });

    const _jumpToLoginPage = () => {
        // 跳转到登录页
        setTimeout(() => {
            window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=12';
        }, 3000);
    };

    createDialog({
        content: Fai.format(LS.memberSignupRegisterSuccess, emailName),
        confirmButtonText: LS.memberSignupConfirm,
        cancelButtonText: LS.memberSignupResend,
        onConfirm() {
            _jumpToLoginPage();
        },
        onCancel() {
            // 重新发送激活邮件
            _sendSignupActiveEmailRequest({ emailName, acctName })
                .then(() => {
                    successMessage(LS.memberDialogOKorErrorMsg);
                    _jumpToLoginPage();
                })
                .catch((data = {}) => {
                    if (data.AliaNotF) {
                        warningMessage(LS.memberDialogAliasNotFound, true);
                        _jumpToLoginPage();
                    }
                });
        },
        showClose: true,
    });
}

/**
 * 发送激活邮件请求
 * @param {Object} foo
 * @param {String} foo.acctName 账号名
 * @param {String} foo.emailName 邮箱名
 * @returns {Promise}
 */
function _sendSignupActiveEmailRequest({ acctName = '', emailName = '' }) {
    return new Promise((resolve, reject) => {
        sendMemberActiveMail({
            memName: acctName,
            memEmail: emailName,
        })
            .then((data) => {
                data.success ? resolve(data) : reject(data);
            })
            .catch(() => {
                warningMessage(LS.memberSignupRegisterError, true);
            });
    });
}

/**
 * 检测注册的输入项是否合法
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {Boolean} foo.signUpbyMobile 是否通过手机号注册
 * @param {Boolean} foo.signUpbyMail 是否通过邮箱注册
 * @returns {Object} bar
 * 			{Boolean} bar.checkResult 是否通过检测
 * 			{String} bar.captcha 验证码数据
 * 			{Object} bar.info 注册需要的数据
 */
function _checkSignupValid({ signUpbyMobile, signUpbyMail }) {
    const resultData = {
        checkResult: true,
        captcha: '',
        info: {},
    };

    let acct = '';
    if (!signUpbyMobile && !signUpbyMail) {
        acct = $.trim($('#memberSignupAcct').val());
        if (acct == null || acct == '') {
            warningMessage(LS.memberSignupRegisterAcctEmpty, true);
            resultData.checkResult = false;
            return resultData;
        }
    } else if (signUpbyMail) {
        const item = '#email';
        const userAddItemID = $(item).attr('id');
        const userAddItemValue = $(item).val();
        const userAddItemName = $(item).attr('name');
        const userAddItemMaxLength = $(item).attr('maxlength');

        resultData.info[userAddItemID] = userAddItemValue;
        if (userAddItemValue.length > userAddItemMaxLength) {
            warningMessage(Fai.format(LS.msgBoardInputMaxLength, userAddItemName, userAddItemMaxLength), true);
            isUserAddItemNameTooLong = 1;
            resultData.checkResult = false;
            return resultData;
        }
        if (userAddItemID == 'email') {
            if (userAddItemValue.length < 1) {
                warningMessage(Fai.format(LS.msgBoardInputIsEmpty, userAddItemName), true);
                resultData.checkResult = false;
                return resultData;
            } else if (!Fai.isEmail(userAddItemValue)) {
                warningMessage(Fai.format(LS.msgBoardInputCorrect, userAddItemName), true);
                resultData.checkResult = false;
                return resultData;
            }
        }
    } else if (signUpbyMobile) {
        const item = '#mobile';
        const userAddItemID = $(item).attr('id');
        const userAddItemValue = $(item).val();
        const userAddItemName = $(item).attr('name');
        const userAddItemMaxLength = $(item).attr('maxlength');

        resultData.info[userAddItemID] = userAddItemValue;
        if (userAddItemValue.length > userAddItemMaxLength) {
            warningMessage(Fai.format(LS.msgBoardInputMaxLength, userAddItemName, userAddItemMaxLength), true);
            isUserAddItemNameTooLong = 1;
            resultData.checkResult = false;
            return resultData;
        }
        if (userAddItemID == 'mobile') {
            if (userAddItemValue.length < 1) {
                warningMessage(Fai.format(LS.msgBoardInputIsEmpty, userAddItemName), true);
                isUserAddItemPass = 1;
                resultData.checkResult = false;
                return resultData;
            } else if (!Fai.isNationMobile(userAddItemValue)) {
                warningMessage(Fai.format(LS.msgBoardInputCorrect, userAddItemName), true);
                isUserAddItemPass = 1;
                resultData.checkResult = false;
                return resultData;
            } else {
                resultData.info['mobileCt'] = $('#mobileCt').val();
            }
        }
    }

    const pwd = $('#memberSignupPwd').val();
    if (pwd == null || pwd == '') {
        warningMessage(LS.memberSignupRegisterPwdEmpty, true);
        resultData.checkResult = false;
        return resultData;
    }
    if (pwd.length < 4) {
        warningMessage(LS.memberSignupRegisterPwdMinLength, true);
        resultData.checkResult = false;
        return resultData;
    }
    const repwd = $('#memberSignupRepwd').val();
    if (repwd == null || repwd == '') {
        warningMessage(LS.memberDialogPleaseEnterPwd2, true);
        resultData.checkResult = false;
        return resultData;
    }
    if (pwd != repwd) {
        warningMessage(LS.memberSignupRegisterPwdNotMatch, true);
        resultData.checkResult = false;
        return resultData;
    }
    let remark = '';
    if ($('#memberSignupRemark').length > 0) {
        remark = $('#memberSignupRemark').val();
        const remarkMaxLength = $('#memberSignupRemark').attr('maxlength');
        if (remark.length > remarkMaxLength) {
            warningMessage(Fai.format(LS.msgBoardInputMaxLength, LS.loginDialogComment, remarkMaxLength), true);
            resultData.checkResult = false;
            return resultData;
        }
    }

    resultData.info['acct'] = acct;
    resultData.info['pwd'] = $.md5(pwd);
    resultData.info['remark'] = remark;

    resultData.info['signUpbyMobile'] = signUpbyMobile;
    resultData.info['signUpbyMail'] = signUpbyMail;

    let isUserAddItemPass = 0;
    let isUserAddItemNameTooLong = 0;
    $('.J_userAddItem').each((index, item) => {
        const userAddItemID = $(item).attr('id');
        const userAddItemValue = $(item).val();
        const userAddItemName = $(item).attr('name');
        const userAddItemMaxLength = $(item).attr('maxlength');

        resultData.info[userAddItemID] = userAddItemValue;
        if (userAddItemValue.length > userAddItemMaxLength) {
            warningMessage(Fai.format(LS.msgBoardInputMaxLength, userAddItemName, userAddItemMaxLength), true);
            isUserAddItemNameTooLong = 1;
            resultData.checkResult = false;
            return resultData;
        }
        if (!signUpbyMail && userAddItemID == 'email' && userAddItemValue.length > 0) {
            if (!Fai.isEmail(userAddItemValue)) {
                warningMessage(Fai.format(LS.msgBoardInputCorrect, userAddItemName), true);
                isUserAddItemPass = 1;
                resultData.checkResult = false;
                return resultData;
            }
        }

        if (!signUpbyMobile && userAddItemID == 'mobile' && userAddItemValue.length > 0) {
            if (!Fai.isNationMobile(userAddItemValue)) {
                warningMessage(Fai.format(LS.msgBoardInputCorrect, userAddItemName), true);
                isUserAddItemPass = 1;
                resultData.checkResult = false;
                return resultData;
            } else {
                resultData.info['mobileCt'] = $('#mobileCt').val();
            }
        }
    });

    if (isUserAddItemPass == 1) {
        resultData.checkResult = false;
        return resultData;
    }
    if (isUserAddItemNameTooLong == 1) {
        resultData.checkResult = false;
        return resultData;
    }

    let isCheckUAIStatus = 0;
    $('.J_isCheckUAI').each((index, item) => {
        const userAddItemValue = $(item).val();
        let userAddItemName = $(item).attr('name');
        if ($(item).parents('.m_member_signup_item-file').length) {
            userAddItemName = $(item).parents('.m_member_signup_item-file').find('.m_member_signup_text').attr('title');
        }
        if ($(item).hasClass('member_file_input')) {
            if (!userAddItemValue.length) {
                warningMessage(Fai.format(LS.site_memberSignupUserAddItemIsEmpty2, userAddItemName), true);
                isCheckUAIStatus = 1;
            }
            return false;
        }

        if (userAddItemValue == null || userAddItemValue == '') {
            if ($(item).is('input')) {
                warningMessage(Fai.format(LS.msgBoardInputIsEmpty, userAddItemName), true);
            } else {
                warningMessage(LS.memberSignupUserAddItemIsEmpty2 + userAddItemName, true);
            }
            isCheckUAIStatus = 1;
            return false;
        }
    });
    if (isCheckUAIStatus == 1) {
        resultData.checkResult = false;
        return resultData;
    }

    if ($('.J_memberSignupCaptcha').length && $('.J_memberSignupCaptcha').css('display') != 'none') {
        resultData.captcha = $('#memberSignupCaptcha').val();
        if (resultData.captcha == null || resultData.captcha == '' || typeof resultData.captcha == 'undefined') {
            warningMessage(LS.memberSignupRegisterCaptchaEmpty, true);
            resultData.checkResult = false;
            return resultData;
        }
    } else {
        if (resultData.captcha == null || resultData.captcha == '' || typeof resultData.captcha == 'undefined') {
            resultData.captcha = '';
        }
    }

    const memberAgreePro = $('#memberAgreePro');
    if (memberAgreePro.length > 0) {
        if (!memberAgreePro.prop('checked')) {
            warningMessage(LS.memberProtocolNotAgree, true);
            resultData.checkResult = false;
            return resultData;
        }
    }

    resultData.checkResult = true;
    return resultData;
}

/*
 * 拿到当前页面的url参数（登录成功后要跳转的页面链接）
 */
function _getReturnUrl() {
    return Fai.getUrlParam(window.location.href, 'url');
}
