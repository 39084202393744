//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getPictureScaleCss } from '../cssUtils';

export default {
    name: 'ProductResultCss',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        picSpaceMobiCss() {
            if (this.mobiPattern.pmt !== 1) {
                return '';
            }
            let n = 2;
            if (this.mobiPattern.rlt === 1) {
                n = this.mobiPattern.rl;
            } else {
                if (this.pcPattern.rlt === 1) {
                    n = this.pcPattern.rl;
                }
            }
            let mobiSplitLineCss = '';
            if (this.module.prop0 === 1) {
                n = 1;
                mobiSplitLineCss = `${this.mobiId} .jz_product_result_1 .result_show_product:after { bottom: -${
                    this.mobiPattern.pmv / 2
                }px; }`;
            }
            return `
                ${this.mobiId} .result_show_product {
                    width: calc(${100 / n}% - ${this.mobiPattern.pmh}px) !important;
                    margin: ${this.mobiPattern.pmv / 2}px ${this.mobiPattern.pmh / 2}px;
                }
                ${mobiSplitLineCss}
            `;
        },
        picScaleCss() {
            const getCss = (id, s) => {
                const scale = getPictureScaleCss(s);
                if (!scale) {
                    return '';
                }
                return `${id} .result_product_pic_item {${scale}}`;
            };
            // 新创建站点的模块就联动pc设置的图片比例
            const pcSelector = this.module.isNewPicSetting ? this.id : this.pcId;
            return `${getCss(pcSelector, this.pcPattern.psy)} ${getCss(this.mobiId, this.mobiPattern.psy)}`;
        },
        picSizeCss() {
            if (this.module.prop0 !== 1) {
                return '';
            }

            if (this.mobiPattern.pst === 0) {
                return '';
            }

            return `
                ${this.mobiId} .result_product_pic_wrap {
                    width: ${this.mobiPattern.psw * 100}%;
                    padding-bottom: ${this.mobiPattern.psh * 100}%;
                }
                ${this.mobiId} .jz_product_result_1 .result_prop {
                    width: ${100 - this.mobiPattern.psw * 100}%;
                }
            `;
        },
        picHeightMobiCss() {
            if (this.module.prop0 !== 0) {
                return '';
            }
            if (this.mobiPattern.pht === 0) {
                return '';
            }
            return `${this.mobiId} .result_product_pic_wrap { padding-bottom: ${
                this.mobiPattern.ph * 100
            }% !important; }`;
        },
    },
    methods: {},
};
