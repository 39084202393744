//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
export default {
    name: 'IconCombinationCss',
    components: {
        ChildrenCSS,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        moduleStyle() {
            return this.module.prop0;
        },
        moduleClassPrefix() {
            return `#module${this.moduleId}`;
        },
        pcPattern() {
            return this.module.privatePattern['pc'];
        },
        mobiPattern() {
            return this.module.privatePattern['mobi'];
        },
        contentPaddingCss() {
            let pcContentPadding = this.paddingContentClass(
                this.moduleStyle,
                this.pcPattern.ircs,
                this.pcPattern.irs,
                `.jz_screen_pc ${this.moduleClassPrefix}`
            );
            let mobiContentPadding = this.paddingContentClass(
                this.moduleStyle,
                this.mobiPattern.ircs,
                this.mobiPattern.irs,
                `.jz_screen_mobi ${this.moduleClassPrefix}`
            );
            return pcContentPadding + mobiContentPadding;
        },
    },
    methods: {
        paddingContentClass(iconStyle, ircs, irs, prefix) {
            let style = '';
            if (ircs == 1) {
                switch (iconStyle) {
                    case 1:
                        style = `${prefix} .icon_combination .first_style_item_content { padding: ${irs * 20}% }\n`;
                        break;
                    case 2:
                        style = `${prefix} .icon_combination .second_style_item_content { padding: ${irs * 10}% }\n`;
                        break;
                    case 3:
                        style = `${prefix} .icon_combination .third_style_item_content { padding: ${irs * 20}% }\n`;
                        break;
                }
            }
            return style;
        },
    },
};
