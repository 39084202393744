//
//
//
//
//
//

export default {
    name: 'newsListTopIcon',
    props: {
        iconType: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        topSvgClass() {
            return [`top_svg_${this.iconType}`];
        },
    },
};
