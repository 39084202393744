import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

/**
 * 创建图形验证码弹窗
 * @param {Function} sendValidateCode 发送验证码
 * @returns {Promise} 验证结果
 */
const createCaptchaPopup = (sendValidateCode) =>
    new Promise((resolve) => {
        const tpl = `
		<div class="c_captcha_popup" style="visibility:hidden;">
			<div class="c_captcha_popup--mask"></div>
			<div class="c_captcha_popup--content J_captchaPopupContent">
				<div class="c_captcha_popup--title">请填写图片验证码</div>
				<div class="c_captcha_popup--input_wrap">
					<input class="c_captcha_popup--input J_captchaPopupInput" maxlength="4">
					<img class="c_captcha_popup--validate_code J_captchaPopupValidateCode">
                    <div class="c_captcha_popup--msg J_captchaPopupMsg"></div>
				</div>
				<div class="c_captcha_popup--btns">
					<button class="c_captcha_popup--btn_confirm J_captchaPopupBtnConfirm">确 定</button>
				</div>
				<i class="c_captcha_popup--close J_captchaPopupClose"></i>
			</div>
		</div>
	`;
        const $el = $(tpl);
        const handleClose = () => {
            $el.fadeOut(() => $el.remove());
            $('html').css({ overflow: '' });
        };
        const handleShow = () => {
            $el.css('visibility', '');
        };
        const handleMsg = (msg) => {
            $el.find('.J_captchaPopupMsg').html(msg);
        };
        const handleResize = () => {
            const width = $el.find('.J_captchaPopupContent').outerWidth();
            const height = $el.find('.J_captchaPopupContent').outerHeight();
            const containerWidth = $el.width();
            const containerHeight = $el.height();
            const left = (containerWidth - width) / 2 + 'px';
            const top = (containerHeight - height) / 2 + 'px';

            $el.find('.J_captchaPopupContent').css({
                left,
                top,
            });
        };
        const handleInputError = (isError = true) => {
            if (isError) {
                $el.find('.J_captchaPopupInput').addClass('c_captcha_popup--input__error');
            } else {
                $el.find('.J_captchaPopupInput').removeClass('c_captcha_popup--input__error');
            }
        };
        const handleConfirm = async (val) => {
            const data = await sendValidateCode(val);
            if (data && data.success) {
                handleClose();
                resolve(data);
            } else {
                // eslint-disable-next-line no-undef
                handleMsg(data && data.msg ? data.msg : LS.js_argsError);
                handleInputError(true);
                handleResize();
            }
        };
        const handleRefresh = () => {
            $el.find('.J_captchaPopupValidateCode').attr(
                'src',
                '/validateCode.jsp?validateCodeRegType=3&random=' + Math.round(Math.random() * 1000)
            );
        };

        $el.find('.J_captchaPopupBtnConfirm')
            .off('click')
            .on('click', () => {
                const val = $el.find('.J_captchaPopupInput').val();
                if (val === '') {
                    // eslint-disable-next-line no-undef
                    handleMsg(LS.memberDialogEnterCode);
                    return;
                }
                handleConfirm(val);
            });
        $el.find('.J_captchaPopupClose')
            .off('click')
            .on('click', () => {
                handleClose();
                resolve();
            });
        $el.find('.J_captchaPopupValidateCode')
            .off('load')
            .on('load', () => {
                handleResize();
            });
        $el.find('.J_captchaPopupValidateCode')
            .off('click')
            .on('click', () => {
                handleRefresh();
            });
        $el.find('.J_captchaPopupInput')
            .off('input')
            .on('input', () => {
                const val = $el.find('.J_captchaPopupInput').val();
                handleInputError(!val || val.length != 4);
            });

        $el.appendTo(document.body);
        $('html').css({ overflow: 'hidden' });
        handleRefresh();
        handleResize();
        handleShow();
    });

export { createCaptchaPopup };
