var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cn_group__wrap", class: "module" + _vm.moduleId },
    [
      _c("div", { staticClass: "cn_group__header" }, [
        _c("span", { staticClass: "cn_group__title" }, [
          _vm._v(_vm._s(_vm.group.name)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cn_group__close", on: { click: _vm.close } }),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "cn_group__content" },
        [
          _c("li", { staticClass: "cn_group__item cn_group__all" }, [
            _c(
              "a",
              {
                staticClass: "cn_group__itemname",
                attrs: { href: _vm.group.href },
              },
              [_vm._v(_vm._s(_vm.LS.mobi_productGroupAll))]
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.group.children, function (item) {
            return _c("li", { key: item.id, staticClass: "cn_group__item" }, [
              _c(
                "a",
                {
                  staticClass: "cn_group__itemname",
                  class: _vm.getSelectClass(item),
                  attrs: { href: item.href },
                },
                [_vm._v(_vm._s(item.name))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "cn_group__sonwrap" },
                _vm._l(item.children, function (son) {
                  return _c(
                    "a",
                    {
                      key: son.id,
                      staticClass: "cn_group__son",
                      class: _vm.getSelectClass(son),
                      attrs: { href: son.href },
                    },
                    [_vm._v(_vm._s(son.name))]
                  )
                }),
                0
              ),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }