var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item_type_input" }, [
    _vm.requiredIcon
      ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      staticClass: "input_style jz_focus jz_theme_focus_border_color",
      style: _vm.itemInputStyle,
      attrs: {
        maxlength: _vm.initMaxLength(),
        placeholder: _vm.item.placeholder,
      },
      domProps: { value: _vm.value },
      on: { input: _vm.inputItemText },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }