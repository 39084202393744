//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//style 23 普通的留言板模块
import { Message } from '@shared/manage/componMessage/index.js';
import { msgBoard } from '@shared/visitor/msgBoard/index.js';
import ItemType_0 from './forms/itemType0.vue';
import ItemType_1 from './forms/itemType1.vue';
import ItemType_9 from '../common/forms/itemType9.vue';
import ItemType_8 from '../common/forms/itemType8.vue';
import ItemType_validateCode from './forms/itemTypeValidateCode.vue';
import FormContainer from './forms/formContainer.vue';
import FormItem from './forms/formItem.vue';
import MsgBoardComment from './MsgBoardComment.vue';
import { warningMessage, successMessage } from '@/site/shared/dialog/index.js';
import { addMsgBoardComment } from '@/api/msgBoard/index.js';
import { logDog } from '@/site/shared/log/index.js';
import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES } from '@/services';
import { topTips, realNameAuthTips } from '@/site/topTips.js';

export default {
    name: 'moduleMsgBoard',
    components: {
        FormContainer,
        FormItem,
        ItemType_0,
        ItemType_1,
        ItemType_8,
        ItemType_9,
        ItemType_validateCode,
        MsgBoardComment,
    },
    props: ['id', 'module'],
    data() {
        return {
            validateCode: '',
            needCode: false,
            validateCodeKey: 0,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'LS', 'manageMode', 'responsiveInfo', 'realNameAuth']),
        contentList() {
            return this.module.propList;
        },
    },
    watch: {
        'module.propList': function (value) {
            var idList = [];
            value.forEach(function (item) {
                idList.push(item.id.toString());
            });
            this.module.prop1 = idList;
        },
    },
    created() {},
    mounted() {
        this.controller = new msgBoard(this.id, this.module);
        this.initRealNameAuthTips();
    },
    methods: {
        getFormItem(type) {
            return `ItemType_${type}`;
        },
        inputItem(item, val) {
            if (this.manageMode) {
                //如果是管理态则不设置这个数据，否则可能会被存到设置项的数据中
                //如果是访客态的话每一个模块拿的都不是同一个引用的数据，所以可以作为一个临时的数据储存点
                return;
            }
            Vue.set(item, 'value', val);
        },
        async submitHanlder() {
            if (this.manageMode) {
                Message.warning('当前为管理状态，提交无效。');
                return;
            }
            if (window._store.state.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行提交。', true);
                return;
            }
            // 【7月3上线】免费版削弱
            if (window._store.state.isJuly3FreeUser) {
                logDog(200964, 5);
                warningMessage('免费版暂不支持此功能', true);
                return false;
            }

            if (this.realNameAuth.allowSubmitMessage === false) {
                return;
            }

            const mobileAuthPopupService = new MobileAuthPopupService(this.device, this.responsiveInfo.themeColor);
            const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(
                MOBILE_AUTH_MESSAGE_TYPES.MSG_BOARD
            );
            if (needAuth) {
                mobileAuthPopupService.createSubmitHandler(
                    () => {
                        this.submit(showAfterReview);
                    },
                    (error) => {
                        Fai.ing(error.msg);
                    }
                );
                mobileAuthPopupService.show();
            } else {
                this.submit(showAfterReview);
            }
        },
        submit(showAfterReview = false) {
            for (const formItem of this.contentList) {
                const { must, value, key = '', name, type } = formItem;
                if (must && !value) {
                    warningMessage(Fai.format(LS.msgBoardInputIsEmpty, Fai.encodeHtml(name)), false);
                    return;
                }

                if (key === 'reqEmail' && value && !Fai.isEmail(value)) {
                    warningMessage(Fai.format(LS.msgBoardInputCorrect, Fai.encodeHtml(name)), false);
                    return;
                }
                if (key === 'reqPhone' && value && !Fai.isPhone(value)) {
                    warningMessage(Fai.format(LS.msgBoardInputCorrect, Fai.encodeHtml(name)), false);
                    return;
                }

                const maxLength = type === 1 ? 1000 : 100;

                if (value.length > maxLength) {
                    warningMessage(Fai.format(LS.msgBoardInputMaxLength, Fai.encodeHtml(name), maxLength), false);
                }

                if (key === 'reqContent') {
                    if (!value) {
                        warningMessage(LS.msgBoardInputContent, false);
                        return;
                    }
                    const contentLimit = 10000;
                    if (value.length > contentLimit) {
                        warningMessage(Fai.format(LS.msgBoardInputContent2, contentLimit), false);
                        return;
                    }
                }
            }
            if ((this.module.showCaptcha || this.needCode) && !this.validateCode) {
                warningMessage(LS.msgBoardInputValidateCode, false);
                return;
            }
            successMessage(LS.msgBoardDoing, true);

            const msgData = {
                memberId: this.module.mId,
            };
            this.contentList.forEach(function (item) {
                msgData[item.key] = item.value.trim();
            });
            addMsgBoardComment({
                msgBdData: $.toJSON(msgData),
                validateCode: this.validateCode,
                moduleId: 9,
            })
                .then((result) => {
                    if (result.success) {
                        if (showAfterReview === false) {
                            successMessage(Fai.format(LS.msgBoardSendOkAutoOpen), true);
                        } else {
                            successMessage(Fai.format(LS.msgBoardSendOk), true);
                        }

                        this.contentList.forEach(function (item) {
                            if (item.disabled) {
                                return;
                            }
                            item.value = '';
                        });
                        this.validateCode = '';
                    } else {
                        if (result.isRealDemo) {
                            Fai.ing(result.msg);
                            return;
                        }
                        if (result.errno == 1) {
                            warningMessage(LS.js_captchaError);
                        } else if (result.errno == 2) {
                            warningMessage(LS.js_argsError);
                        } else if (result.errno == -4) {
                            warningMessage(LS.msgBoardAddCountLimit);
                        } else {
                            warningMessage(LS.js_systemError);
                        }

                        if (result.needCode) {
                            warningMessage(result.msg);
                            this.needCode = true;
                        }
                        if (result.hasFW) {
                            warningMessage(result.msg);
                        }
                    }
                    this.validateCodeKey++;
                    JZ.msgBoardInfo.getComments(false);
                })
                .catch((error) => {
                    console.error(error);
                    var options = {
                        htmlContent: '留言提交失败',
                        width: 205,
                        height: 78,
                    };
                    Site.popupBox(options);

                    setTimeout(function () {
                        Fai.top.location.reload();
                    }, 3000);
                });
        },
        initRealNameAuthTips() {
            if (this.manageMode === false && this.realNameAuth.allowShowMessage === false) {
                topTips.addTopTips(realNameAuthTips);
            }
        },
    },
};
