//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { componentProps } from './componentProps';
export default {
    props: componentProps,
    data() {
        return {};
    },
    methods: {
        languageItemClass(lcid) {
            let classes = [];
            if (lcid == this.currentLan.lcid) {
                classes.push('jz_theme_font_color', 's_multi_language_active_text');
            } else {
                classes.push('s_multi_language_normal_text');
            }
            return classes;
        },
    },
};
