import {
    setIs20210707NewModule,
    setCopyModuleAnchorName,
    setCopyModuleName,
} from '@shared/manage/moduleUtils/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { fullNavManage } from '@/manage/system/navManage/index.js';
import { getSvgName } from '@shared/manage/svgManage/index.js';
import { scrollToTarget } from '@shared/manage/scroll/index.js';
import {
    copyModuleTab,
    setTabsIdToDeleteModules,
    isTabpack,
    isInTabpack,
    getParentTabPackModule,
} from '@shared/manage/tabpackUtils/index.js';
import { getCurrentCarouselRow } from '@/manage/grid/row/effectRow/index.js';
import { store } from '@/store/store.js';

const getAjaxOption = (function () {
    let ajaxOptions = {
        // 产品详情
        9: {
            url: 'productModule_h.jsp?cmd=getWafNotCk_getProductLib',
            data: {},
            flag: true,
            isRajax: true,
        },
        // 产品筛选
        10: {
            url: 'productModule_h.jsp?cmd=getWafNotCk_getProductLib',
            data: {},
            flag: true,
            isRajax: true,
        },
        // 产品展示
        16: {
            url: 'productModule_h.jsp?cmd=getWafNotCk_getProductLib',
            data: {},
            flag: true,
            isRajax: true,
        },
        // 产品分类
        18: {
            url: 'productModule_h.jsp?cmd=getWafNotCk_getProductLib',
            data: {},
            flag: true,
            isRajax: true,
        },
    };
    return function (moduleId) {
        let moduleData = store.state.modules[`module${moduleId}`];
        let styleId = moduleData.style;
        let options = typeof ajaxOptions[styleId] != 'undefined' ? ajaxOptions[styleId] : {};
        return options;
    };
})();

export const getEditModuleData = function getEditModuleData(moduleId) {
    return new Promise((resolve, reject) => {
        let ajaxOption = getAjaxOption(moduleId);
        let moduleData = store.state.modules[`module${moduleId}`]; //return 掉不需要进行ajax请求的模块

        if (!Object.keys(ajaxOption).length) {
            resolve();
            return;
        } // 合并参数

        let data = {
            moduleId,
            ...ajaxOption.data,
        }; // 同个类型的模块只请求一次

        if (!ajaxOption.flag) {
            resolve();
            return;
        } // FIXME: $.ajax => jzRequest
        // 发送 ajax

        $.ajax({
            type: 'get',
            url: Site.genAjaxUrl(ajaxOption.url, ajaxOption.isRajax),
            data,

            success(result) {
                result = JSON.parse(result);

                if (!result.success) {
                    Message.warning('系统错误');
                    return;
                } // 对请求回来的数据，进行操作

                ajaxOption.func && ajaxOption.func(result.info);

                store.commit('manage/setModuleComInfo', {
                    moduleStyle: moduleData.style,
                    info: result.info,
                });

                ajaxOption.flag = false;
                resolve();
            },

            error() {
                reject();
            },
        });
    });
};

export const editModule = function (moduleId, type = 'module', innerType = '') {
    // 业务监控
    const endMonitor = window.Monitor.startMonitor(Site.MonitorDef.MonitorId.R_EDIT_MODULE);
    getEditModuleData(moduleId)
        .then(
            () => {
                store.commit('manage/editPanel/editPanelChange', {
                    id: moduleId,
                    type,
                    innerType,
                    active: true,
                });

                endMonitor();
            },
            (e) => {
                endMonitor(false, e);
            }
        )
        .catch((e) => {
            endMonitor(false, e);
        });
};

export const editMobiModule = function (moduleId) {
    // 业务监控
    const endMonitor = window.Monitor.startMonitor(Site.MonitorDef.MonitorId.R_EDIT_MODULE);

    try {
        store.commit('manage/editPanel/editPanelChange', {
            id: moduleId,
            type: 'module',
            active: true,
        });

        endMonitor();
    } catch (e) {
        endMonitor(false, e);
    }
};

export const copyModule = function (info, opts, callback) {
    // 临时模块逻辑
    // 生成临时模块id
    // 判断是否达到临时模块个数上限
    let tempId = 201;
    let originModuleId = info.id;
    {
        _temporary.addModule = _temporary.addModule || [];

        //前端其实还有个200，但是用来做初始化保护用了，所有后台那边判定200-299为临时数据
        if (_temporary.addModule.length) {
            tempId = _temporary.addModule[_temporary.addModule.length - 1] + 1;
        }

        if (tempId >= 300) {
            Message.warning('已经添加99个模块啦，先保存刷新下吧！');
            return;
        }
        _temporary.addModule.push(tempId);
    }

    // 模块内容修改
    {
        info.id = tempId;
        info.name = setCopyModuleName(info.name, info.style);
        setCopyModuleAnchorName(info);
        setIs20210707NewModule(info);
    }

    // 处理数据到模块集合
    {
        if (info.style == 32) {
            // 将标签栏数据临时存储
            let tempTabs = $.extend(true, [], info.blob0.tabs); // 清空新复制模块的标签数据

            info.blob0.tabs.forEach((item) => {
                item.mIds = [];
            });
            Vue.set(store.state.modules, 'module' + tempId, info); // 复制标签模块的子模块

            copyModuleTab(info, tempTabs);
        } else {
            Vue.set(store.state.modules, 'module' + tempId, info);
        }
    }

    // 处理
    //添加到容器
    //添加动画

    if (!callback) {
        // 添加到容器
        if (!opts.isLayoutModule) {
            // 复制后添加到的容器
            let receiveMIds;

            let receiveRow = store.state.rows['row' + opts.rowId];

            receiveMIds = opts.copyTabpack ? opts.copyTab.mIds : receiveRow?.cols[opts.colIndex]?.mIds;

            if (store.getters.isCarouselRow(opts.rowId)) {
                receiveMIds = getCurrentCarouselRow(opts.rowId)?.cols?.[opts.colIndex]?.mIds;
            }

            if (opts.copyTabpack) {
                receiveMIds.push(tempId);
            } else {
                let prevModuleId = originModuleId;
                let receiveMatchPrev = receiveMIds.indexOf(prevModuleId);
                receiveMIds.splice(receiveMatchPrev + 1, 0, tempId);
            }
        } else {
            window._store.state.responsiveInfo.layoutStatusModuleIds.push(tempId);
        }

        // 添加动画
        const svgName = getSvgName(info.style);

        let scrollElem;
        let flag;

        if (fullNavManage.isEdit()) {
            scrollElem = '.jz_full_nav_wrap ';
            flag = true;
        }

        scrollToTarget(
            '#module' + tempId,
            0,
            250,
            () => {
                store.commit('manage/setActiveSvgAnimation', {
                    selector: `#module${tempId}`,
                    svgName,
                });
            },
            scrollElem,
            flag
        );
    } else {
        //自定义添加位置
        callback(tempId);
    }
};

export const delModule = function (moduleId, rowId, isDeleteModule = true) {
    // 业务监控
    const endMonitor = window.Monitor.startMonitor(Site.MonitorDef.MonitorId.R_HIDE_MODULE);

    // 停靠或者锁定模块，模块id保存在store.state.responsiveInfo.layoutStatusModuleIds
    if (!rowId) {
        // 删除layoutStatusModuleIds
        const layoutStatusModuleIds = store.state.responsiveInfo.layoutStatusModuleIds;
        const index = layoutStatusModuleIds.findIndex((id) => id === moduleId);
        if (index !== -1) {
            layoutStatusModuleIds.splice(index, 1);
        }

        // 标记为删除模块
        delModuleData(moduleId);
        return;
    }

    try {
        let match; // 判断删除的模块是否处于标签模块内

        if (isInTabpack(moduleId)) {
            let $tabpack = getParentTabPackModule(moduleId);
            let tabpackId = $tabpack.attr('tabpackid'),
                curtabid = $tabpack.attr('curtabid'),
                tabpackData = store.state.modules[`module${tabpackId}`];
            let curTabData = tabpackData.blob0.tabs.find((tab) => {
                return tab.id == curtabid;
            });
            match = curTabData.mIds.findIndex((id) => id == moduleId);

            if (match > -1) {
                curTabData.mIds.splice(match, 1);
                if (isDeleteModule) {
                    delModuleData(moduleId);
                }
                endMonitor();
                Root.behavior.addBeHavior('delModule_in_tabpack', {
                    moduleId,
                    tabpackId,
                    tabId: curtabid,
                });
                return moduleId;
            }
        } else {
            if (store.getters.isCarouselRow(rowId)) {
                const _cols = getCurrentCarouselRow(rowId)?.cols;

                for (let col in _cols) {
                    match = _cols[col].mIds.indexOf(moduleId);

                    if (match > -1) {
                        _cols[col].mIds.splice(match, 1);

                        if (isDeleteModule) {
                            delModuleData(moduleId);
                        }
                        endMonitor();
                        Root.behavior.addBeHavior('delModule', {
                            moduleId,
                            colIndex: col,
                            rowId,
                        });
                        return moduleId;
                    }
                }
            } else {
                const row = store.state.rows['row' + rowId];

                for (let col in row.cols) {
                    match = row.cols[col].mIds.indexOf(moduleId);

                    if (match > -1) {
                        row.cols[col].mIds.splice(match, 1);
                        if (isDeleteModule) {
                            delModuleData(moduleId);
                        }
                        endMonitor();
                        Root.behavior.addBeHavior('delModule', {
                            moduleId,
                            colIndex: col,
                            rowId,
                        });
                        return moduleId;
                    }
                }
            }
        }
    } catch (e) {
        endMonitor(false, e);
    }

    return false;
};

export const delModuleData = function (moduleId) {
    //判断是否是标签模块，删除标签模块要将当前标签模块里最新的id列表存入 window._delete.modules中，否则从标签模块内部拖拽出去的模块，也会被删除。
    isTabpack(moduleId) && setTabsIdToDeleteModules(moduleId);

    if (moduleId >= 300) {
        store.commit('manage/setModuleToModuleDeleteSet', moduleId);
    }

    if (moduleId > 200 && moduleId < 300) {
        store.state.modules && Vue.delete(store.state.modules, 'module' + moduleId);
    }
};
