//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logJZFdp } from '@/site/shared/log';
import { mapState } from 'vuex';

export default {
    name: 'DemoPreview',
    data() {
        return {
            showDemoPreviewSection: false,
            devices: [
                {
                    name: 'pc',
                    tips: '电脑视图',
                },
                {
                    name: 'pad',
                    tips: '平板视图',
                },
                {
                    name: 'phone',
                    tips: '手机视图',
                },
            ],
            activeDeviceName: 'pc',
            session: {
                exist: false,
                cid: 0,
                sid: 0,
            },
            templateData: {
                pagination: {
                    prevLink: '',
                    nextLink: '',
                    currentIndex: 0,
                    total: 0,
                },
                QRCodeImage: '',
                useTemplateLink: '',
                moreTemplateLink: '',
                useTemplateFdpDef: {},
                homeDomain: '',
            },
        };
    },
    computed: {
        ...mapState(['oem', 'isDemo']),
    },
    async created() {
        if (this.oem || !this.isDemo) return;

        if (top === window) {
            this.showDemoPreviewSection = true;
        } else {
            return;
        }

        const templateData = await this.fetchTemplateData();
        if (templateData) {
            this.templateData.pagination.nextLink = templateData.nextLink;
            this.templateData.pagination.prevLink = templateData.prevLink;
            this.templateData.pagination.currentIndex = templateData.nowModel;
            this.templateData.pagination.total = templateData.total;
            this.templateData.QRCodeImage = templateData.qrCodeUrl;
            this.templateData.useTemplateLink = templateData.demoAction;
            this.templateData.moreTemplateLink = templateData.moreModelUrl;
            this.templateData.useTemplateFdpDef = templateData.fdpObject;
            this.templateData.homeDomain = templateData.homeDomain;

            // session
            this.session.cid = templateData.sessionCid;
            this.session.sid = templateData.sessionSid;
            this.session.exist = this.session.cid !== 0;
        }
        this.invalidateLogin();
    },
    mounted() {
        this.activateDevice();
    },
    methods: {
        async fetchTemplateData() {
            const { data } = await jzRequest.get('/api/respDemo/getButtonBarData').catch(() => ({ data: null }));
            return data?.model ?? null;
        },
        handleChangePage(pageLink = '', e) {
            if (pageLink === '') {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        useTemplate() {
            logJZFdp('clickcopy_template', this.templateData.useTemplateFdpDef);
        },
        moreTemplateClickHandler() {
            $.cookie('_mdTy', 1, {
                domain: this.templateData.homeDomain,
            });
        },
        activateDevice() {
            const hash = window.location.hash;
            if (hash.includes('#device=') === false) return;
            this.activeDeviceName = hash.replace(/#device=([a-zA-Z])/, (_str, $1) => $1);
        },
        deviceClickHandler(device = 'pc') {
            window.location.href = `${window.location.origin}?previewDemo=true#device=${device}`;
        },
        invalidateLogin() {
            if (this.session.cid !== 0 && this.session.sid !== 0) return;
            const $doc = $(document);
            const visibilitychangeEvt = 'visibilitychange';

            $doc.off(visibilitychangeEvt).on(visibilitychangeEvt, async () => {
                if (document.visibilityState == 'visible') {
                    const { data } = await jzRequest.post('/ajax/login-h.jsp?cmd=checkLoginState');
                    const loginSuccess = data.success;
                    const needReload = (loginSuccess && !this.session.exist) || (!loginSuccess && this.session.exist);

                    if (needReload) {
                        window.location.reload();
                    }
                }
            });
        },
    },
};
