import { store } from '@/store/store.js';
import { createCssStylesApp } from './cssStylesApp';

export const initCssStyles = () => {
    const styleEl = document.createElement('style');
    styleEl.id = 'cssVue';
    document.head.appendChild(styleEl);

    window.cssApp = createCssStylesApp({ store, update }).$mount();

    update();

    function update() {
        styleEl.innerText = window.cssApp?.$el.innerText.trim();
    }
};

export const handleCssStylesSsrError = () => {
    const linkEl = document.querySelector('link[href*=jzresponsivecusstyle]');
    let isError = false;
    if (linkEl && linkEl.sheet) {
        const rules = Object.values(linkEl.sheet.rules) || [];
        // css_ssr_error 为 ssr 失败标志
        isError = Boolean(rules.find((r) => r.selectorText === '.css_ssr_error'));
    } else {
        isError = true;
    }
    if (isError) {
        console.log('[css styles]: ssr error and will call csr');
        cssStylesCsr();
    }
};

export const cssStylesCsr = () => {
    initCssStyles();
};
