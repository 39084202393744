export const bindShareMouseenter = function (elem) {
    var windowHeight = $(window).height(),
        windowWidth = $(window).width(),
        elemRect = elem[0].getBoundingClientRect();

    if (windowWidth <= 768) {
        return;
    } //小于180时就上面展示

    //小于180时就上面展示
    if (windowHeight - elemRect.top < 180) {
        elem.addClass('jz_share_info_hover_top');
    }

    elem.addClass('jz_share_info_hover');
};

export const bindShareMouseleave = function (elem) {
    elem.removeClass('jz_share_info_hover jz_share_info_hover_top');
};

export const triggerSharePanel = function (e, parentSel) {
    if ($('.jz_screen_pc').length) {
        //电脑不执行这段代码
        return;
    }

    $(parentSel).find('.jz_share_wrap').addClass('jz_share_wrap_active');
    $(parentSel)
        .find('.jz_share_wrap_active')
        .off('click.newsdetail')
        .on('click.newsdetail', function (e) {
            e.stopPropagation();
        });
    $(parentSel)
        .find('.jz_share_wrap')
        .on('touchmove', function (e) {
            e.preventDefault();
        });
    $('body').addClass('jz_body_no_scroll');
    $('.jz_share_list').slideDown(300);
    $(parentSel)
        .find('.jz_share_layout, .jz_share_icon')
        .one('click.newsdetail', function () {
            $('body').removeClass('jz_body_no_scroll');
            $('.jz_share_list').slideUp(300, function () {
                $(this).removeAttr('style');
                $(parentSel).find('.jz_share_wrap').removeClass('jz_share_wrap_active');
            });
            // 如果开启了返回顶部按钮，那么在隐藏分享面板的时候需要显示回来返回顶部按钮
        });
    e && e.stopPropagation();
};
