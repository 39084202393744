//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { manageComponent } from './mixin';
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'TimelineItemImage',
    mixins: [manageComponent],
    inject: ['module'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        src: {
            type: String,
            required: true,
        },
        link: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapState(['device']),
        ...mapGetters(['isMobi']),
        scale() {
            if (this.isMobi) {
                const mobiScale = this.module.privatePattern.mobi.scale;
                if (mobiScale === 3) {
                    // 3是mobi默认，取pc的
                    return this.module.privatePattern.pc.scale;
                }
            }
            return this.module.privatePattern[this.device].scale;
        },
    },
    methods: {
        handleClick() {
            this.$emit('on-click');
        },
    },
};
