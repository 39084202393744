//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dayjs } from '@jz/utils';
import { Message } from '@shared/manage/componMessage/index.js';
import ModuleNewsComment from '../common/moduleNewsComment.vue';
import {
    bindShareMouseenter,
    bindShareMouseleave,
    triggerSharePanel,
} from '@/components/modules/common/shareComponent/index.js';
import { TableResponsive } from '@jz/biz-shared';
import { Comm } from '@/components/utils.js';
import { warningMessage, successMessage } from '@/site/shared/dialog/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import { submitNewsComment } from '@/api/news/index.js';
import JzShareSsrCompon from '@/components/modules/common/shareCompon/index.vue';
import { browser } from '@jz/utils';
const { getDisplayData, bindFlag, extend } = Comm;
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
import { decodeHtml } from '@/components/utils.js';

import HtmlRenderer from '@/components/htmlRenderer/index.vue';
import { slideshowView } from '@shared/visitor/slideshow/index.js';
import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES } from '@/services';
import { realNameAuthTips, topTips } from '@/site/topTips';
const { getBitMemory } = Comm;
export default {
    name: 'ModuleNewsDetail',
    components: {
        ModuleNewsComment,
        JzShareSsrCompon,
        HtmlRenderer,
    },
    props: ['id', 'module'],
    data() {
        return {
            isAndroid: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'LS', 'sections', 'responsiveInfo', 'realNameAuth']),
        qrCodeLcid() {
            return this.LS.qrcode;
        },
        hideNews() {
            return this.module.hideNews;
        },
        noViewAllow() {
            return this.module.noViewAllow;
        },
        limitViewInfo() {
            return this.module.limitViewInfo;
        },
        refleshValidateTips() {
            return this.LS.msgBoradChageValidateCode;
        },
        checkColonMsgBoardContent() {
            let tmpMsgBoardContent = this.module.lcid.msgBoardContent;

            if (tmpMsgBoardContent.endsWith('：')) {
                tmpMsgBoardContent = tmpMsgBoardContent.slice(0, tmpMsgBoardContent.length - 1);
            }

            return tmpMsgBoardContent;
        },

        commentPlaceHolder() {
            return this.module.newsCommentLength > 0 ? this.module.lcid.comeToComment : this.module.lcid.hasNoComment;
        },

        deviceData() {
            var device = this.device;
            return this.module[device == 'pc' ? 'prop1' : 'prop5'];
        },

        date() {
            return dayjs(this.module.newsInfo.date).format('YYYY-MM-DD HH:mm');
        },
        needCommentName() {
            if (this.sections.logined) {
                return false;
            }
            return true;
        },
        memberName() {
            return this.sections.memberName || '';
        },
        newsInfo() {
            return this.module.newsInfo;
        },

        comment() {
            return (
                this.newsInfo.comment || {
                    list: [],
                }
            );
        },

        showViewIcon() {
            return !(this.device === 'mobi' && this.isAndroid);
        },

        flagDate: bindFlag(0x1),
        flagView: bindFlag(0x2),
        flagAuthor: bindFlag(0x4),
        flagGroup: bindFlag(0x8),
        flagSource: bindFlag(0x10),
        flagLink: bindFlag(0x20),
        flagPrevNext: bindFlag(0x40),
        flagQrCode: bindFlag(0x80),
        flagShare: bindFlag(0x100),
        flagMobiDate: Comm.bindFlag(0x200),
        flagMobiAuthor: Comm.bindFlag(0x400),
        flagMobiGroup: Comm.bindFlag(0x800),
        flagMobiSource: Comm.bindFlag(0x1000),
        flagMobiLink: Comm.bindFlag(0x2000),
        flagMobiPrevNext: Comm.bindFlag(0x4000),
        flagMobiDefaultShow: Comm.bindFlag(0x8000),

        mobiDevice() {
            return this.device === 'mobi';
        },

        mobiDefaultShow() {
            return this.mobiDevice && this.flagMobiDefaultShow;
        },

        isDateOpen() {
            return this.mobiDefaultShow ? this.flagMobiDate : !this.flagDate;
        },

        isViewOpen() {
            return !this.flagView && this.newsInfo.isViewsOpen;
        },

        isAuthorOpen() {
            let autherOpen = this.mobiDefaultShow ? this.flagMobiAuthor : !this.flagAuthor;
            return autherOpen && this.newsInfo.author;
        },

        isGroupOpen() {
            let groupOpen = this.mobiDefaultShow ? this.flagMobiGroup : !this.flagGroup;
            return groupOpen && this.newsInfo.groupIds.length;
        },

        isSourceOpen() {
            let sourceOpen = this.mobiDefaultShow ? this.flagMobiSource : this.flagSource;
            return sourceOpen && this.newsInfo.source;
        },

        isLinkOpen() {
            let linkOpen = this.mobiDefaultShow ? this.flagMobiLink : this.flagLink;
            return linkOpen && this.newsInfo.link;
        },

        isPrevNextOpen() {
            let prevNext = this.mobiDefaultShow ? this.flagMobiPrevNext : this.flagPrevNext;
            return prevNext && (this.newsInfo.prevNext.hasPrev || this.newsInfo.prevNext.hasNext);
        },

        isQrCodeOpen() {
            return this.flagQrCode && this.newsInfo.isQRCodeOpen;
        },

        isShareOpen() {
            return !this.flagShare;
        },

        isHeaderInfoLineOpen() {
            return this.isDateOpen || this.isViewOpen || this.isAuthorOpen || this.isQrCodeOpen;
        },

        isFooterInfoLineOpen() {
            return this.isPrevNextOpen && (this.newsInfo.prevNext.hasPrev || this.newsInfo.prevNext.hasNext);
        },

        hoverQrSrc() {
            return '/qrCode.jsp?cmd=responsiveDetailQR&id=' + this.newsInfo.id + '&lcid=2052&t=1'; //return "/qrCode.jsp?cmd=resposiveDetialQrCode&id=", id, "&lcid=", lcid, "&t=1
        },

        download() {
            return this.module.newsInfo.downLoadFiles;
        },

        titleStyle() {
            var device = this.device,
                pcFont = this.module.privatePattern.pc.cmtf,
                mobiFont = this.module.privatePattern.mobi.cmtf,
                alignDict = ['left', 'center', 'right', 'justify'],
                font = getDisplayData(device, pcFont, mobiFont),
                fontStyle = {},
                style = {};
            fontStyle = font.y && {
                fontSize: font.s + 'px',
                fontFamily: font.f,
                color: font.c,
                fontStyle: font.i == 1 ? 'italic' : 'normal',
                fontWeight: font.w == 1 ? 'bold' : 'normal',
                textDecoration: font.d == 1 ? 'underline' : 'none',
                textAlign: alignDict[font.ta],
            };
            extend(style, fontStyle);
            return style;
        },

        subTitleStyle() {
            var pcFont = this.module.privatePattern.pc.stf,
                mobiFont = this.module.privatePattern.mobi.stf,
                device = this.device,
                font = getDisplayData(device, pcFont, mobiFont),
                alignDict = ['left', 'center', 'right', 'justify'],
                fontStyle = {},
                style = {};
            fontStyle = font.y && {
                fontSize: font.s + 'px',
                fontFamily: font.f,
                color: font.c,
                fontStyle: font.i == 1 ? 'italic' : 'normal',
                fontWeight: font.w == 1 ? 'bold' : 'normal',
                textDecoration: font.d == 1 ? 'underline' : 'none',
                textAlign: alignDict[font.ta],
            };
            Comm.extend(style, fontStyle);
            return style;
        },

        groups() {
            return this.module.newsGroupData;
        },
        isExternal() {
            const flag = this.newsInfo.flag ?? 0;
            return getBitMemory(flag, 0x80);
        },
    },
    watch: {
        'module.prop3': function () {
            for (let i in window._store.state.modules.module2.prop3) {
                this.module.share.list[i].isShow = window._store.state.modules.module2.prop3[i].i;
            }
        },
        'newsInfo.richContent': function () {
            this.$nextTick(() => {
                this.handleTableResponisve();
            });
        },
    },

    mounted() {
        this.isAndroid = browser.isAndroid();
        this.handleTableResponisve();
        if (!this.$isServer) {
            setHrefEventHasReqArgs($(`#module${this.id}`));
        }
        this.initRealNameAuthTips();
    },

    methods: {
        _validateHasLink(el) {
            let hasLink = false;
            if (el != null) {
                let $el = $(el);
                while (!$el.hasClass('news_detail_content')) {
                    $el = $el.parent();
                    let href = $el.attr('href');
                    if ($el[0].tagName === 'A' && href != '' && href != null && href != 'javascript:void(0)') {
                        hasLink = true;
                        break;
                    }
                }
            }
            return hasLink;
        },
        bindUeSlider(e, terminalName = '自适应-文章详情-ue') {
            const target = e.target;
            let tagName = target.tagName;
            if (typeof tagName == 'string' && tagName.toLowerCase() == 'img' && !this._validateHasLink(target)) {
                // 处理链接
                const html = this.$refs.htmlRenderer.$refs[this.$refs.htmlRenderer.refName];
                let result = [];

                let initialIndex = 0;

                Array.from($(html).find('img')).forEach((el, index) => {
                    // 过滤掉video标签内嵌标签
                    if (!$(el).parent().is('video')) {
                        let imageId = 'photoswipe_' + index;

                        let elSrc = $(el).attr('src');
                        result.push({
                            src: elSrc,
                            width: $(el).attr('vwidth') || $(el).attr('picWidth') || el.naturalWidth,
                            height: $(el).attr('vheight') || $(el).attr('picHeight') || el.naturalHeight,
                            title: '',
                            description: '',
                            detail: false,
                            id: imageId,
                        });

                        el.setAttribute('data-picId', imageId);
                    }
                });

                result.forEach((item, index) => {
                    if (item.id === target.getAttribute('data-picId')) {
                        initialIndex = index;
                    }
                });

                slideshowView({
                    id: `${parseInt(Math.random() * 10000)}`,
                    previewSrcList: result,
                    initialIndex,
                    logEventConfig: {
                        event: 'jz_hdpzj',
                        properties: {
                            jz_content_terminal: terminalName,
                            jz_version: window._store.state.jzVersion,
                        },
                    },
                });
            }
        },
        handleTableResponisve() {
            TableResponsive.init({
                $tables: $('.module_content_detail table'),
                parentClassName: '.module_content_detail',
            });
        },

        decodeHtml,

        async newsSubmitBtnClick() {
            if (this.manageMode) {
                Message.warning('当前为管理状态，您不能提交评论!');
                return;
            }

            if (this.realNameAuth.allowSubmitMessage === false) {
                return;
            }

            const mobileAuthPopupService = new MobileAuthPopupService(this.device, this.responsiveInfo.themeColor);
            const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(
                MOBILE_AUTH_MESSAGE_TYPES.NEWS_COMMENT
            );
            if (needAuth) {
                mobileAuthPopupService.createSubmitHandler(
                    () => {
                        this.submitComment(showAfterReview);
                    },
                    (error) => {
                        Fai.ing(error.msg);
                    }
                );
                mobileAuthPopupService.show();
            } else {
                this.submitComment(showAfterReview);
            }
        },
        submitComment(showAfterReview = false) {
            var creator = $.trim($('#newsCreatName').val() || this.newsInfo?.comment?.creator || '');
            var comment = $('#newsCreatContent').val();
            var commentMinLen = 2; //评论最小字数
            var validateCode = $('#newsValidateCode').val();
            var newsId = this.newsInfo.id;

            if (typeof creator != 'string') {
                warningMessage(this.LS.creatorTips, true);
                return;
            }

            if (typeof comment != 'string' || comment == '') {
                warningMessage(this.LS.commentNotEmpty, true);
                return;
            }
            if (comment.length < commentMinLen) {
                warningMessage(Fai.format(this.LS.commentLenTips, Fai.encodeHtml(commentMinLen)), true);
                return;
            }

            if ($('.J_newsCommentCaptcha').length && !$('.J_newsCommentCaptcha').hasClass('captchaHide')) {
                if (typeof validateCode != 'string' || validateCode == '') {
                    warningMessage(this.LS.memberInputCaptcha, true);
                    return;
                }
            }

            var submitData = {
                validateCode,
                newsId,
                creator,
                comment,
            };
            //已限制了输入  不必检验最大长度
            submitNewsComment({
                ...submitData,
            })
                .then((data) => {
                    var preprocessUrl = Site.preprocessUrl({
                        path: '',
                        oldPath: '',
                    });
                    if (!data || !data.success) {
                        $('#msgBoardCaptchaImg').click();
                    }
                    Fai.removeBg();
                    switch (data.msg) {
                        case 1: //验证码错误
                            warningMessage(this.LS.js_captchaError, true);
                            $('#newsValidateCodeImg').length > 0 && $('#newsValidateCodeImg').click(); // 更换验证码
                            break;
                        case 2: //姓名错误
                            warningMessage(this.LS.creatorError, true);
                            break;
                        case 3: //评论错误
                            warningMessage(this.LS.commentError, true);
                            break;
                        case 4: //提交成功
                            // setTimeout(() => Fai.top.location.reload(), 2000);
                            if (showAfterReview === false) {
                                successMessage(this.LS.commentSuccess, true); // 评论成功
                            } else {
                                successMessage(this.LS.commentSuccessNeedReview, true); // 评论提交成功，请等待管理员审核
                            }
                            // 提交后异步刷新加载第一页评论
                            eventBus.$emit('getCommentsByPage', 1, () => {
                                $('#newsCreatName').length > 0 && $('#newsCreatName').val(''); // 清空用户名
                                $('#newsCreatContent').length > 0 && $('#newsCreatContent').val(''); // 清空内容
                                $('#newsValidateCode').length > 0 && $('#newsValidateCode').val(''); // 清空验证码输入框
                                $('#newsValidateCodeImg').length > 0 && $('#newsValidateCodeImg').click(); // 切换验证码
                            });
                            $('.creat_comment_box_layer').length > 0 && $('.creat_comment_box_layer').click(); // 隐藏评论面板
                            break;
                        case 5: //参数错误，请重新填写
                            warningMessage(this.LS.paramError, true);
                            break;
                        case 6: //评论仅对会员开通，请登录。
                            warningMessage(
                                Fai.format(
                                    this.LS.commentOnlyMember,
                                    '<a href="' +
                                        preprocessUrl +
                                        'login.jsp?url=' +
                                        Fai.encodeUrl(window.location.href) +
                                        '">' +
                                        Fai.encodeHtml(this.LS.login) +
                                        '</a>'
                                ),
                                true
                            );
                            break;
                        case 7: //评论功能关闭。
                            warningMessage(this.LS.commentClosed, true);
                            break;
                        case 8: //系统错误
                            warningMessage(this.LS.commentSubmitError, true);
                            break;
                        case 9: //评论数据已超过总数量限制。
                            warningMessage(this.LS.commentCountLimit, true);
                            break;
                        case 10: // 提示输入验证码
                            warningMessage(this.LS.msgBoardInputValidateCode, true);
                            if (data.needCode) {
                                $('.J_newsCommentCaptcha').removeClass('captchaHide');
                            }
                            break;
                        default:
                    }
                    //Fai.successHandle(result, '', '', document.location.href, 1, 1);
                    if (data.hasFW) {
                        warningMessage(data.msg, true);
                    }
                })
                .catch(() => {
                    Message.warning(this.LS.js_systemError);
                    $('#msgBoardCaptchaImg').click();
                });
        },

        newsValidateCodeImgClick() {
            this.changeValidateCode();
        },

        mobiNewsDetailCommentEnterClick() {
            this.mobiCommentActive();
        },

        jzShareMouseenter(event) {
            bindShareMouseenter($(event.target));
        },

        jzShareMouseleave(event) {
            bindShareMouseleave($(event.target));
        },

        newsShareClick() {
            if (this.device == 'pc') {
                return;
            }

            triggerSharePanel(null, '.news_share_info'); // 点击这个分享的时候，隐藏掉返回顶部按钮
        },

        getLevelStyle(item) {
            //自定义图标
            if (item.levelIconCus) {
                return item.levelPath;
            } else {
                return {
                    color: item.levelColor,
                };
            }
        },

        getLevelClass(item) {
            if (item.levelIconCus) {
                return 'news_reader_level_cus';
            } else {
                return item.levelClass;
            }
        },

        changeValidateCode() {
            $('#newsValidateCodeImg').attr(
                'src',
                '/validateCode.jsp?' + Math.random() * 1000 + '&validateCodeRegType=3'
            );
        },

        mobiCommentActive(e) {
            $('.creat_comment_box').addClass('creat_comment_box_mobi');
            $('.creat_comment_form').slideDown(300);
            $('body').addClass('jz_body_no_scroll');
            $('.creat_comment_box_layer')
                .off('click.newsdetail')
                .on('click.newsdetail', function () {
                    $('body').removeClass('jz_body_no_scroll');
                    $('.creat_comment_form').slideUp(300, function () {
                        $(this).removeAttr('style');
                        $(document).off('click.newsdetail');
                        $('.creat_comment_box').removeClass('creat_comment_box_mobi').off('click.news');
                    });
                });
            e && e.stopPropagation();
        },

        initRealNameAuthTips() {
            if (this.manageMode === false && this.realNameAuth.allowShowMessage === false) {
                topTips.addTopTips(realNameAuthTips);
            }
        },
    },
};
