var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.descClass, style: _vm.style },
    [
      _vm.showPhotoAnimateLayer
        ? [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 48 48",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z",
                    fill: "rgba(51, 51, 51,0.8)",
                    "fill-rule": "evenodd",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M33.8,32.39l-3.62-3.62A8.42,8.42,0,0,0,32,23.51a8.5,8.5,0,1,0-8.5,8.5,8.42,8.42,0,0,0,5.26-1.83l3.62,3.62ZM23.51,30a6.5,6.5,0,1,1,6.5-6.5A6.51,6.51,0,0,1,23.51,30Z",
                    fill: "rgb(250, 250, 250)",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { width: "100%" } }, [
              _c("span", {
                style: _vm.textStyle,
                domProps: { innerHTML: _vm._s(_vm.htmlText) },
              }),
            ]),
          ]
        : _c("span", {
            style: _vm.textStyle,
            domProps: { innerHTML: _vm._s(_vm.htmlText) },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }