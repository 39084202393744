//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    inject: {
        isMaterialPreview: {
            default: () => false,
        },
    },
    props: ['list', 'module'],
    data() {
        return {
            dragOptions: {
                filter: '.fixed_item',
                preventOnFilter: false,
                fallbackTolerance: 1,
                handle: '.form_item',
                forceFallback: true,
                supportPointer: false, //如果没有这个属性，绑定的事件是pointerdown，然后给resize句柄加的mousedown就没有效果 (*/ω＼*)
                checkMove(e) {
                    return !$(e.related).hasClass('fixed_item');
                },
            },
        };
    },
    methods: {
        onSortStart() {
            $(this.$el).addClass('form_editing');
        },
        onSortEnd() {
            $(this.$el).removeClass('form_editing');
        },
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        allowEdit() {
            return this.manageMode && this.device === 'pc' && !this.isMaterialPreview;
        },
    },
};
