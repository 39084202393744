var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "timeline_carousel",
      class: { timeline_carousel_layout_two: _vm.isColumnStyle },
    },
    [
      _c("div", { staticClass: "timeline_c_content_wrap" }, [
        _c(
          "div",
          {
            staticClass: "timeline_c_content",
            class: [].concat(_vm.swiperContainerClass),
          },
          [
            _c(
              "div",
              {
                staticClass: "timeline_c_scroll",
                class: [].concat(_vm.swiperWrapClass),
                style: _vm.scrollStyle,
              },
              _vm._l(_vm.timelineItems, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "timeline_c_item",
                    class: [].concat(_vm.swiperSlideClass),
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "timeline_c_image_wrap",
                        class: { compat_gap: _vm.isCompatGap },
                      },
                      [
                        _c("timeline-item-image", {
                          attrs: {
                            id: item.id,
                            src: item.pic.psrc,
                            link: item.pic.link,
                          },
                          on: {
                            "on-click": function ($event) {
                              return _vm.handleImageClick(item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.title || item.desc,
                            expression: "item.title || item.desc",
                          },
                        ],
                        staticClass: "timeline_c_text_wrap",
                      },
                      [
                        _c("timeline-item-title", {
                          staticClass: "timeline_text_h_item_title",
                          attrs: { id: item.id, value: item.title },
                        }),
                        _vm._v(" "),
                        _c("timeline-item-desc", {
                          staticClass: "timeline_h_top_desc",
                          attrs: { id: item.id, value: item.desc },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "timeline_c_prev",
            class: { "timeline_c_prev--disabled": _vm.prevDisabled },
            on: { click: _vm.onPrev },
          },
          [_c("svg", [_c("use", { attrs: { "xlink:href": "#icon_prev" } })])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "timeline_c_next",
            class: { "timeline_c_next--disabled": _vm.nextDisabled },
            on: { click: _vm.onNext },
          },
          [_c("svg", [_c("use", { attrs: { "xlink:href": "#icon_next" } })])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "timeline_slide" }, [
        _c(
          "div",
          {
            staticClass: "timeline_slide_scroll",
            style: _vm.slideTranslateStyle,
          },
          [
            _c("div", { staticClass: "timeline_ruler" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "timeline_slide_item_time" },
              _vm._l(_vm.timelineItems, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "timeline_s_item",
                    on: {
                      click: function ($event) {
                        return _vm.handleTimeClick(index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "timeline_s_dot_wrap" }, [
                      _c("div", { staticClass: "timeline_s_dot" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.scrollIndex === index,
                              expression: "scrollIndex === index",
                            },
                          ],
                          staticClass:
                            "timeline_s_dot--active jz_theme_bg_color",
                        },
                        [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon_plane" },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("timeline-item-time", {
                      staticClass: "timeline_s_time",
                      class: {
                        jz_theme_font_color: _vm.scrollIndex === index,
                        "timeline_s_time--active": _vm.scrollIndex === index,
                      },
                      attrs: { id: item.id, value: item.time },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": 9999,
          "initial-index": _vm.imageIndex,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "on-close": _vm.closeViewer,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }