//
//
//
//
//
//
//
//

export default {
    computed: {
        ...Vuex.mapGetters(['info']),
        ...Vuex.mapState(['sections']),
        mobiIconColor() {
            if (this.headerPattern.mobiIconType) {
                return this.headerPattern.mobiIconColor;
            }
            return '';
        },
        loginedClass() {
            return {
                s_member_login_mobi__logined: this.sections.logined,
            };
        },
        pattern() {
            return this.info.pattern.memberLogin;
        },
        loginUrl() {
            return this.sections.logined ? this.sections.mCenterUrl : this.sections.loginUrl;
        },
        headerPattern() {
            return this.info.pattern.header;
        },
        isShow() {
            if (this.info.pattern.displayContent.type) {
                return this.info.pattern.displayContent.memberLogin;
            } else {
                return !this.pattern.h;
            }
        },
    },
};
