//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PopupLayerComponent from './forms/popupLayerComponent.vue';
export default {
    name: 'MobiSelect',
    components: { PopupLayerComponent },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        isLinkToForm: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            required: true,
        },
    },
    methods: {
        onShowInput(value) {
            this.$emit('input', value);
        },
        mobiCancel() {
            this.$emit('input', false);
            this.$emit('on-cancel');
        },
        mobiConfirm() {
            this.$emit('input', false);
            const scrollTop = $(this.$refs.list).scrollTop();
            const currentIndex = Math.round(scrollTop / 38);
            this.$emit('on-confirm', this.list[currentIndex]);
        },
        onTouchStart(e) {
            clearInterval(this.moveTimer);

            this.oldTouchY = this.getTouchEventY(e);

            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('mousemove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
            window.addEventListener('mouseup', this.onTouchEnd);
        },
        onTouchMove(e) {
            const touchY = this.getTouchEventY(e);
            const $list = $(this.$refs.list);

            this.speedTouchY = this.oldTouchY - touchY;

            $list.scrollTop($list.scrollTop() + this.speedTouchY);

            this.oldTouchY = touchY;
        },
        onTouchEnd() {
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
            window.removeEventListener('mouseup', this.onTouchEnd);

            const $list = $(this.$refs.list);

            //惯性滑动
            let speed = this.speedTouchY;
            this.moveTimer = setInterval(() => {
                speed *= 0.8;
                $list.scrollTop($list.scrollTop() + speed);
                if (Math.abs(speed) < 1) {
                    clearInterval(this.moveTimer);
                    finish(speed);
                }
            }, 1000 / 60);

            const finish = () => {
                const scrollTop = $list.scrollTop();
                const currentIndex = Math.round(scrollTop / 38);

                $list.animate(
                    {
                        scrollTop: currentIndex * 38,
                    },
                    50
                );
            };
        },
        getTouchEventY(e) {
            if (e.changedTouches && e.changedTouches[0]) {
                return e.changedTouches[0].pageY;
            } else {
                return e.pageY;
            }
        },
    },
};
