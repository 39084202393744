export default (qs) => {
    qs = qs || '';
    const pageParamObj = {};
    const pageParamArr = qs.replace('?', '').split('&');
    pageParamArr.forEach((e) => {
        const [key, value] = e.split('=');
        if (value) {
            pageParamObj[key] = decodeURIComponent(isNaN(Number(value)) ? value : Number(value));
        }
    });
    return pageParamObj;
};
