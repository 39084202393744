//
//
//
//
//

import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
export default {
    props: ['projectid'],
    computed: {
        id() {
            return this.projectid;
        },
        ...Vuex.mapState(['device']),
        modules() {
            return window._store.state.modules;
        },
        isMobi() {
            return this.device === 'mobi';
        },

        module() {
            return this.modules['module' + this.id];
        },

        pcPrivatePattern() {
            return this.module.privatePattern.pc;
        },
        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },
        privatePattern() {
            return this.device === 'mobi' ? this.mobiPrivatePattern : this.pcPrivatePattern;
        },
    },
    mounted() {
        let featureListener = `f-MarginResize${this.id}`;
        let orContentHeight = null;
        eventBus.$off(`${featureListener}.start`).$on(`${featureListener}.start`, (data) => {
            if (data.type === 'rightBottom') {
                orContentHeight =
                    document.querySelector(`#module${this.id} .module_content`).getBoundingClientRect().height ||
                    this.privatePattern.pch ||
                    0;
            }
        });
        eventBus.$off(`${featureListener}.move`).$on(`${featureListener}.move`, (data) => {
            if (data.type === 'rightBottom') {
                let realyContent = orContentHeight + Math.floor(data.moveY);
                //按钮模块的minHeight 30；
                let minHeight = 30;
                if (realyContent > minHeight) {
                    this.privatePattern.pch = Math.round(realyContent);
                }
                this.privatePattern.pcht = 1;
                this.privatePattern.lock = 0;
            }
        });
    },
    destroy() {
        let featureListener = `f-MarginResize${this.id}`;
        eventBus.$off(`${featureListener}.start`);
    },
};
