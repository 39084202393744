var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "swipeListPhoto",
      staticClass: "list_photos_swipe_style",
      class: _vm.wrapperClasses,
      attrs: { leninrow: _vm.photosInRow },
    },
    [
      _c(
        "div",
        {
          staticClass: "jz_scroll_wrap",
          on: { mouseleave: _vm.startSwipe, mouseover: _vm.stopSwipe },
        },
        [
          _c(
            "ul",
            { ref: "photoList", staticClass: "photo_display_area" },
            [
              _vm._l(_vm.photoList, function (photoItem, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "animate",
                        rawName: "v-animate",
                        value: _vm.module,
                        expression: "module",
                      },
                    ],
                    key: photoItem.pid,
                    staticClass: "photo_item_li photo_item_li_origin",
                    class: "photo_item_li" + index,
                    style: _vm.photoItemStyle(photoItem),
                    attrs: { "data-id": photoItem.pid },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "list_photo_wrapper",
                        class:
                          "" +
                          (_vm.cursorAuto(photoItem.link.url)
                            ? "link-cursor-auto"
                            : ""),
                        attrs: {
                          href: _vm.noLink
                            ? "javascript: void(0);"
                            : photoItem.link.url,
                          target: _vm.noLink
                            ? "_self"
                            : _vm.linkTarget(photoItem.link.ib),
                          rel: _vm.linkNofollow(photoItem.link.nf),
                          onclick: _vm.jumpOnclickStr(photoItem),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.slidesshow(index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "photo_item_inner_wrap",
                            attrs: { "has-desc": !!photoItem.desc },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "hovermenu",
                                    rawName: "v-hovermenu",
                                    value: {
                                      hovermenu: _vm.hovermenu,
                                      args: {
                                        photoId: photoItem.pid,
                                        delHidden: _vm.usePhotoGroup,
                                      },
                                    },
                                    expression:
                                      "{\n                                hovermenu,\n                                args: {\n                                    photoId: photoItem.pid,\n                                    delHidden: usePhotoGroup,\n                                },\n                            }",
                                  },
                                ],
                                staticClass: "photo_item_img_box",
                              },
                              [
                                _c("ImageComponent", {
                                  directives: [
                                    {
                                      name: "lazyload2",
                                      rawName: "v-lazyload2",
                                      value: {
                                        id: _vm.module.id,
                                        src: _vm.imgOriginalSrc(photoItem),
                                      },
                                      expression:
                                        "{\n                                    id: module.id,\n                                    src: imgOriginalSrc(photoItem),\n                                }",
                                    },
                                  ],
                                  staticClass: "photo_item",
                                  style: _vm.photoStyle(photoItem),
                                  attrs: {
                                    src: _vm.imgOriginalSrc(photoItem),
                                    "wrap-style": _vm.photoWrapStyle(),
                                    "wrap-class": "photo_item_imgwrap",
                                    webp: _vm.webpOptOpen,
                                    alt: photoItem.desc,
                                    "img-effects": _vm.isMobi
                                      ? { type: 0 }
                                      : _vm.isNotSwitchImgEffect
                                      ? _vm.imgOptsComputed
                                      : _vm.getImgComponetOption(
                                          _vm.module,
                                          photoItem.pid
                                        ),
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !!photoItem.desc && _vm.model.cmdd
                              ? _c("photo-description", {
                                  attrs: {
                                    module: _vm.module,
                                    text: photoItem.desc,
                                    hide: !_vm.model.cmdd,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.photoList, function (photoItem, index) {
                return [
                  _vm.showCopyPhotoList
                    ? _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "animate",
                              rawName: "v-animate",
                              value: _vm.module,
                              expression: "module",
                            },
                          ],
                          key: photoItem.pid + "copy",
                          staticClass: "photo_item_li",
                          class: "photo_item_li" + index,
                          style: _vm.photoItemStyle(photoItem),
                          attrs: { "data-id": photoItem.pid },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "list_photo_wrapper",
                              class:
                                "" +
                                (_vm.cursorAuto(photoItem.link.url)
                                  ? "link-cursor-auto"
                                  : ""),
                              attrs: {
                                href: _vm.noLink
                                  ? "javascript: void(0);"
                                  : photoItem.link.url,
                                target: _vm.noLink
                                  ? "_self"
                                  : _vm.linkTarget(photoItem.link.ib),
                                rel: _vm.linkNofollow(photoItem.link.nf),
                                onclick: _vm.jumpOnclickStr(photoItem),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.slidesshow(index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "photo_item_inner_wrap",
                                  attrs: { "has-desc": !!photoItem.desc },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hovermenu",
                                          rawName: "v-hovermenu",
                                          value: {
                                            hovermenu: _vm.hovermenu,
                                            args: {
                                              photoId: photoItem.pid,
                                              delHidden: _vm.usePhotoGroup,
                                            },
                                          },
                                          expression:
                                            "{\n                                    hovermenu,\n                                    args: {\n                                        photoId: photoItem.pid,\n                                        delHidden: usePhotoGroup,\n                                    },\n                                }",
                                        },
                                      ],
                                      staticClass: "photo_item_img_box",
                                    },
                                    [
                                      _c("ImageComponent", {
                                        directives: [
                                          {
                                            name: "lazyload2",
                                            rawName: "v-lazyload2",
                                            value: {
                                              id: _vm.module.id,
                                              src: _vm.imgOriginalSrc(
                                                photoItem
                                              ),
                                            },
                                            expression:
                                              "{\n                                        id: module.id,\n                                        src: imgOriginalSrc(photoItem),\n                                    }",
                                          },
                                        ],
                                        staticClass: "photo_item",
                                        style: _vm.photoStyle(photoItem),
                                        attrs: {
                                          src: _vm.imgOriginalSrc(photoItem),
                                          "wrap-style": _vm.photoWrapStyle(),
                                          "wrap-class": "photo_item_imgwrap",
                                          webp: _vm.webpOptOpen,
                                          alt: photoItem.desc,
                                          "img-effects": _vm.isMobi
                                            ? { type: 0 }
                                            : _vm.isNotSwitchImgEffect
                                            ? _vm.imgOptsComputed
                                            : _vm.getImgComponetOption(
                                                _vm.module,
                                                photoItem.pid,
                                                "2"
                                              ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !!photoItem.desc && _vm.model.cmdd
                                    ? _c("photo-description", {
                                        attrs: {
                                          module: _vm.module,
                                          text: photoItem.desc,
                                          hide: !_vm.model.cmdd,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }