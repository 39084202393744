//
//
//
//
//
//
//
//
//
//

import { SERVICE_TYPE } from './utils.js';

export default {
    props: {
        img: {
            type: String,
        },
        text: {
            type: String,
            required: true,
        },
        path: {
            type: String,
        },
        dataKey: {
            type: String,
        },
        type: {
            type: Number,
        },
        account: {
            type: String,
        },
        iconType: {
            type: Number,
        },
    },
    computed: {
        ...Vuex.mapState(['device']),
        defaultSvg() {
            switch (this.iconType) {
                case SERVICE_TYPE.QQ:
                    return '#icon_qq';
                case SERVICE_TYPE.ALI_WANGWANG:
                    return '#icon_wangwang';
                case SERVICE_TYPE.TAOBAO_WANGWANG:
                    return '#icon_wangwang';
                case SERVICE_TYPE.SKYPE:
                    return '#icon_skype';
                default:
                    return '';
            }
        },
        showDefaultIcon() {
            return this.type === 0;
        },
        showCustomIcon() {
            return this.type === 2;
        },
    },
    methods: {
        handleClick() {
            const account = this.account;
            let url = '';
            switch (this.iconType) {
                case SERVICE_TYPE.QQ: {
                    const num = Number(account);
                    url = `//wpa.qq.com/msgrd?v=3&uin=${num}&site=qq&menu=yes`;
                    break;
                }
                case SERVICE_TYPE.ALI_WANGWANG: {
                    url = `//amos.alicdn.com/getcid.aw?v=3&groupid=0&s=1&charset=utf-8&uid=${encodeURIComponent(
                        account
                    )}&site=cnalichn`;
                    break;
                }
                case SERVICE_TYPE.TAOBAO_WANGWANG: {
                    // 【在线客服模块设置淘宝旺旺客服，手机上点击无法跳转】https://www.tapd.cn/42954297/bugtrace/bugs/view?bug_id=1142954297001092221
                    const pcUrl = `//www.taobao.com/go/market/webww/ww.php?ver=3&touid=${account}&siteid=cntaobao&status=2&charset=utf-8`;
                    const h5Url = `//h5.m.taobao.com/ww/index.htm#!dialog-${btoa(encodeURIComponent(account))}`;
                    url = this.device === 'mobi' ? h5Url : pcUrl;
                    break;
                }
                case SERVICE_TYPE.SKYPE: {
                    url = `skype:${account}?call`;
                    break;
                }
                default: {
                    url = '';
                }
            }
            this.openLink(url);
        },
        openLink(link = '') {
            window.open(link, '_blank');
        },
    },
};
