//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
    },
    data() {
        return {
            //管理态不能修改item的value，所以要用一个临时的值储存，跟siteFormData分开
            manageVal: '',
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        itemList() {
            return (this.item.input || '').split('\n').filter((item) => item);
        },
        currentVal() {
            if (!this.manageMode) {
                return this.value;
            } else {
                return this.manageVal;
            }
        },
        ruleList() {
            return this.item.rules || [];
        },
    },
    methods: {
        selectItem(item, index) {
            this.checkRuleChange(item, index);
            this.checkRulePriceChange(item, index);
            if (!this.manageMode) {
                this.$emit('input', item);
            } else {
                this.manageVal = item;
            }
        },
        checkRuleChange(item, index) {
            const list = [];
            this.ruleList.forEach((rule) => {
                const flag = `${rule.contentId}_${rule.optionName}`;
                rule.showList.forEach((showItem) => {
                    if (rule.optionName === item && rule.selected === index) {
                        list.push({ type: 'add', flag, itemId: showItem.key });
                    } else {
                        list.push({
                            type: 'reduce',
                            flag,
                            itemId: showItem.key,
                        });
                    }
                });
            });
            this.$emit('change-by-rule', list);
        },
        checkRulePriceChange(item, index) {
            this.$emit('change-by-price-rule', this.item.id, item, index);
        },
    },
};
