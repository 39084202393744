//
//
//
//
//
//
//
//
//
//
//
//
//

import jzWebRowEntry from './JzWebRowEntry.vue';
export default {
    name: 'jz-web-rows',
    components: {
        jzWebRowEntry,
    },
    props: ['ids', 'content'],
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'responsiveInfo', 'rows']),
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState('row', ['showMobiHideRow']),
        componentInfo() {
            let needTransition = this.manageMode && !this.isFullScreen;
            return {
                compon: needTransition ? 'transition-group' : 'div',
                tag: needTransition ? 'div' : undefined,
                moveClass: needTransition ? 'jz_web_row_move_anim' : undefined,
            };
        },
        curRows() {
            let rows = [],
                ids = Array.from(this.ids),
                length = ids.length,
                i,
                rowId;
            for (i = 0; i < length; i++) {
                rowId = ids[i];
                // mobi视图隐藏通栏
                if (this.responsiveInfo.rowIds.includes(rowId) && this.rows['row' + rowId] && this.device === 'mobi') {
                    if (this.rows['row' + rowId]?.pattern.mobi.mh && !this.showMobiHideRow) continue;
                }
                if (rowId && this.rows['row' + rowId]) {
                    rows.push(this.rows['row' + rowId]);
                } else {
                    // 清除脏数据
                    var index = this.ids.indexOf(rowId);
                    if (index > -1) this.ids.splice(index, 1);
                }
            }
            return rows;
        },
    },
};
