const REFRESH_SCROLL_TOP_KEY = 'refreshScrollTop';
const REFRESH_SCROLL_TOP_BY_FULLSCREEN_KEY = 'refreshScrollTopByFullScreen';
/**
 * 记录模块位置，刷新后恢复
 * @param { Number } moduleId - 模块id
 */
export function recordModuleAndRefreshPage(moduleId) {
    if (window._store.state.fullScreen) {
        const $row = $(`#module${moduleId}`).parents('[id^=row]');
        if ($row.length > 0) {
            window.sessionStorage.setItem(
                REFRESH_SCROLL_TOP_BY_FULLSCREEN_KEY,
                ($row.attr('id') || '').replace('row', '')
            );
        }
    } else {
        const scrollTop = $(window).scrollTop();
        window.sessionStorage.setItem(REFRESH_SCROLL_TOP_KEY, scrollTop);
    }
    window.location.reload();
}

/**
 * 从sessionStorage中获取模块位置并恢复
 */
export function restoreModulePosition() {
    const { fullScreen, colId } = window._store.state;
    if (fullScreen) {
        const rowId = window.sessionStorage.getItem(REFRESH_SCROLL_TOP_BY_FULLSCREEN_KEY);
        if (rowId) {
            setTimeout(() => {
                JZ.jumpToAnchor(colId, `row_${rowId}`, undefined, true);
            }, 500);
            window.sessionStorage.removeItem(REFRESH_SCROLL_TOP_BY_FULLSCREEN_KEY);
        }
    } else {
        const scrollTop = window.sessionStorage.getItem(REFRESH_SCROLL_TOP_KEY);
        if (scrollTop) {
            $(window).scrollTop(scrollTop);
            window.sessionStorage.removeItem(REFRESH_SCROLL_TOP_KEY);
        }
    }
}
