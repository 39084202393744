// pattern里的语言数据列表需要section字段里的补充
function fillLanguageData(patternLanguage, openLanInfoList) {
    if (!Array.isArray(openLanInfoList)) {
        console.error('[multiLanguage Error]: openLanInfoList must be an array');
        openLanInfoList = [];
    }

    if (openLanInfoList.length === 0) {
        return patternLanguage;
    }

    return openLanInfoList.map((s) => {
        const matched = patternLanguage.find((p) => p.lcid === s.lcid);
        if (matched) {
            return {
                url: s.url,
                lanCode: s.lanCode,
                lanVerDesc: s.lanVerDesc,
                name: matched.name,
                lcid: s.lcid,
            };
        }
        return s;
    });
}

export { fillLanguageData };
