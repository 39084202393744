var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c("div", { staticClass: "header" }, [
        !_vm.onlyOnePhoto && !_vm.mobile
          ? _c(
              "div",
              {
                staticClass: "circle prev",
                class: _vm.bigPrevClass,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleBigPrev.apply(null, arguments)
                  },
                },
              },
              [_c("a", { staticClass: "arrow carousel_arrow prev" })]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.onlyOnePhoto && !_vm.mobile
          ? _c(
              "div",
              {
                staticClass: "circle next",
                class: _vm.bigNextClass,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleBigNext.apply(null, arguments)
                  },
                },
              },
              [_c("a", { staticClass: "arrow carousel_arrow next" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "big-photo-list" },
          [
            _vm._l(_vm.photoList, function (item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "lazyload2",
                      rawName: "v-lazyload2",
                      value: {
                        src: item.path,
                        id: _vm.moduleId,
                      },
                      expression:
                        "{\n                    src: item.path,\n                    id: moduleId,\n                }",
                    },
                  ],
                  key: item.id,
                  staticClass: "big-photo",
                  class: _vm.bigPhotoListClass(index),
                  style: _vm.genPicItemStyle(item),
                  attrs: { "src-original": item.path },
                  on: {
                    click: function ($event) {
                      return _vm.slidesshow(item, index)
                    },
                  },
                },
                [
                  !_vm.isEmptyDesc(item) && _vm.desShow
                    ? _c("div", { staticClass: "desc" }, [
                        _c("span", {
                          staticClass: "name",
                          domProps: { innerHTML: _vm._s(item.desc) },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "proportion" }, [
                          _vm._v(_vm._s(index + 1 + "/" + _vm.photoListLength)),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCircle,
                    expression: "showCircle",
                  },
                ],
                staticClass: "circle-proportion",
              },
              [_vm._v(_vm._s(_vm.selectId + 1 + "/" + _vm.photoListLength))]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.onlyOnePhoto
        ? _c("div", { staticClass: "footer", class: _vm.footerClass }, [
            !_vm.isLessPhoto && !_vm.mobile
              ? _c(
                  "div",
                  {
                    ref: "footerPrev",
                    staticClass: "prev block",
                    class: _vm.footerPrevClass,
                    on: { click: _vm.handlePrev },
                  },
                  [
                    _c("svg", { attrs: { width: "8px", height: "15px" } }, [
                      _c("use", {
                        attrs: {
                          "xlink:href": "#icon_photo_group_detail_prev",
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "slider" }, [
              _c(
                "div",
                {
                  ref: "smallPhotoList",
                  staticClass: "small-photo-list",
                  class: _vm.lessPhotoClass,
                },
                _vm._l(_vm.photoList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "small-photo",
                      class: _vm.smallPhotoClass(index),
                      on: {
                        click: function ($event) {
                          return _vm.selectPhoto(index)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "mask",
                        class: _vm.smallMaskStyle(index),
                      }),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "lazyload2",
                            rawName: "v-lazyload2",
                            value: {
                              src: item.path,
                              id: _vm.moduleId,
                            },
                            expression:
                              "{\n                            src: item.path,\n                            id: moduleId,\n                        }",
                          },
                        ],
                        staticClass: "photo",
                        style: _vm.genPicItemStyle(item),
                        attrs: { "src-original": item.path },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            !_vm.isLessPhoto && !_vm.mobile
              ? _c(
                  "div",
                  {
                    ref: "footerNext",
                    staticClass: "next block",
                    class: _vm.footerNextClass,
                    on: { click: _vm.handleNext },
                  },
                  [
                    _c("svg", { attrs: { width: "8px", height: "15px" } }, [
                      _c("use", {
                        attrs: {
                          "xlink:href": "#icon_photo_group_detail_next",
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }