import { store } from '@/store/store.js';
import { getModuleBelong } from '@shared/manage/moduleUtils/index.js';
import { isInTabpack } from '@shared/manage/tabpackUtils/index.js';
import { deepExtendClone } from '@jz/utils';
import { setSiteCloseNoPopup } from '@/api/siteClose/index.js';
import { getAcctInfo, cloneCol } from '@/api/webTools/index.js';
import { DetectZoom } from '@shared/manage/detectZoom/index.js';
import { getComputedTextColor } from '@shared/manage/utils/index.js';

const creatResizeLayout = function (value, project, type) {
    // 如果是横向标签模块内的模块，拖拽移动时不产生阴影
    if (isInTabpack($(project).attr('projectid'))) {
        return;
    }

    if (type != 'leftTop' && type != 'left') {
        if (value != 0) {
            $('.project_resize_layout').hide();
            return;
        }
    } else if (type == 'leftTop') {
        if (value.realyML != 0 && value.realyMT != 0 && value.realyMR != 0) {
            $('.project_resize_layout').hide();
            $('.jz_editing_another_layout').hide();
            return;
        } else if (value.realyMT != 0) {
            $('.project_resize_layout').hide();
        } else if (value.realyML != 0 && value.realyMR != 0) {
            $('.jz_editing_another_layout').hide();
        }
    } else if (type == 'left') {
        if (value.realyML != 0 && value.realyMR != 0) {
            $('.project_resize_layout').hide();
            return;
        }
    }

    var body = $('body'),
        belong = getModuleBelong($(project)) || {},
        rowId = belong.rowId,
        rowOption = window._store.state.rows[`row${rowId}`],
        colLength = rowOption.cols.length,
        colIndex = belong.colIndex,
        prevProjectId = belong.prevProjectId,
        created = $('.project_resize_layout').length,
        layout = created
            ? $('.project_resize_layout')
            : $('<div class="jz_editing_layout project_resize_layout"></div>'),
        anotherLayout = $('.jz_editing_another_layout'),
        rowDom = $('#row' + rowId),
        rowOffset = rowDom.offset(),
        rowPageRect = $.extend(
            {
                height: rowDom.outerHeight(),
                width: rowDom.outerWidth(),
                right: rowOffset.left + rowDom.outerWidth(),
                bottom: rowOffset.top + rowDom.outerHeight(),
            },
            rowOffset
        ),
        colDom = $(project).parents('.jz_web_col'),
        colOffset = colDom.offset(),
        colPageRect = $.extend(
            {
                height: colDom.outerHeight(),
                width: colDom.outerWidth(),
                right: colOffset.left + colDom.outerWidth(),
                bottom: colOffset.top + colDom.outerHeight(),
            },
            colOffset
        ),
        device = Comm.getDevice(),
        row = window._store.state.rows['row' + rowId],
        pattern = row.pattern[device],
        colPattern = rowOption.cols[colIndex].pattern,
        isMutilCol = colLength > 1,
        //是否为多列
        match = false,
        anthorMatch = false; // 生成模块拖拽边界的阴影

    if (isMutilCol) {
        creatMutilResizeLayout();
    } else {
        createSingleResizeLayout();
    }

    layout.removeClass(
        'project_resize_layout_bottom project_resize_layout_top project_resize_layout_left project_resize_layout_rightBottom project_resize_layout_leftTop'
    );
    layout.addClass('project_resize_layout_' + type);

    if (match) {
        !created && body.append(layout);
        layout.show();
    } else {
        layout.hide();
    }

    if (anthorMatch) {
        !anthorMatch.length && body.append(anotherLayout);
        anotherLayout.show();
    } else {
        anotherLayout.hide();
    } // 多列

    function creatMutilResizeLayout() {
        let borderTopWidth = parseInt(colDom.css('borderTopWidth')),
            borderLeftWidth = parseInt(colDom.css('borderLeftWidth')),
            borderRightWidth = parseInt(colDom.css('borderRightWidth')),
            paddingLeft = parseInt(colDom.css('paddingLeft')),
            paddingRight = parseInt(colDom.css('paddingRight')),
            paddingTop = parseInt(colDom.css('paddingTop')),
            rowPaddingTop = parseInt(rowDom.css('paddingTop')),
            rowPaddingLeft = parseInt(rowDom.css('paddingLeft')),
            rowPaddingRight = parseInt(rowDom.css('paddingRight')),
            rowBorderTopWidth = parseInt(rowDom.css('borderTopWidth')),
            rowBorderLeftWidth = parseInt(rowDom.css('borderLeftWidth')),
            rowBorderRightWidth = parseInt(rowDom.css('borderRightWidth')),
            rowTopMatch = false;

        if (type == 'left') {
            if (value.realyML == 0) {
                if (colPattern.ml > 0) {
                    layout.css({
                        left: colPageRect.left + borderLeftWidth,
                        top: colPageRect.top + borderTopWidth,
                        height: colPageRect.height - borderTopWidth * 2,
                        width: paddingLeft,
                    });
                    match = true;
                } else if (pattern.pl > 0 && colIndex == 0 && colPattern.ml <= 0) {
                    layout.css({
                        left: rowPageRect.left,
                        top: rowPageRect.top,
                        height: rowPageRect.height,
                        width: rowPaddingLeft + rowBorderLeftWidth,
                    });
                    match = true;
                }
            } else if (value.realyMR == 0) {
                if (colPattern.mr > 0) {
                    layout.css({
                        left: colPageRect.right - paddingRight - borderRightWidth,
                        top: colPageRect.top + borderTopWidth,
                        height: colPageRect.height - borderTopWidth * 2,
                        width: paddingRight,
                    });
                    match = true;
                } else if (pattern.pr > 0 && colIndex == row.cols.length - 1 && colPattern.mr <= 0) {
                    layout.css({
                        left: rowPageRect.right - rowPaddingRight - rowBorderRightWidth,
                        top: rowPageRect.top,
                        height: rowPageRect.height,
                        width: rowPaddingRight + rowBorderRightWidth,
                    });
                    match = true;
                }
            }
        }

        if (type == 'top' && prevProjectId === 0) {
            if (colPattern.mt > 0) {
                layout.css({
                    left: colPageRect.left + borderLeftWidth,
                    top: colPageRect.top + borderTopWidth,
                    height: paddingTop,
                    width: colPageRect.width - borderLeftWidth * 2,
                });
                match = true;
            } else if (pattern.pt > 0 && colPattern.mt <= 0) {
                layout.css({
                    left: rowPageRect.left,
                    top: rowPageRect.top,
                    height: rowPaddingTop + rowBorderTopWidth,
                    width: rowPageRect.width,
                });
                match = true;
            }
        }

        if (type == 'leftTop') {
            let layoutTop = colPageRect.top + borderTopWidth;
            let rowLayoutTop = rowPageRect.top;
            let anotherLayoutTop = 0;
            let anotherLayoutHeight = 0; // 上边距

            if (prevProjectId === 0 && value.realyMT == 0) {
                if (colPattern.mt > 0) {
                    layout.css({
                        left: colPageRect.left + borderLeftWidth,
                        top: colPageRect.top + borderTopWidth,
                        height: paddingTop,
                        width: colPageRect.width - borderLeftWidth * 2,
                    });
                    match = true;
                } else if (colPattern.mt <= 0 && pattern.pt > 0) {
                    layout.css({
                        left: rowPageRect.left,
                        top: rowPageRect.top,
                        height: rowPaddingTop + rowBorderTopWidth,
                        width: rowPageRect.width,
                    });
                    match = true;
                    rowTopMatch = true;
                }
            } // 左边距

            if (value.realyML == 0) {
                anotherLayout = anotherLayout.length
                    ? anotherLayout
                    : $('<div class="jz_editing_another_layout project_resize_another_layout"></div>');

                if (colPattern.ml > 0) {
                    anotherLayoutTop = match ? layoutTop + paddingTop : layoutTop;
                    anotherLayoutHeight = match
                        ? colPageRect.height - borderTopWidth * 2 - paddingTop
                        : colPageRect.height - borderTopWidth * 2;
                    anotherLayout.css({
                        left: colPageRect.left + borderLeftWidth,
                        top: anotherLayoutTop,
                        height: anotherLayoutHeight,
                        width: paddingLeft,
                    });
                    anthorMatch = true;
                } else if (pattern.pl > 0 && colIndex == 0 && colPattern.ml <= 0) {
                    anotherLayoutTop = rowTopMatch ? rowLayoutTop + rowPaddingTop + rowBorderTopWidth : rowLayoutTop;
                    anotherLayoutHeight = rowTopMatch
                        ? rowPageRect.height - rowBorderTopWidth - rowPaddingTop
                        : rowPageRect.height;
                    anotherLayout.css({
                        left: rowPageRect.left,
                        top: anotherLayoutTop,
                        height: anotherLayoutHeight,
                        width: rowPaddingLeft + rowBorderLeftWidth,
                    });
                    anthorMatch = true;
                }
            } // 右边距

            if (value.realyMR == 0) {
                anotherLayout = anotherLayout.length
                    ? anotherLayout
                    : $('<div class="jz_editing_another_layout project_resize_another_layout"></div>');

                if (colPattern.mr > 0) {
                    anotherLayoutTop = match ? layoutTop + paddingTop : layoutTop;
                    anotherLayoutHeight = match
                        ? colPageRect.height - borderTopWidth * 2 - paddingTop
                        : colPageRect.height - borderTopWidth * 2;
                    anotherLayout.css({
                        left: colPageRect.right - paddingRight - borderRightWidth,
                        top: anotherLayoutTop,
                        height: anotherLayoutHeight,
                        width: paddingRight,
                    });
                    anthorMatch = true;
                } else if (pattern.pr > 0 && colIndex == row.cols.length - 1 && colPattern.mr <= 0) {
                    anotherLayoutTop = rowTopMatch ? rowLayoutTop + rowPaddingTop + rowBorderTopWidth : rowLayoutTop;
                    anotherLayoutHeight = rowTopMatch
                        ? rowPageRect.height - rowBorderTopWidth - rowPaddingTop
                        : rowPageRect.height;
                    anotherLayout.css({
                        left: rowPageRect.right - rowPaddingRight - rowBorderRightWidth,
                        top: anotherLayoutTop,
                        height: anotherLayoutHeight,
                        width: rowPaddingRight + rowBorderRightWidth,
                    });
                    anthorMatch = true;
                }
            }
        }

        if (type == 'rightBottom') {
            if (colPattern.mr > 0) {
                layout.css({
                    left: colPageRect.right - paddingRight - borderRightWidth,
                    top: colPageRect.top + borderTopWidth,
                    height: colPageRect.height - borderTopWidth * 2,
                    width: paddingRight,
                });
                match = true;
            } else if (pattern.pr > 0 && colIndex == row.cols.length - 1 && colPattern.mr <= 0) {
                layout.css({
                    left: rowPageRect.right - rowPaddingRight - rowBorderRightWidth,
                    top: rowPageRect.top,
                    height: rowPageRect.height,
                    width: rowPaddingRight + rowBorderRightWidth,
                });
                match = true;
            }
        }
    } // 单列

    function createSingleResizeLayout() {
        let layoutWidth = parseInt(rowDom.css('paddingRight')) + parseInt(rowDom.css('borderRightWidth'));

        if (type == 'left') {
            if (pattern.pl > 0 && colIndex == 0 && value.realyML == 0) {
                layout.css({
                    left: rowPageRect.left,
                    top: rowPageRect.top,
                    height: rowPageRect.height,
                    width: parseInt(rowDom.css('paddingLeft')) + parseInt(rowDom.css('borderLeftWidth')),
                });
                match = true;
            } else if (pattern.pr > 0 && value.realyMR == 0) {
                layout.css({
                    left: rowPageRect.right - layoutWidth,
                    top: rowPageRect.top,
                    height: rowPageRect.height,
                    width: layoutWidth,
                });
                match = true;
            }
        }

        if (type == 'top' && pattern.pt > 0 && prevProjectId === 0) {
            layout.css({
                left: rowPageRect.left,
                top: rowPageRect.top,
                height: parseInt(rowDom.css('paddingTop')) + parseInt(rowDom.css('borderTopWidth')),
                width: rowPageRect.width,
            });
            match = true;
        }

        if (type == 'rightBottom' && pattern.pr > 0 && colIndex == row.cols.length - 1) {
            layout.css({
                left: rowPageRect.right - layoutWidth,
                top: rowPageRect.top,
                height: rowPageRect.height,
                width: layoutWidth,
            });
            match = true;
        }

        if (type == 'bottom' && pattern.pb > 0) {
            layout.css({
                left: rowPageRect.left,
                top:
                    rowPageRect.bottom -
                    parseInt(rowDom.css('paddingBottom')) -
                    parseInt(rowDom.css('borderBottomWidth')),
                height: parseInt(rowDom.css('paddingBottom')) + parseInt(rowDom.css('borderBottomWidth')),
                width: rowPageRect.width,
            });
            match = true;
        }

        if (type == 'leftTop') {
            let layoutTop = rowPageRect.top;
            let anotherLayoutTop = 0;
            let layoutHeigth = parseInt(rowDom.css('paddingTop')) + parseInt(rowDom.css('borderTopWidth'));
            let anotherLayoutWidth = parseInt(rowDom.css('paddingLeft')) + parseInt(rowDom.css('borderLeftWidth'));
            let anotherLayoutHeight; // 上边距

            if (pattern.pt > 0 && prevProjectId === 0 && value.realyMT == 0) {
                layout.css({
                    left: rowPageRect.left,
                    top: layoutTop,
                    height: layoutHeigth,
                    width: rowPageRect.width,
                });
                match = true;
            } // 左边距

            if (pattern.pl > 0 && colIndex == 0 && value.realyML == 0) {
                anotherLayoutTop = match ? layoutTop + layoutHeigth : layoutTop;
                anotherLayoutHeight = match
                    ? rowPageRect.height - parseInt(rowDom.css('paddingTop'))
                    : rowPageRect.height;
                anotherLayout = anotherLayout.length
                    ? anotherLayout
                    : $('<div class="jz_editing_another_layout project_resize_layout"></div>');
                anotherLayout.css({
                    left: rowPageRect.left,
                    top: anotherLayoutTop,
                    height: anotherLayoutHeight,
                    width: anotherLayoutWidth,
                });
                anthorMatch = true;
            } // 右边距

            if (pattern.pr > 0 && colIndex == row.cols.length - 1 && value.realyMR == 0) {
                anotherLayoutTop = match ? layoutTop + layoutHeigth : layoutTop;
                anotherLayoutHeight = match
                    ? rowPageRect.height - parseInt(rowDom.css('paddingTop'))
                    : rowPageRect.height;
                anotherLayout = anotherLayout.length
                    ? anotherLayout
                    : $('<div class="jz_editing_another_layout project_resize_layout"></div>');
                anotherLayout.css({
                    left: rowPageRect.right - layoutWidth,
                    top: anotherLayoutTop,
                    height: anotherLayoutHeight,
                    width: anotherLayoutWidth,
                });
                anthorMatch = true;
            }
        }
    }
};

const removeResizeLayout = function () {
    $('.project_resize_layout').remove();
    $('.jz_editing_another_layout').remove();
}; //克隆当前页面

const cloneCurCol = function () {
    getAcctInfo()
        .then((currData) => {
            if (currData.success) {
                var inputAcctName = prompt(
                    '将当前页面克隆到下面输入的网站(ps:输入的账号必须为内部账号)\n\n请输入账号名：'
                );
                commAjaxFunc(inputAcctName, currData);
            }
        })
        .catch((err) => {
            console.error(err);
            Fai.ing('系统异常，请稍后重试', true);
        });

    function commAjaxFunc(inputAcctName, currData) {
        if (inputAcctName == null) {
            return;
        } else if (inputAcctName.trim() == '') {
            alert('输入用户名不能为空！');
        } else {
            var inputAtype = prompt('请输入客户类型：\n\n直销账号为0；  分销账号为1');

            if (inputAtype != 0 && inputAtype != 1) {
                alert('输入客户类型错误, 只能输出0 或者 1！');
                return;
            }

            var inputSiteId = prompt('克隆到的网站ID, 默认是 0');

            if (isNaN(inputSiteId) || inputSiteId < 0 || inputSiteId > 19) {
                alert('输入网站ID错误, 只能输出0 到 19的数字！');
                return;
            }

            getAcctInfo({
                inputAcctName,
                inputAtype,
                inputSiteId,
            })
                .then((inputData) => {
                    if (inputData.success) {
                        if (inputData.aid == currData.aid && inputData.siteId == currData.siteId) {
                            alert('相同网站不能克隆！');
                        } else {
                            if (!inputData.isFaier) {
                                alert('你所输入网站账号不是内部账号，不能克隆！');
                                return;
                            }

                            var confirmStr = [
                                '把企业aid：' +
                                    currData.aid +
                                    '，网站ID：' +
                                    currData.siteId +
                                    '，' +
                                    currData.atypeName +
                                    '，' +
                                    currData.aacct +
                                    currData.siteVerName +
                                    '(原网站)',
                                '\n\n克隆\n\n',
                                '到企业aid：' +
                                    inputData.aid +
                                    '，网站ID：' +
                                    inputData.siteId +
                                    '，' +
                                    inputData.atypeName +
                                    '，' +
                                    inputData.aacct +
                                    inputData.siteVerName +
                                    '(目标网站)',
                            ];
                            var sure = window.confirm(confirmStr.join(''));

                            if (sure) {
                                var sureAgain = window.confirm(
                                    '确定克隆到id：' +
                                        inputData.aid +
                                        '；' +
                                        '网站ID：' +
                                        inputData.siteId +
                                        '；' +
                                        inputData.atypeName +
                                        '账号：' +
                                        inputData.aacct +
                                        '？必须检查清楚！！'
                                );
                            }

                            if (sureAgain) {
                                cloneCol({
                                    toAid: inputData.aid,
                                    toSiteId: inputData.siteId,
                                    colId: window._store.state.colId,
                                })
                                    .then((data) => {
                                        if (data.success) {
                                            alert('克隆成功!');
                                            window.location.reload();
                                        } else {
                                            if (data.msg != null) {
                                                alert(data.msg);
                                            } else {
                                                alert('系统错误，克隆失败！');
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        Fai.ing('系统异常，请稍后重试', true);
                                    });
                            }
                        }
                    } else {
                        if (inputData.msg == null) {
                            alert('系统出错，克隆失败！');
                        } else {
                            alert(inputData.msg);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Fai.ing('系统异常，请稍后重试', true);
                });
        }
    }
};

const getComputedBg = function (bg, isLazy) {
    let bGstyle = {},
        repeatDict = ['repeat', 'no-repeat', 'repeat-x', 'repeat-y'],
        positionDict = [
            'top left',
            'top center',
            'top right',
            'center left',
            'center center',
            'center right',
            'bottom left',
            'bottom center',
            'bottom right',
        ],
        sizeDict = ['auto', '100% 100%', '100%', 'cover', 'contain'];

    if (bg.y === 0) {
        bGstyle.background = '';
    } else if (bg.y == 1) {
        bGstyle.background = 'none';
    } else if (bg.y == 2) {
        if (bg.d == 0) {
            //纯色
            bGstyle.backgroundColor = bg.c;
        } else if (bg.d == 1) {
            //渐变色
            bGstyle.backgroundImage = '-webkit-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = '-o-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = '-moz-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = 'linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
        } else if (bg.d == 2) {
            if (bg.f) {
                bGstyle.backgroundSize = sizeDict[bg.s];
                if (!isLazy) {
                    bGstyle.backgroundImage = 'url(' + bg.path + ')';
                }
                bGstyle.backgroundColor = bg.c;
                if (bg.s == 4) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 3) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 2) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else {
                    bGstyle.backgroundRepeat = repeatDict[bg.r];
                }
                if (bg.s == 4) {
                    bGstyle.backgroundPosition = 'center';
                } else if (bg.s == 3) {
                    bGstyle.backgroundPosition = 'center';
                } else {
                    bGstyle.backgroundPosition = positionDict[bg.p];
                }
            }
        } else if (bg.d == 3) {
            // image
            if (bg.f) {
                if (bg.tc || !isLazy) {
                    bGstyle.backgroundImage = 'url(' + bg.path + ')';
                }
            }

            if (bg.tc) {
                bGstyle.backgroundImage =
                    (bGstyle.backgroundImage ? bGstyle.backgroundImage + ',' : '') +
                    'linear-gradient(' +
                    bg.a +
                    'deg, ' +
                    bg.c +
                    ' ' +
                    bg.cp +
                    '%, ' +
                    bg.tc +
                    ' ' +
                    bg.tcp +
                    '%)';
            } // color

            if (!bg.tc) {
                bGstyle.backgroundColor = bg.c;
            } // size

            bGstyle.backgroundSize = sizeDict[bg.s]; // repeat

            if (bg.s == 4) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 3) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 2) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else {
                bGstyle.backgroundRepeat = repeatDict[bg.r];
            } // position

            if (bg.s == 4) {
                bGstyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 3) {
                bGstyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 1) {
                bGstyle.backgroundPosition = positionDict[3];
            } else {
                bGstyle.backgroundPosition = positionDict[bg.p];
            }
        }
    }

    return bGstyle;
};

const getComputedShadow = (shadowData = {}) => {
    const shadowStyle = {};

    if ([1, 2].includes(shadowData.t)) {
        shadowStyle.boxShadow = {
            1: 'none',
            2: `${shadowData.x}px ${shadowData.y}px ${shadowData.b}px ${shadowData.c}`,
        }[shadowData.t];
    }

    return shadowStyle;
};

const getComputedBorder = (borderData = {}) => {
    const borderStyle = {};
    const borderStyleList = ['solid', 'dotted', 'dashed'];

    if (borderData.y == 1) {
        borderStyle.border = 'none';
    } else if (borderData.y == 2) {
        let borderStr = `${borderData.w}px ${borderStyleList[borderData.s]} ${borderData.c}`;
        borderData.t && (borderStyle.borderTop = borderStr);
        borderData.r && (borderStyle.borderRight = borderStr);
        borderData.b && (borderStyle.borderBottom = borderStr);
        borderData.l && (borderStyle.borderLeft = borderStr);
    }

    return borderStyle;
};

const getFirstGradientColor = function (color) {
    let ret = color || '';

    if (color) {
        if (color.indexOf('linear-gradient') > -1) {
            const re = /.*linear-gradient\(.*?(rgb[a]?\(.*?\)).*?\).*/.exec(color);

            if (re) {
                ret = re[1];
            }
        }
    }

    return ret;
};

const operatingProtect = function () {
    if (!$('#popupBgJzOperatingProtect').length) {
        Fai.bg('JzOperatingProtect', 0, 9100, 'jz_operating_protect');
        if (!window._store.state.row.rowEditing) {
            Root.vm.hoverToolbar.splice(0, Root.vm.hoverToolbar.length);
        }
    }
};

const removeOperatingProtect = function () {
    $('#popupBgJzOperatingProtect').remove();
};

const onbeforeunload = function () {
    if (typeof window.winBeforeunload == 'function') {
        window.winBeforeunload();
    } //有做过修改

    //有做过修改
    if (window._store.state.manage.changedFlag.page) {
        return '您的修改尚未保存，确定要离开吗？';
    }
};

/**
 * JZ.showVisitLimitPopup() 网站关闭时出现遮罩层及弹窗
 * JZ.removeVisitLimitPopup() 关闭遮罩层及弹窗
 **/

const removeVisitLimitPopup = function () {
    $('.limitPopupBackGroud').remove();
    $('.limitPopup').remove();
    $(document.body).css('overflow-y', 'scroll');
    $('#siteCloseAppealBtn').attr('disabled', 'false');
    $('#siteCloseAppealBtn').css('cursor', 'pointer'); //发请求，设置不出现弹窗

    setSiteCloseNoPopup();
};

const showVisitLimitPopup = function () {
    $(document.body).css('overflow-y', 'hidden');
    $('#siteCloseAppealBtn').attr('disabled', 'true');
    $('#siteCloseAppealBtn').css('cursor', 'not-allowed');

    function initLimitPopup() {
        var limitPopuphtml = '';
        limitPopuphtml +=
            '<div class="limitPopupBackGroud" style="width: 100%;height: 100%;position: absolute;opacity: 0.4;left: 0;top: 0;z-index: 9033;background: black;top:45px;"></div>';
        limitPopuphtml +=
            '<div class="limitPopup" style="position: fixed;width: 100%;height: 100%; top: 0;z-index: 9999;">';
        limitPopuphtml += '<div style="width:400px;height:213px;margin:0 auto;margin-top: 39px;">';
        limitPopuphtml +=
            '<div style="margin: 0 auto; width: 2px; border-width: 12px;border-style: solid;border-color: transparent transparent white;"></div>';
        limitPopuphtml += '<div style="width:400px;height:200px;background:#FFFFFF;border-radius:4px;">';
        limitPopuphtml += '<div style="margin: 0 auto;width:304px;padding-top:49px;">';
        limitPopuphtml +=
            '<span style="text-align:center;font-size:16px;">你的访问已被限制，请整改完成后申请开通</span>';
        limitPopuphtml += '</div>';
        limitPopuphtml +=
            '<div class="limitPopupButton" style="margin: 0 auto;width:170px;margin-top:39px;height:51px;background:#E4E4E4;border-radius:26px;font-size: 16px;line-height:51px;text-align:center;color: #999999;cursor: not-allowed;"  onclick="JZ.removeVisitLimitPopup()">';
        limitPopuphtml += '知道了<span class="limitPopupCount">（10S）</span>';
        limitPopuphtml += '</div>';
        limitPopuphtml += '</div>';
        limitPopuphtml += '</div>';
        limitPopuphtml += '</div>';
        $(document.body).append(limitPopuphtml);
    }

    initLimitPopup();
    var count = 10;

    function countDown() {
        var limitPopupCount = $('.limitPopupCount');
        var limitPopupButton = $('.limitPopupButton');
        count--;
        limitPopupCount.html('（' + count + 'S）');

        if (count <= 0) {
            limitPopupButton.css({
                background: '#5874D8',
                color: '#FFFFFF',
                cursor: 'pointer',
            });
            limitPopupCount.remove();
            clearInterval(countDownInter);
        }
    }

    var countDownInter = setInterval(countDown, 1000);
};
/**
 *@obj JZ.moduleAlignLine 模块辅助对齐线
 *@version {create} 2018.08.14 from Site.absAlignLine
 **/

let moduleAlignLine = {
    resize: {},
    position: {},
    commFunc: {},
}; //resize

var resize = moduleAlignLine.resize;
resize.showGap = 5;
resize.inTopLine = false;
resize.inRightLine = false;
resize.inBottomLine = false;
resize.inLeftLine = false; // 参数  helper = 当前jq节点,  directionArr = 辅助线生成方向,  compareForms = 需要对比的dom, isAdsorb = 辅助线生成时,是否需要让dom自动对齐。

resize.init = function (helper, directionArr, compareForms, isAdsorb) {
    // compareForms 获取需要对比的元素
    // elemRectData 当前拖拉的dom的位置信息
    // compareData  获取对比元素的位置信息
    compareForms = compareForms || Fai.top.$('.form:visible').not(helper[0]);
    let elemRectData = helper[0].getBoundingClientRect();
    var flag = compareForms ? true : false;
    moduleAlignLine.commFunc.hideAlignLine();
    resize.compareData = getCompareData(compareForms, flag);
    resize.compareAlignForm(elemRectData, resize.compareData, helper, directionArr, isAdsorb);
};

resize.compareAlignForm = function (elemRectData, compareData, helper, directionArr, isAdsorb) {
    //resizeElem 边与 此时循环比较Elem边的距离差
    var gapTop = 0,
        gapRight = 0,
        gapBottom = 0,
        gapLeft = 0,
        //记录匹配高度
        saveTop = 0,
        saveRight = 0,
        saveBottom = 0,
        saveLeft = 0,
        //将所有匹配边的点进行数组保存，喜欢结束后依据此数据决定辅助线的长度或高度
        obtainTop = [elemRectData.left, elemRectData.right],
        obtainRight = [elemRectData.top, elemRectData.bottom],
        obtainBottom = [elemRectData.left, elemRectData.right],
        obtainLeft = [elemRectData.top, elemRectData.bottom];

    for (var i in compareData) {
        var nowData = compareData[i];
        gapTop = elemRectData.top - nowData.top;
        gapRight = elemRectData.right - nowData.right;
        gapBottom = elemRectData.bottom - nowData.bottom;
        gapLeft = elemRectData.left - nowData.left; //进行匹配比较

        if ($.inArray('top', directionArr) != -1 && Math.abs(gapTop) < resize.showGap) {
            saveTop = nowData.top;
            obtainTop.push(nowData.left, nowData.right);
            resize.inTopLine = true;
        }

        if ($.inArray('right', directionArr) != -1 && Math.abs(gapRight) < resize.showGap) {
            if (isAdsorb) {
                var width = compareData[i].right - helper[0].getBoundingClientRect().left;
                helper.outerWidth(width);
                return;
            }

            saveRight = nowData.right;
            obtainRight.push(nowData.top, nowData.bottom);
            resize.inRightLine = true;
        }

        if ($.inArray('bottom', directionArr) != -1 && Math.abs(gapBottom) < resize.showGap) {
            saveBottom = nowData.bottom;
            obtainBottom.push(nowData.left, nowData.right);
            resize.inBottomLine = true;
        }

        if ($.inArray('left', directionArr) != -1 && Math.abs(gapLeft) < resize.showGap) {
            saveLeft = nowData.left;
            obtainLeft.push(nowData.top, nowData.bottom);
            resize.inLeftLine = true;
        }
    } //进行吸附

    if (resize.inTopLine) {
        moduleAlignLine.commFunc.calLineSize('top', obtainTop);
        moduleAlignLine.commFunc.setAbsAlignLine('Top', saveTop); // var helperTop = parseInt(helper.css("top").replace("px", "")) || 0;
        // helper.css("top", (helperTop - saveGapTop) + "px");

        resize.inTopLine = false; // resize.showGap = 2;
    }

    if (resize.inRightLine) {
        moduleAlignLine.commFunc.calLineSize('right', obtainRight);
        moduleAlignLine.commFunc.setAbsAlignLine('Right', saveRight); // helper.css("width", (helper.width() - saveGapRight) + "px");

        resize.inRightLine = false; // resize.showGap = 3;
    }

    if (resize.inBottomLine) {
        moduleAlignLine.commFunc.calLineSize('bottom', obtainBottom);
        moduleAlignLine.commFunc.setAbsAlignLine('Bottom', saveBottom); // helper.css("height", (helper.height() - saveGapBottom) + "px");

        resize.inBottomLine = false; // resize.showGap = 3;
    }

    if (resize.inLeftLine) {
        moduleAlignLine.commFunc.calLineSize('left', obtainLeft);
        moduleAlignLine.commFunc.setAbsAlignLine('Left', saveLeft); // var helperLeft = parseInt(helper.css("left").replace("px", "")) || 0;
        // helper.css("left", (helperLeft - saveGapLeft)+ "px");

        resize.inLeftLine = false; // resize.showGap = 2;
    }
}; //position

var position = moduleAlignLine.position;
position.showGap = 2;

position.init = function (helper, projectResize) {
    var compareForms = Fai.top.$('.form:visible').not(helper[0]),
        elemRectData = helper[0].getBoundingClientRect(); //包含他的列也参与对齐

    compareForms = compareForms.add(helper.parents('.jz_web_col:eq(0)'));
    moduleAlignLine.commFunc.hideAlignLine();
    position.compareData = getCompareData(compareForms);
    position.compareAlignForm(elemRectData, position.compareData, helper, projectResize);
};

position.compareAlignForm = function (elemRectData, compareData) {
    var d = position.showGap;
    var x1 = elemRectData.left,
        width = elemRectData.width,
        x2 = x1 + width,
        x3 = (x1 + x2) / 2,
        y1 = elemRectData.top,
        height = elemRectData.height,
        y2 = y1 + height,
        y3 = (y1 + y2) / 2; //辅助线数据缓存容器

    var alignLeft = [y1, y2],
        alignRight = [y1, y2],
        alignVertical = [y1, y2],
        alignTop = [x1, x2],
        alignBottom = [x1, x2],
        alignLevel = [x1, x2],
        saveTop = 0,
        saveRight = 0,
        saveLevel = 0,
        saveBottom = 0,
        saveLeft = 0,
        saveVertical = 0;

    for (var i in compareData) {
        var elem = compareData[i],
            l = elem.left,
            r = l + elem.width,
            t = elem.top,
            b = t + elem.height,
            level = (t + b) / 2,
            vertical = (l + r) / 2;
        var options = {
            l,
            r,
            t,
            b,
            level,
            vertical,
            ls: Math.abs(l - x1) <= d,
            ls2: Math.abs(l - x2) <= d,
            ls3: Math.abs(l - x3) <= d,
            rs: Math.abs(r - x1) <= d,
            rs2: Math.abs(r - x2) <= d,
            rs3: Math.abs(r - x3) <= d,
            ts: Math.abs(t - y1) <= d,
            ts2: Math.abs(t - y2) <= d,
            ts3: Math.abs(t - y3) <= d,
            bs: Math.abs(b - y1) <= d,
            bs2: Math.abs(b - y2) <= d,
            bs3: Math.abs(b - y3) <= d,
            levelS: Math.abs(level - y1) <= d,
            levelS2: Math.abs(level - y2) <= d,
            levelS3: Math.abs(level - y3) <= d,
            verticalS: Math.abs(vertical - x1) <= d,
            verticalS2: Math.abs(vertical - x2) <= d,
            verticalS3: Math.abs(vertical - x3) <= d,
            selector: i,
        };
        guidesDrawLine(options);
    }

    function guidesDrawLine(options) {
        var l = options.l,
            r = options.r,
            t = options.t,
            b = options.b,
            level = options.level,
            vertical = options.vertical,
            ls = options.ls,
            ls2 = options.ls2,
            ls3 = options.ls3,
            rs = options.rs,
            rs2 = options.rs2,
            rs3 = options.rs3,
            ts = options.ts,
            ts2 = options.ts2,
            ts3 = options.ts3,
            bs = options.bs,
            bs2 = options.bs2,
            bs3 = options.bs3,
            levelS = options.levelS,
            levelS2 = options.levelS2,
            levelS3 = options.levelS3,
            verticalS = options.verticalS,
            verticalS2 = options.verticalS2,
            verticalS3 = options.verticalS3;

        if (ls) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0, left: l}).left;
            alignLeft.push(t, b);
            saveLeft = l; // projectResize.marginLeft = projectResize.marginLeft + (l - x1);
            // projectResize.marginRight = projectResize.marginRight - (l - x1);
        }

        if (ls2) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0,left: l - width}).left;
            alignRight.push(t, b);
            saveRight = l;
        }

        if (ls3) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0, left: l - width/2}).left;
            alignVertical.push(t, b);
            saveVertical = l;
        }

        if (rs) {
            // ui.position.left = inst._convertPositionTo("relative", { top: 0, left: r}).left;
            alignLeft.push(t, b);
            saveLeft = r;
        }

        if (rs2) {
            // ui.position.left = inst._convertPositionTo("relative", { top: 0, left: r - width}).left;
            alignRight.push(t, b);
            saveRight = r;
        }

        if (rs3) {
            // ui.position.left = inst._convertPositionTo("relative", { top: 0, left: r - width/2}).left;
            alignVertical.push(t, b);
            saveVertical = r;
        }

        if (verticalS) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0, left: vertical}).left;
            alignLeft.push(t, b);
            saveLeft = vertical;
        }

        if (verticalS2) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0, left: vertical - width}).left;
            alignRight.push(t, b);
            saveRight = vertical;
        }

        if (verticalS3) {
            // ui.position.left = inst._convertPositionTo("relative", {top: 0, left: vertical - width/2}).left;
            alignVertical.push(t, b);
            saveVertical = vertical;
        }

        if (ts) {
            // ui.position.top = inst._convertPositionTo("relative", { top: t, left: 0 }).top;
            alignTop.push(l, r);
            saveTop = t;
        }

        if (ts2) {
            // ui.position.top = inst._convertPositionTo("relative", { top: t - height, left: 0 }).top;
            alignBottom.push(l, r);
            saveBottom = t;
        }

        if (ts3) {
            // ui.position.top = inst._convertPositionTo("relative", { top: t - height/2, left: 0 }).top;
            alignLevel.push(l, r);
            saveLevel = t;
        }

        if (bs) {
            // ui.position.top = inst._convertPositionTo("relative", { top: b, left: 0 }).top;
            alignTop.push(l, r);
            saveTop = b;
        }

        if (bs2) {
            // ui.position.top = inst._convertPositionTo("relative", { top: b - height, left: 0 }).top;
            alignBottom.push(l, r);
            saveBottom = b;
        }

        if (bs3) {
            // ui.position.top = inst._convertPositionTo("relative", { top: b - height/2, left: 0 }).top;
            alignLevel.push(l, r);
            saveLevel = b;
        }

        if (levelS) {
            // ui.position.top = inst._convertPositionTo("relative", {top: level, left: 0}).top;
            alignTop.push(l, r);
            saveTop = level;
        }

        if (levelS2) {
            // ui.position.top = inst._convertPositionTo("relative", {top: level - height, left: 0}).top;
            alignBottom.push(l, r);
            saveBottom = level;
        }

        if (levelS3) {
            // ui.position.top = inst._convertPositionTo("relative", {top: level - height/2, left: 0}).top;
            alignLevel.push(l, r);
            saveLevel = level;
        }
    } //计算辅助线高或宽, 并显示辅助线

    var alignLineFunc = moduleAlignLine.commFunc;

    if (alignRight.length > 2) {
        alignLineFunc.calLineSize('right', alignRight);
        alignLineFunc.setAbsAlignLine('Right', saveRight);
    }

    if (alignLeft.length > 2) {
        alignLineFunc.calLineSize('left', alignLeft);
        alignLineFunc.setAbsAlignLine('Left', saveLeft);
    }

    if (alignBottom.length > 2) {
        alignLineFunc.calLineSize('bottom', alignBottom);
        alignLineFunc.setAbsAlignLine('Bottom', saveBottom);
    }

    if (alignTop.length > 2) {
        alignLineFunc.calLineSize('top', alignTop);
        alignLineFunc.setAbsAlignLine('Top', saveTop);
    }

    if (alignLevel.length > 2) {
        alignLineFunc.calLineSize('level', alignLevel);
        alignLineFunc.setAbsAlignLine('Level', saveLevel);
    }

    if (alignVertical.length > 2) {
        alignLineFunc.calLineSize('vertical', alignVertical);
        alignLineFunc.setAbsAlignLine('Vertical', saveVertical);
    }
}; //获取所有模块的位置信息

function getCompareData(compareForms, flag) {
    var alignFormsData = flag ? [] : {},
        $winH = Fai.top.$(window).height();

    if (!flag) {
        compareForms.each(function () {
            var _this = $(this);

            var _thisId = _this.attr('id');

            if (!_thisId) {
                //兼容列
                var _thisClass = _this.attr('class');

                _thisId = _thisClass.match(/jz_web_col\d+/);
                _thisId = '.' + _thisId;
            } else {
                _thisId = '#' + _thisId;
            }

            if (_thisId) {
                alignFormsData[_thisId] = _this[0].getBoundingClientRect(); //判断是否再可视区中

                if (alignFormsData[_thisId].top >= $winH || alignFormsData[_thisId].bottom <= 0) {
                    return true;
                }
            }
        });
    } else {
        compareForms.each(function (index) {
            var compareData = this.getBoundingClientRect(); //判断是否再可视区中

            if (compareData.top >= $winH || compareData.bottom <= 0) {
                return true;
            }

            alignFormsData[index] = compareData;
        });
    }

    return alignFormsData;
} //隐藏辅助对齐线

moduleAlignLine.commFunc.hideAlignLine = function () {
    Fai.top.$('#absAlignTop, #absAlignRight, #absAlignBottom, #absAlignLeft, #absAlignLevel, #absAlignVertical').hide();
}; //计算辅助线高或宽

moduleAlignLine.commFunc.calLineSize = function (dir, dataArr) {
    var minPoint = Math.min.apply(null, dataArr),
        maxPoint = Math.max.apply(null, dataArr);

    switch (dir) {
        case 'top':
            Fai.top.$('#absAlignTop').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'bottom':
            Fai.top.$('#absAlignBottom').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'level':
            Fai.top.$('#absAlignLevel').css({
                left: minPoint - 15 + 'px',
                width: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'left':
            Fai.top.$('#absAlignLeft').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'right':
            Fai.top.$('#absAlignRight').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        case 'vertical':
            Fai.top.$('#absAlignVertical').css({
                top: minPoint - 15 + 'px',
                height: maxPoint - minPoint + 30 + 'px',
            });
            break;

        default:
    }
}; //生成或定位辅助对齐线

moduleAlignLine.commFunc.setAbsAlignLine = function (direction, pos) {
    var body = Fai.top.$('body');

    if (body.find('.absAlign' + direction).length < 1) {
        var lineHtml = "<div id='absAlign" + direction + "' class='absAlign" + direction + "' ";

        switch (direction) {
            case 'Top':
            case 'Bottom':
            case 'Level':
                lineHtml += "style='top:" + pos + "px;' ";
                break;

            case 'Left':
            case 'Right':
            case 'Vertical':
                lineHtml += "style='right:" + pos + "px;' ";
                break;

            default:
        }

        lineHtml += '></div>';
        body.append(lineHtml);
    } else {
        switch (direction) {
            case 'Top':
                body.find('.absAlign' + direction).css({
                    top: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Bottom':
                body.find('.absAlign' + direction).css({
                    top: pos - 1 + 'px',
                    display: 'block',
                });
                break;

            case 'Level':
                body.find('.absAlign' + direction).css({
                    top: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Left':
                body.find('.absAlign' + direction).css({
                    left: pos + 'px',
                    display: 'block',
                });
                break;

            case 'Right':
                body.find('.absAlign' + direction).css({
                    left: pos - 1 + 'px',
                    display: 'block',
                });
                break;

            case 'Vertical':
                body.find('.absAlign' + direction).css({
                    left: pos + 'px',
                    display: 'block',
                });
                break;

            default:
        }
    }
};

let detectZoom = null;
if (typeof window === 'object') {
    store.state.manageMode && !store.state.manage.isLoginO && (detectZoom = new DetectZoom()); //先关闭检测缩放
}

const createPlaceholderStyle = (baseClass, fontData) => {
    return [
        '::-webkit-input-placeholder',
        ':-moz-placeholder',
        '::-moz-placeholder',
        ':-ms-input-placeholder',
        '::-ms-input-placeholder',
        '::placeholder',
    ].map((placeholderClass) => {
        const fontSize = fontData.s ? `font-size: ${fontData.s}px;` : 'font-size: 13px;';
        const fontFamily = `font-family: ${fontData.f};`;
        const fontStyle = `font-style: ${fontData.i == 1 ? 'italic' : 'normal'};`;
        const fontWeight = `font-weight: ${fontData.w == 1 ? 'bold' : 'normal'};`;
        const textDecoration = fontData.d == 1 ? 'text-decoration: underline;' : '';
        const color = fontData.c ? `color: ${fontData.c} !important;` : '';
        return `${baseClass} input${placeholderClass}, ${baseClass} textarea${placeholderClass} { ${fontSize}${fontFamily}${fontStyle}${fontWeight}${textDecoration}${color} }`;
    });
};

const updatePlaceholderStyle = ({ id = '', baseClass, pcFontData = {}, mobiFontData = {} }) => {
    if (!id) {
        return;
    }

    const styleId = `placeholderStyle_${id}`;
    let $style = $(`#${styleId}`);
    let styleContent = '';

    if (pcFontData.y === 1) {
        styleContent += createPlaceholderStyle(`.jz_screen_pc ${baseClass}`, pcFontData);
    } // mobi的文本数据默认是继承pc的，但是不会继承字号

    if (mobiFontData.y === 0 && pcFontData.y !== 0) {
        mobiFontData = deepExtendClone(pcFontData);
        mobiFontData.s = '';
    }

    if (mobiFontData.y === 1) {
        styleContent += createPlaceholderStyle(`.jz_screen_mobi ${baseClass}`, mobiFontData);
    }

    if (styleContent === '') {
        $style.remove();
        return;
    }

    if ($style.length === 0) {
        $(`<style id="${styleId}">`).html(styleContent).appendTo('head');
    } else {
        $style.html(styleContent);
    }
};

export {
    creatResizeLayout,
    removeResizeLayout,
    getComputedBg,
    getComputedTextColor,
    getFirstGradientColor,
    getComputedBorder,
    operatingProtect,
    removeOperatingProtect,
    onbeforeunload,
    removeVisitLimitPopup,
    showVisitLimitPopup,
    detectZoom,
    moduleAlignLine,
    getComputedShadow,
    updatePlaceholderStyle,
    cloneCurCol,
};
