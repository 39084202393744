import { ServiceFactory } from './factory';

const SERVICE_INTERFACE = 'banner';

/**
 * 从 JzBannerContent.vue 提取
 */
export class BannerService {
    constructor(globalInfo, device) {
        this._info = globalInfo;
        this._pattern = globalInfo.pattern;
        this.device = device;
    }

    /**
     * 根据独立横幅的开关获取独立横幅或全局横幅数据
     * @returns Banner Data
     */
    get bannerData() {
        return this._pattern.independent.self && this._pattern.independent.selfBanner && this._pattern.independent.open
            ? this._pattern.independent.banner
            : this._info.banner;
    }

    /**
     *
     * @param {String} device - mobi | pc
     */
    setDevice(device) {
        this.device = device;
    }

    /**
     * @returns {Boolean} - 是否混合横幅，视频和图片
     */
    get isMixedBanner() {
        return this.bannerData.t === 2;
    }

    get isPC() {
        return this.device === 'pc';
    }

    get isVideoBanner() {
        return this.bannerData.t === 1 && this.isPC;
    }

    /**
     * 获取当前使用的横幅列表数据
     */
    getBanners() {
        if (this.isPC) {
            // 使用电脑的图片列表
            if (this.isMixedBanner) {
                if (!this.isPC) {
                    const banners = this.bannerData.nmbl.filter((b) => b.st !== 1);
                    if (banners.length === 0) {
                        return [this.bannerData.bl[0]];
                    }
                    return banners;
                }
                return this.bannerData.nmbl;
            }
            return this.bannerData.bl;
        } else {
            return this.bannerData.mbl;
        }
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: BannerService,
});
