import { deepFreeze } from '@jz/utils';
/**
 * @author mackie
 * @private
 * @description
 * 定义模块名字跟svgclass的数据 原先的JZ.getSvgName Comm.getModuleStyleName
 * 都从这去获取 避免添加模块/漏加上
 * {
 *  id: module.style,
 *  svgClass: svg icon class(指添加模块面板的svg),
 *  moduleComponentName: 模块的组件名,
 *  moduleCnName: 模块中文名（目前就编辑面板的标题）,
 *  sysId: module.id (系统模块id), 系统模块通过模块id获取
 * }
 */
const MODULE_STYLE_DATA = deepFreeze([
    { id: -1, svgClass: 'fullmeasurePack' },
    {
        id: 1,
        svgClass: 'rich',
        moduleComponentName: 'rich',
        moduleCnName: '图文展示',
    },
    {
        id: 2,
        svgClass: 'carousel_photos',
        moduleComponentName: 'carouselPhotos',
        moduleCnName: '轮播多图',
    },
    {
        id: 3,
        svgClass: 'list_photos',
        moduleComponentName: 'listPhotos',
        moduleCnName: '列表多图',
    },
    { id: 4, moduleComponentName: 'newsDetail', moduleCnName: '文章详情' },
    { id: 5, moduleComponentName: 'newsResult', moduleCnName: '文章筛选' },
    {
        id: 6,
        svgClass: 'simpleText',
        moduleComponentName: 'textModule',
        moduleCnName: '文本',
    },
    {
        id: 7,
        svgClass: 'floatImg',
        moduleComponentName: 'imageModule',
        moduleCnName: '图片',
    },
    {
        id: 8,
        svgClass: 'floatBtn',
        moduleComponentName: 'buttonModule',
        moduleCnName: '按钮',
    },
    { id: 9, moduleComponentName: 'productDetail', moduleCnName: '产品详情' },
    { id: 10, moduleComponentName: 'productResult', moduleCnName: '产品筛选' },
    { id: 11, moduleComponentName: 'memberSignup', moduleCnName: '会员注册' },
    { id: 12, moduleComponentName: 'memberLogin', moduleCnName: '会员登录' },
    { id: 13, moduleComponentName: 'memberCenter', moduleCnName: '会员中心' },
    {
        id: 14,
        svgClass: 'newsgroup',
        moduleComponentName: 'categoryNav',
        moduleCnName: '分类导航',
    },
    {
        id: 15,
        svgClass: 'photoGroup',
        moduleComponentName: 'photoGroupModule',
        moduleCnName: '图册目录',
    },
    {
        id: 16,
        svgClass: 'product',
        moduleComponentName: 'productList',
        moduleCnName: '产品展示',
    },
    {
        id: 17,
        svgClass: 'article',
        moduleComponentName: 'newsList',
        moduleCnName: '文章列表',
    },
    {
        id: 18,
        svgClass: 'productNav',
        moduleComponentName: 'categoryNav',
        moduleCnName: '分类导航',
    },
    {
        id: 19,
        svgClass: 'map',
        moduleComponentName: 'onlineMap',
        moduleCnName: '在线地图',
    },
    {
        id: 20,
        svgClass: 'flv',
        moduleComponentName: 'flv',
        moduleCnName: '在线视频',
    },
    {
        id: 21,
        svgClass: 'siteForm',
        moduleComponentName: 'onlineForm',
        moduleCnName: '在线表单',
    },
    { id: 22, moduleComponentName: 'msgBoard', moduleCnName: '留言栏目' },
    {
        id: 23,
        svgClass: 'msgSubmit',
        moduleComponentName: 'moduleMsgBoard',
        moduleCnName: '留言提交',
    },
    {
        id: 24,
        svgClass: 'customSearch',
        moduleComponentName: 'customSearch',
        moduleCnName: '信息查询',
    },
    {
        id: 25,
        moduleComponentName: 'customSearchResult',
        moduleCnName: '信息查询结果',
    },
    {
        id: 26,
        svgClass: 'iframe',
        moduleComponentName: 'iframe',
        moduleCnName: '嵌入页面',
    },
    {
        id: 27,
        svgClass: 'panelFile',
        moduleComponentName: 'fileDownload',
        moduleCnName: '文件下载',
    },
    {
        id: 28,
        svgClass: 'code',
        moduleComponentName: 'pluginCode',
        moduleCnName: '插件代码',
    },
    {
        id: 29,
        svgClass: 'siteSearch',
        moduleComponentName: 'siteSearch',
        moduleCnName: '全站搜索',
    },
    { id: 30, moduleComponentName: 'searchResult', moduleCnName: '搜索结果' },
    {
        id: 31,
        svgClass: 'serviceOnline',
        moduleComponentName: 'onlineService',
        moduleCnName: '在线客服',
    },
    {
        id: 32,
        svgClass: 'tabPackHorizonal',
        moduleComponentName: 'tabpack',
        moduleCnName: '标签模块',
    },
    {
        id: 33,
        svgClass: 'icons',
        moduleComponentName: 'iconCombination',
        moduleCnName: '图标组合',
    },
    {
        id: 34,
        svgClass: 'current_position',
        moduleComponentName: 'currentPosition',
        moduleCnName: '当前位置',
    },
    {
        id: 35,
        svgClass: 'dynamicNumber',
        moduleComponentName: 'dynamicNumber',
        moduleCnName: '动态数字',
    },
    {
        id: 36,
        moduleComponentName: 'photoGroupDetail',
        moduleCnName: '图册详情',
        sysId: 12,
    },
    {
        id: 37,
        svgClass: 'timeline',
        moduleComponentName: 'timeline',
        moduleCnName: '时间轴',
    },
    {
        id: 38,
        svgClass: 'friendLink',
        moduleComponentName: 'friendLink',
        moduleCnName: '友情链接',
    },
    {
        id: 39,
        svgClass: 'categoryNav',
        moduleComponentName: 'categoryNav',
        moduleCnName: '分类导航',
    },

    { sysId: 150, moduleComponentName: 'nav' },
    { sysId: 151, moduleComponentName: 'Footnav' },
    { sysId: 152, moduleComponentName: 'Suspendserv' },
]);

/**
 * @function name - getModuleTargetName
 * @param param0 {Object}
 * @description
 * {
 *  id: 指需要获取数据的模块id或者模块style,
 *  key: 指MODULE_STYLE_DATA数据根据模块id获取（sysId） 还是模块style获取 （id）,
 *  targetKey: 指需要获取的数据的对象属性名
 * }
 * 具体可看MODULE_STYLE_DATA 具体内容
 */
export const getModuleTargetName = ({ id, key = 'id', targetKey = 'moduleComponentName' }) =>
    MODULE_STYLE_DATA.filter((value) => value[key] == Number(id))?.[0]?.[targetKey] || 'none';
