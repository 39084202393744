//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
const {
    extend,
    getComputedTextColor,
    getStyleDataByDevice,
    getWrapStyle,
    bindFlag,
    getTextAlignStyle,
    isNewModuleAfter20210707,
} = Comm;
import { managePhotoGroupSort, addPhotoGroup, selectPhotoGroupMenuItem } from '@shared/manage/photoGroup/index.js';
import { PhotoGroupModule } from '@shared/visitor/modulePhotoGroup/index.js';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { animationMixin } from '../animationMixin';
import { photoSettingMixin } from '../photoSettingMixin';
const manageUse = {
    components: {},
};
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
import { getPhotoList } from '@/api/photo/index.js';

export default {
    name: 'PhotoGroupModule',
    components: {
        ImageMainViewer,
        ...manageUse.components,
        PaginationComponent,
        ImageComponent,
    },
    mixins: [slidesshowDataMixin, animationMixin, photoSettingMixin],
    style: 15,
    props: {
        id: {
            type: Number,
            default: 0,
        },
        module: {
            type: Object,

            default() {
                return {};
            },
        },
    },

    data() {
        const data = {
            currentPage: 1,
            hasAnimated: false,
            groundIndex: 0,
            isFixIe: false,
            photoGroupList: [],
        };
        const isServer = typeof window === 'undefined';
        if (isServer || !window._store.state.manageMode) {
            data.photoGroupList = this.module.photoGroupList;
            data.currentPage = this.module.initPage;
        }
        return data;
    },

    computed: {
        ...Vuex.mapState(['jzVersion']),
        photoStyle() {
            const style = {};
            if (!this.isFixIe) {
                switch (this.curPpst) {
                    case 1:
                        style['object-fit'] = 'cover';
                        style['object-position'] = 'center';
                        break;
                    case 2:
                        style['object-fit'] = 'none';
                        style['object-position'] = 'center';
                        break;
                    case 3:
                        style['object-fit'] = 'contain';
                        style['object-position'] = 'center';
                        break;
                }
            }
            return style;
        },
        themeColor() {
            return this.$store.state.responsiveInfo.themeColor;
        },
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-图册-图册详情',
                    jz_version: this.jzVersion,
                },
            };
        },
        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },

        nameStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnf,
                mobiData: this.mobiPrivatePattern.pnf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnl,
                mobiData: this.mobiPrivatePattern.pnl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            const descAlign = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pna,
                mobiData: this.mobiPrivatePattern.pna,
                isMobi: this.device === 'mobi',
            });
            extend(style, getTextAlignStyle(descAlign));
            return style;
        },

        flagPagination: bindFlag(0x2),

        isNewPhotoSlides() {
            return this.module.commProp.ps.t === 1;
        },

        previewSrcList() {
            const curGroup = this.displayPhotoGroupList[this.groundIndex] || {},
                { photoList = [], previewSrcList } = curGroup,
                _previewSrcList = previewSrcList ? previewSrcList : [];

            if (!_previewSrcList.length && this.isNewPhotoSlides) {
                photoList.forEach((value) => {
                    let _preview = {};
                    _preview.src = value.picPath;
                    _preview.title = value.name || '';
                    _preview.description = value.basic;

                    _previewSrcList.push(_preview);
                });
            }

            return _previewSrcList;
        },

        paginationPageCount() {
            const totalSize = this.photoGroupList.length;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },

        paginationPageSize() {
            return this.module.prop2;
        },

        paginationStyleIndex() {
            return this.module.prop4;
        },

        activeButtonText() {
            return this.useGroupSort ? '选择分类' : '添加图册';
        },

        buttonText() {
            return this.useGroupSort ? '管理分类' : '选择图册';
        },

        useGroupSort: bindFlag(0x1),

        //使用图册分类筛选
        style() {
            return this.module.prop0;
        },

        styleClass() {
            const _class = {
                photo_group_new_module_after_20210707: this.isNewModuleAfter20210707,
            };

            switch (this.style) {
                case 0:
                    _class['photo_group_basic_style'] = true;
                    break;

                case 1:
                    _class['photo_group_slider_style'] = true;
                    break;

                case 2:
                    _class['photo_group_marquee_style'] = true;
                    break;
            }

            return _class;
        },

        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },

        photosInRow() {
            var len = 4;

            if (this.isMobi) {
                len = 2; // 默认一行2个

                if (this.pattern.prlt === 1) {
                    if (this.pattern.prl === 3) {
                        len = 1;
                    }
                }
            } else {
                if (this.pattern.prlt === 1) {
                    len = this.pattern.prl;
                }
            }

            return len;
        },

        cardStyle() {
            const style = {
                //"transition": "padding 0.1s,width 0.1s",
            };

            if (
                (this.model.prlt === 1 && !this.isMobi) ||
                (this.model.prlt === 1 && this.isMobi && this.module.prop0 !== 1)
            ) {
                //自定义单行图片项个数
                style['width'] = `${100 / this.model.prl}%`;
            }

            if (
                this.isMobi &&
                this.model.prlt === 0 &&
                this.pcPrivatePattern.prlt === 1 &&
                (this.pcPrivatePattern.prl === 3 || this.pcPrivatePattern.prl === 1)
            ) {
                // 当电脑视图是1个或3个一行的时候，手机视图需要显示1个作为一行
                style['width'] = '100%';
            }

            if (!this.manageMode) {
                return style;
            }

            if (this.model.ppmt === 1 && !this.isMobi) {
                //自定义图片间距
                style['padding-left'] = `${parseInt(this.model.ppmh / 2)}px`;
                style['padding-right'] = `${parseInt(this.model.ppmh / 2)}px`;
                style['padding-top'] = `${parseInt(this.model.ppmv / 2)}px`;
                style['padding-bottom'] = `${parseInt(this.model.ppmv / 2)}px`;
            }

            return style;
        },

        groupIdList() {
            return this.module.prop1;
        },

        groupSortIdList() {
            return this.module.prop3;
        },

        pattern() {
            const device = this.device;
            const pattern = this.module.privatePattern[device];

            if (device !== 'pc') {
                //非pc的情况下混合pc pattern
                const pcPattern = this.module.privatePattern['pc'];
                return Object.assign({}, pcPattern, pattern);
            }

            return pattern;
        },

        displayPhotoGroupList() {
            const list = this.photoGroupList || [];

            if (this.style === 2) {
                //轮播样式如果个数超过可显示个数则开启轮播，需要显示多一版的卡片支持图片轮播
                let display_length = 0;

                if (this.isMobi) {
                    //手机超过两个就会滚
                    display_length = 2;
                } else {
                    if (this.pattern.prlt === 0) {
                        display_length = 4;
                    } else {
                        display_length = this.pattern.prl;
                    }
                }

                if (list.length > display_length) return list.concat(list.slice(0, display_length));
            } // 处理分页，只有样式1需要分页

            const page = this.currentPage;
            const pageSize = this.paginationPageSize;

            if (this.style == 0 && this.flagPagination) {
                if (this.paginationStyleIndex == 2) {
                    return list.slice(0, page * pageSize);
                } else {
                    return list.slice((page - 1) * pageSize, page * pageSize);
                }
            } else {
                return list.slice(0, 300);
            }
        },

        isSelectDetail() {
            const pcPattern = this.module.privatePattern['pc'];
            return pcPattern.ppc == 0 || false;
        },
    },
    watch: {
        style(val) {
            val === 1 && this.controller.initSlider();
            val === 2 && this.controller.initMarquee();
        },

        paginationStyleIndex() {
            this.currentPage = 1;
        },
    },
    created() {
        this.getPhotoGroupListInManage();
    },
    mounted() {
        // 防止jq先生成dom
        this.initPhotoGroupModule();
        this.isFixIe = !this.manageMode && Fai.isIE();
        if (this.isFixIe) {
            setTimeout(() => {
                $(`#module${this.module.id} .photoWrapper img`).attr('src', '');
            }, 500);
        }
    },
    methods: {
        getPhotoGroupListInManage() {
            if (!this.manageMode) return;
            this.$watch(
                () => [this.useGroupSort, this.groupSortIdList, this.groupIdList],
                async () => {
                    const params = {
                        useGroupSort: this.useGroupSort,
                    };
                    if (this.useGroupSort) {
                        params.sortIdList = JSON.stringify(this.groupSortIdList);
                    } else {
                        params.groupIdList = JSON.stringify(this.groupIdList);
                    }
                    const { success = false, list = [] } = await getPhotoList(params);
                    if (success) {
                        this.photoGroupList = list;
                    }
                },
                {
                    immediate: true,
                    deep: true,
                }
            );
        },
        getHoverStyle(item) {
            let style = {};
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnf,
                mobiData: this.mobiPrivatePattern.pnf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });

            if (item.hovering) {
                if (font.y != 0) {
                    style = getComputedTextColor(font.hc || '#000');
                } else {
                    if (!this.$isServer) {
                        style.color = this.themeColor;
                    }
                }
            }

            if (this.isMobi) {
                return {};
            }

            return style;
        },

        initPhotoGroupModule() {
            const that = this;
            this.controller = new PhotoGroupModule(this.module.id, {
                get style() {
                    return that.style;
                },

                get photoGroupList() {
                    return that.photoGroupList;
                },
            });
        },
        photoSlide(index, photoGroup) {
            let { id, url } = photoGroup;
            // 这里进行点击图片方式判断，详情页不进行展示幻灯片 FIX
            if (!this.isSelectDetail) {
                if (this.isNewPhotoSlides) {
                    this.groundIndex = index;
                    if (this.isMobi) {
                        this.showMobiViewer = true;
                    } else {
                        this.showPcViewer = true;
                    }
                } else {
                    if (!this.controller.initSlide) {
                        this.initPhotoGroupModule();
                    }
                    this.controller.initSlide(id);
                }
            } else {
                if (url.indexOf('?') != -1) {
                    url = url + `&mid=${this.id}`;
                } else {
                    url = url + `?mid=${this.id}`;
                }
                window.open(url);
            }
        },

        handlePaginationPageChange() {
            // computed中响应了数据的变化
        },

        clickActiveButtonHandler() {
            this.useGroupSort ? this.clickSelectPhotoGroupGroupHandler() : this.clickAddPhotoGroupHandler();
        },

        clickButtonHandler() {
            this.useGroupSort ? this.clickManagePhotoGroupGroupHandler() : this.clickSelectPhotoGroupHandler();
        },

        clickAddPhotoGroupHandler() {
            addPhotoGroup().then((newPhotoGroup = {}) => {
                if (newPhotoGroup.id != 0) {
                    this.module.prop1 = this.module.prop1.concat(newPhotoGroup.id);
                }
            });
        },

        clickSelectPhotoGroupHandler() {
            selectPhotoGroupMenuItem(this.id);
        },

        clickSelectPhotoGroupGroupHandler() {
            this.manageMode && editModule(this.id);
        },

        clickManagePhotoGroupGroupHandler() {
            managePhotoGroupSort();
        },

        photoWrapStyle() {
            const style = {};
            if (this.model.ppht === 1) {
                //自定义图片高度
                style['padding-bottom'] = `${this.model.pph * 100}%`;
            } else if (this.isMobi && this.model.ppht == 0) {
                style['padding-bottom'] = `${this.pcPrivatePattern.pph * 100}%`;
            }
            return style;
        },
        photoFixIEStyle(pic) {
            const style = {};
            style['background-image'] = `url(${pic.picPath})`;
            style['background-repeat'] = 'no-repeat';
            switch (this.curPpst) {
                case 0:
                    style['background-size'] = 'cover';
                    style['background-position'] = 'center';
                    break;
                case 1:
                    style['background-size'] = 'cover';
                    style['background-position'] = 'center';
                    break;
                case 2:
                    style['background-size'] = 'none';
                    style['background-position'] = 'center';
                    break;
                case 3:
                    style['background-size'] = 'contain';
                    style['background-position'] = 'center';
                    break;
            }
            return style;
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
