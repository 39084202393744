import '@/styles/fontsIcon.scss';
import '@/styles/base.scss';
import '@/styles/video-js.scss';
import '@/styles/video.scss';
import '@/styles/slidePanel.scss';
import '@/styles/moduleAnimation.scss';
import '@/styles/independentMobi.scss';
import '@/styles/scrollFullScreen.scss';

import '@/polyfill/intersection-observer.js';
import '@/site/setPublicPath.js';
import '@/libs/comMethods.js';
import '@/libs/jzUtils.js';
import '@/libs/fai.js';
import '@/libs/jquery.uploadifyNew.js';
import '@/libs/comm.js';
import '@/libs/city2.js';
import '@/libs/moduleAnimation.js';

import { createApp } from './visitor/createApp.js';
import { injectMonitorInWindow } from '@/monitor/index.js';
import { injectDeviceToVue } from '@/site/shared/device/index.js';
import { registerEmptyDirective } from './visitor/registerEmptyDirective.js';
import { registerDirectives } from '@/site/plugins/index.js';
import { initPageAfterDomLoaded, initFaiHawkEyeMonitor } from '@/site/initSite.js';
import { injectFunctionsToWindow } from '@/site/index.js';

import { registerMobileAuthPopupInVue } from './services/index';

// 注入监控系统
initFaiHawkEyeMonitor();

// 注入监控
injectMonitorInWindow();

registerEmptyDirective(window.Vue);
registerDirectives(window.Vue);
injectDeviceToVue(window.Vue);

registerMobileAuthPopupInVue(window.Vue);

// 注入全局方法到window，例如 JZ Site Mobi、管理态访客态共用的方法
injectFunctionsToWindow();

const { app, store } = createApp();

window._store = store;

if (window.__INITIAL_STATE__) {
    app.$mount('#jzRoot', true);
} else {
    // CSR 逻辑
    if (window.renderData) {
        app.$mount('#jzRoot');
        console.log('当前页面为csr页面');
    } else {
        app.$mount('#jzRoot');
        console.log('当前页面未获取到页面数据');
        // onLoadScripts();
    }
}

initPageAfterDomLoaded();
