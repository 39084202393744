//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { componentProps } from './componentProps';
import { typeIsInNavRow, currentPageIsFixed } from '@/site/shared/fixTopStyle/index.js';
import { createDialog } from '@/site/shared/dialog/index.js';

const createPcModalStyle = function (list, fontStyle) {
    return {
        data() {
            return {
                lists: list,
                fontStyle: fontStyle,
            };
        },
        template: `
			<ul class="lan_lists">
				<li v-for="item in lists" class="lan_list" :key="item.lanCode">
					<a hidefocus="true" :href="item.url" class="language_item_wrapper">
						<i class='s_multi_language_icon' :class="'icon_' + item.lanCode "></i>
						<span class='s_multi_language_text s_multi_language_normal_text' :style="fontStyle">{{item.name}}</span>
					</a>
				</li>
			</ul>`,
        mounted() {
            if (typeIsInNavRow('language') && currentPageIsFixed()) {
                let isScroll = $(document).scrollTop() > 0;
                if (isScroll) {
                    $('.multi_language_panel').addClass('fix_top_class');
                }
            }
        },
    };
};

// mobi 弹窗效果
const createMobiModalStyle = function (list, currentLan, fontStyle, selectedFontStyle) {
    return {
        data() {
            return {
                lists: list,
                showPanel: true,
                currentLan,
                fontStyle: fontStyle,
                selectedFontStyle,
            };
        },
        mounted() {
            Vue.nextTick(function () {
                $('.multi_Language_mobi_panel').scrollstart(function () {
                    // 禁止在多语言面板那边滑屏
                    event.stopPropagation();
                    event.preventDefault();
                });
            });
        },
        methods: {
            hidePanel() {
                this.showPanel = false;
            },
            stopPropagation() {
                event.stopPropagation();
            },
            itemStyle(list) {
                let style = this.fontStyle;
                if (this.currentLan.lcid == list.lcid && this.selectedFontStyle != null) {
                    style = this.selectedFontStyle;
                }
                return style;
            },
        },
        template: `
			<div class="multi_Language_mobi_panel" :class="{lan_active:showPanel}">
				<div class="lan_layout" @click="hidePanel"></div>
				<ul class="lan_mobi_lists">
					<li v-for="list in lists" class="lan_mobi_list">
						<a  hidefocus="true" :href="list.url" @click="stopPropagation">
							<i class='s_multi_language_icon' :class="'icon_' + list.lanCode "></i>
							<span
								:style="itemStyle(list)"
								class='s_multi_language_text'
								:class="currentLan.lcid == list.lcid ? 'jz_theme_font_color' : ''"
							>
								{{list.name}}
							</span>
						</a>
					</li>
				</ul>
			</div>`,
    };
};

export default {
    props: componentProps,
    computed: {
        topPosition() {
            return this.areaType === 0;
        },
    },
    methods: {
        openLanguagePopup(event) {
            event.stopPropagation();
            event.preventDefault();
            const list = this.lanList;
            const currentLan = this.currentLan;
            const fontStyle = this.createFontStyle();
            const selectedFontStyle = this.createFontStyle(true);
            if ($('.jz_screen_pc').length) {
                // pc 视图
                createDialog({
                    showClose: true,
                    showLayout: true,
                    content: `
						<div class="multi_language_panel">
							<div class="lan_title">${LS.languageSelect}</div>
							<div class="lan_container">
								<div id="multiLanguagePanel"></div>
							</div>
						</div>`,
                });
                new Vue(createPcModalStyle(list, fontStyle)).$mount('#multiLanguagePanel');
            } else {
                // mobi 视图
                if (
                    $('.jz_mobi_header #multi_Language_mobi_panel').length > 0 ||
                    $('#gridFooter #multi_Language_mobi_panel').length > 0
                ) {
                    this.languagePanelApp = new Vue(
                        createMobiModalStyle(list, currentLan, fontStyle, selectedFontStyle)
                    );
                    if ($('.jz_mobi_header #multi_Language_mobi_panel').length > 0) {
                        this.languagePanelApp.$mount('.jz_mobi_header #multi_Language_mobi_panel');
                    } else {
                        this.languagePanelApp.$mount('#gridFooter #multi_Language_mobi_panel');
                    }
                } else {
                    if (this.languagePanelApp) {
                        this.languagePanelApp.fontStyle = fontStyle; // 更新样式
                        this.languagePanelApp.selectedFontStyle = selectedFontStyle;
                    }
                    !!this.languagePanelApp && (this.languagePanelApp.showPanel = true);
                }
            }
        },
    },
};
