//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { bindFlag, createUrlArgsOnclickStr } = Comm;
import { getNewsListByPagination } from '@api/news/index.js';
import { decodeHtml } from '@/components/utils.js';
const manageUse = {};
import PaginationComponent from '@/components/modules/common/pagination/index.vue';

export default {
    name: 'NewsResult',
    style: 5,
    components: {
        PaginationComponent,
    },
    props: ['id', 'module'],
    data() {
        return {
            currentPage: 1,
            getLoading: false,
            sortKeys: ['default', 'title', 'date', 'views'],
            sortTypes: ['desc', 'asc'],
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'isMaterialLazyLoad']),
        ...Vuex.mapGetters(['isMobi', 'hashRemoved']),
        classes() {
            return ['module_news_result_style' + this.module.prop0];
        },
        newsList: {
            get() {
                return this.module.newsList || [];
            },
            set(value) {
                this.$set(this.module, 'newsList', value);
            },
        },
        newsGroupList() {
            return this.module.newsGroupList || [];
        },
        flagTarget: bindFlag(0x1),
        flagDate: bindFlag(0x2),
        flagGroup: bindFlag(0x4),
        flagPagination: bindFlag(0x10),
        flagSummary: Comm.bindFlag(0x20),
        flagDateMobi: Comm.bindFlag(0x40),
        flagGroupMobi: Comm.bindFlag(0x80),
        flagSummaryMobi: Comm.bindFlag(0x100),
        showSummary() {
            if (this.isMobi && !this.mobiDefaultShowContent) {
                return !this.flagSummaryMobi;
            }
            return !this.flagSummary;
        },
        showGroup() {
            if (this.isMobi && !this.mobiDefaultShowContent) {
                return !this.flagGroupMobi;
            }
            return !this.flagGroup;
        },
        showDate() {
            if (this.isMobi && !this.mobiDefaultShowContent) {
                return !this.flagDateMobi;
            }
            return !this.flagDate;
        },
        mobiDefaultShowContent() {
            return this.module.privatePattern.mobi.p_sc === 0;
        },
        showPagination() {
            return this.flagPagination;
        },
        paginationPageSize() {
            return this.module.prop2;
        },
        paginationStyleIndex() {
            return this.module.prop4;
        },
        target() {
            return this.flagTarget ? '_self' : '_blank';
        },
        noResult() {
            return this.module.noResult;
        },

        paginationPageCount() {
            const totalSize = this.module.totalSize;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },

        privatePattern() {
            return this.module.privatePattern[this.device];
        },
        pcPrivatePattern() {
            return this.module.privatePattern['pc'];
        },

        itemLineStyle() {
            if (!this.manageMode) {
                return;
            }

            var type = this.module.prop0;
            if (this.device != 'mobi' && type == 2) {
                if (this.privatePattern.pplnt == 1) {
                    return {
                        width: 'calc(100% / ' + this.privatePattern.ppln + ' - 20px)',
                    };
                }
            }

            if (this.device == 'mobi' && type == 2) {
                if (this.privatePattern.pplnt == 1) {
                    return {
                        width: `${100 / this.privatePattern.ppln - 3.6458}%`,
                    };
                }
            }
            return {};
        },
        itemLineClass() {
            var classes = {};

            if (this.pcPrivatePattern.pplnt == 1) {
                if (this.pcPrivatePattern.ppln == 1 || this.pcPrivatePattern.ppln == 3) {
                    classes['news_result_item_mobi_full'] = true;
                }
            }

            return classes;
        },
        sortKey() {
            try {
                return this.sortKeys[this.module.blob0.sortKey || 0];
            } catch (error) {
                return this.sortKeys[0];
            }
        },
        sortType() {
            try {
                return this.sortTypes[this.module.blob0.sortType || 0];
            } catch (error) {
                return this.sortTypes[0];
            }
        },
        filterNewsList() {
            const originNewsList = this.newsList;
            const nowTime = new Date().getTime();
            return originNewsList.filter(
                (value) => new Date(value.date).getTime() <= nowTime || !value.publishRegularly
            );
        },
    },
    watch: {
        paginationStyleIndex() {
            this.currentPage = 1;
            this.getNewsList(1);
        },
    },
    mounted() {
        if (this.manageMode) {
            this.$set(this.module, 'forceUpdateNewsList', false);
            this.$watch(
                () => {
                    return JSON.stringify([
                        this.module.blob0.sortKey,
                        this.module.blob0.sortType,
                        this.paginationPageSize,
                        this.showPagination,
                        this.module.forceUpdateNewsList,
                    ]);
                },
                () => {
                    this.currentPage = 1;
                    this.getNewsList(1);
                }
            );
        }
    },
    methods: {
        forceUpdateNewsListData() {
            this.module.forceUpdateNewsList = !this.module.forceUpdateNewsList;
        },
        getItemTarget(item) {
            if (!this.manageMode) return item.target;
            return Fai.getUrlParam(item.url, 'groupId') === Fai.getUrlParam(location.href, 'groupId')
                ? '_self'
                : this.target;
        },
        stopWatchDataInManageMode() {
            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
        },
        getNewsList(page) {
            if (this.getLoading) return;
            this.getLoading = true;
            let paginationPageSize = this.paginationPageSize;
            if (!this.showPagination) {
                page = 1;
                paginationPageSize = 300;
            }
            this.getNewsListByPagination(page, paginationPageSize)
                .then((res) => {
                    this.getLoading = false;
                    if (res.success) {
                        this.stopWatchDataInManageMode();
                        const newList = res.list;
                        if (this.paginationStyleIndex == 2 && page !== 1) {
                            // 如果是加载更多样式，则需要插入数组
                            this.newsList = this.newsList.concat(newList);
                        } else {
                            this.newsList = newList;
                        }
                        this.module.totalSize = res.total;
                    } else {
                        console.error(res.msg);
                    }
                })
                .catch(() => {
                    this.getLoading = false;
                });
        },
        getNewsListByPagination(page = 1, pageSize = 300) {
            const data = {
                useGroup: true,
                groupIdList: JSON.stringify([this.module.groupId]),
                sortKey: this.module.blob0.sortKey,
                sortType: this.module.blob0.sortType,
                fromMid: this.module.id,
            };
            return new Promise((resolve, reject) => {
                getNewsListByPagination({
                    page,
                    pageSize,
                    ...data,
                })
                    .then((data) => (data.success ? resolve(data) : reject(data)))
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        decodeHtml,
        onclickStr(item) {
            const { ndReqArgs } = item;
            return createUrlArgsOnclickStr(ndReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        hovermenu: manageUse.hovermenu || function () {},
        genImgStyle(item) {
            var style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.path}')`;
            }

            if (!this.manageMode) return style;

            var type = this.module.prop0;

            if (this.device != 'mobi') {
                //两列的样式
                if (type != 0 && this.privatePattern.ppht == 1) {
                    style.paddingTop = this.privatePattern.pph * 100 + '%';
                }
            }

            if (this.device == 'mobi') {
                //上下图文样式手机高度跟随pc
                if (type == 2 && this.pcPrivatePattern.ppht == 1) {
                    style.paddingTop = this.pcPrivatePattern.pph * 100 + '%';
                }
            }

            return style;
        },
    },
};
