//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let manageUse = {
    components: {},
};
import { Comm } from '../../utils';
const { mapFlag } = Comm;
import { logDog } from '@/site/shared/log/index.js';
import { getCustomSearchData } from '@shared/manage/customSearch/index.js';
import unitCustomSearchEntry from './unitCustomSearchEntry.vue';

export default {
    name: 'CustomSearch',
    style: 24,
    components: {
        unitCustomSearchEntry,
        ...manageUse.components,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    asyncComputed: {
        asyncProjectInfo: {
            get() {
                const { prop0 } = this.module;
                return getCustomSearchData().then((data) => {
                    return data.find(({ id }) => id == prop0) || {};
                });
            },
            default: {},
            lazy: true,
        },
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'device']),
        isMobi() {
            return this.device == 'mobi';
        },
        ...mapFlag({
            hideTitle: 0x1,
            hideTips: 0x2,
            mobiHideTitle: 0x4,
            mobiHideTips: 0x8,
        }),
        handledHideTitle() {
            if (this.isMobi && this.module.privatePattern.mobi.psc === 1) {
                return this.mobiHideTitle;
            }
            return this.hideTitle;
        },
        handledHideTips() {
            if (this.isMobi && this.module.privatePattern.mobi.psc === 1) {
                return this.mobiHideTips;
            }
            return this.hideTips;
        },
        ...mapFlag(
            {
                open: 0x1,
            },
            'projectInfo.flag'
        ),
        initalState() {
            return this.module.initalState || {};
        },
        Loc() {
            return this.initalState.Loc || {};
        },
        projectInfo() {
            return this.$isServer || !this.manageMode ? this.initalState.projectInfo : this.asyncProjectInfo;
        },
        itemListCondited() {
            return (this.projectInfo.itemList || []).filter(({ isCondition }) => isCondition);
        },
        isDeviceMobi() {
            return this.device == 'mobi';
        },
    },
    methods: {
        clickButtonHandler() {
            this.manageMode && editModule(this.module.id);
        },
        clickActiveButtonHandler() {
            if (!this.manageMode) {
                return;
            }
            logDog(200881, 1);
            showTopManageFrame('manageFrameSearchProjectAdd');
        },
    },
};
