import { isObject, isArray } from '@jz/utils';
import { logJzFdp as jzFdpRequest, logDog as logDogRequest } from '@/api/log/index.js';
class JzFdpLog {
    constructor() {}
    static logFdpAfterSave() {
        let saveList = [].concat(this.fdpList);
        Object.keys(this.fdpMap).forEach((item) => {
            saveList.push(this.fdpMap[item]);
        });
        if (saveList.length) {
            JzFdpLog.fdpRequest(saveList);
            this.fdpList = [];
            this.fdpMap = {};
        }
    }

    static addFdpLog(option) {
        const { event, properties } = option;
        const isPropertyObject = isObject(properties);
        const isEventNameString = typeof event === 'string';
        if (isPropertyObject && isEventNameString) {
            this.fdpList.push({
                event,
                properties,
            });
        }
    }

    static addRepeatFdpLog(option) {
        const { sign, event, properties } = option;
        const isPropertyObject = isObject(properties);
        const isEventNameString = typeof event === 'string';
        if (isPropertyObject && isEventNameString) {
            this.fdpMap[sign] = {
                event,
                properties,
            };
        }
    }

    static getRepeatFdpLog() {
        return this.fdpMap;
    }

    static getNormalFdpLog() {
        return this.fdpList;
    }

    static logJzFdp(event, properties) {
        return jzFdpRequest({
            event,
            properties,
        });
    }

    static pushFdpRequest(item) {
        if (isObject(item)) {
            this.requestList.push(item);
        }
    }

    static clearTimeout() {
        clearTimeout(this.requestTimer);
        this.requestTimer = null;
    }

    static clearRequestData() {
        this.requestList.length = 0;
    }

    static fdpRequest(list) {
        if (!isArray(list)) return;
        /* eslint-disable-next-line no-undef */
        jzRequest
            .post('/ajax/log_h.jsp', {
                data: {
                    cmd: 'wafNotCk_logJZFdpListTrack',
                    fdpList: JSON.stringify(list),
                },
            })
            .catch((value) => {
                console.log(value);
            });
    }

    static logFdpRequest(event, properties, listLength = 10, delay = 3000) {
        if (JzFdpLog.requestList.length < listLength) {
            JzFdpLog.pushFdpRequest({
                event,
                properties,
            });
            if (JzFdpLog.requestTimer) return;
            JzFdpLog.requestTimer = setTimeout(() => {
                JzFdpLog.fdpRequest(JzFdpLog.requestList);
                JzFdpLog.clearRequestData();
                JzFdpLog.clearTimeout();
            }, delay);
        } else {
            JzFdpLog.requestTimer && JzFdpLog.clearTimeout();
            JzFdpLog.fdpRequest(JzFdpLog.requestList);
            JzFdpLog.clearRequestData();
            JzFdpLog.pushFdpRequest({
                event,
                properties,
            });
            return;
        }
    }
}

JzFdpLog.requestTimer = null;
JzFdpLog.requestList = [];

JzFdpLog.fdpList = []; // 存储可重复的 fdpLog
JzFdpLog.fdpMap = {}; // 存储不可重复的 fdpLog

/**
 *
 * @function name - logJZFdp
 * @description [logJZFdp 发送自适应 fdp 请求 ]
 * @param event {String}       description
 * @param properties {Object}  description
 * @return {undefined}            [description]
 */
const logJZFdp = JzFdpLog.logJzFdp;

/**
 *
 * @function name - logFdpRequest
 * @description [logJZFdp 发送自适应 fdp 请求 3s内10个请求以上会合并发出  建议短时间内大量的请求使用]
 * @param event {String}       description
 * @param properties {Object}  description
 * @return {undefined}            [description]
 */
const logFdpRequest = JzFdpLog.logFdpRequest;

/**
 * logDog
 * Site的后端接口已经改造过了 by jser 2017-6-5
 * 用法示例：
    用法1：传入数值
        Site.logDog(10000, 1);
    用法2：id为数值       src传入数组json   1、数组不可以是空数组。2、且里面的元素数值或数值字符串，要做前端过滤
        Site.logDog(10000, $.toJSON([1, 2, 3]))
    用法3：id为对象json   src不传值         1、确保对象不是空对象。2、键名必须是数值字符串。3、键值是数值或者数字字符串
        objJSON = $.toJSON({
            "10000": 1,
            "10001": [1, 2, 3]
        });
        Site.logDog(objJSON);
 */

const logDog = function (dogId, dogSrc) {
    logDogRequest({
        dogId: dogId,
        dogSrc: dogSrc,
    });
};

const logFdpList = JzFdpLog.fdpRequest;

export { logJZFdp, logFdpRequest, JzFdpLog, logDog, logFdpList };
