//
//
//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getFontCss, getFontColorCss, getWrapCss } from '../cssUtils';

export default {
    name: 'NewsResultCss',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        titleCss() {
            return getFontCss({
                pcFont: this.pcPattern.p_tf,
                mobiFont: this.mobiPattern.p_tf,
                className: `${this.id} .news_result_item_title`,
            });
        },
        summaryCss() {
            return getFontCss({
                pcFont: this.pcPattern.p_af,
                mobiFont: this.mobiPattern.p_af,
                className: `${this.id} .news_result_item_summery`,
            });
        },
        dateCss() {
            return getFontCss({
                pcFont: this.pcPattern.p_df,
                mobiFont: this.mobiPattern.p_df,
                className: `${this.id} .news_result_item_date`,
            });
        },
        groupCss() {
            return getFontCss({
                pcFont: this.pcPattern.p_cf,
                mobiFont: this.mobiPattern.p_cf,
                className: `${this.id} .news_result_item_group_item`,
            });
        },
        hoverCss() {
            let css = '';
            const getCss = (fontPattern, className) => {
                if (fontPattern.y === 1) {
                    return `${this.pcId} .news_result_item_line:hover ${className} { ${getFontColorCss(
                        fontPattern.sc
                    )} }`;
                }
                return '';
            };
            css += getCss(this.pcPattern.p_tf, '.news_result_item_title');
            css += getCss(this.pcPattern.p_af, '.news_result_item_summery');
            css += getCss(this.pcPattern.p_df, '.news_result_item_date');
            css += getCss(this.pcPattern.p_cf, '.news_result_item_group_item');
            return css;
        },
        titleWrap() {
            return getWrapCss({
                pcPattern: this.pcPattern.p_tl,
                mobiPattern: this.mobiPattern.p_tl,
                className: `${this.id} .news_result_item_title`,
            });
        },
        summaryWrapCss() {
            return getWrapCss({
                pcPattern: this.pcPattern.p_al,
                mobiPattern: this.mobiPattern.p_al,
                className: `${this.id} .news_result_item_summery`,
            });
        },
    },
};
