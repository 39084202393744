import { createStore, initStore } from '@/store/index.js';
import { isBrowserEnv } from '@jz/utils';
import { logMonitor } from '@/api/log/index.js';

const manageMode = isBrowserEnv() && !window.__INITIAL_STATE__;

const _store = createStore({ manageMode });

if (typeof window !== 'undefined') {
    const initialState = window.__INITIAL_STATE__;

    if (manageMode && window.data) {
        // 管理态
        window.data.LS = window.LS;
        initStore(_store, window.data);
    } else if (initialState) {
        if (initialState === 'error content') {
            logMonitor({
                id: 12244,
                isLog: true,
            });
        }
        // 访客态
        window.__INITIAL_STATE__.LS = window.LS;
        _store.replaceState(window.__INITIAL_STATE__);
    } else if (window.renderData) {
        // 访客态SSR失败，CSR 逻辑
        window.renderData.LS = window.LS;
        initStore(_store, window.renderData);
    }
}

export const store = _store;

export const createNewStoreToSsr = () => createStore({ manageMode: false });
