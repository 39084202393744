//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CommentFiveStar',
    props: ['index', 'star', 'creatComment'],
    data() {
        return {
            curIndx: this.index + 1,
        };
    },
    computed: {
        classes() {
            return {
                select_star: this.star >= this.index,
                jz_theme_font_color: this.star >= this.index,
                jz_theme_font_hover_color: this.creatComment,
                jz_hover: this.creatComment,
            };
        },
    },
};
