//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getComputedBg, extend, getComputedTextColor } = Comm;
import { addClass, addClasses, verifyCusLinkType, hasChildByRow } from './utils.js';
import FreeLayout from './freeLayout.vue';

import { ScrollAnchorService } from '@/services';
import { mapMutations } from 'vuex';

export default {
    name: 'NavItem',
    components: {
        FreeLayout,
    },
    props: {
        nav: {
            type: Object,
        },
        cloneNav: {
            type: Object,
        },
        list: {
            type: Object,
        },
        viewNavList: {
            type: Array,
        },
    },
    data() {
        return {
            selectedColId: -1,
            jumpAnchorColId: -1,
            slectNavClass: 'jz_nav_item_selected jz_theme_border_color jz_theme_font_color',
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'colId', 'sections', 'rows', 'colId']),
        ...Vuex.mapGetters(['info']),
        ...Vuex.mapState({
            activeAnchor: (state) => state.scrollAnchor.anchor,
        }),
        hasActiveAnchor() {
            return this.activeAnchor.moduleId !== -1 || this.activeAnchor.rowId !== -1;
        },
        threeNavListClass() {
            return `three_nav_list-${this.ncc.t}`;
        },
        isShowFreeLayout() {
            return this.isFreeLayoutType && !this.isFullScreenNav && (this.isPc || this.ncc.mfl);
        },
        isFreeLayoutType() {
            return this.ncc.t == 2;
        },
        arrow() {
            return {
                style: this.nav.as,
                visible: this.nav.at === 1,
            };
        },
        isPc() {
            return this.device === 'pc';
        },
        subNavClass() {
            var classes = [];
            if (this.isShowNavStyleCss) {
                classes.push('jz_theme_bg_color');
            }
            return classes;
        },
        subNavBeforeStyle() {
            var style = {},
                mobiStyle = {};
            // 非公用设置项
            if (this.ncc.t == 1) {
                if (this.ncc.hmt == 1 && this.ncc.hmp != undefined) {
                    style.top = '-' + this.ncc.hmp + 'px';
                    style.height = this.ncc.hmp + 'px';
                }
            } else {
                if (this.ncc.vmt == 1 && this.ncc.vmp != undefined) {
                    style.top = '-' + this.ncc.vmp + 'px';
                    style.height = this.ncc.vmp + 'px';
                }
            }

            return !this.isPc ? mobiStyle : style;
        },
        getSubSelectBgStyle() {
            var navStyle = {},
                navMobiStyle = {},
                bGstyle = {};

            bGstyle = getComputedBg(this.ncc.sbg);
            extend(navStyle, bGstyle);

            return !this.isPc ? navMobiStyle : navStyle;
        },
        getSubSelectStyle() {
            var navStyle = {},
                navMobiStyle = {};

            if (this.ncc.if && this.ncc.if.y == 1) {
                this.ncc.if.sc != undefined && extend(navStyle, getComputedTextColor(this.ncc.if.sc));
            }

            return !this.isPc ? navMobiStyle : navStyle;
        },
        threeNavBeforeStyle() {
            if (!this.manageMode) {
                return {};
            }
            var style = {},
                mobiStyle = {};
            // 非公用设置项
            if (this.ncc.t == 1) {
                if (this.ncc.hmt == 1 && this.ncc.hmp != undefined) {
                    style.top = '-' + this.ncc.hmp + 'px';
                    style.height = this.ncc.hmp + 'px';
                }
            }

            return !this.isPc ? mobiStyle : style;
        },
        ncc() {
            return this.nav.ncc;
        },
        getNavColTextWrapSelectStyle() {
            var navStyle = {},
                navMobiStyle = {};
            if (!this.isPc) {
                return {};
            }
            if (this.nav.cf && this.nav.cf.y == 1) {
                this.nav.cf.sc != undefined && extend(navStyle, getComputedTextColor(this.nav.cf.sc));
            }

            return !this.isPc ? navMobiStyle : navStyle;
        },
        getNavSelectStyle() {
            var navStyle = {},
                navMobiStyle = {},
                bGstyle = {};
            if (!this.isPc) {
                return {};
            }
            if (this.nav.cb && this.nav.cb.y == 1) {
                // 边框
                this.nav.cb.w != undefined && (navStyle.borderWidth = this.nav.cb.w + 'px');
                !this.nav.cb.t && (navStyle.borderTopStyle = 'none');
                !this.nav.cb.b && (navStyle.borderBottomStyle = 'none');
                !this.nav.cb.l && (navStyle.borderLeftStyle = 'none');
                !this.nav.cb.r && (navStyle.borderRightStyle = 'none');
                !!this.nav.cb.sc && (navStyle.borderColor = this.nav.cb.sc);
            }

            bGstyle = getComputedBg(this.nav.csbg);
            bGstyle = Object.assign({ backgroundImage: 'none' }, bGstyle);

            extend(navStyle, bGstyle);

            return !this.isPc ? navMobiStyle : navStyle;
        },
        isShowNavStyleCss() {
            // 是否显示选择的导航样式
            return this.isPc && this.nav.s !== -1;
        },
        hasThemeColor() {
            var hasNavStyleId = typeof this.nav.s !== 'undefined' && this.nav.s !== -1;
            var hasNavCloneStyleId = typeof this.nav.cs !== 'undefined' && this.nav.cs !== -1;
            if (hasNavStyleId) {
                return true;
            }

            try {
                var hasCloneNavStyleId = typeof this.cloneNav.s !== 'undefined' && this.cloneNav.s !== -1;
                var hasCloneNavCloneStyleId = typeof this.cloneNav.cs !== 'undefined' && this.cloneNav.cs !== -1;
                if (!hasNavStyleId) {
                    var hasCloneNavStyle = hasCloneNavStyleId || hasCloneNavCloneStyleId;
                    return hasNavCloneStyleId || hasCloneNavStyle;
                }
            } catch (error) {
                return false;
            }
            return false;
        },
        isDisableTarget() {
            if (this.isShowFreeLayout) {
                let dontClickType = this.manageMode ? this.info.pattern.nav.dt : this.nav.dt;
                return this.hasChildFreeLayout && dontClickType;
            }
            // fix: 检查二级栏目是否都被隐藏
            const hasShowChildCol =
                !!this.list.colList2.length &&
                this.list.colList2.some((item) => {
                    return !item.nav_hidden;
                });

            if (this.manageMode) {
                return this.info.pattern.nav.dt && hasShowChildCol;
            } else {
                return this.nav.dt && hasShowChildCol;
            }
        },
        isFullScreenNav() {
            return this.nav.type === 1;
        },
        isShowArrow() {
            return this.isPc && !this.isFullScreenNav;
        },
        firstNavHasChild() {
            if (this.isShowFreeLayout) {
                return this.hasChildFreeLayout;
            }
            return this.hasChildMenu(this.list.colList2);
        },
        hasChildFreeLayout() {
            let rIds = this.list.rIds || [];
            let flag = false;
            for (let i = 0; i < rIds.length; i++) {
                flag = hasChildByRow(rIds[i], this.rows);
            }
            return flag;
        },
    },
    created() {
        if (this.module && this.module._colId) {
            this.selectedColId = this.module._colId;
        }
    },
    mounted() {
        this.selectedColId = this.colId;
        if (verifyCusLinkType() != -1) {
            this.selectedColId = verifyCusLinkType();
        }
        Site.addQrCode('.jz_nav');
    },
    methods: {
        ...mapMutations('scrollAnchor', ['setActiveAnchor']),
        mouseoverNavItem(navItem) {
            if (this.isFreeLayoutType && this.isPc) {
                this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                navItem.showFreeLayout = true;
            }
            this.changeHoverToolStyle({ zIndex: '98' });
        },
        checkHideFreeLayout(e, isFromFreeLayout) {
            let navItem = this.list;
            if (this.isShowFreeLayout) {
                let navItemDom, $navItem, navId, $freeLayout;
                if (isFromFreeLayout) {
                    $freeLayout = $(e.currentTarget);
                    (navId = $freeLayout.attr('id').replace('s_freelayout__', '')),
                        ($navItem = $(`.jz_nav_layout2 .nav_item${navId}`)),
                        (navItemDom = $navItem.get(0));
                } else {
                    (navItemDom = e.currentTarget),
                        ($navItem = $(navItemDom)),
                        (navId = $navItem.attr('_colid')),
                        ($freeLayout = $(`#jzRoot #s_freelayout__${navId}`));
                }
                if ($freeLayout.length) {
                    setTimeout(() => {
                        let { clientY, clientX } = e;
                        let { top, bottom } = $freeLayout.get(0).getBoundingClientRect();
                        let {
                            top: etTop,
                            left: etLeft,
                            bottom: etBottom,
                            right: etRight,
                        } = navItemDom.getBoundingClientRect();
                        if (
                            !(
                                (clientY >= top && clientY <= bottom) ||
                                (clientY >= etTop && clientY <= etBottom && clientX >= etLeft && clientX <= etRight)
                            )
                        ) {
                            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                            navItem.showFreeLayout = false;
                        }
                    }, 200);
                }
            }
        },
        mouseoutNavItem(e) {
            this.checkHideFreeLayout(e);
            this.changeHoverToolStyle({ zIndex: '' });
        },
        getNavItemAttr(info) {
            if (info && info.other) {
                return info.other.nf ? 'nofollow' : '';
            }
            return;
        },
        isDisableTarget2(list) {
            // fix: 检查三级栏目是否都被隐藏
            const hasShowChildCol =
                !!list?.colList3?.length &&
                list?.colList3?.some((item) => {
                    return !item.nav_hidden;
                });

            if (this.manageMode) {
                return this.info.pattern.nav.dt && hasShowChildCol;
            } else {
                return this.nav.dt && hasShowChildCol;
            }
        },
        changeHoverToolStyle(css) {
            if (this.firstNavHasChild) {
                $('#jzToolCompon').parent('.jz_compon_container').css(css);
            }
        },
        itemSelectClass(_class, list) {
            var select = [];

            select.push(this.spliceClass(_class, list));

            addClass('jz_theme_font_hover_color');

            if (this.isPc) {
                addClass(select, 'jz_theme_border_hover_color');
            }

            if (this.checkChildItemSelect(this.selectedColId, list, _class)) {
                addClasses(select, 'jz_nav_item_selected', 'jz_theme_border_color', 'jz_theme_font_color');
            }

            if (this.firstNavHasChild) {
                addClass(select, 'nav_child_item');
            }

            if (this.isPc && this.hasThemeColor) {
                addClasses(select, 'jz_theme_border_color', 'jz_theme_bg_color');
            }

            return select;
        },
        changeHref(event, url = '') {
            let $target = $(event.target),
                href = $target.attr('href') || '';

            // 点击锚点则予以选中状态。
            if (href.indexOf('javascript:JZ.jumpToAnchor') != -1 || url.indexOf('javascript:JZ.jumpToAnchor') != -1) {
                JZ.jumpToAnchor.fromColId = this.jumpAnchorColId = $target.attr('_colid');
            }
        },
        getNavColStyle(list) {
            if (!this.manageMode) {
                return {};
            }
            var colStyle = {},
                mobiColStyle = {},
                bGstyle = {};
            if (!this.isPc) {
                return;
            }
            if (this.nav.cwht == 1) {
                // 宽高
                this.nav.cw != undefined && (colStyle.minWidth = this.nav.cw + 'px');
                this.nav.ch != undefined &&
                    (colStyle.height = this.nav.ch + 'px') &&
                    (colStyle.lineHeight = this.nav.ch + 'px');
            }

            if (this.nav.cbrt == 1) {
                // 圆角
                this.nav.cbrv != undefined && (colStyle.borderRadius = this.nav.cbrv + 'px');
            }

            if (this.nav.cb && this.nav.cb.y == 1) {
                // 边框
                this.nav.cb.w != undefined && (colStyle.borderWidth = this.nav.cb.w + 'px');
                colStyle.borderTopStyle = this.nav.cb.t ? 'solid' : 'none';
                colStyle.borderBottomStyle = this.nav.cb.b ? 'solid' : 'none';
                colStyle.borderLeftStyle = this.nav.cb.l ? 'solid' : 'none';
                colStyle.borderRightStyle = this.nav.cb.r ? 'solid' : 'none';

                !!this.nav.cb.c && (colStyle.borderColor = this.nav.cb.c);
                !!this.nav.cb.sc &&
                    (list.hasSelect || list.id == this.selectedColId) &&
                    (colStyle.borderColor = this.nav.cb.sc);
            }

            if (this.nav.cost >= 1) {
                // 外阴影
                colStyle.boxShadow = this.nav.cost == 1 ? '0 2px 6px 0 rgba(0,0,0,.1)' : 'none';
            }

            const normalSelected = list.hasSelect || list.id == this.selectedColId;

            if (
                (normalSelected && !this.hasActiveAnchor) ||
                this.checkChildItemSelect(this.selectedColId, list, 'nav_item') ||
                this.isActiveAnchor(list)
            ) {
                bGstyle = getComputedBg(this.nav.csbg);
            } else {
                bGstyle = getComputedBg(this.nav.cbg);
            }
            extend(colStyle, bGstyle);

            return !this.isPc ? mobiColStyle : colStyle;
        },
        navItemTextWrapClass() {
            var select = [];

            if (this.isShowNavStyleCss) {
                select.push('jz_theme_font_color');
            }

            return select;
        },
        getNavColTextWrapStyle(list) {
            if (!this.manageMode) {
                return {};
            }
            var colStyle = {};
            if (!this.isPc) {
                return;
            }
            if (this.nav.cf && this.nav.cf.y == 1) {
                // 字体
                if (this.nav.cf.sc != undefined) {
                    extend(colStyle, getComputedTextColor(this.nav.cf.c));
                    const normalSelected = list.id == this.selectedColId;

                    if (
                        (normalSelected && !this.hasActiveAnchor) ||
                        this.checkSelect(list.colList2, this.selectedColId) ||
                        this.isActiveAnchor(list)
                    ) {
                        extend(colStyle, getComputedTextColor(this.nav.cf.sc));
                    } else {
                        extend(colStyle, getComputedTextColor(this.nav.cf.c));
                    }
                }
            }

            return colStyle;
        },
        hasChildMenu(data) {
            if (this.isFullScreenNav && this.isPc) {
                return false;
            }

            return (
                !!data &&
                data.length > 0 &&
                data.some((i) => {
                    return !i.nav_hidden;
                })
            );
        },
        getChildColStyle(level) {
            if (!this.manageMode) {
                return {};
            }
            var style = {},
                mobiStyle = {},
                bGstyle = {};

            // 非公用设置项
            if (this.ncc.t == 1) {
                if (this.ncc.hwht == 1) {
                    this.ncc.hw != undefined && (style.width = this.ncc.hw + 'px');
                    this.ncc.hh != undefined && (style.height = this.ncc.hh + 'px');
                    style.position = 'absolute';
                    style.justifyContent = 'initial';
                }
                if (this.ncc.hmt == 1) {
                    // 外边距
                    this.ncc.hmp != undefined && (style.marginTop = this.ncc.hmp + 'px');
                    this.ncc.hml != undefined && (style.marginLeft = this.ncc.hml + 'px');
                }
                // 二级导航
                if (level == 'sub') {
                    this.nav.cwht == 1 && this.nav.ch > 0 && (style.top = this.nav.ch + 'px');
                }
            } else {
                if (this.ncc.vwht == 1) {
                    // 宽高
                    this.ncc.vw != undefined && (style.width = this.ncc.vw + 'px');
                    this.ncc.vh != undefined && (style.height = this.ncc.vh + 'px');
                }
                if (this.ncc.vmt == 1) {
                    // 外边距
                    this.ncc.vmp != undefined && (style.marginTop = this.ncc.vmp + 'px');
                    this.ncc.vml != undefined && (style.marginLeft = this.ncc.vml + 'px');
                }
            }
            // 共用设置项
            if (this.ncc.b && this.ncc.b.y == 1) {
                // 边框
                this.ncc.b.w != undefined && (style.borderWidth = this.ncc.b.w + 'px');
                !!this.ncc.b.c && (style.borderColor = this.ncc.b.c);
                !this.ncc.b.t && (style.borderTopStyle = 'none');
                !this.ncc.b.b && (style.borderBottomStyle = 'none');
                !this.ncc.b.l && (style.borderLeftStyle = 'none');
                !this.ncc.b.r && (style.borderRightStyle = 'none');
            }

            if (this.ncc.brt == 1) {
                // 圆角
                this.ncc.brv != undefined && (style.borderRadius = this.ncc.brv + 'px');
            }

            if (this.ncc.ost >= 1) {
                // 外阴影
                style.boxShadow = this.ncc.ost == 1 ? '0 2px 6px 0 rgba(0,0,0,.1)' : 'none';
            }

            bGstyle = getComputedBg(this.ncc.bg);
            extend(style, bGstyle);

            // 子级阴影
            if (this.isPc) {
                const { t: shadowType, c: shadowColor, x, y, b: shadowWidth } = this.ncc.is;
                if (shadowType === 1) {
                    // 隐藏
                    style.boxShadow = 'none';
                }
                if (shadowType === 2) {
                    // 自定义
                    style.boxShadow = `${x}px ${y}px ${shadowWidth}px ${shadowColor}`;
                }
            }

            return !this.isPc ? mobiStyle : style;
        },
        hideHoverTool() {
            $('#jzToolCompon').hide();
        },
        showHoverTool() {
            $('#jzToolCompon').show();
        },
        getLineSpaceStyle(level) {
            if (!this.manageMode) {
                return {};
            }
            var style = {},
                mobiStyle = {};

            if (this.isPc && level === 'sub') {
                // 子级分割线
                const type = this.ncc.islt;
                const color = this.ncc.islc;

                if (this.ncc.t === 0 && type !== 0) {
                    // 竖式导航才能配置
                    if (type === 1) {
                        style.backgroundColor = 'transparent';
                    } else {
                        if (color.indexOf('linear-gradient') !== -1) {
                            style.backgroundImage = color;
                            style.color = 'transparent';
                        } else {
                            style.backgroundColor = color;
                        }
                    }
                }
            }

            if (this.ncc.t == 1) {
                if (this.ncc.hilst == 1) {
                    this.ncc.hilsv != undefined && (style.margin = '0 ' + this.ncc.hilsv / 2 + 'px');
                }
            } else {
                if (this.ncc.vilst == 1) {
                    this.ncc.vilsv != undefined &&
                        (style.borderTop = this.ncc.vilsv / 2 + 'px transparent solid') &&
                        (style.borderBottom = this.ncc.vilsv / 2 + 'px transparent solid');
                }
            }

            if (level == 'sub') {
                if (this.nav.mfclst == 1) {
                    // 设置heigh=1是不让margin塌陷
                    this.nav.mfclsv != undefined &&
                        (mobiStyle.borderTop = this.nav.mfclsv / 2 + 'px transparent solid') &&
                        (mobiStyle.borderBottom = this.nav.mfclsv / 2 + 'px transparent solid');
                }
            } else if (level == 'three') {
                if (this.nav.msclst == 1) {
                    // 设置heigh=1是不让margin塌陷
                    this.nav.msclsv != undefined &&
                        (mobiStyle.borderTop = this.nav.msclsv / 2 + 'px transparent solid') &&
                        (mobiStyle.borderBottom = this.nav.msclsv / 2 + 'px transparent solid');
                }
            }

            return !this.isPc ? mobiStyle : style;
        },
        spliceClass(_class, list) {
            var id = list.id || list,
                select = [];

            select.push(_class + id);
            if (typeof list === 'undefined') {
                return [];
            }

            if (this.checkChildItemSelect(this.selectedColId, list, _class) && this.isPc) {
                addClasses(select, 'jz_nav_item_selected', 'jz_theme_border_color', 'jz_theme_font_color');
            }
            if (this.hasChildMenu(list.colList3)) {
                addClass(select, 'sub_child_item');
            }

            if (this.isShowNavStyleCss) {
                addClass(select, 'jz_theme_font_color');
            }

            return select;
        },
        getChildColItemStyle(level, list) {
            if (!this.manageMode) {
                return {};
            }
            var style = {},
                mobiStyle = {},
                bGstyle = {};

            // 非公用设置项
            if (this.ncc.t == 1) {
                if (this.ncc.hiwht == 1) {
                    this.ncc.hiw != undefined && (style.width = this.ncc.hiw + 'px');
                    this.ncc.hih != undefined &&
                        (style.height = this.ncc.hih + 'px') &&
                        (style.lineHeight = this.ncc.hih + 'px');
                }
                if (this.ncc.hcpt == 1) {
                    this.ncc.hcpp != undefined && (style.paddingTop = this.ncc.hcpp + 'px');
                    this.ncc.hcpl != undefined && (style.paddingLeft = this.ncc.hcpl + 'px');
                    this.ncc.hcpr != undefined && (style.paddingRight = this.ncc.hcpr + 'px');
                    this.ncc.hcpb != undefined && (style.paddingBottom = this.ncc.hcpb + 'px');
                }
            } else {
                if (this.ncc.viwht == 1) {
                    // 宽高
                    this.ncc.viw != undefined && (style.width = this.ncc.viw + 'px');
                    this.ncc.vih != undefined &&
                        (style.height = this.ncc.vih + 'px') &&
                        (style.lineHeight = this.ncc.vih + 'px');
                }
                if (this.ncc.vcpt == 1) {
                    this.ncc.vcpp != undefined && (style.paddingTop = this.ncc.vcpp + 'px');
                    this.ncc.vcpl != undefined && (style.paddingLeft = this.ncc.vcpl + 'px');
                    this.ncc.vcpr != undefined && (style.paddingRight = this.ncc.vcpr + 'px');
                    this.ncc.vcpb != undefined && (style.paddingBottom = this.ncc.vcpb + 'px');
                }
            }
            // 共用设置项
            if (this.ncc.ib && this.ncc.ib.y == 1) {
                // 边框
                this.ncc.ib.w != undefined && (style.borderWidth = this.ncc.ib.w + 'px');
                !!this.ncc.ib.c && (style.borderColor = this.ncc.ib.c);
                !this.ncc.ib.t && (style.borderTopStyle = 'none');
                !this.ncc.ib.b && (style.borderBottomStyle = 'none');
                !this.ncc.ib.l && (style.borderLeftStyle = 'none');
                !this.ncc.ib.r && (style.borderRightStyle = 'none');
            }

            if (this.ncc.ibrt == 1) {
                // 圆角
                this.ncc.ibrv != undefined && (style.borderRadius = this.ncc.ibrv + 'px');
            }

            if (this.ncc.iost >= 1) {
                // 外阴影
                style.boxShadow = this.ncc.iost == 1 ? '0 2px 6px 0 rgba(0,0,0,.1)' : 'none';
            }

            if (level == 'sub') {
                if (this.nav.mfcwt == 1) {
                    this.nav.mfcw != undefined && (mobiStyle.width = this.nav.mfcw + 'px');
                }
                if (this.nav.mfcht == 1) {
                    this.nav.mfch != undefined && (mobiStyle.height = this.nav.mfch + 'px');
                }
                if (this.nav.mfcost >= 1) {
                    // 外阴影
                    mobiStyle.boxShadow = this.nav.mfcost == 1 ? '0 2px 6px 0 rgba(0,0,0,.1)' : 'none';
                }
                if (this.nav.mfcbrt == 1) {
                    // 圆角
                    this.nav.mfcbrv != undefined && (mobiStyle.borderRadius = this.nav.mfcbrv + 'px');
                }
            } else if (level == 'three') {
                if (this.nav.mscwt == 1) {
                    this.nav.mscw != undefined && (mobiStyle.width = this.nav.mscw + 'px');
                }
                if (this.nav.mscht == 1) {
                    this.nav.msch != undefined && (mobiStyle.height = this.nav.msch + 'px');
                }
                if (this.nav.mscbrt == 1) {
                    // 圆角
                    this.nav.mscbrv != undefined && (mobiStyle.borderRadius = this.nav.mscbrv + 'px');
                }
            }

            bGstyle = getComputedBg(this.ncc.ibg);
            extend(style, bGstyle);

            if (list.id === this.selectedColId || this.checkSelect(list.colList3, this.selectedColId)) {
                Object.assign(style, this.getSubSelectBgStyle);
            }

            return !this.isPc ? mobiStyle : style;
        },
        getChildColItemAWrapStyle(level, list) {
            if (!this.manageMode) {
                return {};
            }
            var style = {};
            if (this.ncc && this.ncc.if && this.ncc.if.y == 1) {
                this.ncc.if.c != undefined && extend(style, getComputedTextColor(this.ncc.if.c));
            }

            if (list.id === this.selectedColId || this.checkSelect(list.colList3, this.selectedColId)) {
                Object.assign(style, this.getSubSelectStyle);
            }

            return !this.isPc ? {} : style;
        },
        navItemTextClass(_class, list) {
            var select = [];

            if (_class == 'nav_item') {
                if (this.firstNavHasChild) {
                    select.push('nav_child_item_text');
                }
            } else if (_class == 'sub_item') {
                if (this.hasChildMenu(list.colList3)) {
                    select.push('nav_child_item_text');
                }
            }
            select.push('jz_theme_font_hover_color');
            return select;
        },
        getChildColItemAStyle() {
            let style = {};
            if (this.ncc.ta == 1) {
                style['text-align'] = 'left';
            }
            if (!this.manageMode) {
                return style;
            }
            style.color = 'inherit';
            if (this.ncc.if && this.ncc.if.y == 1) {
                this.ncc.if.d != undefined && (style.textDecoration = this.ncc.if.d == 1 ? 'underline' : 'none');
                // 字体
                this.ncc.if.s != undefined && (style.fontSize = this.ncc.if.s + 'px');
                this.ncc.if.f != undefined && (style.fontFamily = this.ncc.if.f);
                this.ncc.if.i != undefined && (style.fontStyle = this.ncc.if.i == 1 ? 'italic' : 'normal');
                this.ncc.if.w != undefined && (style.fontWeight = this.ncc.if.w == 1 ? 'bold' : 'normal');
            }

            return this.isPc ? style : {};
        },
        checkChildItemSelect(selectedColId, item, _class) {
            var arr,
                isSelect = false;

            const normalSelected = selectedColId == item.id;

            if ((normalSelected && !this.hasActiveAnchor) || this.isActiveAnchor(item)) {
                return true;
            } else {
                if (_class == 'nav_item' || _class == 'nav_sub_item') {
                    arr = _class == 'nav_item' ? item.colList2 : item.colList3;
                    isSelect = this.checkSelect(arr, selectedColId);
                }
                return isSelect;
            }
        },
        isActiveAnchor(navItem) {
            if (!ScrollAnchorService.isAnchorHref(navItem.url)) {
                return false;
            }
            // 锚点选中
            const { rowId, colId, moduleId } = ScrollAnchorService.parseAnchorHref(navItem.url);

            if (this.colId !== colId) return false;
            if (rowId !== -1) {
                return this.activeAnchor.rowId === rowId;
            } else if (moduleId !== -1) {
                return this.activeAnchor.moduleId === moduleId;
            } else {
                return false;
            }
        },
        checkSelect(arr, selectedColId) {
            var isSelect = false,
                col3;
            if (!arr) {
                return false;
            }
            arr.forEach((item) => {
                if ((item.id == selectedColId && !this.hasActiveAnchor) || this.isActiveAnchor(item)) {
                    isSelect = true;
                    return false;
                } else {
                    col3 = item.colList3;
                    !!col3 &&
                        !!col3.length &&
                        col3.forEach((item) => {
                            if ((item.id == selectedColId && !this.hasActiveAnchor) || this.isActiveAnchor(item)) {
                                isSelect = true;
                                return false;
                            }
                        });
                }
            });
            return isSelect;
        },
        getNavColTextStyle(list) {
            if (!this.manageMode || !this.isPc) {
                return {};
            }

            var colStyle = {};

            if (this.nav.cf && this.nav.cf.y == 1) {
                // 字体
                this.nav.cf.s !== undefined && (colStyle.fontSize = this.nav.cf.s + 'px');
                this.nav.cf.f !== undefined && (colStyle.fontFamily = this.nav.cf.f);
                this.nav.cf.i !== undefined && (colStyle.fontStyle = this.nav.cf.i == 1 ? 'italic' : 'normal');
                this.nav.cf.w !== undefined && (colStyle.fontWeight = this.nav.cf.w == 1 ? 'bold' : 'normal');
                this.nav.cf.d !== undefined && (colStyle.textDecoration = this.nav.cf.d == 1 ? 'underline' : 'none');
            }

            if (this.nav.cf && this.nav.cf.y == 1) {
                // 字体
                if (this.nav.cf.sc != undefined) {
                    extend(colStyle, getComputedTextColor(this.nav.cf.c));
                    if (list.id == this.selectedColId || this.checkSelect(list.colList2, this.selectedColId)) {
                        extend(colStyle, getComputedTextColor(this.nav.cf.sc));
                    } else {
                        extend(colStyle, getComputedTextColor(this.nav.cf.c));
                    }
                    colStyle.color = 'inherit';
                }
            }

            return colStyle;
        },
        openLink(event, url, target, level) {
            let $target = $(event.target),
                href = $target.attr('href') || '',
                isOpenFixedTop = !this.manageMode
                    ? !window._store.state.header.isOpenFixedTop
                    : !this.info.pattern.header.fixedTop;
            if (
                isOpenFixedTop &&
                (href.includes('javascript:JZ.jumpToAnchor') || url.includes('javascript:JZ.jumpToAnchor'))
            ) {
                $('.sub_nav_list_active').removeClass('sub_nav_list_active');
            }
            //window.open(url, target);
            this.changeHref(event, url, level);
        },
    },
};
