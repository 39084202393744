/**
 * 创建弹层
 * @param {Obejct} foo
 * @param {String} foo.content 弹层内容
 * @param {String} foo.type 弹层类型
 * @param {Boolean} foo.autoHide 是否自动关闭
 * @param {String} foo.confirmButtonText 确定按钮的文本
 * @param {String} foo.cancelButtonText 取消按钮的文本
 * @param {String} foo.warmIcon 弹窗的中间图标（目前只加在warm弹窗）
 * @param {Boolean} foo.showClose 显示关闭按钮
 * @param {Boolean} foo.showLayout 显示遮罩层（禁用滚动穿透）
 * @param {Function} foo.onConfirm 确认的回调函数
 * @param {Function} foo.onCancel 取消的回调函数
 * @param {Function} foo.onCancel 关闭的回调函数
 * @returns {Object} 弹层节点的jQuery对象
 */
function createPopup({
    content = '',
    type = '',
    autoHide = false,
    confirmButtonText = '',
    cancelButtonText = '',
    showClose = false,
    showLayout = false,
    onConfirm,
    onCancel,
    onClose,
    warmIcon,
}) {
    const types = {
        warning: 'warning',
        success: 'success',
    };

    const realType = types[type] || '';
    const hasButtons = confirmButtonText !== '' || cancelButtonText !== '';
    const realShowClose = showClose || hasButtons || !autoHide;

    // template
    let html = '';
    html += '<div class="c_popup">';
    if (showLayout) {
        html += '<div class="c_popup_layout"></div>';
    }
    html += '<div class="c_popup_main">';
    if (realType !== '') {
        html += '<div class="c_popup_icon">';
        if (realType == 'warning') {
            html += `<i class="${warmIcon || 'i_warning'}"></i>`;
        } else if (realType == 'success') {
            html += '<div class="i_success">';
            html += '<div class="i_success_placeholder"></div>';
            html += '<i class="i_success_bingo"></i>';
            html += '</div>';
        }
        html += '</div>';
    }
    html += '<div class="c_popup_content">';
    html += content;
    html += '</div>';
    if (hasButtons) {
        html += '<div class="c_popup_buttons">';
        if (confirmButtonText !== '') {
            html +=
                '<button class="c_popup_button c_popup_button__active J_popupButtonConfirm">' +
                confirmButtonText +
                '</button>';
        }
        if (cancelButtonText !== '') {
            html += '<button class="c_popup_button J_popupButtonCancel">' + cancelButtonText + '</button>';
        }
        html += '</div>';
    }
    if (realShowClose) {
        html += '<i class="c_popup_close i_close J_popupClose faisco-icons-S000118"></i>';
    }
    html += '</div>';
    html += '</div>';

    // sideEffects
    const $dom = $(html);
    const handleClose = () => {
        $dom.fadeOut(() => {
            $dom.remove();
        });
    };
    $dom.find('.J_popupButtonConfirm').on('click.confirm', () => {
        handleClose();
        if (typeof onConfirm == 'function') {
            onConfirm();
        }
    });
    $dom.find('.J_popupButtonCancel').on('click.cancel', () => {
        handleClose();
        if (typeof onCancel == 'function') {
            onCancel();
        }
    });
    $dom.find('.J_popupClose').on('click.close', () => {
        handleClose();
        if (typeof onClose == 'function') {
            onClose();
        }
    });

    $dom.appendTo(
        Fai.top._store.state.manageMode && Comm.getDevice() == 'mobi' ? $('#jzPreviewContent') : 'body'
    ).fadeIn(
        autoHide
            ? () => {
                  setTimeout(handleClose, 2000);
              }
            : null
    );

    if (showLayout) {
        $dom.off('mousewheel.mw').on('mousewheel.mw', function (e) {
            e.stopPropagation();
            e.preventDefault();
        });
    } else {
        $dom.off('mousewheel.mw');
    }

    return $dom;
}

/**
 * 创建对话框（访客态）
 * @param {any} args
 * @returns {any} createPopup的返回结果
 */
export const createDialog = (...args) => {
    return createPopup(...args);
};

/**
 * 警告提示语（访客态）
 * @param {String} content 提示语内容
 * @param {Boolean} autoHide 是否自动关闭
 * @param {String} warmIcon 自定义弹窗的图标
 * @returns {any} createPopup的返回结果
 */
export const warningMessage = (content = '', autoHide = false, warmIcon) => {
    return createPopup({
        type: 'warning',
        content,
        autoHide,
        warmIcon,
    });
};

/**
 * 成功提示语（访客态）
 * @param {String} content 提示语内容
 * @param {Boolean} autoHide 是否自动关闭
 * @returns {any} createPopup的返回结果
 */
export const successMessage = (content = '', autoHide = false) => {
    return createPopup({
        type: 'success',
        content,
        autoHide,
    });
};
