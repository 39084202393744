import { store } from '@/store/store.js';
import { logDog } from '@api/log/index.js';
import { browser } from '@jz/utils';
/**
 * detectZoomDialog
 */
let diaLogVue = null;
let detectZoomDiaLog = () => {
    if (typeof window.Vue !== 'undefined') {
        if (!diaLogVue) {
            const template = `
                <div id="jzPopup">
                    <popover-component id="detectZoomPopup" :w='569' :h='379' @close='handleClose'>
                        <template slot="content">
                            <span class='detectZoomDiaLog' style="display: inline-block;width: 480px;text-align: left;">
                                检查到你当前的页面缩放比例不正确，将影响部分功能的正常使用。
                                建议调整回正常比例（快捷键：ctrl+0）
                            </span>
                            <checkbox-component style="position:absolute;left:45px;top:250px;" v-model="showDetectZoomTips" @on-change="select"  class="ex-checkbox">不再提示</checkbox-component>
                        </template>
                        <div slot="operate">
                            <button-component active @click="handleSure">我知道了</button-component>
                        </div>
                    </popover-component>
                </div>`;
            $('body').append(template);
            diaLogVue = new window.Vue({
                el: '#jzPopup',
                data: {
                    showDetectZoomTips: false,
                    cookieFlag: false,
                },
                methods: {
                    select(val) {
                        this.cookieFlag = val;
                    },
                    handleSure() {
                        this.cookieFlag &&
                            $.cookie(
                                `showDetectZoomTips_${window._store.state.aid}_${window._store.state.siteId}`,
                                true,
                                { expires: 999999 }
                            );
                        $('#jzPopup').fadeOut();
                        this.cookieFlag && logDog(201515, 5);
                        logDog(201515, 3);
                    },
                    handleClose() {
                        logDog(201515, 4);
                        $('#jzPopup').fadeOut();
                    },
                },
                mounted() {
                    logDog(201515, 2);
                },
            });
        } else {
            logDog(201515, 2);
            $('#jzPopup').fadeIn();
            detectZoomDiaLog = () => {
                $('#jzPopup').fadeIn();
                logDog(201515, 2);
            };
        }
    }
};
export class DetectZoom {
    constructor() {
        this.body = $('body');
        this.type = this.checkType();
        this.detectZoomFunc = {
            firefox: () => {
                return window.devicePixelRatio ? window.devicePixelRatio : undefined;
            },
            chrome: () => {
                if (window.devicePixelRatio) return window.devicePixelRatio;
                return undefined;
            },
        };
        this.timer = null;
        this.init();
    }
    init() {
        this.keydown();
        this.resize();
        this.setRatio();
    }
    checkType() {
        if (window.devicePixelRatio && !browser.isSafari()) {
            return 'devicePixelRatio';
        } else {
            return 'detectZoom';
        }
    }
    diaLog() {
        detectZoomDiaLog();
    }
    setRatio() {
        const isShowDetectZoomTips = $.cookie(`showDetectZoomTips_${store.state.aid}_${store.state.siteId}`);
        const isScale = this.checkZoomScale();
        setTimeout(() => {
            this.body.attr('zoom', !isScale ? '' : 'scale');
            !isShowDetectZoomTips && isScale && this.diaLog();
        }, 50);
    }
    // 因为鼠标滚轮和按键操作都是频繁触发的，应该降低触发处理函数的频率
    debounce(time, func) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            func.call(this);
        }, time);
    }
    checkZoomScale() {
        let zoom = this.detectZoom();
        let sys = this.system();
        if (zoom === undefined) {
            return false;
        }
        return ('win' == sys && 1 != zoom) || ('mac' == sys && zoom % 1 != 0 && zoom % 2 != 0) || false;
    }
    detectZoom() {
        return this.detectZoomFunc[this.getBrowser()] ? this.detectZoomFunc[this.getBrowser()]() : undefined;
    }
    getBrowser() {
        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('firefox') >= 0
            ? 'firefox'
            : ua.indexOf('chrome') >= 0
            ? 'chrome'
            : ua.indexOf('opera') >= 0
            ? 'opera'
            : ua.indexOf('safari') >= 0
            ? 'safari'
            : void 0;
    }
    system() {
        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('win') >= 0 ? 'win' : ua.indexOf('mac') >= 0 ? 'mac' : false;
    }
    resize() {
        $(window).on('resize.detectZoom', () => {
            this.debounce(200, this.setRatio);
        });
    }
    keydown() {
        $(document).on('keydown.detectZoom', (e) => {
            if (e.ctrlKey) {
                switch (e.which) {
                    case 48: /*0*/
                    case 96: /*0*/
                    case 187: /*+*/
                    case 189:
                        this.debounce(200, this.setRatio);
                        break;
                }
            }
        });
    }
}
