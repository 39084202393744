var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product_list_content module_content_detail",
      class: _vm.moduleClasses,
    },
    [
      _c("div", { staticClass: "jz_scroll_wrap" }, [
        _c(
          "div",
          {
            staticClass: "exhibition_show_products jz_scroll",
            class: _vm.exhibitionShowProductsClass,
            attrs: { productsinrow: _vm.productsInRow },
          },
          _vm._l(_vm.productList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "animate",
                    rawName: "v-animate",
                    value: _vm.module,
                    expression: "module",
                  },
                  {
                    name: "hovermenu",
                    rawName: "v-hovermenu",
                    value: {
                      hovermenu: _vm.hovermenu,
                      args: {
                        moduleId: _vm.id,
                        productId: item.id,
                      },
                    },
                    expression:
                      "{\n                    hovermenu,\n                    args: {\n                        moduleId: id,\n                        productId: item.id,\n                    },\n                }",
                  },
                ],
                key: item.id,
                staticClass: "exhibition_show_product",
                class: _vm.exhibitionShowProductClass,
                style: _vm.productStyle,
                on: {
                  mouseenter: function ($event) {
                    return _vm.handleMouseenter(item)
                  },
                  mouseleave: function ($event) {
                    return _vm.handleMouseleave(item)
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "exhibition_show_product_wrap jz_hover jz_theme_font_hover_color jz_theme_border_hover_color",
                    class: { border_bottom_transition: _vm.isNonePicStyle },
                    style: _vm.propAlign,
                    attrs: {
                      href: _vm.setQueryString(
                        item.url,
                        "fromColId",
                        _vm.colId
                      ),
                      target: _vm.imgJump,
                      onclick: _vm.createOnclickStr(item),
                    },
                    on: { click: _vm.setHistoryCurrentPage },
                  },
                  [
                    !_vm.isNonePicStyle
                      ? [
                          _c("ImageComponent", {
                            directives: [
                              {
                                name: "lazyload2",
                                rawName: "v-lazyload2",
                                value: {
                                  id: _vm.module.id,
                                  src: item.picPath,
                                  naturalWidth: item.picWidth,
                                  naturalHeight: item.picHeight,
                                },
                                expression:
                                  "{\n                                id: module.id,\n                                src: item.picPath,\n                                naturalWidth: item.picWidth,\n                                naturalHeight: item.picHeight,\n                            }",
                              },
                            ],
                            staticClass: "exhibition_product_pic_item",
                            style: _vm.picItemStyle,
                            attrs: {
                              src: item.picPath,
                              webp: _vm.webpOptOpen,
                              "wrap-class": "exhibition_product_pic_wrap",
                              "wrap-style": _vm.productImgSize,
                              quality: "700",
                              "img-effects": _vm.isMobi
                                ? { type: 0 }
                                : _vm.imgOptsComputed,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "exhibition_prop",
                              style: _vm.productPropSize,
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "exhibition_product_name_wrap" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "exhibition_product_name jz_hover jz_theme_font_hover_color",
                                      style: Object.assign(
                                        {},
                                        _vm.nameStyle,
                                        _vm.getHoverStyle(item)
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.name) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isLinkProduct(item.productType)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "exhibition_product_prop_wrap",
                                    },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "exhibition_product_props",
                                        },
                                        [
                                          _vm._l(
                                            _vm.propList["lib_" + item.lid],
                                            function (list, i) {
                                              return [
                                                list.type != 11 &&
                                                list.type != 12 &&
                                                _vm.showList[
                                                  "lib_" + item.lid
                                                ].indexOf(list.id) != -1 &&
                                                list.allowed
                                                  ? _c(
                                                      "li",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "exhibition_product_prop",
                                                        style: _vm.propStyle,
                                                      },
                                                      [
                                                        !_vm.hidePropNameSetting
                                                          ? _c("span", {
                                                              staticClass:
                                                                "prop_title",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.encodeHtmlStr(
                                                                      list.name
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "prop_value",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item[
                                                                  list.fieldKey
                                                                ]
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isLinkProduct(item.productType)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "exhibition_product_pirce_wrap",
                                    },
                                    [
                                      _vm.isPriceVisible(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "exhibition_product_pirce jz_theme_font_color",
                                              style: _vm.priceStyle,
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "pirce_coln",
                                                  style: _vm.prizeResetStyle,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.choiceCurrencyVal
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "pirce_value",
                                                  style: _vm.prizeResetStyle,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatPrice(item)
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isMallMarketPriceVisible(item)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "exhibition_product_pirce jz_theme_font_color marketPrice",
                                              style: _vm.marketPriceStyle,
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pirce_coln" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.choiceCurrencyVal
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "pirce_value" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatMarketPrice(
                                                        item
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.module.openProductForm &&
                              !_vm.flagIsHideFormBtn &&
                              !_vm.isLinkProduct(item.productType)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "exhibition_product_btn_wrap",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "exhibition_product_form_btn jz_theme_bg_color",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openForm(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "exhibition_product_form_btn_text",
                                            },
                                            [_vm._v(_vm._s(_vm.formBtnText))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "exhibition_product_name",
                              style: Object.assign(
                                {},
                                _vm.nameStyle,
                                _vm.getHoverStyle(item)
                              ),
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          !_vm.isLinkProduct(item.productType)
                            ? _c(
                                "ul",
                                { staticClass: "exhibition_product_props" },
                                [
                                  _vm._l(
                                    _vm.propList["lib_" + item.lid],
                                    function (list, i) {
                                      return [
                                        list.type != 11 &&
                                        list.type != 12 &&
                                        _vm.showList["lib_" + item.lid].indexOf(
                                          list.id
                                        ) != -1 &&
                                        !(
                                          _vm.hidePropNameSetting &&
                                          item[list.fieldKey] == ""
                                        )
                                          ? _c(
                                              "li",
                                              {
                                                key: i,
                                                staticClass:
                                                  "exhibition_product_prop",
                                                style: _vm.propStyle,
                                              },
                                              [
                                                !_vm.hidePropNameSetting
                                                  ? _c("span", {
                                                      staticClass: "prop_title",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.encodeHtmlStr(
                                                            list.name
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "prop_value" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item[list.fieldKey]
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLinkProduct(item.productType)
                            ? _c(
                                "div",
                                {
                                  staticClass: "exhibition_product_pirce_wrap",
                                },
                                [
                                  _vm.isPriceVisible(item)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "exhibition_product_pirce jz_theme_font_color",
                                          style: _vm.priceStyle,
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "pirce_coln",
                                              style: _vm.prizeResetStyle,
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.choiceCurrencyVal)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "pirce_value",
                                              style: _vm.prizeResetStyle,
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.formatPrice(item))
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMallMarketPriceVisible(item)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "exhibition_product_pirce jz_theme_font_color marketPrice",
                                          style: _vm.marketPriceStyle,
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pirce_coln" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.choiceCurrencyVal)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "pirce_value" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatMarketPrice(item)
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.module.openProductForm &&
                          !_vm.flagIsHideFormBtn &&
                          !_vm.isLinkProduct(item.productType)
                            ? _c(
                                "div",
                                { staticClass: "exhibition_product_btn_wrap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "exhibition_product_form_btn jz_theme_bg_color",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openForm(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "exhibition_product_form_btn_text",
                                        },
                                        [_vm._v(_vm._s(_vm.formBtnText))]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.module.prop0 === 2 && index !== _vm.productList.length - 1
                  ? _c("div", {
                      staticClass: "product_list_style_2_separator_line",
                      style: {
                        bottom:
                          _vm.model.pmt == 1
                            ? "-" + _vm.model.pmv / 2 + "px"
                            : "",
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("a", { staticClass: "arrow prev" }),
        _vm._v(" "),
        _c("a", { staticClass: "arrow next" }),
      ]),
      _vm._v(" "),
      _vm.manageMode &&
      (!_vm.productList ||
        !_vm.productList.length ||
        _vm.productList.length == 0)
        ? _c("empty-tips", {
            attrs: {
              "is-mobi": _vm.isMobi,
              name: "产品",
              "active-button-text": _vm.flagSelectProduct
                ? "添加产品"
                : "选择分类",
              "button-text": _vm.flagSelectProduct ? "选择产品" : "管理分类",
            },
            on: {
              "click-active-button": function ($event) {
                _vm.flagSelectProduct
                  ? _vm.clickAddProductHandler()
                  : _vm.clickSelectProductGroupHandler()
              },
              "click-button": function ($event) {
                _vm.flagSelectProduct
                  ? _vm.clickSelectProductHandler()
                  : _vm.clickManageProductGroupHandler()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPagination
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.handlePaginationPageChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }