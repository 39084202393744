//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getFontTextCss } from '../cssUtils';

export default {
    name: 'ProductDetail',
    components: {
        ChildrenCSS,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        moduleIdSelector() {
            return `#module${this.moduleId}`;
        },
        pcPattern() {
            return this.module.privatePattern['pc'];
        },
        mobiPattern() {
            return this.module.privatePattern['mobi'];
        },
        titleStyle() {
            if (this.pcPattern.p_tf.y !== 1) {
                return '';
            }
            const style = getFontTextCss(this.pcPattern.p_tf);
            return `.jz_screen_pc ${this.moduleIdSelector} .detail_main_title { ${style} }`;
        },

        titleStyleForMobi() {
            if (this.mobiPattern.p_tf.y !== 1) {
                return '';
            }
            const style = getFontTextCss(this.mobiPattern.p_tf);
            return `.jz_screen_mobi ${this.moduleIdSelector} .detail_main_title { ${style} }`;
        },

        productIntroStyle() {
            if (this.pcPattern.p_if.y !== 1) {
                return '';
            }
            const style = getFontTextCss(this.pcPattern.p_if);
            return `.jz_screen_pc ${this.moduleIdSelector} .product_desc { ${style} }`;
        },
        productParamsStyle() {
            if (this.pcPattern.p_ppf.y !== 1) {
                return '';
            }
            const style = getFontTextCss(this.pcPattern.p_ppf);
            return `.jz_screen_pc ${this.moduleIdSelector} .detail_other_message .detail_title, 
                        .jz_screen_pc ${this.moduleIdSelector} .detail_other_message .detail_value {
                            ${style} 
                        }`;
        },

        productParamStyleForMobi() {
            if (this.mobiPattern.p_ppf.y !== 1) {
                return '';
            }
            const style = getFontTextCss(this.mobiPattern.p_ppf);
            return `.jz_screen_mobi ${this.moduleIdSelector} .detail_other_message .detail_title, 
                        .jz_screen_mobi ${this.moduleIdSelector} .detail_other_message .detail_value,
                        .jz_screen_mobi ${this.moduleIdSelector} .detail_other .detail_title,
                        .jz_screen_mobi ${this.moduleIdSelector} .detail_other .detail_value {
                            ${style} 
                        }`;
        },
    },
};
