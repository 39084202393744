//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bindShareMouseenter, bindShareMouseleave } from '@/components/modules/common/shareComponent/index.js';
import CarouselComponent from './carousel-component.vue';
import { photoGroupInfoMixin, photoGroupDetailMixin } from '../mixin';
import JzShareSsrCompon from '@/components/modules/common/shareCompon/index.vue';
import HtmlRenderer from '@/components/htmlRenderer/index.vue';

export default {
    name: 'pc-view',
    components: {
        CarouselComponent,
        JzShareSsrCompon,
        HtmlRenderer,
    },
    mixins: [photoGroupInfoMixin, photoGroupDetailMixin],
    props: ['module', 'id'],
    methods: {
        jzShareMouseenter(event) {
            bindShareMouseenter($(event.target));
        },
        jzShareMouseleave(event) {
            bindShareMouseleave($(event.target));
        },
    },
};
