//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { mapFlag } = Comm;
import unitCustomSearchEntry from '../customSearch/unitCustomSearchEntry.vue';
import { PreviewImgPopup } from './previewImgPopup.js';
import { getCustomSearchData } from '@shared/manage/customSearch/index.js';
import { getCustomSearchDataByPaginationRequest } from '@api/customSearch/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import FileView from './FileView.vue';

/**
 *
 * @param {Object} param0
 * @param {Number} param0.searchId - 要查询的项目的id
 * @param {Array} param0.condition - 查询条件
 * @param {Number} param0.pageNum - 要查询的页数
 */
function getCustomSearchDataByPagination({ searchId, condition, pageNum }) {
    return new Promise((resolve, reject) => {
        getCustomSearchDataByPaginationRequest({
            searchId,
            condition: $.toJSON(condition),
            pageNum,
        })
            .then((data) => (data.success ? resolve(data) : reject(data)))
            .catch((err) => {
                reject(err);
            });
    });
}

export default {
    name: 'CustomSearchResult',
    style: 25,
    components: {
        unitCustomSearchEntry,
        PaginationComponent,
        FileView,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            resultData: this.module.initalState.resultData || [],
            currentPage: 1,
            previewVm: null,
        };
    },
    asyncComputed: {
        asyncProjectInfo: {
            get() {
                const { searchId } = this.initalState;
                return getCustomSearchData().then((data) => {
                    return data.find(({ id }) => id == searchId) || {};
                });
            },
            default: {},
            lazy: true,
        },
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        ...mapFlag({
            hideEntry: 0x1,
            hideTitle: 0x2,
            hideTips: 0x4,
            hideResultExplain: 0x8,
        }),
        ...mapFlag(
            {
                open: 0x1,
            },
            'projectInfo.flag'
        ),
        initalState() {
            return this.module.initalState;
        },
        Loc() {
            return this.initalState.Loc || {};
        },
        projectInfo() {
            return this.$isServer || !this.manageMode ? this.initalState.projectInfo : this.asyncProjectInfo;
        },
        itemList() {
            return this.projectInfo.itemList || [];
        },
        itemListCondited() {
            return this.itemList.filter(({ isCondition }) => isCondition);
        },
        resultList() {
            return this.resultData.map((result) =>
                this.itemList
                    .map(({ id, itemName, type }) => {
                        let comm = {
                            name: itemName,
                            type: type,
                            value: result[`prop${id}`],
                        };
                        if (type == 4) {
                            return Object.assign({}, comm, {
                                path: result[`fileItem${id}`].path || ``,
                                downloadPath: result[`fileItem${id}`].downloadPath || ``,
                                fileName: result[`fileItem${id}`].fileName || ``,
                                isDefault: result[`fileItem${id}`].isDefault,
                            })
                        }  else if (type == 5) {
                            let fileName = result[`fileItem${id}`].fileName || '';
                            return Object.assign({}, comm, {
                                path: result[`fileItem${id}`].path || ``,
                                downloadPath: result[`fileItem${id}`].downloadPath || ``,
                                fileName: result[`fileItem${id}`].fileName || ``,
                                isDefault: result[`fileItem${id}`].isDefault,
                                isImage: /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/.test(fileName),
                                isPdf: /\.(pdf|PDF)$/.test(fileName),
                            });
                        } else  {
                            return comm;
                        }
                    })
                    .filter(({ value }) => value)
            );
        },
        paginationPageCount() {
            return Math.ceil((this.initalState.totalSize || 0) / this.paginationPageSize);
        },
        paginationPageSize() {
            return this.initalState.searchLimit || 10;
        },
    },
    mounted() {
        this.previewVm = new PreviewImgPopup();
    },
    methods: {
        handlePaginationPageChange(pageNum) {
            const { searchId, condition } = this.initalState;
            getCustomSearchDataByPagination({
                pageNum,
                searchId,
                condition,
            }).then(({ resultData }) => {
                this.resultData = resultData;
                this.currentPage = pageNum;
            });
        },
        imgStyle(item) {
            let scaleType = {
                0: 'cover',
                1: 'cover',
                2: 'auto',
                3: 'contain',
            };
            return {
                'background-size': scaleType[this.module.prop0],
                'background-image': `url(${item.path})`,
            };
        },
        changePreviewImg(imgData) {
            this.previewVm.toggle(imgData);
        },
    },
};
