//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { warningMessage } from '@/site/shared/dialog/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { loginMember } from '@/api/member/index.js';
import {
    _enableLoginBtn,
    _getReturnUrl,
    loginByWechat,
    loginBySina,
    _checkValid,
    _disableLoginBtn,
    _showLoginActiveEmailMsg,
    _showLoginErrorMsg,
    refreshCaptcha,
    getAcquireCode,
    memberFastLogin,
    memberFdPwdStepOne,
    loginByQQ,
    otherLoginAfterUrlBack,
} from './index.js';
export default {
    name: 'MemberLogin',
    style: 12,
    props: ['module'],
    data() {
        return {
            isShowOtherLogin: false,
            isFastLogin: false,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'isDemo', 'LS']),
        isShowMemberLogin() {
            if (this.manageMode) {
                return this.memberLoginData.hasOtherLogin;
            } else {
                return this.isShowOtherLogin;
            }
        },
        isWeiXin() {
            return Fai.isWeixin();
        },
        memberLoginData() {
            return this.module.data || {};
        },
    },
    mounted() {
        this.isShowWxOpen();
        this.checkOtherLogin();
    },
    methods: {
        checkOtherLogin() {
            if (this.manageMode) {
                return;
            }
            const loginType = Fai.getUrlParam(window.location.href, 'loginType');
            if (!loginType) {
                return;
            }
            if (!this.module.data) {
                return;
            }
            otherLoginAfterUrlBack(this.module.data);
        },
        clickCheck() {
            if (this.manageMode) {
                Message.warning('您当前处理网站管理状态，可点击右上方“预览”查看效果。');
                return false;
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
                return false;
            }
            return true;
        },
        findPwdClick() {
            if (this.clickCheck()) {
                memberFdPwdStepOne(this.module.id);
            }
        },
        fastLoginBtnClick() {
            if (this.clickCheck()) {
                memberFastLogin(this.module.id, this.module.data);
            }
        },
        acquireCodeClick() {
            if (this.clickCheck()) {
                getAcquireCode(this.module.id);
            }
        },
        acctLoginClick() {
            this.isFastLogin = false;
        },
        fastLoginClick() {
            this.isFastLogin = true;
        },
        loginCaptchaClick() {
            if (this.clickCheck()) {
                refreshCaptcha(this.module.id);
            }
        },
        loginBtnClick() {
            if (this.clickCheck()) {
                this.memberLogin(this.module.id, this.module.data);
            }
        },
        memberLogin(moduleId, extendParam) {
            // 进行检测
            const { valid, data } = _checkValid(moduleId);
            if (!valid) {
                return;
            }
            _disableLoginBtn(moduleId);
            let param = {};
            data.split('&').forEach((strKey) => {
                let [key, value] = strKey.split('=');
                param[key] = value;
            });
            new Promise((resovle, reject) => {
                loginMember({
                    ...param,
                })
                    .then(async (data) => {
                        $('#_TOKEN')?.attr('value') || $('title')?.after(data._TOKEN);
                        await bizShared?.clueReuqestVm?.authRecordLoginOther();
                        data.success ? resovle(data) : reject(data);
                    })
                    .catch(() => {
                        _enableLoginBtn(moduleId);
                        warningMessage(LS.js_systemError, true);
                    });
            })
                .then(() => {
                    _enableLoginBtn(moduleId);

                    if (extendParam.skipUrl) {
                        window.location.href = extendParam.skipUrl;
                    } else {
                        const returnUrl = _getReturnUrl();
                        window.location.href = returnUrl || Site.preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp';
                    }
                })
                .catch((data = {}) => {
                    _enableLoginBtn(moduleId);

                    if (data.active) {
                        _showLoginActiveEmailMsg({
                            emailName: data.mail,
                            acctName: data.memName,
                        });
                    } else if (data.rt == 2000) {
                        window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=-307';
                    } else {
                        _showLoginErrorMsg({ data, moduleId });
                    }
                });
        },
        loginBySina() {
            if (this.clickCheck()) {
                loginBySina(this.memberLoginData);
            }
        },
        loginByQQ() {
            if (this.clickCheck()) {
                loginByQQ(this.memberLoginData);
            }
        },
        loginByWeChat() {
            if (this.clickCheck()) {
                loginByWechat(this.memberLoginData);
            }
        },
        isShowWxOpen() {
            if (this.manageMode) return;
            if (
                this.memberLoginData.hasOtherLogin &&
                this.memberLoginData.wxOpen &&
                !this.memberLoginData.sinaOpenn &&
                !this.memberLoginData.qqOpen
            ) {
                // 只有单独开启微信登录，才去判断是否为微信浏览器再显示
                if (this.isWeiXin || $('html').hasClass('jz_screen_pc')) {
                    this.isShowOtherLogin = true;
                }
            } else {
                this.isShowOtherLogin = this.memberLoginData.hasOtherLogin;
                if (
                    this.memberLoginData.hasOtherLogin &&
                    this.memberLoginData.wxOpen &&
                    !this.isWeiXin &&
                    !$('html').hasClass('jz_screen_pc')
                ) {
                    this.memberLoginData.wxOpen = false;
                }
            }
        },
    },
};
