//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getComputedBg } from '../../manage/designer/initContext/utils';
import { getPageScrollTarget } from '@/site/shared/index.js';
import { initDomMutationObserver } from '@/site/shared/mutationObserver/index.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
const manageUse = {};
const SYS_HEADER = 3;
const FIXED_BACKGROUND_DEFAULT = 0;
export default {
    name: 'grid-header',
    props: {
        info: {
            type: Object,

            default() {
                return {};
            },
        },
    },

    data() {
        return {
            isRightPosition: false,
            headerPaddingTop: '',
            headerTopStr: '',
            hideHeaderFlag: false,
            opacityFlag: false,
            headerDomMutation: null,
        };
    },

    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState(['device']),
        ...Vuex.mapState('header', ['headerStyleStatus']),
        ...Vuex.mapState('row', ['rollingScreenRowInfo']),
        pattern() {
            return this.info.pattern || {};
        },

        header() {
            return this.pattern.header || {};
        },

        background() {
            return this.header.background || {};
        },

        fixedBackground() {
            return this.header.fixedBackground || {};
        },

        headerRowIds() {
            return this.info.headerRowIds || [];
        },

        // 是否开启固定顶部
        isOpenFixedTop() {
            return this.header.fixedTop == 1 && this.pattern.rollingScreen.type === 0;
        },

        isFixedTopFloatStyle() {
            return this.header.fixedTop == 1 && this.pattern.rollingScreen.type === 0 && this.header.hftt == 1;
        },

        isUnfixedTopFloatStyle() {
            return this.header.fixedTop == 0 && this.pattern.rollingScreen.type === 0 && this.header.hftt == 1;
        },

        // 当前是否是固定顶部样式
        isFixedBackgroundCurrent() {
            return (
                (this.isOpenFixedTop || (this.isFullScreen && this.pattern.rollingScreen.isFixedTop)) &&
                this.isRightPosition
            );
        },

        // 是否开启固定导航高度
        isOpenFixedHeight() {
            return this.header.fixedHeight == 1;
        },

        // 当前是否固定导航高度
        isFixedHeightCurrent() {
            return (
                (this.isOpenFixedTop || (this.isFullScreen && this.pattern.rollingScreen.isFixedTop)) &&
                this.isRightPosition &&
                this.isOpenFixedHeight
            );
        },

        // 是否是默认固定背景
        isFixedBackgroundDefault() {
            return this.fixedBackground.y == FIXED_BACKGROUND_DEFAULT;
        },

        // 顶部内容区样式
        headerContentStyle() {
            const background = getComputedBg(
                this.isFixedBackgroundCurrent && !this.isFixedBackgroundDefault ? this.fixedBackground : this.background
            );
            return Object.assign(
                {},
                background,
                {
                    top: this.headerTopStr,
                },
                this.isFullScreen
                    ? {
                          opacity: this.opacityFlag ? 0 : 1,
                          transition: this.opacityFlag ? 'none' : 'all 0.3s',
                      }
                    : {}
            );
        },

        headerStyle() {
            let _style = {};
            if (
                !this.isHeaderLevitate &&
                this.isRightPosition &&
                !!this.pattern.rollingScreen.type &&
                this.pattern.rollingScreen.isFixedTop
            ) {
                _style.height = this.headerStyleStatus.headerHeight + 'px';
            }

            _style.paddingTop = this.headerPaddingTop;

            if (this.isFixedTopFloatStyle) {
                // 固定悬浮，不占位
                _style.paddingTop = '';
            }

            return Object.assign(getComputedBg(this.background), _style);
        },

        isHeaderLevitate() {
            // 是否悬浮
            return this.pattern.rollingScreen.type && this.pattern.rollingScreen.topType === 1;
        },
        headerNotFixed() {
            return (
                this.isFullScreen && !this.pattern.rollingScreen.isFixedTop && this.pattern.rollingScreen.topType === 2
            );
        },

        headerIsFixed() {
            return this.isOpenFixedTop || this.pattern.rollingScreen.isFixedTop;
        },

        isActiveFullPage() {
            return (
                this.isFullScreen && !this.pattern.rollingScreen.isFixedTop && this.rollingScreenRowInfo.curIndex === 0
            );
        },

        isHideHeaderContent() {
            return (
                this.isFullScreen && !this.pattern.rollingScreen.isFixedTop && this.rollingScreenRowInfo.curIndex !== 0
            );
        },

        headerContentClass() {
            const classObj = {
                g_header_content__fixed: this.isOpenFixedTop,
                g_header_content_fixed_bg_def: this.isFixedBackgroundDefault,
                g_header_content__levitate: this.isHeaderLevitate,
                g_header_content_not_fixed: this.headerNotFixed,
                active_page: this.isActiveFullPage,
                hide_header_content: this.hideHeaderFlag,
                g_header_content__unfixed_float: this.isUnfixedTopFloatStyle,
                g_header_content_temp__fixed: this.headerIsFixed,
            };
            return classObj;
        },
    },
    watch: {
        isFixedHeightCurrent() {
            this.changeHeaderTop();
        },

        isOpenFixedHeight() {
            this.checkIsRightPosition();
        },

        isOpenFixedTop() {
            this.setOpenFixedTopEvent();
            this.setHeaderPaddingTop();
        },

        isHideHeaderContent(val) {
            // 暂时先延时处理 不做动画---(不能做动画处理 不然衔接会很奇怪)
            switch (this.pattern.rollingScreen.type) {
                case 1:
                    this.hideHeaderFlag = val;
                    break;

                case 2:
                    this.opacityFlag = val;
                    this.hideHeaderFlag = val;
                    break;

                default:
                    break;
            }
        },

        'pattern.rollingScreen.topType'() {
            this.changeHeaderTop();
            this.setPureFullScreenHeight();
        },

        'pattern.rollingScreen.isFixedTop'() {
            this.changeHeaderTop();
            this.setPureFullScreenHeight();
        },

        'header.fixedHeight'() {
            this.$store.commit('header/setIsOpenFixedHeight', this.isOpenFixedHeight);
            this.setPureFullScreenHeight();
        },

        headerRowIds() {
            setTimeout(() => {
                this.setHeaderPaddingTop();
            }, 200);
        },
    },

    mounted() {
        this.checkIsRightPosition();
        this.changeHeaderTop();
        this.setHeaderPaddingTop();
        this.setOpenFixedTopEvent();

        eventBus.$on('headerPaddingTopChange', this.setHeaderPaddingTop); //在管理态从手机视图切换到电脑视图需要fix顶部占位

        const headerContent = this.$refs.headerContent;

        if (typeof headerContent !== 'undefined') {
            window._store.commit('header/changeHeaderStyleStatus', {
                status: false,
            });

            this.device === 'pc' &&
                (this.headerDomMutation = initDomMutationObserver({
                    node: headerContent,
                    id: jzUtils.createRandomId(),
                    callback: () => {
                        let $headerContent = headerContent;
                        const sysHeader = document.getElementById('row' + SYS_HEADER);
                        if (sysHeader && this.isRightPosition != 0 && this.headerIsFixed && this.isOpenFixedHeight) {
                            $headerContent = sysHeader;
                        }
                        const _height = parseFloat(getComputedStyle($headerContent).height);

                        _height !== this.headerStyleStatus.headerHeight &&
                            window._store.commit('header/changeHeaderStyleStatus', {
                                status: true,
                                headerHeight: _height,
                            });
                    },
                }));
        }

        this.switchDevice();
        window._store.commit('header/setIsOpenFixedHeight', this.isOpenFixedHeight);
        window._store.commit(
            'header/setIsOpenFixedTop',
            this.pattern.rollingScreen.type === 0 ? this.header.fixedTop : this.pattern.rollingScreen.isFixedTop
        );

        eventBus.$on('pureFullScreenCurIndexChange', this.pureFullScreenCurIndexChange);
    },

    beforeDestroy() {
        this?.headerDomMutation?.disconnect();
        this.setOpenFixedTopEvent(true);
        eventBus.$off('pureFullScreenCurIndexChange', this.pureFullScreenCurIndexChange);
        eventBus.$off('headerPaddingTopChange', this.setHeaderPaddingTop);
    },

    created() {
        this.hideHeaderFlag = this.isHideHeaderContent;
    },

    methods: {
        pureFullScreenCurIndexChange(val) {
            this.isRightPosition = val;
            this.setPureFullScreenHeight();
        },
        setPureFullScreenHeight() {
            if (!this.isFullScreen) {
                return;
            }
            let headerContent = this.$refs.headerContent;

            const sysHeader = document.getElementById('row' + SYS_HEADER);
            if (sysHeader && this.isRightPosition != 0 && this.headerIsFixed && this.isOpenFixedHeight) {
                headerContent = sysHeader;
            }
            window._store.commit('header/changeHeaderStyleStatus', {
                status: true,
                headerHeight: parseFloat(getComputedStyle(headerContent).height),
            });
        },
        /**
         * 判断是否达到转变样式的位置
         */
        checkIsRightPosition() {
            if (!this.$el || this.pattern.rollingScreen.type !== 0) {
                return;
            }

            const { top, height } = this.$el.getBoundingClientRect(); // 根据是否开启固定导航高度走不同的判断条件

            if (this.isOpenFixedHeight ? Math.abs(top - Comm.getPageTop()) > height : top < Comm.getPageTop()) {
                this.isRightPosition = true;
            } else {
                this.isRightPosition = false;
            }
        },

        /**
         * 若开启了固定顶部，给与顶部节点一个跟顶部内容区一样高的上内边距，作为占位
         */
        setHeaderPaddingTop() {
            if (!this.$refs.headerContent || this.pattern.rollingScreen.type !== 0) {
                return;
            }

            this.headerPaddingTop = this.isOpenFixedTop ? window.getComputedStyle(this.$refs.headerContent).height : '';
        },

        /**
         * 设置开启了固定顶部后的事件
         * @param {Boolean} destory 是否销毁
         */
        setOpenFixedTopEvent(destory = false) {
            this.pageScrollTarget = this.pageScrollTarget || getPageScrollTarget();

            if (!this.pageScrollTarget) {
                return;
            }

            if (!this.isOpenFixedTop || destory) {
                // 添加滚动事件
                this.pageScrollTarget.removeEventListener('scroll', this.checkIsRightPosition);
                this.pageScrollTarget = null;
                window.removeEventListener('resize', this.resizeGridHeaderHandler);
                window.removeEventListener('load', this.resizeGridHeaderHandler);
            } else {
                this.pageScrollTarget.addEventListener('scroll', this.checkIsRightPosition);
                window.addEventListener('resize', this.resizeGridHeaderHandler);
                window.addEventListener('load', this.resizeGridHeaderHandler);
            }
        },

        /**
         * 根据当前是否固定导航高度改变顶部的top值
         */
        changeHeaderTop(pageTop = Comm.getPageTop()) {
            if (this.isFixedHeightCurrent) {
                //固定整个顶部
                if (this.isOpenFixedHeight) {
                    //只固定系统行
                    // const sysHeader = document.getElementById('row' + SYS_HEADER);
                    // if (sysHeader) {
                    // 	let sysHeaderTop = sysHeader.getBoundingClientRect().top;
                    // 	if (this.pattern.rollingScreen.type && this.pattern.rollingScreen.topType === 2) {
                    // 		sysHeaderTop += 61;
                    // 	}
                    // 	// 乘以2是因为需要减掉获取是 已有的Comm.getPageTop（）高度
                    // 	this.headerTopStr = '-' + (sysHeaderTop - 2 * Comm.getPageTop()) + 'px';
                    // 	return;
                    // }
                    const sysHeader = document.getElementById('row' + SYS_HEADER);
                    const headerContent = document.getElementsByClassName('g_header_content')[0];

                    if (sysHeader) {
                        let sysHeaderHeight = sysHeader.getBoundingClientRect().height,
                            headerContentHeight = headerContent.getBoundingClientRect().height,
                            sysHeaderTop = headerContentHeight - sysHeaderHeight - pageTop;

                        if (this.pattern.rollingScreen.type && this.pattern.rollingScreen.topType === 2) {
                            sysHeaderTop += pageTop;
                        }

                        this.headerTopStr = '-' + sysHeaderTop + 'px';
                        return;
                    }
                } else {
                    this.headerTopStr = pageTop + 'px';
                    return;
                }
            }

            if (
                this.pattern.rollingScreen.type &&
                this.pattern.rollingScreen.topType === 1 &&
                this.pattern.rollingScreen.isFixedTop
            ) {
                this.headerTopStr = `${pageTop}px`;
                return;
            }

            this.headerTopStr = '';
        },

        resizeGridHeaderHandler() {
            this.setHeaderPaddingTop();
        },

        switchDevice() {
            var timer = 800;
            var durTime = 1000 / 60;

            const update = () => {
                this.setHeaderPaddingTop();
                this.checkIsRightPosition();
                setTimeout(() => {
                    timer -= durTime;
                    if (timer > 0) update();
                }, durTime);
            };

            update();
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
