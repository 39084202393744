var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("basicPicCompon", {
    ref: "carousel",
    staticClass: "module_content_detail module_carousel_photos_content",
    attrs: {
      "data-photo-count": _vm.photoList.length,
      module: _vm.module,
      "photo-list": _vm.photoList,
      "module-style": _vm.style,
      pattern: _vm.privatePattern,
      "is-mobi": _vm.isMobi,
      "use-photo-group": _vm.usePhotoGroup,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }