//
//
//
//

import index1 from './index1.vue';
import index2 from './index2.vue';
export default {
    components: {
        index1,
        index2,
    },
    props: ['id', 'module'],
    computed: {
        comp() {
            return this.module.prop0 === 0 || this.module.prop0 === 1 ? 'index1' : 'index2';
        },
    },
};
