var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m_custom_search J_customSearch module_content_detail",
      attrs: { "data-search": _vm.projectInfo.id },
    },
    [
      _c(
        "div",
        { staticClass: "m_custom_search--wrap" },
        [
          _vm.itemListCondited.length > 0
            ? _c("unit-custom-search-entry", {
                attrs: {
                  data: _vm.itemListCondited,
                  "hide-title": _vm.handledHideTitle,
                  "hide-tips": _vm.handledHideTips,
                  title: _vm.projectInfo.name,
                  tips: _vm.projectInfo.tip,
                  "search-id": _vm.module.prop0,
                  disabled: !_vm.open,
                  "search-text": _vm.Loc.customSearchText,
                  module: _vm.module,
                },
              })
            : _vm.manageMode
            ? _c("empty-tips", {
                attrs: {
                  "is-mobi": _vm.isDeviceMobi,
                  name: "查询项目",
                  "button-text": "选择查询",
                  "active-button-text": "添加查询",
                },
                on: {
                  "click-button": _vm.clickButtonHandler,
                  "click-active-button": _vm.clickActiveButtonHandler,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }