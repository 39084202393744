export const picMixin = {
    computed: {
        imgWrapStyle() {
            let style = {};
            if (this.privatePattern.ppht == 1) {
                style.paddingBottom = this.privatePattern.pph * 100 + '%';
            }
            //上下图文样式手机高度跟随pc mobi视图 默认保留原先旧逻辑
            if (this.device == 'mobi' && this.privatePattern.ppht == 0 && this.pcPrivatePattern.ppht == 1) {
                style.paddingBottom = this.pcPrivatePattern.pph * 100 + '%';
            }
            return style;
        },
        curPpst() {
            const pps =
                this.privatePattern.ppst !== undefined
                    ? this.privatePattern.ppst
                    : this.privatePattern.pps === undefined
                    ? this.privatePattern.psy
                    : this.privatePattern.pps;
            const pcPps =
                this.pcPrivatePattern.ppst !== undefined
                    ? this.pcPrivatePattern.ppst
                    : this.pcPrivatePattern.pps === undefined
                    ? this.pcPrivatePattern.psy
                    : this.pcPrivatePattern.pps;
            return this.isMobi && pps == 0 ? pcPps : pps;
        },
        imgStyle() {
            var style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }
            return style;
        },
    },
};
