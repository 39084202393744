//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { componentProps } from './componentProps';

export default {
    props: componentProps,
    data() {
        return {
            dropdownPanelActive: false,
        };
    },
    computed: {
        languages() {
            if (this.isMobi) {
                return this.lanList;
            } else {
                return this.lanList.filter((l) => l.lcid !== this.currentLan.lcid);
            }
        },
        hideEvent() {
            return this.isMobi ? 'click' : 'mouseleave';
        },
        dropdownEvents() {
            const e = {};
            e.mouseenter = this.activeDropdownPanel;
            !this.isMobi && (e.mouseleave = this.hideDropdownPanel);
            return e;
        },
        dropdownActiveEvents() {
            const e = {};
            if (this.isMobi) {
                e.click = this.toggleDropdownPanel;
            } else {
                e.mouseenter = this.activeDropdownPanel;
            }
            return e;
        },
        topPosition() {
            return this.areaType === 0;
        },
    },
    methods: {
        activeDropdownPanel() {
            if (this.$refs['languagesDropdownPanel'] && !this.dropdownPanelActive) {
                $(this.$refs['languagesDropdownPanel']).slideDown(() => (this.dropdownPanelActive = true));
            }
        },
        hideDropdownPanel() {
            if (this.$refs['languagesDropdownPanel'] && this.dropdownPanelActive) {
                $(this.$refs['languagesDropdownPanel']).slideUp(() => (this.dropdownPanelActive = false));
            }
        },
        toggleDropdownPanel() {
            if (this.dropdownPanelActive) {
                this.hideDropdownPanel();
            } else {
                this.activeDropdownPanel();
            }
        },
    },
};
