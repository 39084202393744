//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getStyleDataByDevice } = Comm;
import { refreshResAnimation } from '../animation/index.js';
import { initLazyloadTwo } from '@/site/shared/sectionObserver/index.js';
import { InterSectionObserApi } from '@jz/biz-shared';
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import Pack from './Pack.vue';
import PictureTabs from './picture/Tabs.vue';
import tabpackSortable from './mixins/tabpackSortable';
import { pictureChangeDefaultImage } from '@/components/modules/tabpack/picture/pictureDefaultImageLogic.js';
import MobiRightArrowIcon from './units/MobiRightArrowIcon.vue';
import MobiLeftArrowIcon from './units/MobiLeftArrowIcon.vue';
import { encodeHtmlStr } from '@/components/utils.js';
const manageUse = {};
// Project 组件循环引用，导致webpack文件解析异常 project->tabpack->project,
// 目前通过全局注册形式解决
// import Project from '@/business/manage/components/Project.vue';
export default {
    name: 'Tabpack',
    style: 32,
    components: { Pack, PictureTabs, MobiRightArrowIcon, MobiLeftArrowIcon },
    mixins: [tabpackSortable],
    props: ['id', 'module', 'rowid', 'moduleIndex'],
    data() {
        return {
            curIndex: 0,
            hoverIndex: -1,
            isShowArrowLeft: false,
            isShowArrowRight: false,
            maxButtonWidth: 0,
            initTabPackModuleStyleTimer: null,
        };
    },

    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        ...Vuex.mapState('manage', ['funcPanel']),
        ...Vuex.mapGetters(['info', 'isMobi']),
        ...Vuex.mapState('manage/auth', ['designAuth']),
        verticalTabItemClass() {
            if (!this.isVerticalTab) {
                return '';
            }
            return [0, 3].includes(this.styleType) ? 'm_tabpack_bg_white' : '';
        },
        pictureTabClass() {
            if (!this.isPictureTab) {
                return '';
            }
            const classes = [];
            if (this.isMobi) {
                classes.push('m_tabpack_picture_layout_v');
                return classes;
            }
            switch (this.module.blob0.ptl) {
                case 0:
                    classes.push('m_tabpack_picture_layout_h');
                    break;
                case 1:
                    classes.push('m_tabpack_picture_layout_h_reverse');
                    break;
                case 2:
                    classes.push('m_tabpack_picture_layout_v');
                    break;
                case 3:
                    classes.push('m_tabpack_picture_layout_v_reverse');
                    break;
            }
            return classes;
        },
        directionClass() {
            if (this.module.prop0 === 1 && this.isMobi) {
                return 'm_tabpack_horizontal';
            }
            return (
                {
                    0: 'm_tabpack_horizontal',
                    1: 'm_tabpack_vertical',
                    2: 'm_tabpack_picture',
                }[this.module.prop0] || ''
            );
        },
        isPictureTab() {
            return this.module.prop0 === 2;
        },
        isVerticalTab() {
            return this.module.prop0 === 1 && !this.isMobi;
        },
        themeColor() {
            return this.info.themeColor;
        },

        switchEventClass() {
            return this.module.prop4 == 1 ? 'j_tappack__switchevent-click' : 'j_tappack__switchevent-enter';
        },

        tabList() {
            return this.module.blob0.tabs;
        },

        styleType() {
            if (this.module.prop0 === 1 && this.isMobi) {
                return 5;
            }
            return this.module.prop2;
        },

        styleDatas() {
            return this.module.privatePattern.pc;
        },

        mobiStyleDatas() {
            return this.module.privatePattern.mobi;
        },

        centerLayout() {
            if (this.isVerticalTab) {
                return '';
            }
            if (this.isMobi && this.module.prop0 === 1) {
                return '';
            }
            let tabListClass = '';

            switch (this.styleDatas.tl) {
                case 3:
                    tabListClass = 'm_tabpack_tablist-right';
                    break;

                case 1:
                    tabListClass = 'm_tabpack_tablist-center';
                    break;

                default:
                    break;
            }

            return tabListClass;
        },

        averageLayout() {
            if (this.isVerticalTab) {
                return '';
            }
            if (this.styleDatas.tl == 2) {
                if ((this.tabList.length <= 5 && this.isMobi) || !this.isMobi) {
                    return 'm_tabpack_tab-average';
                }
            }

            return '';
        },

        tabListWidthClass() {
            if (this.isVerticalTab) {
                return '';
            }
            if (this.styleDatas.tl == 2) {
                if (this.tabList.length <= 5 && this.isMobi) {
                    return 'm_tabpack_tablist-average';
                }
            }

            return '';
        },

        unlineType() {
            return this.styleDatas.au;
        },

        splitOpt() {
            return this.styleDatas.isp;
        },

        splitType() {
            return this.splitOpt.st == 1 ? 'm_tabpack_split-scale' : '';
        },

        showSplit() {
            return this.styleType == 1 || this.styleType == 2 || this.styleType == 4;
        },

        splitStyle() {
            if (!this.splitOpt.y) {
                return {};
            }

            return {
                display: this.splitOpt.y == 1 ? 'none' : 'block',
                background: this.splitOpt.c,
            };
        },

        tabWrapBoder() {
            const borderOpt = getStyleDataByDevice({
                isMobi: this.isMobi,
                pcData: this.styleDatas.tb,
                mobiData: this.mobiStyleDatas.tb,
            });

            if (borderOpt.y == 1) {
                return {
                    border: '0',
                };
            } else if (borderOpt.y == 0) {
                return {};
            }

            return {
                'border-width': borderOpt.w + 'px',
                'border-top-style': borderOpt.t ? 'solid' : 'none',
                'border-bottom-style': borderOpt.b ? 'solid' : 'none',
                'border-left-style': borderOpt.l ? 'solid' : 'none',
                'border-right-style': borderOpt.r ? 'solid' : 'none',
                'border-color': borderOpt.c,
            };
        },

        arrowStyle() {
            if (!this.styleDatas.abg.y) {
                return {};
            }

            return {
                'border-top-color': this.styleDatas.abg.c,
            };
        },

        // 样式四的背景
        styleFourBg() {
            if (this.isVerticalTab) {
                return {};
            }
            if (this.styleType != 3 || this.styleDatas.ibg.y != 2) {
                return {};
            }

            return {
                'background-color': this.styleDatas.ibg.c,
            };
        },

        childrenModuleMap() {
            return this.module.blob0.tabs.reduce((map, cur) => {
                if (Array.isArray(cur.mIds)) {
                    cur.mIds.forEach((id) => {
                        map[id] = this.$store.state.modules[`module${id}`] || {};
                    });
                }
                return map;
            }, {});
        },
        mTabpackStyleClass() {
            return this.isMobi ? `m_tabpack_style_${this.mobiStyleDatas.mts}` : '';
        },
        isShowArrow() {
            return this.mobiStyleDatas.mts === 1 && this.isMobi;
        },
    },
    watch: {
        async isMobi() {
            await Vue.nextTick();
            this.cleanTabPackStyleSideEffect();
            this.initTabPackModuleStyle();
            this.initFixTabContentWidthInMobi();
        },
        'mobiStyleDatas.mts': {
            async handler() {
                if (this.$isServer) {
                    return;
                }
                await Vue.nextTick();
                this.cleanTabPackStyleSideEffect();

                this.initTabPackModuleStyle();
            },
            immediate: true,
        },
    },
    created() {
        pictureChangeDefaultImage(this);
        this.module.blob0.tabs.forEach((tab) => {
            tab.mIds = tab.mIds.filter((id) => {
                return this.$store.state.modules[`module${id}`];
            });
        });
    },

    mounted() {
        this.initLazyloadTwo();
        this.$nextTick(this.initOther);
        this.initTabsChangeEvent();
        this.initFixTabContentWidthInMobi();
    },

    methods: {
        initFixTabContentWidthInMobi() {
            // 修复宽度不是整数导致会露出上一屏一点点的内容
            if (this.manageMode || this.alreadyInit || !this.isMobi) {
                return;
            }
            this.alreadyInit = true;
            const fix = () => {
                const tabContent = document.querySelector(`#module${this.module.id} .m_tabpack_content`);
                if (!tabContent) {
                    return;
                }
                tabContent.style.width = '';
                if (!this.isMobi) {
                    return;
                }
                const computedWidth = Number(window.getComputedStyle(tabContent).width.replace('px', ''));
                if (computedWidth % 1 !== 0) {
                    tabContent.style.width = `${Math.ceil(computedWidth)}px`;
                }
            };
            fix();
            window.addEventListener('resize', fix);
        },
        encodeHtmlStr,
        addModule(tab, index) {
            window._store.commit('manage/funcPanel/funcPanelChange', {
                active: true,
                type: 'module',
                second: undefined,
                showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
                isTabpack: true,
            });

            global._temporary.addModuleArea = {
                rowId: this.rowid,
                colIndex: index,
                tab,
                tabpackId: this.module.id,
            };
        },

        hoverThemeTypeClass(index) {
            if (index == this.curIndex) {
                if (this.isVerticalTab) {
                    let classes = '';
                    if ([0, 1, 3].includes(this.styleType)) {
                        classes += 'jz_theme_bg_color';
                    }
                    if ([2].includes(this.styleType)) {
                        classes += 'jz_theme_border_color';
                    }
                    return classes;
                } else {
                    if (this.styleType == 0 || this.styleType == 3 || this.styleType == 4 || this.styleType == 5) {
                        return 'jz_theme_bg_color';
                    }
                }

                return 'jz_theme_font_color';
            }
        },

        activeTabClass(index) {
            if (index == this.curIndex) {
                return 'm_tabpack_tab-active';
            }

            return '';
        },

        clickChangeTab(index) {
            if (this.dragging) {
                return;
            }

            if (this.module.prop4 != 1) {
                return;
            }

            this.curIndex = index;
        },

        mouseChangeTab(index) {
            if (this.dragging) {
                return;
            }

            this.hoverTab(index);

            if (this.module.prop4 != 0) {
                return;
            }

            this.curIndex = index;
        },

        initSwiperEvent() {
            let _this = this;

            $(this.$refs.tabpackwrap)
                .find('.m_tabpack_tablist')
                .off('swipeleft.tl')
                .on(
                    'swipeleft.tl',
                    {
                        dir: 'left',
                    },
                    function (e) {
                        _this.slideTabItem(this, e.data.dir);
                    }
                );
            $(this.$refs.tabpackwrap)
                .find('.m_tabpack_tablist')
                .off('swiperight.tr')
                .on(
                    'swiperight.tr',
                    {
                        dir: 'right',
                    },
                    function (e) {
                        _this.slideTabItem(this, e.data.dir);
                    }
                );
        },

        slideTabItem(dom, dir) {
            if (!this.isMobi) {
                return;
            }

            var $dom = $(dom),
                $parent = $dom.parent('.m_tabpack_listwrap'),
                thisLeft = $dom.position().left,
                parentWidth = $parent.outerWidth(),
                parentRight = $parent.get(0).getBoundingClientRect().right,
                domRight = $dom.get(0).getBoundingClientRect().right,
                parentLeft = $parent.get(0).getBoundingClientRect().left,
                domLeft = $dom.get(0).getBoundingClientRect().left;

            if (dir == 'left') {
                let rightLimit = domRight - parentRight;

                if (rightLimit <= 0) {
                    return;
                }

                let maxLeft = rightLimit >= parentWidth ? parentWidth : rightLimit;
                $dom.css('left', thisLeft - maxLeft + 'px');
            }

            if (dir == 'right') {
                let leftLimit = parentLeft - domLeft;

                if (leftLimit <= 0) {
                    return;
                }

                let maxLeft = leftLimit >= parentWidth ? parentWidth : leftLimit;
                $dom.css('left', thisLeft + maxLeft + 'px');
            }
        },

        transformStyle(index) {
            if (!this.module.prop6) {
                if (index == this.curIndex) {
                    return {
                        transform: `translateX(${index * -100}%)`,
                    };
                } else if (index > this.curIndex) {
                    // 这里+1，解决tabContent宽度有可能是小数点，前后tabContent溢出
                    return {
                        transform: `translateX(${(index - 1) * -100 + 1}%)`,
                    };
                } else {
                    // 这里-1，解决tabContent宽度有可能是小数点，前后tabContent溢出
                    return {
                        transform: `translateX(${(index + 1) * -100 - 1}%)`,
                    };
                }
            } else {
                if (index == this.curIndex) {
                    return {
                        transform: 'translateX(0%)',
                        position: 'relative',
                        left: '0',
                    };
                } else if (index > this.curIndex) {
                    return {
                        transform: 'translateX(0%)',
                        position: 'absolute',
                        left: '100%',
                    };
                } else {
                    return {
                        transform: 'translateX(0%)',
                        position: 'absolute',
                        left: '-100%',
                    };
                }
            }
        },

        hoverTab(index, isOut) {
            this.hoverIndex = isOut ? -1 : index;
        },

        getTextAlign(ta) {
            return (
                {
                    0: 'left',
                    1: 'center',
                    2: 'right',
                    3: 'justify',
                }[ta] || ''
            );
        },

        exChangeColor(sHex, alpha = 0.4) {
            // 十六进制颜色值的正则表达式
            var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
            /* 16进制颜色转为RGB格式 */

            let sColor = sHex.toLowerCase();

            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    var sColorNew = '#';

                    for (let i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                    }

                    sColor = sColorNew;
                } //  处理六位的颜色值

                var sColorChange = [];

                for (let i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
                }

                return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')';
            } else {
                return sColor;
            }
        },

        activeShadow(index) {
            if (this.isVerticalTab) {
                return {};
            }
            if (typeof window == 'undefined') {
                return {};
            }

            const as = !this.isMobi ? this.styleDatas.as : this.mobiStyleDatas.as;

            if (index != this.curIndex || (this.styleType != 0 && this.styleType != 5) || !as) {
                return {};
            }

            let themeColor = this.themeColor;
            const abg = getStyleDataByDevice({
                isMobi: this.isMobi,
                pcData: this.styleDatas.abg,
                mobiData: this.mobiStyleDatas.abg,
            });
            let rgba = !abg.y ? this.exChangeColor(themeColor) : this.exChangeColor(abg.c);
            return {
                'box-shadow': `${rgba} 0px 3px 8px 0px`,
            };
        },

        initShadow(index) {
            if (this.isVerticalTab) {
                return {};
            }
            if (index == this.curIndex || (this.styleType != 0 && this.styleType != 5)) {
                return {};
            }

            return {
                'box-shadow':
                    this.styleDatas.ibg.y && this.styleDatas.as
                        ? `${this.exChangeColor(this.styleDatas.ibg.c)} 0px 1.8px 3.6px 0`
                        : '',
            };
        },

        initLazyloadTwo() {
            if (this.manageMode) {
                return;
            }

            let imgArr = Array.from(
                document.querySelectorAll(`#module${this.module.id} .module_image_content .jz_img_lazyload`)
            );
            imgArr.length && initLazyloadTwo(imgArr);
        },

        initOther() {
            let animateArr = Array.from(document.querySelectorAll(`#module${this.module.id} .jz_module_animation`));
            InterSectionObserApi.animationUnobserve(animateArr);
            refreshResAnimation(this.childrenModuleMap);
            animateArr.length && InterSectionObserApi.initAnimationObserver(animateArr);
        },

        initTabsChangeEvent() {
            if (!this.manageMode) {
                return;
            }

            _voidVm.$on('changeTabs', (flag, id) => {
                if (this.module.id != id) {
                    return;
                }

                if (flag == 'add') {
                    this.curIndex = this.tabList.length - 1;
                    pictureChangeDefaultImage(this);
                }

                if (flag == 'delete') {
                    this.curIndex = 0;
                }
            });
        },

        initTabSpace() {
            if (this.isVerticalTab) {
                return {};
            }

            // 纵向标签，mobi视图下，tab间隔默认不跟随pc视图
            if (this.isMobi && this.module.prop0 === 1 && this.mobiStyleDatas.ts.t === 0) {
                return {};
            }
            const tabSpace = getStyleDataByDevice({
                isMobi: this.isMobi,
                pcData: this.styleDatas.ts,
                mobiData: this.mobiStyleDatas.ts,
                typeKey: 't',
            });

            if (!tabSpace.t) {
                return {};
            }

            return this.styleDatas.tl == 2
                ? {
                      'margin-top': ` ${tabSpace.v / 2}px `,
                      'margin-bottom': ` ${tabSpace.v / 2}px `,
                  }
                : {
                      margin: `${tabSpace.v / 2}px ${tabSpace.h / 2}px`,
                  };
        },
        hovermenu: manageUse.hovermenu || function () {},
        initTabPackModuleArrowStyle() {
            let buttonWidth = 0;
            $(this.$refs.tabpackwrap)
                .find('.m_tabpack_tab')
                .each((index, el) => {
                    buttonWidth = el.offsetWidth;
                    if (buttonWidth > this.maxButtonWidth) {
                        this.maxButtonWidth = buttonWidth;
                    }
                });
            const $dom = $(this.$refs.tabpackwrap).find('.m_tabpack_listwrap');

            const $dom2 = $(this.$refs.tabpackwrap).find('.m_tabpack_tablist');

            this.isShowArrowRight = false;

            this.isShowArrowLeft = false;

            if ($dom2.width() > $dom.width()) {
                this.isShowArrowRight = true;
            } else {
                this.isShowArrowRight = false;
            }
        },
        cleanTabPackStyleSideEffect() {
            if (!this.manageMode) {
                return;
            }

            $(`#module${this.module.id} .m_tabpack_tablist`).css('left', 0);
        },
        cleanSwiper() {
            $(this.$refs.tabpackwrap).find('.m_tabpack_tablist').off('swipeleft.tl');
            $(this.$refs.tabpackwrap).find('.m_tabpack_tablist').off('swiperight.tr');
        },

        initTabPackModuleStyle() {
            this.cleanSwiper();
            this.initTabPackModuleStyleTimer && clearTimeout(this.initTabPackModuleStyleTimer);
            if (this.mobiStyleDatas.mts === 0) {
                this.initSwiperEvent();
            }
            if (this.mobiStyleDatas.mts === 1 && this.isMobi) {
                this.initTabPackModuleStyleTimer = setTimeout(() => {
                    this.initTabPackModuleArrowStyle();
                }, 300);
            }
        },
        updateArrow() {
            const $dom = $(this.$refs.tabpackwrap).find('.m_tabpack_listwrap');
            const curLeft = $dom[0].scrollLeft;

            const $dom2 = $(this.$refs.tabpackwrap).find('.m_tabpack_tablist');

            const scrollMax = $dom2.outerWidth() - $dom.outerWidth();

            if (curLeft > 0) {
                this.isShowArrowLeft = true;
            } else if (curLeft === 0) {
                this.isShowArrowLeft = false;
            }
            if (curLeft >= scrollMax) {
                this.isShowArrowRight = false;
            } else if (curLeft < scrollMax) {
                this.isShowArrowRight = true;
            }
        },
        arrowRightHandler(e) {
            e.stopPropagation();

            const $dom = $(this.$refs.tabpackwrap).find('.m_tabpack_listwrap');
            let curLeft = $dom[0].scrollLeft;
            const lastLeft = curLeft + this.maxButtonWidth;
            let timer = null;

            timer = setInterval(() => {
                if (curLeft < lastLeft) {
                    curLeft += 2;
                    $dom[0].scrollLeft = curLeft;
                } else {
                    clearInterval(timer);
                    this.updateArrow();
                }
            }, 1);
        },
        arrowLeftHandler(e) {
            e.stopPropagation();

            const $dom = $(this.$refs.tabpackwrap).find('.m_tabpack_listwrap');
            let curLeft = $dom[0].scrollLeft;
            const lastLeft = curLeft - this.maxButtonWidth;
            let timer = null;

            timer = setInterval(() => {
                if (lastLeft < curLeft) {
                    curLeft -= 2;
                    $dom[0].scrollLeft = curLeft;
                } else {
                    clearInterval(timer);
                    this.updateArrow();
                }
            }, 1);
        },
    },
};
