//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import { getCarouselRowsEffect } from '@shared/visitor/carouselRowsEffects/index.js';
import { editRow } from '@/manage/grid/row/rowManage/index.js';
import { hasChildByRow } from './utils.js';
import { logJZFdp } from '@/site/shared/log/index.js';
export default {
    name: 'FreeLayout',
    props: {
        nav: {
            type: Object,
            require: true,
        },
        navList: {
            type: Array,
            require: true,
        },
        navItem: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['rows', 'jzVersion', 'device', 'manageMode', 'fullScreen']),
        ...Vuex.mapState('manage', ['isEditFreeLayout', 'funcPanel']),
        rowIds() {
            return this.navItem.rIds || [];
        },
        row() {
            return this.rows[`row${this.rowIds[0]}`];
        },
        hasChild() {
            return this.rowIds && this.rowIds.length && hasChildByRow(this.row.id, this.rows);
        },
        noEventClass() {
            // 手机端不能编辑自由排版所以不需要禁止内容得交互
            return !this.manageMode || (this.isEditFreeLayout && this.isPc) || !this.isPc
                ? ''
                : 's_freelayout__row-noevent';
        },
        isPc() {
            return this.device === 'pc';
        },
    },
    watch: {
        ['navItem.showFreeLayout'](val) {
            if (!this.isPc) {
                return;
            }
            this.teleport(val);
        },
    },
    mounted() {
        _voidVm.$on('exit_editFreeLayout', () => {
            this.teleport(false);
            this.closeEdit();
        });
        _voidVm.$on('hide_freelayout', () => {
            this.teleport(false);
            this.hideCurFreeLayout();
        });
        _voidVm.$on('reset_default_addmodule', () => {
            if (this.navItem.showFreeLayout) {
                this.initAddAreaState();
            }
        });
    },
    methods: {
        ...Vuex.mapMutations('manage', ['changeEditFreeLayout', 'material']),
        teleport(val) {
            if (val) {
                // 一个自由排版展示，就要让其他自由排版归位
                this.navList.forEach((nav) => {
                    if (nav.id == this.navItem.id) {
                        return;
                    }
                    let target = $(`.jz_nav_item[_colid=${nav.id}] .nav_item_text_wrap`);
                    if (target.find(`#s_freelayout__${nav.id}`).length) {
                        $(`#jzRoot > #s_freelayout__${nav.id}`).remove();
                    } else {
                        $(`#jzRoot > #s_freelayout__${nav.id}`).appendTo(
                            `.jz_nav_item[_colid=${nav.id}] .nav_item_text_wrap`
                        );
                    }
                });
                if (this.$refs.freeLayout) {
                    $(this.$refs.freeLayout).appendTo('#jzRoot');
                    this.$refs.freeLayout.style.top =
                        document.querySelector('.jz_nav_wrap').getBoundingClientRect().bottom +
                        document.documentElement.scrollTop +
                        'px';
                }
            } else {
                // 如果vue重新渲染后又生成了节点了，那就不要插入了，直接删除了。
                if (
                    $(`.jz_nav_item[_colid=${this.navItem.id}] .nav_item_text_wrap #s_freelayout__${this.navItem.id}`)
                        .length
                ) {
                    $(`#jzRoot > #s_freelayout__${this.navItem.id}`).remove();
                } else {
                    let targetArea = $(`.jz_nav_item[_colid=${this.navItem.id}] .nav_item_text_wrap`);
                    if (targetArea.length) {
                        $(`#jzRoot > #s_freelayout__${this.navItem.id}`).appendTo(targetArea);
                    } else {
                        $(`#jzRoot > #s_freelayout__${this.navItem.id}`).remove();
                    }
                }
            }
        },
        closeEdit(e, isFromMask) {
            this.changeEditFreeLayout({ isEdit: false, colId: -1 });
            this.$store.commit('manage/editPanel/editPanelActiveChange', false);
            this.$store.commit('manage/funcPanel/funcPanelActiveChange', false);
            document.querySelector('body').classList.remove('s_freelayout__zindex-fix');
            this.hideCurFreeLayout();
            JZ.changeRowDisableAdd && JZ.changeRowDisableAdd(false);
            this.log(isFromMask ? '点击遮罩退出' : '退出编辑');
        },
        openEdit() {
            // 开启前要先关闭面板
            this.$store.commit('manage/editPanel/editPanelActiveChange', false);
            this.$store.commit('manage/funcPanel/funcPanelActiveChange', false);
            return new Promise((res) => {
                this.changeEditFreeLayout({ isEdit: true, colId: this.navItem.id });
                _voidVm.$emit('v-hovermenu.removeHoverMenu');
                setTimeout(() => {
                    document.querySelector('body').classList.add('s_freelayout__zindex-fix');
                    res();
                }, 300);
                JZ.changeRowDisableAdd && JZ.changeRowDisableAdd(true);
                this.log('编辑导航');
            });
        },
        mouseoutFreelayout(e) {
            let flag = this.manageMode ? !this.isEditFreeLayout && this.isPc : this.isPc;
            if (flag) {
                this.$emit('mouseout-freelayout', e, true);
            }
        },
        hideCurFreeLayout() {
            if ((!this.manageMode || !this.isEditFreeLayout) && this.isPc) {
                this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                this.navItem.showFreeLayout = false;
            }
        },
        isNestWrapRow() {
            return this.row.cols.some((col) => {
                return col.rIds && col.rIds.length;
            });
        },
        defautNestRowId() {
            let cols = this.row.cols || [];
            if (cols[0]?.rIds?.length) {
                let rIds = cols[0].rIds || [];
                return rIds[0];
            }
            return this.row.id;
        },
        initAddAreaState() {
            global._temporary.addModuleArea = {
                rowId: this.isNestWrapRow() ? this.defautNestRowId() : this.row.id,
                colIndex: 0,
            };
            this.$store.commit('manage/material/setMaterialInsertRowId', this.row.id);
        },
        addModule() {
            this.$store.commit('manage/funcPanel/funcPanelActiveChange', false);
            this.$store.commit('manage/editPanel/editPanelActiveChange', false);
            setTimeout(() => {
                this.$store.commit('manage/funcPanel/setIsFreeLayoutOpen', true);
                this.$store.commit('manage/funcPanel/funcPanelChange', {
                    active: true,
                    type: 'module',
                    showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
                });
                this.initAddAreaState();
                this.log('添加模块');
            });
        },
        async addMaterial() {
            this.$store.commit('manage/funcPanel/funcPanelActiveChange', false);
            this.$store.commit('manage/editPanel/editPanelActiveChange', false);
            setTimeout(() => {
                this.$store.commit('manage/funcPanel/setIsFreeLayoutOpen', true);
                this.$store.commit('manage/funcPanel/funcPanelChange', {
                    active: true,
                    type: 'module',
                    second: 'MaterialAddPanel',
                    showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
                });
                this.initAddAreaState();
                this.log('添加素材');
            });
        },
        editRow() {
            const rowId = this.row.id;
            if (this.$store.getters.isCarouselRow(rowId)) {
                getCarouselRowsEffect(rowId)?.stopAutoPlay();
            }
            editRow(rowId);
            this.log('编辑通栏');
        },
        addMaterial_openEdit() {
            this.openEdit().then(() => {
                this.addMaterial();
                this.log('选择添加素材开始');
            });
        },
        log(jz_free_text_0) {
            logJZFdp('jz_zsy_nav_zypb', {
                jz_version: this.jzVersion,
                jz_zsy_page: this.fullScreen ? '滚屏栏目' : '常规栏目',
                jz_free_text_0,
            });
        },
    },
};
