//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formMixin from './formMixin';
export default {
    mixins: [formMixin],
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            phoneNumber: '',
        };
    },
    methods: {
        inputPhoneNumber(e) {
            const value = e.target.value;
            this.phoneNumber = value;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.phoneNumber);
        },
    },
};
