//
//
//
//
//
//
//

export default {
    name: 'TimelineItemDot',
    inject: ['module'],
    computed: {},
};
