var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search_result_tabs" }, [
    _c(
      "div",
      { staticClass: "search_result_tabs_wrapper" },
      _vm._l(_vm.tabs, function (tab) {
        return _c(
          "div",
          {
            key: tab.index,
            staticClass: "search_result_tab_item",
            class: {
              "search_result_tab_item--active jz_theme_border_color jz_theme_font_color":
                tab.index === _vm.activeIndex,
            },
            on: {
              click: function ($event) {
                return _vm.handleSelect(tab.index)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(tab.name) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "search_result_tabs_line" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }