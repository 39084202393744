//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { createUrlArgsOnclickStr } = Comm;
import photoDescription from './photoDescription.vue';
import { linkTarget, linkNofollow } from './utils.js';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { photoSettingMixin } from '../photoSettingMixin';
const manageUse = {};
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { getImgComponetOption, imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
export default {
    components: {
        photoDescription,
        ImageComponent,
        ImageMainViewer,
    },
    mixins: [slidesshowDataMixin, photoSettingMixin],
    props: ['photoList', 'module', 'moduleStyle', 'pattern', 'usePhotoGroup', 'allPhotoList'],
    data() {
        return {
            renderStyles: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'jzVersion']),
        ...Vuex.mapGetters(['hashRemoved']),
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-列表多图',
                    jz_version: this.jzVersion,
                },
            };
        },
        descStyle() {
            return this.device === 'mobi' ? this.module.privatePattern.mobi.pds : this.module.privatePattern.pc.pds;
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.psrc;
                previewSrcItem.description = value.desc;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
        photoMargin() {
            if (!this.manageMode) {
                return;
            }
            const style = {
                //"transition": "padding 0.1s",
            };
            if (this.model.ppmt === 1) {
                //自定义图片间距
                style['padding-left'] = `${parseInt(this.model.ppmh / 2)}px`;
                style['padding-right'] = `${parseInt(this.model.ppmh / 2)}px`;
                style['padding-top'] = `${parseInt(this.model.ppmv / 2)}px`;
                style['padding-bottom'] = `${parseInt(this.model.ppmv / 2)}px`;
            }
            return style;
        },
    },
    mounted() {
        this.renderStyles = true;
    },
    methods: {
        getImgComponetOption,
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        photoItemStyle(item) {
            if (!this.manageMode) {
                return;
            }
            const style = {};
            if (this.model.ppmt === 1 && this.isMobi) {
                style.padding = 0;
            }
            if (!this.renderStyles) {
                return style;
            }
            style['flex-grow'] = (item.w * 100) / item.h;
            style['flex-basis'] = `${(item.w * (this.pattern.ppht === 1 ? this.pattern.p3bh : 240)) / item.h}px`;
            return style;
        },
        imgOriginalSrc(photoItem) {
            return photoItem.psrc || '';
        },
        photoStyle() {
            const style = {};
            return style;
        },
        linkTarget(isBlank) {
            return linkTarget(isBlank);
        },
        linkNofollow(nofollow) {
            return linkNofollow(nofollow);
        },
        listPhotoWrap(photoItem) {
            if (!this.manageMode) {
                return;
            }
            const style = {};
            style.paddingBottom = (photoItem.h / photoItem.w) * 100 + '%';
            return style;
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
