export class PreviewImgPopup {
    constructor() {
        this.vm = null;
        this.create();
    }

    create() {
        if (window._store.state.manageMode) {
            $('#jzPreviewContent').append("<div id='j_previewImgPopup'></div>");
        } else {
            $('body').append("<div id='j_previewImgPopup'></div>");
        }
        setTimeout(() => {
            let that = this;
            this.vm = new Vue({
                el: '#j_previewImgPopup',
                data: {
                    path: '', //图片路径
                    showPreviewImg: false, //是否显示
                    downloadPath: '',
                    fileName: '',
                },
                methods: {
                    close() {
                        that.hide();
                    },
                },
                template: `
                    <div class="p_preview_imgwrap" v-show="showPreviewImg">
                        <svg class="p_preview_prevbtn" @click="close">
                            <use xlink:href="#icon_prev"></use>
                        </svg>
                        <img class="p_preview_img" :src="path">
                        <div class="p_preview_download">
                            {{fileName}}
                            <a 
                                target="_self" :href="downloadPath"
                                class="p_preview_dowanbtn"
                            >
                                下载
                            </a>
                            <svg class="p_preview_closebtn" @click="close">
                                <use xlink:href="#icon_material_close"></use>
                            </svg>
                        </div>
                    </div>`,
            });
        });
    }

    toggle(imgData) {
        this.vm.showPreviewImg ? this.hide() : this.show(imgData);
    }

    show(imgData) {
        this.vm.showPreviewImg = true;
        this.vm.path = imgData.path;
        this.vm.downloadPath = imgData.downloadPath;
        this.vm.fileName = imgData.fileName;
    }

    hide() {
        this.vm.showPreviewImg = false;
    }
}
