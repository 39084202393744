var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m_custom_search_result module_content_detail" },
    [
      _c(
        "div",
        { staticClass: "m_custom_search_result--wrap" },
        [
          !_vm.hideEntry && _vm.itemListCondited.length > 0
            ? _c("unit-custom-search-entry", {
                attrs: {
                  data: _vm.itemListCondited,
                  "hide-title": _vm.hideTitle,
                  "hide-tips": _vm.hideTips,
                  title: _vm.projectInfo.name,
                  tips: _vm.projectInfo.tip,
                  "search-id": _vm.projectInfo.id,
                  disabled: !_vm.open,
                  "search-text": _vm.Loc.customSearchText,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "m_custom_search_result--result_title" }, [
            _vm._v(_vm._s(_vm.Loc.customSearchResultModule)),
          ]),
          _vm._v(" "),
          !_vm.hideResultExplain &&
          _vm.projectInfo.resultExplain &&
          _vm.module.isNotAuth
            ? _c(
                "div",
                { staticClass: "m_custom_search_result--result_explain" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.projectInfo.resultExplain) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m_custom_search_result--result_area" },
            [
              _vm.resultList.length < 1
                ? _c("div", { staticClass: "m_custom_search_result--empty" }, [
                    _c("div", {
                      staticClass: "m_custom_search_result--empty_icon",
                    }),
                    _vm._v(" "),
                    _vm.initalState.resultTips
                      ? _c("div", {
                          staticClass: "m_custom_search_result--empty_tips",
                          domProps: {
                            innerHTML: _vm._s(_vm.initalState.resultTips),
                          },
                        })
                      : _vm._e(),
                  ])
                : [
                    _c(
                      "div",
                      _vm._l(_vm.resultList, function (resultItem, index) {
                        return _c(
                          "table",
                          {
                            key: index,
                            staticClass: "m_custom_search_result--table",
                          },
                          [
                            _c(
                              "tbody",
                              [
                                _vm._l(resultItem, function (item, index) {
                                  return [
                                    !item.isDefault
                                      ? _c(
                                          "tr",
                                          {
                                            key: index,
                                            staticClass:
                                              "m_custom_search_result--tr",
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "m_custom_search_result--td_name",
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "m_custom_search_result--td_value",
                                              },
                                              [
                                                item.type == 4
                                                  ? [
                                                      _c("div", {
                                                        staticClass:
                                                          "m_custom_result_img",
                                                        style:
                                                          _vm.imgStyle(item),
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changePreviewImg(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  : item.type == 5
                                                  ? _c("file-view", {
                                                      attrs: {
                                                        "img-style":
                                                          _vm.imgStyle(item),
                                                        "file-item": item,
                                                      },
                                                      on: {
                                                        imgClick: function (
                                                          $event
                                                        ) {
                                                          return _vm.changePreviewImg(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(item.value) +
                                                          "\n                                        "
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("pagination-component", {
                      attrs: {
                        "module-id": _vm.module.id,
                        "page-count": _vm.paginationPageCount,
                        "current-page": _vm.currentPage,
                        "page-size": _vm.paginationPageSize,
                        "style-index": 0,
                      },
                      on: { currentChange: _vm.handlePaginationPageChange },
                    }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }