//
//
//
//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getFontCss, getFontTextCss } from '../cssUtils';

export default {
    name: 'ModuleStyles',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        moduleStyle() {
            return this.module.prop0;
        },
        tipsCss() {
            return getFontCss({
                pcFont: this.pcPattern.tts,
                mobiFont: this.mobiPattern.tts,
                className: `${this.id} .m_friendlink__tipsname`,
            });
        },
        tipsSelectCss() {
            let css = '';
            if (this.pcPattern.tts.y === 1) {
                css += `${this.id} .m_friendlink__select-showing .m_friendlink__tipsname { color: ${this.pcPattern.tts.sc}; }`;
            }
            if (this.mobiPattern.tts.y === 1) {
                css += `${this.mobiId} .m_friendlink__select-showing .m_friendlink__tipsname { color: ${this.mobiPattern.tts.sc}; }`;
            }
            return css;
        },
        optionCss() {
            return `${this.id} .m_friendlink__itemname { ${getFontTextCss(this.pcPattern.its)} }`;
        },
        optionSelectCss() {
            if (this.pcPattern.its.y === 0) {
                return '';
            }
            return `${this.id} .m_friendlink__itemname:hover { color: ${this.pcPattern.its.sc}; }`;
        },
        contentBgCss() {
            if (this.pcPattern.cc.y === 1) {
                return `
                    ${this.id} .m_friendlink__list { background-color: ${this.pcPattern.cc.c}; }
                    ${this.id} .m_friendlink__item:hover { background-color: ${this.pcPattern.cc.hc}; }
                `;
            }
            return '';
        },
        dropDownBoxColorCss() {
            const getCss = (pattern, id) => {
                if (pattern.ddbc.y === 1) {
                    return `
                        ${id} .m_friendlink__wrapper { background-color: ${pattern.ddbc.c}; }
                        ${id} .m_friendlink__wrapper.m_friendlink__select-showing { background-color: ${pattern.ddbc.hc}; }
                    `;
                }
                return '';
            };
            return `${getCss(this.pcPattern, this.id)} ${getCss(this.mobiPattern, this.mobiId)}`;
        },
        radiusCss() {
            const getCss = (pattern, id) => {
                if (pattern.rds.y === 1) {
                    return `${id} .m_friendlink__wrapper { border-radius: ${pattern.rds.v}px; }`;
                }
                return '';
            };
            return `${getCss(this.pcPattern, this.id)} ${getCss(this.mobiPattern, this.mobiId)}`;
        },
        borderCss() {
            const getCss = (pattern, id) => {
                if (pattern.fc.y === 1) {
                    return `
                        ${id} .m_friendlink__wrapper { border-color: ${pattern.fc.c}; border-width: ${pattern.fc.w}px; }
                        ${id} .m_friendlink__wrapper.m_friendlink__select-showing { border-color: ${pattern.fc.sc}; }
                    `;
                }
                return '';
            };
            return `${getCss(this.pcPattern, this.id)} ${getCss(this.mobiPattern, this.mobiId)}`;
        },
    },
    methods: {},
};
