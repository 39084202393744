//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mobiSelect from '../common/mobiSelect.vue';
import ClientOnly from 'vue-client-only';
export default {
    name: 'FriendLink',
    components: { mobiSelect, ClientOnly },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showMobiSelect: false,
            showPcSelect: false,
        };
    },
    computed: {
        ...Vuex.mapGetters(['isMobi']),
        ...Vuex.mapState(['manageMode']),
        moduleStyle() {
            return this.module.prop0;
        },
        direction() {
            return this.module.prop2;
        },
        tips() {
            return this.module.prop3;
        },
        linkItems() {
            return this.module.prop1;
        },
        selectShowingClass() {
            if ((this.isMobi && this.showMobiSelect) || (!this.isMobi && this.showPcSelect)) {
                return 'm_friendlink__select-showing';
            }
            return '';
        },
    },
    methods: {
        handlePcLinkClick(link) {
            if (link.url && link.url !== 'javascript:void(0)') {
                this.hideList();
            }
        },
        handleMobiSelectConfirm(item) {
            const { id, link } = item;
            $(this.$refs.friendLink).find(`.m_friendlink__link-${id}`)[0].click();
            if (link.url && link.url !== 'javascript:void(0)') {
                this.showMobiSelect = false;
            }
        },
        handleMobiPopupOpen() {
            this.showMobiSelect = true;
        },
        toggleList() {
            if (this.isMobi) {
                return this.handleMobiPopupOpen();
            }
            const $list = $(this.$refs.friendLink).find('.m_friendlink__list');
            if ($list.is(':visible')) {
                this.hideList();
            } else {
                this.showList();
            }
        },
        showList() {
            if (this.isMobi) {
                return;
            }
            const $list = $(this.$refs.friendLink).find('.m_friendlink__list');
            this.moduleStyle === 0 ? $list.show() : $list.slideDown();
            this.showPcSelect = true;
        },
        hideList() {
            if (this.isMobi) {
                return;
            }
            const $list = $(this.$refs.friendLink).find('.m_friendlink__list');
            this.moduleStyle === 0 ? $list.hide() : $list.slideUp();
            this.showPcSelect = false;
        },
        handleClickOutside(event) {
            if (this.manageMode) {
                if (event.path.some((p) => p.classList?.contains('edit_panel_outer_content'))) {
                    return;
                }
            }
            this.hideList();
        },
    },
};
