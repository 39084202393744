import request from '@jz/request';

export function logJzFdp(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_logJZFdpTrack', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function logDog(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_dog', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function logCorpDogRequest(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_logCorpDog', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function logBssFodderRequest(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_logBssFodder', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function siteLogDogRequest(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_siteLogDog', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function logMonitor(data) {
    return request.post('/ajax/log_h.jsp?cmd=wafNotCk_monitor', { data }).then(({ data = {} }) => {
        return data;
    });
}
