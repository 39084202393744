var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        {
          staticClass: "welcome_page J_welcomePage",
          class: _vm.classes,
          attrs: { id: "welcomePage" },
        },
        [
          _c("div", {
            staticClass:
              "welcome_page_image J_welcomePageImage welcome_page_image_wrapper",
            class: _vm.imageClass,
            style: _vm.imageStyle,
            attrs: {
              "data-pic-pc": _vm.imagePCInfo.picUrl || _vm.defaultImage,
              "data-pic-mobile": _vm.imageMobileInfo.picUrl || _vm.defaultImage,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "welcome_page_video_wrapper J_welcomePageVideoWrapper",
              class: _vm.videoClass,
            },
            [
              _c(
                "video",
                {
                  staticClass: "welcome_page_video",
                  attrs: {
                    id: "J_welcomePageVideo",
                    playsinline: "",
                    muted: "muted",
                    loop: "loop",
                    autoplay: "autoPlay",
                  },
                  domProps: { muted: true },
                },
                [
                  _c("source", {
                    attrs: {
                      type: "video/mp4",
                      src: _vm.videoSrc || _vm.defaultVideo,
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "countdown J_welcomePageCountDown" }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }