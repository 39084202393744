//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GroupItem from './GroupItem.vue';
import MobiGroupPanel from './MobiGroupPanel.vue';
import { deepExtendClone } from '@jz/utils';
import emptyTipsMixin from './emptyTipsMixin.js';
let manageUse = {
    components: {},
};
function getMultiLevelList(list, parentId) {
    parentId = parentId || 0;
    return list
        .filter(function (item) {
            //过滤其他元素，只保留当前级
            return item.parentId === parentId;
        })
        .map(function (item) {
            //获取子元素
            item.children = getMultiLevelList(list, item.id);
            return item;
        });
}
export default {
    name: 'NewsGroup',
    components: {
        GroupItem,
        ...manageUse.components,
    },
    mixins: [emptyTipsMixin],
    props: ['id', 'module'],
    style: 14,
    data() {
        return {
            vmpanel: null,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'colId']),
        moduleClasses() {
            const classes = {
                [`module_cate_group_style${this.style}`]: true,
            };
            return classes;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        // eslint-disable-next-line vue/return-in-computed-property
        style() {
            if (this.module.prop0 === 2) {
                return 0;
            } else if (this.module.prop0 === 3) {
                return 1;
            }
        },
        showTips() {
            return this.displayList.length === 0;
        },
        isSelectGroupAll() {
            return this.module.prop2 === 0;
        },
        normalizationDisplayList() {
            try {
                const listName = this.isSelectGroupAll ? 'displayListAll' : 'displayList';
                const displayList = this.module[listName].filter((item) => item.id !== 0);
                return this.isSelectGroupAll && !this.module.style === 39
                    ? displayList
                    : displayList.filter((item) => item.check);
            } catch (error) {
                console.error(error);
                return [];
            }
        },

        displayList() {
            return getMultiLevelList(deepExtendClone(this.normalizationDisplayList));
        },
        isOnlyOneLevel() {
            return !this.normalizationDisplayList.some((item) => item.parentId !== 0);
        },
        isColumnNav() {
            return this.module.style === 39;
        },
    },
    watch: {
        isMobi(val) {
            if (!val) {
                if (this.vmpanel) {
                    this.vmpanel.showPanel = false;
                }
            }
        },
    },
    methods: {
        clickAddGroupHandler() {
            manageCategoryNav(this.module.id, this.module.style, this.module.style === 39, true);
        },
        clickManageGroupHandler() {
            manageCategoryNav(this.module.id, this.module.style, this.module.style === 39, false);
        },
        clickSelectGroupHandler() {
            this.manageMode && editModule(this.id);
        },
        showPanelEvent(data) {
            if (this.vmpanel) {
                this.vmpanel.showPanel = true;
                this.vmpanel.groupData = data;
            } else {
                this.genderGroupPanel(data);
            }
        },
        genderGroupPanel(data) {
            this.vmpanel = new Vue({
                components: {
                    MobiGroupPanel,
                },
                data: {
                    showPanel: true,
                    isMobi: this.isMobi,
                    groupData: data,
                    isColumnNav: this.isColumnNav,
                    colId: this.colId,
                    moduleId: this.module.id,
                },
                methods: {
                    hidePanelEvent() {
                        this.showPanel = false;
                    },
                },
                template: `
                    <mobi-group-panel
                        v-if="isMobi && showPanel" 
                        @on-closepanel="hidePanelEvent" 
                        :group="groupData"
                        :colId="colId"
                        :is-column-nav="isColumnNav"
                        :moduleId="moduleId"
                    />
                `,
            }).$mount();
            let selector = 'body';
            if (this.manageMode) {
                selector = '#jzPreviewContent';
            }
            $(selector).append(this.vmpanel.$el);
        },
    },
};
