//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timelineMixin } from './mixin';

export default {
    name: 'TextVerticalCross',
    mixins: [timelineMixin],
};
