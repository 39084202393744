let isSupportWebp = function () {
    let supportWebp = false;

    try {
        supportWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } catch (err) {
        console.log(err);
    }

    isSupportWebp = () => supportWebp;

    return supportWebp;
};

export { isSupportWebp };
