//
//
//
//

import FullScreenJzBanner from './FullScreenJzBanner.vue';
import JzBannerContent from './JzBannerContent.vue';
export default {
    name: 'jz-banner',
    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        wrapComponent() {
            return this.isFullScreen ? 'FullScreenJzBanner' : 'JzBannerContent';
        },
    },
    components: {
        FullScreenJzBanner,
        JzBannerContent,
    },
};
