//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'welcomePage',
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'welcomePage', 'colId', 'resRoot']),
        isMobi() {
            return this.device == 'mobi';
        },
        classes() {
            return {
                'welcome_page--hidden': !this.isOpen,
            };
        },
        defaultImage() {
            return this.resRoot + '/image/rimage/section/welcomePage/default/picture.jpg';
        },
        defaultVideo() {
            return this.resRoot + '/image/rimage/section/welcomePage/default/flv.mp4';
        },
        isImage() {
            return this.viewData.type == 0 || this.mobi;
        },
        imagePCInfo() {
            return this.welcomePage.pc.picInfo;
        },
        imageMobileInfo() {
            let picInfo = this.welcomePage.mobi.picInfo;
            if (this.isMobi) {
                // 手机视图下以图片来展示
                if (this.viewData.type == -1) {
                    picInfo = this.welcomePage.pc.picInfo;
                }
            }
            return picInfo;
        },
        imageClass() {
            let classes = [];

            // 图片缩放类型样式
            let _pictureZoomTypePrefix = 'welcome_page_image_zoom--';
            let welcomePagePictureZoomClassNames = [
                _pictureZoomTypePrefix + 'cover',
                _pictureZoomTypePrefix + 'contain',
                _pictureZoomTypePrefix + 'norepeat',
            ];

            let zoomType = this.viewData.picInfo.zoomType;

            if (this.isMobi) {
                // 手机视图下以图片来展示
                if (this.viewData.type == -1) {
                    zoomType = this.imagePCInfo.zoomType;
                }
            }

            classes.push(welcomePagePictureZoomClassNames[zoomType]);
            if (this.isOpen && this.isImage) {
                classes.push('welcome_page_wrapper--visible');
            }
            return classes;
        },
        imageStyle() {
            let picUrl = this.defaultImage;
            if (this.welcomePage.pc.picInfo.picUrl) {
                picUrl = this.welcomePage.pc.picInfo.picUrl;
            }
            return {
                'background-image': `url('${picUrl}')`,
            };
        },
        videoClass() {
            return {
                'welcome_page_wrapper--visible': this.isOpen && !this.isImage,
            };
        },
        videoSrc() {
            return this.welcomePage.pc.videoInfo.videoUrl;
        },
        viewData() {
            return this.welcomePage[this.device];
        },
        isOpen() {
            let welcomePage = this.welcomePage;
            let isMobile = this.isMobi;
            //colId == 2首页
            //
            return (
                this.colId === 2 &&
                welcomePage.allowOpen &&
                welcomePage.open &&
                (!isMobile || (isMobile && welcomePage.mobiOpen))
            );
        },
    },
};
