export default {
    namespaced: true,
    state: () => ({
        siteAdmDomain: '',
        checkUPlanAcct: false,
        mallHomeUrl: '',
    }),
    mutations: {
        setSalesPromotionData(state, payload) {
            state.siteAdmDomain = payload.siteAdmDomain;
            state.checkUPlanAcct = payload.checkUPlanAcct;
            state.mallHomeUrl = payload.mallHomeUrl;
        },
    },
};
