var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.module.notAllowPrint
    ? _c(
        "div",
        {
          ref: "module",
          staticClass: "module_content_detail m_online_form_content",
          on: { dblclick: _vm.dblClickHandler },
        },
        [
          _vm.hasFormData && _vm.hasFormItem
            ? _c(
                "div",
                { staticClass: "m_form", class: _vm.classes },
                [
                  !_vm.isLinkToForm
                    ? _c(
                        "h2",
                        {
                          staticClass: "form_title",
                          style: _vm.formTitleStyle,
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "form_title_text",
                              style: _vm.formTitleTextStyle,
                            },
                            [_vm._v(_vm._s(_vm.formData.name))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.openSubmitLimitTime && _vm.formLimitTimeTips
                    ? _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSubmitLimitTime,
                              expression: "showSubmitLimitTime",
                            },
                          ],
                          staticClass: "form_limit_time_tips",
                          style: _vm.limitTimeTipsTextStyle,
                        },
                        [
                          _c(
                            "svg",
                            { staticClass: "form_limit_time_tips_icon" },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon_time" },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "form_limit_time_tips_text",
                              style: _vm.limitTimeTipsTextStyleForText,
                            },
                            [_vm._v(_vm._s(_vm.formLimitTimeTips))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form-container",
                    {
                      style: _vm.formContainerStyle,
                      attrs: { list: _vm.contentList, module: _vm.module },
                    },
                    [
                      _vm._l(_vm.contentList, function (item) {
                        return !item.hide
                          ? _c(
                              "form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !item.hideListByRule ||
                                      item.hideListByRule.length > 0,
                                    expression:
                                      "!item.hideListByRule || item.hideListByRule.length > 0",
                                  },
                                ],
                                key: _vm.formData.id + "" + item.id,
                                attrs: {
                                  item: item,
                                  module: _vm.module,
                                  form: _vm.formData,
                                },
                              },
                              [
                                _c(_vm.getFormItem(item.type), {
                                  ref: "formItem" + item.id,
                                  refInFor: true,
                                  tag: "component",
                                  staticClass: "content",
                                  class: {
                                    required:
                                      item.must && _vm.pattern.itt.y === 1,
                                  },
                                  attrs: {
                                    value: item.value,
                                    module: _vm.module,
                                    item: item,
                                    form: _vm.formData,
                                    "required-icon":
                                      item.must && _vm.pattern.itt.y === 1,
                                    "item-input-style": _vm.itemInputStyle,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.inputItem(item, $event)
                                    },
                                    "change-by-rule": _vm.handleChangeByRule,
                                    "change-by-price-rule":
                                      _vm.handleChangeByPriceRule,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm.validation
                        ? _c(
                            "form-item",
                            {
                              key: "validateCode",
                              staticClass: "fixed_item",
                              attrs: {
                                item: {
                                  id: "validateCode",
                                  name: _vm.verificationCodeName,
                                  hideToolBar: true,
                                },
                                module: _vm.module,
                                form: _vm.formData,
                              },
                            },
                            [
                              _c(_vm.getFormItem("validateCode"), {
                                tag: "component",
                                staticClass: "content",
                                attrs: {
                                  value: _vm.validateCode,
                                  module: _vm.module,
                                  form: _vm.formData,
                                  "item-input-style": _vm.itemInputStyle,
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.validateCode = $event
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.openOnlinePay
                        ? _c("form-pay-item", {
                            attrs: {
                              price: _vm.price,
                              "pay-txt": _vm.payTxt,
                              "only-support-ali-pay": _vm.onlySupportAliPay,
                              "only-support-wx-pay": _vm.onlySupportWxPay,
                              "support-multiple": _vm.supportMultiple,
                              "show-pay-alert": _vm.showPayAlert,
                              "form-id": _vm.module.prop2,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOpenFormProtocol && _vm.allowFormProtocol
                        ? _c(
                            "form-item",
                            {
                              key: "formProtocol",
                              staticClass: "fixed_item",
                              attrs: {
                                item: {
                                  id: "formProtocol",
                                  name: "",
                                },
                                module: _vm.module,
                                form: _vm.formData,
                              },
                            },
                            [
                              _c("div", { staticClass: "form_protocol" }, [
                                _c("div", [
                                  _c("ul", { staticClass: "checkbox_style" }, [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "checkbox_item",
                                        class: {
                                          checkbox_active: _vm.checkbox_active,
                                        },
                                        staticStyle: { "padding-bottom": "0" },
                                        on: { click: _vm.toggleItem },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "checkbox_inner jz_theme_bg_color faisco-icons-S000335",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "form_protocol_text",
                                      style: _vm.formProtocolTextStyle,
                                      on: { click: _vm.toggleItem },
                                    },
                                    [_vm._v(_vm._s(_vm.formProtocolText))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "form_protocol_title",
                                      style: _vm.formProtocolTitleStyle,
                                      on: { click: _vm.viewFormProtocol },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.formProtocolTitle)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form-item",
                        {
                          key: "submit",
                          staticClass: "fixed_item",
                          attrs: {
                            item: { id: "submit", name: " " },
                            module: _vm.module,
                            form: _vm.formData,
                          },
                        },
                        [
                          _vm.oldBtnStyle
                            ? [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hovermenu",
                                        rawName: "v-hovermenu",
                                        value: {
                                          hovermenu: _vm.hovermenu,
                                          args: {
                                            moduleId: _vm.module.id,
                                            formId: _vm.formData.id,
                                            itemId: "submit",
                                            isLinkToForm: _vm.isLinkToForm,
                                            hideToolBar: !_vm.isLinkToForm,
                                          },
                                        },
                                        expression:
                                          "{\n                            hovermenu,\n                            args: {\n                                moduleId: module.id,\n                                formId: formData.id,\n                                itemId: 'submit',\n                                isLinkToForm: isLinkToForm,\n                                hideToolBar: !isLinkToForm,\n                            },\n                        }",
                                      },
                                    ],
                                    staticClass:
                                      "form_submit content jz_theme_bg_color",
                                    on: { click: _vm.submit },
                                  },
                                  [_vm._v(_vm._s(_vm.submitText))]
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "hovermenu",
                                        rawName: "v-hovermenu",
                                        value: {
                                          hovermenu: _vm.hovermenu,
                                          args: {
                                            moduleId: _vm.module.id,
                                            formId: _vm.formData.id,
                                            itemId: "submit",
                                            isLinkToForm: _vm.isLinkToForm,
                                            hideToolBar: !_vm.isLinkToForm,
                                          },
                                        },
                                        expression:
                                          "{\n                            hovermenu,\n                            args: {\n                                moduleId: module.id,\n                                formId: formData.id,\n                                itemId: 'submit',\n                                isLinkToForm: isLinkToForm,\n                                hideToolBar: !isLinkToForm,\n                            },\n                        }",
                                      },
                                    ],
                                    staticClass: "submit_btn_container",
                                    class: _vm.submitBtnClassList,
                                    on: { click: _vm.submit },
                                  },
                                  [
                                    _c(
                                      "button",
                                      { staticClass: "form_submit content" },
                                      [_vm._v(_vm._s(_vm.submitText))]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "after",
                                      class: _vm.afterClass,
                                    }),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.manageMode && _vm.hasFormData && !_vm.hasFormItem
            ? _c("div", { staticClass: "jz_form_empty_tips" }, [
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _vm.isActiveEditorPanel
                      ? [_vm._v("点击面板中的类型，可添加表单项进来")]
                      : [_vm._v("当前表单无内容，点击开始编辑表单")],
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isActiveEditorPanel,
                          expression: "isActiveEditorPanel",
                        },
                      ],
                      staticClass: "img",
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.isMobi
                  ? _c(
                      "div",
                      {
                        staticClass: "buttons",
                        style: {
                          visibility: _vm.isActiveEditorPanel
                            ? "hidden"
                            : "inherit",
                        },
                      },
                      [
                        _c(
                          "button-component",
                          {
                            attrs: { active: "" },
                            on: { click: _vm.clickAddFormItemHandler },
                          },
                          [_vm._v("添加表单项")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.manageMode && !_vm.hasFormData
            ? _c("empty-tips", {
                attrs: {
                  "is-mobi": _vm.isMobi,
                  name: "表单",
                  "button-text": "选择表单",
                  "active-button-text": "添加表单",
                },
                on: {
                  "click-button": _vm.clickButtonHandler,
                  "click-active-button": _vm.clickActiveButtonHandler,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.manageMode && (!_vm.hasFormData || !_vm.hasFormItem)
            ? _c("div", { staticStyle: { height: "200px" } })
            : _vm._e(),
        ],
        1
      )
    : _c("div", [_vm._v("\n    " + _vm._s(_vm.module.notAllowText) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }