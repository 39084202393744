//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { warningMessage } from '@/site/shared/dialog/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
export default {
    name: 'SignUpByMail',
    props: ['id', 'conlon', 'captchaMaxlen'],
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'isDemo', 'LS']),
    },
    methods: {
        validateCodeLink() {
            return `/validateCode.jsp?${parseInt(Math.random() * 1000)}`;
        },
        captchaClick() {
            if (this.manageMode) {
                Message.warning('您当前处理网站管理状态，可点击右上方“预览”查看效果。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                changeCaptchaImg(this.id);
            }
        },
    },
};

/**
 * 刷新验证码图片
 * @param {Number}moduleId 模块id
 */
function changeCaptchaImg(moduleId) {
    $('#module' + moduleId)
        .find('.J_memberSignupCaptchaImg')
        .attr('src', `/validateCode.jsp?${parseInt(Math.random() * 1000)}`);
}
