import { InterSectionObserApi } from '@jz/biz-shared';
import { initResAnimation } from '../../../components/modules/animation/index.js';
const runAnimate = ({ id, commProp, style, el, isFullNavModule }) => {
    requestAnimationFrame(() => {
        initResAnimation({ id, commProp, style, el });
        if (!isFullNavModule) {
            !InterSectionObserApi.isObserveEl(el, 1) && InterSectionObserApi.initAnimationObserver(el);
        }
    });
};
export function animateObserver(el, binding) {
    const isMaterialLazyLoad = location.href.includes('isMaterialLazyLoad');

    if (isMaterialLazyLoad) {
        return;
    }

    var { module, projectid } = binding.value,
        needAnimated = false,
        needConetentAnimated = false;
    let { style, id, commProp } = module;
    // 如果是克隆的模块 则不跑动画
    if (id !== parseInt(projectid)) return;
    if (commProp) {
        var al = commProp.al,
            cal = commProp.cal;
        al.forEach(function (item) {
            if (item.t != 0) {
                needAnimated = true;
                return false;
            }
        });
        cal.forEach(function (item) {
            if (item.t != 0) {
                needConetentAnimated = true;
                return false;
            }
        });
    }
    // 如果是全屏导航的模块，本身手动控制监听器
    const isFullNavModule = window._store.state.inFullNavRowModuleIds.includes(id);

    if (needConetentAnimated || needAnimated) {
        if (window._store.state.manageMode) {
            el.classList.add('jz_module_animation');
            runAnimate({ id, commProp, style, el, isFullNavModule });
        } else {
            let _el =
                el && $(el).attr('id') && $(el).attr('id').includes('module') ? el : $(el).parents('#module' + id)[0];
            runAnimate({
                id,
                commProp,
                style,
                el: _el,
                isFullNavModule,
            });
        }
    }
}
