//
//
//
//
//
//
//
//
//
//

import JzBannerContent from './JzBannerContent.vue';
import { fullScreenMixin } from '../row/fullScreenMixin.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
export default {
    name: 'full-screen-jz-banner',
    components: {
        JzBannerContent,
    },
    mixins: [fullScreenMixin],
    props: ['data'],
    computed: {
        activePage() {
            return this.rollingScreenRowInfo.curIndex === 0;
        },
        activePageClass() {
            return {
                active_page: this.activePage,
            };
        },
        animateClass() {
            return this.rollingScreenRowInfo.animateClass;
        },
        rollingScreen() {
            return this.info.pattern.rollingScreen;
        },
        fullScreenInnerInfo() {
            //为啥传一样？ 依据旧代码
            return {
                bannerFlvSize: this.bannerSize,
                bannerHeightSize: this.bannerSize,
            };
        },
        bannerSize() {
            if (this.isMobi && !this.rollingScreen.isMobiSync) {
                return;
            }

            let _style = {
                paddingBottom: 0,
            };

            let headerHeight = this.headerLevitate ? 0 : this.headerHeight || 0;
            let toolBarHeight = this.toolBarHeight;
            let mobiFooterNavServerHeight = this.mobiFooterNavServerHeight;

            let _excludeHeight = this.isMobi ? 0 : toolBarHeight;

            // //悬停
            // _store.state.responsiveInfo.pattern.rollingScreen.topType == 1
            // //占位
            // _store.state.responsiveInfo.pattern.rollingScreen.topType == 2
            // //固定
            // this.rollingScreen.isFixedTop
            // //不固定
            // this.rollingScreen.isFixedTop == false;

            //开始计算顶部去除区域
            //顶部有两个独立状态 ： （悬停-占位） / （固定-不固定）
            //非悬停情况，即是占位情况
            if (!this.headerLevitate) {
                //固定和非固定情况都需要减去，因为横幅只能是第一个
                if (this.rollingScreen.isFixedTop) {
                    //固定 所有都要减去顶部高度
                    _excludeHeight += headerHeight;
                }
            }
            //手机底部fixed的服务栏
            if (this.isMobi && this.hasMobiFooterNavServer) {
                _excludeHeight += mobiFooterNavServerHeight;
            }

            _style.height = `calc(${this.clientHeight} - ${_excludeHeight}px)`;
            //原代码中还需要减去 footerNavHeight，没明白原因， 可能是手机端需要减去
            return _style;
        },
    },
    methods: {
        beforeEnter() {
            this.$store.commit('row/setRollingScreenRowInfo', {
                isRunning: true,
            });
        },
        afterEnter() {
            this.$store.commit('row/setRollingScreenRowInfo', {
                isRunning: false,
            });
            eventBus.$emit('fullScreenAfterEnter');
        },
    },
};
