//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { INDEX_ALL, SEARCH_TABS } from './constants.js';

export default {
    props: {
        ranges: {
            type: Array,
            default: () => [],
        },
        filterTabIndexs: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeIndex: INDEX_ALL,
        };
    },
    computed: {
        tabs() {
            let tabs = SEARCH_TABS.filter((tab) => tab.index !== -1 && this.filterTabIndexs.indexOf(tab.index) === -1);

            if (tabs.length > 1) {
                tabs = [SEARCH_TABS[0]].concat(tabs);
                if (tabs.findIndex((tab) => tab.index === this.activeIndex) === -1) {
                    this.activeIndex = tabs[0].index;
                }
            } else if (tabs.length === 0) {
                tabs = [SEARCH_TABS[0]];
                this.activeIndex = tabs[0].index;
            } else {
                this.activeIndex = tabs[0].index;
            }

            this.$emit('update-tabs', this.activeIndex);

            return tabs;
        },
    },
    methods: {
        handleSelect(index) {
            this.activeIndex = index;
            this.$emit('select', index);
        },
    },
};
