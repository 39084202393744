//
//
//
//

import flvModuleComponent from './flvModuleComponent.vue';
export default {
    name: 'Flv',
    style: 20,
    components: {
        flvModuleComponent,
    },
    props: ['id', 'module'],
    data() {
        return {};
    },
};
