//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { createCaptchaPopup } from './captchaPopup.js';
import { Message } from '@shared/manage/componMessage/index.js';
import SelectComponent from './selectComponent.vue';
import { sendValidateCode } from '@api/form';

export default {
    components: {
        SelectComponent,
    },
    props: {
        item: Object,
        value: {
            type: Object,
            default: () => ({}),
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            phoneNumber: '',
            areaCode: {},
            photoValidating: false,
            timeCount: 0,
            phoneValidateCode: '',
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'LS']),
        mobileCtNameList() {
            const mobileCtNameList = this.module.mobileCtNameList.map((item) => ({
                ...item,
                pcText: item.ctShowName + item.ctCode,
            }));
            this.areaCode = mobileCtNameList[0];
            return mobileCtNameList;
        },
        memberDialogEnterCode() {
            return this.module.mobi_inputMobileCode;
        },
        photoneValidatorText() {
            return this.photoValidating && this.timeCount > 0
                ? Fai.format(this.LS.reacquireMemCode, this.timeCount)
                : this.memberDialogAcquireCode;
        },
        memberDialogAcquireCode() {
            return this.LS.memberDialogAcquireCode;
        },
        photoValidatorDisabled() {
            return this.phoneNumber.length != 11 || this.photoValidating;
        },
        codeBtnClass() {
            return {
                phone_validator_code_btn__disabled: this.photoValidatorDisabled,
            };
        },
        smsVerifyCurr() {
            return this.item.smsVerify && this.module.allowedFormSms;
        },
    },
    methods: {
        inputPhoneNumber(e) {
            const value = e.target.value;
            this.phoneNumber = value;
            this.emitInput();
        },
        selectAreaCode(item) {
            this.areaCode = item;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', {
                areaCode: this.areaCode,
                phoneNumber: this.phoneNumber,
                phoneValidateCode: this.phoneValidateCode,
            });
        },
        onPhoneValidateCodeClick(e) {
            const value = e.target.value;
            this.phoneValidateCode = value;
            this.emitInput();
        },
        async onPhoneValidatorClick() {
            if (this.manageMode) {
                Message.warning('管理态不支持获取验证码');
                return;
            }

            // 防止重复点击
            if (this.photoValidatorDisabled || this.photoValidating) {
                return;
            }

            if (!this.phoneNumber || !Fai.isMobile(this.phoneNumber)) {
                warningMessage(Fai.format(this.LS.site_newMobileNumRegular, 11), true);
                return;
            }

            this.photoValidating = true;

            // 图形验证码 or 直接发送验证码
            const data = this.item.smsImgVerify
                ? await createCaptchaPopup(this.sendValidateCode)
                : await this.sendValidateCode();

            // 非图形验证时弹出调用消息
            if (!this.item.smsImgVerify) {
                if (data && data.success) {
                    successMessage(this.LS.memberDialogSendMobileCode, true);
                } else {
                    warningMessage(data && data.msg ? data.msg : this.LS.js_argsError);
                }
            }

            // 倒计时
            if (data && data.success) {
                await this.startTimeCounter();
            }

            this.photoValidating = false;
        },
        sendValidateCode(validateCode) {
            return new Promise((resolve) => {
                const param = {
                    formId: this.module.prop2,
                    contentId: this.item.id,
                    validateCode,
                    mobile: this.phoneNumber,
                };
                sendValidateCode(param)
                    .then((data) => resolve(data))
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },
        startTimeCounter() {
            return new Promise((resolve) => {
                this.timeCount = 60;
                const timer = setInterval(() => {
                    this.timeCount--;
                    if (this.timeCount < 1) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 1000);
            });
        },
    },
};
