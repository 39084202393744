//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        // 输入框的值，支持sync
        keyword: {
            type: String,
            default: '',
        },
        // 输入框样式id
        styleId: {
            type: Number,
            required: true,
        },
        // 输入框样式风格
        styleType: {
            type: Number,
            required: true,
        },
        // input的placeholder
        searchTip: {
            type: String,
            default: '',
        },
        inputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isExpend: false,
            isSearchResult: false,
        };
    },
    watch: {
        device(val) {
            this.handlePointerEvents(val);
        },
        async styleId() {
            await Vue.nextTick();
            this.handlePointerEvents(this.device);
        },
    },
    methods: {
        handleSearch(isSearchBtn) {
            if ([6, 7, 8].includes(this.styleId) && isSearchBtn && !this.isExpend && !this.isSearchResult) {
                this.isExpend = true;
                return;
            }
            this.$emit('onSearch');
        },
        handleMobiSearch() {
            this.$emit('onMobiSearch');
        },
        handleInputFocus() {
            this.$emit('onFocus');
        },
        handleInputBlur() {
            this.$emit('onBlur');
        },
        handleInputChange(e) {
            this.$emit('onChange', e.target.value);
            this.$emit('update:keyword', e.target.value);
        },
        handlePointerEvents(device = 'mobi') {
            const el = this.$refs && this.$refs['siteSearchInput'];
            if (!el) {
                return;
            }

            if (device === 'pc') {
                el.classList.remove('pointer_event_none');
            } else {
                el.classList.add('pointer_event_none');
            }
        },
        transitionend() {
            if (this.isExpend) {
                $(this.$refs['siteSearchInput']).focus();
            }
        },
    },

    computed: {
        ...Vuex.mapState(['device', 'LS']),
        siteSearchWrapClasses() {
            const classes = [];
            const styleId = this.styleId;
            const styleType = this.styleType;
            if (![6, 7, 8].includes(styleId)) {
                classes.push(styleType == 0 ? 'site_search_wrap_border' : 'bg');
            }

            if ([1, 3, 5].includes(styleId)) {
                classes.push('site_search_wrap_border_radius');
            }
            if (styleType == 0 && [0, 1].includes(styleId)) {
                classes.push('jz_theme_border_color', 'site_search_wrap_border2px');
            }
            if (styleId == 5) {
                classes.push('site_search_wrap_flex_reverse');
            }
            if (styleId == 6 || styleId == 7 || styleId == 8) {
                classes.push(`site_search_wrap_expend`);
                classes.push(`site_search_wrap_expend_${styleId}`);
                if (this.isExpend || this.isMobi) {
                    classes.push('site_search_expend_active');
                }
            }

            return classes;
        },
        searchBtnClasses() {
            const classes = [];
            const styleId = this.styleId;
            if ([0, 1, 2, 3].includes(styleId)) {
                classes.push('jz_theme_bg_color');
            }
            if ([2, 3, 4, 5].includes(styleId)) {
                classes.push('site_search_btn_wrap_width50');
            }
            if (styleId == 4) {
                classes.push('jz_theme_fill_color');
            } else if (styleId == 5 || styleId == 6 || styleId == 7 || styleId == 8) {
                if (this.isExpend || this.isMobi) {
                    classes.push('jz_theme_fill_color');
                } else {
                    classes.push('site_search_btn_wrap_svg_grey');
                }
            }

            return classes;
        },
        searchInputClasses() {
            const classes = [];
            const styleId = this.styleId;
            if (styleId == 5) {
                classes.push('site_search_input_pl0');
            }
            return classes;
        },
        searchInputWrapClass() {
            const styleId = this.styleId;
            const classes = [];
            if (styleId == 6 || styleId == 7 || styleId == 8) {
                classes.push('site_search_input_wrap_expend');
            }

            return classes;
        },
        isMobi() {
            return this.device == 'mobi';
        },
    },

    mounted() {
        this.handlePointerEvents(this.device);
    },
};
