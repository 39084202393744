//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { decodeHtml } from '@/components/utils.js';
export default {
    name: 'UnitSearchResultFile',
    inject: ['module'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        other: {
            type: Object,
            default: () => ({}),
            require: true,
        },
    },
    data() {
        return {
            fileTypeSvg: {},
            moduleId: 'file_download',
        };
    },
    computed: {
        ...Vuex.mapState(['LS']),
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        renderFileList() {
            if (!Array.isArray(this.list)) {
                return [];
            }
            return this.list.map((file) => {
                return {
                    ...file,
                    svg: this.transformSvg(file.fileName),
                    size: Comm.transformSize(file.size),
                };
            });
        },
        svgStyle() {
            return this.isMobi ? { width: '36px', height: '36px' } : { width: '48px', height: '48px' };
        },
        pageCount() {
            const totalSize = this.fileDownload.total || 0;
            return totalSize % this.other.pageSize === 0
                ? totalSize / this.other.pageSize
                : Math.ceil(totalSize / this.other.pageSize);
        },
        noPicStyle() {
            return this.module.prop0 === 0;
        },
        fileDownloadStyleClass() {
            return `jz_file_download_${this.module.prop0}`;
        },
    },
    created() {
        this.fileTypeSvg = new Map([
            ['.pdf', '#icon_pdf'],
            ['.doc', '#icon_word'],
            ['.xls', '#icon_exls'],
            ['.xlsx', '#icon_exls'],
            ['.docx', '#icon_word'],
            ['.pptx', '#icon_ppt'],
            ['.ppt', '#icon_ppt'],
            ['.txt', '#icon_txt'],
            ['.mp4', '#icon_video'],
            ['.avi', '#icon_video'],
            ['.rm', '#icon_video'],
            ['.mp3', '#icon_Music'],
            ['.zip', '#icon_GZIP'],
            ['.rar', '#icon_GZIP'],
            ['.jpg', '#icon_image'],
            ['.png', '#icon_image'],
            ['.gif', '#icon_image'],
        ]);
    },
    methods: {
        decodeHtml,
        transformSvg(filename) {
            for (let fileType of this.fileTypeSvg.entries()) {
                if (filename) {
                    if (filename.toLocaleLowerCase().endsWith(fileType[0])) {
                        return fileType[1];
                    }
                }
            }
            return '#icon_file';
        },
    },
};
