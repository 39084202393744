//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../../utils';
const { bindFlag } = Comm;
import { Message } from '@shared/manage/componMessage/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import FileUpload from './fileUpload';
import { setNewFileUploadStyle } from '@api/form';
export default {
    components: {
        FileUpload,
    },
    props: {
        item: Object,
        value: {
            type: [Object, Array],
            default: () => ({}),
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
        form: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            uploadPreviewFileList: [],
            showPcViewer: false,
            showMobiViewer: false,
            zIndex: 9999,
            imageIndex: 0,
            sumbitFileList: [],
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'oem', 'siteVer', 'jzVersion', 'LS']),
        siteFormSubmitFileUploadTips() {
            return this.LS.siteFormSubmitFileUploadTips;
        },
        siteFormSubmitFileUploadBtnText() {
            return this.LS.siteFormSubmitFileUploadBtnText;
        },
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-在线表单',
                    jz_version: this.jzVersion,
                },
            };
        },
        fileLimitSize() {
            let fileSize = this.item.size;
            let fileLimitSize = this.module.fileSizeLimit || 50;

            if (this.oem) {
                if (this.siteVer <= 120) {
                    fileLimitSize = 10;
                }
            } else {
                if (this.siteVer <= 10) {
                    fileLimitSize = 1;
                }
            }

            if (fileSize > fileLimitSize) {
                return fileLimitSize;
            } else {
                return fileSize;
            }
        },
        fileNumLimit() {
            return this.item?.fileNumLimit ?? 1;
        },
        newFormModuleFileUploadStyle: bindFlag(0x1), // 上传文件新样式 模块
        newFormFileUploadStyle: bindFlag(0x8000, 'form.flag'), // 上传文件新样式 表单
        newFileUploadStyle: {
            get() {
                return this.isLinkToForm ? this.newFormFileUploadStyle : this.newFormModuleFileUploadStyle;
            },
            set(val) {
                if (this.isLinkToForm) {
                    this.newFormFileUploadStyle = val;
                    setNewFileUploadStyle({
                        formId: this.form.id,
                        isNew: this.newFormFileUploadStyle,
                    }).then(() => {
                        Message.success({
                            message: '保存成功',
                            autoHide: true,
                        });
                    });
                } else {
                    this.newFormModuleFileUploadStyle = val;
                }
            },
        },
        isLinkToForm() {
            return !!this?.module?.isLinkToForm;
        },
    },
    mounted() {
        !this.newFileUploadStyle && this.initUpload();
    },
    methods: {
        toNewFileUploadStyle() {
            this.newFileUploadStyle = true;
        },
        input(data) {
            this.$emit('input', data);
        },
        initUpload() {
            var fileSize = this.fileLimitSize;
            var fileTypeList = '*.*';
            if (!this.item.isAllFile) {
                //自定义类型
                fileTypeList = ((this.item.dftl || '') + (this.item.cftl || ''))
                    .split(' ')
                    .filter((item) => item)
                    .map((item) => `*.${item}`)
                    .join(';');
            }
            var advance_setting = {
                siteFree: false, //是否为免费版
                updateUrlViewRes: '', //升级目标版本URL
                auto: true,
                fileTypeExts: fileTypeList,
                multi: false,
                fileSizeLimit: fileSize * 1024 * 1024,
                fileSizeLimitTips: this.LS.siteFormSubmitFileUploadTips,
                fileSplitSize: 20 * 1024 * 1024,
                breakPoints: true,
                saveInfoLocal: false,
                showUploadedPercent: false, //是否实时显示上传的百分比，如20%
                showUploadedSize: false,
                removeTimeout: 9999999,
                post_params: {
                    app: 21,
                    type: 0,
                    fileUploadLimit: fileSize,
                    isSiteForm: true,
                    pieceUpload: true, // 开启断点续传
                },
                isBurst: true, //表单先不分片,因为断点续传后台还不支持。到时可以了再去掉    -- 开启分片
                isDefinedButton: true,
                buttonText: '',
                // uploader:"/ajax/commUpsiteimg_h.jsp?cmd=mobiUpload&_TOKEN="+$('#_TOKEN').attr('value'),
                uploader: '/ajax/advanceUpload.jsp?cmd=_mobiupload', //调用genUploadUrl 可改变url
                onUploadSuccess: (file, text) => {
                    var data = jQuery.parseJSON(text);
                    if (data.success) {
                        successMessage(Fai.format(LS.siteFormSubmitFileUploadSucess, file.name), true);
                        this.$emit('input', data);
                    } else {
                        warningMessage(LS.files + ':' + file.name + '，' + data.msg, true);
                    }
                },
                onUploadError(file, repText) {
                    $('#progressBody_ ' + file.id).remove();
                    $('#progressWrap_' + file.id).remove();
                    warningMessage('文件：' + file.name + '  ' + JSON.parse(repText).msg, true);
                },
                onSelect() {
                    if (this.manageMode) {
                        Message.warning('当前为管理状态，文件上传无效。');
                        return false;
                    }
                    // 上传之前检查是否超过限制
                    return true;
                },
                onUploadStart(file) {
                    $('#progressBody_ ' + file.id).remove();
                    $('#progressWrap_' + file.id).remove();
                },
                msgInfo(type, msg) {
                    if (type === 'warning') {
                        warningMessage(msg, true);
                    }
                },
            };
            $(this.$refs.uploadBtn).uploadify(advance_setting);
        },
        uploadFile() {
            $(this.$refs.uploadBtn).find('a').click();
        },
    },
};
