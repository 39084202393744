//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: Object,
        value: {
            type: Array,
            default: () => [],
        },
        module: Object,
        requiredIcon: Boolean,
    },
    data() {
        return {
            //管理态不能修改item的value，所以要用一个临时的值储存，跟siteFormData分开
            manageVal: [],
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        itemList() {
            return (this.item.input || '').split('\n').filter((item) => item);
        },
        currentVal() {
            if (!this.manageMode) {
                return this.value;
            } else {
                return this.manageVal;
            }
        },
        ruleList() {
            return this.item.rules || [];
        },
    },
    methods: {
        isChecked(item) {
            return this.currentVal.includes(item);
        },
        toggleItem(item) {
            let newVal = this.currentVal.slice();
            const isChecked = this.isChecked(item);
            if (isChecked) {
                newVal = newVal.filter((oldItem) => oldItem !== item);
            } else {
                newVal.push(item);
            }

            if (!this.manageMode) {
                this.$emit('input', newVal);
            } else {
                this.manageVal = newVal;
            }
            this.$nextTick(() => {
                this.checkRuleChange();
            });
        },
        checkRuleChange() {
            const list = [];
            this.ruleList.forEach((rule) => {
                const flag = `${rule.contentId}_${rule.optionName}`;
                rule.showList.forEach((showItem) => {
                    if (this.isChecked(this.itemList[rule.selected])) {
                        list.push({ type: 'add', flag, itemId: showItem.key });
                    } else {
                        list.push({
                            type: 'reduce',
                            flag,
                            itemId: showItem.key,
                        });
                    }
                });
            });
            this.$emit('change-by-rule', list);
        },
    },
};
