var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentInfo.compon,
    {
      tag: "component",
      staticClass: "jz_web_rows",
      attrs: {
        tag: _vm.componentInfo.tag,
        "move-class": _vm.componentInfo.moveClass,
      },
    },
    [
      _vm._l(_vm.curRows, function (row, index) {
        return [
          _c("jz-web-row-entry", {
            key: row.id,
            attrs: {
              row: row,
              index: index,
              "rows-length": _vm.curRows.length,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }