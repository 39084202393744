//
//
//
//
//

import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
export default {
    props: ['projectid'],
    computed: {
        id() {
            return this.projectid;
        },
        ...Vuex.mapState(['device']),
        modules() {
            return window._store.state.modules;
        },
        isMobi() {
            return this.device === 'mobi';
        },

        module() {
            return this.modules['module' + this.id];
        },
    },
    mounted() {
        let featureListener = `f-MarginResize${this.id}`;
        eventBus.$off(`${featureListener}.start`).$on(`${featureListener}.start`, () => {
            this.module.prop2 = 0;
        });
    },
    destroy() {
        let featureListener = `f-MarginResize${this.id}`;
        eventBus.$off(`${featureListener}.start`);
    },
};
