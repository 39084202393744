//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            inputText: '',
        };
    },
    methods: {
        initMaxLength() {
            var maxLength = 100;
            var wordLimit = this.item.wordLimit;
            if (typeof wordLimit != 'undefined' && wordLimit != null) {
                if (typeof wordLimit != 'object') {
                    try {
                        wordLimit = JSON.parse(wordLimit);
                    } catch (e) {
                        wordLimit = {};
                    }
                }

                if (parseInt(wordLimit.o) == 1) {
                    maxLength = wordLimit.a;
                }
            }
            return maxLength;
        },
        inputItemText(e) {
            var value = e.target.value;
            this.inputText = value;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.inputText);
        },
    },
};
