import { JzFdpLog } from '@/site/shared/log/index.js';
window.addEventListener('beforeunload', () => {
    if (JzFdpLog?.requestList?.length) {
        JzFdpLog.fdpRequest(JzFdpLog.requestList);
        JzFdpLog.clearRequestData();
        JzFdpLog.clearTimeout();
    }
});
window.addEventListener('unload', () => {
    if (JzFdpLog?.requestList?.length) {
        JzFdpLog.fdpRequest(JzFdpLog.requestList);
        JzFdpLog.clearRequestData();
        JzFdpLog.clearTimeout();
    }
});
export default JzFdpLog;
