//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils.js';
const { getComputedTextColor } = Comm;
export default {
    name: 'GroupItem',
    props: {
        isOnlyOneLevel: {
            type: Boolean,
        },
        groupList: {
            type: Array,
            default: () => {
                return [];
            },
            required: true,
        },
        rowCount: {
            type: Number,
            default: 1,
        },
        module: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parentId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'colId']),
        openType: Comm.bindFlag(0x1), // false: 新窗口 true: 当前窗口
        isMobi() {
            return this.device === 'mobi';
        },
        // eslint-disable-next-line vue/return-in-computed-property
        style() {
            if (this.module.prop0 === 2) {
                return 0;
            } else if (this.module.prop0 === 3) {
                return 1;
            }
        },
        isNewModule() {
            return this.module.isNewModule;
        },
        pattern() {
            return this.module.privatePattern[this.device];
        },
        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },
        pcPrivatePattern() {
            return this.module.privatePattern.pc;
        },
        oldFontPattern() {
            return this.pattern['cft'] || {};
        },
        fontPattern() {
            const k = {
                1: 'cff',
                2: 'csf',
                3: 'ctf',
            };
            return this.pattern[k[this.rowCount]];
        },
        linePattern() {
            return this.isMobi && this.mobiPrivatePattern.cl.t === 0 && this.isNewModule
                ? this.pcPrivatePattern.cl
                : this.pattern.cl;
        },
        lineHeightPatten() {
            return this.isMobi && this.mobiPrivatePattern.clht === 0 && this.isNewModule
                ? this.pcPrivatePattern.clh
                : this.pattern.clh;
        },
        lineHeightPattenType() {
            return this.isMobi && this.mobiPrivatePattern.clht === 0 && this.isNewModule
                ? this.pcPrivatePattern.clht
                : this.pattern.clht;
        },
        fontStyle() {
            const style = {};

            if (this.lineHeightPattenType === 1) {
                style['line-height'] = `${this.lineHeightPatten}px`;
            }
            if (this.rowCount === 1 && this.manageMode) {
                Comm.extend(style, this.alginStyle);
            }
            return style;
        },
        oldLinkHoverStyle() {
            const style = {};

            if (this.isMobi) {
                return {};
            }

            if (this.oldFontPattern.y === 1) {
                Comm.extend(style, getComputedTextColor(this.oldFontPattern.hc));
            }

            return style;
        },
        linkHoverStyle() {
            const style = {};

            if (this.isMobi) {
                return {};
            }

            if (this.fontPattern.y === 1) {
                Comm.extend(style, getComputedTextColor(this.fontPattern.hc));
            }

            if (!this.isNewModuleAfter20210707 && this.fontPattern.y === 0) {
                Comm.extend(style, this.oldLinkHoverStyle);
            }

            return style;
        },
        algin() {
            let al = this.pattern.al;
            // 手机视图，如果选择默认，则使用pc的数据
            if (this.isMobi && this.module.privatePattern.mobi.al === 0) {
                al = this.module.privatePattern.pc.al;
            }
            return al;
        },
        isApplyAlgin() {
            return !(this.style === 1 || !this.isOnlyOneLevel) && this.algin == 2;
        },
        alginStyle() {
            const style = {};
            if (!this.isApplyAlgin) {
                return style;
            }

            style['text-align'] = ['', 'left', 'center'][this.algin];

            return style;
        },
        isNewModuleAfter20210707() {
            return Comm.isNewModuleAfter20210707(this.module);
        },
    },
    watch: {
        'module.prop6': function () {
            this.$nextTick(function () {
                if (this.module.prop0 === 2 && this.manageMode) {
                    this.expendLevel();
                }
            });
        },
    },
    mounted() {
        if (this.module.prop6) {
            this.expendLevel();
        }
    },
    updated: function () {
        this.$nextTick(function () {
            if (this.module.prop0 === 2 && this.manageMode && this.module.prop6) {
                this.expendLevel();
            }
        });
    },
    methods: {
        itemStyle(index) {
            const style = {};
            if (index !== this.groupList.length - 1 && this.rowCount == 1) {
                if (this.linePattern.t === 1 && (this.style === 0 || this.isMobi)) {
                    const borderStyle = ['solid', 'dotted', 'dashed'][this.linePattern.s];
                    style['border-bottom'] = `${this.linePattern.w}px ${borderStyle} ${this.linePattern.c}`;
                }
            }
            return style;
        },
        showSubGroup(e) {
            if (this.style || this.isMobi) {
                return;
            }
            let $subGroup = $(e.currentTarget).next('.cate_group_list');
            let $switchIcon = $(e.currentTarget).find('.m_categroup__switchicon');
            let down = $subGroup.hasClass('m_categroup_linkwrap-active');
            if (!down) {
                $subGroup.slideDown();
                $switchIcon.addClass('m_categroup__switchicon-active');
                $subGroup.addClass('m_categroup_linkwrap-active');
            } else {
                $subGroup.slideUp();
                $switchIcon.removeClass('m_categroup__switchicon-active');
                $subGroup.removeClass('m_categroup_linkwrap-active');
            }
        },
        showFixedGroup(e, level) {
            if (!this.style || this.isMobi || level != 1) {
                return;
            }
            // 触发enter就直接让所有二级面板回归原节点处，并且隐藏所有二级面板；
            this.panelHoming();
            let $el = $(`#module${this.module.id}`),
                $target = $(e.currentTarget),
                topCss = $target.offset().top + $target.outerHeight(),
                $subGroup = $target.find('> .cate_group_rowwrap2');
            $subGroup.appendTo('body');

            $subGroup.css({
                top: topCss,
                left: $el.offset().left + 5,
                width: $el.find('.cate_group_rowwrap1').width(),
            });
            $subGroup.show();
        },
        hideFixedGroup(e, groupId, level) {
            if (!this.style || this.isMobi || level != 1) {
                return;
            }
            let id = groupId,
                $panel = $(`body > .J_categroup__hoveritem-${id}`),
                _this = this;
            // 隐藏
            if (!$panel.length) {
                return;
            }
            // id --> panelId
            let { pageX, pageY } = e;
            let { left: screenLeft, top: screenTop } = $panel.offset();
            // 如果二级面板的位置已经归位，那就说明已经隐藏过了，不需要再隐藏了。
            if ($panel.parent(`.m_categroup_itemwrap1[data-id=${String(this.module.id) + String(id)}]`).length) {
                return;
            }
            // 判断鼠标鼠标在对应id的Panel内，是的话不能隐藏，如果不是则隐藏。
            if (
                pageX >= screenLeft &&
                pageX <= screenLeft + $panel.outerWidth() &&
                pageY >= screenTop &&
                pageY <= screenTop + $panel.outerHeight()
            ) {
                $panel.off('mouseleave.showPanel').on('mouseleave.showPanel', function (event) {
                    setTimeout(() => {
                        let tabs = Array.from($(`#module${_this.module.id}`).find('.m_categroup_itemwrap1'));
                        let isOverTab = tabs.some((tab) => {
                            let $tab = $(tab);
                            let { pageX, pageY } = event;
                            let { left: screenLeft, top: screenTop } = $tab.offset();
                            return (
                                pageX >= screenLeft &&
                                pageX <= screenLeft + $tab.outerWidth() &&
                                pageY >= screenTop &&
                                pageY <= screenTop + $tab.outerHeight()
                            );
                        });
                        if (isOverTab) {
                            return;
                        }
                        // 从body移动回原父级节点
                        _this.panelHoming($(this));
                    }, 20);
                });
                return;
            }
            // 从body移动回原父级节点
            this.panelHoming($panel);
        },
        panelHoming($target) {
            $('body > .J_categroup__hoveritem').hide();
            if ($target) {
                let fromId = $target.attr('data-id');
                if (!$target.parent(`.m_categroup_itemwrap1[data-id=${fromId}]`).length) {
                    $(`.m_categroup_itemwrap1[data-id=${fromId}]`).append($target);
                }
            } else {
                $('body > .J_categroup__hoveritem').each(function (_, panel) {
                    let $panel = $(panel),
                        fromId = $panel.attr('data-id');
                    if ($panel.parent(`.m_categroup_itemwrap1[data-id=${fromId}]`).length) {
                        return;
                    }
                    $(`.m_categroup_itemwrap1[data-id=${fromId}]`).append(panel);
                });
            }
        },
        showPanel(group) {
            if (!this.isMobi) {
                return;
            }
            if (!group.children || !group.children.length) {
                if (!group.href?.startsWith('javascript:')) {
                    window.open(group.href);
                }
                return;
            }
            this.$emit('on-showpanel', group);
        },
        getLinkTarget(item) {
            return (this.module.style === 39 ? item.target : !this.openType) ? '_blank' : '';
        },
        getSelectClass(item) {
            return this.module.style === 39 && item.id === this.colId ? 'category_select_item' : '';
        },
        expendLevel() {
            if (!this.isMobi) {
                this.$refs.mCategroupLinkwraps.forEach((mCategroupLinkwrap) => {
                    mCategroupLinkwrap.click();
                });
            }
        },
    },
};
