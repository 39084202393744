var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    [
      !_vm.isOldStyle
        ? [
            _vm._v(
              "\n        " +
                _vm._s(_vm.classPrefix) +
                " .form_submit { border-radius: " +
                _vm._s(_vm.btnBorderRadius) +
                "px;\n        "
            ),
            _vm.btnBackgroundType == 1 && !_vm.isStyle5
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.backgroundColorCss(_vm.btnBackgroundSetting.ic)
                      ) +
                      ";\n        "
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.btnFontSetting.y == 1
              ? [
                  _vm._v(
                    "\n            color: " +
                      _vm._s(_vm.btnFontSetting.c) +
                      "; font-weight: " +
                      _vm._s(_vm.btnFontSetting.w == 1 ? "bold" : "initial") +
                      ";\n            font-style: " +
                      _vm._s(_vm.btnFontSetting.i == 1 ? "italic" : "initial") +
                      "; text-decoration:\n            " +
                      _vm._s(
                        _vm.btnFontSetting.d == 1 ? "underline" : "initial"
                      ) +
                      "; font-size: " +
                      _vm._s(_vm.btnFontSetting.s) +
                      "px; font-family:\n            " +
                      _vm._s(_vm.btnFontSetting.f) +
                      ";\n        "
                  ),
                ]
              : _vm._e(),
            _vm._v("\n        }\n        "),
            _vm.isStyle5
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.classPrefix) +
                      " .submit_btn_container { border-radius: " +
                      _vm._s(_vm.btnBorderRadius) +
                      "px; }\n        "
                  ),
                ]
              : _vm._e(),
            _vm._v(
              "\n\n        " +
                _vm._s(_vm.classPrefix) +
                " .submit_btn_container:hover .form_submit {\n        "
            ),
            _vm.btnBackgroundType == 1 && !_vm.isStyle5 && _vm.isPc
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.backgroundColorCss(
                          _vm.btnBackgroundSetting.hc,
                          true
                        )
                      ) +
                      ";\n        "
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.btnFontSetting.y == 1 && _vm.isPc
              ? [_vm._v(" color: " + _vm._s(_vm.btnFontSetting.hc) + "; ")]
              : _vm._e(),
            _vm._v("\n        }\n\n        "),
            _vm.btnBackgroundType == 1 && _vm.isStyle5
              ? [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.classPrefix) +
                      " .submit_btn_container {\n            " +
                      _vm._s(
                        _vm.backgroundColorCss(_vm.btnBackgroundSetting.ic)
                      ) +
                      "\n            }\n            " +
                      _vm._s(_vm.classPrefix) +
                      " .form_submit { background-color: transparent; background-image: initial;}\n            " +
                      _vm._s(_vm.classPrefix) +
                      " .submit_btn_container .after {\n            " +
                      _vm._s(
                        _vm.backgroundColorCss(_vm.btnBackgroundSetting.hc)
                      ) +
                      "\n            }\n        "
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }