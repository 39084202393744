import { deepExtendClone } from '@jz/utils';
import { JzFdpLog } from '@/site/shared/log/index.js';

/**
 * @function name slideshowView
 * @description 幻灯片
 * @author mikey.chuhui
 * @date 2021-02-03
 * @param options {Object}
 * @example
 * slideshowView({
 *                   id: 999,//幻灯片id Number
 *                   previewSrcList: [{src:"",title:"title", description:"description"}], // 幻灯片图片列表 Array
 *                   initialIndex: 0,// 打开幻灯片的默认图片 Number
 *                   logEventConfig: {// fdplog
 *                      jz_content_terminal: 'site',
                        jz_version: Fai.top.jzVersion,
 *                   },
 *               });
 */

export const slideshowView = (options) => {
    const _options = deepExtendClone(
        {
            id: `${Math.random() * 10000}`,
            previewSrcList: [],
            zIndex: 9999,
            initialIndex: 0,
            onLog: JzFdpLog.logFdpRequest,
            disableLongPress: window._store.state.contextMenuDisabled,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'unknow',
                    jz_version: window?._store?.state?.jzVersion,
                },
            },
            manageMode: window._store.state.manageMode,
        },
        options
    );
    bizShared.slideshowView(_options);
};
