var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module_content_detail" },
    [
      _vm.module.notAdded
        ? _c(
            "div",
            { staticClass: "module_product_detail_content pd_del_show" },
            [_vm._m(0)]
          )
        : !_vm.emptyProduct
        ? _c(
            "div",
            {
              staticClass: "module_product_detail_content",
              class: _vm.moduleClasses,
            },
            [
              _c("div", { staticClass: "product_detail_wrap" }, [
                _vm.isNonePicStyle
                  ? _c("div", { staticClass: "product_detail_left_content" }, [
                      _c("div", { staticClass: "detail_inner_wrap" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showDefaultImg,
                                expression: "showDefaultImg",
                              },
                            ],
                            staticClass: "detail_big_pic_content",
                            class: {
                              mobi_hide_big_pic_content:
                                _vm.productList.picPathList &&
                                _vm.productList.picPathList.length > 1,
                            },
                          },
                          [
                            _vm._l(
                              _vm.productList.picPathList,
                              function (list, key) {
                                return [
                                  _vm.module.prop0 == 0 ||
                                  (_vm.module.prop0 == 2 && key == 0)
                                    ? _c("img", {
                                        key: list.path,
                                        staticClass: "detail_big_pic",
                                        class: "detail_big_pic" + key,
                                        attrs: { index: key, src: list.path },
                                        on: {
                                          click: function ($event) {
                                            return _vm.photoSlide(key)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              }
                            ),
                            _vm._v(" "),
                            !_vm.productList.picPathList ||
                            _vm.productList.picPathList.length == 0
                              ? _c("img", {
                                  staticClass: "detail_big_pic detail_big_pic0",
                                  attrs: { src: _vm.productList.picPath },
                                  on: {
                                    click: function ($event) {
                                      return _vm.photoSlide(_vm.key)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showVideo
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bf",
                                    attrs: { title: "播放视频" },
                                    on: { click: _vm.getProductVideo },
                                  },
                                  [
                                    _c("div", { staticClass: "bfDiv" }, [
                                      _vm._v("视频"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.productList.picPathList &&
                        _vm.productList.picPathList.length >= 1
                          ? _c(
                              "ul",
                              { staticClass: "mobi_detail_big_pic_content" },
                              [
                                _vm.showVideo
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "bf",
                                        attrs: { title: "播放视频" },
                                        on: { click: _vm.getProductVideo },
                                      },
                                      [
                                        _c("div", { staticClass: "bfDiv" }, [
                                          _vm._v("视频"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.productList.picPathList,
                                  function (list, key) {
                                    return _c(
                                      "li",
                                      {
                                        key: list.path,
                                        staticClass:
                                          "detail_big_pic_content mobi_detail_big_pic_item",
                                        class: "mobi_detail_big_pic_item" + key,
                                        attrs: { index: key },
                                        on: {
                                          click: function ($event) {
                                            return _vm.photoSlide(key)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mobi_detail_big_pic",
                                          class: "mobi_detail_big_pic" + key,
                                          attrs: { src: list.path },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mobi_detail_page" }, [
                                  _vm._v(
                                    "1/" +
                                      _vm._s(_vm.productList.picPathList.length)
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.productList.picPathList &&
                        _vm.productList.picPathList.length >= 1
                          ? _c(
                              "div",
                              {
                                staticClass: "detail_small_pic_wrap",
                                class: {
                                  beyond_pic_area: _vm.smallPicBeyondArea,
                                },
                              },
                              [
                                _vm.smallPicBeyondArea && _vm.module.prop0 != 2
                                  ? _c("a", {
                                      staticClass:
                                        "small_pic_prev small_pic_arrow",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "jz_scroll_wrap" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "detail_small_pic_lists jz_scroll",
                                    },
                                    [
                                      _vm._l(
                                        _vm.productList.picPathList,
                                        function (list, key) {
                                          return [
                                            _vm.module.prop0 == 0 ||
                                            (_vm.module.prop0 == 2 &&
                                              _vm.showVideo == true &&
                                              key != 0) ||
                                            (_vm.module.prop0 == 2 &&
                                              _vm.showVideo == false)
                                              ? _c(
                                                  "li",
                                                  {
                                                    key: list.path,
                                                    staticClass:
                                                      "detail_small_pic_list jz_theme_border_hover_color jz_hover",
                                                    class: {
                                                      select_small_pic:
                                                        key == 0,
                                                      jz_theme_border_color:
                                                        key == 0,
                                                    },
                                                    style: _vm.needHover,
                                                    attrs: { index: key },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.photoSlide(
                                                          key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "position_center_middle self_adaption_img",
                                                      attrs: {
                                                        src: list.path,
                                                        path: list.path,
                                                        index: key,
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.smallPicBeyondArea && _vm.module.prop0 != 2
                                  ? _c("a", {
                                      staticClass:
                                        "small_pic_next small_pic_arrow",
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.flagPrevNext,
                              expression: "!flagPrevNext",
                            },
                          ],
                          staticClass: "detail_pagination_wrap",
                          domProps: {
                            innerHTML: _vm._s(_vm.productPreAndNextHtml),
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isScrollStyle && _vm.productList.picPathList.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "product_img_radio",
                        style: _vm.isImgRadioStyle,
                        on: { click: _vm.scrollIndexImg },
                      },
                      _vm._l(
                        _vm.productList.picPathList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "product_img_radio_item_wrap",
                              attrs: { "data-index": index },
                            },
                            [
                              _c("div", {
                                staticClass: "product_img_radio_item",
                                class: {
                                  product_img_radio_item_active:
                                    index == _vm.activeIndex,
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product_detail_right_content",
                    style: _vm.isRightContentStyle,
                  },
                  [
                    _c("div", { staticClass: "detail_title_wrap" }, [
                      _c("h1", { staticClass: "detail_main_title" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.productList.name) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "detail_func_wrap" }, [
                        _vm.qrFlag && !_vm.flagQrCode
                          ? _c("div", { staticClass: "detail_QRcode" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    id: "图层_1",
                                    "data-name": "图层 1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20",
                                    width: _vm.svgGap,
                                    height: "20px",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M8,12a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1H8m0-1H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V13a2,2,0,0,0-2-2Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M17,3a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1H13a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1h4m0-1H13a2,2,0,0,0-2,2V8a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M14,5h2V7H14Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M5,14H7v2H5Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M5,5H7V7H5Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M18.5,14a.5.5,0,0,0-.5.5V18H14.5a.5.5,0,0,0,0,1h4a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,0,18.5,14Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M14.5,16a.5.5,0,0,0,.5-.5V13h2.5a.5.5,0,0,0,0-1h-3a.5.5,0,0,0-.5.5V15H11.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,1,0V16Z",
                                      fill: "#666",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M8,3A1,1,0,0,1,9,4V8A1,1,0,0,1,8,9H4A1,1,0,0,1,3,8V4A1,1,0,0,1,4,3H8M8,2H4A2,2,0,0,0,2,4V8a2,2,0,0,0,2,2H8a2,2,0,0,0,2-2V4A2,2,0,0,0,8,2Z",
                                      fill: "#666",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isNonePicStyle && _vm.module.prop0 != 2
                                ? _c(
                                    "span",
                                    { staticClass: "detail_func_content" },
                                    [_vm._v(_vm._s(_vm.qrcode))]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.flagShare,
                                expression: "!flagShare",
                              },
                            ],
                            staticClass: "detail_share jz_share_info",
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  id: "图层_1",
                                  "data-name": "图层 1",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 20 20",
                                  width: _vm.svgGap,
                                  height: "20px",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M16.5,10.06a.5.5,0,0,0-.5.5v5.6a.9.9,0,0,1-.9.9H3.9a.9.9,0,0,1-.9-.9V5a.9.9,0,0,1,.9-.9H9.5a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H3.9A1.9,1.9,0,0,0,2,5v11.2a1.9,1.9,0,0,0,1.9,1.9H15.1a1.9,1.9,0,0,0,1.9-1.9v-5.6A.5.5,0,0,0,16.5,10.06Z",
                                    fill: "#666",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M18.1,4.18h0L14.9,1.06a.5.5,0,0,0-.7.72l2.39,2.33A10,10,0,0,0,8,11.23a10.1,10.1,0,0,0-.28,1.35.5.5,0,0,0,.43.56H8.2a.5.5,0,0,0,.49-.44,10,10,0,0,1,.25-1.21,9.05,9.05,0,0,1,7.48-6.36L14.2,7.3a.5.5,0,0,0,.35.86A.54.54,0,0,0,14.9,8L18.1,4.9h0a.49.49,0,0,0,.15-.35A.51.51,0,0,0,18.1,4.18Z",
                                    fill: "#666",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isNonePicStyle && _vm.module.prop0 != 2
                              ? _c(
                                  "span",
                                  { staticClass: "detail_func_content" },
                                  [_vm._v(_vm._s(_vm.share))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("jz-share-ssr-compon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isMobi,
                                  expression: "!isMobi",
                                },
                              ],
                              attrs: {
                                list: _vm.module.prop3,
                                "share-param-list": _vm.module.shareParamList,
                              },
                              on: {
                                "share-to-pinterest": _vm.shareToPinterest,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.module.isOpenPdDesc
                      ? _c("div", {
                          staticClass: "product_desc",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.encodeHtmlStr(_vm.productInfo.salePointDesc)
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowPrice || _vm.isShowMarketPrice
                      ? _c("div", { staticClass: "detail_price_wrap" }, [
                          _c("div", { staticClass: "detail_price_content" }, [
                            _c("div", { staticClass: "detail_price_left" }, [
                              _vm.isShowPrice
                                ? _c("span", { staticClass: "detail_price" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "detail_coin jz_theme_font_color",
                                      },
                                      [_vm._v(_vm._s(_vm.choiceCurrencyVal))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "detail_value jz_theme_font_color",
                                      },
                                      [_vm._v(_vm._s(_vm.formatPrice))]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isShowMarketPrice
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "detail_price detail_marketPrice",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "detail_coin" },
                                        [_vm._v(_vm._s(_vm.choiceCurrencyVal))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "detail_value" },
                                        [_vm._v(_vm._s(_vm.formatMarketPrice))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm.isShowSales
                              ? _c(
                                  "div",
                                  { staticClass: "detail_price_right" },
                                  [
                                    _c("div", { staticClass: "detail_sales" }, [
                                      _c(
                                        "span",
                                        { staticClass: "detail_title" },
                                        [_vm._v(_vm._s(_vm.LS.g_sales))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "detail_value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.productList[
                                                _vm.getSpecialProp(
                                                  _vm.SYS_SALES
                                                ).fieldKey
                                              ]
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.module.prop0 === 2 && _vm.isPC
                      ? _c(
                          "div",
                          {
                            staticClass: "detail_other_message",
                            class: {
                              no_margin_left: !_vm.isShowPrice,
                              table: _vm.isPC,
                              table_collapse: _vm.isPC,
                            },
                            style: _vm.mobiNoneMsgStyle,
                          },
                          [
                            _c(
                              "div",
                              { class: { table_body: _vm.isPC } },
                              [
                                _vm._l(_vm.propList, function (prop) {
                                  return [
                                    _vm.isShowMessageSales(prop.id) &&
                                    prop.type != _vm.SYS_MALL_PRICE &&
                                    prop.type != _vm.SYS_MALL_MARKET_PRICE &&
                                    _vm.showList.indexOf(prop.id) != -1 &&
                                    !!_vm.productList[prop.fieldKey] &&
                                    prop.allowed
                                      ? _c(
                                          "div",
                                          {
                                            key: prop.id,
                                            staticClass: "detail_other",
                                            class: { table_row: _vm.isPC },
                                            on: { click: _vm.showMobiMsgWrap },
                                          },
                                          [
                                            _vm.showParameter
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "detail_other-prefix",
                                                    class: {
                                                      table_cell: _vm.isPC,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.module.parameter
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "detail_title",
                                              class: { table_cell: _vm.isPC },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.encodeHtmlStr(prop.name)
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "detail_value",
                                                class: { table_cell: _vm.isPC },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.productList[
                                                      prop.fieldKey
                                                    ]
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass:
                                                "detail_other_message_icon_for_mobi faisco-icons-S000106",
                                              class: { table_cell: _vm.isPC },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "detail_other_message",
                            class: { no_margin_left: !_vm.isShowPrice },
                            style: _vm.mobiNoneMsgStyle,
                          },
                          [
                            _vm._l(_vm.propList, function (prop) {
                              return [
                                _vm.isShowMessageSales(prop.id) &&
                                prop.type != _vm.SYS_MALL_PRICE &&
                                prop.type != _vm.SYS_MALL_MARKET_PRICE &&
                                _vm.showList.indexOf(prop.id) != -1 &&
                                !!_vm.productList[prop.fieldKey] &&
                                prop.allowed
                                  ? _c(
                                      "div",
                                      {
                                        key: prop.id,
                                        staticClass: "detail_other",
                                        on: { click: _vm.showMobiMsgWrap },
                                      },
                                      [
                                        _vm.showParameter
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "detail_other-prefix",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.module.parameter)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "detail_title",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.encodeHtmlStr(prop.name)
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "detail_value" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.productList[prop.fieldKey]
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "detail_other_message_icon_for_mobi faisco-icons-S000106",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                    _vm._v(" "),
                    _vm.advisoryInfo.openProductAdvisory ||
                    _vm.productInfo.openProductForm
                      ? _c(
                          "div",
                          {
                            staticClass: "product_button_wrap",
                            class: {
                              product_button_wrap_style_2:
                                !_vm.isNonePicStyle ||
                                !_vm.isScrollStyle ||
                                !_vm.isShowPrice ||
                                !_vm.hasProps,
                              product_button_mobi_wrap: _vm.isMobi,
                            },
                          },
                          [
                            _vm.advisoryInfo.openProductAdvisory
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "product_advisory_btn jz_theme_bg_color",
                                    on: { click: _vm.handleAdvisoryClick },
                                  },
                                  [
                                    _vm.advisoryInfo.advisoryWay == 0 &&
                                    !_vm.isMobi
                                      ? [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "advisory_phone_svg",
                                            },
                                            [
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href": "#icon_phone",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "product_advisory_phone_text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.advisoryInfo.advisoryPhone
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.advisoryInfo.advisoryTxt)
                                          ),
                                        ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productInfo.openProductForm
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "product_detail_form_btn jz_theme_font_color jz_theme_border_color",
                                    class: {
                                      jz_secondary_bg_color:
                                        _vm.isMobi &&
                                        _vm.advisoryInfo.openProductAdvisory,
                                      jz_theme_bg_color:
                                        _vm.isMobi &&
                                        !_vm.advisoryInfo.openProductAdvisory,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.openForm.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.productInfo.globalProductFormText
                                      ) + "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.flagPrevNext && !_vm.isNonePicStyle,
                          expression: "!flagPrevNext && !isNonePicStyle",
                        },
                      ],
                      staticClass: "detail_pagination_wrap",
                      domProps: {
                        innerHTML: _vm._s(_vm.productPreAndNextHtml),
                      },
                    }),
                    _vm._v(" "),
                    _vm.isMobi
                      ? _c(
                          "div",
                          {
                            ref: "mobiMsgBigWrap",
                            staticClass: "detail_other_message_mobi_wrap",
                          },
                          [
                            _c("div", {
                              ref: "mobiMsgBg",
                              staticClass: "detail_other_panel_bg",
                              on: { click: _vm.hideMobiMsgWrap },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "mobiMsgWrap",
                                staticClass: "detail_other_message_mobi",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "detail_other_panel_title" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.module.mobi_productParameter)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "detail_other_scroll_wrap" },
                                  [
                                    _vm._l(_vm.propList, function (prop) {
                                      return [
                                        _vm.isShowMessageSales(prop.id) &&
                                        prop.type != _vm.SYS_MALL_PRICE &&
                                        prop.type !=
                                          _vm.SYS_MALL_MARKET_PRICE &&
                                        _vm.showList.indexOf(prop.id) != -1 &&
                                        !!_vm.productList[prop.fieldKey]
                                          ? _c(
                                              "div",
                                              {
                                                key: prop.id,
                                                staticClass: "detail_other",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "detail_other_item_left",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail_title",
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "detail_title_inner",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.encodeHtmlStr(
                                                                prop.name
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "detail_other_item_right",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "detail_value",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.productList[
                                                              prop.fieldKey
                                                            ]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "detail_other_message_mobi_btn jz_theme_bg_color",
                                on: { click: _vm.hideMobiMsgWrap },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.module.iknow)
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product_detail_specific_wrap" }, [
                _c("div", { staticClass: "product_detail_specific_content" }, [
                  _c("div", { staticClass: "detail_specific_tab_wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "detail_specific_tab",
                        class: "tab_item_" + _vm.showTabLen,
                      },
                      [
                        _vm._l(_vm.showTabLists, function (tabList) {
                          return [
                            _vm.showTabListItem(tabList)
                              ? _c(
                                  "span",
                                  {
                                    key: "tab_" + tabList.id,
                                    staticClass: "detail_tab_item",
                                    class: _vm.tabListItemClass(tabList),
                                    attrs: { _topanel: _vm.tabPanel(tabList) },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.tabListItemLabel(tabList))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail_specfic_panel" },
                    [
                      _vm._l(_vm.showTabLists, function (tabList) {
                        return [
                          _vm.showTabListItem(tabList)
                            ? [
                                tabList.id == 1
                                  ? [
                                      _vm.productDetailContent
                                        ? _c("html-renderer", {
                                            key: tabList.id,
                                            staticClass:
                                              "detail_specific_desc detail_item_content",
                                            class: _vm.tabContentClass(tabList),
                                            attrs: {
                                              html: _vm.productDetailContent,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.bindUeSlider($event)
                                              },
                                            },
                                          })
                                        : _c(
                                            "div",
                                            {
                                              key: tabList.id,
                                              staticClass:
                                                "detail_specific_desc detail_item_content",
                                              class:
                                                _vm.tabContentClass(tabList),
                                            },
                                            [_vm._v(_vm._s(_vm.emptyDetailMsg))]
                                          ),
                                    ]
                                  : tabList.id == 2
                                  ? [
                                      _vm.isOpen &&
                                      (_vm.manageMode
                                        ? true
                                        : _vm.realNameAuth.allowShowMessage)
                                        ? _c(
                                            "div",
                                            {
                                              key: tabList.id,
                                              staticClass:
                                                "detail_specific_comment detail_item_content",
                                              class:
                                                _vm.tabContentClass(tabList),
                                            },
                                            [
                                              !_vm.module.siteGfwNotAllow
                                                ? [
                                                    _vm.isOpen &&
                                                    (_vm.manageMode
                                                      ? true
                                                      : _vm.realNameAuth
                                                          .allowShowMessage)
                                                      ? _c("productComment", {
                                                          ref: "productComment",
                                                          refInFor: true,
                                                          attrs: {
                                                            "comment-loc":
                                                              _vm.commentLoc,
                                                            "module-id":
                                                              _vm.moduleId,
                                                            "product-id":
                                                              _vm.productId,
                                                            cmd: "getProductComment",
                                                          },
                                                          on: {
                                                            changeTotalSize:
                                                              _vm.changeTotalSize,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    (
                                                      _vm.manageMode
                                                        ? true
                                                        : _vm.realNameAuth
                                                            .allowShowMessage
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "detail_evaluate_panel",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "detail_evaluate_panel_mobi",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "detail_evaluate_title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .module
                                                                            .pdComment
                                                                        ) +
                                                                        "\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "evaluate_line",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_line_title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.productCommentCreator
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_nick_name",
                                                                        attrs: {
                                                                          maxlength:
                                                                            _vm
                                                                              .productInfo
                                                                              .CREATOR_MAXLEN,
                                                                          minlength:
                                                                            _vm
                                                                              .productInfo
                                                                              .CREATOR_MINLEN,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.openCommStar
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_line",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_line_title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.pd_praiseDegree
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_star",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "commentFiveStar",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    index: 1,
                                                                                    "creat-comment": true,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_star_text",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "evaluate_line evaluate_content_wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_line_title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.msgBoardContent
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_content",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "textarea",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_text",
                                                                            attrs:
                                                                              {
                                                                                placeholder:
                                                                                  _vm.commentPlaceholder,
                                                                                maxlength:
                                                                                  _vm
                                                                                    .productInfo
                                                                                    .COMMENT_MAXLEN,
                                                                                minlength:
                                                                                  _vm
                                                                                    .productInfo
                                                                                    .COMMENT_MINLEN,
                                                                                cols: "70",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "evaluate_line evaluate_content_wrap",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_line_title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .module
                                                                              .pd_upCommImg
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_content",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_upload_pic_wrap",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "evaluate_upload_pic_lists",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "evaluate_upload_pic",
                                                                                attrs:
                                                                                  {
                                                                                    id: "evaluate_upload_pic",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "evaluate_upload_tip",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .module
                                                                                      .pageTotal
                                                                                  )
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "upload_total",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " 0 "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .module
                                                                                      .mobi_piece
                                                                                  ) +
                                                                                    "，" +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .module
                                                                                        .uploaded
                                                                                    )
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "upload_remain",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                    " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .productInfo
                                                                                            .COMMENT_UP_IMG_MAX_SIZE
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .module
                                                                                      .picFormat
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "evaluate_line",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_line_title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.verificationCode
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_auth_code",
                                                                        attrs: {
                                                                          maxlength:
                                                                            "4",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "evaluate_get_code",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticClass:
                                                                              "evaluate_code_img",
                                                                            attrs:
                                                                              {
                                                                                id: "evaluate_code_img",
                                                                                title:
                                                                                  _vm.msgBoradChageValidateCode,
                                                                                src: "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "commit_comment",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.submitCommentHandler.apply(
                                                                            null,
                                                                            arguments
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "commit_comment_btn jz_theme_bg_color",
                                                                        class: {
                                                                          "not-allowed-button":
                                                                            !_vm.manageMode &&
                                                                            _vm
                                                                              .realNameAuth
                                                                              .allowSubmitMessage ===
                                                                              false,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .module
                                                                              .productCommentSubmit
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "commit_tip",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.module
                                                            .siteGfwNoticeTips
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c("div", {
                                        key: tabList.id,
                                        staticClass: "detail_item_content",
                                        class: _vm.tabContentClass(tabList),
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.tabContent(tabList)
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.bindUeSlider($event)
                                          },
                                        },
                                      }),
                                    ],
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            { staticClass: "module_product_detail_content pd_del_show" },
            [_vm._m(1)]
          ),
      _vm._v(" "),
      !_vm.module.notAdded && !_vm.emptyProduct
        ? _c("image-main-viewer", {
            attrs: {
              "show-mobi-viewer": _vm.showMobiViewer,
              "show-pc-viewer": _vm.showPcViewer,
              "z-index": _vm.zIndex,
              "initial-index": _vm.imageIndex,
              "on-close": _vm.closeViewer,
              "url-list": _vm.previewSrcList,
              "is-mobi": _vm.isMobi,
              "log-event-config": _vm.logEventConfig,
              "manage-mode": _vm.manageMode,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jz_empty_tips" }, [
      _c("i", { staticClass: "jz_empty_tips__img" }),
      _vm._v(" "),
      _c("div", { staticClass: "jz_empty_tips__text" }, [
        _vm._v("该产品已经下架了，看看其他吧~"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jz_empty_tips" }, [
      _c("i", { staticClass: "jz_empty_tips__img" }),
      _vm._v(" "),
      _c("div", { staticClass: "jz_empty_tips__text" }, [
        _vm._v("找不到产品了，看看其他的吧~"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }