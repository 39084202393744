var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "outsideclick",
          rawName: "v-outsideclick",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside",
        },
      ],
      ref: "friendLink",
      staticClass: "module_content_detail module_friendLink_content",
    },
    [
      _c(
        "div",
        {
          staticClass: "m_friendlink__wrapper",
          class: [
            "m_friendlink__wrapper-" + _vm.moduleStyle,
            _vm.selectShowingClass,
          ],
          on: { click: _vm.toggleList },
        },
        [
          _c("div", { staticClass: "m_friendlink__tipswrap" }, [
            _c("span", { staticClass: "m_friendlink__tipsname" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.tips) + "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.moduleStyle === 0
            ? _c("div", {
                staticClass: "m_friendlink__triangle m_friendlink__triangle-up",
              })
            : _c(
                "svg",
                {
                  staticClass:
                    "m_friendlink__triangle m_friendlink__triangle-svg m_friendlink__triangle",
                },
                [_c("use", { attrs: { "xlink:href": "#icon_more" } })]
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "m_friendlink__list m_friendlink__list-" + _vm.moduleStyle,
            _vm.direction === 1 ? "m_friendlink__list-up" : "",
          ],
        },
        [
          _c(
            "ul",
            { staticClass: "m_friendlink__ul" },
            _vm._l(_vm.linkItems, function (item) {
              return _c(
                "li",
                { key: item.id, staticClass: "m_friendlink__item" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "m_friendlink__itemname",
                      class: ["m_friendlink__link-" + item.id],
                      attrs: {
                        target: item.link.ib ? "_blank" : null,
                        href: item.link.url || "javascript:void(0);",
                        title: item.name,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePcLinkClick(item.link)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ClientOnly",
        [
          _c("mobiSelect", {
            attrs: { list: _vm.linkItems },
            on: { "on-confirm": _vm.handleMobiSelectConfirm },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.name) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.showMobiSelect,
              callback: function ($$v) {
                _vm.showMobiSelect = $$v
              },
              expression: "showMobiSelect",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }