//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { createUrlArgsOnclickStr } = Comm;
import photoDescription from './photoDescription.vue';
import { linkTarget, linkNofollow } from './utils.js';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { photoSettingMixin } from '../photoSettingMixin';
const manageUse = {};
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { getImgComponetOption, imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
export default {
    components: {
        photoDescription,
        ImageComponent,
        ImageMainViewer,
    },
    mixins: [slidesshowDataMixin, photoSettingMixin],
    props: ['photoList', 'module', 'moduleStyle', 'pattern', 'usePhotoGroup', 'allPhotoList'],
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['jzVersion']),
        ...Vuex.mapGetters(['hashRemoved']),
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-列表多图',
                    jz_version: this.jzVersion,
                },
            };
        },
        descStyle() {
            return this.device === 'mobi' ? this.module.privatePattern.mobi.pds : this.module.privatePattern.pc.pds;
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.psrc;
                previewSrcItem.description = value.desc;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
        wrapperClasses() {
            switch (this.moduleStyle) {
                case 0:
                    return ['list_photos_list_style'];
                case 1:
                    return ['list_photos_slider_style'];
            }
            return [];
        },
        photosInRow() {
            var len = 4;
            if (this.isMobi) {
                if (this.moduleStyle === 1) {
                    if (this.module.privatePattern.pc.prlt === 1) {
                        len = this.module.privatePattern.pc.prl;
                    }
                } else {
                    len = 2; // 默认一行2个
                    if (this.pattern.prlt === 1) {
                        if (this.pattern.prl === 3) {
                            len = 1;
                        }
                    }
                }
            } else {
                if (this.pattern.prlt === 1) {
                    len = this.pattern.prl;
                }
            }
            return len;
        },
    },
    methods: {
        getImgComponetOption,
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        imgOriginalSrc(photoItem) {
            return photoItem.psrc || '';
        },
        prev() {
            const $list = $(this.$refs.photoList);
            $list.animate({
                scrollLeft: $list.scrollLeft() - $list.width(),
            });
        },
        next() {
            const $list = $(this.$refs.photoList);
            $list.animate({
                scrollLeft: $list.scrollLeft() + $list.width(),
            });
        },
        photoItemStyle() {
            const style = {
                //"transition": "padding 0.1s,width 0.1s",
            };
            const pcPattern = this.pcPrivatePattern,
                mobiPattern = this.module.privatePattern.mobi;
            if (pcPattern.prlt === 1 && !this.isMobi) {
                //自定义单行图片项个数
                style['width'] = `${100 / pcPattern.prl}%`;
            }

            if (this.isMobi && this.module.prop0 === 0 && mobiPattern.prlt === 1) {
                // 样式一支持单独设置手机图片个数
                style['width'] = `${100 / mobiPattern.prl}%`;
            }

            let pattern = this.model;

            if (pattern.ppmt === 1) {
                //自定义图片间距
                style['padding-left'] = `${parseInt(pattern.ppmh / 2)}px`;
                style['padding-right'] = `${parseInt(pattern.ppmh / 2)}px`;
                style['padding-top'] = `${parseInt(pattern.ppmv / 2)}px`;
                style['padding-bottom'] = `${parseInt(pattern.ppmv / 2)}px`;
            }
            return style;
        },
        photoWrapStyle() {
            const style = {};
            if (this.model.ppht === 1) {
                //自定义图片高度
                style['padding-bottom'] = `${this.model.pph * 100}%`;
            } else if (this.isMobi && this.model.ppht == 0) {
                style['padding-bottom'] = `${this.pcPrivatePattern.pph * 100}%`;
            }
            return style;
        },
        photoStyle() {
            const style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }

            return style;
        },
        linkTarget(isBlank) {
            return linkTarget(isBlank);
        },
        linkNofollow(nofollow) {
            return linkNofollow(nofollow);
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
