import { getOneSiteForm } from '@/api/form/index.js';
import { PopupWin } from '@/site/shared/popupWin/index.js';
import { getSiteFormList } from '@shared/manage/onlineForm/index.js';
import OnlineForm from '@/components/modules/onlineForm/index.vue';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

function getSiteForm(id) {
    return (getSiteForm.promise = new Promise(function (resolve, reject) {
        if (typeof Fai.top.linkFormList == 'undefined') {
            Fai.top.linkFormList = {};
        }
        if (Fai.top.linkFormList[id]) {
            resolve(Fai.top.linkFormList[id]);
            return;
        }
        getOneSiteForm({
            formId: id,
            ram: Math.random(),
        })
            .then((result) => {
                var info = result.info;
                Fai.top.linkFormList[id] = info;
                resolve(info);
            })
            .catch((err) => {
                reject(err);
            });
    }));
}

export const openSiteForm = function (id) {
    let opts,
        module = {},
        options = {},
        formDataList;
    module.prop0 = 0;
    module.prop2 = id;
    module.prop3 = {
        url: '',
    };
    module.isLinkToForm = true;
    module.privatePattern = {
        pc: {
            itt: {
                y: 0,
            },
        },
    };
    getSiteForm(id).then(async (formInfo) => {
        window._store.state.manageMode && (await getSiteFormList());
        formDataList = Fai.top.linkFormList[id];
        formDataList.formData = formInfo;

        if (Comm.getDevice() === 'pc') {
            formInfo.prop1 = formInfo.buttonName;
        } else {
            formInfo.prop1 = LS.siteFormSubmit;
        }
        $.extend(formDataList, module);
        opts = {
            width: 900,
            height: 588,
            component: OnlineForm,
            module: formDataList,
            props: {
                module: formDataList,
            },
            options,
        };

        if (!formDataList.other.p.n) {
            formDataList.other.p.n = LS.popupFormSubmit;
        }

        formDataList.prop1 = formDataList.buttonName;
        formDataList.prop3.url = formDataList.other.p.lu;
        const popupWinVm = PopupWin(opts);

        if (!Fai.top.formDataList) {
            Fai.top.formDataList = new Map();
            Fai.top.popupWinVmList = new Map();
            Fai.top.popupWinVmList.set(formDataList.id, popupWinVm);
            Fai.top.formDataList.set(formDataList.id, formDataList);
        } else {
            Fai.top.popupWinVmList.set(formDataList.id, popupWinVm);

            if (!Fai.top.formDataList.has(formDataList.id)) {
                Fai.top.formDataList.set(formDataList.id, formDataList);
            }
        }
    });
};
