var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    directives: [
      {
        name: "hovermenu",
        rawName: "v-hovermenu",
        value: {
          hovermenu: _vm.hovermenu,
          args: {
            moduleId: _vm.module.id,
            itemType: "image",
            itemId: _vm.id,
          },
        },
        expression:
          "{\n        hovermenu,\n        args: {\n            moduleId: module.id,\n            itemType: 'image',\n            itemId: id,\n        },\n    }",
      },
      {
        name: "lazyload2",
        rawName: "v-lazyload2",
        value: {
          id: _vm.module.id,
          src: _vm.src,
        },
        expression: "{\n        id: module.id,\n        src: src,\n    }",
      },
    ],
    staticClass: "timeline_item_image",
    class: ["timeline_item_image_" + _vm.id],
    attrs: {
      target: _vm.link.ib ? "_blank" : null,
      rel: _vm.link.nf ? "nofollow" : null,
      href: _vm.link.url || null,
    },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }