//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { warningMessage } from '@/site/shared/dialog/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import {
    logoutMember,
    confirmPwd,
    closeContentPanel,
    confirmProfile,
    initFormList,
    changeSubmitStatus,
    initMemberHeadImg,
    initFileUpload,
} from './index.js';
import { Message } from '@shared/manage/componMessage/index.js';

export default {
    name: 'ModuleMemberCenter',
    components: {},
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        id: Number,
    },
    data() {
        return {
            getLoading: false,
            currentPage: 1,
            noticeActive: false,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'isDemo', 'device']),
        data() {
            return this.module.data || {};
        },
        pageCount() {
            //待增
            return this.data.pageCount;
        },
        LS() {
            return this.$store.state.LS;
        },
        noticeActiveClass() {
            return {
                m_member_center_notice_panel: this.noticeActive,
            };
        },
        isPc() {
            return this.device === 'pc';
        },
    },
    mounted() {
        const moduleId = this.id;
        const { headPic, isDefaultImg, displayList } = this.data;

        initMemberHeadImg({ headPic, moduleId, isDefaultImg });
        initFormList(true);

        setHrefEventHasReqArgs($('.m_member_center_notice'));
        initFileUpload(displayList, moduleId);
    },
    methods: {
        logoutClick() {
            if (this.manageMode) {
                Message.warning('当前为管理状态，退出无效。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                logoutMember();
            }
        },
        confirmPwdClick() {
            if (this.manageMode) {
                Message.warning('当前为管理状态，修改无效。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                confirmPwd({
                    moduleId: this.id,
                    sessionMid: this.data.sessionMid,
                });
            }
        },
        contentBackClick() {
            $('.jz_nav_menu').show();
            closeContentPanel(this.id);
        },
        noticBackClick() {
            if (this.isPc) {
                return;
            }
            this.noticeActive = false;
        },
        noticeClick() {
            if (this.isPc) {
                return;
            }
            this.noticeActive = true;
            $('#module' + this.id)
                .find('.J_memberCenterNoticePanel')
                .off('mousemove.stop')
                .on('mousemove.stop', (e) => e.stopPropagation());
        },
        confirmProfileClick() {
            let $module = $('#module' + this.id);
            if (this.manageMode) {
                Message.warning('当前为管理状态，修改无效。');
            } else if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行注册。', true);
            } else {
                if ($module.find('.J_memberCenterConfirmProfile').attr('data-readonly') == 'true') {
                    $module
                        .find('.J_memberCenterConfirmProfile')
                        .attr('data-readonly', 'false')
                        .text(LS.memberProfileResetPwd);
                    $module.find('.J_userControllItem').each((i, item) => {
                        if ($(item).attr('data-disabled') == 1) {
                            $(item).prop('readonly', false);
                            $(item).prop('disabled', false);
                        }
                    });
                    $module.find('.m_member_center_acctName').prop('readonly', false);
                    $module.find('.member_addfile_btn-notallow').removeClass('member_addfile_btn-notallow');
                } else {
                    confirmProfile({
                        moduleId: this.id,
                        sessionMid: this.data.sessionMid,
                    });
                }
            }
        },
        tabItemClick(item) {
            ($(window).width() > 768 ? changeTabs : openContentPanel)({
                moduleId: this.id,
                target: item,
            });
            if ($(window).width() > 768) {
                return;
            }
            $('#module' + this.id)
                .find('.J_memberCenterMain')
                .off('mousemove.stop')
                .on('mousemove.stop', (e) => e.stopPropagation());
        },
        getFileOption(tmpProp) {
            return tmpProp.fileOption;
        },
        getValues(tmpProp) {
            return tmpProp.values || '';
        },
        stopWatchDataInManageMode() {
            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
        },
        paginationCilck(page) {
            //checkme-coyife
            Site.ajaxFormClick(event, this.id);

            if (this.getLoading) return;
            this.getLoading = true;
            this.getMemberCenterFormList(page, 10)
                .then((res) => {
                    this.getLoading = false;
                    if (res.success) {
                        this.stopWatchDataInManageMode();
                        const newList = res.list;
                        if (this.paginationStyleIndex == 2) {
                            // 如果是加载更多样式，则需要插入数组
                            this.newsList = this.newsList.concat(newList);
                        } else {
                            this.newsList = newList;
                        }
                        this.module.totalSize = res.total;
                    } else {
                        console.error(res.msg);
                    }
                })
                .catch(() => {
                    this.getLoading = false;
                });
        },
        getFileUpInfo(tmpProp) {
            return JSON.parse(tmpProp.pc);
        },
        getItemClass(tmpProp) {
            const classes = [];
            const { pt, fileUpInfo, previewLinkInfo = {} } = tmpProp;
            if (pt === 2) {
                classes.push('m_member_center_item-file');
                const { fileId } = previewLinkInfo;
                if (fileId) {
                    if (fileUpInfo.mc === 0) {
                        classes.push('m_member_center_item_notauth');
                    } else if (fileUpInfo.mc === 1) {
                        classes.push('m_member_center_item-active');
                    }
                }
            }
            return classes;
        },
    },
};

/**
 * 切换内容
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {String} foo.target 目标内容的id
 */
function changeTabs({ moduleId = 0, target = '' }) {
    if ($('#' + target).length > 0) {
        $('#module' + moduleId)
            .find('.J_memebrCenterTabsItem')
            .removeClass('m_member_center_tabs_item_active jz_theme_font_color');
        $('#module' + moduleId)
            .find(`.J_memebrCenterTabsItem[data-target=${target}]`)
            .addClass('m_member_center_tabs_item_active jz_theme_font_color');
        $('#module' + moduleId)
            .find('.J_memberCenterMain')
            .removeClass('m_member_center_main_active');
        $('#' + target).addClass('m_member_center_main_active');
    }
    if (target == 'memberCenterForms') {
        changeSubmitStatus();
    }
}

/**
 * 打开内容面板
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {String} foo.target 目标内容的id
 */
function openContentPanel({ moduleId = 0, target = '' }) {
    $('#module' + moduleId)
        .find('.J_memberCenterMain')
        .removeClass('m_member_center_main_panel');
    $('#' + target).addClass('m_member_center_main_panel');
    $('.jz_nav_menu').hide();
    if (target == 'memberCenterForms') {
        initFormList(false);
        changeSubmitStatus();
    }
}
