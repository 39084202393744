import { setQueryString, Comm } from '../../../../../utils';
const { createUrlArgsOnclickStr, bindFlag, getBitMemory } = Comm;

export const linkMixin = {
    computed: {
        ...Vuex.mapGetters(['hashRemoved']),
        flagIsBlank: bindFlag(0x1),
        target() {
            return this.flagIsBlank ? '_self' : '_blank';
        },
    },
    methods: {
        onclickStr(item) {
            const { ndReqArgs } = item;
            return createUrlArgsOnclickStr(ndReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        setQueryString(url, key, val, flag = 0) {
            //外链直接返回url，不用加上fromColId
            if (getBitMemory(flag, 0x80)) {
                return url;
            }
            return setQueryString(url, key, val);
        },
    },
};
