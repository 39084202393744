import { getComputedBg } from '@shared/manage/utils/index.js';

export const isDef = (n) => {
    return typeof n !== 'undefined';
};

export const getBorderCss = (border, options = {}) => {
    const { borderDefaultStyle = 'solid' } = options;
    let result = '';
    if (border && border.y === 0) {
        return result;
    }

    if (border.y === 1) {
        return 'border: none;';
    }

    if (isDef(border.w)) {
        result += `border-width: ${border.w}px;`;
    }

    if (border.c) {
        result += `border-color: ${border.c};`;
    }

    if (border.t === false) {
        result += 'border-top-style: none;';
    } else {
        if (borderDefaultStyle) {
            result += `border-top-style: ${borderDefaultStyle};`;
        }
    }
    if (border.r === false) {
        result += 'border-right-style: none;';
    } else {
        if (borderDefaultStyle) {
            result += `border-right-style: ${borderDefaultStyle};`;
        }
    }
    if (border.b === false) {
        result += 'border-bottom-style: none;';
    } else {
        if (borderDefaultStyle) {
            result += `border-bottom-style: ${borderDefaultStyle};`;
        }
    }
    if (border.l === false) {
        result += 'border-left-style: none;';
    } else {
        if (borderDefaultStyle) {
            result += `border-left-style: ${borderDefaultStyle};`;
        }
    }

    return result;
};

export const getBackgroundCss = (bg) => {
    let css = '';
    const bgStyle = getComputedBg(bg);
    if (bgStyle.background) {
        css += `background: ${bgStyle.background};`;
    }
    if (bgStyle.backgroundImage) {
        css += `background-image: ${bgStyle.backgroundImage};`;
    }
    if (bgStyle.backgroundSize) {
        css += `background-size: ${bgStyle.backgroundSize};`;
    }
    if (bgStyle.backgroundColor) {
        css += `background-color: ${bgStyle.backgroundColor};`;
    }
    if (bgStyle.backgroundRepeat) {
        css += `background-repeat: ${bgStyle.backgroundRepeat};`;
    }
    if (bgStyle.backgroundPosition) {
        css += `background-position: ${bgStyle.backgroundPosition};`;
    }
    return css;
};

export const getFontColorCss = (color, options = {}) => {
    let result = '';
    if (isDef(color)) {
        if (color.includes('linear-gradient')) {
            result += `background-image: ${color};`;
            result += `color: transparent;`;
            result += `-webkit-text-fill-color: transparent;`;
            result += `-webkit-background-clip: text;`;
        } else {
            result += `${options.isBackgroundColor ? 'background-color' : 'color'}: ${color};`;
        }
    }
    return result;
};

const getTextAlign = (ta) => {
    return (
        {
            0: 'left',
            1: 'center',
            2: 'right',
            3: 'justify',
        }[ta] || ''
    );
};

export const getFontTextCss = (font, options = {}) => {
    if (!font || font.y !== 1) {
        return '';
    }
    let result = '';
    if (isDef(font.s)) {
        result += `font-size: ${font.s}px;`;
    }
    if (isDef(font.f)) {
        result += `font-family: ${font.f};`;
    }
    if (isDef(font.i)) {
        result += `font-style: ${font.i === 1 ? 'italic' : 'normal'};`;
    }
    if (isDef(font.w)) {
        result += `font-weight: ${font.w === 1 ? 'bold' : 'normal'};`;
    }
    if (isDef(font.d)) {
        result += `text-decoration: ${font.d === 1 ? 'underline' : 'none'};`;
    }

    if (!options.filterColor) {
        result += getFontColorCss(font.c);
    }

    const alignKey = options.alignKey || 'ta';
    if (options.needTextAlign && isDef(font[alignKey]) && getTextAlign(font[alignKey])) {
        result += `text-align: ${getTextAlign(font[alignKey])};`;
    }
    return result;
};

export function getFontCss(data) {
    const {
        pcFont,
        mobiFont,
        className,
        isTextNotExtendFontSize = true,
        needTextAlign = false,
        alignKey = 'ta',
    } = data;
    const fontTextOptions = { needTextAlign, alignKey };
    let result = '';
    if (pcFont.y === 1) {
        result += `.jz_screen_pc ${className} { ${getFontTextCss(pcFont, fontTextOptions)} }`;
    }
    if (mobiFont.y === 1) {
        result += `.jz_screen_mobi ${className} { ${getFontTextCss(mobiFont, fontTextOptions)} }`;
    } else if (mobiFont.y === 0 && pcFont.y === 1) {
        const mobiFontFilterFontSize = Object.assign({}, pcFont);
        if (isTextNotExtendFontSize) {
            delete mobiFontFilterFontSize.s;
        }
        result += `.jz_screen_mobi ${className} { ${getFontTextCss(mobiFontFilterFontSize, fontTextOptions)} }`;
    }
    return result;
}

export const getBgCss = (data) => {
    const { pcBg, mobiBg, className } = data;
    let result = '';
    if (pcBg.y !== 0) {
        result += `${className} { ${getBackgroundCss(pcBg)} }`;
    }
    if (mobiBg.y !== 0) {
        result += `.jz_screen_mobi ${className} { ${getBackgroundCss(mobiBg)} }`;
    }
    return result;
};

export const getBorderCssWithDevice = (data) => {
    const { pcBorder, mobiBorder, className } = data;
    let result = '';
    if (pcBorder.y !== 0) {
        result += `${className} { ${getBorderCss(pcBorder)} }`;
    }
    if (mobiBorder.y !== 0) {
        result += `.jz_screen_mobi ${className} { ${getBorderCss(mobiBorder)} }`;
    }
    return result;
};

export const getWrapCss = ({ pcPattern = {}, mobiPattern = {}, className }) => {
    const getCss = (pattern, isMobi) => {
        let { y: type = 0, l: line = 1 } = pattern;
        let css = '';
        if (type === 0) {
            // 默认
            return css;
        } else {
            if (type === 2) {
                // 不换行
                line = 1;
            }
            css += `${
                isMobi ? '.jz_screen_mobi' : ''
            } ${className} { display: -webkit-box; overflow: hidden; -webkit-line-clamp: ${line}; -webkit-box-orient: vertical; white-space:normal; word-break: break-word; max-height: none; }`;
        }
        return css;
    };
    return `${getCss(pcPattern)} ${getCss(mobiPattern, true)}`;
};

export const getPictureScaleCss = (t) => {
    return (
        {
            1: 'background-size: cover;',
            2: 'background-size: auto',
            3: 'background-size: contain;',
        }[t] || ''
    );
};
