//
//
//
//
//
//
//
//
//

import pcView from './components/pc-view.vue';
import mobiView from './components/mobi-view.vue';
import emptyView from './components/empty-view.vue';
import { logJZFdp } from '@/site/shared/log';

export default {
    name: 'PhotoGroupDetail',
    style: 36,
    components: {
        pcView,
        mobiView,
        emptyView,
    },
    props: ['id', 'module'],
    data() {
        return {
            photoGroupInfo: this.module.photoGroupInfo || {},
        };
    },
    computed: {
        ...Vuex.mapState(['device']),
        emptyPhotoGroupInfo() {
            return Object.keys(this.photoGroupInfo).length === 0 || this.photoGroupInfo.id == null;
        },
        pattern() {
            return this.module.pattern || {};
        },
        pc() {
            return this.device === 'pc';
        },
    },
    mounted() {
        this.logVisitorPhotoGroup();
    },
    methods: {
        logVisitorPhotoGroup() {
            logJZFdp('jz_album_pages_visit', {
                jz_content_terminal: '自适应',
            });
        },
    },
};
