//
//
//
//
//
//
//

import { genCommFontStyleForText } from './utils.js';

export default {
    props: ['item', 'module'],
    computed: {
        ...Vuex.mapState(['manageMode']),
        titleTextStyle() {
            if (!this.manageMode || !!this.module.isLinkToForm) {
                return;
            }
            return genCommFontStyleForText(this.module.privatePattern.pc.itt);
        },
    },
};
