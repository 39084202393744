//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {
    components: {},
};
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import { initNav, fixSubMenuPostion } from './index.js';
import NavItem from './navItem.vue';
import { Comm } from '../../utils';
const { getComputedBg, extend, getComputedTextColor, mergeWithRule } = Comm;
import { addClass, addClasses, verifyCusLinkType } from './utils.js';
import { triggerFullNav, initFullNav, updateFullNavData } from './fullNav';
import { canAddFixTopClass, FIX_TOP_TEMP_CLASS } from '@/site/shared/fixTopStyle/index.js';

import { ScrollAnchorService, ServiceFactory } from '@/services';

const NAV_ITEM_SELECTOR = '#gridHeader .jz_nav_item';
function initNavData() {
    var nav = window._store.state.responsiveInfo.pattern.nav;
    if (nav.hide) {
        return;
    }
}

let preTouchCrossObj = {
    isSaveScroll: false,
    scrollTop: 0,
};

export default {
    name: 'Nav',
    components: {
        NavItem,
        ...manageUse.components,
    },
    props: ['isMobi', 'rowid', 'module'],
    data() {
        return {
            selectedColId: -1,
            centerLogoIndex: -1,
            partitionLines: ['|', '/'],
            logo: [{ navItemFirst: false, logo: true }],
            loading: true,
            logoHoverd: false,
            mergedNav: {}, // nav 和 cloneNav合并后的
            nextArrowVisible: false,
            movedNavItemCountList: [],
            updating: false,
            mountedNav: false,
            arrow: {
                color: '',
                selectedColor: '',
            },
            isPending: false,
            navInstance: null,
            preHeight: null,
        };
    },
    computed: {
        ...Vuex.mapState('manage/nav', ['isShowFullNav']),
        ...Vuex.mapState(['device', 'manageMode', 'resRoot', 'colId', 'sections', 'extId', 'fullScreen']),
        ...Vuex.mapGetters(['info']),
        responsiveInfo() {
            return this.info;
        },
        responsiveSections() {
            return this.sections; //this.manageMode ? this.sections : this.module.sections;
        },
        navPattern() {
            return this.info.pattern.nav;
        },
        navHide() {
            let pattern = this.info.pattern;
            if (
                pattern.independent &&
                pattern.independent.hideNav &&
                pattern.independent.open &&
                pattern.independent.self
            ) {
                return !pattern.independent.hideNav;
            }
            return !this.navPattern.hide;
        },
        colParititonLineStyle() {
            if (!this.isPc) {
                return {};
            }
            const colStyle = {};
            const height = this.nav.cwht === 0 ? 40 : this.nav.ch;

            this.nav.ch !== undefined && (colStyle.height = height + 'px') && (colStyle.lineHeight = height + 'px');
            return colStyle;
        },
        isPc() {
            return this.device === 'pc';
        },
        navLogo() {
            if (this.$isServer) {
                return this.nav.slp;
            }
            return this.nav.slp ? this.nav.slp : this.resRoot + '/image/rimage/manage/logo.jpg';
        },
        navLogoStyle() {
            var logoStyle = {};
            if (this.nav.cwht == 1) {
                this.nav.ch != undefined && (logoStyle.height = this.nav.ch + 'px');
            }
            return logoStyle;
        },
        navListStyle() {
            if (this.nextArrowVisible || this.prevArrowVisible) {
                return {
                    textAlign: 'left',
                };
            }
            var navListStyle = {};
            if (typeof this.nav.nca !== 'undefined' && this.nav.nca !== -1) {
                navListStyle.textAlign = {
                    0: 'left',
                    1: 'center',
                    2: 'right',
                }[this.nav.nca];
            }
            return navListStyle;
        },
        visibleColList() {
            let list = [];

            if (this.module && this.module.navColList) {
                list = this.module.navColList;
            } else {
                list = this.responsiveSections ? this.responsiveSections.navColList : [];
            }

            return list.filter((item) => {
                return (item.parentId || 0) == 0 && !item.nav_hidden;
            });
        },
        navColList() {
            if (this.isPc && this.nav.st === 1) {
                return this.navColListWithLogo;
            }
            return this.visibleColList.slice(this.movedCount);
        },
        navColListWithLogo() {
            var centerIndex = this.centerLogoIndex;
            var leftList = this.visibleColList.slice(this.movedCount).slice(0, centerIndex);
            var rightList = this.visibleColList.slice(this.movedCount).slice(centerIndex);
            return leftList.concat(this.logo).concat(rightList);
        },
        hasLogo() {
            return this.nav.st === 1;
        },
        nav() {
            if (this.module && this.module.pattern) {
                // ssr
                return this.module.pattern.nav;
            }
            if (this.manageMode && this.responsiveInfo.pattern.nav.s === -1) {
                // if (this.info.pattern.nav.s === -1) {
                return this.mergedNav;
            }

            return this.responsiveInfo.pattern.nav;
        },
        cloneNav() {
            if (this.module && this.module.pattern) {
                // ssr
                return this.module.pattern.cloneNav;
            }

            return this.responsiveInfo.pattern.cloneNav;
        },
        ncc() {
            return this.nav.ncc;
        },
        getNavStyle() {
            const fullNavStyle = {
                display: 'flex',
                'justify-content': 'space-between',
                'align-items': 'center',
            };

            const isUseFullNavStyle = this.isPc && this.isFullScreenNav;

            if (!this.manageMode) {
                if (isUseFullNavStyle) {
                    return fullNavStyle;
                } else {
                    return {};
                }
            }

            var navStyle = {},
                navMobiStyle = {},
                bGstyle = {};

            if (this.nav.wht == 1) {
                // 宽高
                this.nav.w != undefined && (navStyle.width = this.nav.w + 'px');
                this.nav.h != undefined && (navStyle.height = this.nav.h + 'px');
            }

            if (this.nav.pt == 1) {
                // 位置
                this.nav.t != undefined && (navStyle.top = this.nav.t + 'px');
                this.nav.l != undefined && (navStyle.left = this.nav.l + 'px');
            }

            if (this.nav.b && this.nav.b.y == 1) {
                // 边框
                this.nav.b.w != undefined && (navStyle.borderWidth = this.nav.b.w + 'px');
                !!this.nav.b.c && (navStyle.borderColor = this.nav.b.c);
                !this.nav.b.t && (navStyle.borderTopStyle = 'none');
                !this.nav.b.b && (navStyle.borderBottomStyle = 'none');
                !this.nav.b.l && (navStyle.borderLeftStyle = 'none');
                !this.nav.b.r && (navStyle.borderRightStyle = 'none');
            }

            if (this.nav.brt == 1) {
                // 圆角
                this.nav.brv != undefined && (navStyle.borderRadius = this.nav.brv + 'px');
            }

            if (this.nav.ost >= 1) {
                // 外阴影
                navStyle.boxShadow = this.nav.ost == 1 ? '0 2px 6px 0 rgba(0,0,0,.1)' : 'none';
            }

            if (this.nav.ncpt == 1) {
                // 宽高
                this.nav.ncpp != undefined && (navStyle.paddingTop = this.nav.ncpp + 'px');
                this.nav.ncpl != undefined && (navStyle.paddingLeft = this.nav.ncpl + 'px');
                this.nav.ncpr != undefined && (navStyle.paddingRight = this.nav.ncpr + 'px');
                this.nav.ncpb != undefined && (navStyle.paddingBottom = this.nav.ncpb + 'px');
            }

            bGstyle = getComputedBg(this.nav.bg);
            extend(navStyle, bGstyle);

            if (isUseFullNavStyle) {
                Object.assign(navStyle, fullNavStyle);
            }

            return !this.isPc ? navMobiStyle : navStyle;
        },
        isShowNavStyleCss() {
            // 是否显示选择的导航样式
            return this.isPc && this.nav.s !== -1;
        },
        prevArrowVisible() {
            return this.movedNavItemCountList.length > 0;
        },
        movedCount() {
            return this.movedNavItemCountList.reduce((acc, count) => {
                acc += count;
                return acc;
            }, 0);
        },
        mobiIconColor() {
            if (this.$isServer) {
                return '';
            }
            if (this.responsiveInfo.pattern.header.mobiIconType) {
                return this.responsiveInfo.pattern.header.mobiIconColor || '';
            }
            return '';
        },
        isHideFirstCol() {
            return this.isPc && this.nav.ihnn && this.isFullScreenNav;
        },
        isFullScreenNav() {
            return this.nav.type === 1;
        },
        fullNavIconType() {
            return this.nav.icon;
        },
        // eslint-disable-next-line vue/return-in-computed-property
        jzFullNavMenuItemStyle() {
            if (this.fullNavIconType === 0) {
                return {
                    'background-color': '#333',
                };
            } else if (this.fullNavIconType === 3) {
                return {
                    'background-color': '#fff',
                };
            }
        },
        // eslint-disable-next-line vue/return-in-computed-property
        jzFullNavMenuStyle() {
            let style = {};

            if (!this.isHideFirstCol) {
                style['margin-left'] = '16px';
            }

            if (this.fullNavIconType === 2) {
                Object.assign(style, {
                    'background-color': '#fff',
                    'border-radius': '50%',
                    'box-shadow': '0 0px 6px rgb(0 0 0 / 10%)',
                });
            } else if (this.fullNavIconType === 3) {
                Object.assign(style, {
                    'background-color': ' #4D4D4D',
                    'border-radius': '2px',
                });
            }

            return style;
        },
        fullNavExpendType() {
            return this.nav.ed;
        },
        isShowScroll() {
            return this.nav.iss;
        },
        isApplyFullNavToMobi() {
            return this.nav.ispn && this.isFullScreenNav;
        },
        innerFeatures() {
            if (!this.navInstance) {
                return [];
            }
            let innerFeatures = this.navInstance.getFeatures();
            return innerFeatures;
        },
    },
    watch: {
        ['nav.type']() {
            Vue.nextTick(this.hideAllFreeLayout);
        },
        'ncc.t': function () {
            Vue.nextTick(fixSubMenuPostion);
            Vue.nextTick(this.hideAllFreeLayout);
        },
        'nav.hide': function () {
            Vue.nextTick(initNavData);
            Vue.nextTick(this.hideAllFreeLayout);
        },
        'nav.cw': function () {
            this.withNavTimer(this.updateNavViewArea);
        },
        'nav.cwht': function () {
            this.withNavTimer(this.updateNavViewArea);
        },
        'nav.st': function () {
            this.withNavTimer(this.updateNavViewArea);
            Vue.nextTick(this.hideAllFreeLayout);
        },
        'nav.s': function () {
            this.withNavTimer(this.updateNavViewArea);
            Vue.nextTick(this.hideAllFreeLayout);
        },
        'responsiveInfo.pattern.nav': {
            deep: true,
            handler() {
                if (this.manageMode) {
                    this.mergeNavAndCloneNav();
                }
            },
        },
        fullNavIconType() {
            updateFullNavData(this.fullNavIconType, this.fullNavExpendType, this.isShowScroll);
        },
        fullNavExpendType() {
            updateFullNavData(this.fullNavIconType, this.fullNavExpendType, this.isShowScroll);
        },
        isShowScroll() {
            updateFullNavData(this.fullNavIconType, this.fullNavExpendType, this.isShowScroll);
        },
        isPc: {
            handler() {
                if (!this.$isServer) {
                    if (document.readyState === 'complete') {
                        updateFullNavData(this.fullNavIconType, this.fullNavExpendType, this.isShowScroll);
                        triggerFullNav(false);
                    }
                    this.exitEditFreeLayoutMode();
                }
            },
            immediate: true,
        },
        'nav.cf.y': function () {
            this.useArrowColor();
        },
        'nav.cf.c': function (val) {
            this.arrow.color = val;
            this.useArrowColor();
        },
        'nav.cf.sc': function (val) {
            this.arrow.selectedColor = val;
            this.useArrowColor();
        },
    },
    created() {
        if (this.manageMode) {
            this.mergeNavAndCloneNav();
        }
        if (this.module && this.module._colId) {
            this.selectedColId = this.module._colId;
        }
        this.updateCenterLogoIndex();
        this.initNavItemShowFreeLayoutKey();
    },
    mounted() {
        if (this.manageMode) {
            this.initNavManageInstance();
        }

        this.selectedColId = this.colId;

        if (verifyCusLinkType() != -1) {
            this.selectedColId = verifyCusLinkType();
        }
        this.initNavView();
        this.addResponsiveEvent();

        initFullNav(
            this.fullNavIconType,
            this.fullNavExpendType,
            this.isShowScroll,
            this.isInitFullNavRowsAndModules()
        );

        if (this.manageMode) {
            this.useArrowColor();
            this.watchIsShowFullNav();
        }
        if (this.ncc.t == 2) {
            window.addEventListener('resize', this.exitEditFreeLayoutMode);
        }

        if (this.isPc) {
            this.initScrollAnchor();
        }
    },
    updated() {
        this.$nextTick(() => {
            if (canAddFixTopClass('nav')) {
                $('.jz_nav_wrap').addClass(FIX_TOP_TEMP_CLASS);
            }
        });
    },
    methods: {
        initScrollAnchor() {
            const navItems = this.getNavItems();
            const anchors = navItems
                .filter((item) => ScrollAnchorService.isAnchorHref(item?.url ?? ''))
                .map((item) => {
                    const { rowId, colId, moduleId } = ScrollAnchorService.parseAnchorHref(item.url);
                    return ScrollAnchorService.createAnchor({
                        rowId,
                        colId,
                        moduleId,
                    });
                });

            const ScrollAnchor = ServiceFactory.get('ScrollAnchor', {
                params: [anchors],
            });
            if (anchors.length > 0) {
                if (this.fullScreen) {
                    ScrollAnchor.listenInFullScreen();
                } else {
                    ScrollAnchor.listen();
                }
            }
        },
        getNavItems() {
            let navItems = [];

            const getChildren = (item = {}) => {
                navItems.push(item);
                if (Array.isArray(item.colList2)) {
                    item.colList2.forEach((subItem) => getChildren(subItem));
                }
                if (Array.isArray(item.colList3)) {
                    item.colList3.forEach((thirdItem) => getChildren(thirdItem));
                }
            };

            this.navColList.forEach(getChildren);

            return navItems;
        },
        hideAllFreeLayout() {
            _voidVm.$emit('hide_freelayout');
        },
        initNavItemShowFreeLayoutKey() {
            let list = [];
            if (this.module && this.module.navColList) {
                list = this.module.navColList;
            } else {
                list = this.responsiveSections ? this.responsiveSections.navColList : [];
            }
            list.forEach((item) => {
                if (typeof item.showFreeLayout != 'boolean') {
                    this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                    Vue.set(item, 'showFreeLayout', false);
                }
            });
        },
        exitEditFreeLayoutMode() {
            this.manageMode && _voidVm.$emit('exit_editFreeLayout');
        },
        initNavManageInstance() {
            const info = this.$store.state.responsiveInfo;
            let navInstance = new NavManage({
                pattern: info.pattern.nav,
            });
            navInstance.registerFeatures();
            this.navInstance = navInstance;
            this.$watch(
                () => this.device,
                () => this.setFeatureData(),
                {
                    immediate: true,
                }
            );
        },
        setFeatureData() {
            const navInstance = this.navInstance;
            let innerFeatures = navInstance.getFeatures();
            for (let i in innerFeatures) {
                if (navInstance[`set${innerFeatures[i].name}`]) {
                    navInstance[`set${innerFeatures[i].name}`](this, this.device);
                }
            }
        },
        watchIsShowFullNav() {
            this.$watch(
                () => this.isShowFullNav,
                () => {
                    this.fetchFullNav().then(() => {
                        this.toggleManageModeFullNavShow();
                    });
                }
            );
        },
        isLinearGradientColor(color) {
            if (!color) {
                return false;
            }
            return color.indexOf('linear-gradient') !== -1;
        },
        getLinearGradientFirstColor(color) {
            if (this.isLinearGradientColor(color)) {
                return color.substring(color.indexOf('rgb'), color.indexOf(')') + 1);
            } else {
                return color;
            }
        },
        useArrowColor() {
            const isDefaultColor = this.nav.cf.y !== 1;
            // 若初始color或选中color有一个是渐变色才用css变量方案, 若都是纯色, 则用currentColor
            const useCssVariable =
                this.isLinearGradientColor(this.nav.cf.c) || this.isLinearGradientColor(this.nav.cf.sc);
            // 如果是渐变色获取第一个颜色
            this.arrow.color = this.getLinearGradientFirstColor(this.nav.cf.c);
            this.arrow.selectedColor = this.getLinearGradientFirstColor(this.nav.cf.sc);

            let navRef = this.$refs['nav'];
            if (!navRef) {
                return;
            }
            if (!isDefaultColor && useCssVariable) {
                navRef.style.setProperty('--arrow-color', this.arrow.color);
                navRef.style.setProperty('--arrow-selected-color', this.arrow.selectedColor);
            } else {
                navRef.style.setProperty('--arrow-color', 'currentColor');
                navRef.style.setProperty('--arrow-selected-color', 'currentColor');
            }
        },
        withNavTimer(cb) {
            return setTimeout(cb.bind(this), 300); // 导航有个过渡动画, 时间为0.3s
        },
        createSymbol(id) {
            return Symbol(id);
        },
        resizeNav() {
            this.movedNavItemCountList = [];

            this.updateArrowVisible();
            const count = this.getVisibleNavItemCount();

            if (this.nextArrowVisible && count <= 2) {
                return;
            }
            this.updateCenterLogoIndex();
        },
        updateCenterLogoIndex() {
            if (this.updating) {
                return;
            }

            if (!this.isPc) {
                return;
            }

            if (this.$isServer) {
                this.centerLogoIndex = parseInt(Math.round((this.navColList.length - 1) / 2), 10);
                return;
            }

            if (this.isPc && this.nav.st !== 1 && this.nav.cst !== 1) {
                return;
            }

            const count = this.getVisibleNavItemCount(),
                len = this.nextArrowVisible ? count : this.navColList.length - 1;

            this.updating = true;
            this.centerLogoIndex = parseInt(Math.round(len / 2), 10);
            this.validateNavLogoIndex();
            this.$nextTick(() => {
                this.updating = false;
                this.mountedNav = true;
            });
        },
        validateNavLogoIndex() {
            setTimeout(() => {
                const count = this.getVisibleNavItemCount(),
                    len = this.nextArrowVisible ? count : this.navColList.length - 1;

                const idx = parseInt(Math.round(len / 2), 10);
                if (idx !== this.centerLogoIndex) {
                    this.updateCenterLogoIndex();
                }
            }, 200);
        },
        isLogoIndex(index) {
            if (!this.hasLogo) {
                return false;
            }
            return index === this.centerLogoIndex || index === this.centerLogoIndex + 1;
        },
        convertPartitionLineClass(style = 0) {
            var partitionLineVisible = this.nav.cplt === 2 && this.nav.cpls === style;
            return ['jz_nav_partition_line_style' + style, partitionLineVisible && 'jz_nav_partition_line--visible'];
        },
        getPartitionLineStyle(index) {
            if (!this.manageMode) {
                return {};
            }
            var style = {},
                mobiStyle = {};

            switch (this.nav.cplt) {
                case 0:
                    break;
                case 1: {
                    style.display = 'none';
                    break;
                }
                case 2: {
                    style.display = index === this.nav.cpls ? 'block' : 'none';
                    if (this.nav.cplcp && this.nav.cplcp.c) {
                        // 颜色
                        extend(style, getComputedTextColor(this.nav.cplcp.c));
                    }
                    if (this.nav.cf && this.nav.cf.y == 1) {
                        // 字体大小
                        this.nav.cf.s != undefined && (style.fontSize = this.nav.cf.s + 'px');
                    }
                }
            }

            return !this.isPc ? mobiStyle : style;
        },
        spliceClass(_class, list) {
            var id = list.id || list,
                select = [];

            select.push(_class + id);

            if (typeof list === 'undefined') {
                return [];
            }
            if (this.checkChildItemSelect(this.selectedColId, list, _class) && this.isPc) {
                addClasses(select, 'jz_nav_item_selected', 'jz_theme_border_color', 'jz_theme_font_color');
            }
            if (this.hasChildMenu(list.colList3)) {
                addClass(select, 'sub_child_item');
            }

            if (this.isShowNavStyleCss) {
                addClass(select, 'jz_theme_font_color');
            }

            return select;
        },
        checkChildItemSelect(selectedColId, item, _class) {
            var arr,
                isSelect = false;
            if (selectedColId == item.id) {
                return true;
            } else {
                if (_class == 'nav_item' || _class == 'nav_sub_item') {
                    arr = _class == 'nav_item' ? item.colList2 : item.colList3;
                    isSelect = this.checkSelect(arr, selectedColId);
                }
                return isSelect;
            }
        },
        checkSelect(arr, selectedColId) {
            var isSelect = false,
                col3;
            if (!arr) {
                return false;
            }
            arr.forEach(function (item) {
                if (item.id == selectedColId) {
                    isSelect = true;
                    return false;
                } else {
                    col3 = item.colList3;
                    !!col3 &&
                        !!col3.length &&
                        col3.forEach(function (item) {
                            if (item.id == selectedColId) {
                                isSelect = true;
                                return false;
                            }
                        });
                }
            });
            return isSelect;
        },
        hasChildMenu(data) {
            return (
                !!data &&
                data.length > 0 &&
                data.some((i) => {
                    return !i.nav_hidden;
                })
            );
        },
        updateNavViewArea() {
            this.updateArrowVisible();
            this.$nextTick(this.updateCenterLogoIndex);
        },
        selectNavLogo() {
            Fai.fileUpload2(
                false,
                {
                    title: '更换图片',
                    maxSize: 1,
                    type: ['jpg', 'jpeg', 'gif', 'png'],
                    from: 'navLogo',
                    entry: 'navLogo',
                    openIcon: false,
                    maxChoiceList: 1,
                    group: 'simple',
                },
                (result) => {
                    if (result) {
                        const info = $.parseJSON(result).data[0];
                        if (info) {
                            Vue.set(this.nav, 'slp', info.filePath);
                            Vue.set(this.nav, 'slpid', info.fileId);
                            this.info.pattern.nav.slp = info.filePath;
                            this.info.pattern.nav.slpid = info.fileId;
                        }
                    }
                }
            );
        },
        mergeNavAndCloneNav() {
            const rule = {
                st: {
                    // 样式风格
                    handler() {
                        return false;
                    },
                },
                nca: {
                    // 内容区对齐
                    handler(originalVal, targetVal) {
                        return originalVal === -1 && targetVal !== -1;
                    },
                },
                cplcp: {
                    // 分割线
                    handler(originalVal, targetVal, originalObject, targetObject) {
                        return originalObject.cplt === 0 && targetObject.cplt !== 0;
                    },
                },
            };

            try {
                if (typeof this.responsiveInfo.pattern.cloneNav !== 'undefined') {
                    this.mergedNav = mergeWithRule(
                        rule,
                        this.responsiveInfo.pattern.nav,
                        this.responsiveInfo.pattern.cloneNav
                    );
                    const mergedNavNcc = mergeWithRule(
                        {
                            t: {
                                handler(originalVal) {
                                    return typeof originalVal === 'undefined';
                                },
                            },
                        },
                        this.responsiveInfo.pattern.nav.ncc,
                        this.responsiveInfo.pattern.cloneNav.ncc
                    );
                    mergedNavNcc.is = this.responsiveInfo.pattern.nav.ncc.is;
                    mergedNavNcc.islt = this.responsiveInfo.pattern.nav.ncc.islt;
                    mergedNavNcc.islc = this.responsiveInfo.pattern.nav.ncc.islc;
                    this.mergedNav.ncc = mergedNavNcc;

                    Vue.set(this.mergedNav, 'type', this.responsiveInfo.pattern.nav.type);

                    this.mergedNav.ihnn = this.responsiveInfo.pattern.nav.ihnn;
                    this.mergedNav.ispn = this.responsiveInfo.pattern.nav.ispn;
                    this.mergedNav.iss = this.responsiveInfo.pattern.nav.iss;
                } else {
                    this.mergedNav = this.responsiveInfo.pattern.nav;
                }
            } catch (error) {
                return;
            }
        },
        isRightArrowVisible() {
            if (Fai.top.$('#gridHeader .J_headerContent').hasClass('hide_header_content')) {
                // 全屏通栏, 被隐藏顶部了, 返回false
                return false;
            }
            const length = this.navColList.length;
            const logoWidth = Fai.top.$('#gridHeader .m_nav_logo_img').eq(0).width();
            const septalWidth = Fai.top.$('#gridHeader .nav_item_septal').eq(0).outerWidth(true);
            const septalCount = length - (this.hasLogo && logoWidth > 0 ? 3 : 1);
            const navWidth = this.getNavItemWidth() + septalWidth * septalCount;
            const viewAreaWidth = this.getViewAreaWidth();
            return navWidth > viewAreaWidth;
        },
        getNavItemWidth() {
            const logoWidth = this.hasLogo ? Fai.top.$('#gridHeader .m_nav_logo_li').eq(0).width() : 0;
            let navWidth = 0;
            Fai.top.$(NAV_ITEM_SELECTOR).each(function (index, navitem) {
                navWidth += $(navitem).outerWidth(true);
            });
            return navWidth + logoWidth;
        },

        getViewAreaWidth() {
            return Fai.top.$('#gridHeader .jz_pc_nav').eq(0).width();
        },
        updateArrowVisible() {
            this.nextArrowVisible = this.isRightArrowVisible();
        },
        initNavView() {
            initNav();
            if (this.isPc) {
                // 收起mobi的导航栏
                !!$('.nav_drawer_style').length && $('.nav_drawer_style').removeClass('nav_drawer_style');
                !!$('.jz_nav_wrap_active').length && $('.jz_nav_wrap_active').removeClass('jz_nav_wrap_active');
                setTimeout(() => {
                    this.withNavTimer(this.updateNavViewArea);
                }, 500);
            }
        },
        getInVisibleNavItemCount() {
            const $ = Fai.top.$;
            const navEl = document.querySelector('#gridHeader .jz_nav_content');
            if (navEl === null) {
                return;
            }
            const viewAreaPositionRight = navEl.getBoundingClientRect().right;
            let count = 0;
            $.each($(NAV_ITEM_SELECTOR), function (i, currentNavItem) {
                const right = currentNavItem.getBoundingClientRect().right;
                if (right >= viewAreaPositionRight) {
                    count++;
                }
            });
            return count;
        },
        getVisibleNavItemCount() {
            const count = this.getInVisibleNavItemCount();
            const all = this.hasLogo ? this.navColList.length - 1 : this.navColList.length;
            return all - count;
        },
        handleClickNextArrow() {
            this.hideAllFreeLayout();
            this.translateNavItemsToLeft(() => {
                const inVisibleCount = this.getInVisibleNavItemCount();
                const visibleCount = this.getVisibleNavItemCount();
                this.movedNavItemCountList.push(Math.min(inVisibleCount, visibleCount));
                this.updateArrowVisible();
            });
        },
        handleClickPreArrow() {
            this.hideAllFreeLayout();
            this.translateNavItemsToRight(() => {
                this.movedNavItemCountList.pop();
                setTimeout(() => {
                    this.updateArrowVisible();
                }, 200);
            });
        },
        translateNavItems(baseClassName, inClassName, outClassName) {
            return (callback) => {
                const visibleCount = this.getVisibleNavItemCount();
                Fai.top.$(`${NAV_ITEM_SELECTOR}:lt(${visibleCount})`).css({ opacity: 0 }).addClass(baseClassName);
                typeof callback === 'function' &&
                    setTimeout(() => {
                        callback();
                        this.$nextTick(() => {
                            Fai.top.$(`${NAV_ITEM_SELECTOR}`).addClass(inClassName);
                        });
                        setTimeout(() => {
                            Fai.top.$(`${NAV_ITEM_SELECTOR}`).addClass(outClassName);
                            this.removeTranslateNavItemClass();
                        }, 50);
                    }, 200);
            };
        },
        translateNavItemsToRight(callback) {
            return this.translateNavItems(
                'jz_nav_item_translate--right',
                'jz_nav_item_translate--leftIn',
                'jz_nav_item_translate--leftOut'
            )(callback);
        },
        translateNavItemsToLeft(callback) {
            return this.translateNavItems(
                'jz_nav_item_translate--left',
                'jz_nav_item_translate--rightIn',
                'jz_nav_item_translate--rightOut'
            )(callback);
        },
        showNavItems() {
            Fai.top.$(NAV_ITEM_SELECTOR).css({ opacity: 1 });
        },
        hideNavItems() {
            Fai.top.$(NAV_ITEM_SELECTOR).css({ opacity: 0 });
        },
        removeTranslateNavItemClass() {
            const toRemoveClasses = [
                'jz_nav_item_translate--right',
                'jz_nav_item_translate--rightIn',
                'jz_nav_item_translate--rightOut',
                'jz_nav_item_translate--left',
                'jz_nav_item_translate--leftIn',
                'jz_nav_item_translate--leftOut',
                'jz_nav_item_translate--right',
                'jz_nav_item_translate--left',
            ].join(' ');

            Fai.top.$(NAV_ITEM_SELECTOR).removeClass(toRemoveClasses);
        },
        addResponsiveEvent() {
            if ($(this.$el).parents('#gridHeader').length) {
                Fai.top
                    .$(window)
                    .off('resize.nav')
                    .on('resize.nav', () => {
                        setTimeout(() => {
                            this.updateNavViewArea();
                        }, 500);
                    });
            }
        },

        removeResponsiveEvent() {
            Fai.top.$(window).off('resize.nav');
        },
        toggleFullNavShow() {
            this.showFullNav();
        },
        fetchFullNav() {
            if (this.isInitFullNavRowsAndModules()) {
                return Promise.resolve();
            } else {
                this.isPending = true;
                return initFullNavData({
                    colId: this.colId,
                    extId: this.extId,
                    isEmptyRow: !this.responsiveInfo.pattern.nav.rl.length,
                }).then(() => {
                    initFullNav(
                        this.fullNavIconType,
                        this.fullNavExpendType,
                        this.isPc,
                        this.isShowScroll,
                        this.isInitFullNavRowsAndModules()
                    );
                    this.isPending = false;
                });
            }
        },
        showFullNav() {
            if (this.manageMode) {
                if (!this.isPending) {
                    this.$store.commit('manage/nav/setIsEnterFullNavFromPanel', false);
                    this.$store.commit('manage/nav/setIsShowFullNav', true);
                }
            } else {
                triggerFullNav(true);
            }
        },
        toggleManageModeFullNavShow() {
            if (this.isShowFullNav) {
                triggerFullNav(true);
            }
        },
        isInitFullNavRowsAndModules() {
            if (this.responsiveInfo.pattern.nav.rl.length) {
                if (this.$store.state.rows[`row${[this.responsiveInfo.pattern.nav.rl[0].id]}`]) {
                    return true;
                }
            }
            return false;
        },
        toggleMobiMenu(event) {
            if (this.isApplyFullNavToMobi) {
                this.showFullNav();
            } else {
                this.toggleNav(event);
            }
        },
        toggleNav() {
            if (!$('.mobi_nav').length) {
                return;
            }
            if ($('.jz_nav_layout1').length) {
                $('.jz_web_container').addClass('nav_drawer_style');
                $('.mobi_nav').find('.jz_theme_border_hover_color').removeClass('jz_theme_border_hover_color');
            }

            if ($('.jz_nav_layout0').length || $('.jz_nav_layout2').length) {
                $('.mobi_nav').find('.jz_theme_border_hover_color').removeClass('jz_theme_border_hover_color');
            }

            if ($('.jz_nav_wrap').hasClass('jz_nav_wrap_active')) {
                $('body').removeClass('jz_body_no_scroll');
                $('.jz_web_container').removeClass('nav_drawer_style');
                $('.jz_nav_wrap').removeClass('jz_nav_wrap_active');

                /**防止移动化滑动穿透***/
                /* eslint-disable-next-line no-undef */
                if (!this.manageMode) {
                    if ($('.jz_nav_layout1').length != 0) {
                        if (this.preHeight) {
                            $('#jzWebContainer').css('height', this.preHeight);
                        } else {
                            $('#jzWebContainer').css('height', 'auto');
                        }
                    }
                    preTouchCrossObj.isSaveScroll = false;
                }
            } else {
                $('.jz_nav_wrap').addClass('jz_nav_wrap_active');
                this.preHeight = $('.jz_web_container')[0]?.style.height;

                /**防止移动化滑动穿透 **/
                /* eslint-disable-next-line no-undef */
                if (!preTouchCrossObj.isSaveScroll && !window._store.state.manageMode) {
                    // 苹果浏览器，不能应用这块，否则滚动不了。
                    !Fai.isSafari() && $('body').addClass('jz_body_no_scroll'); // 防止移动化滑动穿透

                    preTouchCrossObj.isSaveScroll = true;
                    // 导航样式二特殊处理，直接设置高度使其无法滚动
                    if ($('.jz_nav_layout1').length != 0) {
                        $('#jzWebContainer').css('height', '100vh');
                    }
                }
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
        hovermenuNavLogo() {
            return {
                list: [
                    {
                        type: 'edit',
                        style: 'edit_icon',
                        click: () => {
                            this.selectNavLogo();
                        },
                    },
                ],
                posMode: 7,
                styleMode: 1,
                resident: false,
            };
        },
    },
    beforeDestory() {
        this.removeResponsiveEvent();
        window.removeEventListener('resize', this.exitEditFreeLayoutMode);
    },
};
