//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectComponent from './selectComponent.vue';
import { getMultiDropDownInfo, getMultiDropDownLevel, getMultiDropDownLevelData } from '@jz/biz-shared';

export default {
    components: {
        SelectComponent,
    },
    props: {
        item: Object,
        value: {
            type: Object,
            default: () => ({
                province: {},
                city: {},
                county: {},
            }),
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            manageVal: {
                firstLevel: {
                    val: '',
                    disabled: false,
                    info: [],
                },
                secondLevel: {
                    val: '',
                    disabled: true,
                    info: [],
                },
                thirdLevel: {
                    val: '',
                    disabled: true,
                    info: [],
                },
                fourthLevel: {
                    val: '',
                    disabled: true,
                    info: [],
                },
            },
        };
    },
    computed: {
        placeholder() {
            return this.$store.state.LS.siteFormPleaseSelect;
        },
        shortLevel() {
            return getMultiDropDownLevel(this?.item?.input, true);
        },
        level() {
            return getMultiDropDownLevel(this?.item?.input);
        },
        currentVal() {
            return this.manageVal;
        },
        dropDownInfo() {
            const input = this.item.input || '';
            return getMultiDropDownInfo(input);
        },
        firstLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(this.dropDownInfo, 0);
            } catch (e) {
                return [];
            }
        },
        secondLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(this.dropDownInfo, 1, this?.currentVal?.firstLevel?.val);
            } catch (e) {
                return [];
            }
        },
        thirdLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(
                    this.dropDownInfo,
                    2,
                    this?.currentVal?.firstLevel?.val,
                    this?.currentVal?.secondLevel?.val
                );
            } catch (e) {
                return [];
            }
        },
        fourthLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(
                    this.dropDownInfo,
                    3,
                    this?.currentVal?.firstLevel?.val,
                    this?.currentVal?.secondLevel?.val,
                    this?.currentVal?.thirdLevel?.val
                );
            } catch (e) {
                return [];
            }
        },
    },
    methods: {
        changeOtherLevel(key, disabled = false) {
            this.currentVal[key].val = '';
            this.currentVal[key].disabled = disabled;
        },
        selectItem(item, level = '') {
            if (item instanceof Object) {
                this.currentVal[level].val = item.value;
            } else {
                this.currentVal[level].val = item;
            }
            this.$emit('input', this.currentVal);
        },
        selectFirstLevel(item) {
            this.selectItem(item, 'firstLevel');
            this.changeOtherLevel('secondLevel');
            this.changeOtherLevel('thirdLevel', true);
            this.changeOtherLevel('fourthLevel', true);
        },
        selectSecondLevel(item) {
            this.selectItem(item, 'secondLevel');
            this.changeOtherLevel('thirdLevel');
            this.changeOtherLevel('fourthLevel', true);
        },
        selectThirdLevel(item) {
            this.selectItem(item, 'thirdLevel');
            this.changeOtherLevel('fourthLevel');
        },
        selectFourthLevel(item) {
            this.selectItem(item, 'fourthLevel');
        },
    },
};
