/**
 * @description 统一处理管理态访客态的通栏轮播 固定背景效果 需求id 【ID1055702】
 * 管理态通过vuex数据驱动 访客态js触发
 * @author mackie.chuhui
 * @date 2021-09-13
 * @export
 * @class CarouselRowsEffects
 */
import { asyncLoad, context } from '@jz/utils';
export class CarouselRowsEffects {
    constructor(options = {}) {
        try {
            const defaultOptions = {};
            this.options = jzUtils.deepExtendClone(defaultOptions, options);
            this.options.mobiPatternEffect = window._store.state.rows?.[`row${options.id}`]?.pattern?.['mobi']?.e;
            this.options.pcPatternEffect = window._store.state.rows?.[`row${options.id}`]?.pattern?.['pc']?.e;
            this.carouselIdx = 1;
            this.preCarouselIdx = 1;
            this.carouselDotIdx = 1;
            this.FIXED_EFFECT = 2;
            this.CAROUSEL_EFFECT = 3;
            this.showTimeFlag = true; // 控制动画执行 避免动画未执行完
            this._proxyOptions();
            this.updateData();
            this.firstIndex = 1;
            this.timer = null;
            this.carouselFlag = true;
            this.runed = false;
            this.replaceLast = true;
            this.replaceFirst = true;
            this.carouselEvent = false;
            this._vm = null;
            this.init();
        } catch (error) {
            console.log(error);
        }
    }
    _proxyOptions() {
        // 代理options属性到对象
        Object.keys(this.options).forEach((key) => {
            Object.defineProperty(this, key, {
                get() {
                    return this.options[key];
                },
                set(val) {
                    this.options[key] = val;
                },
            });
        });
    }
    updateData() {
        this.isMobi = Comm.getDevice() == 'mobi';
        this.isMobiDefaultEffect = !this.isMobi || (this.isMobi && this.options?.mobiPatternEffect?.t === 0);
        this.rowEffectType = this.isMobiDefaultEffect ? this.pcPatternEffect.t : this.mobiPatternEffect.t;
        this.rowEffectSpeed = this.isMobiDefaultEffect ? this.pcPatternEffect.s : this.mobiPatternEffect.s;
        this.rowEffectStyleType = this.isMobiDefaultEffect ? this.pcPatternEffect.rst : this.mobiPatternEffect.rst;
        this.isCarousel = this.rowEffectType == this.CAROUSEL_EFFECT;
        this.isAuto = this.isMobiDefaultEffect ? !!this.pcPatternEffect.rm : !!this.mobiPatternEffect.rm;
        this.lastIndex = this.pcPatternEffect?.rbs?.length;
        this.isCarouselStandAloneSet = this.isCarousel && this.pcPatternEffect?.rb;
        this.isCarouselFixedBg = this.isCarousel && this.pcPatternEffect?.rfb && !this.isCarouselStandAloneSet;
        return this;
    }
    init() {
        const isLoadStellar = this.rowEffectType == this.FIXED_EFFECT;
        isLoadStellar && this.loadStellarScript();
        if (!window._store.state.manageMode) {
            if (this.isCarousel) {
                this._$rootContainer = $(`#row${this.id}`);
                this._$carouselItem = this._$rootContainer?.find('.jz_web_row--carousel_item');
                this.bindActionEvent();
                this.initWatchVm();
            }
        }
        if (this.isCarousel) {
            this.initWatchVm();
        }
        if (this.isCarousel && this.pcPatternEffect?.rm) {
            this.autoPlay();
            this.initCarouselEvent();
        }
        return this;
    }
    replaceLastCarouselItem() {
        if (!this.replaceLast) return;
        let lastContent = this._$carouselItem
            .eq(this._$carouselItem.length - 2)
            .clone()
            .html();
        this._$carouselItem.eq(0).html(lastContent);
    }
    replaceFirstCarouselItem() {
        if (!this.replaceFirst) return;
        let firstContent = this._$carouselItem.eq(1).clone().html();
        this._$carouselItem.eq(this._$carouselItem.length - 1).html(firstContent);
    }
    initCarouselEvent() {
        if (this.carouselEvent) return;
        this.carouselEvent = true;
        $(`#row${this.id}`).hover(
            () => {
                if (
                    window._store.state.manageMode &&
                    (window._store.state.row.rowEditing ||
                        window._store.state.manage.editPanel.active ||
                        (window._store.state.manage.funcPanel.active &&
                            window._store.state.manage.funcPanel.type == 'module'))
                ) {
                    return;
                }
                this.stopAutoPlay();
            },
            () => {
                if (
                    window._store.state.manageMode &&
                    (window._store.state.row.rowEditing ||
                        window._store.state.manage.editPanel.active ||
                        (window._store.state.manage.funcPanel.active &&
                            window._store.state.manage.funcPanel.type == 'module'))
                ) {
                    return;
                }
                this.autoPlay();
            }
        );
    }
    addAnimationHideClassHandler(realDomIdx, cloneDomIdx, moduleClass = 'jz_module') {
        const _$rootContainer = $(`#row${this.id}`),
            ANIMATE_MODULE = 'animateModule',
            _$carouselItem = _$rootContainer?.find('.jz_web_row--carousel_item');
        _$carouselItem
            .eq(realDomIdx)
            .find(`.${moduleClass}`)
            .each((idx, item) => {
                const moduleId = parseInt($(item)?.attr('id')?.match(/\d/g)?.join(''));
                const cloneModuleClass = `#module${moduleId}0`;
                const $cloneModule = _$carouselItem.eq(cloneDomIdx).find(cloneModuleClass);
                if ($(item).hasClass(ANIMATE_MODULE)) {
                    $cloneModule.addClass(`${ANIMATE_MODULE}`);
                } else if ($cloneModule.hasClass(ANIMATE_MODULE)) {
                    $cloneModule.removeClass(`${ANIMATE_MODULE}`);
                }
            });
    }
    addAnimationHideClass() {
        // 处理管理态 模块动画会先显示再执行动画 拿到真实的dom模块是否含有动画隐藏class 有则给对应的克隆dom模块加上
        const len = this.pcPatternEffect?.rbs?.length + 2; // 加上2页克隆的
        if (this.carouselIdx === 0) {
            this.addAnimationHideClassHandler(len - 2, 0);
        } else if (this.carouselIdx === len - 1) {
            this.addAnimationHideClassHandler(1, len - 1);
        }
    }
    initWatchVm() {
        const that = this;
        // 渲染数据用的节点（不挂载）
        const dataEl = document.createElement('div');
        this._vm = new Vue({
            el: dataEl,
            data: {
                carouselRowsEffectVm: that,
            },
            watch: {
                'carouselRowsEffectVm.carouselIdx': function (val) {
                    if (!window._store.state.manageMode) {
                        if (val === 0) {
                            that.replaceLastCarouselItem();
                        } else if (val === that._$carouselItem.length - 1) {
                            that.replaceFirstCarouselItem();
                        } else {
                            that._$carouselItem.eq(0).html('');
                            that._$carouselItem.eq(that._$carouselItem.length - 1).html('');
                        }
                        that.transformCarouselItemStyle();
                    } else {
                        const len = that.pcPatternEffect?.rbs?.length + 2;
                        const _$rootContainer = $(`#row${that.id}`),
                            _$carouselItem = _$rootContainer?.find('.jz_web_row--carousel_item');

                        const delayTime = that.pcPatternEffect?.ram?.ss || 1;
                        if (val === 0) {
                            if (that.runed) {
                                _$carouselItem.eq(val).find('.jz_web_row__content').show();
                                setTimeout(() => {
                                    _$carouselItem.eq(val).find('.jz_web_row__content').hide();
                                }, delayTime * 1000);
                            }
                            that.addAnimationHideClass();
                        } else if (val === len - 1) {
                            if (that.runed) {
                                _$carouselItem.eq(val).find('.jz_web_row__content').show();
                                setTimeout(() => {
                                    _$carouselItem.eq(val).find('.jz_web_row__content').hide();
                                }, delayTime * 1000);
                            }
                            that.addAnimationHideClass();
                        }
                    }
                },
            },
        });
    }
    transformCarouselItemStyle() {
        const curIndex = this?.carouselIdx ?? 0;
        const carouselFlag = this?.carouselFlag ?? true;
        const preCarouselIdx = this?.preCarouselIdx ?? 0;
        const carouselListLen = this._$carouselItem?.length;
        this._$carouselItem?.each((index, item) => {
            const _style = {};
            if (!this.isCarouselFixedBg || this.isCarouselStandAloneSet) {
                if (index == curIndex) {
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                } else if (index > curIndex) {
                    _style['position'] = 'absolute';
                    _style['left'] = '100%';
                } else {
                    _style['position'] = 'absolute';
                    _style['left'] = '-100%';
                }
                if (preCarouselIdx === carouselListLen - 2 && curIndex === 1 && index == curIndex && carouselFlag) {
                    // 最后一张过渡到第一张
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                } else if (
                    curIndex === carouselListLen - 2 &&
                    curIndex == index &&
                    preCarouselIdx === 1 &&
                    carouselFlag
                ) {
                    // 第一张过渡到最后一张
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                }
                _style['transform'] = 'translateX(0%)';
                if (preCarouselIdx == index || index == curIndex) {
                    _style['transition'] = `left ${this.pcPatternEffect.ram.ss}s`;
                } else {
                    _style['transition'] = '';
                }
            } else {
                if (index == curIndex) {
                    _style['transform'] = `translateX(${index * -100}%)`;
                } else if (index > curIndex) {
                    _style['transform'] = `translateX(${(index - 1) * -100}%)`;
                } else {
                    _style['transform'] = `translateX(${(index + 1) * -100}%)`;
                }
                if (preCarouselIdx == index || index == curIndex) {
                    _style['transition'] = `transform ${this.pcPatternEffect.ram.ss}s`;
                } else {
                    _style['transition'] = '';
                }
                if (preCarouselIdx === carouselListLen - 2 && curIndex === 1 && index == curIndex && carouselFlag) {
                    // 最后一张过渡到第一张
                    _style['transform'] = `translateX(${index * -100}%)`;
                } else if (
                    curIndex === carouselListLen - 2 &&
                    curIndex == index &&
                    preCarouselIdx === 1 &&
                    carouselFlag
                ) {
                    // 第一张过渡到最后一张
                    _style['transform'] = `translateX(${index * -100}%)`;
                }
            }
            if (carouselFlag) {
                $(item).addClass('transition_none');
            } else {
                $(item).removeClass('transition_none');
            }
            $(item).css(_style);
        });
    }
    // 加载固定背景插件
    loadStellarScript() {
        asyncLoad.loadJs(window._store.state.stellarPath, this.addStellarEvent);
        return this;
    }
    bindActionEvent() {
        switch (this.rowEffectStyleType) {
            case 0:
            case 1:
                this._selectActionEvent();
                break;
            case 2:
                this._arrowActionEvent();
                break;
            default:
                break;
        }
        //左滑
        $(this._$rootContainer)
            .off('swipeleft.l')
            .on('swipeleft.l', '.jz_web_row--carousel_wrap', () => {
                this.next();
                this._changeDotClass();
            });
        //右滑
        $(this._$rootContainer)
            .off('swiperight.r')
            .on('swiperight.r', '.jz_web_row--carousel_wrap', () => {
                this.prev();
                this._changeDotClass();
            });
    }
    _selectActionEvent() {
        const $row = this._$rootContainer,
            event = `click.select${this.id}`;
        const that = this;
        $row.find('.carousel_select_photo')
            .off(event)
            .on(event, function () {
                if (that.showTimeFlag) {
                    const idx = $(this).index();
                    $(this).siblings().removeClass('active');
                    $(this).addClass('active');
                    that.selectPhoto(idx + 1);
                }
            });
    }
    _arrowActionEvent() {
        const $row = this._$rootContainer,
            event = `click.arrow${this.id}`;
        $row.find('.carousel_arrow.next')
            .off(event)
            .on(event, () => {
                this.next();
            });
        $row.find('.carousel_arrow.prev')
            .off(event)
            .on(event, () => {
                this.prev();
            });
    }
    _changeDotClass() {
        if ((this.rowEffectStyleType == 0 || this.rowEffectStyleType == 1) && !window._store.state.manageMode) {
            this._$rootContainer
                .find('.select_photo')
                .eq(this.carouselDotIdx - 1)
                .addClass('active')
                .siblings()
                .removeClass('active');
        }
    }
    addStellarEvent() {
        var $moduleBgListDom = $('.stellar_background[data-stellar-background-ratio]'),
            moduleBgListDom = Array.from($moduleBgListDom),
            isHaveParallax = !!moduleBgListDom.length;
        if (isHaveParallax) {
            $(window).stellar('destroy');
            $(window).stellar({
                horizontalScrolling: false,
                responsive: false,
            });
        }
        return this;
    }
    next() {
        if (this.pcPatternEffect.rbs.length <= 1 || !this.showTimeFlag) return;
        this.showTimeFlag = false;
        const delayTime = this.pcPatternEffect?.ram?.ss || 1;
        this.preCarouselIdx = this.carouselIdx;
        this.runed = true;
        if (this.carouselIdx < this.lastIndex) {
            this.carouselFlag = false;
            this.carouselIdx++;
            this.carouselDotIdx++;
            setTimeout(() => {
                this.carouselFlag = true;
                this.runed = false;
            }, delayTime * 1000);
        } else {
            this.carouselFlag = false;
            this.carouselIdx++;
            this.carouselDotIdx = this.firstIndex;
            setTimeout(() => {
                this.carouselFlag = true;
                this.carouselIdx = this.firstIndex;
                this.runed = false;
            }, delayTime * 1000);
        }
        setTimeout(() => {
            this.showTimeFlag = true;
        }, delayTime * 1000);
        return this;
    }
    prev() {
        if (this.pcPatternEffect.rbs.length <= 1 || !this.showTimeFlag) return;
        this.showTimeFlag = false;
        const delayTime = this.pcPatternEffect?.ram?.ss || 1;
        this.preCarouselIdx = this.carouselIdx;
        this.runed = true;
        if (this.carouselIdx > this.firstIndex) {
            this.carouselFlag = false;
            this.carouselIdx--;
            this.carouselDotIdx--;
            setTimeout(() => {
                this.carouselFlag = true;
                this.runed = false;
            }, delayTime * 1000);
        } else {
            this.carouselFlag = false;
            this.carouselIdx--;
            this.carouselDotIdx = this.lastIndex;
            this.runed = true;
            setTimeout(() => {
                this.carouselFlag = true;
                this.carouselIdx = this.lastIndex;
                this.runed = false;
            }, delayTime * 1000);
        }
        setTimeout(() => {
            this.showTimeFlag = true;
        }, delayTime * 1000);
        return this;
    }
    clearTimer() {
        clearInterval(this.timer);
        return this;
    }
    stopAutoPlay() {
        this.clearTimer();
        this.timer = null;
        return this;
    }
    autoPlay() {
        this.clearTimer();
        if (!this.isAuto) return this;
        this.timer = setInterval(() => {
            this.next();
            this._changeDotClass();
        }, this.pcPatternEffect?.ram?.dt * 1000);
        return this;
    }
    selectPhoto(idx) {
        if (!this.showTimeFlag) return;
        const delayTime = this.pcPatternEffect?.ram?.ss || 1;
        this.showTimeFlag = false;
        this.preCarouselIdx = this.carouselIdx;
        this.carouselFlag = false;
        this.carouselDotIdx = idx;
        this.carouselIdx = idx;
        this.runed = true;
        setTimeout(() => {
            this.showTimeFlag = true;
            this.carouselFlag = true;
            this.runed = false;
        }, delayTime * 1000);
        return this;
    }
}
if (!context._CarouselRowsEffectsMap) {
    context._CarouselRowsEffectsMap = new Map();
}
CarouselRowsEffects.CarouselRowsEffectsMap = context._CarouselRowsEffectsMap;
