//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MuteButton',
    props: {
        isVideoMute: {
            type: Boolean,
            required: true,
        },
        muteBtn: {
            type: Object,
            required: true,
        },
    },
    computed: {
        btnStyle() {
            if (this.muteBtn.open === 1 && this.muteBtn.style === 1 && this.muteBtn.imgUrl) {
                return {
                    backgroundImage: `url(${this.muteBtn.imgUrl})`,
                };
            } else {
                return {};
            }
        },
    },
    mounted() {
        this.fixTop();
    },
    methods: {
        toggleMute() {
            this.$emit('toggleMute');
        },
        fixTop() {
            // 顶部悬浮
            if (!this.$store.getters.isFullScreen) {
                this.$watch(
                    '$store.state.responsiveInfo.pattern.header.hftt',
                    () => {
                        fixTop.call(this, this.$store.state.responsiveInfo.pattern.header.hftt === 1);
                    },
                    {
                        immediate: true,
                    }
                );
            } else {
                this.$watch(
                    '$store.state.responsiveInfo.pattern.rollingScreen.topType',
                    () => {
                        fixTop.call(this, this.$store.state.responsiveInfo.pattern.rollingScreen.topType == 1);
                    },
                    {
                        immediate: true,
                    }
                );
            }
            function fixTop(condition) {
                if (!this.$refs.audioBtn) {
                    return;
                }
                if (condition) {
                    const DEFAULT_TOP = 20;
                    const top = parseInt($('.header_content').css('height')) + DEFAULT_TOP;
                    $(this.$refs.audioBtn).css('top', top);
                } else {
                    $(this.$refs.audioBtn).css('top', '');
                }
            }
        },
    },
};
