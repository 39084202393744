import { warningMessage } from '@/site/shared/dialog/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

// 搜索弹窗
class SearchPupop {
    constructor(options = {}) {
        this.options = options;
        this.created = false;
        this.historyList = getSearchHistoryRecord();
        this.baseTemplate = `
          <div class="site_search_mobi_popup searchWindowOpen" data-id="{{moduleId}}">
              <div class="search_mobi_header">
                  <div class="search_mobi_input_wrap">
                      <svg class="search_mobi_search_svg"><use xlink:href="#icon_search"></use></svg>
                      <input type="text" placeholder="{{placeholder}}">
                      <svg style="display: none;" class="search_mobi_close_svg"><use xlink:href="#icon_close_search"></use></svg>
                  </div>
                  <p class="search_mobi_cancel">${LS.cancel}</p>
              </div>
              <div id="searchMobiRecommend">
                  {{recommend}}
              </div>
              <div id="searchMobiHistory">
                  {{history}}
              </div>
          </div>
      `;
        this.create();
    }

    create() {
        const template = this.replaceHtml();
        const addSelector = this.options.manageMode ? '#jzWebContainer' : 'body';
        $(addSelector).append($(template));
        this.initEvent();
    }

    initEvent() {
        const _this = this;
        const $input = $('.search_mobi_input_wrap input');
        const $clearInputSvg = $('.search_mobi_close_svg');
        $('.search_mobi_cancel')
            .off('click')
            .on('click', () => {
                this.hide();
            });
        $input.off('keyup').on('keyup', function (e) {
            if (e.which === 13) {
                const keyword = $(this).val();
                if (keyword.length == 0) {
                    _this.toastError(LS.searchBoxErrorToast);
                    return;
                }
                _this.addHistoryRecord(keyword);
                jumpSearchPage(_this.options.moduleId, keyword);
            }

            if ($(this).val() != '') {
                $clearInputSvg.show();
            } else {
                $clearInputSvg.hide();
            }
        });
        $('.search_mobi_keyword')
            .off('click')
            .on('click', function () {
                const keyword = $(this).text();
                _this.addHistoryRecord(keyword);
                jumpSearchPage(_this.options.moduleId, keyword);
            });
        $('.search_mobi_list_clear')
            .off('click')
            .on('click', function () {
                $('.search_mobi_history_list').remove();
                removeAllSearchHistoryRecord();
            });

        $clearInputSvg.off('click').on('click', function () {
            $input.val('');
            $clearInputSvg.hide();
        });

        $('.site_search_mobi_popup').on(
            'animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend',
            function () {
                $input.focus();
                $(this).off('animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend');
            }
        );
    }

    replaceHtml() {
        const options = this.options;
        let template = this.baseTemplate;

        const recommendKeyWordList = options.recommendKeyWordList || [];
        let recommendDomStr = '';
        if (recommendKeyWordList.length > 0) {
            recommendDomStr = `
              <div class="search_mobi_keyword_list">
                  <p class="search_mobi_list_title">${LS.searchBoxHotText}</p>
                  <div class="search_mobi_keyword_wrap">
                      ${recommendKeyWordList
                          .map((e) => '<span class="site_search_text_compon search_mobi_keyword">' + e + '</span>')
                          .join('')}
                  </div>
              </div>
          `;
        }

        // 初始化热门搜索
        let historyDomStr = '';
        historyDomStr = this.renderHistoryDom(this.historyList);
        template = template.replace('{{placeholder}}', options.placeholder || '');
        template = template.replace('{{history}}', historyDomStr || '');
        template = template.replace('{{recommend}}', recommendDomStr || '');
        template = template.replace('{{moduleId}}', options.moduleId || '');
        return template;
    }

    update(options) {
        this.options = options;
        const recommendKeyWordList = options.recommendKeyWordList || [];
        let recommendDomStr = '';
        if (recommendKeyWordList.length > 0) {
            recommendDomStr = `
              <div class="search_mobi_keyword_list">
                  <p class="search_mobi_list_title">${LS.searchBoxHotText}</p>
                  <div class="search_mobi_keyword_wrap">
                      ${recommendKeyWordList
                          .map((e) => '<span class="site_search_text_compon mobi_keyword">' + e + '</span>')
                          .join('')}
                  </div>
              </div>
          `;
        }

        $('#searchMobiRecommend').html(recommendDomStr);

        // 初始化热门搜索
        this.historyList = getSearchHistoryRecord();
        let historyDomStr = '';
        historyDomStr = this.renderHistoryDom(this.historyList);
        $('#searchMobiHistory').html(historyDomStr);
        this.initEvent();

        const moduleId = options.moduleId;
        $('.site_search_mobi_popup').attr('data-id', moduleId);
        $('.site_search_mobi_popup').addClass('searchWindowOpen').show();
    }

    toastError(errorMsg) {
        if (errorMsg === '') return;
        if (this.options.manageMode) {
            Vue?.$famessage?.warning(errorMsg, 3, null, true);
        } else {
            warningMessage(errorMsg, true);
        }
    }

    hide() {
        $('.site_search_mobi_popup').on(
            'animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend',
            function () {
                $(this).hide();
                $(this).removeClass('searchWindowOff searchWindowOpen');
                $(this).off('animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend');
            }
        );
        $('.site_search_mobi_popup').addClass('searchWindowOff');
        setTimeout(() => {
            $('.site_search_mobi_popup').hide();
            $('.site_search_mobi_popup').removeClass('searchWindowOff searchWindowOpen');
            $('.site_search_mobi_popup').off(
                'animationend webkitAnimationEnd msAnimationEnd oAnimationEnd oanimationend'
            );
        }, 500);
    }

    renderHistoryDom(historyList) {
        if (historyList.length > 0 && this.options.isShowHistoryRecord) {
            return `
              <div class="search_mobi_keyword_list search_mobi_history_list">
                  <p class="search_mobi_list_title">${LS.searchBoxHistoryText}</p>
                  <p class="search_mobi_list_clear search_mobi_list_title">${LS.searchBoxClearText}</p>
                  <div class="search_mobi_keyword_wrap">
                      ${historyList
                          .map((e) => '<span class="site_search_text_compon search_mobi_keyword">' + e + '</span>')
                          .join('')}
                  </div>
              </div>
          `;
        } else {
            return '';
        }
    }

    addHistoryRecord(keyword) {
        addSearchHistoryRecord(keyword, (historyRecordList) => {
            this.historyList = historyRecordList;
        });
    }
}

const getSearchPupop = (() => {
    let popup = null;
    return (options) => {
        if (popup) {
            popup.created = true;
            return popup;
        } else {
            return (popup = new SearchPupop(options));
        }
    };
})();

const jumpSearchPage = (moduleId, keyword) => {
    const encodeKeyword = encodeURIComponent(keyword.trim());
    const encodeId = encodeURIComponent(moduleId);
    const url = Site.preprocessUrl({ path: '', oldPath: '' }) + `search.jsp?id=${encodeId}&q=${encodeKeyword}`;
    window.location.href = url;
};

const HISTORY_LOCALSTORAGE_NAME = 'siteSearchHistoryRecordList';
const MAX_HISTORY_LENGTH = 5;

const getSearchHistoryRecord = () => {
    if (!window.localStorage) return [];
    return JSON.parse(window.localStorage.getItem(HISTORY_LOCALSTORAGE_NAME)) || [];
};

const addSearchHistoryRecord = (keyword, callback) => {
    if (!window.localStorage || keyword === '') return;

    const list = JSON.parse(window.localStorage.getItem(HISTORY_LOCALSTORAGE_NAME) || '[]');
    if (list.length === 0) {
        list.unshift(keyword);
    } else {
        // 列表中有这个搜索词, 换位置
        const index = list.findIndex((e) => e == keyword);
        if (index == -1) {
            if (list.length === MAX_HISTORY_LENGTH) {
                list.pop();
            }
        } else {
            list.splice(index, 1);
        }
        list.unshift(keyword);
    }
    window.localStorage.setItem(HISTORY_LOCALSTORAGE_NAME, JSON.stringify(list));
    typeof callback === 'function' && callback(list);
};

const removeAllSearchHistoryRecord = () => {
    if (!window.localStorage) return;
    window.localStorage.removeItem(HISTORY_LOCALSTORAGE_NAME);
};

export { getSearchPupop, addSearchHistoryRecord, removeAllSearchHistoryRecord, getSearchHistoryRecord, jumpSearchPage };
