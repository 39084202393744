//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timelineMixin } from './mixin';
export default {
    name: 'TimelineTextVertical',
    mixins: [timelineMixin],
    computed: {
        isAllTitleEmpty() {
            return this.timelineItems.every((t) => t.time === '');
        },
    },
};
