import { Comm } from '../../../../../utils';
const { getStyleDataByDevice, extend, getComputedTextColor, getWrapStyle } = Comm;

export const titleMixin = {
    computed: {
        showTitle() {
            if (this.isMobi) {
                return this.mobiPrivatePattern.psc == 0
                    ? this.pcPrivatePattern.pts === 0
                    : this.mobiPrivatePattern.pts == 0;
            } else {
                return this.pcPrivatePattern.pts === 0;
            }
        },
        titleStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ptf,
                mobiData: this.mobiPrivatePattern.ptf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ptl,
                mobiData: this.mobiPrivatePattern.ptl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },
    },
    methods: {
        getTitleHoverColor(item) {
            let style = {};
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ptf,
                mobiData: this.mobiPrivatePattern.ptf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            if (item.hovering && !this.isMobi) {
                if (font.y != 0) {
                    style = getComputedTextColor(font.hc || '#000');
                } else {
                    if (!this.$isServer) {
                        style.color = this.themeColor;
                    }
                }
            }
            return style;
        },
        handleItemMouseEnter(item) {
            this.stopWatchDataInManageMode();
            this.$set(item, 'hovering', true);
        },
        handleItemMouseLeave(item) {
            this.stopWatchDataInManageMode();
            this.$set(item, 'hovering', false);
        },
        stopWatchDataInManageMode() {
            this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
        },
    },
};
