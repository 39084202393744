//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { triggerSharePanel } from '@/components/modules/common/shareComponent/index.js';
import { photoGroupInfoMixin, photoGroupDetailMixin } from '../mixin';
import CarouselComponent from './carousel-component.vue';
import JzShareSsrCompon from '@/components/modules/common/shareCompon/index.vue';
export default {
    name: 'mobi-view',
    components: {
        CarouselComponent,
        JzShareSsrCompon,
    },
    mixins: [photoGroupInfoMixin, photoGroupDetailMixin],
    props: ['module', 'id'],
    data() {
        return {
            mobile: true,
        };
    },
    computed: {
        sharePanelId() {
            return `photoGroupShare${this.module.id}`;
        },
    },
    methods: {
        showSharePanel(e) {
            triggerSharePanel(e, `#${this.sharePanelId}`);
        },
    },
};
