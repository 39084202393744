//
//
//
//
//
//
//
//
//

const titleDivColList = [7, 8, 22];
export default {
    name: 'siteTitle',
    props: ['value', 'indexUrl'],
    computed: {
        ...Vuex.mapState(['device', 'colId']),
        titleTag() {
            if (this.device === 'mobi') {
                return 'h2';
            }
            //为了网站SEO，文章详情页的网站标题去掉h1标签，把h1标签放在文章标题
            if (titleDivColList.includes(this.colId)) {
                return 'div';
            }
            return 'h1';
        },
        titleContent() {
            let content = this.value;
            if (!content.includes('</a>') && !content.includes('href')) {
                content = `<a href="${this.indexUrl || '/index.jsp'}">${content}</a>`;
            }
            return content;
        },
    },
};
