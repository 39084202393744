//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formMixin from './formMixin';
export default {
    mixins: [formMixin],
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
};
