//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { createUrlArgsOnclickStr } = Comm;
import { observeAnimate } from '@/site/shared/index.js';
import { USE_NEW_CAROUSEL, THREE_ONE_PHOTO_OFFSET } from './constants.js';
import photoDescription from './photoDescription.vue';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { photoSettingMixin } from '../photoSettingMixin';
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
const manageUse = {};
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { getImgComponetOption, imgOptsComputed } from '@/components/modules/shared/imgEffect.js';

/**
 * @function name - initCarousel
 * @description 初始化轮播功能
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function initCarousel() {
    //左滑
    $(this.$el)
        .off('swipeleft.l')
        .on('swipeleft.l', '.photo_display_list', () => {
            this.next();
        });
    //右滑
    $(this.$el)
        .off('swiperight.r')
        .on('swiperight.r', '.photo_display_list', () => {
            this.prev();
        });
}

/**
 * @function name - autoPlay
 * @description 自动轮播
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function autoPlay() {
    if (!USE_NEW_CAROUSEL) {
        this.controller.resetAutoPlay();
        return;
    }
    if (this.timerId || !this.isAutoPlay) return;
    this.timerId = `marquee-${this.module.id}`;
    this.resetAutoPlay();
}

/**
 * @function name resetAutoPlay
 * @description
 * @author mikey.chuhui
 * @date 2020-08-07
 * @returns
 */

function resetAutoPlay() {
    if (!USE_NEW_CAROUSEL) {
        this.controller.resetAutoPlay();
        return;
    }
    if (!this.timerId) return;
    Fai.stopInterval(this.timerId);
    Fai.addInterval(
        this.timerId,
        () => {
            this.showTimeFlag && this.next();
        },
        this.showTime
    );
    Fai.startInterval(this.timerId);
}

/**
 * @function name stopAutoPlay
 * @description 暂停自动轮播
 * @author mikey.chuhui
 * @date 2020-08-10
 */

function stopAutoPlay() {
    this.timerId && Fai.stopInterval(this.timerId);
    this.timerId = null;
}

/**
 * @function name playThreePhotoStyleAnimate
 * @description 播放三图滚动样式
 * @author mikey.chuhui
 * @date 2020-08-05
 */

function playThreePhotoStyleAnimate() {}

/**
 * @function name changeThreePicIndex
 * @description 切换三图样式图片索引
 * @author mikey.chuhui
 * @date 2020-08-10
 */

function changeThreePicIndex(dir) {
    const photoLastIndex = this.photoListRealLen - 5;
    const delayTime = this.speedTime;
    this.showTimeFlag = false;
    if (dir === 'right') {
        // 上一张
        if (this.currentPicIndex > 0) {
            this.threePhotoFlag = false;
            this.prePicIndex = this.currentPicIndex;
            this.currentPicIndex--;
            setTimeout(() => {
                this.threePhotoFlag = true;
            }, delayTime * 1000);
        } else {
            // 当前图是第一张时 上一张最后一张 先再多走一张 再无过渡效果的回到第一张
            this.threePhotoFlag = false;
            this.currentPicIndex--;
            this.prePicIndex = 0;
            setTimeout(() => {
                this.threePhotoFlag = true;
                // 过渡动画时间过后 回到第一张 做无缝滚动效果
                this.currentPicIndex = photoLastIndex;
            }, delayTime * 1000);
        }
    } else {
        if (this.currentPicIndex < photoLastIndex) {
            this.threePhotoFlag = false;
            this.prePicIndex = this.currentPicIndex;
            this.currentPicIndex++;
            setTimeout(() => {
                this.threePhotoFlag = true;
            }, delayTime * 1000);
        } else {
            // 最后一张回到第一张  先再多走一张 再无过渡效果的回到第一张
            this.threePhotoFlag = false;
            this.currentPicIndex++;
            this.prePicIndex = photoLastIndex;
            setTimeout(() => {
                this.threePhotoFlag = true;
                // 过渡动画时间过后 回到第一张 做无缝滚动效果
                this.currentPicIndex = 0;
            }, delayTime * 1000);
        }
    }
    setTimeout(() => {
        this.showTimeFlag = true;
    }, delayTime * 1000);
}

/**
 * @function name changeDefaultPicIndex
 * @description 切换默认样式图片索引
 * @author mikey.chuhui
 * @date 2020-08-10
 */

function changeDefaultPicIndex(dir) {
    const photoLastIndex = this.photoListRealLen - 1;
    if (dir === 'right') {
        // 上一张
        if (this.currentPicIndex > 0) {
            this.prePicIndex = this.currentPicIndex;
            this.currentPicIndex--;
        } else {
            // 当前图是第一张时 上一张最后一张
            this.prePicIndex = 0;
            this.currentPicIndex = photoLastIndex;
        }
    } else {
        if (this.currentPicIndex < photoLastIndex) {
            this.prePicIndex = this.currentPicIndex;
            this.currentPicIndex++;
        } else {
            this.currentPicIndex = 0;
            this.prePicIndex = photoLastIndex;
        }
    }
}

/**
 * @function name changePicIndex
 * @description 切换图片
 * @author mikey.chuhui
 * @date 2020-08-10
 * @param dir {String}
 */

function changePicIndex(dir) {
    switch (this.moduleStyle) {
        case 3:
            this.changeThreePicIndex(dir);
            break;
        default:
            this.changeDefaultPicIndex(dir);
            break;
    }
}

/**
 * @function name playDefaultAnimate
 * @description 播放默认滚动动画
 * @author mikey.chuhui
 * @date 2020-08-05
 */

function playDefaultAnimate() {
    let outConfig = {},
        inConfig = {};
    outConfig.page = $(this.$el).find('.photo_display_list li').eq(this.prePicIndex);
    outConfig.animateType = 'out';
    inConfig.page = $(this.$el).find('.photo_display_list li').eq(this.currentPicIndex);
    inConfig.animateType = 'in';
    this.runAnimation(inConfig);
    this.runAnimation(outConfig);
}

/**
 * @function name play
 * @description 执行动画
 * @author mikey.chuhui
 * @date 2020-08-10
 */

function play() {
    switch (this.moduleStyle) {
        case 3:
            this.playThreePhotoStyleAnimate();
            break;
        default:
            this.playDefaultAnimate();
            break;
    }
}

function runAnimation(config) {
    const { page, animateType } = config;
    if (animateType == 'out') {
        // 进入动画 则直接显示图片
        this.outActivePicFlag = true;
    }
    const _this = this;
    if (animateType == 'out') {
        // 加上动画类
        this.outAnimateFlag = true;
    } else {
        this.inAnimateFlag = true;
    }
    const animateOptions = {
        el: page,
        startCallBack(event) {
            $(this).data('carousel-animated', 'true'); // 标记动画开始执行
            animateType == 'in' && (_this.showTimeFlag = false);
            event.stopPropagation();
            return false;
        },
        endCallBack(event) {
            $(this).removeData('carousel-animated'); // 标记动画已经执行结束
            if (animateType == 'out') {
                // 出场动画 则动画结束后隐藏并移除动画类名
                _this.outActivePicFlag = false;
                _this.outAnimateFlag = false;
            } else {
                // 入场动画 移除动画类名
                _this.inAnimateFlag = false;
                _this.showTimeFlag = true;
            }
            event.stopPropagation();
            return false;
        },
        eventDes: 'carousel',
    };
    observeAnimate(animateOptions);
}

const throttle = function (delay, action) {
    var last = 0;
    return function () {
        var curr = new Date();
        if (curr - last > delay) {
            action.apply(this, arguments);
            last = curr;
        }
    };
};

/**
 * @function name runHanle
 * @description
 * @author mikey.chuhui
 * @date 2020-08-05
 */

const runHanle = function (time) {
    let delay = time > 0.5 ? 500 : time * 1000;
    const _this = this;
    return throttle(delay, function (dir) {
        _this.animateDir !== dir && (_this.animateDir = dir);
        _this.changePicIndex(dir);
        _this.play();
    });
};

/**
 * @function name prev
 * @description 上一张
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function prev() {
    if (this.isRunThreePhoto) return;
    USE_NEW_CAROUSEL && this.runHanle(this.showTime)('right');
}

/**
 * @function name next
 * @description 下一张
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function next() {
    if (this.isRunThreePhoto) return;
    USE_NEW_CAROUSEL && this.runHanle(this.showTime)('left');
}

/**
 * @function name selectPhoto
 * @description 选中第几张
 * @author mikey.chuhui
 * @date 2020-08-04
 * @param index {Number}
 */

function selectPhoto(index) {
    if (index === this.currentPicIndex || !USE_NEW_CAROUSEL) return;
    this.prePicIndex = this.currentPicIndex;
    this.currentPicIndex = index;
    if (this.currentPicIndex > this.prePicIndex) {
        this.animateDir = 'left';
    } else {
        this.animateDir = 'right';
    }
    this.play();
}

function selectStyle0Photo(index) {
    //下面的列表
    const $photo = $(this.$el).find('.photo_list_area').children().eq(index),
        $list = $photo.parent(),
        photo_pos = $photo.position().left,
        photo_width = $photo.outerWidth(true),
        list_width = $list.outerWidth(true);

    if (photo_pos >= list_width - photo_width * 2) {
        //右边元素超出可视区
        $list.animate(
            {
                scrollLeft: (index + 2) * photo_width - list_width - 18, //18像素为每一个元素左右的间距,跟css关联
            },
            this.speedTime
        );
    } else if (photo_pos < photo_width) {
        //左边元素超出可视区
        $list.animate(
            {
                scrollLeft: $list.scrollLeft() + photo_pos - photo_width + 9, //9像素为第一个元素左边少去的边距,跟css关联
            },
            this.speedTime
        );
    }
}

/**
 * @function name photoMouseover
 * @description 图片移入 暂停轮播
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function photoMouseover() {
    this.stopAutoPlay();
}

/**
 * @function name photoMouseleave
 * @description 图片移出 继续轮播
 * @author mikey.chuhui
 * @date 2020-08-04
 */

function photoMouseleave() {
    this.autoPlay();
}

function linkTarget(isBlank) {
    return isBlank ? '_blank' : '';
}

const Carousel = {
    initCarousel,
    autoPlay,
    prev,
    next,
    selectPhoto,
    photoMouseover,
    photoMouseleave,
    play,
    runAnimation,
    playDefaultAnimate,
    playThreePhotoStyleAnimate,
    runHanle,
    stopAutoPlay,
    resetAutoPlay,
    changePicIndex,
    changeDefaultPicIndex,
    changeThreePicIndex,
    selectStyle0Photo,
};
export default {
    components: {
        ImageMainViewer,
        photoDescription,
        ImageComponent,
    },
    mixins: [slidesshowDataMixin, photoSettingMixin],
    props: ['photoList', 'module', 'moduleStyle', 'pattern', 'usePhotoGroup'],
    data() {
        return {
            carouselAnimateType: 1,
            animateDir: 'left', // 滚动方向 从右往左 下一张
            currentPicIndex: 0, // 下一张
            prePicIndex: 0, // 上一张
            timerId: null,
            outActivePicFlag: false, // 控制选中图类名的 出场动画需动画结束后才去除
            inAnimateFlag: false, // 入场动画的一个锁
            outAnimateFlag: false, // 出场动画的一个锁
            threePhotoFlag: true, // 控制最后一张到第一张 切换回真实的第一张后才能继续滚动 或第一到最后
            showTimeFlag: true, // 控制展示时间 区分动画 动画走完才让走下一屏
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'jzVersion']),
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-轮播多图',
                    jz_version: this.jzVersion,
                },
            };
        },
        isRunThreePhoto() {
            return this.moduleStyle === 3 && !this.threePhotoFlag;
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.psrc;
                previewSrcItem.description = value.desc;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
        isDefaultAuto() {
            // 是否默认自动播放
            return this.curPct === 0;
        },
        isMobiDefaultAuto() {
            return this.isMobi && this.model.pct == 0;
        },
        curPct() {
            // mobi 默认  联动pc轮播数据
            return this.isMobiDefaultAuto ? this.pcPrivatePattern.pct : this.model.pct;
        },
        curPcst() {
            return this.isMobiDefaultAuto ? this.pcPrivatePattern.pcst : this.model.pcst;
        },
        curPcswt() {
            return this.isMobiDefaultAuto ? this.pcPrivatePattern.pcswt : this.model.pcswt;
        },
        showTime() {
            // 单图展示时间
            return this.isDefaultAuto ? 4000 : this.curPcst * 1000;
        },
        speedTime() {
            // 轮播切换速度
            return this.isDefaultAuto ? 0.8 : this.curPcswt;
        },
        isAutoPlay() {
            // 是否自动播放
            return this.curPct !== 2;
        },
        photoListRealLen() {
            //图片数据长度 样式3 则根据处理后的图片数据长度 因为其他样式不用复制数据
            return this.displayList.length;
        },
        photoListStyle() {
            // 样式3
            if (!USE_NEW_CAROUSEL || this.moduleStyle !== 3) return;
            const style = {};
            style['transform'] = `translateX( ${-((this.currentPicIndex + 2) * THREE_ONE_PHOTO_OFFSET - 8)}%)`;
            style['transition'] = `transform ${this.speedTime}s`;
            this.moduleStyle3Change && (this.moduleStyle3Change = false);
            if (this.prePicIndex === this.photoListRealLen - 5 && this.currentPicIndex === 0 && this.threePhotoFlag) {
                // 最后一张过渡到第一张
                style['transition'] = 'none';
                style['transform'] = `translateX( ${-(2 * THREE_ONE_PHOTO_OFFSET - 8)}%)`;
            } else if (
                this.currentPicIndex === this.photoListRealLen - 5 &&
                this.prePicIndex === 0 &&
                this.threePhotoFlag
            ) {
                // 第一张过渡到最后一张
                style['transition'] = 'none';
                style['transform'] = `translateX( ${-((this.currentPicIndex + 2) * THREE_ONE_PHOTO_OFFSET - 8)}%)`;
            }
            return style;
        },
        photoListItemStyle() {
            // 动画切换速度
            if (this.moduleStyle === 3 || !USE_NEW_CAROUSEL) return;
            return { 'animation-duration': `${this.speedTime}s` };
        },
        photoWidth() {
            if (!this.manageMode) {
                return;
            }
            const style = {};
            if (this.pattern.ppszt === 1) {
                !this.isMobi && (style['width'] = `${this.pattern.ppw * 100}%`);
            }
            return style;
        },
        photoHeight() {
            const style = {};
            if (this.model.ppszt === 1) {
                style['padding-bottom'] = `${this.model.pph * 100}%`;
            } else if (this.isMobi && this.model.ppszt == 0) {
                style['padding-bottom'] = `${this.pcPrivatePattern.pph * 100}%`;
            }
            return style;
        },
        wrapperClasses() {
            switch (this.moduleStyle) {
                case 0:
                    return ['carousel_small_pic'];
                case 1:
                    return ['carousel_arrow_pic'];
                case 2:
                    return ['carousel_dot_pic'];
                case 3:
                    return ['carousel_three_pic'];
            }
            return [];
        },
        displayList() {
            if (this.moduleStyle === 3) {
                return this.displayListThreePhoto;
            } else if (USE_NEW_CAROUSEL) {
                return this.photoList;
            }
            //展示的时候在列表最后加一项用来做最后一项到第一项的效果
            //第一项也加上最后一项
            var displayList = this.photoList.slice();
            displayList.push(this.photoList[0]);
            displayList.unshift(this.photoList[this.photoList.length - 1]);
            return displayList;
        },
        displayListThreePhoto() {
            if (this.photoList.length === 1) {
                return [this.photoList[0], this.photoList[0], this.photoList[0], this.photoList[0], this.photoList[0]];
            }
            var displayList = this.photoList.slice();
            displayList.push(this.photoList[0]);
            displayList.push(this.photoList[1]);
            displayList.unshift(this.photoList[this.photoList.length - 1]);
            displayList.unshift(this.photoList[this.photoList.length - 2]);
            return displayList;
        },
    },
    watch: {
        currentPicIndex(val) {
            this.moduleStyle === 0 && this.selectStyle0Photo(val);
        },
    },
    mounted() {
        USE_NEW_CAROUSEL && this.initCarousel();
        //TODO: 有一定几率出现先执行emit，再执行on的情况，需要做一个判断是否已经符合条件执行却未执行的通用函数
        _voidVm.$on(`autoPlay${this.module.id}`, this.autoPlay);
        _voidVm.$on(`stopAutoPlay${this.module.id}`, this.stopAutoPlay);
    },
    methods: {
        getImgComponetOption,
        photoSlide() {
            if (this.isPhotoSlides) {
                if (this.isMobi) {
                    this.showMobiViewer = true;
                } else {
                    this.showPcViewer = true;
                }
            }
        },
        photoStyle() {
            const style = {};

            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }
            return style;
        },
        photoBackground() {
            return {};
        },
        linkTarget(isBlank) {
            return linkTarget(isBlank);
        },
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        linkNofollow(nofollow) {
            return this.module.canUseNofollow ? (nofollow ? 'nofollow' : '') : '';
        },
        ...Carousel,
        hovermenu: manageUse.hovermenu || function () {},
    },
};
