//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
    },
};
