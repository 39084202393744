//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';

export default {
    name: 'NewsDetailCss',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        elementAlignCss() {
            let css = '';
            if (this.pcPattern.ea == 1) {
                css += `${this.id} .news_detail_title,  ${this.id} .news_detail_subtitle{ text-align: center; }\n`;
                css += `${this.id} .news_detail_info { justify-content: center;}\n`;
            }
            return css;
        },
    },
};
