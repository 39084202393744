//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jzModule from '@/components/modules/jzModule.vue';
import SectionWebsiteTitle from './system/sectionWebsiteTitle.vue';
import SectionMultiLanguage from './system/multiLanguage/index.vue';
import SectionMemberLogin from './system/sectionMemberLogin/index.vue';
import SectionNav from './system/nav/index.vue';
import SectionFooter from './system/sectionFooter.vue';


export default {
    name: 'Project', //moduleIndex 模块在当前通栏的位置
    components: {
        SectionWebsiteTitle,
        SectionMultiLanguage,
        SectionMemberLogin,
        SectionNav,
        SectionFooter,
    },
    inject: {
        isSysRow: {
            default: false,
        },
        rowNewMobiResponsiveConversion: {
            default: false,
        },
        rowNewResponsiveConversion: {
            default: false,
        },
    },
    props: ['rowid', 'id', 'moduleIndex', 'cloneModuleId'],
    computed: {
        ...Vuex.mapState('manage/editPanel', ['moduleEditorActiveId']),
        ...Vuex.mapState([
            'device',
            'manageMode',
            'newResponsiveConversion',
            'newMobiResponsiveConversion',
            'templateNewResponsiveConversion',
            'templateNewMobiResponsiveConversion',
        ]),
        ...Vuex.mapGetters(['info']),
        isSection() {
            return this.id >= 150 && this.id < 200;
        },
        curCompon() {
            if (this.isSection) {
                switch (this.id) {
                    //导航
                    case 150:
                        return 'sectionNav';
                    // 会员登录部件
                    case 151:
                        return 'sectionMemberLogin';
                    // 底部部件
                    case 152:
                        return 'sectionFooter';
                    // 网站标题
                    case 153:
                        return 'sectionWebsiteTitle';
                    // 多语言组件
                    case 154:
                        if (!this.$store.state.sections.multiLanguage.showLan) {
                            return;
                        }
                        return 'sectionMultiLanguage';
                }
            }
            return jzModule;
        },
        moduleId() {
            return this.cloneModuleId !== undefined ? parseInt(this.id + this.cloneModuleId) : this.id;
        },

        isMobi() {
            return this.device == 'mobi';
        },

        wrapClasses() {
            const classes = {};
            classes[`jz_project_${this.id}`] = true;
            classes['jz_project__edit'] = this.manageMode && this.moduleEditorActiveId === this.projectid;

            return classes;
        },
        modules() {
            return this.$store.state.modules;
        },
        margin() {
            switch (this.curCompon) {
                case 'sectionNav':
                    return this.info.pattern.nav;
                case 'sectionMultiLanguage':
                    return this.info.pattern.multiLanguage;
                case 'sectionMemberLogin':
                    return this.info.pattern.memberLogin;
                case 'sectionFooter':
                    return {}; //不注册
                case 'sectionWebsiteTitle':
                    return this.info.pattern.title;
                case jzModule:
                    return this.modules['module' + this.id].pattern[this.device];
                default:
                    //可能是开过多语言后又关闭的站点。
                    return {};
            }
        },
        isNewMobiResponsiveMargin() {
            return (
                this.newMobiResponsiveConversion ||
                (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                this.rowNewMobiResponsiveConversion
            );
        },
        isNewPcResponsiveMargin() {
            return (
                this.newResponsiveConversion ||
                (this.isSysRow && this.templateNewResponsiveConversion) ||
                this.rowNewResponsiveConversion
            );
        },
        wrapMarginStyle() {
            if (!this.manageMode) {
                return {};
            }
            //这里只设置pc的margin，因为控件在mobi状态下不包含margin，
            //模块margin交由模块内部自身实现
            if (
                !this.curCompon === jzModule ||
                (this.curCompon === jzModule &&
                    (!this.modules[`module${this.id}`] || this.modules[`module${this.id}`].commProp.ls.st !== 0))
            ) {
                return {};
            }
            var margin = this.margin;
            let unit = '%',
                _outerWidth = 1,
                mobiClientWidth = 1;

            if (this.isMobi) {
                if (this.isNewMobiResponsiveMargin) {
                    // 新得边距换算逻辑
                    unit = 'vw';
                    mobiClientWidth = 375;
                    _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                }
            } else {
                if (this.isNewPcResponsiveMargin) {
                    unit = 'vw';
                }
            }

            let _marginTop = `${((margin.mt * mobiClientWidth) / _outerWidth) * 100}${unit}`,
                _marginRight = `${margin.mr * 100}%`,
                _marginBottom = `${((margin.mb * mobiClientWidth) / _outerWidth) * 100}${unit}`,
                _marginLeft = `${margin.ml * 100}%`;
            return {
                marginTop: _marginTop,
                marginRight: _marginRight,
                marginBottom: _marginBottom,
                marginLeft: _marginLeft,
            };
        },
    },
    methods: {
        contextmenus(...args) {
            return moduleContextmenus.apply(this, args);
        },
    },
};
