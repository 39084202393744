//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getComputedTextColor, extend, getStyleDataByDevice } = Comm;
import { warningMessage } from '@/site/shared/dialog/index.js';
import CustomSearchSelect from './customSearchSelect.vue';
import CustomSearchCheckbox from './customSearchCheckbox.vue';
import CustomSeachRadio from './customSeachRadio.vue';
import CustomSearchInput from './customSearchInput.vue';
import { logDog } from '@/site/shared/log/index.js';

export default {
    name: 'UnitCustomSearchEntry',
    components: {
        CustomSearchSelect,
        CustomSearchCheckbox,
        CustomSeachRadio,
        CustomSearchInput,
    },
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        hideTitle: Boolean,
        hideTips: Boolean,
        title: String,
        tips: String,
        searchId: [String, Number],
        disabled: Boolean,
        searchText: String,
        module: Object,
    },
    data() {
        return {
            currentData: [],
        };
    },
    computed: {
        ...Vuex.mapState(['openMsgSearch', 'LS']),
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        titleTextStyle() {
            if (!this.module) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.st,
                mobiData: this.module.privatePattern.mobi.st,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        tipsTextStyle() {
            if (!this.module) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.se,
                mobiData: this.module.privatePattern.mobi.se,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        queryTextStyle() {
            if (!this.module) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.sif,
                mobiData: this.module.privatePattern.mobi.sif,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        inputTextStyle() {
            if (!this.module) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.if,
                mobiData: this.module.privatePattern.mobi.if,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
    },
    watch: {
        data: {
            deep: true,
            immediate: true,
            handler() {
                this.currentData = this.data.map((item) => {
                    const currentItem = this.currentData.find(({ id }) => id == item.id);
                    return {
                        ...item,
                        value: currentItem ? currentItem.value : '',
                    };
                });
            },
        },
    },
    methods: {
        onSubmit() {
            if (!this.openMsgSearch) {
                //用户关闭了查询功能
                Fai.ing('功能不可用，如需继续使用请联系网站管理员', true);
                return;
            }
            if (this.disabled) {
                warningMessage(this.LS.js_customSearchNoOpen, true);
                return;
            }

            logDog(201006, 2);

            const params = [];

            params.push('id=' + this.searchId);

            const condition = [];
            for (const item of this.currentData) {
                const { type, isRequired, id, itemName, value } = item;
                let _value = '';
                let isEmptyValue = false;

                switch (type) {
                    case 0:
                        _value = value;
                        if (!value) {
                            if (isRequired) {
                                warningMessage(
                                    Fai.format(this.LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(itemName)),
                                    true
                                );
                                return false;
                            } else {
                                isEmptyValue = true;
                            }
                        }
                        break;
                    case 1:
                        _value = value;
                        if (!value) {
                            if (isRequired) {
                                warningMessage(
                                    Fai.format(this.LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(itemName)),
                                    true
                                );
                                return false;
                            } else {
                                isEmptyValue = true;
                            }
                        }
                        break;
                    case 2:
                        if (value.length < 1) {
                            if (isRequired) {
                                warningMessage(
                                    Fai.format(this.LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(itemName)),
                                    true
                                );
                                return false;
                            } else {
                                isEmptyValue = true;
                            }
                        }
                        _value = value.join('\n');
                        break;
                    case 3:
                        _value = value;
                        if (value == 'none' || !value) {
                            if (isRequired) {
                                warningMessage(
                                    Fai.format(this.LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(itemName)),
                                    true
                                );
                                return false;
                            } else {
                                isEmptyValue = true;
                            }
                        }
                        break;
                }

                if (!isEmptyValue) {
                    condition.push({
                        type,
                        prop: `prop${id}`,
                        value: _value,
                    });
                }
            }
            params.push('condition' + '=' + Fai.encodeUrl($.toJSON(condition)));

            window.location.href =
                Site.preprocessUrl({ path: '', oldPath: '', useNew: true }) + `csr.jsp?${params.join('&')}`;
        },
    },
};
