//
//
//
//
//
//
//
//

export default {
    name: 'TimelineArrow',
    inject: ['module'],
    props: {
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['prev', 'next'].includes(value);
            },
        },
    },
    computed: {
        pattern() {
            return this.module.privatePattern.pc;
        },
        style() {
            const styles = {};
            const { nc = {} } = this.pattern;
            if (nc.y === 1) {
                styles.color = nc.c;
                styles.borderColor = nc.c;
            }
            return styles;
        },
    },
};
