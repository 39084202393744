//
//
//
//

import sectionMultiLanguageFull from './sectionMultiLanguageFull.vue';
import sectionMultiLanguageCore from './sectionMultiLanguageCore.vue';

export default {
    name: 'sectionMultiLanguage',
    components: {
        sectionMultiLanguageFull,
        sectionMultiLanguageCore,
    },
    props: {
        useCore: Boolean,
    },
    computed: {
        activeComponent() {
            return this.useCore ? 'sectionMultiLanguageCore' : 'sectionMultiLanguageFull';
        },
    },
};
