var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PopupLayerComponent",
    {
      attrs: { value: _vm.value, "is-link-to-form": _vm.isLinkToForm },
      on: { input: _vm.onShowInput },
    },
    [
      _c("div", { staticClass: "p_mobi_picker" }, [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "btn cancel", on: { click: _vm.mobiCancel } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn confirm", on: { click: _vm.mobiConfirm } },
            [_vm._v("确定")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          {
            ref: "list",
            staticClass: "list",
            on: { touchstart: _vm.onTouchStart, mousedown: _vm.onTouchStart },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "li",
              { key: index, staticClass: "item" },
              [_vm._t("default", null, { item: item })],
              2
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }