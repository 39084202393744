//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getComputedTextColor, getFirstGradientColor } = Comm;
import { CountUp } from 'countup.js';
import { createLinkWithSideEffect } from '../jumpLink';
import { debounce } from '@jz/utils';

const MAX_NUMBER = 99999999999;

export default {
    name: 'DynamicNumber',
    style: 35,
    props: ['module'],
    data() {
        return {
            CountUpInstance: null,
            intersectionObserver_: null,
            started_: false,
            timer_: null,
            defaultConfig: {
                startVal: '0',
                endVal: '100',
                duration: 0.6,
                timeout: 0.6,
                unitText: '家+',
                bottomText: '示例数值',
                numberFontSize: 48,
            },
            edit: false,
            isIE: false,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        device() {
            return this.$store.state.device;
        },
        content() {
            return this.module.prop1;
        },
        pcPattern() {
            return this.module.privatePattern['pc'];
        },
        mobiPattern() {
            return this.module.privatePattern['mobi'];
        },
        pattern() {
            return this.device === 'mobi' ? this.mobiPattern : this.pcPattern;
        },
        startVal() {
            return Number(this.content.sn) ?? Number(this.defaultConfig.startVal);
        },
        endVal() {
            return Number(this.content.en) ?? Number(this.defaultConfig.endVal);
        },
        unitText() {
            return this.content.ut ?? this.defaultConfig.unitText;
        },
        bottomText() {
            return this.content.bt ?? this.defaultConfig.bottomText;
        },
        timeout() {
            return this.pcPattern.dt ?? this.defaultConfig.timeout;
        },
        duration() {
            return this.pcPattern.kt ?? this.defaultConfig.duration;
        },
        link() {
            const { url = '' } = this.content.link;
            this.$nextTick(() => {
                createLinkWithSideEffect(url, this.$refs['linkRef']);
            });
            if (url.indexOf('Site.hoverQrCode(') === -1) {
                return url === '' ? undefined : url;
            } else {
                return 'javascript: void 0';
            }
        },
        linkTarget() {
            const isBlank =
                this.link !== 'javascript: void 0' &&
                this.content.link &&
                this.content.link.url &&
                this.content.link.ib; // 地址不存在 或者 不能跳转的 不新标签打开
            return isBlank ? '_blank' : '';
        },
        linkNofollow() {
            return this.content.link.nf ? 'nofollow' : '';
        },
        numberCountTextStyle() {
            const pattern = this.mobiPattern.nts.y === 0 ? this.pcPattern : this.pattern;
            if (pattern.nts.y === 0) {
                return {
                    fontSize: this.defaultConfig.numberFontSize + 'px',
                };
            }
            return {
                ...this.createTextStyle(pattern.nts),
                fontSize: this.mobiPattern.nts.y === 0 ? `${pattern.nts.s}px` : `${pattern.nts.s}px !important`,
            };
        },
        numberUnitTextStyle() {
            const pattern = this.mobiPattern.uts.y === 0 ? this.pcPattern : this.pattern;
            return this.createTextStyle(pattern.uts);
        },
        numberBottomTextStyle() {
            const pattern = this.mobiPattern.bts.y === 0 ? this.pcPattern : this.pattern;
            return this.createTextStyle(pattern.bts);
        },
        startValFormatted() {
            return this.startVal.toLocaleString();
        },
        hideSeparator() {
            return this.pcPattern.hs ?? false;
        },
        isMobi() {
            return this.device == 'mobi';
        },
        alginStyle() {
            return {
                'text-align': [undefined, 'left', 'center'][
                    this.isMobi && this.module.privatePattern['mobi'].al === 0 ? this.pcPattern.al : this.pattern.al
                ],
            };
        },
    },
    watch: {
        'content.en'() {
            this._start();
        },
        'pcPattern.hs'() {
            this._start();
        },
    },
    methods: {
        createCountUp() {
            const dynamicNumberEl = this.$refs['dynamicNumberCountEl'];
            this.CountUpInstance = new CountUp(dynamicNumberEl, this.endVal, {
                startVal: this.startVal,
                duration: this.duration,
                useGrouping: !this.hideSeparator,
            });
            return {
                start: () => {
                    if (!this.CountUpInstance.error) {
                        this.CountUpInstance.start();
                    } else {
                        console.error(this.CountUpInstance.error);
                    }
                },
            };
        },
        initEventListeners() {
            // startAnimation
            window.addEventListener('ModuleDynamicNumber.startAnimation', (e) => {
                const { moduleId } = e.detail;
                if (this.module.id === moduleId) {
                    this._start();
                }
            });
            window.addEventListener('ModuleDynamicNumber.resetAnimation', (e) => {
                const { moduleId } = e.detail;
                if (this.module.id === moduleId) {
                    this._reset();
                }
            });
        },
        _reset() {
            if (this.CountUpInstance) {
                this.CountUpInstance.reset();
                if (this.timer_) {
                    clearTimeout(this.timer_);
                }
            }
        },
        _start() {
            const instance = this.createCountUp();
            if (this.timeout > 0) {
                this.timer_ = setTimeout(() => {
                    instance.start();
                    clearTimeout(this.timer_);
                }, this.timeout * 1000);
            } else {
                instance.start();
            }
        },
        start() {
            if (this.started_ === true) return;
            this._start();
            this.started_ = true;
        },
        lazyStartInView() {
            const dynamicNumberEl = this.$refs['dynamicNumberCountEl'];
            this.intersectionObserver_ = new IntersectionObserver((entries) => {
                if (entries[0].intersectionRatio <= 0) return;
                this.start();
            });
            this.intersectionObserver_.observe(dynamicNumberEl);
        },
        createTextStyle(prop) {
            const {
                s: fontSize,
                c: color,
                f: fontFamily,
                w: fontBold,
                d: fontDecoration,
                i: fontItalic,
                y: isCustom,
            } = prop;
            if (Boolean(isCustom) === false) {
                return {};
            }
            return {
                fontSize: `${fontSize}px`,
                ...this.getComputedTextColor(color),
                fontFamily,
                fontWeight: fontBold === 0 ? 'normal' : 'bold',
                textDecoration: fontDecoration === 0 ? 'none' : 'underline',
                fontStyle: fontItalic === 0 ? 'normal' : 'italic',
            };
        },
        getComputedTextColor(color) {
            if (color) {
                if (color.indexOf('linear-gradient') > -1) {
                    if (this.isIE) {
                        return {
                            color: getFirstGradientColor(color),
                        };
                    } else {
                        return getComputedTextColor(color);
                    }
                } else {
                    return getComputedTextColor(color);
                }
            }
        },
        changeEndNumber: debounce(function () {
            const el = this.$refs['dynamicNumberCountEl'];
            const endNumber = this.getNumberFromText(el.innerText);
            if (!Number.isNaN(endNumber)) {
                if (endNumber > MAX_NUMBER) {
                    Vue.$famessage.warning(`结束数字不能大于${MAX_NUMBER}`);
                    this.content.en = String(MAX_NUMBER);
                    $(el).text(this.CountUpInstance.formatNumber(MAX_NUMBER));
                } else {
                    this.content.en = String(endNumber);
                }
            } else {
                Vue.$famessage.warning('结束数字必须是数字类型');
                this.content.en = String(this.endVal);
                $(el).text(this.CountUpInstance.formatNumber(this.endVal));
            }
        }, 500 /* default debounce time */),
        getNumberFromText(text = '') {
            if (text.length > 3) {
                const arr = text.split(',');
                if (arr[0].startsWith('0')) return Number.NaN;
                for (let i = 1; i < arr.length; i++) {
                    if (arr[i].length < 3) {
                        return Number.NaN;
                    }
                }
                return Number(arr.join(''));
            } else {
                return Number(text);
            }
        },
    },
    beforeDestroy() {
        this.intersectionObserver_ && this.intersectionObserver_.disconnect();
    },
    mounted() {
        const ifNotInFullNavRow =
            $(`#module${this.module.id}`).parents('.jz_full_nav_wrap').length === 0 ||
            $(`#module${this.module.id}`).parents('.jz_full_nav_wrap_active').length > 0;
        if (window.requestIdleCallback) {
            window.requestIdleCallback(() => {
                ifNotInFullNavRow && this.lazyStartInView();
            });
        } else {
            ifNotInFullNavRow && this.lazyStartInView();
        }
        this.initEventListeners();
        if (Fai.isIE()) {
            this.isIE = true;
        }
    },
};
