var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMobiViewer || _vm.showPcViewer
    ? _c(
        "div",
        { staticClass: "image-main-viewer" },
        [
          _vm.showMobiViewer
            ? _c("image-viewer", {
                attrs: {
                  "z-index": _vm.zIndex,
                  "initial-index": _vm.initialIndex,
                  "on-close": _vm.onClose,
                  "on-log": _vm.onLog,
                  "log-event-config": _vm.logEventConfig,
                  "url-list": _vm.urlList,
                  "append-to-body": !_vm.manageMode,
                  "append-to-container-id": _vm.appendToContainerId,
                  "is-mobi": _vm.isMobi,
                  "manage-mode": _vm.manageMode,
                  "disable-long-press": _vm.contextMenuDisabled,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showPcViewer
            ? _c("image-viewer", {
                attrs: {
                  "z-index": _vm.zIndex,
                  "initial-index": _vm.initialIndex,
                  "on-log": _vm.onLog,
                  "log-event-config": _vm.logEventConfig,
                  "on-close": _vm.onClose,
                  "url-list": _vm.urlList,
                  "append-to-body": true,
                  "is-mobi": _vm.isMobi,
                  "manage-mode": _vm.manageMode,
                  "disable-long-press": _vm.contextMenuDisabled,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }