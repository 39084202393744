var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.wrapComponent, {
    key: _vm.row.id,
    tag: "component",
    attrs: { row: _vm.row, index: _vm.index, "rows-length": _vm.rowsLength },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }