//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../../utils';
const { format } = Comm;
const ltSvg = {
    template:
        '<svg viewBox="0 0 16 16"><path d="M7.21,8.5l3.64-3.65a.48.48,0,0,0,0-.7.48.48,0,0,0-.7,0l-4,4a.48.48,0,0,0,0,.7l4,4a.5.5,0,1,0,.7-.7Z" fill="currentColor"/></svg>',
};
const gtSvg = {
    template:
        '<svg viewBox="0 0 16 16"><path d="M10.85,8.15l-4-4a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7L9.79,8.5,6.15,12.15a.5.5,0,1,0,.7.7l4-4a.48.48,0,0,0,0-.7Z" fill="currentColor"/></svg>',
};
const bottomSvg = {
    template:
        '<svg viewBox="0 0 16 16"><path d="M7.5,9.79,3.85,6.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7l4,4a.48.48,0,0,0,.7,0l4-4a.5.5,0,1,0-.7-.7Z" fill="currentColor"/></svg>',
};
export default {
    name: 'PaginationComponent',
    components: { ltSvg, gtSvg, bottomSvg },
    props: {
        moduleId: {
            type: [Number, String],
            required: true,
        },
        pageCount: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        styleIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            jumpPageValue: '',
            vistorDevice: 'pc',

            style2PrevFocus: false,
            style2NextFocus: false,
            moreBtnFocus: false,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'responsiveInfo']),

        themeColor() {
            return this.responsiveInfo.themeColor;
        },

        multiLanguage() {
            const LS = this.$store.state.LS;
            if (LS) {
                return LS;
            }
            return {
                rPaginationNext: '下一页',
                rPaginationMore: '展开更多',
                rPaginationNoMore: '已经到底了~',
                rPaginationConfirm: '确定',
                rPaginationJumpEnd: '页',
                rPaginationPrev: '上一页',
                rPaginationJumpFront: '到第',
                rPaginationToTalPages: '共{0}页',
            };
        },

        totalPagesText() {
            return format(this.multiLanguage.rPaginationToTalPages, this.pageCount);
        },

        isMobi() {
            return this.device == 'mobi';
        },

        pagerList() {
            const count = this.pageCount;
            const currentPage = this.currentPage;
            if (count <= 4) {
                return Array.from(new Array(count), (val, index) => index + 1);
            } else {
                if (currentPage <= 2) {
                    return [1, 2, 3, 'more', this.pageCount];
                } else {
                    if (currentPage == 3 && count >= 6) {
                        return [1, 2, 3, 4, 'more', count];
                    }
                    if (count - currentPage <= 2) {
                        return [1, 'more', count - 2, count - 1, count];
                    } else {
                        return [1, 'more', currentPage - 1, currentPage, currentPage + 1, 'more', count];
                    }
                }
            }
        },

        style2PrevBtnColor() {
            if (this.currentPage == 1) return '#ccc';
            if (this.style2PrevFocus) return this.themeColor;
            return '#1a1a1a';
        },

        style2NextBtnColor() {
            if (this.currentPage == this.pageCount) return '#ccc';
            if (this.style2NextFocus) return this.themeColor;
            return '#1a1a1a';
        },

        moreBtnColor() {
            if (this.moreBtnFocus) return this.themeColor;
            return '#1a1a1a';
        },
    },
    methods: {
        handleMobileInput(event) {
            let value = event.target.value;
            value = value.replace(/\\D/g, '');
            if (value == '') {
                this.jumpPageValue = '';
                return;
            }
            this.jumpPageValue = parseInt(value).toFixed(0);
            if (value < 1) {
                this.jumpPageValue = 1;
            }
            if (value > this.pageCount) {
                this.jumpPageValue = this.pageCount;
            }
        },
        handleJumpPage() {
            if (!this.jumpPageValue) return;
            this.handleNumberClick(+this.jumpPageValue);
        },

        handleNumberClick(clickedPage = -1) {
            if (this.currentPage === clickedPage) {
                return;
            }
            if (clickedPage > this.pageCount) {
                clickedPage = this.pageCount;
            }
            if (clickedPage < 1) {
                clickedPage = 1;
            }
            $.cookie(`module_${this.moduleId}_page`, null, {
                expires: -2,
                path: '/',
                domain: location.host,
            });
            this.$emit('currentChange', clickedPage);
            this.$emit('update:currentPage', clickedPage);
        },

        handlePrevClick() {
            if (this.currentPage == 1) return;
            if (this.currentPage == 2) {
                this.style2PrevFocus = false;
            }
            this.handleNumberClick(this.currentPage - 1);
        },

        handleNextClick() {
            if (this.currentPage == this.pageCount) return;
            if (this.currentPage == this.pageCount - 1) {
                this.style2NextFocus = false;
            }
            this.handleNumberClick(this.currentPage + 1);
        },
    },
};
