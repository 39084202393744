//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../../utils';
const { getComputedTextColor, getStyleDataByDevice, extend, bindFlag } = Comm;
import {
    getSearchHistoryRecord,
    getSearchPupop,
    addSearchHistoryRecord,
    removeAllSearchHistoryRecord,
} from '@/components/modules/shared/siteSearch/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { warningMessage } from '@/site/shared/dialog/index.js';
import textCompon from './textCompon.vue';
import searchBox from './searchBox.vue';

const constant = {
    STYLE: 'prop0',
    SEARCH_TIP: 'prop1',
    STYLE_TYPE: 'prop2',
    RECOMMEND_KEYWORD_LIST: 'prop3',
    SEARCH_TYPE: 'prop4',
    SEARCH_RANGE_LIST: 'prop5',
};

export default {
    name: 'SiteSearchComponent',
    components: {
        textCompon,
        searchBox,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        let historyRecordList = [];
        return {
            keyword: this.value,
            isShowDropDownBox: false,
            historyRecordList,
        };
    },
    watch: {
        device(newVal) {
            if (newVal == 'pc') this.hideSearchPopup();
        },
    },
    methods: {
        handleKeywordChange(value) {
            if (typeof value == 'string') {
                value = value.trim();
            } else {
                value = '';
            }
            this.keyword = value;
            this.$emit('input', value);
            this.$emit('onChange', value);
        },
        handleInputFocus() {
            if (this.device == 'mobi') {
                this.mobiCreatePopup();
                return;
            }
            this.showDropDownBox();
        },
        handleInputBlur() {
            this.hideDropDownBox();
        },
        mobiCreatePopup() {
            const options = {
                manageMode: this.manageMode,
                placeholder: this.searchTip,
                recommendKeyWordList: this.recommendKeyWordList,
                moduleId: this.moduleId,
                isShowHistoryRecord: !this.isHideHistoryRecord,
            };
            const searchPupop = getSearchPupop(options);
            if (searchPupop.created) searchPupop.update(options);
        },
        hideSearchPopup() {
            const options = {
                manageMode: this.manageMode,
                placeholder: this.searchTip,
                recommendKeyWordList: this.recommendKeyWordList,
                moduleId: this.moduleId,
                isShowHistoryRecord: !this.isHideHistoryRecord,
            };
            const searchPupop = getSearchPupop(options);
            searchPupop.hide();
        },
        showDropDownBox() {
            if (this.device == 'mobi') return;
            this.isShowDropDownBox = true;
            $(this.$el)
                .parents('.jz_web_row_line.jz_web_row_line_pc1, .jz_module_style_29, jz_web_row')
                .css('z-index', '899');
            const $dropdownEl = $(this.$el).find('.site_search_dropdown');
            // 判断是否下拉框是否超过页面底部
            const pageHeight = $(document).height();
            const dropdownHeight = parseInt($dropdownEl.css('height')) || 500;
            const bottomDistance = pageHeight - $(this.$el).offset().top - parseInt($(this.$el).css('height'));
            // 下拉框超出页面底部，下拉框与模块距离3px
            if (dropdownHeight + 3 > bottomDistance) {
                $dropdownEl.addClass('position_top');
            }
            $dropdownEl.slideDown(300);
        },
        hideDropDownBox() {
            if (this.device == 'mobi') return;
            this.isShowDropDownBox = false;
            const $dropdownEl = $(this.$el).find('.site_search_dropdown');
            const restoreStyleFn = restoreStyle.bind(this);
            if (!$dropdownEl.length) {
                restoreStyleFn();
                return;
            }
            $dropdownEl.slideUp(300, () => {
                restoreStyleFn();
            });

            function restoreStyle() {
                $(this.$el)
                    .parents('.jz_web_row_line.jz_web_row_line_pc1, .jz_module_style_29, jz_web_row')
                    .css('z-index', '');
                $dropdownEl.removeClass('position_top');
            }
        },
        handleSearch() {
            if (this.keyword.trim() === '') {
                this.toastError(this.LS.searchBoxErrorToast);
                return;
            }
            this.$emit('onSearch', this.moduleId, this.keyword);
            this.addHistoryRecord(this.keyword);
        },
        handleMobiSearch() {
            this.$emit('onMobiSearch');
        },
        toastError(errorMsg) {
            if (errorMsg === '') return;
            if (this.manageMode) {
                Message.warning(errorMsg, true);
            } else {
                warningMessage(errorMsg, true);
            }
        },
        jumpSearchPage(keyword) {
            this.handleKeywordChange(keyword);
            this.handleSearch();
        },
        addHistoryRecord(keyword) {
            addSearchHistoryRecord(keyword, (list) => {
                this.historyRecordList = list;
            });
        },
        clearHistoryRecord() {
            this.historyRecordList = [];
            removeAllSearchHistoryRecord();
        },
        handlerSearchBoxOutside() {
            if (!this.$el.classList.contains('search_result_box')) {
                this.$refs.searchBox.isExpend = false;
            }
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        isHideHistoryRecord: bindFlag(0x2),
        ...Vuex.mapState(['manageMode', 'device', 'LS']),
        styleType() {
            return this.module[constant.STYLE_TYPE];
        },
        styleId() {
            return this.module[constant.STYLE];
        },
        searchTip() {
            return this.module[constant.SEARCH_TIP];
        },
        recommendKeyWordList() {
            return this.module[constant.RECOMMEND_KEYWORD_LIST] || [];
        },
        isMobi() {
            return this.device == 'mobi';
        },
        inputStyle() {
            if (!this.module.privatePattern) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.if,
                mobiData: this.module.privatePattern.mobi.if,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
    },
    mounted() {
        // 延后加载，不然直接放data，会报服务端和客户端节点不一致的警告
        this.historyRecordList = getSearchHistoryRecord();
        if (this.$el.classList.contains('search_result_box')) {
            this.$refs.searchBox.isExpend = true;
            this.$refs.searchBox.isSearchResult = true;
        }
    },
};
