//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message } from '@shared/manage/componMessage/index.js';
import { getCommentInfo as getCommentInfoRequest } from '@api/product/index.js';
import CommentFiveStar from '../commentFiveStar.vue';
import { decodeHtml } from '@/components/utils.js';
export default {
    name: 'ProductComment',
    components: {
        CommentFiveStar,
    },
    props: ['cmd', 'productId', 'moduleId', 'commentLoc'],
    data() {
        return {
            productCommentInfo: {},
            commentList: [],
            hasNoComment: '',
            productCommentCreator: '',
            uploaded: '',
            picFormat: '',
            pd_praiseDegree: '',
            slidePre: '',
            slideNext: '',
            paginationList: [],
            disableStyle: {
                cursor: 'auto',
                color: '#ccc',
            },
            allowStyle: {
                cursor: 'pointer',
            },
            jumpPage: 1,
            notAllowComm: false,
            noticeWord: '',
            isLoading: false,
        };
    },
    computed: {
        ...Vuex.mapState(['LS', 'isMaterialLazyLoad']),
        ls() {
            return this.LS;
        },
        bossReply() {
            return this.LS.bossReply;
        },
        markDegree() {
            return parseInt((this.productCommentInfo.avgScore || 5) * 20);
        },
        scaleBlockStyle() {
            return {
                left: this.markDegree + '%',
            };
        },
    },
    mounted() {
        this.getCommentInfo(1);
    },
    methods: {
        // 换行处理
        encodeHtml(html) {
            var arr = html.split('<br/>');
            return arr.join('<br>');
        },
        encodeContent(content) {
            return Fai.encodeHtml(content);
        },
        getCommentInfo(page = 1) {
            // 如果是处于当前页 或者输入的页数超出总页数  或者小于一   就不需要发送请求了
            if (
                page === this.productCommentInfo.curPage ||
                this.productCommentInfo.totalPage < page ||
                page < 1 ||
                typeof page != 'number'
            ) {
                this.jumpPage = 1;
                return;
            }
            this.getCommentInfoAjax(page);
        },
        getCommentInfoAjax(page, callback) {
            this.isLoading = true;
            getCommentInfoRequest({
                moduleId: this.moduleId,
                productId: this.productId,
                m4pageno: page, // 这个表示评论的页码
            }).then(
                (comments) => {
                    this.isLoading = false;
                    try {
                        // 每次请求后都清空分页的页码
                        this.paginationList = [];
                        this.$emit('changeTotalSize', comments.data.totalSize); // 修正评论数量显示错误的问题

                        var curPage = comments.data.curPage,
                            pageSum = comments.data.totalPage;
                        var viewStart = curPage - 2 <= 0 ? 1 : curPage - 2;
                        var viewEnd = curPage + 2 >= pageSum ? pageSum : curPage + 2;

                        //判断是否应该显示首页
                        if (Math.abs(curPage - 1) > 2 && viewStart != 1) {
                            this.paginationList.push(1);
                        }

                        //判断是否应该显示首页的多页提示 '...'
                        if (viewStart > 2) {
                            this.paginationList.push('...');
                        }

                        //循环显示翻页数字
                        for (var j = viewStart; j <= viewEnd; j++) {
                            this.paginationList.push(j);
                        }

                        //判断是否应该显示末页的多页提示 '...'
                        if (viewEnd < pageSum - 1) {
                            this.paginationList.push('...');
                        }

                        //判断是否应该显示末页
                        if (Math.abs(curPage - pageSum) > 2 && pageSum != viewEnd) {
                            this.paginationList.push(pageSum);
                        }

                        // 改变编辑态下评论的条数显示
                        $('.cur_total_size').html(comments.data.totalSize);

                        callback && callback();
                    } catch (error) {
                        console.log(error);
                        comments = {};
                    }
                    this.productCommentInfo = comments.data || {};
                    this.commentList = this.productCommentInfo.list || [];
                    this.hasNoComment = this.productCommentInfo.hasNoComment || '';
                    this.productCommentCreator = this.productCommentInfo.productCommentCreator || '';
                    this.uploaded = this.productCommentInfo.uploaded || '';
                    this.picFormat = this.productCommentInfo.picFormat || '';
                    this.pd_praiseDegree = this.productCommentInfo.pd_praiseDegree || '';
                    this.slidePre = this.productCommentInfo.slidePre || '';
                    this.slideNext = this.productCommentInfo.slideNext || '';
                    this.notAllowComm = this.productCommentInfo.notAllowComm || false;
                    this.noticeWord = this.productCommentInfo.noticeWord || '';
                },
                () => {
                    this.isLoading = false;
                    Message.warning({
                        message: '网络繁忙，请刷新后重试',
                        autoHide: false,
                    });
                }
            );
        },
        changePage(type) {
            // type表示点击了上一页还是下一页
            let page = this.productCommentInfo.curPage || 1;
            if (type === 'next') {
                this.productCommentInfo.totalPage > page && this.getCommentInfo(page + 1);
            } else if (type === 'prev') {
                page > 1 && this.getCommentInfo(page - 1);
            }
        },
        memberIcon(color) {
            return {
                color,
            };
        },
        decodeHtml,
    },
};
