//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getFontTextCss, getBackgroundCss, getFontColorCss } from '../cssUtils';
const isNewModuleAfter20210707 = (module = {}) => {
    return module?.commProp?.unst ?? false;
};
export const getStyleDataByDevice = ({
    mobiData,
    pcData,
    isMobi,
    typeKey = 'y',
    mobiTypeDefaultValue = 0,
    mobiDefaultIsExtendPc = true,
    // 文字不继承字体大小，其他都继承（例如颜色、斜体会继承）
    isTextNotExtendFontSize = false,
    // 是否是新模块
    isNewModule = true,
}) => {
    if (isMobi) {
        if (typeof mobiData !== 'object') {
            if (mobiDefaultIsExtendPc && mobiData === mobiTypeDefaultValue) {
                return pcData;
            }
            return mobiData;
        }
        if (mobiDefaultIsExtendPc && mobiData[typeKey] === mobiTypeDefaultValue) {
            if (isTextNotExtendFontSize && isNewModule) {
                return Object.assign({}, pcData, { s: '' });
            }
            return pcData;
        }
        return mobiData;
    }
    return pcData;
};
const getBitMemory = function (flag, bit) {
    return (flag & bit) == bit;
};

export default {
    name: 'ProductDetail',
    components: {
        ChildrenCSS,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        moduleId() {
            return this.module.id;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        moduleIdSelector() {
            return `.module${this.moduleId}`;
        },
        mobiPattern() {
            return this.module.privatePattern['mobi'];
        },
        pcPattern() {
            return this.module.privatePattern['pc'];
        },
        style() {
            return this.module.prop0;
        },
        isNewModule() {
            return getBitMemory(this.module.flag, 0x4);
        },
        secondFontStyle() {
            let fontPattern = this.mobiPattern.csf;
            if (!this.isNewModuleAfter20210707 && fontPattern.y === 0) {
                fontPattern = this.mobiPattern.cft;
            }

            if (this.isNewModule && fontPattern.y === 0) {
                fontPattern = this.pcPattern.csf;
            }

            const style = getFontTextCss(fontPattern);

            return ` ${this.moduleIdSelector} .cn_group__itemname { ${style} }`;
        },
        thirdFontStyle() {
            let fontPattern = this.mobiPattern.ctf;
            if (!this.isNewModuleAfter20210707 && fontPattern.y === 0) {
                fontPattern = this.mobiPattern.cft;
            }

            if (this.isNewModule && fontPattern.y === 0) {
                fontPattern = this.pcPattern.ctf;
            }

            const style = getFontTextCss(fontPattern);

            return ` ${this.moduleIdSelector} .cn_group__son { ${style} }`;
        },
        // eslint-disable-next-line vue/return-in-computed-property
        selectBgStyle() {
            if (this.style == 0 || this.style == 2) {
                let cla =
                    this.style == 2
                        ? `#module${this.moduleId} .m_categroup_linkwrap.category_select_item, .jz_screen_pc #module${this.moduleId} .m_categroup_linkwrap:hover`
                        : `#module${this.moduleId} .level1_link_wrap.category_select_item, #module${this.moduleId} .level2_link_wrap.category_select_item, #module${this.moduleId} .level3_link_wrap.category_select_item , .jz_screen_pc #module${this.moduleId} .level1_link_wrap:hover, .jz_screen_pc #module${this.moduleId} .level2_link_wrap:hover, .jz_screen_pc #module${this.moduleId} .level3_link_wrap:hover`;
                return `  ${cla} { ${getBackgroundCss(this.pcPattern.sbg)} }
                #module${this.moduleId} .level3_link_wrap.category_select_item .level3_link { background: none; }
                `;
            }
        },
        linkHoverStyle() {
            let color = '';
            const fontPattern = this.mobiPattern['cft'];
            if (fontPattern.y === 1) {
                color = getFontColorCss(this.fontPattern.hc);
            }

            return color;
        },
        isOneTwoStyle() {
            return [0, 1].includes(this.module.prop0);
        },

        firstLinkStyle() {
            return this.genFontLink(
                'cff',
                this.isOneTwoStyle ? ['.level1_link_wrap', '.level1_link_wrap a'] : ['.m_categroup_item1'],
                this.isMobiDefaultFollowPc
            );
        },
        secondLinkStyle() {
            return this.genFontLink(
                'csf',
                this.isOneTwoStyle ? ['.level2_link'] : ['.m_categroup_item2'],
                this.isMobiDefaultFollowPc
            );
        },
        thirdLinkStyle() {
            return this.genFontLink(
                'ctf',
                this.isOneTwoStyle ? ['.level3_link'] : ['.m_categroup_item3'],
                this.isMobiDefaultFollowPc
            );
        },

        firstLinkHoverStyle() {
            return this.genFontLinkHover(
                'cff',
                this.isOneTwoStyle
                    ? [
                          '.level1_link_wrap.category_select_item .level1_link',
                          '.level1_link_wrap:hover',
                          '.level1_link_wrap a:hover',
                      ]
                    : ['.m_categroup_item1:hover', '.category_select_item .m_categroup_item1'],
                this.isMobiDefaultFollowPc
            );
        },
        secondLinkHoverStyle() {
            return this.genFontLinkHover(
                'csf',
                this.isOneTwoStyle
                    ? ['.level2_link_wrap.category_select_item .level2_link', '.level2_link:hover']
                    : ['.m_categroup_item2:hover', '.category_select_item .m_categroup_item2'],
                this.isMobiDefaultFollowPc
            );
        },
        thirdLinkHoverStyle() {
            return this.genFontLinkHover(
                'ctf',
                this.isOneTwoStyle
                    ? ['.level3_link_wrap.category_select_item .level3_link', '.level3_link:hover']
                    : ['.m_categroup_item3:hover', '.category_select_item .m_categroup_item3'],
                this.isMobiDefaultFollowPc
            );
        },

        moduleRadiusStyle() {
            return this.genmoduleRadius('rt', 'r');
        },
        isMobiDefaultFollowPc() {
            return this.isOneTwoStyle || this.module.isNewModule;
        },
    },
    methods: {
        genmoduleRadius(typeKey, valueKey) {
            let style = '';
            if (this.pcPattern[typeKey]) {
                style += `.jz_screen_pc #module${this.moduleId} {  border-radius: ${this.pcPattern[valueKey]}px }`;
            }
            if (this.mobiPattern[typeKey]) {
                style += `.jz_screen_mobi #module${this.moduleId} {  border-radius: ${this.mobiPattern[valueKey]}px }`;
            }
            return style;
        },
        getFont(key, isMobi, isMobiDefaultFollowPc) {
            let font = isMobiDefaultFollowPc
                ? getStyleDataByDevice({
                      pcData: this.module.privatePattern.pc[key],
                      mobiData: this.module.privatePattern.mobi[key],
                      isMobi: isMobi,
                      isTextNotExtendFontSize: true,
                  })
                : (isMobi ? this.mobiPattern : this.pcPattern)[key];

            if (!this.isNewModuleAfter20210707 && font.y === 0) {
                font = (isMobi ? this.mobiPattern : this.pcPattern)['cft'];
            }
            return font;
        },
        genFontLink(key, selectors = '', isMobiDefaultFollowPc) {
            let css = '';
            selectors.forEach((selector) => {
                css +=
                    `.jz_screen_mobi #module${this.moduleId} ${selector} {${getFontTextCss(
                        this.getFont(key, true, isMobiDefaultFollowPc)
                    )}} ` +
                    `.jz_screen_pc #module${this.moduleId} ${selector} {${getFontTextCss(
                        this.getFont(key, false, isMobiDefaultFollowPc)
                    )}}`;
            });
            return css;
        },
        genFontLinkHover(key, selectors = '', isMobiDefaultFollowPc) {
            let css = '';
            const font = this.getFont(key, false, isMobiDefaultFollowPc);
            selectors.forEach((selector) => {
                if (font.y === 1) {
                    css +=
                        `#module${this.moduleId} ${selector} {  ${getFontColorCss(font.hc)}}` +
                        `.jz_screen_pc.jz_screen_pc .cate_group_rowwrap${
                            this.module.id
                        }  ${selector} {  ${getFontColorCss(font.hc)}}`;
                } else {
                    css +=
                        `#module${this.moduleId} ${selector} {   color: var(--theme-color); }` +
                        `.jz_screen_pc.jz_screen_pc .cate_group_rowwrap${this.module.id}  ${selector} {  color: var(--theme-color); }`;
                }
            });
            return css;
        },
    },
};
