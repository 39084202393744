//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ueHoverChangeImage } from '@shared/visitor/rich/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import { Comm } from '../../utils';
const { createUrlArgsOnclickStr } = Comm;
import { bindUeSlider, ueHoverStyle } from '@/components/modules/shared/ue/index.js';
import { TableResponsive } from '@jz/biz-shared';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
const PAGE_BREAK = '_baidu_page_break_tag_';
const manageUse = {};
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { imgOptsComputed } from '@/components/modules/shared/imgEffect.js';

import HtmlRenderer from '@/components/htmlRenderer/index.vue';

export default {
    name: 'Rich',
    style: 1,
    components: {
        ImageMainViewer,
        PaginationComponent,
        ImageComponent,
        HtmlRenderer,
    },
    mixins: [slidesshowDataMixin],
    props: ['id', 'module'],
    data() {
        return {
            page: 1,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'jzVersion', 'webpOptOpen', 'contextMenuDisabled']),
        ...Vuex.mapGetters(['hashRemoved']),
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-图文展示',
                    jz_version: this.jzVersion,
                },
            };
        },
        previewSrcList() {
            const _previewSrcList = [];
            let _preview = {};
            _preview.src = this.imgSrc;
            _preview.description = this.picDisc;

            _previewSrcList.push(_preview);

            return _previewSrcList;
        },

        imgSrc() {
            return this.module.prop1.path;
        },

        naturalWidth() {
            return this.module.prop1.iw;
        },

        naturalHeight() {
            return this.module.prop1.ih;
        },

        picJumpOnclickStr() {
            const { reqArgs } = this.module.prop1 || {};
            return createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },

        imgsStyle() {
            if (!this.manageMode) return {};
            var style = {};
            style.width = this.module.privatePattern[this.device].pw + '%';
            return style;
        },

        picLink() {
            try {
                return this.isPhotoSlides || this.noLink
                    ? 'javascript: void(0);'
                    : decodeURIComponent(this.module.prop1.url || '');
            } catch (err) {
                return '';
            }
        },

        picTarget() {
            if (this.noLink) {
                return '_self';
            }
            return this.module.prop1.ib && !this.isPhotoSlides ? '_blank' : '_self';
        },

        nofollow() {
            return this.module.canUseNofollow && this.module.prop1.nf ? 'nofollow' : '';
        },

        contentClass() {
            return {
                rich_img_right: this.module.prop0 == 1,
                rich_img_none: this.module.prop0 == 2,
                rich_img_top: this.module.prop0 == 3 && this.module.privatePattern[this.device].pp === 0,
                rich_img_bottom: this.module.prop0 == 3 && this.module.privatePattern[this.device].pp === 1,
            };
        },

        isMobi() {
            return this.device === 'mobi';
        },

        picDisc() {
            return this.module.prop1.d;
        },

        content() {
            const content = !this.isMobi
                ? this.module.content
                : this.module.prop1.mct
                ? this.module.prop3
                : this.module.content;
            return typeof content === 'string' ? content : '';
        },

        onShowContent() {
            // 内容可能具有分页
            if (this.hasContentPage) {
                return this.pageList[this.page - 1] || '';
            }

            return this.content;
        },

        pageList() {
            return this.hasContentPage ? this.content.split(PAGE_BREAK) : [];
        },

        hasContentPage() {
            return this.content.includes(PAGE_BREAK);
        },
    },
    watch: {
        'module.content': function () {
            if (typeof window === 'undefined') return;
            Vue.nextTick(() => {
                ueHoverStyle();
                ueHoverChangeImage();
                this.handleTableResponsive();
            });
        },
        'module.prop1.mct'() {
            if (this.module.prop1.mct && !this.module.prop1.mctc) {
                this.module.prop3 = this.module.content;
            }
        },
        'module.prop3'() {
            this.module.prop1.mctc = true;
        },
    },

    mounted() {
        const $module = $(`#module${this.module.id}`);
        ueHoverStyle();
        ueHoverChangeImage($module);
        setHrefEventHasReqArgs($module);
        this.handleTableResponsive();
        if (!this.manageMode && this.contextMenuDisabled) {
            $module.find('img').attr('draggable', false);
        }
    },

    methods: {
        bindUeSlider(e) {
            bindUeSlider(e, '自适应-图文展示-ue');
        },

        photoSlide() {
            if (this.isPhotoSlides) {
                if (this.isMobi) {
                    this.showMobiViewer = true;
                } else {
                    this.showPcViewer = true;
                }
            }
        },

        handleTableResponsive() {
            TableResponsive.init({
                $tables: $('.module_content_detail table'),
                parentClassName: '.module_content_detail',
            });
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
