import TimelineItemDesc from './components/timelineItemDesc.vue';
import TimelineItemTime from './components/timelineItemTime.vue';
import TimelineItemTitle from './components/timelineItemTitle.vue';
import TimelineItemDot from './components/timelineItemDot.vue';
import TimelineItemLine from './components/timelineItemLine.vue';
import TimelineItemConnectContentLine from './components/timelineItemConnectContentLine.vue';
import TimelineItemImage from './components/timelineItemImage.vue';
import timelineArrow from './components/timelineArrow.vue';

export const timelineMixin = {
    inject: ['module'],
    components: {
        TimelineItemDesc,
        TimelineItemTime,
        TimelineItemTitle,
        TimelineItemDot,
        TimelineItemLine,
        TimelineItemConnectContentLine,
        TimelineItemImage,
        timelineArrow,
    },
    computed: {
        ...Vuex.mapGetters(['isMobi']),
        timelineItems() {
            return this.module.prop1;
        },
    },
};
