//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getComputedBg } from '../../../manage/designer/initContext/utils';
import { banner } from '@/components/grid/banner/index.js';
import { logDog } from '@/site/shared/log/index.js';
import { checkElement } from '@/site/shared/index.js';
import { editRow } from '@/manage/grid/row/rowManage/index.js';
import { fullScreenMixin } from './fullScreenMixin.js';
import { initCarouselRowsEffects } from '@shared/visitor/carouselRowsEffects/index.js';
import { Comm } from '../../utils.js';
import JzWebCol from './jzWebCol.vue';
import RowVideoBg from './RowVideoBg.vue';

const manageComponents = {};

export default {
    name: 'jz-web-row',
    components: {
        JzWebCol,
        RowVideoBg,
        ...manageComponents,
    },
    mixins: [fullScreenMixin],

    provide() {
        return {
            isSysRow: this.isSysRow,
            rowNewResponsiveConversion: this.rowNewResponsiveConversion,
            rowNewMobiResponsiveConversion: this.rowNewMobiResponsiveConversion,
            isCarouselRow: this.isCarouselRow,
        };
    },
    props: [
        'row',
        'hideToolBar', //工具条中使用，误删
        'index',
        'cloneModuleId',
        'fullInnerInfo',
        'hideMaterialLine',
    ],

    data() {
        return {
            rowParentWidth: 0,
            border: null,
            activeRow: false,
            // 当前屏
            rowOutAnimate: false,
            //上一个通栏出场动画
            timerFlag: true,
            // 控制动画是否走入初始类的锁  因为动画结束后需要移除类 不能再赋值上原本的类
            rowInAnimate: false,
            carouselRowsEffectController: null,
            isRowBgLazy: true, //背景是否懒加载

            rowInstance: null,
        };
    },
    computed: {
        ...Vuex.mapState([
            'fullScreen',
            'manageMode',
            'newResponsiveConversion',
            'newMobiResponsiveConversion',
            'templateNewResponsiveConversion',
            'templateNewMobiResponsiveConversion',
            'isShowSetBar',
            'isMaterialLazyLoad',
        ]),
        ...Vuex.mapState('row', ['rowEditing', 'rowEditingActive', 'showMobiHideRow']),
        ...Vuex.mapState('footer', ['footerStyleStatus']),
        isShowVideoBg() {
            return this.$store.getters.isShowVideoBg(this.row);
        },
        rowNewResponsiveConversion: {
            get() {
                return Comm.getBitMemory(this.row.flag, 0x1);
            },

            set(val) {
                this.row.flag = Comm.setBitMemory(this.row.flag, 0x1, val);
            },
        },
        rowNewMobiResponsiveConversion: {
            get() {
                return Comm.getBitMemory(this.row.flag, 0x2);
            },

            set(val) {
                this.row.flag = Comm.setBitMemory(this.row.flag, 0x2, val);
            },
        },

        pattern() {
            return this.row.pattern[this.device];
        },

        patternEffect() {
            return this.pattern?.e ?? {};
        },

        pcPatternEffect() {
            return this.row.pattern['pc']?.e ?? {};
        },

        carouselList() {
            if (this.pcPatternEffect?.rbs.length === 1) {
                return this.pcPatternEffect?.rbs;
            }

            const realListLastIndex = this.pcPatternEffect?.rbs.length - 1;
            var displayList = this.pcPatternEffect?.rbs.slice();
            displayList.push(this.pcPatternEffect?.rbs[0]);
            displayList.unshift(this.pcPatternEffect?.rbs[realListLastIndex]);
            displayList.forEach((value, index, array) => {
                // 处理对应的真实数据的索引
                if (index == 0) {
                    value.carouselIndex = realListLastIndex;
                } else if (index == array.length - 1) {
                    value.carouselIndex = 0;
                } else {
                    value.carouselIndex = index - 1;
                }
            });
            return displayList;
        },
        localRowEditing() {
            return this.rowEditing == this.row.id;
        },
        fullScreenInnerInfo() {
            return this.fullInnerInfo || {};
        },

        lineClasses() {
            var pattern = this.row.pattern,
                _class = {},
                pc = pattern.pc;
            _class['jz_web_row_line_pc1'] = pc.l == 1;
            _class['jz_web_row--editing'] = this.localRowEditing;
            // 固定背景类名
            _class['jz_web_row_effect_bg'] = this.showEffectBg && this.isOpenAdaptWidthFullBg;
            _class['jz_web_row_line--carousel'] = this.isCarouselRow; // 轮播通栏

            // 垂直对齐方式
            if (this.$store.getters.isRowOpenVerticalAlign(this.row)) {
                const alignClass =
                    {
                        1: 'jz_web_row_line--colAlignStart',
                        2: 'jz_web_row_line--colAlignCenter',
                        3: 'jz_web_row_line--colAlignEnd',
                    }[this.row.pattern.pc.ai] || '';
                if (alignClass) {
                    _class[alignClass] = true;
                    _class['jz_web_row_line--colAlign'] = true;
                }
            }

            this.showEffectBg && this.isOpenAdaptWidthFullBg && Comm.extend(_class, this.effectBgClass);
            return _class;
        },

        contentClass() {
            const _class = {};
            Object.assign(_class, this.fullScreenInnerInfo.contentClass);
            _class['jz_web_row__content--fullNav'] = this.row.type === 6;
            return _class;
        },
        isSectionRow() {
            return this.row.type > 2 && !this.isFullSiteRow && this.row.type != 7;
        },
        isFullSiteRow() {
            return this.$store.getters.isFullSiteRow(this.row.id);
        },
        isModuleSysRow() {
            return this.row.type == 2;
        },
        classes() {
            let colsLen = (this.row.cols && this.row.cols.length) || 1,
                pattern = this.row.pattern,
                mobi = pattern.mobi;
            const _class = {
                ['jz_web_row' + colsLen]: true,
                jz_section_row: this.isSectionRow,
                jz_module_row: this.row.type <= 2,
                jz_module_sys_row: this.isModuleSysRow,
                jz_freelayout_row: this.row.type == 7,
                ['jz_web_row_mobi' + mobi.l]: mobi.l,
                jz_web_row_edit: this.isEdit,
                // 全站通栏类 管理态有就行 主要是针对拖拽模块占位的特殊提示样式
                jz_web_row_full_site: this.isFullSiteRow,
                'jz_web_row--carousel': this.isCarouselRow,
                // 轮播通栏
                jz_web_row_effect_bg: this.showEffectBg && !this.isOpenAdaptWidthFullBg, // 固定背景类名
            }; // 固定背景类名

            this.showEffectBg && !this.isOpenAdaptWidthFullBg && Comm.extend(_class, this.effectBgClass);
            return _class;
        },

        isEdit() {
            return this.rowEditingActive == this.row.id;
        },

        isOpenAdaptWidth() {
            const { wt: widthType = 0 } = this.row.pattern.pc;
            const { io: isOpen = 0 } = this.info.pattern.adaptWidth;
            return widthType == 1 && isOpen == 1 && !this.isMobi;
        },

        isAdaptWidthFullBg() {
            return this.row.pattern.pc.ab === 0;
        },

        rowLineStyle() {
            let style = {};
            var device = this.device,
                pattern = this.row.pattern;

            if (this.isCarouselRow) {
                if (this.showEffectBg) {
                    if (this.isOpenAdaptWidthFullBg && !this.isShowVideoBg) {
                        Comm.extend(style, this.bgStyle);
                    }
                }
                return style;
            } else {
                if (this.isOpenAdaptWidthFullBg) {
                    let pcShadowType = pattern.pc.st,
                        mobiShadowType = pattern.mobi.st,
                        shadow = device == 'mobi' ? mobiShadowType : pcShadowType,
                        pcBorder = pattern.pc.b,
                        mobiBorder = pattern.mobi.b,
                        border = Comm.getDisplayData(device, pcBorder, mobiBorder),
                        borderStyleList = ['solid', 'dotted', 'dashed'];
                    if (shadow == 1) {
                        style.boxShadow = '0 0 12px 0 rgba(0,0,0,0.15)';
                    }
                    if (border.y == 1) {
                        style.border = 'none';
                    } else if (border.y == 2) {
                        let borderStr = border.w + 'px ' + borderStyleList[border.s] + ' ' + border.c;
                        border.t && (style.borderTop = borderStr);
                        border.r && (style.borderRight = borderStr);
                        border.b && (style.borderBottom = borderStr);
                        border.l && (style.borderLeft = borderStr);
                    }
                }
            }

            if (this.isOpenAdaptWidthFullBg && !this.isShowVideoBg) {
                Comm.extend(style, this.bgStyle);
            }

            return style;
        },

        bgStyle() {
            const device = this.device;
            const pattern = this.row.pattern;
            const mobiBg = pattern.mobi.rb;
            const pcBg = pattern.pc.rb;
            const bg = Comm.getDisplayData(device, pcBg, mobiBg);
            let bgStyle = getComputedBg(bg, this.isRowBgLazy);
            if (this.isRowBgLazy) {
                this.isRowBgLazy = false;
            }
            return bgStyle;
        },

        carouselWrapStyle() {
            var device = this.device,
                pattern = this.row.pattern,
                pcBorder = pattern.pc.b,
                mobiBorder = pattern.mobi.b,
                pcShadowType = pattern.pc.st,
                mobiShadowType = pattern.mobi.st,
                border = Comm.getDisplayData(device, pcBorder, mobiBorder),
                shadow = Comm.getDisplayData(device, pcShadowType, mobiShadowType),
                borderStyleList = ['solid', 'dotted', 'dashed'],
                style = {};

            if (shadow == 1) {
                style.boxShadow = '0 0 12px 0 rgba(0,0,0,0.15)';
            }

            if (border.y == 1) {
                style.border = 'none';
            } else if (border.y == 2) {
                let borderStr = border.w + 'px ' + borderStyleList[border.s] + ' ' + border.c;
                border.t && (style.borderTop = borderStr);
                border.r && (style.borderRight = borderStr);
                border.b && (style.borderBottom = borderStr);
                border.l && (style.borderLeft = borderStr);
            }

            return style;
        },

        rowDefaultStyle() {
            //如果mobi默认，就取pc的数据
            var device = this.device,
                pattern = this.row.pattern,
                pcBorder = pattern.pc.b,
                mobiBorder = pattern.mobi.b,
                pcShadowType = pattern.pc.st,
                mobiShadowType = pattern.mobi.st,
                border = Comm.getDisplayData(device, pcBorder, mobiBorder),
                shadow = device == 'mobi' ? mobiShadowType : pcShadowType,
                borderStyleList = ['solid', 'dotted', 'dashed'],
                style = {},
                unit = '%',
                mobiClientWidth = 1,
                _outerWidth = 1;
            if (this.isMobi) {
                if (
                    this.newMobiResponsiveConversion ||
                    (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                    this.rowNewMobiResponsiveConversion
                ) {
                    !this.rowNewMobiResponsiveConversion && (this.rowNewMobiResponsiveConversion = true); //  新得边距换算逻辑

                    unit = 'vw';
                    mobiClientWidth = 375;
                    if (this.$isServer) {
                        // 服务端拿不到宽度，所以按照375来计算
                        _outerWidth = 375;
                    } else {
                        _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                    }
                }
            } else {
                if (
                    this.newResponsiveConversion ||
                    (this.isSysRow && this.templateNewResponsiveConversion) ||
                    this.rowNewResponsiveConversion
                ) {
                    !this.rowNewResponsiveConversion && (this.rowNewResponsiveConversion = true);
                    unit = 'vw';
                }
            }
            if (!this.isCarouselRow && !this.isOpenAdaptWidthFullBg) {
                if (shadow == 1) {
                    style.boxShadow = '0 0 12px 0 rgba(0,0,0,0.15)';
                }

                if (border.y == 1) {
                    style.border = 'none';
                } else if (border.y == 2) {
                    let borderStr = border.w + 'px ' + borderStyleList[border.s] + ' ' + border.c;
                    border.t && (style.borderTop = borderStr);
                    border.r && (style.borderRight = borderStr);
                    border.b && (style.borderBottom = borderStr);
                    border.l && (style.borderLeft = borderStr);
                }
            }

            if (this.isWebContentRow) {
                style.paddingRight = `${pattern[device].pr * 100}%`;
                style.paddingLeft = `${pattern[device].pl * 100}%`;
            } else {
                style.paddingRight = `${pattern[device].pr * 100}%`;
                style.paddingLeft = `${pattern[device].pl * 100}%`;
                style.paddingTop = `${((pattern[device].pt * mobiClientWidth) / _outerWidth) * 100}${unit}`;
                style.paddingBottom = `${((pattern[device].pb * mobiClientWidth) / _outerWidth) * 100}${unit}`;
            }

            if (this.isOpenAdaptWidth) {
                // 适应宽度用自己的padding数据
                if (this.isWebContentRow) {
                    style.paddingRight = `${pattern[device].awpr * 100}%`;
                    style.paddingLeft = `${pattern[device].awpl * 100}%`;
                } else {
                    style.paddingRight = `${pattern[device].awpr * 100}%`;
                    style.paddingLeft = `${pattern[device].awpl * 100}%`;
                    style.paddingTop = `${((pattern[device].awpt * mobiClientWidth) / _outerWidth) * 100}${unit}`;
                    style.paddingBottom = `${((pattern[device].awpb * mobiClientWidth) / _outerWidth) * 100}${unit}`;
                }
            }

            return style;
        },

        isOpenAdaptWidthFullBg() {
            return this.isOpenAdaptWidth && this.isAdaptWidthFullBg && !this.isMobi;
        },
        isWebRowBg() {
            return !this.isOpenAdaptWidthFullBg;
        },
        rowBgImgSrc() {
            const device = this.device;
            const pattern = this.row.pattern;
            const mobiBg = pattern.mobi.rb;
            const pcBg = pattern.pc.rb;
            const bg = Comm.getDisplayData(device, pcBg, mobiBg);
            return bg.y != 2 || bg.d <= 1 || (bg.d === 3 && bg.tc) ? '' : bg.path;
        },
        rowStyle() {
            if (!this.manageMode) {
                return;
            }
            const style = {};
            const { w: width, wo: widthOption } = this.info.pattern.adaptWidth;
            const bgStyle = this.bgStyle;

            if (this.isCarouselRow) {
                if (
                    (this.isOpenAdaptWidth && !this.isAdaptWidthFullBg) ||
                    (this.isCarouselFixedBg && this.isOpenAdaptWidth && this.isAdaptWidthFullBg)
                ) {
                    const value = {
                        0: 960,
                        1: 1200,
                        2: width,
                    }[widthOption];
                    style.maxWidth = `${value}px`;
                }

                if (this.isCarouselFixedBg) {
                    if (!this.isOpenAdaptWidthFullBg && !this.isShowVideoBg) {
                        Comm.extend(style, bgStyle);
                    }
                }

                return style;
            }

            if (this.isOpenAdaptWidth) {
                const value = {
                    0: 960,
                    1: 1200,
                    2: width,
                }[widthOption];
                style.maxWidth = `${value}px`;
            }
            if (!this.isShowVideoBg) {
                // 开启视频背景，不需要图片背景的样式了
                if (!this.isOpenAdaptWidthFullBg) {
                    Comm.extend(style, bgStyle);
                }
            }
            Comm.extend(style, this.rowDefaultStyle);
            return style;
        },

        hidden() {
            if (this.showMobiHideRow) return false;
            return this.device === 'mobi' && this.row.pattern.mobi.mh;
        },

        isWebContentRow() {
            return this.info.rowIds.includes(this.row.id) && this.isFullScreen;
        },

        banner() {
            return this.info.banner;
        },

        hasHeader() {
            return !this.info.pattern.header.display;
        },

        headerHeight() {
            return this.hasHeader ? this.headerStyleStatus.headerHeight : 0;
        },

        rowContentStyle() {
            let _style = Object.assign({}, this.fullScreenInnerInfo.rowContentStyle);

            if (this.isCarouselRow && !this.isCarouselFixedBg) {
                // 轮播通栏需要将 背景 适应宽度设置到jz_web_row__content
                if (this.isOpenAdaptWidth && this.isAdaptWidthFullBg) {
                    const { w: width, wo: widthOption } = this.info.pattern.adaptWidth;
                    const value = {
                        0: 960,
                        1: 1200,
                        2: width,
                    }[widthOption];
                    _style.maxWidth = `${value}px`;
                }
            } else {
                // 不是轮播通栏则不需要这个样式
                _style.maxWidth = '';
            }

            return _style;
        },

        // 是否嵌套的行
        isNestRow() {
            return this.$store.getters.isNestRow(this.row.id);
        },
        // isSysRow 是否是系统底部顶部里的行
        isSysRow() {
            return this.$store.getters.isSysRow(this.row.id);
        },

        // 是否允许设置效果的通栏
        isEffectRow() {
            return this.$store.getters.isEffectRow(this.row.id);
        },

        isCarouselRow() {
            return this.$store.getters.isCarouselRow(this.row.id);
        },

        isMobiDefaultEffect() {
            return this.isMobi && this.patternEffect.t == 0;
        },

        rowEffectType() {
            // mobi 通栏效果 默认跟随pc
            return this.isMobiDefaultEffect ? this.pcPatternEffect.t : this.patternEffect.t;
        },

        rowEffectSpeed() {
            return this.isMobiDefaultEffect ? this.pcPatternEffect.s : this.patternEffect.s;
        },

        carouselStyleType() {
            return this.isMobiDefaultEffect ? this.pcPatternEffect.rst : this.patternEffect.rst;
        },

        // 是否固定背景
        isFixedBg() {
            return this.isEffectRow && this.rowEffectType == 2;
        },

        // 轮播是否固定
        isCarouselFixedBg() {
            return this.isCarouselRow && this.pcPatternEffect.rfb && !this.isCarouselStandAloneSet;
        },

        showEffectBg() {
            return (
                this.isEffectRow &&
                (this.rowEffectType == 2 ||
                    (this.rowEffectType == 3 && !this.pcPatternEffect.rb && this.pcPatternEffect.rfb))
            );
        },

        // 轮播背景是否独立设置
        isCarouselStandAloneSet() {
            return this.isCarouselRow && this.pcPatternEffect.rb;
        },

        effectBgStyle() {
            return this.bgStyle;
        },

        effectBgClass() {
            const _class = {};
            _class['stellar_background'] = this.isFixedBg;
            return _class;
        },

        dataStellarBackgroundRatio() {
            return this.isFixedBg && this.showEffectBg ? this.rowEffectSpeed : null;
        },

        carouselListLen() {
            return this.carouselList.length;
        },

        realCarouselList() {
            return this.pcPatternEffect?.rbs || [];
        },

        isOpenAdaptWidthFullBgCarousel() {
            return !this.isOpenAdaptWidth || (this.isOpenAdaptWidth && this.isAdaptWidthFullBg);
        },
        innerFeatures() {
            let rowInstance = this.rowInstance;
            if (!rowInstance) {
                return [];
            }
            let innerFeatures = rowInstance.getFeatures('inner-row');
            if (!this.isMobi) {
                innerFeatures = innerFeatures.filter(function (item) {
                    return item.name != 'ShowHideRow';
                });
            }
            return innerFeatures;
        },

        editingPaddingResize() {
            // 嵌套内的行不需要出现编辑边距
            if (this.hideMaterialLine) {
                return null;
            }
            const rowInstance = this.rowInstance;
            const editingPaddingResizeFeature = rowInstance?.getFeatureByName('EditingPaddingResize');
            return editingPaddingResizeFeature;
        },
    },
    watch: {
        isShowVideoBg(value) {
            // 切换视频横幅需要清除通栏图片的懒加载背景
            if (value) {
                $(this.$refs.webRowLine).css('background-image', '').find('> .jz_web_row').css('background-image', '');
            }
        },
        isFixedBg(val) {
            if (!val) return;

            if (this.carouselRowsEffectController) {
                this.carouselRowsEffectController.addStellarEvent();
            } else {
                this.initCarouselRowsEffects();
            }
        },

        rowEffectType(val) {
            if (val === 3) {
                if (!this.carouselRowsEffectController) {
                    this.initCarouselRowsEffects();
                }
            }
        },

        'row.cols': {
            deep: true,

            handler() {
                if (this.row.type === 5) {
                    Vue.nextTick(() => {
                        banner.fixBannerOverflowHeight();
                    });
                }
            },
        },
    },

    mounted() {
        if (this.manageMode) {
            this.initRowInstance();
        }

        try {
            setTimeout(() => {
                if (checkElement(this.$el)) {
                    this.isEffectRow && (this.isFixedBg || this.rowEffectType == 3) && this.initCarouselRowsEffects();
                    this.rowParentWidth = parseFloat(getComputedStyle(this.$el).width);
                    if (this.manageMode) {
                        this.bindFeaturesData();
                    }
                }
            }, 500);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        initRowInstance() {
            const rowInstance = new RowManage(this.row);
            rowInstance.registerFeatures();
            this.rowInstance = rowInstance;
        },
        bindFeaturesData() {
            if (!this.$store.state.manage.auth.designAuth) {
                return;
            }
            this.rowInstance.setShowHideRow(this);
            this.rowInstance.setPaddingResize(this, this.rowParentWidth);
            this.rowInstance.setEditingPaddingResize(this, this.rowParentWidth);
        },

        showCarouselItem(carouselIndex) {
            return carouselIndex !== 0 && carouselIndex !== this.carouselList.length - 1;
        },

        setCarouselRowBg(_style, carouselIndex) {
            if (!this.isCarouselFixedBg && !this.isCarouselStandAloneSet) {
                Comm.extend(_style, this.effectBgStyle);
            } else if (this.isCarouselStandAloneSet) {
                Comm.extend(_style, this.effectCarouselStandAloneBgStyle(carouselIndex));
            }
        },

        setCarouselRowContentStyle(carouselIndex) {
            const _style = {}; // 轮播通栏且不固定轮播背景且开启了适应通栏不适应背景

            if (this.isCarouselRow && !this.isCarouselFixedBg) {
                if (this.isOpenAdaptWidth && this.isAdaptWidthFullBg) {
                    const { w: width, wo: widthOption } = this.info.pattern.adaptWidth;
                    const value = {
                        0: 960,
                        1: 1200,
                        2: width,
                    }[widthOption];
                    _style.maxWidth = `${value}px`;
                    Comm.extend(_style, this.rowDefaultStyle);
                }
            } else {
                // 不是轮播通栏则不需要这个样式
                _style.maxWidth = '';
            } // 避免死循环

            return carouselIndex;
        },

        effectCarouselStandAloneBgStyle(carouselIndex) {
            // 独立背景 只拿pc的数据
            const bg = this.realCarouselList?.[carouselIndex]?.bg || {};
            return getComputedBg(bg);
        },

        carouselItemClass(item, index) {
            let colsLen = (item.cols && item.cols.length) || 1;
            const carouselFlag = this?.carouselRowsEffectController?.carouselFlag ?? true;
            const mobiL = item.l;
            const _class = [];

            if (carouselFlag) {
                // 最后一张过渡到第一张 or // 第一张过渡到最后一张
                _class.push('transition_none');
            }

            if (colsLen) {
                _class.push(`jz_web_row--carousel_item_${colsLen}`);
            }

            if (mobiL) {
                _class.push(`jz_web_row--carousel_item_mobi_${mobiL}`);
            }

            if (this.isCarouselRow && !this.isCarouselFixedBg && this.isOpenAdaptWidth) {
                if (this.isAdaptWidthFullBg) {
                    _class.push('jz_web_row--carousel_item_none_padding');
                }
            }

            _class.push(`jz_web_row--carousel_item${item.carouselIndex}`);

            if (index == 0 || this.carouselListLen - 1 == index) {
                _class.push('jz_web_row--carousel_item_clone');
            }

            if (this.$store.getters.isCarouselRowOpenVerticalAlign(this.row.id, item.carouselIndex)) {
                const alignClass = {
                    1: 'jz_web_row_line--colAlignStart',
                    2: 'jz_web_row_line--colAlignCenter',
                    3: 'jz_web_row_line--colAlignEnd',
                }[item.ai];
                _class.push(alignClass, 'jz_web_row_line--colAlign');
            }
            return _class;
        },

        selectPhoto(idx) {
            if (this.localRowEditing) return;
            this.carouselRowsEffectController?.selectPhoto(idx + 1);
        },

        carouselDotClass(idx) {
            const carouselDotIdx = this.carouselRowsEffectController?.carouselDotIdx ?? 1;
            return {
                active: idx + 1 === carouselDotIdx,
            };
        },

        prev() {
            if (this.localRowEditing) return;
            this?.carouselRowsEffectController?.prev();
        },

        next() {
            if (this.localRowEditing) return;
            this?.carouselRowsEffectController?.next();
        },

        carouselItemStyle(index, carouselIndex) {
            // 轮播子项样式 只拿pc数据 mobi不支持独立设置
            const curIndex = this?.carouselRowsEffectController?.carouselIdx ?? 0;
            const carouselFlag = this?.carouselRowsEffectController?.carouselFlag ?? true;
            const preCarouselIdx = this?.carouselRowsEffectController?.preCarouselIdx ?? 0;
            const _style = {};

            if (!this.isCarouselFixedBg || this.isCarouselStandAloneSet) {
                if (index == curIndex) {
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                } else if (index > curIndex) {
                    _style['position'] = 'absolute';
                    _style['left'] = '100%';
                } else {
                    _style['position'] = 'absolute';
                    _style['left'] = '-100%';
                }

                if (
                    preCarouselIdx === this.carouselListLen - 2 &&
                    curIndex === 1 &&
                    index == curIndex &&
                    carouselFlag
                ) {
                    // 最后一张过渡到第一张
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                } else if (
                    curIndex === this.carouselListLen - 2 &&
                    curIndex == index &&
                    preCarouselIdx === 1 &&
                    carouselFlag
                ) {
                    // 第一张过渡到最后一张
                    _style['position'] = 'relative';
                    _style['left'] = 0;
                }

                _style['transform'] = 'translateX(0%)';

                if (preCarouselIdx == index || index == curIndex) {
                    _style['transition'] = `left ${this.pcPatternEffect.ram.ss}s`;
                }
            } else {
                if (index == curIndex) {
                    _style['transform'] = `translateX(${index * -100}%)`;
                } else if (index > curIndex) {
                    _style['transform'] = `translateX(${(index - 1) * -100}%)`;
                } else {
                    _style['transform'] = `translateX(${(index + 1) * -100}%)`;
                }

                if (preCarouselIdx == index || index == curIndex) {
                    _style['transition'] = `transform ${this.pcPatternEffect.ram.ss}s`;
                }

                if (
                    preCarouselIdx === this.carouselListLen - 2 &&
                    curIndex === 1 &&
                    index == curIndex &&
                    carouselFlag
                ) {
                    // 最后一张过渡到第一张
                    _style['transform'] = `translateX(${index * -100}%)`;
                } else if (
                    curIndex === this.carouselListLen - 2 &&
                    curIndex == index &&
                    preCarouselIdx === 1 &&
                    carouselFlag
                ) {
                    // 第一张过渡到最后一张
                    _style['transform'] = `translateX(${index * -100}%)`;
                }
            }

            if (this.isOpenAdaptWidthFullBgCarousel || !this.isCarouselFixedBg) {
                this.setCarouselRowBg(_style, carouselIndex);
            } // 是轮播通栏 且 （是通栏宽屏或（适应通栏且适应背景且不固定轮播背景）或 （固定轮播背景且适应通栏）） 作用内边距

            if (
                this.isCarouselRow &&
                (!this.isOpenAdaptWidth ||
                    (this.isOpenAdaptWidth && !this.isAdaptWidthFullBg && !this.isCarouselFixedBg) ||
                    (this.isCarouselFixedBg && this.isOpenAdaptWidth))
            ) {
                Comm.extend(_style, this.rowDefaultStyle);
            }

            return _style;
        },

        initCarouselRowsEffects() {
            this.carouselRowsEffectController = initCarouselRowsEffects({
                id: this.row.id,
            });
        },

        editRow() {
            // 嵌套的行没有单独编辑面板
            if (this.isNestRow) {
                return;
            } // 位于编辑通栏模式

            // 位于编辑通栏模式
            if (window._store.state.row.rowEditing) {
                return;
            }

            editRow(this.row.id);
            logDog(200610, 18);
        },

        setRowContentStyle(status = 'update') {
            if (this.isMobi && !this.rollingScreen.isMobiSync) {
                this.rowContentStyle = {};
                return;
            }

            this.rowContentStyle = this.getRowContentStyle(status);
        },

        contextmenu(...args) {
            return rowContextmenus.apply(this, args);
        },
        hovermenu(...args) {
            return hovermenu.apply(this, args);
        },
    },
};
