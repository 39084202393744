import { ServiceFactory } from '../factory';
import { DragService, SERVICE_INTERFACE } from './service';

export class BannerDragHandler extends DragService {
    constructor(dragData) {
        super(dragData);
        this.bannerService = ServiceFactory.get('banner', {
            params: [window._store.state.responsiveInfo, window._store.state.device],
        });
    }

    validate() {
        const banners = this.bannerService.getBanners();
        const bannerIds = this.bannerService.isVideoBanner
            ? [this.bannerService.bannerData.frid]
            : banners.map((b) => b.prid);

        return bannerIds.includes(this.dragData.rowId);
    }

    /**
     *
     * 【【小需求】自适应-顶部悬停下添加模块逻辑优化】
     *  @see https://www.tapd.cn/42954297/prong/stories/view/1142954297001102511
     *
     * 条件:
     *  0. PC视图
     *  1. 拖拽添加
     *  2. 横幅的第一个模块或者有模块但模块被顶部遮挡
     *  3. 顶部区域设置为悬浮效果
     */
    whenHeaderIsHangState() {
        const HeaderRect = document.querySelector('.header_content')?.getBoundingClientRect() ?? {};

        const inVisible = (moduleId) => {
            if (!moduleId) return false;
            try {
                return document.querySelector(`#module${moduleId}`).getBoundingClientRect().bottom <= HeaderRect.bottom;
            } catch (error) {
                return false;
            }
        };
        return (
            window._store.state.device === 'pc' &&
            document.querySelector('.header_content') &&
            this.dragData &&
            this.dragData.drag &&
            this.dragData.canAdd &&
            (!this.dragData.prevModuleId || inVisible(this.dragData.prevModuleId)) &&
            window._store.state.responsiveInfo.pattern.header.hftt === 1 // 是否悬浮顶部
        );
    }

    /**
     *
     * @param {Jquery<HTMLElement>} $dragPlaceholder - 拖拽时的占位节点
     */
    onDragOver($dragPlaceholder) {
        const HeaderRect = document.querySelector('.header_content')?.getBoundingClientRect() ?? {};

        const prevModule = $dragPlaceholder.prev('.jz_project');
        const prevModuleId = prevModule.length ? parseInt(prevModule.attr('projectid')) : 0;
        this.dragData.prevModuleId = prevModuleId;

        if ($dragPlaceholder.parents('.jz_web_banner').length > 0 && this.whenHeaderIsHangState()) {
            const hasPrevModule = prevModuleId > 0;

            let lastModuleBottom = 0;
            if (hasPrevModule) {
                lastModuleBottom = document.querySelector(`#module${prevModuleId}`).getBoundingClientRect().bottom;
            }

            $dragPlaceholder.css({
                position: 'relative',
                top: Math.round(hasPrevModule ? HeaderRect.bottom - lastModuleBottom : HeaderRect.height / 2),
            });
        }
    }

    /**
     *
     * @param {Object} params - add request params
     */
    beforeAdd(params = {}) {
        let isDragAdd = this.dragData.drag && this.dragData.rowId;
        if (!isDragAdd || !this.validate()) return;

        if (this.whenHeaderIsHangState()) {
            const HeaderRect = document.querySelector('.header_content')?.getBoundingClientRect() ?? {};
            try {
                const pattern = JSON.parse(params?.pattern ?? '{}');
                const bannerWidth = document.querySelector('#jz_banner').getBoundingClientRect().width;
                const hasPrevModule = this.dragData.prevModuleId > 0;
                let lastModuleBottom = 0;
                if (hasPrevModule) {
                    lastModuleBottom = document
                        .querySelector(`#module${this.dragData.prevModuleId}`)
                        .getBoundingClientRect().bottom;
                }

                const top = hasPrevModule ? HeaderRect.bottom - lastModuleBottom : HeaderRect.height;
                const relativeTop = parseFloat((top / bannerWidth).toFixed(4));
                if (!pattern.pc) {
                    pattern.pc = {};
                }
                pattern.pc.mt = (pattern.pc?.mt ?? 0) + relativeTop;

                params.pattern = JSON.stringify(pattern);
            } catch (error) {
                console.error(error);
            }
        }
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: DragService,
    impl: {
        bannerDargHandler: BannerDragHandler,
    },
});
