import request from '@jz/request';
export function getCustomSearchDataByPaginationRequest(data) {
    return request
        .post('/ajax/siteCustomSearch_h.jsp?cmd=getWafNotCk_searchData', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCustomSearchData(data = {}) {
    return request.post('/ajax/siteCustomSearch_h.jsp?cmd=getWafNotCk_get', { data }).then(({ data = {} }) => {
        return data;
    });
}
