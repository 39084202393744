//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'UnitSearchResultCol',
    inject: ['module'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        other: {
            type: Object,
            default: () => ({}),
            require: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        getATarget(item) {
            if (typeof item.url === 'string') {
                return item.url.startsWith('javascript:') ? null : '_blank';
            }
            return null;
        },
    },
    computed: {
        ...Vuex.mapState(['LS']),
    },
};
