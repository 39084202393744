//
//
//
//

import sectionMemberLoginSite from './sectionMemberLoginSite.vue';
import sectionMemberLoginMobi from './sectionMemberLoginMobi.vue';
import LoggedInSite from './LoggedInSite.vue';

const SECTION_MEMBER_LOGIN_SITE_NAME = 'sectionMemberLoginSite';
const SECTION_MEMBER_LOGIN_MOBI_NAME = 'sectionMemberLoginMobi';

export default {
    name: 'SectionMemberLogin',
    components: {
        sectionMemberLoginSite,
        sectionMemberLoginMobi,
    },
    props: {
        isMobi: Boolean,
        rowid: Number,
    },
    computed: {
        ...Vuex.mapState(['sections']),
        dynamicComponent() {
            return this.isMobi
                ? SECTION_MEMBER_LOGIN_MOBI_NAME
                : this.sections.logined
                ? LoggedInSite
                : SECTION_MEMBER_LOGIN_SITE_NAME;
        },
    },
};
