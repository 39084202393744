//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { getStyleDataByDevice, extend, getComputedTextColor } = Comm;
import { Message } from '@shared/manage/componMessage/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import FormContainer from './forms/formContainer.vue';
import FormItem from './forms/formItem.vue';
import ItemType_0 from '../common/forms/itemType0.vue';
import ItemType_1 from '../common/forms/itemType1.vue';
import ItemType_8 from '../common/forms/itemType8.vue';
import ItemType_9 from '../common/forms/itemType9.vue';
import ItemType_validateCode from '../common/forms/itemTypeValidateCode.vue';
import { submitForm } from './utils.js';
import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES } from '@/services';
import { realNameAuthTips, topTips } from '@/site/topTips';

export default {
    name: 'ModuleMsgBoard',
    style: 23,
    components: {
        FormContainer,
        FormItem,
        ItemType_0,
        ItemType_1,
        ItemType_8,
        ItemType_9,
        ItemType_validateCode,
    },
    props: ['module'],

    data() {
        return {
            validateCode: '',
            validation: this.module.showCaptcha,
            loginDialogCaptcha: this.module.msgBoardValidateCode,
        };
    },

    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'isDemo', 'LS', 'responsiveInfo', 'realNameAuth']),
        isMobi() {
            return this.device === 'mobi';
        },

        contentList() {
            return this.module.propList;
        },

        hasFormItem() {
            return this.contentList && this.contentList.length > 0;
        },

        classes() {
            const classes = {};
            classes[`s_${this.module.prop0}`] = true;
            return classes;
        },

        pattern() {
            return this.module.privatePattern.pc;
        },

        itemInputStyle() {
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.if,
                mobiData: this.module.privatePattern.mobi.if,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
    },

    mounted() {
        setHrefEventHasReqArgs($(`#module${this.module.id}`));
        this.initRealNameAuthTips();
    },

    methods: {
        async submitHandler() {
            if (this.manageMode) {
                Message.warning('当前为管理状态，提交无效。');
                return;
            }
            if (this.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”再进行提交。', true);
                return;
            }

            if (this.realNameAuth.allowSubmitMessage === false) {
                return;
            }

            const mobileAuthPopupService = new MobileAuthPopupService(this.device, this.responsiveInfo.themeColor);
            const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(
                MOBILE_AUTH_MESSAGE_TYPES.MSG_BOARD
            );
            if (needAuth) {
                mobileAuthPopupService.createSubmitHandler(
                    () => {
                        this.submit(showAfterReview);
                    },
                    (error) => {
                        Fai.ing(error.msg);
                    }
                );
                mobileAuthPopupService.show();
            } else {
                this.submit(showAfterReview);
            }
        },
        submit(showAfterReview = false) {
            const $module = $('#module' + this.module.id);
            submitForm(this.module, this.formData, this.contentList, this.validation, this.validateCode)
                .then(() => {
                    if (showAfterReview === false) {
                        successMessage(Fai.format(this.LS.msgBoardSendOkAutoOpen), true);
                    } else {
                        successMessage(Fai.format(this.LS.msgBoardSendOk), true);
                    }

                    $module.find('input:not(:disabled),textarea').val('');
                    $module
                        .find('.validateCode_img')
                        .attr('src', '/validateCode.jsp?' + Math.random() * 1000 + '&validateCodeRegType=3'); //重置验证码及输入框(旧逻辑，但是这里都刷新了，重置他还有什么用呢)
                })
                .catch((error) => {
                    if (error instanceof Error) {
                        //系统错误
                        console.error(error);
                        return;
                    } //下面是提交时返回的错误

                    if (error.msg) {
                        warningMessage(error.msg, true);
                    }

                    if (error.itemId) {
                        $(this.$refs.module).find(`.formitem_${error.itemId}`).find('input, textarea').focus();
                    }

                    switch (error.errCode) {
                        case 'needCode':
                            //显示验证码
                            this.validation = true;
                            break;

                        case -401:
                            //验证码有误，清空并刷新验证码
                            this.validateCode = '';
                            this.validation = false;
                            Vue.nextTick(() => {
                                this.validation = true;
                            });
                            break;

                        case -28:
                            //已经提交过，两秒后刷新页面
                            setTimeout(function () {
                                document.location.reload();
                            }, 2000);
                            break;
                    }
                });
        },

        formItemClasses(item) {
            const classes = [];
            classes.push('prop_input_' + item.type);
            classes.push('msg_input_' + item.id);
            return classes;
        },

        getFormItem(type) {
            return `ItemType_${type}`;
        },

        inputItem(item, val) {
            if (this.manageMode) {
                //如果是管理态则不设置这个数据，否则可能会被存到设置项的数据中
                //如果是访客态的话每一个模块拿的都不是同一个引用的数据，所以可以作为一个临时的数据储存点
                return;
            }

            Vue.set(item, 'value', val);
        },
        initRealNameAuthTips() {
            if (this.manageMode === false && this.realNameAuth.allowShowMessage === false) {
                topTips.addTopTips(realNameAuthTips);
            }
        },
    },
};
