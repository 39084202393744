//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getVideoResourceHandler } from '@/site/mediaResourceHandler';

export default {
    name: 'RowVideoBg',
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...Vuex.mapGetters(['isMobi']),
        ...Vuex.mapState(['isJzCdnFlowLimit']),
        rowBackground() {
            return this.row.pattern.pc.rb;
        },
        isShowVideoBg() {
            return this.$store.getters.isShowVideoBg(this.row);
        },
        src() {
            return this.isJzCdnFlowLimit ? '' : this.rowBackground.vpath;
        },
    },
    mounted() {
        if (this.isShowVideoBg && this.isJzCdnFlowLimit) {
            const VideoResourceHandler = getVideoResourceHandler();
            VideoResourceHandler.add(this.$refs.VideoWrapper);
        }
    },
};
