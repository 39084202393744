var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_category_nav_content_2",
      class: _vm.moduleClasses,
    },
    [
      _c("group-item", {
        attrs: {
          "is-only-one-level": _vm.isOnlyOneLevel,
          module: _vm.module,
          "group-list": _vm.displayList,
        },
        on: { "on-showpanel": _vm.showPanelEvent },
      }),
      _vm._v(" "),
      _vm.showTips && _vm.manageMode
        ? _c("empty-tips", {
            attrs: {
              "is-mobi": _vm.isMobi,
              name: _vm.name,
              "active-button-text": _vm.activeButtonText,
              "button-text": _vm.buttonText,
            },
            on: {
              "click-active-button": function ($event) {
                _vm.isSelectGroupAll
                  ? _vm.clickAddGroupHandler()
                  : _vm.clickSelectGroupHandler()
              },
              "click-button": _vm.clickManageGroupHandler,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }