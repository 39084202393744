//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { isNewModuleAfter20210707, getStyleDataByDevice, bindFlag } = Comm;
import ServiceBtn from './serviceBtn.vue';
import ServiceContact from './serviceContact.vue';
import WechatService from './wechatService.vue';
import { SERVICE_TYPE, SERVICE_ICON_KEY } from './utils.js';

export default {
    name: 'OnlineService',
    style: 31,
    components: {
        ServiceBtn,
        ServiceContact,
        WechatService,
    },
    props: ['module'],
    data() {
        return {
            serviceIconKey: SERVICE_ICON_KEY,
        };
    },
    computed: {
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        services() {
            return this.module.prop1;
        },
        contacts() {
            return this.module.prop3;
        },
        serviceWithoutWechats() {
            return this.services.filter((item) => item.s !== SERVICE_TYPE.WECHAT);
        },
        wechatServices() {
            return this.services.filter((item) => item.s === SERVICE_TYPE.WECHAT);
        },
        pattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        classes() {
            return {
                online_service_new_module_after_20210707: this.isNewModuleAfter20210707,
            };
        },
        contactVisible: bindFlag(0x1),
        textStyle() {
            const textStyleData = getStyleDataByDevice({
                isMobi: this.isMobi,
                pcData: this.pattern.itt,
                mobiData: this.mobiPattern.itt,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            let defaultTextType = textStyleData.y;
            if (defaultTextType === 0) {
                return {};
            }
            let { s: fontSize, c: fontColor, f: fontFamily, w: weight, i: _style, d: decoration } = textStyleData;

            const fontStyle = _style === 0 ? 'normal' : 'italic';
            const fontWeight = weight === 0 ? 'normal' : 'bold';
            const fontDecoration = decoration === 0 ? 'normal' : 'underline';

            const base = {
                'font-size': fontSize ? `${fontSize}px` : '',
                'font-family': fontFamily,
                'font-style': fontStyle,
                'font-weight': fontWeight,
                'text-decoration': fontDecoration,
            };

            if (fontColor.indexOf('linear') !== -1) {
                base['-webkit-background-clip'] = 'text';
                base['-webkit-text-fill-color'] = 'transparent';
                base.backgroundImage = fontColor;
            } else {
                base.color = fontColor;
            }

            return base;
        },
    },
    methods: {
        createSymbol(t) {
            return Symbol(t);
        },
        getPath(service = {}) {
            const { s: serviceType } = service;
            const dataKey = this.serviceIconKey[serviceType];

            if (dataKey === void 0) {
                return '';
            }

            const isCustomIcon = this.pattern[dataKey].y === 2;
            if (isCustomIcon) {
                return this.pattern[dataKey].p;
            } else {
                return '';
            }
        },
    },
};
