const MonitorDef = {};

/*
 * 『业务模块监控』: 支持统计业务次数，耗时，错误率
 */
MonitorDef.MonitorId = {
    // 设计器大保存
    DESIGN_SAVE: 7332,
    // 极速建站打开
    RAPID_SITE_OPEN: 7346,
    // 表单模块提交
    FORM_SUBMIT: 7347,
    // 添加模块
    ADD_MODULE: 7348,
    // 编辑模块
    EDIT_MODULE: 7349,
    // 隐藏模块
    HIDE_MODULE: 7350,
    // 复制模块
    COPY_MODULE: 7351,
    // 模块初始化
    INIT_MODULES: 7352,
    // 模块样式初始化
    INIT_MODULE_STYLE: 7353,
    // 切换模块状态
    MODULE_STATE_SWITCH: 7354,
    // 打开样式面板
    OPEN_EDIT_PANEL: 7355,
    // 打开功能面板
    OPEN_FUN_PANEL: 7356,
    // 极速建站初始化数据的ajax请求
    RAPID_SITE_INIT_DATA: 9020,
    // 自适应设计器大保存
    R_DESIGN_SAVE: 9027,
    // 自适应表单模块提交
    R_FORM_SUBMIT: 9029,
    // 自适应添加模块
    R_ADD_MODULE: 9031,
    // 自适应编辑模块
    R_EDIT_MODULE: 9033,
    // 自适应删除模块
    R_HIDE_MODULE: 9034,
    // 自适应复制模块
    R_COPY_MODULE: 9035,
    // 自适应模块初始化
    R_INIT_MODULES: 9036,
    // 自适应打开功能面板
    R_OPEN_FUN_PANEL: 9040,
    // 自适应添加素材
    R_ADD_MATERIAL: 9037,
    // 自适应全屏滚动初始化
    R_FULL_SCREEN: 9038,
    // 自适应访客态模块激活请求
    R_MODULE_REACT_REQUEST: 11531,
    // 自适应访客态模块激活
    R_MODULE_REACT: 9039,
    // 自适应访客态系统模块激活请求
    R_SYSTEM_MODULE_REACT_REQUEST: 11532,
    // 自适应访客态系统模块激活
    R_SYSTEM_MODULE_REACT: 9058,
};

/*
 * 『普通业务监控』: 原有的业务监控，统计业务次数, 可配置告警以及实时视图
 */
MonitorDef.AlarmMonitorId = {
    // 设计器大保存
    DESIGN_SAVE: 7345,
    // 极速建站打开
    RAPID_SITE_OPEN: 7333,
    // 表单模块提交
    FORM_SUBMIT: 7334,
    // 添加模块
    ADD_MODULE: 7335,
    // 编辑模块
    EDIT_MODULE: 7336,
    // 隐藏模块
    HIDE_MODULE: 7337,
    // 复制模块
    COPY_MODULE: 7338,
    // 模块初始化
    INIT_MODULES: 7339,
    // 模块样式初始化
    INIT_MODULE_STYLE: 7340,
    // 切换模块状态
    MODULE_STATE_SWITCH: 7341,
    // JZ_UTILS 报错监控
    JZ_UTILS: 7344,
    // 打开样式面板
    OPEN_EDIT_PANEL: 7342,
    // 打开功能面板
    OPEN_FUN_PANEL: 7343,
    // 自适应设计器大保存
    R_DESIGN_SAVE: 9028,
    // 自适应表单模块提交
    R_FORM_SUBMIT: 9030,
    // 自适应添加模块
    R_ADD_MODULE: 9032,
    // 自适应编辑模块
    R_EDIT_MODULE: 9041,
    // 自适应删除模块
    R_HIDE_MODULE: 9042,
    // 自适应复制模块
    R_COPY_MODULE: 9043,
    // 自适应模块初始化
    R_INIT_MODULES: 9044,
    // 自适应打开功能面板
    R_OPEN_FUN_PANEL: 9048,
    // 自适应添加素材
    R_ADD_MATERIAL: 9045,
    // 自适应全屏滚动初始化
    R_FULL_SCREEN: 9047,
    // 自适应访客态模块激活
    R_MODULE_REACT: 9046,
    // 自适应访客态系统模块激活
    R_SYSTEM_MODULE_REACT: 9057,
    // 自适应访客态系统模块激活请求
    R_SYSTEM_MODULE_REACT_REQUEST: 11534,
    // 自适应访客态模块激活请求
    R_MODULE_REACT_REQUEST: 11533,
};

const MonitorId = MonitorDef.MonitorId;
const AlarmMonitorId = MonitorDef.AlarmMonitorId;

/*
 * 自动告警监控
 * 在这里配置业务模块监控与普通业务监控（告警）的对应关系
 * 在业务监控接收到错误时自动进行告警监控的统计，可以省去手动进行告警监控的步骤
 */
MonitorDef._AutoAlarmMonitorMap = {
    // 设计器大保存
    [MonitorId.DESIGN_SAVE]: AlarmMonitorId.DESIGN_SAVE,
    // 极速建站打开
    [MonitorId.RAPID_SITE_OPEN]: AlarmMonitorId.RAPID_SITE_OPEN,
    // 表单模块提交
    [MonitorId.FORM_SUBMIT]: AlarmMonitorId.FORM_SUBMIT,
    // 添加模块
    [MonitorId.ADD_MODULE]: AlarmMonitorId.ADD_MODULE,
    // 隐藏模块
    [MonitorId.HIDE_MODULE]: AlarmMonitorId.HIDE_MODULE,
    // 复制模块
    [MonitorId.COPY_MODULE]: AlarmMonitorId.COPY_MODULE,
    // 模块初始化
    [MonitorId.INIT_MODULES]: AlarmMonitorId.INIT_MODULES,
    // 模块样式初始化
    [MonitorId.INIT_MODULE_STYLE]: AlarmMonitorId.INIT_MODULE_STYLE,
    // 切换模块状态
    [MonitorId.MODULE_STATE_SWITCH]: AlarmMonitorId.MODULE_STATE_SWITCH,
    // 打开样式面板
    [MonitorId.OPEN_EDIT_PANEL]: AlarmMonitorId.OPEN_EDIT_PANEL,
    // 打开功能面板
    [MonitorId.OPEN_FUN_PANEL]: AlarmMonitorId.OPEN_FUN_PANEL,
    // 自适应设计器大保存
    [MonitorId.R_DESIGN_SAVE]: AlarmMonitorId.R_DESIGN_SAVE,
    // 自适应表单模块提交
    [MonitorId.R_FORM_SUBMIT]: AlarmMonitorId.R_FORM_SUBMIT,
    // 自适应添加模块
    [MonitorId.R_ADD_MODULE]: AlarmMonitorId.R_ADD_MODULE,
    // 自适应编辑模块
    [MonitorId.R_EDIT_MODULE]: AlarmMonitorId.R_EDIT_MODULE,
    // 自适应隐藏模块
    [MonitorId.R_HIDE_MODULE]: AlarmMonitorId.R_HIDE_MODULE,
    // 自适应复制模块
    [MonitorId.R_COPY_MODULE]: AlarmMonitorId.R_COPY_MODULE,
    // 自适应模块初始化
    [MonitorId.R_INIT_MODULES]: AlarmMonitorId.R_INIT_MODULES,
    // 自适应打开功能面板
    [MonitorId.R_OPEN_FUN_PANEL]: AlarmMonitorId.R_OPEN_FUN_PANEL,
    // 自适应添加素材
    [MonitorId.R_ADD_MATERIAL]: AlarmMonitorId.R_ADD_MATERIAL,
    // 自适应全屏滚动初始化
    [MonitorId.R_FULL_SCREEN]: AlarmMonitorId.R_FULL_SCREEN,
    // 自适应访客态模块激活
    [MonitorId.R_MODULE_REACT]: AlarmMonitorId.R_MODULE_REACT,
    // 自适应访客态系统模块激活
    [MonitorId.R_SYSTEM_MODULE_REACT]: AlarmMonitorId.R_SYSTEM_MODULE_REACT,
    // 自适应访客态系统模块激活请求
    [MonitorId.R_SYSTEM_MODULE_REACT_REQUEST]: AlarmMonitorId.R_SYSTEM_MODULE_REACT_REQUEST,
    // 自适应访客态模块激活请求
    [MonitorId.R_MODULE_REACT_REQUEST]: AlarmMonitorId.R_MODULE_REACT_REQUEST,
};

export default MonitorDef;
