import { Module } from '@shared/visitor/module/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';

import MsgBoardComment from '@/components/modules/moduleMsgBoard/MsgBoardComment.vue';
class msgBoard extends Module {
    constructor(...args) {
        super(...args);

        this.init();
    }

    init() {
        if (!window._store.state.manageMode) {
            var _this = this;
            this.needCode = false;

            if ($('#msg_board_comment').length > 0) {
                _this.msgBoard = new Vue({
                    el: '#msg_board_comment',
                    store: window._store,
                    components: {
                        MsgBoardComment,
                    },
                    template: `<msgBoardComment ref="msgBoard" :styleId='${_this.conf.style}'/>`,
                });
            }
        }
        setHrefEventHasReqArgs($(`#module${this.moduleId}`));
    }
}
export { msgBoard };
