//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Animation } from '../constant';
import { decodeHtmlStr } from '@/components/utils.js';
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
export default {
    components: {
        ImageMainViewer,
    },
    props: {
        photoList: {
            type: Array,
            default() {
                return [];
            },
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        desShow: {
            type: Boolean,
            default: true,
        },
        moduleId: {
            type: Number,
            default: 12,
        },
    },
    data() {
        return {
            selectId: 0, // 选中图片下标, 0 开始
            preSelectId: -1,
            animateDir: '',
            // 小滑动条
            total: 1,
            page: 1,
            smallPhotoSliderWidth: 0,
            // 幻灯片所需变量
            showPcViewer: false,
            showMobiViewer: false,
            zIndex: 9999,
            imageIndex: 0,
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'jzVersion', 'isMaterialLazyLoad']),
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-图册详情',
                    jz_version: this.jzVersion,
                },
            };
        },
        photoListLength() {
            return this.photoList.length;
        },
        bigPrevClass() {
            let classList = [];
            if (this.selectId == 0) {
                classList.push('ban');
            }
            return classList;
        },
        bigNextClass() {
            let classList = [];
            if (this.selectId == this.photoList.length - 1) {
                classList.push('ban');
            }
            return classList;
        },
        onlyOnePhoto() {
            return this.photoListLength == 1;
        },
        isLessPhoto() {
            if (this.mobile) {
                return this.photoListLength > 1 && this.photoListLength < 6;
            } else {
                return this.photoListLength > 1 && this.photoListLength < 9;
            }
        },
        footerClass() {
            let classList = [];
            if (this.mobile && this.isLessPhoto) {
                classList.push('slider_footer');
            }
            return classList;
        },
        lessPhotoClass() {
            let classList = [];
            if (this.isLessPhoto) {
                classList.push('less-photo');
            }
            return classList;
        },
        footerPrevClass() {
            let classList = [];
            this.page == 1 ? classList.push('footer_ban_direct') : classList.push('footer_normal_direct');
            return classList;
        },
        footerNextClass() {
            let classList = [];
            this.page == this.total ? classList.push('footer_ban_direct') : classList.push('footer_normal_direct');
            return classList;
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.path;
                previewSrcItem.description = decodeHtmlStr(value.desc);
                previewSrcItem.title = value.name;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        showCircle() {
            return (
                !this.desShow ||
                (this.desShow && this.photoList[this.selectId] && this.isEmptyDesc(this.photoList[this.selectId]))
            );
        },
    },
    watch: {
        selectId(val, oldVal) {
            this.preSelectId = oldVal;
            this.animateDir = val > oldVal ? 'left' : 'right';
        },
    },
    mounted() {
        this.updateScrollWidth();
        if (this.mobile) {
            this.initCarousel();
        }
        this.$device.on(this.$device.EVENTS.ON_CHANGE_AFTER_TRANSITION, () => {
            this.updateScrollWidth();
        });
    },
    methods: {
        bigPhotoListClass(index) {
            let classList = [];
            if (this.animateDir != '') {
                if (index == this.selectId) {
                    classList.push(this.animateDir == 'left' ? Animation.MOVE_LEFT_IN : Animation.MOVE_RIGHT_IN);
                }
                if (index == this.preSelectId) {
                    classList.push(this.animateDir == 'left' ? Animation.MOVE_LEFT_OUT : Animation.MOVE_RIGHT_OUT);
                }
            }
            if (index == this.selectId || index == this.preSelectId) {
                classList.push('active');
            }

            return classList;
        },
        selectPhoto(index) {
            this.selectId = index;
            this.caculateToScroll();
        },
        smallPhotoClass(index) {
            let classList = [];
            if (index == this.selectId) {
                classList.push('photo-select', 'jz_theme_border_color');
            }
            classList.push(`small_photo_${index}`);
            return classList;
        },
        smallMaskStyle(index) {
            let classList = [];
            if (index == this.selectId) {
                classList.push('no-mask');
            }
            return classList;
        },
        handlePrev() {
            const $list = $(this.$refs.smallPhotoList);
            $list.animate({
                scrollLeft: $list.scrollLeft() - $list.width() - 18,
            });
            if (this.page > 1) {
                this.page = this.page - 1;
            }
        },
        handleNext() {
            const $list = $(this.$refs.smallPhotoList);
            $list.animate({
                scrollLeft: $list.scrollLeft() + $list.width() + 18,
            });
            if (this.page < this.total) {
                this.page = this.page + 1;
            }
        },
        handleBigPrev() {
            if (this.selectId > 0) {
                this.selectId = this.selectId - 1;
                this.caculateToScroll();
            }
        },
        handleBigNext() {
            if (this.selectId < this.photoList.length - 1) {
                this.selectId = this.selectId + 1;
                this.caculateToScroll();
            }
        },
        isEmptyDesc(item) {
            return item.desc == null || item.desc == '';
        },
        getSmallPhotoListWidth() {
            const $list = $('.small-photo-list .small-photo');
            let totalWidth = 0;
            $list.each((index, item) => {
                totalWidth += $(item).outerWidth(true);
            });
            return totalWidth;
        },
        caculateToScroll() {
            // 可视区： 0 - smallPhotoListSlider 之间
            this.$nextTick(() => {
                // 获取当前选中元素距离左边距离
                const el = $(`.small_photo_${this.selectId}`)[0];
                const listEl = this.$refs.smallPhotoList;
                const $list = $(listEl);

                let elOffsetLeft = el.offsetLeft;
                let elOffsetWidth = el.offsetWidth;
                let listOffsetLeft = listEl.offsetLeft;
                let listScrollLeft = listEl.scrollLeft;
                // 计算该距离是否在可视区内，若不在，则滚动一段距离
                /*
          差值计算：(elOffsetLeft + elOffsetWidth) - listScrollLeft - listOffsetLeft
          三种情况：
          1. 不用滚动 刚好在视区内，不用滚动
          2. 向左滚动  < 0， 说明滚过头了 
          3. 向右滚动  > smallPhotoSliderWidth ，说明还没滚动出来
        */
                let result = elOffsetLeft + elOffsetWidth - listScrollLeft - listOffsetLeft;
                let rightSpace = elOffsetWidth - (result - this.smallPhotoSliderWidth);
                let leftSpace = elOffsetLeft - listScrollLeft - listOffsetLeft;

                if (result > this.smallPhotoSliderWidth) {
                    // 向右滚动
                    $list.animate({
                        scrollLeft: $list.scrollLeft() + $list.width() - rightSpace,
                    });
                    if (this.page < this.total) {
                        this.page = this.page + 1;
                    }
                } else if (result < 0 || leftSpace < 0) {
                    // 向左滚动
                    $list.animate({
                        scrollLeft: $list.scrollLeft() - $list.width() + elOffsetWidth + leftSpace,
                    });
                    if (this.page > 1) {
                        this.page = this.page - 1;
                    }
                }
            });
        },
        closeViewer() {
            this.showPcViewer = false;
            this.showMobiViewer = false;
        },
        slidesshow(item, index) {
            let { url } = item;
            if (url === '') {
                this.imageIndex = index;
                if (this.isMobi) {
                    this.showMobiViewer = true;
                } else {
                    this.showPcViewer = true;
                }
            } else {
                if (url.startsWith('javascript:')) {
                    eval(url);
                } else {
                    window.open(item.url);
                }
            }
        },
        initCarousel() {
            let _this = this;
            //左滑
            $(this.$el)
                .off('swipeleft.l')
                .on('swipeleft.l', '.big-photo, .small-photo-list', function (e) {
                    let $el = $(e.target);
                    if ($el.hasClass('big-photo')) {
                        _this.handleBigNext();
                    } else {
                        _this.handleNext();
                    }
                });
            //右滑
            $(this.$el)
                .off('swiperight.r')
                .on('swiperight.r', '.big-photo, .small-photo-list', function (e) {
                    let $el = $(e.target);
                    if ($el.hasClass('big-photo')) {
                        _this.handleBigPrev();
                    } else {
                        _this.handlePrev();
                    }
                });
        },
        handleStr(html) {
            return html && html.replace ? html.replace(/&nbsp;/gi, ' ').replace(/<br\/>/gi, '\n') : html;
        },
        updateScrollWidth() {
            let totalWidth = this.getSmallPhotoListWidth();
            let smallPhotoSliderWidth = $(this.$refs.smallPhotoList).width();
            this.total = Math.ceil(totalWidth / smallPhotoSliderWidth);
            this.smallPhotoSliderWidth = smallPhotoSliderWidth;
        },
        genPicItemStyle(item) {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.path}')`;
            }

            return { ...style };
        },
    },
};
