//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomSearchPopupLayer from './customSearchPopupLayer.vue';

export default {
    components: {
        CustomSearchPopupLayer,
    },
    props: {
        value: {},
        list: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        contentKey: {
            type: String,
            default: 'content',
        },
        requiredIcon: Boolean,
        inputTextStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showList: false,
            mobiShowList: false,
            oldTouchY: 0, //上一次的位置
            speedTouchY: 0, //移动速度
            moveTimer: null,
        };
    },
    methods: {
        selectItem(item) {
            this.$emit('input', item);
            this.showList = false;
        },
        toggleList() {
            if (document.querySelector('.jz_screen_mobi')) {
                if (!this.list.length) {
                    return;
                }
                this.showList = false;
                this.mobiShowList = true;
                Vue.nextTick(() => {
                    if (this.value) {
                        $(this.$refs.list).scrollTop(this.list.indexOf(this.value) * 38);
                    }
                });
                return;
            }

            this.showList = !this.showList;
            if (this.showList) {
                Vue.nextTick(() => {
                    $(document).one('click', () => {
                        this.showList = false;
                    });
                });
            }
        },
        onTouchStart(e) {
            clearInterval(this.moveTimer);

            this.oldTouchY = this.getTouchEventY(e);

            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('mousemove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
            window.addEventListener('mouseup', this.onTouchEnd);
        },
        onTouchMove(e) {
            const touchY = this.getTouchEventY(e);
            const $list = $(this.$refs.list);

            this.speedTouchY = this.oldTouchY - touchY;

            $list.scrollTop($list.scrollTop() + this.speedTouchY);

            this.oldTouchY = touchY;
        },
        onTouchEnd() {
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
            window.removeEventListener('mouseup', this.onTouchEnd);

            const $list = $(this.$refs.list);

            //惯性滑动
            let speed = this.speedTouchY;
            this.moveTimer = setInterval(() => {
                speed *= 0.8;
                $list.scrollTop($list.scrollTop() + speed);
                if (Math.abs(speed) < 1) {
                    clearInterval(this.moveTimer);
                    finish(speed);
                }
            }, 1000 / 60);

            const finish = () => {
                const scrollTop = $list.scrollTop();
                const currentIndex = Math.round(scrollTop / 38);

                $list.animate(
                    {
                        scrollTop: currentIndex * 38,
                    },
                    50
                );
            };
        },
        getTouchEventY(e) {
            if (e.changedTouches && e.changedTouches[0]) {
                return e.changedTouches[0].pageY;
            } else {
                return e.pageY;
            }
        },
        getContentText(value) {
            if (value instanceof Object) {
                return value[this.contentKey];
            } else {
                return value;
            }
        },
        mobiCancel() {
            this.mobiShowList = false;
        },
        mobiConfirm() {
            this.mobiShowList = false;

            const scrollTop = $(this.$refs.list).scrollTop();
            const currentIndex = Math.round(scrollTop / 38);
            this.$emit('input', this.list[currentIndex]);
        },
    },
};
