var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module_content_detail", class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "file_download_container", class: _vm.styleClass },
        _vm._l(_vm.renderFileList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id + index,
              staticClass: "file_item_wrap",
              style: _vm.fileItemStyle,
            },
            [
              _c(
                "div",
                {
                  staticClass: "file_item",
                  on: {
                    mouseenter: function ($event) {
                      return _vm.onShowViewIcon(index)
                    },
                    mouseleave: _vm.onHideViewIcon,
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.styleType == 1,
                          expression: "styleType == 1",
                        },
                      ],
                      style: _vm.svgStyle,
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href": _vm.checkFileTypeSvg(item.name),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "file_item_message" }, [
                    _c(
                      "div",
                      { staticClass: "file_item_title", style: _vm.nameStyle },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.decodeHtml(item.name)) +
                            "\n                        "
                        ),
                        item.isPdf
                          ? _c(
                              "div",
                              {
                                staticClass: "file_view_content",
                                on: { click: _vm.viewFile },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.showViewIcon === index ||
                                          _vm.isMobi,
                                        expression:
                                          "showViewIcon === index || isMobi",
                                      },
                                    ],
                                    staticClass: "file_view_icon",
                                    attrs: {
                                      target: "_blank",
                                      rel: "nofollow",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkPreviewPdf(
                                          $event,
                                          item.sourcePath
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      { staticClass: "icon_file_preview" },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon_file_preview",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "file_item_size", style: _vm.sizeStyle },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.size) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "file_item_download",
                      attrs: { "data-url": item.path },
                      on: {
                        mouseenter: _vm.setBgColor,
                        mouseleave: _vm.deleteBgColor,
                        click: _vm.downloadFile,
                      },
                    },
                    [
                      !_vm.isJuly3FreeUser
                        ? _c("a", {
                            style: _vm.downloadBtnStyle,
                            attrs: {
                              href: item.path,
                              target: "_blank",
                              rel: "nofollow",
                            },
                            domProps: { innerHTML: _vm._s(_vm.buttonType) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isJuly3FreeUser
                        ? _c("a", {
                            style: _vm.downloadBtnStyle,
                            attrs: {
                              href: "javascript:void(0);",
                              target: "_blank",
                              rel: "nofollow",
                              onclick:
                                "Site.logDog(200964, 13);Fai.ing('免费版暂不支持此功能', true);",
                            },
                            domProps: { innerHTML: _vm._s(_vm.buttonType) },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.manageMode &&
      (!_vm.module.prop3 ||
        !_vm.module.prop3.length ||
        _vm.module.prop3.length == 0)
        ? _c("empty-tips", {
            attrs: {
              "is-mobi": _vm.isMobi,
              name: "文件",
              "active-button-text": "添加文件",
            },
            on: { "click-active-button": _vm.addFile },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openPagination
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.handlePaginationPageChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }