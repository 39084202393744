import { context as globalContext, isBrowserEnv } from '@jz/utils';
import { EventBus } from '@jz/biz-shared';

export class Device extends EventBus {
    constructor() {
        super();
        this._init();
    }

    get EVENTS() {
        return Device.EVENTS;
    }

    _init() {
        if (!isBrowserEnv()) {
            return;
        }
        window.addEventListener('DOMContentLoaded', () => {
            Fai.top?._store?.watch(
                (store) => store.device,
                (val, oldVal) => {
                    this.emit(this.EVENTS.ON_CHANGE, val, oldVal);
                    if (Fai?.top?._store.state.manageMode) {
                        const previewEl = document.querySelector('.jz_preview_area');
                        const endFunction = () => {
                            this.emit(this.EVENTS.ON_CHANGE_AFTER_TRANSITION, val, oldVal);
                            previewEl.removeEventListener('transitionend', endFunction);
                        };
                        previewEl.addEventListener('transitionend', endFunction);
                    } else {
                        // 访客态没有transition
                        this.emit(this.EVENTS.ON_CHANGE_AFTER_TRANSITION, val, oldVal);
                    }
                }
            );
        });
    }
}

Device.EVENTS = {
    ON_CHANGE: 'onChange',
    ON_CHANGE_AFTER_TRANSITION: 'onChangeAfterTransition',
};

export const device = globalContext._device || (globalContext._device = new Device());

export const injectDeviceToVue = (Vue) => {
    if (!Vue) {
        return;
    }

    if (!Vue.prototype.$device) {
        Vue.prototype.$device = device;
    }
};
