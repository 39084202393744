var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "hovermenu",
          rawName: "v-hovermenu",
          value: _vm.hovermenu,
          expression: "hovermenu",
        },
      ],
      staticClass: "jz_mobi_header g_mobi_header",
      class: _vm.headerContentClass,
      style: _vm.gridMobiHeaderStyle,
      attrs: { id: "gridMobiHeader" },
    },
    [
      _c(
        "div",
        {
          ref: "headerContent",
          staticClass: "mobi_nav g_mobi_header_content",
          class: { jz_theme_bg_color: _vm.hasThemeBg },
          style: _vm.mobiHeaderContent,
        },
        [
          _c("section-nav", { attrs: { "is-mobi": "" } }),
          _vm._v(" "),
          _c("section-website-title", {
            style: _vm.mobiTitleWidthStyle,
            attrs: { "is-mobi": "" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "g_mobi_header__rightbar",
              style: _vm.modeHeaderRightBarStyle,
            },
            [
              _c("section-member-login", { attrs: { "is-mobi": "" } }),
              _vm._v(" "),
              _vm.renderMultiLanguageCore
                ? _c("section-multi-language", { attrs: { "use-core": "" } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }