/**
 *
 * @function name - getImgComponetOption
 * @description 传入模块数据和配置返回图片组件需要的图片特效数据
 * @param module  {String} - 图片地址
 * @param imgId {String} - 换图特效，多图类型的模块需要传递图片的id来查找到具体换的图片，不传则直接拿commProp.pa.hfPath.
 * @return  {Object} - 图片组件需要的配置
 */
export const getImgComponetOption = (module = {}, imgId) => {
    if (!Object.getOwnPropertyNames(module).length) {
        return {};
    }
    let effects = module?.commProp?.pa || {};
    let optional = {};
    if (effects?.b?.y) {
        optional.border = {
            size: effects?.b?.bw,
            color: effects?.b?.bc,
        };
    } else {
        if (typeof window !== 'undefined') {
            optional.border = {
                color: window._store.state.responsiveInfo.themeColor, //主题色
            };
        }
    }
    if (effects.bg?.y) {
        optional.bgColor = effects.bg?.c;
    }
    let switchImg = '';
    if (imgId) {
        switchImg = effects?.hfList?.find((item) => item.id == imgId)?.hfPath;
    } else {
        switchImg = effects?.hfPath;
    }
    return {
        type: effects.t,
        mode: effects.st,
        dir: effects.r - 1,
        switchImg,
        ...optional,
    };
};

/**
 *
 * @function name - imgOptsComputed
 * @description 该函数是获取图片特效配置项的computed版本，适合没有换图特效的使用，可以提升性能，否则大量图片的场景可能造成卡顿。getImgComponetOption是methods版本。
 */
export function imgOptsComputed() {
    let effects = this.module?.commProp?.pa || {};
    let optional = {};
    if (effects?.b?.y) {
        optional.border = {
            size: effects?.b?.bw,
            color: effects?.b?.bc,
        };
    } else {
        if (typeof window !== 'undefined') {
            optional.border = {
                color: window._store.state.responsiveInfo.themeColor, //主题色
            };
        }
    }
    if (effects.bg?.y) {
        optional.bgColor = effects.bg?.c;
    }
    let switchImg = effects?.hfPath;
    return {
        type: effects.t,
        mode: effects.st,
        dir: effects.r - 1,
        switchImg,
        ...optional,
    };
}
