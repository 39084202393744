//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { logoutMember } from '@/components/modules/memberCenter/index.js';
export default {
    name: 'LoggedInSite',
    computed: {
        ...Vuex.mapState(['LS', 'sections']),
        className() {
            return this.sections.memberLevelClass;
        },
        mCenterUrl() {
            return this.sections.mCenterUrl;
        },
        name() {
            return this.sections.memberName;
        },
        iconStyle() {
            return this.sections.memberIconStyle;
        },
        memberLevelAuthInfo() {
            return this.sections.memberLevelAuthInfo;
        },
    },
    mounted() {
        //源feissr搬迁过来的
        resetMargin($('#sectionMemberLogin'));
    },
    methods: {
        logoutClick() {
            logoutMember();
        },
    },
};

function resetMargin($el) {
    if (isFirstInit($el)) {
        const $webColContent = $($el).parent();
        const webColContentWidth = $webColContent.width();
        const moduleWidth = $($el).width();
        const marginLeft = (webColContentWidth - moduleWidth) / 2;
        const marginLeftPercent = marginLeft / webColContentWidth;
        const marginTop = 15;
        const marginTopPercent = marginTop / webColContentWidth;
        $el.css('margin-top', `${marginTopPercent * 100}%`);
        $el.css('margin-left', `${marginLeftPercent * 100}%`);
        $el.css('margin-right', `${marginLeftPercent * 100}%`);
    }
}

function isFirstInit($el) {
    const marginTop = parseInt($el.css('margin-top'));
    const marginLeft = parseInt($el.css('margin-left'));
    const marginRight = parseInt($el.css('margin-right'));
    const $webColContent = $($el).parent();
    const webColContentWidth = $webColContent.width();

    return (
        marginTop == 0 &&
        Math.round((marginLeft / webColContentWidth) * 100) == 50 &&
        Math.round((marginRight / webColContentWidth) * 100) == 50
    );
}
