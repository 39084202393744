import CarouselStyle from './carousel/index.vue';

const STYLE_CAROUSEL = 4;

export function getComponentByStyle(moduleStyle) {
    let component = null;
    switch (moduleStyle) {
        case STYLE_CAROUSEL:
            component = CarouselStyle;
            break;
    }
    return component;
}
