//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fileUpload2 } from '@/manage/shared/dependencyInterface/index.js';
import { Comm } from '../../utils';
const { getComputedTextColor, extend, getStyleDataByDevice, isNewModuleAfter20210707, bindFlag } = Comm;
import { decodeHtml } from '@/components/utils.js';

import { warningMessage } from '@/site/shared/dialog/index.js';
import { logDog } from '@/site/shared/log/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
const manageUse = {
    components: {},
};
export default {
    name: 'FileDownload',
    style: 27,
    components: {
        ...manageUse.components,
        PaginationComponent,
    },
    props: ['id', 'module'],
    data() {
        let fileTypeSvg = new Map([
            ['.pdf', '#icon_pdf'],
            ['.doc', '#icon_word'],
            ['.xls', '#icon_exls'],
            ['.xlsx', '#icon_exls'],
            ['.docx', '#icon_word'],
            ['.pptx', '#icon_ppt'],
            ['.ppt', '#icon_ppt'],
            ['.txt', '#icon_txt'],
            ['.mp4', '#icon_video'],
            ['.avi', '#icon_video'],
            ['.rm', '#icon_video'],
            ['.mp3', '#icon_Music'],
            ['.zip', '#icon_GZIP'],
            ['.rar', '#icon_GZIP'],
            ['.jpg', '#icon_image'],
            ['.png', '#icon_image'],
            ['.gif', '#icon_image'],
        ]);
        return {
            textPattern: this.module.privatePattern.pc.itt,
            currentPage: 1,
            fileTypeSvg,
            showViewIcon: -1,
        };
    },
    computed: {
        ...Vuex.mapState(['LS', 'isJuly3FreeUser', 'manageMode']),
        themeColor() {
            return this.$store.state.responsiveInfo.themeColor;
        },
        // 模块样式
        styleType() {
            return this.module.prop0;
        },
        // 控制模块样式的类名
        styleClass() {
            return `jz_file_download_${this.module.prop0}`;
        },
        classes() {
            const classes = [];
            if (this.isNewModuleAfter20210707) {
                classes.push('file_download_new_module_after_20210707');
            }
            return classes;
        },
        // 自定义文字设置
        textStyle() {
            let defaultTextType = this.module.privatePattern.pc.itt.y;
            if (defaultTextType == 0) {
                return {};
            }
            let {
                s: fontSize,
                c: fontColor,
                f: fontFamily,
                w: fontWeight,
                i: fontStyle,
                d: fontDecoration,
            } = this.textPattern;

            fontStyle = fontStyle == 0 ? 'normal' : 'italic';
            fontWeight = fontWeight == 0 ? 'normal' : 'bold';
            fontDecoration = fontDecoration == 0 ? 'normal' : 'underline';
            let style = {
                'font-size': fontSize + 'px',
                'font-family': fontFamily,
                'font-style': fontStyle,
                'font-weight': fontWeight,
                'text-decoration': fontDecoration,
            };
            const textColorStyle = getComputedTextColor(fontColor);
            extend(style, textColorStyle);
            return style;
        },
        nameStyle() {
            const style = {};
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.fnf,
                mobiData: this.module.privatePattern.mobi.fnf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);

            if (!this.isNewModuleAfter20210707 && font.y === 0) {
                extend(style, this.textStyle);
            }
            return style;
        },
        sizeStyle() {
            const style = {};
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.fsf,
                mobiData: this.module.privatePattern.mobi.fsf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);

            if (!this.isNewModuleAfter20210707 && font.y === 0) {
                extend(style, this.textStyle);
            }
            return style;
        },
        downloadBtnStyle() {
            const style = {};
            if (!this.isNewModuleAfter20210707) {
                extend(style, this.textStyle);
            }
            return style;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        // 文件列表
        fileList() {
            if (Array.isArray(this.module.prop3)) {
                let fileListArr = [];
                let re = /\.pdf$/;
                this.module.prop3.forEach((file) => {
                    let { fileName, size, fileId, filePath, sourcePath } = file;
                    size = this.transformSize(size);
                    const isPdf = re.test(fileName) && !this.module.isAndroid;
                    fileListArr.push({
                        name: fileName,
                        size,
                        id: fileId,
                        path: filePath,
                        sourcePath,
                        isPdf,
                    });
                });
                return fileListArr;
            }
            return [];
        },
        renderFileList() {
            if (this.openPagination && this.fileList.length >= this.paginationPageSize) {
                if (this.paginationStyleIndex != 2) {
                    let sliceStart = (this.currentPage - 1) * this.paginationPageSize;
                    let sliceEnd = this.currentPage * this.paginationPageSize;
                    return this.fileList.slice(sliceStart, sliceEnd);
                }
                return this.fileList.slice(0, this.currentPage * this.paginationPageSize);
            }
            return this.fileList;
        },
        // 分页样式
        paginationStyleIndex() {
            return this.module.prop4;
        },
        // 分页每页显示条数
        paginationPageSize() {
            return this.module.prop2;
        },
        // 分页页数
        paginationPageCount() {
            let list = this.fileList.length;
            return list % this.paginationPageSize == 0
                ? list / this.paginationPageSize
                : Math.ceil(list / this.paginationPageSize);
        },
        buttonType() {
            if (this.styleType == 1) {
                return '<svg width="16px" height="16px" fill="#7E858C"><use xlink:href="#icon_download"></use></svg>';
            }
            return this.LS.fileDownload;
        },
        isMobi() {
            return this.$store.state.device == 'mobi';
        },
        svgStyle() {
            return this.isMobi ? { width: '36px', height: '36px' } : { width: '48px', height: '48px' };
        },
        openPagination: bindFlag(0x1),
        flagMemberDowm: bindFlag(0x10),
        flagMemberGroupDown: bindFlag(0x20),
        flagMemberOnlyLevel: bindFlag(0x40),
        modulePcPattern() {
            return this.module?.pattern?.pc ?? {};
        },
        moduleMobiPattern() {
            return this.module?.pattern?.mobi ?? {};
        },
        modulePcBg() {
            return this.modulePcPattern.bg || {};
        },
        moduleMobiBg() {
            return this.moduleMobiPattern.bg || {};
        },
        moduleBg() {
            return this.isMobi ? this.moduleMobiBg : this.modulePcBg;
        },
        fileItemStyle() {
            const styles = {};
            if (this.isMobi) {
                // 手机默认，根据pc视图
                if (this.moduleMobiBg.y === 0 && this.modulePcBg.y === 2) {
                    styles.background = 'none';
                }
                if (this.moduleMobiBg.y === 2) {
                    styles.background = 'none';
                }
            } else {
                if (this.modulePcBg.y === 2) {
                    styles.background = 'none';
                }
            }
            return styles;
        },
        moduleOther() {
            return this.module.blob0 || {};
        },
    },
    watch: {
        paginationStyleIndex() {
            this.currentPage = 1;
        },
        renderFileList: {
            deep: true,
            handler() {
                this.currentPage =
                    this.renderFileList.length == 0 && this.currentPage > 1 ? this.currentPage - 1 : this.currentPage;
            },
        },
    },
    mounted() {},
    methods: {
        decodeHtml,
        checkPreviewPdf(event, pdfPath) {
            event.preventDefault();
            if (this.manageMode || !this.module.allowedMemberDownload) {
                return window.open(pdfPath);
            }
            if (this.moduleOther.vt == 0) {
                return window.open(pdfPath);
            }
            // 会员已经登录
            if (this.module.mId) {
                if (this.module.canViewPdf) {
                    return window.open(pdfPath);
                }
                warningMessage(this.module.cantViewTips);
            } else {
                warningMessage(this.LS.fileDownloadLogin, true);
            }
        },
        transformSize(size) {
            if (!size) {
                return '-';
            }
            let result = size / 1024 / 1024;
            let unit = Number.parseInt(result) > 0 ? 'MB' : 'KB';
            if (unit == 'MB') {
                if (result % 1 > 0) {
                    result =
                        Number.parseInt((result % 1) * 10) > 0
                            ? result.toFixed(1) + unit
                            : Number.parseInt(result) + unit;
                } else {
                    Number.parseInt(result) + unit;
                }
            } else {
                result *= 1024;
                if (result % 1 > 0) {
                    result =
                        Number.parseInt((result % 1) * 10) > 0
                            ? result.toFixed(1) + unit
                            : Number.parseInt(result) + unit;
                } else {
                    result = Number.parseInt(result) + unit;
                }
            }
            return result;
        },
        // 改变页数
        handlePaginationPageChange() {},
        downloadFile(e) {
            // 访客态
            if (!this.manageMode) {
                // 仅会员下载
                if (
                    (this.module.memberScanType == 1 || this.module.memberScanType == 2) &&
                    this.module.allowedMemberDownload
                ) {
                    if ((this.flagMemberDowm || this.flagMemberGroupDown) && this.module.allowedMemberDownload) {
                        // 会员已经登录
                        if (this.module.mId) {
                            if (this.module.memberScanType == 1) {
                                // 不够等级
                                if (this.flagMemberOnlyLevel) {
                                    if (!this.module.memberOnlyLevelIsOk) {
                                        e.preventDefault();
                                        warningMessage(this.LS.resNotAllowMemberDownLoad, true);
                                    }
                                } else {
                                    if (!this.module.memberLevelEnough) {
                                        e.preventDefault();
                                        warningMessage(
                                            Fai.format(
                                                this.LS.resMemberDownloadLevelLimit,
                                                this.module.downloadLimitName
                                            ),
                                            // `亲爱的,您还需要升级到${this.module.downloadLimitName}才能下载该文件。`,
                                            true
                                        );
                                    }
                                }
                            } else if (this.module.memberScanType == 2) {
                                if (!this.module.allowGroupFileDown) {
                                    e.preventDefault();
                                    warningMessage(this.LS.resNotAllowMemberDownLoad, true);
                                }
                            }
                        } else {
                            e.preventDefault();
                            warningMessage(this.LS.fileDownloadLogin, true);
                        }
                    } else {
                        e.preventDefault();
                        warningMessage(this.LS.fileDownloadLogin, true);
                    }
                }
            }
        },
        checkFileTypeSvg(filename) {
            for (let fileType of this.fileTypeSvg.entries()) {
                if (filename) {
                    if (filename.toLocaleLowerCase().endsWith(fileType[0])) {
                        return fileType[1];
                    }
                }
            }
            return '#icon_file';
        },
        addFile() {
            if (!this.manageMode) {
                return;
            }
            fileUpload2(
                false,
                {
                    title: '添加文件',
                    imgMode: -1,
                    maxUploadList: 10,
                    maxChoiceList: 10,
                    from: 'file',
                },
                (back) => {
                    addFileCallBack(this.module, back);
                }
            );
        },
        setBgColor(e) {
            if (!this.isMobi) {
                $(e.target).css('background-color', this.themeColor);
                if (this.styleType == 0) {
                    $(e.target).children().css('color', 'white');
                } else if (this.styleType == 1) {
                    $(e.target)
                        .find('a')
                        .html(
                            '<svg width="16px" height="16px" fill="#fff"><use xlink:href="#icon_download"></use></svg>'
                        );
                }
            }
        },
        deleteBgColor(e) {
            if (!this.isMobi) {
                $(e.target).css('background-color', '');
                if (this.styleType == 0) {
                    $(e.target).children().css('color', '');
                } else if (this.styleType == 1) {
                    $(e.target)
                        .find('a')
                        .html(
                            '<svg width="16px" height="16px" fill="#7E858C"><use xlink:href="#icon_download"></use></svg>'
                        );
                }
            }
        },
        onShowViewIcon(index) {
            this.showViewIcon = index;
        },
        onHideViewIcon() {
            this.showViewIcon = -1;
        },
        viewFile() {
            !this.manageMode && logDog(201610, 3);
        },
    },
};
