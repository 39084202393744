export default {
    namespaced: true,
    state: () => ({
        isOpenFixedHeight: false,
        isOpenFixedTop: false,
        // 固定顶部
        scrollToTop: true,
        headerStyleStatus: {
            status: false,
            headerHeight: 0,
        },
    }),
    mutations: {
        setIsOpenFixedHeight(state, value) {
            state.isOpenFixedHeight = value;
        },
        setIsOpenFixedTop(state, value) {
            state.isOpenFixedTop = value;
        },
        changeHeaderStyleStatus(state, { status, headerHeight }) {
            (status !== null || status !== undefined) && (state.headerStyleStatus['status'] = status);
            (headerHeight !== null || headerHeight !== undefined) &&
                (state.headerStyleStatus['headerHeight'] = headerHeight);
        },
        setScrollToTop(state, flag) {
            state.scrollToTop = flag;
        },
    },
};
