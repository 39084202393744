/**
 * 滚动锚点 Service
 *
 *
 * 状态:
 *      activeAnchor: {
 *          colId: number    // 锚点绑定的栏目 ID
 *          rowId: number    // 锚点绑定的通栏ID
 *          moduleId: number // 锚点绑定的模块ID
 *      }
 *
 * 锚点结构:
 *      Anchor {
 *          colId: number
 *          rowId: number
 *          moduleId: number
 *          element: HTMLElement
 *          top: number
 *          left: number
 *          bottom: number
 *      }
 *
 * 普通栏目:
 *      1. 获取导航所有绑定的锚点列表 anchors
 *      2. 绑定 scroll 事件，动态查找 anchors 对应节点的 BoundingRect 的 top , 并 set activeAnchor   // 由于模块可以设置动画，所以不能按开始时记录的位置
 *      3. 当 activeAnchor变化时，导航链接匹配到对应的锚点则选中
 *
 * 全屏栏目:
 *      1. 获取导航所有绑定的锚点列表 anchors
 *      2. 当 全屏栏目 滚动时找到 anchors, 并根据 anchor colId 和 top 位置与 scroll 位置触发 activeAnchor 更新
 *      3. 当 activeAnchor变化时，导航链接匹配到对应的锚点则选中
 *
 */

import { ServiceFactory } from './factory';
import { throttle } from '@jz/utils';

const SERVICE_INTERFACE = 'ScrollAnchor';

/**
 * @typedef {Object} Anchor
 * @param {number} - colId
 * @param {HTMLElement} - element
 * @param {number} - top
 * @param {number} - left
 *
 */

export class ScrollAnchorService {
    /**
     * @param {Anchor[]} anchors
     */
    constructor(anchors = []) {
        this.anchors = anchors;
        this.store = window._store;
    }

    /**
     * 监听全屏栏目 通栏 change 事件
     */
    listenInFullScreen() {
        const FullScreenRowChangedEvent = 'row/setRollingScreenRowInfo';
        this.store.subscribe((mutation) => {
            if (mutation.type === FullScreenRowChangedEvent) {
                this._updateAnchorsPosition();
                const changedRowId = mutation.payload.rowId;
                if (mutation.payload.curIndex >= 0) {
                    const activeAnchor = this.anchors.find((anchor) => {
                        return anchor.colId === this.store.state.colId && anchor.rowId === changedRowId;
                    });
                    this._updateActiveAnchor({
                        rowId: activeAnchor ? activeAnchor.rowId : -1,
                    });
                }
            }
        });
    }

    /**
     * 普通栏目滚动监听锚点
     */
    listen() {
        const headerFixedHeight =
            document.querySelector('.g_header_content__fixed')?.getBoundingClientRect()?.bottom ?? 0;
        window.addEventListener(
            'scroll',
            throttle(() => {
                this._updateAnchorsPosition();
                const activeAnchor = this.anchors.find((anchor) => {
                    if (anchor.colId === this.store.state.colId) {
                        const canVisibleTop = window.innerHeight - headerFixedHeight;
                        return anchor.top <= canVisibleTop && anchor.bottom >= 0;
                    }
                    return false;
                });
                if (!activeAnchor) {
                    this._updateActiveAnchor({ moduleId: -1, rowId: -1 });
                    return;
                }
                this._updateActiveAnchor({
                    rowId: activeAnchor.rowId,
                    moduleId: activeAnchor.moduleId,
                });
            }, 20 /** default */)
        );
    }

    static createAnchor({ colId = -1, rowId = -1, moduleId = -1, element = null, top = -1, left = -1 }) {
        if (!element) {
            if (rowId !== -1) {
                element = document.querySelector(`#row${rowId}`);
            }
            if (moduleId !== -1) {
                element = document.querySelector(`#module${moduleId}`);
            }
        }

        if (element) {
            let rect = null;
            if (top === -1) {
                rect = element.getBoundingClientRect();
                top = rect.top;
            }
            if (left === -1) {
                rect = rect ?? element.getBoundingClientRect();
                left = rect.left;
            }
        }

        return {
            colId,
            rowId,
            moduleId,
            element,
            top,
            left,
        };
    }

    static isAnchorHref(href = '') {
        return href.includes('jumpToAnchor');
    }

    /**
     * 链接组成: JZ.jumpToAnchor($colId, row_$rowId | module_$moduleId, $href)
     * @param {string} href
     * @returns
     */
    static parseAnchorHref(href = '') {
        try {
            const [, colId, anchor] = /jumpToAnchor\(([\d]+),(.*),(.*)\)/g.exec(href);
            const rowId = parseInt(anchor.match(/row_([\d]+)/)?.[1] ?? -1);
            const moduleId = parseInt(anchor.match(/module_([\d]+)/)?.[1] ?? -1);

            return {
                colId: parseInt(colId),
                rowId,
                moduleId,
            };
        } catch (error) {
            console.error(`[ScrollAnchor.parseAnchorHref] error: ${error}`);
            return {
                colId: -1,
                rowId: -1,
                moduleId: -1,
            };
        }
    }

    _updateActiveAnchor({ moduleId = -1, rowId = -1 }) {
        const type = 'scrollAnchor/setActiveAnchor';
        this.store.commit(type, {
            moduleId,
            rowId,
        });
    }

    _updateAnchorsPosition() {
        this.anchors = this.anchors.map((anchor) => {
            const rect = anchor.element?.getBoundingClientRect() ?? {};
            return {
                ...anchor,
                top: rect.top,
                left: rect.left,
                bottom: rect.bottom,
            };
        });
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: ScrollAnchorService,
});
