function keepLastIndex(obj) {
    if (window.getSelection) {
        //ie11 10 9 ff safari
        obj.focus(); //解决ff不获取焦点无法定位问题
        let range = window.getSelection(); //创建range
        range.selectAllChildren(obj); //range 选择obj下所有子内容
        range.collapseToEnd(); //光标移至最后
    } else if (document.selection) {
        //ie10 9 8 7 6 5
        let range = document.selection.createRange(); //创建选择对象
        //var range = document.body.createTextRange();
        range.moveToElementText(obj); //range定位到obj
        range.collapse(false); //光标移至最后
        range.select();
    }
}

function linkTarget(isBlank) {
    return isBlank ? '_blank' : '';
}

function linkNofollow(nofollow) {
    return nofollow ? 'nofollow' : '';
}

export { keepLastIndex, linkTarget, linkNofollow };
