import { Module } from '@shared/visitor/module/index.js';
//产品分类模块
class ProductNavModule extends Module {
    constructor(...args) {
        super(...args);
        this.initMobiEvent();
    }

    initMobiEvent() {
        $(this.$el).on('click', '.arrow', function () {
            $(this).toggleClass('open').parents('li:eq(0)').children('ul').slideToggle(300);
            return false;
        });
    }
}

export { ProductNavModule };
