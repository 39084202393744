//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'MobiGroupPanel',
    components: {},
    props: ['group', 'colId', 'isColumnNav', 'moduleId'],

    data() {
        return {};
    },
    computed: {
        LS() {
            return this?.$store?.state?.LS || LS;
        },
    },
    methods: {
        close() {
            this.$emit('on-closepanel');
        },
        getSelectClass(item) {
            return this.isColumnNav && item.id === this.colId ? 'jz_theme_font_color' : '';
        },
    },
};
