//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getComputedBg } from '@shared/manage/utils/index.js';
import JzBackTop from './components/system/JzBackTop.vue';
import FullScreenPagination from './components/grid/row/pureFullScreen/FullScreenPagination.vue';
import GridHeader from './components/grid/gridHeader.vue';
import GridFooter from './components/grid/gridFooter.vue';
import SuspendServ from './components/system/suspendServ/index.vue';
import JzBanner from './components/grid/banner/jzBanner.vue';
import GridMobiHeader from './components/grid/gridMobiHeader.vue';
import WelcomePage from './components/system/WelcomePage.vue';
import FootNav from './components/system/footNav/index.vue';
import DemoPreview from './components/demoPreview/index.vue';
import ClientOnly from 'vue-client-only';


export default {
    name: 'app',
    components: {
        GridHeader,
        GridFooter,
        JzBackTop,
        FullScreenPagination,
        SuspendServ,
        JzBanner,
        GridMobiHeader,
        WelcomePage,
        FootNav,
        DemoPreview,
        ClientOnly,
    },
    data() {
        return {
            webShow: 1,
            webStyle: true,
            popupWindow: [],
            hoverToolbar: [],
            mobiDesignerBtn: '',
            isMounted: false,
        };
    },
    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState(['device', 'manageMode', 'colId', 'safeMode', 'fullScreen', 'modules']),
        ...Vuex.mapState('manage/material', ['materialFactory']),
        ...Vuex.mapState('manage/nav', ['isShowFullNavExitTips', 'isEnterFullNavFromPanel', 'updateFullNavKey']),
        ...Vuex.mapState('manage', ['jzPreviewAreaHeight', 'vistorPreviewing', 'showSiteTip', 'settingBarStatus']),
        ...Vuex.mapState('header', ['headerStyleStatus']),
        ...Vuex.mapState('footer', ['footerStyleStatus']),
        ...Vuex.mapState('row', ['rollingScreenRowInfo', 'rowEditing']),
        ...Vuex.mapGetters(['info']),
        updateFullNavKeyInManageMode() {
            return !this.manageMode ? 0 : this.updateFullNavKey;
        },
        rootClass() {
            return {
                ['jz_root_' + this.device]: true,
                jz_editor__active: this.manageMode && !!this.$store.state.moduleEditorActiveId,
            };
        },

        showFullNav() {
            return this.info.pattern.nav.type === 1;
        },

        showBanner() {
            return this.info.pattern.independent.self &&
                this.info.pattern.independent.selfBanner &&
                !this.isMobi &&
                this.info.pattern.independent.open
                ? !this.info.pattern.independent.banner.hide
                : (!this.isMobi && !this.info.banner.hide) ||
                      (this.isMobi && !this.info.banner.hide && !this.info.banner.mobiHide);
        },

        isMobi() {
            return this.device == 'mobi';
        },

        siteStyle() {
            let background =
                this.info.pattern.independent.self &&
                this.info.pattern.independent.selfBg &&
                this.info.pattern.independent.open
                    ? this.info.pattern.independent.bg
                    : this.info.pattern.background;

            let _style = getComputedBg(background);

            return Object.assign(_style, this.getFullScreenRowHeight);
        },

        fullScreenRowStyle() {
            let _style = {};
            return Object.assign(_style);
        },

        hasMobiFooterNavServer() {
            return !this.info.pattern.footNav.h;
        },
        mobiFooterNavServerHeight() {
            //目前固定是51，不可自定义
            return 51;
        },

        getFullScreenRowHeight() {
            if (!this.isMounted) {
                return {};
            }
            if (!this.isFullScreen) {
                return {};
            }

            let clientHeight = !this.isMobi || !this.manageMode ? '100vh' : `${this.jzPreviewAreaHeight}px`;
            let toolBarHeight = this.isMobi ? 0 : Comm.getPageTop();
            let pcHeaderDisplay = this.info.pattern.header.display;
            let hasHeader = !this.isMobi ? !pcHeaderDisplay : !pcHeaderDisplay && !this.info.pattern.header.mobiHide;
            let headerHeight = this.headerStyleStatus.headerHeight;
            let { topType, isFixedTop, bottomType } = this.info.pattern.rollingScreen;
            let footerHeight = this.footerStyleStatus.footerHeight;
            let hasMobiFooterNavServer = this.hasMobiFooterNavServer;
            let mobiFooterNavServerHeight = this.mobiFooterNavServerHeight;
            let { curIndex, isFooterPageActive } = this.rollingScreenRowInfo;

            let _style = {};
            let _excludeHeight = 0;
            let _includeHeight = 0;

            _excludeHeight += toolBarHeight;
            //占位的顶部
            if (!this.isMobi && hasHeader && topType == 2) {
                if (isFixedTop) {
                    _excludeHeight += headerHeight;
                } else if (curIndex == 0) {
                    _excludeHeight += headerHeight;
                }
            }

            if (this.isMobi && hasHeader) {
                const gridMobiHeaderHeight = 51; //顶部高度
                if (isFixedTop) {
                    _excludeHeight += gridMobiHeaderHeight;
                } else {
                    //不是固定顶部则只有第一屏有
                    if (curIndex == 0) {
                        _excludeHeight += gridMobiHeaderHeight;
                    }
                }
            }

            if (this.isMobi && hasMobiFooterNavServer) {
                _excludeHeight += mobiFooterNavServerHeight;
            }

            _style.height = `calc(${clientHeight} - ${_excludeHeight}px${
                _includeHeight === 0 ? '' : ` + ${_includeHeight}px`
            })`;

            //单独一屏的底部激活了
            if (isFooterPageActive && bottomType == 1) {
                _style.transform = `translate(0px, ${-footerHeight}px)`;
            }

            return _style;
        },

        jzWebClass() {
            return 'jz_manage_web_' + this.device;
        },

        fullScreenRowClass() {
            let _class = '';

            if (this.info.pattern.rollingScreen.type === 1 && this.info.pattern.rollingScreen.bottomType === 1) {
                _class += 'footer_last_page';
            }

            return _class;
        },

        siteBarHeight() {
            return !this.settingBarStatus && this.showSiteTip ? $('#sitetips').height() : 0;
        },

        toolBarStyle() {
            let _style = {};

            if (!this.settingBarStatus) {
                _style = {
                    ..._style,
                    top: `${this.siteBarHeight - 61}px`,
                    boxShadow: 'none',
                };
            }

            return _style;
        },

        editContainerStyle() {
            let _style = {};

            if (!this.manageMode) {
                return _style;
            }

            if (!this.settingBarStatus) {
                _style.top = `${0 + this.siteBarHeight}px`;
            }

            return _style;
        },
        fullNavRowIds() {
            this.manageMode && this.updateFullNavKey;
            return this.info.pattern.nav.rl.map(({ id }) => id);
        },
        layoutStatusModuleIds() {
            return this.info.layoutStatusModuleIds.filter((id) => {
                return `module${id}` in this.$store.state.modules;
            });
        },
    },
    watch: {
        device() {
            Vue.nextTick(Site.addQrCode);
            this.$nextTick(() => {
                if (!this.manageMode) {
                    return;
                }
                this?.$refs?.jzPreviewArea &&
                    window._store.commit('manage/jzPreviewAreaHeightChange', $(this?.$refs?.jzPreviewArea).height());
            });
        },
        'info.pattern.header': {
            deep: true,

            handler() {
                if (this.$refs.header) {
                    this.windowScrollTo(this.$refs.header);
                } else {
                    this.$nextTick(() => {
                        this.windowScrollTo(this.$refs.header);
                    });
                }
            },
        },
        'info.pattern.footer': {
            deep: true,

            handler() {
                if (this.$refs.footer) {
                    this.windowScrollTo(this.$refs.footer);
                } else {
                    this.$nextTick(() => {
                        this.windowScrollTo(this.$refs.footer);
                    });
                }
            },
        },
    },

    mounted() {
        this.isMounted = true;
        jzUtils.run({
            name: 'photoAnimation.publish',
            callMethod: true,
            base: JZ,
        });
        this.$nextTick(() => {
            if (!this.manageMode) {
                return;
            }
            this?.$refs?.jzPreviewArea &&
                window._store.commit('manage/jzPreviewAreaHeightChange', $(this?.$refs?.jzPreviewArea).height());
            this.$store.commit('manage/pageTopChange', Comm.getPageTop());
        });
    },

    methods: {
        windowScrollTo(VueComponent) {
            if (this.isFullScreen) return;

            if (window && VueComponent) {
                const dom = VueComponent.$el;
                window.scrollTo(dom.offsetLeft, dom.offsetTop);
            }
        },

        closeFullNavExitTips() {
            window._store.commit('manage/nav/setIsShowFullNavExitTips', false);
            localStorage.setItem('hasCloseFullNavExitTips', true);
        },
    },
};
