//
//
//
//
//
//

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            circles: Array(9)
                .fill('')
                .map((item, i) => i + 1),
        };
    },
    methods: {
        getClassName(circle) {
            return `fk-circle${circle} fk-child`;
        },
    },
};
