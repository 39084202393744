import Visitor from '../App.vue';
import { store, createNewStoreToSsr } from '@/store/store.js';
import { registerEmptyDirectives } from '../components/modules/activate.js';
import Vue from 'vue';
import { isBrowserEnv } from '@jz/utils';
import { registerGlobalComponents } from '@/site/registerGlobalComponents.js';

export function createApp() {
    registerGlobalComponents(Vue);
    const _store = isBrowserEnv() ? store : createNewStoreToSsr();
    registerEmptyDirectives();
    const app = new Vue({
        components: {
            Visitor,
        },
        store: _store,
        template: '<Visitor />',
    });
    return { app, store: _store };
}
