var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item_type_file" },
    [
      !_vm.newFileUploadStyle
        ? _c("div", { staticClass: "form_file_upload_container" }, [
            _c("div", { staticClass: "file_upload" }, [
              _vm.requiredIcon
                ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "file_name_text" }, [
                _c("input", {
                  staticClass: "input_style",
                  style: _vm.itemInputStyle,
                  attrs: { readonly: "", placeholder: _vm.item.placeholder },
                  domProps: { value: _vm.value.name },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.value.fileId,
                        expression: "value.fileId",
                      },
                    ],
                    staticClass: "del_file_btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("input", {})
                      },
                    },
                  },
                  [_c("span", { staticClass: "faisco-icons-S000338" })]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "uploadBtn",
                  staticClass: "file_upload_btn",
                  on: { click: _vm.uploadFile },
                },
                [
                  _c("span", { staticClass: "faisco-icons-S000339" }),
                  _vm._v(
                    _vm._s(_vm.siteFormSubmitFileUploadBtnText) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "file_size_tips" }, [
              _vm._v(
                _vm._s(_vm.siteFormSubmitFileUploadTips) +
                  " " +
                  _vm._s(_vm.fileLimitSize) +
                  "M"
              ),
            ]),
            _vm._v(" "),
            !_vm.newFileUploadStyle && _vm.manageMode && _vm.fileNumLimit > 1
              ? _c("div", { staticClass: "form_upload_tips" }, [
                  _c("span", [_vm._v("上传多个文件需")]),
                  _vm._v(" "),
                  _c("span", { on: { click: _vm.toNewFileUploadStyle } }, [
                    _vm._v("切换新样式"),
                  ]),
                ])
              : _vm._e(),
          ])
        : [
            _c("FileUpload", {
              attrs: {
                "file-size-limit": _vm.module.fileSizeLimit,
                "content-id": _vm.module.id,
                item: _vm.item,
                value: _vm.value,
              },
              on: { input: _vm.input },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }