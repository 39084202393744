//
//
//
//
//
//
//
//
//

export default {
    props: {
        keyword: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['LS']),
        searchResultText() {
            if (this.$isServer) {
                return '';
            }
            return Fai.format(this.LS.searchResultNotFound, this.keyword || '');
        },
    },
};
