var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["s_spserv__hoverarea", _vm.getWxAreaClass, _vm.ieNoWrapClass],
      style: [_vm.bgStyle, _vm.contentStyle],
    },
    [
      _vm.item.servType !== "5"
        ? [
            _c(
              "span",
              { staticClass: "s_spserv__simpletext s_spserv__line-2" },
              [_vm._v("\n            " + _vm._s(_vm.getContext) + "\n        ")]
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "s_spserv__close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.emitChangeCurIndex($event)
                  },
                },
              },
              [_c("use", { attrs: { "xlink:href": "#icon_material_close" } })]
            ),
          ]
        : [
            _c("span", { staticClass: "s_spserv__hovername s_spserv__ib" }, [
              _vm._v("\n            " + _vm._s(_vm.item.name) + "\n        "),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "s_spserv__wxpic",
              style: _vm.getWxServBg,
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "s_spserv__hovercont s_spserv__line-2" },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.item.content) + "\n        "
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }