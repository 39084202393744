//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixins } from './mixins';
const manageUse = {};

export default {
    mixins: [mixins],
    props: ['module'],
    methods: {
        hovermenu: manageUse.hovermenu || function () {},
    },
};
