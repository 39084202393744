// #region types
import { device } from '@/site/shared/device/index.js';

/**
 * 欢迎页类型
 * @readonly
 * @enum {number}
 */
export const WELCOME_PAGE_TYPE = {
    PICTURE: 0, // 图片
    VIDEO: 1, // 视频
};

/**
 * 图片缩放类型
 * @readonly
 * @enum {number}
 */
export const PICTURE_ZOOM_TYPE = {
    COVER: 0, // 填充
    CONTAIN: 1, // 等比例
    NO_REPEAT: 2, // 不平铺
};

// #region end

/**
 * @typedef WelcomePageConfig
 * @type {Object}
 * @property {boolean} autoEntry - 是否自动进入
 * @property {number} [timeout] - 多长时间后进入, 单位: 秒
 * @property {WELCOME_PAGE_TYPE} type
 * @property {string} resourcePath - 资源路径
 * @property {boolean} [countDownVisible] - 是否显示倒计时
 * @property {PICTURE_ZOOM_TYPE} [pictureZoomType] - 图片缩放类型
 */

const J_WELCOME_PAGE_HOOK = '.J_welcomePage';
const WELCOME_PAGE_COUNTDOWN = '.countdown';
const J_WELCOME_PAGE_IMAGE_EL_HOOK = '.J_welcomePageImage';

/**
 * @class
 * @classdesc 欢迎页控制器类
 */
class WelcomePage {
    /**
     *
     * @param {WelcomePageConfig} config
     */
    constructor(config) {
        /** @private */
        this._config = config;

        /** @private */
        this._timer = void 0;

        /** @private */
        this._restTimeout = this._config.timeout;

        /**
         * @type {HTMLElement}
         * @private
         */
        this._container = document.querySelector(J_WELCOME_PAGE_HOOK);

        this.initStyle();
        this.initEventListeners();
    }

    get autoEntry() {
        return this._config.autoEntry;
    }

    get timeout() {
        return this._config.timeout * 1000;
    }

    get restTimeout() {
        return this._restTimeout;
    }

    set restTimeout(value) {
        this._restTimeout = value;
    }

    get countDownIsVisible() {
        return this._config.countDownVisible;
    }

    /**
     * @param {boolean} visible
     */
    set countDownIsVisible(visible) {
        this._config.countDownVisible = visible;
    }

    get pictureZoomType() {
        return this._config.pictureZoomType;
    }

    get type() {
        return this._config.type;
    }

    get isVideoType() {
        return this.type === WELCOME_PAGE_TYPE.VIDEO;
    }

    get isPictureType() {
        return this.type === WELCOME_PAGE_TYPE.PICTURE;
    }

    get resourcePath() {
        return this._config.resourcePath;
    }

    /**
     * @private
     */
    initEventListeners() {
        if (this.autoEntry === true) {
            this.bindAutoEntryEvent();
        }
        this.onClickEntry();
    }

    /**
     * @private
     */
    initStyle() {
        this._overflowValueNotEmpty = getComputedStyle(document.body).getPropertyValue('overflow') !== 'visible';
        if (this._overflowValueNotEmpty === false) {
            this.hideScrollBar();
        }
        this.initImageStyle();
        if (this.isPictureType) {
            try {
                this.initImageStyle();
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     * @private
     * @throws {ReferenceError}
     */
    initImageStyle() {
        /** @type {HTMLElement} */
        const imageEl = this._container.querySelector(J_WELCOME_PAGE_IMAGE_EL_HOOK);
        if (imageEl === null) {
            throw ReferenceError(`[WelcomePage]: init image style error, because imageEL is null`);
        }

        // 图片地址
        const notMatched = window.getComputedStyle(imageEl).backgroundImage.includes(this.resourcePath) === false;
        if (notMatched) {
            imageEl.style.backgroundImage = `url(${this.resourcePath})`;
        }
        // 图片缩放类型
        const prefix = (str) => `welcome_page_image_zoom--${str}`;
        const zoomClassNames = [prefix('cover'), prefix('contain'), prefix('norepeat')];
        for (let className of zoomClassNames) {
            imageEl.classList.remove(className);
        }
        imageEl.classList.add(zoomClassNames[this.pictureZoomType]);
    }

    /**
     * @private
     * @description 绑定自动进入事件，判断是否有倒计时
     */
    bindAutoEntryEvent() {
        if (this.autoEntry === true) {
            try {
                this.countdown();

                const run = () => {
                    this.countdown();
                    if (this.restTimeout < 0) {
                        this.entry();
                    }
                };

                if (this.isVideoType) {
                    this.setTimerWithVideo(run);
                } else {
                    this.setTimer(run);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setTimeout(() => {
                this.entry();
            }, this.timeout);
        }
    }

    /**
     * @private
     */
    onClickEntry() {
        this._container.addEventListener('click', () => {
            this.entry();
        });
    }

    entry() {
        this._container.style.opacity = '0';

        /** @type {HTMLElement} */
        const webContainerEl = document.querySelector('#jzWebContainer');
        webContainerEl.style.overflow = '';
        if (webContainerEl.style.height === '0px') {
            // 避免有其他功能改变了 container 的高度
            webContainerEl.style.height = '';
        }

        this.clearTimer();
        this._container.addEventListener('transitionend', () => {
            if (this._overflowValueNotEmpty === false) {
                this.showScrollBar();
            }
            this.destroy();
            let entryEvent = null;
            try {
                entryEvent = new CustomEvent('WelcomePage.entry');
            } catch (error) {
                // Create the event.
                entryEvent = document.createEvent('CustomEvent');

                // Define that the event name is 'build'.
                entryEvent.initCustomEvent('WelcomePage.entry', true, true, {});
            }
            window.dispatchEvent(entryEvent);
        });
    }

    /**
     * @private
     * @throws {ReferenceError}
     * @description 初始化倒计时
     * 1. 显示倒计时 dom
     * 2. 绑定倒计时事件
     */
    countdown() {
        /** @type {HTMLElement} */
        const countDownElement = document.querySelector(`${J_WELCOME_PAGE_HOOK} ${WELCOME_PAGE_COUNTDOWN}`);
        if (countDownElement === null) {
            throw ReferenceError('[welcomePage]: countDownElement is null but config.countdownVisible is true.');
        }

        if (this.countDownIsVisible === true) {
            countDownElement.style.display = 'block';
        } else {
            countDownElement.style.display = 'none';
        }

        const effect = () => {
            countDownElement.textContent = `${this.restTimeout}s`;
            this.restTimeout--;
        };
        effect();
    }

    /**
     * @private
     * @param {Function} callback
     */
    setTimer(callback) {
        if (this._timer !== void 0) {
            clearTimeout(this._timer);
        }
        if (this.restTimeout <= 0) {
            callback();
            return;
        }
        this._timer = setTimeout(() => {
            callback();
            this.setTimer(callback);
        }, 1000);
    }

    /**
     *
     * @param {Function} callback
     * @param {Number} leadingTime - 单位: 秒
     * @returns
     */
    setTimerWithVideo(callback, leadingTime = 0) {
        /** @type {HTMLMediaElement} */
        const videoEl = document.querySelector('#J_welcomePageVideo');
        if (videoEl === null) {
            return;
        }

        // currentTime start from 0
        leadingTime = videoEl.currentTime - (this.timeout / 1000 - this.restTimeout - 1);
        if (leadingTime > 1) {
            this.restTimeout -= Math.round(leadingTime);
            leadingTime = leadingTime % 1;
        }

        if (this.restTimeout <= 0) {
            callback();
            return;
        }

        this._timer = setTimeout(() => {
            callback();
            this.setTimerWithVideo(callback);
        }, 1000 - Math.max(0, leadingTime) * 1000);
    }

    /**
     * @private
     */
    clearTimer() {
        if (this._timer !== void 0) {
            clearTimeout(this._timer);
            this._timer = void 0;
        }
    }

    /**
     * @private
     */
    hideScrollBar() {
        document.body.style['overflow-y'] = 'hidden';
    }

    /**
     * @private
     */
    showScrollBar() {
        document.body.style['overflow-y'] = 'visible';
    }

    /**
     * 显示/隐藏 container
     * @param {boolean} visible
     */
    toggleContainerVisible(visible) {
        /** @type {HTMLElement}  */
        const containerEl = document.querySelector('#jzWebContainer');
        containerEl.style.display = visible === true ? 'block' : 'none';
    }

    destroy() {
        if (!this?._container?.remove) {
            // ie不支持remove
            document.body.removeChild(this?._container);
        }
        this?._container?.remove?.();
    }

    reset() {
        this.clearTimer();
    }
}

/**
 *
 * @param {WelcomePageConfig} config
 * @returns {WelcomePage}
 */
export function createWelcomePage(config) {
    return new WelcomePage(config);
}

/**
 * @typedef WelcomePageData
 * @type {Object}
 * @property {boolean} allowOpen    权限
 * @property {boolean} open         PC开启
 * @property {boolean} mobiOpen     Mobi开启
 * ......
 */

/**
 * @class
 * @classdesc 欢迎页视图类
 */
class WelcomePageView {
    /**
     *
     * @param {WelcomePageData} welcomePageData
     * @param {string} device
     */
    constructor(welcomePageData, device) {
        /** @type {WelcomePageData} */
        this._data = welcomePageData;
        this.device = device;
        this._deviceChangeInitialed = false;
        this._WelcomePageInstance = null;

        this.init();
    }

    get welcomePageData() {
        return Object.freeze(this._data);
    }

    get WelcomePageInstance() {
        return this._WelcomePageInstance;
    }

    init() {
        this._WelcomePageInstance = createWelcomePage(this.getInitialConfig());
        this.bindDeviceChangedEvent();
    }

    getInitialConfig() {
        const welcomePageData = this.welcomePageData;

        const welcomePageSetting = welcomePageData[this.device === 'pc' ? 'pc' : 'mobi'];

        const isMobileView = this.device === 'mobi';
        let type = welcomePageSetting.type;
        let autoEntry = welcomePageSetting.enterIndex === 0; // 0: 自动进入
        let timeout = welcomePageSetting.aet;
        let pictureZoomType = welcomePageSetting.picInfo.zoomType;
        let countDownVisible = isMobileView ? welcomePageSetting.oct : false; // PC视图下没有倒计时显示
        let resourcePath =
            type === WELCOME_PAGE_TYPE.PICTURE
                ? welcomePageSetting.picInfo.picUrl
                : welcomePageSetting.videoInfo.videoUrl;

        if (isMobileView === true) {
            const PCData = welcomePageData['pc'];
            // 处理手机视图的默认值
            if (type === -1) {
                pictureZoomType = PCData.picInfo.zoomType;
                type = WELCOME_PAGE_TYPE.PICTURE; // 由于视频全屏自动播放在不同的手机浏览器兼容的限制, 手机视图必须是图片类型
                resourcePath = PCData.picInfo.picUrl;
            }
            if (welcomePageSetting.enterIndex === -1) {
                autoEntry = PCData.enterIndex === 0;
                if (autoEntry === true) {
                    timeout = PCData.aet;
                }
            }
            if (autoEntry === false) {
                countDownVisible = false;
            }
        }

        return {
            type,
            autoEntry,
            timeout,
            pictureZoomType,
            countDownVisible,
            resourcePath,
        };
    }

    bindDeviceChangedEvent() {
        if (this._deviceChangeInitialed) {
            return;
        }

        /**
         * 避免在加载过程中切换导致 device 判断不对，所以加载完成后要处理一次
         * @see https://www.tapd.cn/42954297/bugtrace/bugs/view?bug_id=1142954297001091661
         */
        window.addEventListener('load', () => {
            this.handleResponsive();
        });

        device.on(device.EVENTS.ON_CHANGE, (value) => {
            this.device = value;
        });

        this._deviceChangeInitialed = true;
    }

    // 自适应屏幕 当 device 改变时重新 init
    handleResponsive() {
        /** @type {HTMLElement} */
        const welcomePageEl = document.querySelector('#welcomePage');
        /** @type {HTMLElement} */
        const imageEL = document.querySelector('.J_welcomePageImage');
        /** @type {HTMLElement} */
        const videoEl = document.querySelector('.J_welcomePageVideoWrapper');

        if (welcomePageEl === null) {
            return;
        }

        if (this.isOpen()) {
            welcomePageEl.classList.remove('welcome_page--hidden');
        } else {
            welcomePageEl.classList.add('welcome_page--hidden');
            this.WelcomePageInstance.entry();
        }

        const resetVisible = () => {
            /** @type {NodeListOf<HTMLElement>} */
            const visibleElements = document.querySelectorAll('#welcomePage .welcome_page_wrapper--visible');
            if (visibleElements !== null) {
                Array.from(visibleElements).forEach((visibleElement) => {
                    visibleElement.classList.remove('welcome_page_wrapper--visible');
                });
            }
        };

        /**
         * @param {HTMLElement} el
         * @throws {ReferenceError}
         */
        const visible = (el) => {
            el.classList.add('welcome_page_wrapper--visible');
        };

        /**
         * @param {HTMLElement} el
         * @throws {ReferenceError}
         * @returns {boolean}
         */
        const isVisible = (el) => {
            return el.classList.contains('welcome_page_wrapper--visible');
        };

        const device = window.Comm.getDevice();

        this.WelcomePageInstance.reset();
        this.init();

        if (device !== 'pc') {
            resetVisible();
            visible(imageEL);
        } else {
            this.WelcomePageInstance.countDownIsVisible = false;
            const welcomePageSetting = this.welcomePageData[device === 'pc' ? 'pc' : 'mobi'];
            let type = welcomePageSetting.type;
            if (type === WELCOME_PAGE_TYPE.VIDEO && isVisible(videoEl) === false) {
                resetVisible();
                visible(videoEl);
            }
        }
    }

    isOpen() {
        const isMobileView = this.device === 'mobi';
        const welcomePageData = this.welcomePageData;
        if (welcomePageData.allowOpen === false || welcomePageData.open === false) return false;
        return (isMobileView && welcomePageData.mobiOpen === true) || (!isMobileView && welcomePageData.open === true);
    }
}

/**
 *
 * @param {WelcomePageData} data
 * @param {string} device
 */
export function initWelcomePageView(data, device) {
    return new WelcomePageView(data, device);
}
