var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "hovermenu",
        rawName: "v-hovermenu",
        value: {
          hovermenu: _vm.hovermenu,
          args: {
            moduleId: _vm.module.id,
            itemType: "time",
            itemId: _vm.id,
          },
        },
        expression:
          "{\n        hovermenu,\n        args: {\n            moduleId: module.id,\n            itemType: 'time',\n            itemId: id,\n        },\n    }",
      },
    ],
    staticClass: "timeline_item_time",
    domProps: { innerHTML: _vm._s(_vm.htmlValue) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }