var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module_content_detail module_timeline_content" },
    [
      _vm.timelineItems.length
        ? _c(_vm.styleComponent, { tag: "component" })
        : _vm.manageMode
        ? _c("timeline-empty")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }