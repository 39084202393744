import request from '@jz/request';

export function getIsInBaidu(data) {
    return request.post('/ajax/site_h.jsp?cmd=getWafNotCk_getIsInBaidu', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function checkBaiduAutomaticPush(data) {
    return request.post('/ajax/site_h.jsp?cmd=wafNotCk_checkBaiduAutomaticPush', { data }).then(({ data = {} }) => {
        return data;
    });
}
