//
//
//
//

export default {
    name: 'TimelineItemLine',
    inject: ['module'],
    computed: {
        pattern() {
            return this.module.privatePattern.pc;
        },
    },
};
