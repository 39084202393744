var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item_type_city" },
    [
      _c(
        "div",
        { staticClass: "select_wrap pc_select" },
        [
          _c("select-component", {
            attrs: {
              list: _vm.provinceList,
              value: _vm.currentVal.province,
              placeholder: _vm.placeholder,
              "required-icon": _vm.requiredIcon,
              "content-key": "name",
              "item-input-style": _vm.itemInputStyle,
            },
            on: {
              input: function ($event) {
                return _vm.selectProvince($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select_wrap pc_select" },
        [
          _c("select-component", {
            attrs: {
              list: _vm.cityList,
              value: _vm.currentVal.city,
              placeholder: _vm.placeholder,
              "required-icon": _vm.requiredIcon,
              "content-key": "name",
              "item-input-style": _vm.itemInputStyle,
            },
            on: {
              input: function ($event) {
                return _vm.selectCity($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.openMunicipalCode
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCountySelect,
                  expression: "showCountySelect",
                },
              ],
              staticClass: "select_wrap pc_select",
            },
            [
              _c("select-component", {
                attrs: {
                  list: _vm.countyList,
                  value: _vm.currentVal.county,
                  placeholder: _vm.placeholder,
                  "required-icon": _vm.requiredIcon,
                  "content-key": "name",
                  "item-input-style": _vm.itemInputStyle,
                },
                on: {
                  input: function ($event) {
                    return _vm.selectCounty($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "input_style mobi_select",
        style: _vm.itemInputStyle,
        attrs: { placeholder: _vm.placeholder, readonly: "" },
        domProps: { value: _vm.mobiValue },
        on: { click: _vm.showMobiSelect },
      }),
      _vm._v(" "),
      _c(
        "PopupLayerComponent",
        {
          attrs: { "is-link-to-form": _vm.isLinkToForm },
          model: {
            value: _vm.mobiShow,
            callback: function ($$v) {
              _vm.mobiShow = $$v
            },
            expression: "mobiShow",
          },
        },
        [
          _c("div", { staticClass: "p_mobi_address_picker" }, [
            _c("h2", { staticClass: "title" }, [_vm._v("请选择地区")]),
            _vm._v(" "),
            _c("ul", { staticClass: "select_title" }, [
              _c(
                "li",
                {
                  staticClass: "item",
                  class: { active: _vm.currentPage == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.selectMobiPage(0)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.currentVal.province.name || "省") +
                        "\n                        "
                    ),
                    _c("div", { staticClass: "line jz_theme_bg_color" }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "item",
                  class: { active: _vm.currentPage == 1 },
                  on: {
                    click: function ($event) {
                      return _vm.selectMobiPage(1)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.currentVal.city.name || "市") +
                        "\n                        "
                    ),
                    _c("div", { staticClass: "line jz_theme_bg_color" }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCountySelect,
                      expression: "showCountySelect",
                    },
                  ],
                  staticClass: "item",
                  class: { active: _vm.currentPage == 2 },
                  on: {
                    click: function ($event) {
                      return _vm.selectMobiPage(2)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.currentVal.county.name || "县/区") +
                        "\n                        "
                    ),
                    _c("div", { staticClass: "line jz_theme_bg_color" }),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "jz_scroll_wrap" }, [
              _c(
                "ul",
                {
                  ref: "list",
                  staticClass: "select_list jz_scroll",
                  on: {
                    touchstart: _vm.onTouchStart,
                    mousedown: _vm.onTouchStart,
                  },
                },
                _vm._l(_vm.mobiList, function (item) {
                  return _c(
                    "li",
                    {
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.selectMobiItem(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }