var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select_style" },
    [
      _vm.requiredIcon
        ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "select_input input_style",
        style: _vm.inputTextStyle,
        attrs: { readonly: "", placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "arrow faisco-icons-S000112",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleList.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list.length && _vm.showList,
              expression: "list.length && showList",
            },
          ],
          staticClass: "select_list_wrap",
        },
        [
          _c(
            "ul",
            { staticClass: "select_list" },
            _vm._l(_vm.list, function (item, i) {
              return _c(
                "li",
                {
                  key: i,
                  staticClass: "select_item",
                  class: { select_active: _vm.value === item },
                  on: {
                    click: function ($event) {
                      return _vm.selectItem(item)
                    },
                  },
                },
                [_vm._v("\n                " + _vm._s(item) + "\n            ")]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "custom-search-popup-layer",
        {
          model: {
            value: _vm.mobiShowList,
            callback: function ($$v) {
              _vm.mobiShowList = $$v
            },
            expression: "mobiShowList",
          },
        },
        [
          _c("div", { staticClass: "p_mobi_picker" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "btn cancel", on: { click: _vm.mobiCancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn confirm", on: { click: _vm.mobiConfirm } },
                [_vm._v("确定")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                ref: "list",
                staticClass: "list",
                on: {
                  touchstart: _vm.onTouchStart,
                  mousedown: _vm.onTouchStart,
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c("li", { key: index, staticClass: "item" }, [
                  _vm._v(_vm._s(_vm.getContentText(item))),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }