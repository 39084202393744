var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("client-only", [
    _c(
      "div",
      {
        staticClass: "iframe_module module_content_detail",
        class: { "font-size-none": _vm.showIframe },
      },
      [
        _vm.isEmptyIframe || _vm.showHttpsTips || !_vm.authSuccess
          ? _c(
              "div",
              { staticClass: "iframe_module--empty" },
              [
                !_vm.authSuccess && _vm.manageMode
                  ? _c("alert-component", { staticClass: "newSiteVis" }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.authInfo.verProm) },
                      }),
                      _vm._v(" "),
                      !_vm.authInfo.oem
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.authInfo.updateVesionViewUrl,
                                target: "_blank",
                              },
                            },
                            [_vm._v("立即升级")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showHttpsTips
                  ? _c("div", { staticClass: "show_https_tip" }, [
                      _vm._v(
                        "\n                当前模块存在非https链接，建议替换为https链接。或切换为"
                      ),
                      _c("span", { on: { click: _vm.changeToHttpProtocol } }, [
                        _vm._v("http访问"),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.authSuccess
          ? [
              _vm.isEmptyIframe && _vm.manageMode && !_vm.showHttpsTips
                ? _c("empty-tips", {
                    attrs: {
                      "is-mobi": _vm.isMobi,
                      "empty-tips": "请从编辑面板中添加页面链接",
                      "active-button-text": "添加链接",
                    },
                    on: { "click-active-button": _vm.openEditPanel },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showIframe
                ? _c("iframe", {
                    ref: _vm.iframeRef,
                    staticClass: "pointer_events_none",
                    attrs: { width: _vm.iframeWidth, height: _vm.iframeHeight },
                  })
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }