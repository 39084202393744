import request from '@jz/request';

export function getProductGroupList(data) {
    return request
        .post('/rajax/productGroup_h.jsp?cmd=getWafNotCk_getList&fullName=true', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCommentInfo(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductComment', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function submitComment(data) {
    return request.post('/ajax/productComment_h.jsp?cmd=addWafCk_submitPC', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductListByPage(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductListByPage', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getProductResultByPagination(params) {
    return request
        .get('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductResult', {
            params,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getPropNameByLib(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getScreenProduct', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function delProduct(data = {}) {
    return request.post('/ajax/product_h.jsp?cmd=delWafCk_del', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setProductList(data = {}) {
    return request.post('/rajax/productModule_h.jsp?cmd=setWafCk_setProductList', { data }).then(({ data = {} }) => {
        return data;
    });
}
