//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { timelineMixin } from './mixin';
import { LAYOUT, STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH } from './constants';
import { debounce } from '@jz/utils';
import { device } from '@/site/shared/device';

export default {
    name: 'TextHorizontal',
    mixins: [timelineMixin],
    data() {
        return {
            arrowTop: 0,
            scrollIndex: 0,
        };
    },
    computed: {
        ...Vuex.mapGetters(['isMobi']),
        showPrevArrow() {
            if (this.isMobi) {
                return false;
            }
            const scrollLimit = this.scrollIndex > 0;
            return this.timelineItemsLength > STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH && scrollLimit;
        },
        showNextArrow() {
            if (this.isMobi) {
                return false;
            }
            const scrollLimit = this.scrollIndex < this.timelineItemsLength - STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH;
            return this.timelineItemsLength > STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH && scrollLimit;
        },
        timelineItemsLength() {
            return this.timelineItems.length;
        },
        arrowStyle() {
            const style = {
                top: `${this.arrowTop}px`,
            };
            return style;
        },
        scrollContainerStyle() {
            const percent = (-100 / STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH) * this.scrollIndex;
            const style = {};
            if (percent !== 0) {
                style.transform = `translateX(${(-100 / STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH) * this.scrollIndex}%)`;
            }
            return style;
        },
        isCrossStyle() {
            if (this.isMobi) {
                return false;
            }
            return this.moduleLayout === LAYOUT.TWO;
        },
        moduleLayout() {
            return this.module.prop2;
        },
    },
    watch: {
        timelineItemsLength() {
            this.scrollIndex = 0;
        },
        isMobi() {
            this.scrollIndex = 0;
        },
    },
    mounted() {
        this.initFixArrowPosition();
    },
    beforeDestroy() {
        this.removeFixArrowPositionListener();
    },
    methods: {
        removeFixArrowPositionListener() {
            if (window.ResizeObserver) {
                if (this.resizeObserver) {
                    this.resizeObserver.disconnect();
                    this.resizeObserver = null;
                }
            } else {
                if (this.resizeFn) {
                    window.removeEventListener('resize', this.resizeFn);
                    device.off(device.EVENTS.ON_CHANGE_AFTER_TRANSITION, this.resizeFn);
                }
            }
            this.resizeFn = null;
        },
        initFixArrowPosition() {
            this.resizeFn = debounce(() => {
                this.fixArrowPosition();
            }, 16);
            if (window.ResizeObserver) {
                this.resizeObserver = new ResizeObserver(() => {
                    this.resizeFn();
                });
                this.resizeObserver.observe(this.$refs.timelineHorizontalEl);
                this.$watch(() => this.timelineItems, this.resizeFn);
            } else {
                this.fixArrowPosition();
                this.$watch(
                    () => JSON.stringify([this.moduleLayout, this.timelineItems, this.module.pattern.pc.mr]),
                    this.resizeFn
                );
                device.on(device.EVENTS.ON_CHANGE_AFTER_TRANSITION, this.resizeFn);
                window.addEventListener('resize', this.resizeFn);
            }
        },
        isEvenNumber(n) {
            return n % 2 === 0;
        },
        onPrev() {
            if (this.scrollIndex === 0) {
                return;
            }
            this.scrollIndex--;
        },
        onNext() {
            if (this.scrollIndex >= this.timelineItemsLength - STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH) {
                return;
            }
            this.scrollIndex++;
        },
        async fixArrowPosition() {
            await this.$nextTick();
            const $module = $(`#module${this.module.id}`);
            const $container = $module.find('.module_timeline_content');
            const $line = $module.find('.timeline_text_h_item_line');
            const containerTop = $container.offset().top;
            const lineTop = $line.offset().top;
            let $arrow = $module.find('.timeline_text_horizontal_prev');
            if ($arrow.length === 0) {
                $arrow = $module.find('.timeline_text_horizontal_next');
                if ($arrow.length === 0) {
                    return;
                }
            }
            // 最后的2是微调
            this.arrowTop = lineTop - containerTop - $arrow.height() / 2 - 2;
        },
    },
};
