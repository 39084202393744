var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline_text_vertical" },
    _vm._l(_vm.timelineItems, function (item) {
      return _c("div", { key: item.id, staticClass: "timeline_text_item" }, [
        _c(
          "div",
          {
            staticClass: "timeline_text_item_left",
            class: _vm.isAllTitleEmpty ? "timeline_line_empty_title_wrap" : "",
          },
          [
            _c("timeline-item-time", {
              attrs: { id: item.id, module: _vm.module, value: item.time },
            }),
            _vm._v(" "),
            _c("timeline-item-dot", { staticClass: "timeline_text_item_dot" }),
            _vm._v(" "),
            _c("timeline-item-line", {
              staticClass: "timeline_text_item_line",
            }),
            _vm._v(" "),
            _c("timeline-item-connect-content-line"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "timeline_text_item_right" },
          [
            _c("timeline-item-title", {
              attrs: { id: item.id, module: _vm.module, value: item.title },
            }),
            _vm._v(" "),
            _c("timeline-item-desc", {
              staticClass: "timeline_text_desc",
              class: [item.title === "" ? "mt0" : ""],
              attrs: { id: item.id, module: _vm.module, value: item.desc },
            }),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }