//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { bindFlag } = Comm;
import rowRespStyleCompon from './rowRespStyleCompon.vue';
import basicStyleCompon from './basicStyleCompon.vue';
import swipeStyleCompon from './swipeStyleCompon.vue';
import { animationMixin } from '../animationMixin';
import { photoGroupList } from '@shared/manage/photoGroup/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';

export default {
    name: 'ListPhotos',
    style: 3,
    components: {
        rowRespStyleCompon,
        basicStyleCompon,
        swipeStyleCompon,
        PaginationComponent,
    },
    mixins: [animationMixin],
    props: ['module'],
    data() {
        if (typeof this.module.photoList == 'undefined') {
            Vue.set(this.module, 'photoList', []);
        }
        let data = {
            currentPage: 1,
            photoList: [],
        };
        const isServer = typeof window === 'undefined';
        if (isServer || !window._store.state.manageMode) {
            data.usePhotoGroupList = this.sort(this.module.usePhotoGroupList);
            data.photoList = this.module.photoList;
            data.currentPage = this.module.initPage;
        }
        return data;
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        flagPagination: bindFlag(0x2),
        flagMobipagination: bindFlag(0x4),
        usePhotoGroup: bindFlag(0x1),
        paginationPageSize() {
            if (this.device == 'pc') {
                return this.module.prop2;
            } else {
                return this.flagMobipagination ? this.module.prop8 : this.module.prop2;
            }
        },
        paginationStyleIndex() {
            return this.module.prop6;
        },
        paginationPageCount() {
            const totalSize = this.allPhotoList.length;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },
        style() {
            return this.module.prop0;
        },
        localPhotoGroupList() {
            // 未使用
            const list = this.photoGroupList;
            // if (list.length < 1) Root.updatePhotoGroupList();
            return list;
        },
        allPhotoList() {
            let list = [];
            // 是否直接使用图册
            if (!this.usePhotoGroup) {
                list = this.module.prop1;
            } else {
                list = this.usePhotoGroupList;
            }
            // 是否需要分页，样式二不需要分页
            if (this.flagPagination && this.hasPagination) {
                this.photoList = list.slice(0, this.paginationPageSize);
            } else {
                this.photoList = list;
            }
            //全部图片
            this.module.photoList = list;
            return list;
        },
        renderCompon() {
            switch (this.style) {
                case 0:
                case 1:
                    return 'basicStyleCompon';
                case 2:
                    return 'rowRespStyleCompon';
                case 3:
                    return 'swipeStyleCompon';
            }
            return '';
        },
        privatePattern() {
            const device = this.device;
            const pattern = this.module.privatePattern[device];
            if (device !== 'pc') {
                //非pc的情况下混合pc pattern
                const pcPattern = this.module.privatePattern['pc'];
                return Object.assign({}, pcPattern, pattern);
            }
            return pattern;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        hasPagination() {
            return [0, 2].includes(this.style);
        },
    },
    watch: {
        style() {
            this.currentPage = 1;
        },
        paginationStyleIndex() {
            this.currentPage = 1;
            this.photoList = this.allPhotoList.slice(0, this.paginationPageSize);
        },
    },
    asyncComputed: {
        usePhotoGroupList: {
            lazy: true,
            get() {
                return photoGroupList.load([this.module.prop4]);
            },
            then(list) {
                this.refreshOneResAnimation();
                return this.sort(list);
            },
            default: [],
        },
    },
    methods: {
        handlePaginationPageChange(page) {
            const pageSize = this.paginationPageSize;
            const newPhotoList = this.allPhotoList.slice((page - 1) * pageSize, page * pageSize);
            if (this.paginationStyleIndex == 2) {
                // 如果是加载更多样式，则需要插入数组
                this.photoList = this.photoList.concat(newPhotoList);
            } else {
                this.photoList = newPhotoList;
            }
        },
        sort(list = []) {
            const photoGroup = list.find((photoGroup) => photoGroup.id === this.module.prop4);
            if (photoGroup) {
                return photoGroup.photoList.map(function (photo) {
                    return {
                        pid: photo.pic,
                        psrc: photo.picPath,
                        w: photo.width,
                        h: photo.height,
                        desc: photo.basic,
                        link: {
                            url: photo.url ? photo.url : null,
                            // ib: true,
                        },
                    };
                });
            } else {
                return [];
            }
        },
    },
};
