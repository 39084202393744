//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClientOnly from 'vue-client-only';

const manageUse = {
    components: {},
};
export default {
    name: 'Iframe',
    style: 26,
    components: {
        ...manageUse.components,
        ClientOnly,
    },
    props: ['module'],
    data() {
        return {};
    },
    computed: {
        ...Vuex.mapState(['manageMode']),
        isIframeCode() {
            return this.module.prop0 === 1;
        },
        iframeWidth() {
            if (this.isIframeCode) {
                return `${this.iframeInfo.width || '100%'}`;
            }
            return `${this.module.blob0.iframeWidth || 100}%`;
        },
        iframeHeight() {
            if (this.isIframeCode) {
                return `${this.iframeInfo.height || 100}px`;
            }
            return `${this.module.blob0.iframeHeight || 100}px`;
        },
        iframeSrc() {
            return this.iframeInfo.src;
        },
        iframeInfo() {
            const srcMatch = this.module.prop1.match(/<iframe ?(.*) src="(?<src>[^"]*)"/);
            const widthMatch = this.module.prop1.match(/<iframe ?(.*) width="(?<width>[^"]*)"/);
            const heightMatch = this.module.prop1.match(/<iframe ?(.*) height="(?<height>[^"]*)"/);

            return {
                src: srcMatch ? srcMatch.groups.src : '',
                width: widthMatch ? widthMatch.groups.width : '',
                height: heightMatch ? heightMatch.groups.height : '',
            };
        },
        isHttpsIframeUrl() {
            const url = this.isIframeCode ? this.iframeSrc : this.module.prop3;
            if (typeof url === 'undefined' || url === '') {
                // 默认为true
                return true;
            }
            return this.isHttpsUrl(url);
        },
        showHttpsTips() {
            if (this.manageMode) {
                return location.protocol.indexOf('https') !== -1 && !this.isHttpsIframeUrl;
            } else {
                return this.module.isHttpsProtocol && !this.isHttpsIframeUrl;
            }
        },
        isEmptyIframe() {
            if (this.isIframeCode) {
                return !this.iframeSrc;
            } else {
                return !this.module.prop3;
            }
        },
        iframeRef() {
            return `iframe-${this.module.id}`;
        },
        authSuccess() {
            return this.authInfo && this.authInfo.allow;
        },
        authInfo() {
            return this.module.authInfo || {};
        },
        isEmpty() {
            return this.isEmptyIframe || !this.authSuccess;
        },
        isMobi() {
            return this.$store.state.device === 'mobi';
        },
        showIframe() {
            return !this.isEmptyIframe && !this.showHttpsTips;
        },
    },
    watch: {
        'module.prop3': function () {
            this.setSrc();
        },
        'module.prop1': function () {
            this.setSrc();
        },
        'module.prop0': function () {
            this.setSrc();
        },
    },
    mounted() {
        if (this.authSuccess) {
            this.loadIframe();
        }
    },
    methods: {
        isHttpsUrl(url = '') {
            return url.substring(0, 5) === 'https';
        },
        changeToHttpProtocol() {
            try {
                const href = location.href;
                // eslint-disable-next-line @jz/no-use-http-prefix
                if (href.indexOf('https://') === 0) {
                    // eslint-disable-next-line @jz/no-use-http-prefix
                    location.href = 'http://' + href.substring(8);
                }
            } catch (error) {
                return;
            }
        },
        openEditPanel() {
            this.manageMode && editModule(this.module.id);
        },
        setSrc() {
            if (this.isEmptyIframe || this.showHttpsTips) {
                return;
            }
            this.$nextTick(() => {
                const src = this.isIframeCode ? this.iframeSrc : this.module.prop3;
                const el = this.$refs[this.iframeRef] || {};
                if (src === el.src) {
                    return;
                }
                el.src = this.isIframeCode ? this.iframeSrc : this.module.prop3;
            });
        },
        loadIframeAfterOnLoad() {
            this.addOnLoadEvent(this.setSrc.bind(this));
        },
        loadIframe() {
            if (this.isEmptyIframe || this.showHttpsTips) {
                return;
            }
            if (document.readyState !== 'complete') {
                // onload后可能会重新mounted, 比如拖拽, 所以加此判断
                this.loadIframeAfterOnLoad();
            } else {
                this.setSrc();
            }
        },
        addOnLoadEvent(func) {
            const oldOnload = window.onload;
            if (typeof window.onload !== 'function') {
                window.onload = func;
            } else {
                window.onload = function () {
                    oldOnload();
                    func();
                };
            }
        },
    },
};
