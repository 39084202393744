var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isRenderModuleStyle
    ? _c(
        "div",
        {
          class: _vm.wrapperClass,
          style: Object.assign({}, _vm.styleTwoTextAlign),
        },
        [
          _vm._l(_vm.newsList, function (item, index) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "animate",
                      rawName: "v-animate",
                      value: _vm.module,
                      expression: "module",
                    },
                    {
                      name: "hovermenu",
                      rawName: "v-hovermenu",
                      value: { hovermenu: _vm.hovermenu, args: item.id },
                      expression: "{ hovermenu, args: item.id }",
                    },
                    {
                      name: "hover-style",
                      rawName: "v-hover-style:[disabledHoverStyle]",
                      value: _vm.hoverStyle,
                      expression: "hoverStyle",
                      arg: _vm.disabledHoverStyle,
                    },
                  ],
                  key: item.id,
                  staticClass:
                    "news_list_item_line jz_hover jz_theme_font_hover_color",
                  class: _vm.itemLineClass(index),
                  style: _vm.itemLineStyle,
                  on: {
                    mouseenter: function ($event) {
                      return _vm.handleItemMouseEnter(item)
                    },
                    mouseleave: function ($event) {
                      return _vm.handleItemMouseLeave(item)
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "news_list_item_inner",
                      attrs: {
                        target: _vm.target,
                        href: _vm.setQueryString(
                          item.url,
                          "fromColId",
                          _vm.colId,
                          item.flag
                        ),
                        onclick: _vm.onclickStr(item),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("item-click")
                        },
                      },
                    },
                    [
                      _vm.isShowPic(index)
                        ? _c(
                            "div",
                            {
                              staticClass: "news_list_img_box",
                              style: _vm.imgBoxStyle,
                            },
                            [
                              _c("ImageComponent", {
                                directives: [
                                  {
                                    name: "lazyload2",
                                    rawName: "v-lazyload2",
                                    value: {
                                      id: _vm.module.id,
                                      src: item.imgPath,
                                      naturalWidth: item.imgWidth,
                                      naturalHeight: item.imgHeight,
                                    },
                                    expression:
                                      "{\n                            id: module.id,\n                            src: item.imgPath,\n                            naturalWidth: item.imgWidth,\n                            naturalHeight: item.imgHeight,\n                        }",
                                  },
                                ],
                                staticClass: "news_list_img",
                                style: _vm.imgStyle(),
                                attrs: {
                                  src: item.imgPath,
                                  webp: _vm.webpOptOpen,
                                  "wrap-style": _vm.imgWrapStyle(index),
                                  "img-effects": _vm.isMobi
                                    ? { type: 0 }
                                    : _vm.imgOptsComputed,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "news_list_item_content" }, [
                        _c("div", { staticClass: "news_list_item_header" }, [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "news_list_item_title jz_theme_font_hover_color jz_hover",
                              style: Object.assign(
                                {},
                                _vm.titleStyle,
                                _vm.getTitleHoverColor(item)
                              ),
                            },
                            [
                              _vm.topIconSetting.t &&
                              item.top &&
                              !_vm.isSmartRecommend
                                ? _c("top-icon", {
                                    attrs: {
                                      "icon-type": _vm.topIconSetting.s,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.decodeHtml(item.title)) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.flagDate
                            ? _c(
                                "span",
                                {
                                  staticClass: "news_list_item_date",
                                  style: Object.assign(
                                    {},
                                    _vm.dateStyle,
                                    _vm.getHoverColor(item, "pdf")
                                  ),
                                },
                                [_vm._v(_vm._s(_vm.decodeHtml(item.dateStr)))]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        !_vm.flagAbstract && item.summary != ""
                          ? _c(
                              "div",
                              {
                                staticClass: "news_list_item_summery",
                                style: Object.assign(
                                  {},
                                  _vm.abstractStyle,
                                  _vm.getHoverColor(item, "paf")
                                ),
                              },
                              [_vm._v(_vm._s(_vm.decodeHtml(item.summary)))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (!_vm.flagGroup && _vm.groups(item).length) ||
                        _vm.module.prop0 == 1
                          ? _c(
                              "div",
                              { staticClass: "news_list_item_ext_line" },
                              [
                                !_vm.flagGroup && _vm.groups(item).length
                                  ? _c(
                                      "div",
                                      { staticClass: "news_list_item_group" },
                                      _vm._l(
                                        _vm.groups(item),
                                        function (groupItem, index) {
                                          return _c(
                                            "a",
                                            {
                                              key: "groupItem" + index,
                                              staticClass:
                                                "news_list_item_group_item",
                                              style: Object.assign(
                                                {},
                                                _vm.classifyStyle,
                                                _vm.getHoverColor(item, "pcf")
                                              ),
                                              attrs: {
                                                href: _vm.setQueryString(
                                                  groupItem.url,
                                                  "fromColId",
                                                  _vm.colId,
                                                  groupItem.flag
                                                ),
                                                target:
                                                  _vm.getItemTarget(groupItem),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.decodeHtml(
                                                      groupItem.name
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.module.prop0 == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "news_list_item_arrow_link",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "news_list_item_arrow",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.module.prop0 != 1 &&
              index < _vm.newsList.length - 1 &&
              !_vm.isMultiColumn
                ? _c("div", {
                    staticClass: "news_list_parting_line jz_parting_line",
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _c(
        "div",
        [
          _vm.newsList.length
            ? _c(_vm.moduleStyleComponent, {
                directives: [
                  {
                    name: "animate",
                    rawName: "v-animate",
                    value: _vm.module,
                    expression: "module",
                  },
                ],
                tag: "component",
                attrs: {
                  "news-list": _vm.newsList,
                  device: _vm.device,
                  module: _vm.module,
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }