//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {};
import { msgBoardCommentsFunc } from '../../../site/msgBoardCommentCompon.js';
export default {
    name: 'MsgBoardComment',
    props: ['styleId'],
    data() {
        return {
            commentsInfo: {},
            commentList: [],
            paginationList: [],
            openMsgAuto: false,
            jumpPage: 1,
            disableStyle: {
                cursor: 'auto',
                color: '#ccc',
            },
            allowStyle: {
                cursor: 'pointer',
            },
            isLoading: false,
        };
    },
    computed: {
        ...Vuex.mapState(['LS']),
    },
    mounted() {
        JZ.msgBoardInfo = new msgBoardCommentsFunc(1, this);
    },
    methods: {
        headImgStyle(item = {}) {
            const { headPic } = item;
            if (Object.keys(headPic).length === 0) {
                return {};
            }
            const { imgH, imgW, left, top, width } = headPic;
            const imgWrapWidth = width / 50;
            const result = {
                position: 'absolute',
                width: imgW / imgWrapWidth + 'px',
                height: imgH / imgWrapWidth + 'px',
                top: -top / imgWrapWidth + 'px',
                left: -left / imgWrapWidth + 'px',
                maxWidth: 'initial',
            };
            if (result.width === '0px') {
                result.width = '100%';
            }
            if (result.height === '0px') {
                result.height = 'auto';
            }
            return result;
        },
        addComment(event) {
            this.$emit('addComment', event);
        },
        getMsgBoardComments(page = 1) {
            // 如果是处于当前页 或者输入的页数超出总页数  或者小于一    就不需要发送请求了
            JZ.msgBoardInfo.page = page;
            JZ.msgBoardInfo.getComments(false);
        },
        handlePageChange(type) {
            let page = this.commentsInfo.curPage || 1;
            // page表示当前页, type表示点击了上一页还是下一页
            if (type === 'next') {
                if (this.commentsInfo.pageSum > page) {
                    JZ.msgBoardInfo.page++;
                    JZ.msgBoardInfo.getComments(false);
                }
            } else if (type === 'prev') {
                if (page > 1) {
                    JZ.msgBoardInfo.page--;
                    JZ.msgBoardInfo.getComments(false);
                }
            }
        },
        memberIcon(color) {
            return {
                color,
            };
        },
        memberIsDel(item) {
            return !item.isMember_existed && item.isMember_deleted;
        },
        memberIsExt(item) {
            return item.isMember_existed && !item.isMember_deleted;
        },
        memberIsNot(item) {
            return !item.isMember_existed && !item.isMember_deleted;
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
