var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "a",
        {
          staticClass: "s_member_login_mobi",
          class: _vm.loginedClass,
          attrs: { href: _vm.loginUrl },
        },
        [
          _c(
            "svg",
            {
              staticClass: "member_login_svg",
              style: { color: _vm.mobiIconColor },
            },
            [_c("use", { attrs: { "xlink:href": "#icon_members" } })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }