//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    moduleInHeader,
    updateFixTopTextModuleById,
    canAddFixTopClass,
    FIX_TOP_TEMP_CLASS,
} from '@/site/shared/fixTopStyle/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { initJumpWxApp } from '@/site/wx.js';
export default {
    name: 'moduleTextModule',
    props: ['module', 'projectid'],
    created() {
        if (this.manageMode) {
            // 在字体出现前就要加上fontface
            Site.initFontFace(this.fontList.concat(this.module.prop5));
        }
    },
    mounted() {
        setHrefEventHasReqArgs($(`#module${this.module.id}`));

        if (canAddFixTopClass('module', this.projectid)) {
            $(`#gridHeader #module${this.module.id} .module_text_content`).addClass(FIX_TOP_TEMP_CLASS);
            if (moduleInHeader(this.module.id)) {
                updateFixTopTextModuleById(this.module.id, true);
            }
        }
        if (this.manageMode) {
            const fontList = this.fontList;
            if (Array.isArray(fontList)) {
                this.fontList = fontList.filter((font) => font.substring !== ' ');
                const oldFontListResIds = fontList.map((e) => e.resId);
                this.module.oldFontListResIds = oldFontListResIds;
            } else {
                this.module.oldFontListResIds = [];
            }

            if (Array.isArray(this.module.prop5)) {
                const oldMobiFontListResIds = this.module.prop5.map((e) => e.resId);
                this.module.oldMobiFontListResIds = oldMobiFontListResIds;
            } else {
                this.module.oldMobiFontListResIds = [];
            }
            this.initJumpWxAppTips();
        } else {
            if (this.isJumpWxApp) {
                initJumpWxApp(this.module.id, this.module.jumpWxAppData);
            }
        }
    },
    methods: {
        editStatusChange(editStatus) {
            if (this.isMobiCustomTextStyle) {
                return;
            }
            if (!editStatus) {
                const $editor = $(this.$refs.fkeditor.$el);
                if ($editor.parents('.jz_module').attr('projectid') != this.module.id) {
                    return;
                }
                const $fontsEl = $editor.find('*[style*=font-family], font');
                Site.checkFontFaceChange($fontsEl);
                const fontList = Site.mergeFonts($editor);
                this.fontList = fontList;
            }
        },
        initJumpWxAppTips() {
            $(`#module${this.projectid}`).on('click', '.module_text_content *', (e) => {
                if (this.isJumpWxApp) {
                    e.preventDefault();
                    e.stopPropagation();
                    Message.warning('请在微信端打开链接');
                }
            });
        },
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        isJumpWxApp() {
            return this.module.blob0.jumpMode == 1;
        },
        fontList: {
            get() {
                return this.module.prop3 || [];
            },
            set(fontList) {
                this.module.prop3 = fontList;
            },
        },
        privatePattern() {
            return this.module.privatePattern || {};
        },
        mobiStyle() {
            return this.privatePattern.mobi || {};
        },
        mobiFontStyle() {
            return this.mobiStyle.pmf || {};
        },
        isMobi() {
            return this.device == 'mobi';
        },
        isMobiCustomTextStyle() {
            return this.isMobi && this.mobiStyle.cs;
        },
    },
    watch: {
        'module.privatePattern.pc': {
            deep: true,
            immediate: false,
            handler() {
                if (this.manageMode) {
                    if (canAddFixTopClass('module', this.projectid)) {
                        $(`#gridHeader #module${this.module.id} .module_text_content`).addClass(FIX_TOP_TEMP_CLASS);
                    }
                    if (moduleInHeader(this.module.id)) {
                        updateFixTopTextModuleById(this.module.id, true);
                    }
                }
            },
        },
    },
};
