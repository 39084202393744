//
//
//
//
//
//

export default {
    name: 'empty-view',
};
