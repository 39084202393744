//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comm } from '../../utils';
const { imageSettingtMixin } = Comm;
import { PhotoGroupModule } from '@shared/visitor/modulePhotoGroup/index.js';
export default {
    name: 'UnitSearchResultPhotogroup',
    mixins: [imageSettingtMixin],
    inject: ['module'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        other: {
            type: Object,
            default: () => ({}),
            require: true,
        },
    },
    data() {
        return {
            moduleId: 'photo_group', // 这里采用字符串是因为并不是真正的module, 但要用到JZ.PhotoGroupModule的功能
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode', 'LS', 'isMaterialLazyLoad']),
        noPicStyle() {
            return this.module.prop0 === 0;
        },
        model() {
            return this._mixin_model;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        photoItemStyle() {
            if (!this.manageMode) {
                return;
            }
            if (this.noPicStyle) {
                return {
                    width: '100%',
                };
            }

            if (this.isMobi || !this.manageMode) {
                return {};
            }

            return this._mixin_itemStyle;
        },
        picItemStyle() {
            return Object.assign({}, this._mixin_picStyle, this.itemSizeStyle);
        },
        itemSizeStyle() {
            if (this.noPicStyle || this.isMobi || !this.manageMode) {
                return {};
            }
            return this._mixin_itemSizeStyle;
        },
    },
    mounted() {
        // 防止jq先生成dom
        const that = this;
        this.controller = new PhotoGroupModule(this.moduleId, {
            get style() {
                return 1;
            },
            get photoGroupList() {
                return that.list;
            },
        });
    },
    methods: {
        photoSlide(index, photoGroupId) {
            this.controller.initSlide(photoGroupId);
        },
        genPicItemStyle(item) {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.picPath}')`;
            }

            return { ...this.picItemStyle, ...style };
        },
    },
};
