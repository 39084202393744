//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'jz-banner-btn',
    props: ['btnStyle', 'picData', 'isPc'],
    data: function () {
        return {};
    },
    computed: {
        leftRightBtn() {
            return this.isPc && (this.btnStyle == 1 || this.btnStyle == 2);
        },
        btnTypeClass: function () {
            var _class = [];
            this.leftRightBtn
                ? _class.push('banner_btn_left_right') && _class.push('banner_btn_left_right_' + this.btnStyle)
                : '';
            !this.isPc || this.btnStyle == 0 ? _class.push('banner_short_line_wrap') : '';
            return _class;
        },
    },
    methods: {
        prev() {
            this.$emit('prev');
        },
        next() {
            this.$emit('next');
        },
        shortLineClass(index) {
            var _class = [];
            _class.push('pic_short_line_' + index);
            index == 0 ? _class.push('pic_short_line_btn_activity') : '';
            return _class;
        },
    },
};
