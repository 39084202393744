export const componentProps = {
    currentLan: {
        type: Object,
    },
    lanList: {
        type: Array,
    },
    textVisible: {
        type: Boolean,
        default: false,
    },
    iconVisible: {
        type: Boolean,
        default: false,
    },
    isMobi: {
        type: Boolean,
        default: false,
    },
    mobiIconColor: {
        type: String,
    },
    areaType: {
        type: Number, // 0 顶部 1底部
    },
    createFontStyle: {
        type: Function,
    },
};
