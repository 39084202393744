//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
const manageUse = {};
export default {
    // 全屏分页组件
    name: 'FullScreenPagination',
    computed: {
        ...Vuex.mapState('row', ['rollingScreenRowInfo', 'rowEditing']),
        ...Vuex.mapState(['device', 'manageMode', 'manage', 'responsiveInfo']),
        fullScreenInfo() {
            return this.rollingScreenRowInfo;
        },
        instance() {
            return this.rollingScreenRowInfo.instance;
        },
        items() {
            return this.instance.pages;
        },
        sideNavPattern() {
            // 分页器样式
            return this.responsiveInfo.pattern.rollingScreen.sideNavPattern;
        },
        showSlide() {
            const curIndex = this.fullScreenInfo.curIndex;
            const hasBanner = this.instance.hasBannerPage;

            if (curIndex && hasBanner) {
                return true;
            }
            if (!hasBanner) {
                return true;
            }
            return false;
        },
        isPC() {
            return this.device === 'pc';
        },
        paginationStyle() {
            return `pagination_style${this.sideNavPattern}`;
        },
        curIndex() {
            return this.fullScreenInfo.curIndex;
        },
    },
    watch: {
        curIndex: function (val) {
            eventBus.$emit('pureFullScreenCurIndexChange', val);

            //更新表现
        },
        /*'rollingScreen.topType': function () {
            //更新表现
        },*/
    },
    methods: {
        getClass(index) {
            let active = false;
            if (index == this.curIndex) {
                active = true;
            } else if (index == this.items - 1 && this.curIndex > index) {
                active = true;
            }

            return {
                active,
                jz_theme_border_color: this.sideNavPattern == 1,
            };
        },
        handleIdx(item) {
            if (this.sideNavPattern == 1) return '';
            let text = item < 10 ? `0${item}` : item;
            if (this.sideNavPattern == 2) {
                if (item - 1 == this.curIndex || (this.items == item && item == this.curIndex)) {
                    return text;
                } else {
                    return '';
                }
            }
            return text;
        },
        goCurrentPage(idx) {
            if (idx == this.curIndex) return;
            if (this.manageMode && this.rowEditing) return;
            this.instance.goCurrentPage(idx);
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
