/**
 * 社交分享 Service
 */
import { ServiceFactory } from './factory';
import { ShareToService as BaseShareToService } from '@jz/biz-shared';

const SERVICE_INTERFACE = 'ShareTo';

export class ShareToService extends BaseShareToService {
    constructor() {
        const $toastService = Fai.ing;

        super($toastService);
    }
}

ServiceFactory.register(SERVICE_INTERFACE, {
    interfaceClass: ShareToService,
});
