var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item_type_checkbox" }, [
    _vm.requiredIcon
      ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "checkbox_style" },
      _vm._l(_vm.itemList, function (item, i) {
        return _c(
          "li",
          {
            key: i,
            staticClass: "checkbox_item",
            class: { checkbox_active: _vm.isChecked(item) },
            on: {
              click: function ($event) {
                return _vm.toggleItem(item)
              },
            },
          },
          [
            _c("span", {
              staticClass:
                "checkbox_inner jz_theme_bg_color faisco-icons-S000335",
            }),
            _vm._v(_vm._s(item) + "\n        "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }