//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClientOnly from 'vue-client-only';
import { OnlineMap, createMapNavigation, moveMapPosition } from '@shared/visitor/onlineMap/index.js';
import { context } from '@jz/utils';
import { encodeHtmlStr } from '@/components/utils.js';
import AddressSlide from '@/shared/visitor/onlineMap/addressSlide.js';

function refreshMarker(map, newPoint, info, options = {}) {
    const { ComplexCustomOverlay } = context._onlineMapMap.get('onlineMap');
    var mapInfo;
    map.clearOverlays();
    /* eslint-disable-next-line no-undef */
    var myIcon = new window.BMap.Icon(
        window._store.state.resRoot + '/image/rimage/module/online_map/marker_red_sprite.png?v=201809101152',
        new Fai.top.BMap.Size(22, 25),
        {
            anchor: new window.BMap.Size(10, 25),
        }
    );
    var marker = new window.BMap.Marker(newPoint, { icon: myIcon });
    // map.centerAndZoom(newPoint, options.zoom);
    map.addOverlay(marker);
    map.setCenter(newPoint);
    moveMapPosition(map, {
        openNavigation: options.openNavigation,
    });
    mapInfo = Fai.encodeHtml(info);

    let msgContent = mapInfo;
    if (options.msgContent && options.openNavigation) {
        msgContent = options.msgContent;
    }

    var myCompOverlay = new ComplexCustomOverlay({
        point: newPoint,
        content: '<div class="msg">' + msgContent + '</div>',
        boxClass: options.boxClass ? options.boxClass : 'msgBox',
        arrowClass: 'arrow',
        zIndex: 1,
        offset: {
            x: 0,
            y: -35,
        },
        arrowOffsetX: options.openNavigation ? -6 : 16,
    });
    if (msgContent != '') {
        map.addOverlay(myCompOverlay);
    }

    marker.addEventListener('click', function () {
        map.clearOverlays();
        map.addOverlay(myCompOverlay);
        map.addOverlay(marker);
        map.panTo(newPoint);
    });
}

export default {
    name: 'moduleOnlineMap',
    components: { ClientOnly },
    // eslint-disable-next-line vue/require-prop-types
    props: ['module', 'projectid'],
    data() {
        return {
            addressIndex: 0,
            search: '',
            searchMode: false, // 搜索模式
            addressSlide: null,
            inputTips: '请输入城市',
        };
    },
    computed: {
        ...Vuex.mapState(['device', 'manageMode']),
        isMobi() {
            return this.device === 'mobi';
        },
        moduleStyle() {
            if (!this.manageMode) {
                return {};
            }
            const style = {},
                pcPattern = this.module.privatePattern.pc;

            if (!this.isMobi && pcPattern.pmht === 1) {
                style['padding-bottom'] = `${pcPattern.pmh * 100}%`;
            }

            return style;
        },
        mapContainerId() {
            return 'mapContainer' + this.projectid;
        },
        googleMapContainerId() {
            return 'googleMapContainer' + this.projectid;
        },
        map() {
            return context?._OnlineMap?.[this.projectid]?.map;
        },
        isBaiduMap() {
            return this.module.prop0 == 0;
        },
        blob0() {
            return this.module.blob0 || {};
        },
        privatePattern() {
            return this.module.privatePattern || {};
        },
        pcPrivatePattern() {
            return this.privatePattern.pc || {};
        },
        openAddressNav() {
            return this.pcPrivatePattern.pmn;
        },
        openAddressSearch() {
            return this.pcPrivatePattern.pms;
        },
        multiAddress() {
            return this.blob0.multiAddress || [];
        },
        address() {
            let detail = this.multiAddress[this.addressIndex] || {};
            return detail.da || '';
        },
        renderPanelAddressList() {
            let list = this.multiAddress.map((item) => {
                return {
                    ...item,
                    da: encodeHtmlStr(item.da),
                };
            });
            if (this.searchMode) {
                return list.filter((item) => {
                    return this.search == '' || item.p.indexOf(this.search) != -1;
                });
            } else {
                return list;
            }
        },

        renderViewAddressList() {
            let list = this.multiAddress;
            if (this.searchMode) {
                return list.filter((item) => {
                    return this.search == '' || item.p.indexOf(this.search) != -1;
                });
            } else {
                return list;
            }
        },
    },
    watch: {
        'module.prop3'() {
            this.refreshMarker();
        },
        'module.prop1.x'() {
            this.refreshMarker();
        },
        'module.prop1.y'() {
            this.refreshMarker();
        },
        'module.prop1.z'() {
            this.isBaiduMap && this.refreshZoom();
        },
        device() {
            // 切换视图时刷新地图marker
            this.$nextTick(() => {
                let detail = this.renderViewAddressList[this.addressIndex];
                this.refreshMarkerForList(detail);
            });
        },
        addressIndex(val) {
            let detail = this.renderViewAddressList[val];
            this.refreshMarkerForList(detail);
            localStorage.setItem(`module${this.module.id}_address`, val);
        },
        openAddressNav() {
            this.refreshMarkerForList(this.renderViewAddressList[this.addressIndex]);
        },
        search(val) {
            if (val == '') {
                this.searchMode = false;
            }
        },
        multiAddress: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    let detail = this.renderViewAddressList[this.addressIndex];
                    this.refreshMarkerForList(detail);
                });
            },
        },
    },
    mounted() {
        if (!context._OnlineMap) {
            context._OnlineMap = {};
        }

        let firstAddress;
        this.inputTips = LS.onlineMapInputTips || '请输入城市';

        if (this.isBaiduMap) {
            firstAddress = this.multiAddress[0];
            localStorage.removeItem(`module${this.module.id}_address`);
        }

        Vue.nextTick(() => {
            context._OnlineMap[this.projectid] = new OnlineMap(this.projectid, {
                type: this.module.prop0,
                mapContainerId: this.mapContainerId,
                googleMapContainerId: this.googleMapContainerId,
                mapPos: this.isBaiduMap ? firstAddress : this.module.prop1,
                noteInfo: this.isBaiduMap ? firstAddress?.da : this.module.prop3,
                module: this.module,
                openAddressNav: this.openAddressNav,
                addressDetail: firstAddress?.a,
                city: firstAddress?.p,
            });
            $(this.$refs.search).keydown((e) => {
                if (e.keyCode === 13) {
                    this.handleSearch();
                }
            });
        });

        if (this.isMobi && this.isBaiduMap) {
            this.addressSlide = new AddressSlide(this.module.id, 'address_panel');
            this.addressSlide.on(AddressSlide.EVENT_BACK, (addressIndex, detail) => {
                // this.addressIndex = addressIndex;
                this.refreshMarkerForList(detail);
            });
        }
    },
    beforeDestroy() {
        $(this.$refs.search).unbind('keydown');
    },
    methods: {
        refreshMarker() {
            var mapPos = this.module.prop1;
            /* eslint-disable-next-line no-undef */
            var newPoint = new BMap.Point(mapPos.x, mapPos.y);
            if (this.isBaiduMap) {
                refreshMarker(this.map, newPoint, this.address);
            } else {
                context?._OnlineMap?.[this.projectid]?.updataGoogleInfo();
            }
        },
        refreshMarkerForList(address) {
            // eslint-disable-next-line no-undef
            var newPoint = new BMap.Point(address.x, address.y);
            let onlineMap = context._OnlineMap[this.projectid];
            onlineMap.unbindNavigationEvent(this.mapContainerId);
            onlineMap.updateConf({
                addressDetail: address.a,
                city: address.p,
            });
            refreshMarker(this.map, newPoint, address.da, {
                boxClass: this.openAddressNav ? 'msgBox baiduBox' : null,
                msgContent: createMapNavigation(address),
                openNavigation: this.openAddressNav,
                addressDetail: address.a,
                city: address.p,
                zoom: address.z,
            });
            onlineMap.bindNavigationEvent(this.mapContainerId);
        },
        refreshZoom() {
            var mapPos = this.module.prop1;
            /* eslint-disable-next-line no-undef */
            var newPoint = new BMap.Point(mapPos.x, mapPos.y);
            this.map.centerAndZoom(newPoint, this.module.prop1.z);
        },
        handleSearch() {
            if (this.search != '') {
                this.searchMode = true;
                this.addressIndex = 0;
            }
        },
        handleClose() {
            this.search = '';
            this.searchMode = false;
            this.$nextTick(() => {
                let detail = this.renderViewAddressList[this.addressIndex];
                this.refreshMarkerForList(detail);
            });
        },
        showAddressSlide() {
            if (this.addressSlide == null) {
                this.addressSlide = new AddressSlide(this.module.id, 'address_panel');
                this.addressSlide.on(AddressSlide.EVENT_BACK, (addressIndex) => {
                    this.addressIndex = addressIndex;
                });
            }
            this.addressSlide.open();
        },
        handleAddressClick(index) {
            if (this.addressIndex == index) {
                let detail = this.renderViewAddressList[index];
                this.refreshMarkerForList(detail);
            } else {
                this.addressIndex = index;
            }
        },
    },
};
