import { getBitMemory } from '@/components/utils';
import { getProductListByPage } from '@api/product';

export const getProductList = (module, page = 1, pageSize) => {
    const data = {
        moduleId: module.id,
        page,
        productLid: module.blob0.selectLibId,
        propsList: JSON.stringify(module.prop1),
    };
    if (getBitMemory(module.flag, 0x4)) {
        data.pageSize = pageSize || module.prop2 || 300;
    } else {
        const allProductVisible = module.blob0.av === 1;
        const visibleProductCount = module.prop6;
        if (allProductVisible) {
            data.pageSize = pageSize || 300;
        } else {
            data.pageSize = pageSize || visibleProductCount || 300;
        }
    }
    if (module.id == 13) {
        // 推荐产品，多加recommendFromPid参数
        data.recommendFromPid = module.recommendFromPid || 0;
    }

    if (getBitMemory(module.flag, 0x1)) {
        data.idList = JSON.stringify(module.prop5);
    } else {
        data.groupIdList = JSON.stringify(module.prop3.gids);
        data.sortKey = module.blob0.customSort.sortKey;
        data.sortType = module.blob0.customSort.sortType;
    }
    return new Promise((resolve, reject) => {
        getProductListByPage(data)
            .then((data) => (data.success ? resolve(data) : reject(data)))
            .catch((err) => {
                reject(err);
            });
    });
};
