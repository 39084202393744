//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { mapState } from 'vuex';
import { NEED_SSR_MODULE_STYLES, componentMap } from '../constants';

export default {
    name: 'ModuleStyles',
    components: { ChildrenCSS },
    computed: {
        ...mapState(['modules']),
        ssrModules() {
            return Object.keys(this.modules).reduce((cur, key) => {
                if (NEED_SSR_MODULE_STYLES.includes(this.modules[key].style)) {
                    cur[key] = this.modules[key];
                }
                return cur;
            }, {});
        },
    },
    methods: {
        getComponent(module) {
            return componentMap[module.style];
        },
    },
};
