import request from '@jz/request';

export function getPhotoList(data) {
    return request.post('/rajax/photoGroup_h.jsp?cmd=getWafNotCk_getList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getGroupNavList(data) {
    return request
        .post('/ajax/photoGroupNav_h.jsp?cmd=getWafNotCk_getGroupNavList', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getPhotoGroupAllList(data) {
    return request.post('/rajax/photoGroup_h.jsp?cmd=getWafNotCk_getAllList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function delPhotoGroup(data = {}) {
    return request.post('/ajax/photoGroup_h.jsp?cmd=delWafCk_del', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function photoGroupMultiSet(data = {}) {
    return request.post('/ajax/photoGroup_h.jsp?cmd=setWafCk_multiSet', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getPhotoPath(data = {}) {
    return request.post('/ajax/getPhotoData_h.jsp?cmd=getPhotoPath', { data }).then(({ data = {} }) => {
        return data;
    });
}
