import request from '@jz/request';

export function getSystemModuleInfo(data) {
    return request
        .post('/rajax/module_h.jsp?cmd=getWafNotCk_getVisitorSystemModuleInfo', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getModuleListByIds(data) {
    return request
        .post('/rajax/module_h.jsp?cmd=getWafNotCk_getVisitorModuleData', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function addModule(data = {}) {
    return request
        .post('/rajax/module_h.jsp?cmd=addWafCk_addModule', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}
