// 用来设置 图文展示模块->文本->编辑->插入->鼠标经过换图   时的函数
export const ueHoverChangeImage = function ($moduleDom) {
    var images = [],
        hoverImages = []; //var	images = $(" div[_deffont][_hovfont]");

    //var	images = $(" div[_deffont][_hovfont]");
    var isUE = false;
    var editorBody = JZ.body ? JZ.body : JZ.editor ? JZ.editor.body : false;

    if (!!$moduleDom && $moduleDom.length > 0) {
        //暂时这样优化，多图片的模块可能还不是最好的
        images = $moduleDom.find(
            'img[_defImg][_hovImg], img[_defFont][_hovFont], div[_defFont][_hovFont], div[_defImg][_hovImg]'
        );
        hoverImages = $moduleDom.find('.J_hoverImage');
    } else {
        if (editorBody) {
            images = $(editorBody).find(
                'img[_defImg][_hovImg], img[_defFont][_hovFont], div[_defFont][_hovFont], div[_defImg][_hovImg]'
            );
            hoverImages = $(editorBody).find('.J_hoverImage');
        } else {
            //这里有4种情况 图片+图片，图片+图标，图标+图标，图标+图片
            images = $(
                'img[_defImg][_hovImg], img[_defFont][_hovFont], div[_defFont][_hovFont], div[_defImg][_hovImg]'
            );
            hoverImages = $('.J_hoverImage');
        }
    }

    if (images.length == 0) {
        return;
    }

    hoverImages.remove();

    for (var i = 0; i < images.length; i++) {
        var elem = images[i];
        var elemParent = $(elem).parent();
        var elemWidth = $(elem).width();
        var elemHeight = $(elem).height();
        var elemTitle = $(elem).attr('title') || '';
        let hoverImage = '';
        var hoverImageSrc = $(elem).attr('_hovImg');
        var hoverImageStyle = 'position: absolute; opacity:0;';
        var hoverFont = $(elem).attr('_hovFont');
        var hoverColor = $(elem).attr('_hovColor');
        isUE = ($(elem).parents('.richContent').length > 0 || editorBody) && $(elemParent).is('p, span, td');

        if (isUE) {
            if (!(elemParent[0].nodeName.toLowerCase() === 'span' && $(elemParent).hasClass('J_hoverImageParent'))) {
                var spanStyle =
                    'display: inline-block; width: ' +
                    elemWidth +
                    'px; height: ' +
                    elemHeight +
                    'px; _display: block; _zoom: 1;';
                $(elem).after("<span class='J_hoverImageParent' style='" + spanStyle + "'></span>");
                elemParent = $(elem).next('span');
                $(elemParent).append($(elem));
            }
        } //visibility:hidden 防止再ie6-8中hover图片src为空时出现图片ie独有ico 20160106

        //visibility:hidden 防止再ie6-8中hover图片src为空时出现图片ie独有ico 20160106
        hoverImage = $(elemParent).find('.J_hoverImage');

        if (hoverImage.length == 0) {
            hoverImageStyle += 'top: 0px; left: 0px; width:' + elemWidth + 'px; height:' + elemHeight + 'px;'; //浮动图片的内容为字体图标是时，做特殊处理

            //浮动图片的内容为字体图标是时，做特殊处理
            if (hoverFont) {
                let font_size = elemWidth > elemHeight ? elemHeight : elemWidth;
                hoverImageStyle +=
                    'font-size:' + font_size + 'px; text-align : center; line-height:' + elemHeight + 'px;';
                hoverImage =
                    "<div class='J_hoverImage " +
                    hoverFont +
                    "' style='" +
                    hoverImageStyle +
                    'color: ' +
                    hoverColor +
                    "' ></div>";
            } else {
                hoverImage =
                    "<img class='J_hoverImage' src='" +
                    hoverImageSrc +
                    "' style='" +
                    hoverImageStyle +
                    (hoverImageSrc ? '' : 'visibility:hidden;') +
                    "' title='" +
                    elemTitle +
                    "' alt='" +
                    elemTitle +
                    "' >";
            }

            $(elem).after(hoverImage);
        }

        $(elem).css('opacity', ''); // 防止在富文本保存时，原图是被隐藏的，然后style直接被持久化，输出的时候直接被隐藏的问题。
    }

    var eventElems = $(images).parent();
    $.each(eventElems, function (_i, elem) {
        var defImage = $(this).find('img[_defImg], div[_defFont]')[0];

        if (typeof $(defImage).attr('_hovImg') == 'undefined' && typeof $(defImage).attr('_hovFont') == 'undefined') {
            return;
        }

        if (typeof $(defImage).attr('_hovImg') != 'undefined' && $(defImage).attr('_hovImg').length == 0) {
            return;
        }

        var module = $(elem).parents('.form');

        if (Fai.isChrome() && !$(module).hasClass('formStyle31')) {
            $(elem).css('position', 'relative'); // 解决有时候hover上去图片显示不出来。
        }

        $(elem).off('.hoverImage');
        $(elem)
            .on('mouseenter.hoverImage', function () {
                var image = $(this).find('img[_defImg], div[_defFont]')[0];
                var hoverImage = $(this).find('.J_hoverImage');
                var hoverImgSrc = $(hoverImage).attr('src') || '';
                var isGifImg = hoverImgSrc.indexOf('gif') != -1;
                refreshImageStyle(image, hoverImage);
                $(image).css({
                    opacity: 0,
                }); //.stop(false, true).animate({opacity: 0}, 500);    ---   动画需要优化，具体等pm决定

                //.stop(false, true).animate({opacity: 0}, 500);    ---   动画需要优化，具体等pm决定
                $(hoverImage).css({
                    opacity: 1,
                }); // fix：hover是gif时，每次hover都跳到动画开头 json

                // fix：hover是gif时，每次hover都跳到动画开头 json
                if (isGifImg) {
                    if (Fai.isIE()) {
                        if ($(hoverImage).attr('_src')) {
                            // fix IE：重新赋值img的src并不能让浏览器重新加载图片，因此，需要对图片地址拼接一个随机数让其不读缓存数据。
                            hoverImgSrc = $(hoverImage).attr('_src') + '?v' + new Date().getTime();
                        } else {
                            $(hoverImage).attr('_src', $(hoverImage).attr('src'));
                        }
                    }

                    !!hoverImgSrc && $(hoverImage).attr('src', hoverImgSrc);
                }
            })
            .on('mouseleave.hoverImage', function () {
                var image = $(this).find('img[_defImg], div[_defFont]')[0];
                var hoverImage = $(this).find('.J_hoverImage');
                refreshImageStyle(image, hoverImage);
                $(image).css({
                    opacity: 1,
                });
                $(hoverImage).css({
                    opacity: 0,
                });
                var isGifImg = !!$(hoverImage).attr('src') && $(hoverImage).attr('src').indexOf('gif') != -1;

                if (Fai.isIE() && isGifImg) {
                    if ($(hoverImage).attr('_src')) {
                        $(hoverImage).attr('src', $(hoverImage).attr('_src'));
                    }
                }
            }); // $(elem).css("background","");
    });

    function refreshImageStyle(srcImg, targetImg) {
        var imageWidth = $(srcImg).width();
        var imageHeight = $(srcImg).height();
        var imageTop = $(srcImg).position().top;
        var imageLeft = $(srcImg).position().left;
        var imageMarginLeft = $(srcImg).css('margin-left');
        var imageMarginTop = $(srcImg).css('margin-top');

        if (imageMarginLeft) {
            imageMarginLeft = parseInt(imageMarginLeft.replace('px', ''));
            imageLeft += imageMarginLeft;
        }

        if (imageMarginTop) {
            imageMarginTop = parseInt(imageMarginTop.replace('px', ''));
            imageTop += imageMarginTop;
        } //浮动图片的内容为字体图标时，做特殊处理

        //浮动图片的内容为字体图标时，做特殊处理
        if ($(srcImg).context.tagName == 'DIV') {
            $(srcImg).parent().css('display', 'block');
        }

        $(targetImg).css({
            top: imageTop + 'px',
            left: imageLeft + 'px',
            width: imageWidth + 'px',
            height: imageHeight + 'px',
        });
    }
};
