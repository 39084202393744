//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const manageUse = {};
import { getIsOpenRemoveUrlArg } from '@/site/shared/cookie/index.js';
import { Comm } from '../../utils';
const { getComputedBg, createUrlArgsOnclickStr } = Comm;
import hoverArea from './hoverArea.vue';
const SERV_CONTACT_ME_INDEX = -2, //样式三联系我们，固定index
    SERV_WXQRCODE_INDEX = -3; //样式三微信图片，固定index

export default {
    name: 'Suspendserv',
    props: ['module'],
    data() {
        return {
            mouseEnter: false,
            curIndex: -1,
            innerCurIndex: -1,
            contactMe: SERV_CONTACT_ME_INDEX,
            wxQrcode: SERV_WXQRCODE_INDEX,
            iconCode: '&#xa1088',
            isLeft: false,
            draging: false,
        };
    },
    computed: {
        ...Vuex.mapGetters(['isFullScreen']),
        ...Vuex.mapState(['device', 'manageMode', 'fullScreen', 'LS']),
        ...Vuex.mapGetters(['info', 'hashRemoved']),
        ...Vuex.mapState('manage', ['pageTop']),
        themeColor() {
            return this.info.themeColor;
        },
        isMobi() {
            return this.device == 'mobi';
        },
        deviceEnterEvent() {
            return 'mouseenter';
            // return !this.isMobi ? 'mouseenter' : 'click';
        },
        deviceLeaveEvent() {
            return 'mouseleave';
            // return !this.isMobi ? 'mouseleave' : '';
        },
        serv() {
            return this.module.pattern.suspendServ;
        },
        styleType() {
            return this.serv.s;
        },
        styleClass() {
            return 's_spserv__wrap-' + this.serv.s;
        },
        wxServList() {
            return this.serv.list.filter((item) => {
                return item.servType == '5';
            });
        },
        filterWxList() {
            return this.serv.list.filter((item) => {
                return item.servType != '5';
            });
        },
        themeFontColor() {
            if (this.styleType != 2) {
                return {};
            }
            return {
                color: this.serv.c == 'default' ? this.themeColor : this.serv.c,
            };
        },
        contentStyle() {
            let textOption = this.serv.cont;
            let fontStyle = {
                'font-family': `${textOption.f}`,
                'font-weight': `${textOption.w ? 'bold' : 'normal'}`,
                'text-decoration': `${textOption.d ? 'underline' : 'none'}`,
                'font-style': `${textOption.i ? 'italic' : 'normal'}`,
                color: `${textOption.c}`,
            };
            return textOption.y ? fontStyle : {};
        },
        bgStyle() {
            return getComputedBg(this.serv.bg);
        },
        nameStyle() {
            let textOption = this.serv.sn;
            let fontStyle = {
                'font-family': `${textOption.f}`,
                'font-weight': `${textOption.w ? 'bold' : 'normal'}`,
                'text-decoration': `${textOption.d ? 'underline' : 'none'}`,
                'font-style': `${textOption.i ? 'italic' : 'normal'}`,
                color: `${textOption.c}`,
            };
            return textOption.y ? fontStyle : {};
        },
        isChangePosition() {
            return this.serv.top >= 0 || this.serv.left >= 0;
        },
        // 兼容返回顶部，预留出位置
        specialBottomClass() {
            let backTopHide = this.manageMode ? this.info.pattern.backToTop.hide : this.module.backToTopHide;
            if (!this.isMobi) {
                return !this.isChangePosition && !backTopHide ? 's_spserv__wrap-morebottom' : '';
            } else {
                return !backTopHide ? 's_spserv__wrap-morebottom' : '';
            }
        },
        isMobiShow() {
            return !this.$isServer && (!this.isMobi || (this.isMobi && this.serv.mshow));
        },
        fixedDragClass() {
            return !this.isMobi && this.isLeft
                ? `s_spserv__hoverarea_left s_spserv__hoverarea_left_animation${this.styleType}`
                : '';
        },
        dragStyle() {
            let style = {};
            if (!this.isMobi) {
                if (this.serv.top >= 0) {
                    if (this.manageMode) {
                        if (!this.draging) {
                            style.top =
                                this.pageTop == 0 ? `calc(${this.serv.top * 100}% - 61px)` : `${this.serv.top * 100}%`;
                        }
                    } else {
                        style.top = `calc(${this.serv.top * 100}% - 61px)`;
                    }
                }
                if (this.serv.left >= 0) {
                    style.left = `${this.serv.left * 100}%`;
                }
            }
            return style;
        },
    },
    watch: {
        styleType() {
            this.curIndex = -1;
            Vue.nextTick(() => {
                if (!this.isMobi && (this.serv.top >= 0 || this.serv.left >= 0)) {
                    this.initDragStyle();
                }
                this.initWxEvent();
            });
        },
    },
    mounted() {
        if (!this.isMobi) {
            if (this.serv.top >= 0 || this.serv.left >= 0) {
                let { left = 0 } = $('.s_spserv__wrap').offset();
                let documentWidth = document.documentElement.clientWidth;
                this.isLeft = documentWidth > 2 * left;
                this.initDragStyle();
            }
        }
        this.initWxEvent();
    },
    methods: {
        getFontPic(serv) {
            return serv.isPic || typeof window == 'undefined' ? '' : '&#x' + serv.fontPic;
        },
        getBgUrl(serv) {
            return serv.isPic
                ? {
                      'background-image': `url(${serv.picPath})`,
                  }
                : {};
        },
        resetCuIndex(e, curIndex) {
            this.setCurIndex(e, curIndex);
        },
        setCurIndex(e, index) {
            e.stopPropagation();
            this.curIndex = index;
        },
        setInnerCurIndex(e, index) {
            e.stopPropagation();
            this.innerCurIndex = index;
        },
        selfThemeBg(index) {
            if (this.curIndex != index || this.styleType != 0) {
                return {};
            }
            return {
                'background-color': this.serv.c == 'default' ? this.themeColor : this.serv.c,
            };
        },
        selfThemeFont(index) {
            if (this.curIndex != index || this.styleType != 1) {
                return {};
            }
            return {
                color: this.serv.c == 'default' ? this.themeColor : this.serv.c,
            };
        },
        selfInnerThemeFont(index) {
            if (this.innerCurIndex != index) {
                return {};
            }
            return {
                color: this.serv.c == 'default' ? this.themeColor : this.serv.c,
            };
        },
        blockHover(index) {
            return this.curIndex == index ? 's_spserv__hoverarea-block' : '';
        },
        whiteColor(index) {
            if (this.styleType != 0 || this.curIndex != index) {
                return {};
            }
            return {
                color: this.curIndex == index ? '#fff' : '',
            };
        },
        getJumpUrl(url) {
            const normalizedUrl = url && url.length ? url : 'javascript:;';
            if (normalizedUrl.startsWith('_script')) {
                return normalizedUrl.replace('_script', 'javascript');
            }
            return normalizedUrl;
        },
        initWxEvent() {
            let haveWxServ = this.serv.list.some((item) => {
                return item.servType == '5';
            });
            haveWxServ && Site.addQrCode('.s_spserv__wrap');
        },
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            const _openRemoveUrlArgs = this.$isServer ? this.module._openRemoveUrlArgs : getIsOpenRemoveUrlArg();
            return createUrlArgsOnclickStr(reqArgs, _openRemoveUrlArgs, this.hashRemoved);
        },
        getTarget(item) {
            // 如果是微信客服，不返回target
            if (item.servType == '5') {
                return;
            }
            return item.link.target;
        },
        hovermenu: manageUse.hovermenu || function () {},
        isScriptHref(href = '') {
            if (href.startsWith('javascript') || href.startsWith('_script')) {
                // _script 是 hoverQRCode 历史逻辑
                return true;
            }
            return undefined;
        },
        initDrag() {
            let _this = this;
            $('.s_spserv__wrap').draggable({
                containment: 'window',
                cursor: 'move',
                axis: 'x,y',
                scroll: false,
                start() {
                    _this.initDragStyle();
                    _this.draging = true;
                    $(this).addClass('isDragging');
                },
                stop(e, ui) {
                    const { position } = ui;
                    let documentWidth = document.documentElement.clientWidth;
                    let documentHeight = document.documentElement.clientHeight;
                    let { left, top } = position;

                    _this.isLeft = documentWidth > 2 * left;
                    // 采取百分比定位方式，用px在大屏幕拉到边界后，小屏幕会不显示
                    // 在隐藏顶部时，需要补回61px
                    let gMainTop = $('#jzPreviewWrap').offset().top;
                    if (top < gMainTop) {
                        top = gMainTop;
                    }

                    _this.serv.top = (top + (_this.pageTop == 0 ? 61 : 0)) / documentHeight;
                    _this.serv.left = left / documentWidth;
                    _this.draging = false;
                    $(this).removeClass('isDragging');
                },
            });
        },
        initDragStyle() {
            if (!this.isMobi) {
                $('.s_spserv__wrap').addClass('s_spserv__wrap_drag');
            }
        },
    },
    components: {
        hoverArea,
    },
};
