import { getBannerData as getBannerDataRequest } from '@api/banner';
import { delRow } from '@/manage/grid/row/rowManage/index.js';
import { deepExtendClone } from '@jz/utils';

const editBanner = function () {
    window._store.dispatch('manage/pauseDataWatch');
    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'banner',
        active: true,
    });
};
/**
 * @function name -  getBannerData
 * @description 获取横幅数据
 * @kind Root
 * @param flag {Boolean}
 */
export const getBannerData = function (flag) {
    let param = [];
    param.push('cmd=getWafNotCk_getBanner');
    param.push('&_colId=' + window._store.state.colId);
    param.push('&_extId=' + window._store.state.extId);
    getBannerDataRequest({
        _colId: window._store.state.colId,
        _extId: window._store.state.extId,
    })
        .then((result) => {
            if (result.success) {
                var _Banner = result.banner;
                _temporary.banner = _Banner; //在切换前要先把上一次添加的临时行数据删掉

                //在切换前要先把上一次添加的临时行数据删掉
                for (const { prid: rowId } of window._store.state.responsiveInfo.banner.bl) {
                    if (rowId >= 50 && rowId < 100) {
                        delRow(rowId);
                    }
                }

                window._store.state.manage.props.independentSetting.pageBanner = flag;
                const newBannerData = deepExtendClone(
                    window._store.state.responsiveInfo.banner,
                    flag ? _Banner.pageBanner : _Banner.templateBanner
                );
                window._store.state.responsiveInfo.banner = newBannerData;
            }
        })
        .catch((err) => {
            console.log(err);
        });
};
export { editBanner };
