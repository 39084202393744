import {
    setCopyModuleName,
    setCopyModuleAnchorName,
    setIs20210707NewModule,
    checkModuleCanCopy,
    getCurrentAddModuleId,
} from '@shared/manage/moduleUtils/index.js';
import { delModuleData } from '@shared/manage/moduleManage/index.js';
import { getRowBelong, setCopyRowAnchorName } from '@/manage/grid/row/rowUtils/index.js';
import { scrollToTarget } from '@shared/manage/scroll/index.js';
import { addColEditingLayout } from '@/manage/grid/col/colUtils/index.js';
import { copyModuleTab } from '@shared/manage/tabpackUtils/index.js';
/* eslint-disable no-mixed-spaces-and-tabs */
import { Message } from '@shared/manage/componMessage/index.js';
import { getCarouselRowList } from '@/manage/grid/row/effectRow/index.js';
import { gotoFullScreenRowId, delFullScreenRow, updateFullScreen } from '@/components/grid/row/pureFullScreen/index.js';
import { store } from '@/store/store.js';
import Vue from 'vue';

/**
 * @function name - delCol
 * @description 删除列
 * @kind Root
 * @date 2020-07-27
 * @param cols {Array} 列数组
 */

const delCol = (cols = []) => {
    let col, i, j;
    for (i = 0; i < cols.length; i++) {
        col = cols[i];

        // 列内存放模块
        if (col.mIds && Array.isArray(col.mIds)) {
            for (j = 0; j < col.mIds.length; j++) {
                delModuleData(col.mIds[j]);
            }
        }
        // 列内存放嵌套行
        if (col.rIds && Array.isArray(col.rIds)) {
            for (j = 0; j < col.rIds.length; j++) {
                // 删除掉嵌套的行
                delRow(col.rIds[j], () => {});
            }
        }
    }
};

const delCarouselPageCol = (carouselList = []) => {
    if (carouselList.length) {
        carouselList.forEach((value) => {
            delCol(value.cols);
        });
    }
};

/**
 * @function name - delRow
 * @description 删除行 base Root
 * @kind Root
 * @date 2020-07-27
 * @param id {Number} 行id
 * @param callback {function} 删除行回调
 */
const delRow = function (id, callback) {
    id = parseInt(id);
    let rows = store.state.rows,
        delRow = rows['row' + id]; //没有找到行

    //没有找到行
    if (!delRow) {
        callback(id);
        return;
    }

    //系统行不能删除,而banner内的行可以
    if (delRow.type > 1 && delRow.type !== 5 && !store.getters.isFullSiteRow(id)) {
        Message.warning('系统行不能删除!');
        return;
    }

    Root.behavior.addBeHavior('delRow', {
        rowId: id,
    });

    store.commit('removeRowCol', id);

    if (!store.getters.isFullScreen) {
        delCol(delRow.cols); // 删除轮播通栏里的列数据
    }

    // 删除轮播通栏里的列数据
    if (store.getters.isCarouselRow(delRow.id)) {
        delCarouselPageCol(getCarouselRowList(delRow.id));
    }

    if (!callback) {
        const matchBelong = getRowBelong(id);

        if (matchBelong) {
            //内容区的行z只剩下一行的话，清空内部模块，将col数设置为1,  还要将样式也设置为false
            //新增需求，见【自适应-通栏小点优化】https://www.tapd.cn/42954297/prong/stories/view/1142954297001029279
            if (store.state.fullScreen && matchBelong.rows.length == 1) {
                const moduleIds = delRow.cols.map((col) => col.mIds).flat();
                delRow.cols.splice(1, delRow.cols.length);
                for (let id of moduleIds) {
                    delModuleData(id);
                }
                delRow.cols[0].mIds.splice(0, delRow.cols[0].mIds.length);
                delRow.cols[0].pattern.g = 0;
                return;
            }

            matchBelong.rows.splice(matchBelong.index, 1);
        }
    } else {
        // 自行删除引用
        callback(id);
    }

    delRowData(id); //delete store.state.rows['row' + id];       //删除挂在的数据，50-100的话会导致后台新增垃圾数据
    Vue.nextTick(() => {
        updateFullScreen();
    });
};
/**
 * @function name - delRowData
 * @description 删除行数据 base Root
 * @kind Root
 * @date 2020-07-27
 * @param id {Number} 行id
 */
const delRowData = function (id) {
    if (id > 100) {
        store.commit('manage/setRowToRowDeleteSet', id);
    }

    if (id > 50 && id <= 100) {
        store.state.rows && store.state.rows['row' + id] && Vue.delete(store.state.rows, 'row' + id);
    }

    delFullScreenRow(id);
};
/**
 * @function name - editRow
 * @description 编辑行 base Root
 * @kind Root
 * @date 2020-07-27
 * @param rowId {Number} 行id
 */
const editRow = function (rowId) {
    store.dispatch('manage/pauseDataWatch');
    if (store.state.device == 'pc') {
        store.commit('row/rowEditingChange', rowId);

        addColEditingLayout(rowId);
    }

    store.commit('manage/editPanel/editPanelChange', {
        id: rowId,
        type: 'row',
        active: true,
    });
};

/**
 * @function name - designRow
 * @description 设计行 base Root
 * @kind Root
 * @date 2020-07-27
 * @param rowId {Number} 行id
 */
const designRow = function (rowId) {
    if (store.state.device == 'pc') {
        store.commit('row/rowEditingChange', rowId);

        addColEditingLayout(rowId);
    }

    store.commit('manage/editPanel/editPanelChange', {
        id: rowId,
        type: 'rowDesign',
        active: true,
    });
};

/**
 * @function name - editMobiRow
 * @description 编辑mobi行 base Root
 * @kind Root
 * @date 2020-07-27
 * @param rowId {Number} 行id
 */
const editMobiRow = function (rowId) {
    store.commit('manage/editPanel/editPanelChange', {
        id: rowId,
        type: 'row',
        active: true,
    });
};

/**
 * @function name - hideMobiRow
 * @description 隐藏mobi行 base Root
 * @kind Root
 * @date 2020-07-27
 * @param rowId {Number} 行id
 */
const hideMobiRow = function (rowId) {
    const rows = store.state.rows,
        pattern = rows['row' + rowId].pattern.mobi;

    if (!store.state.row.showMobiHideRow) {
        hideElAnimate(`#row${rowId}`, '.jz_mobi_btn_showAllRow', () => {
            //数据设置为隐藏
            pattern.mh = true;
        });
    } else {
        pattern.mh = true;
    }
};

/**
 * @function name - hideElAnimate
 * @description 隐藏元素动画 base Root
 * @kind Root
 * @date 2020-07-27
 * @param id {Number} 行id
 * @param targetEl {Object} 目标dom
 * @param completeFunc {Function} 完成回调
 * @param speed {Number} 动画速度
 */
const hideElAnimate = function (id, targetEl, completeFunc, speed = 500) {
    const $$ = $,
        $el = $$(id),
        $targetEl = $(targetEl),
        $animateEl = $('<div></div>'),
        $previewFrame = $('#jzPreviewWrap');
    if ($el.length <= 0) return; //滚到对应模块

    //滚到对应模块
    $$('html').scrollTop($el.offset().top - 100); //起止位置

    //起止位置
    const elOffset = $el.offset(),
        targetOffset = $targetEl.offset(),
        previewFrameOffset = $previewFrame.offset();
    const startPos = {
        top: previewFrameOffset.top + elOffset.top - $$('html').scrollTop(),
        left: previewFrameOffset.left + elOffset.left,
        width: $el.width(),
        height: $el.height(),
    };
    const endPos = {
        top: targetOffset.top,
        left: targetOffset.left,
        width: $targetEl.width(),
        height: $targetEl.height(),
    }; //生成动画元素

    //生成动画元素
    $animateEl.css({
        border: '2px solid #ff4400',
        position: 'absolute',
        zIndex: '99999',
        boxSizing: 'border-box',
        top: startPos.top,
        left: startPos.left,
        height: startPos.height + 'px',
        width: startPos.width + 'px',
    });
    $('body').append($animateEl);
    $el.fadeOut(speed, completeFunc);
    $animateEl.animate(
        {
            top: endPos.top,
            left: endPos.left,
            width: endPos.width + 'px',
            height: endPos.height + 'px',
        },
        speed,
        () => {
            $animateEl.remove();
        }
    );
};

const copyRowHandler = (cols) => {
    let hasCopyModule = false,
        breakCopy = false;
    $.each(cols, function (index, col) {
        let tempMids = col.mIds,
            tempRids = col.rIds,
            checkMids = [],
            checkRids = [];

        if (col.type == 0) {
            // 列内存放模块
            $.each(tempMids, function (index, val) {
                let tempId = getCurrentAddModuleId(),
                    info = store.state.modules[`module${val}`],
                    cloneInfo; //可能存在复制通栏到其他栏目后，删掉了通栏中的某个模块
                //然后再还有rowId 的这个栏目中再复制通栏，回导致模块没有信息，这个时候就过滤掉这个模块

                if (!info) {
                    return;
                } // 检查模块是否可以复制

                if (checkModuleCanCopy(val)) {
                    if (tempId >= 300) {
                        Message.warning('已经添加99个模块啦，先保存刷新下吧！');
                        breakCopy = true;
                        return;
                    }

                    hasCopyModule = true;

                    _temporary.addModule.push(tempId);

                    cloneInfo = $.extend(true, {}, info);
                    cloneInfo.id = tempId;
                    cloneInfo.name = setCopyModuleName(cloneInfo.name, info.style);
                    setCopyModuleAnchorName(cloneInfo);
                    setIs20210707NewModule(cloneInfo);

                    if (cloneInfo.style == 32) {
                        // 将标签栏数据临时存储
                        let tempTabs = $.extend(true, [], cloneInfo.blob0.tabs); // 清空新复制模块的标签数据

                        cloneInfo.blob0.tabs.forEach((item) => {
                            item.mIds = [];
                        });
                        Vue.set(store.state.modules, 'module' + tempId, cloneInfo); // 复制标签模块的子模块

                        copyModuleTab(cloneInfo, tempTabs);
                    } else {
                        Vue.set(store.state.modules, 'module' + tempId, cloneInfo);
                    }

                    checkMids.push(tempId);
                }
            }); // 替换成复制好的模块

            col.mIds = checkMids;
        } else if (col.type == 1) {
            // 列内存放嵌套行
            tempRids.forEach((rId) => {
                copyRow(rId, (newId) => {
                    checkRids.push(newId);
                });
            }); // 替换成复制好的行

            col.rIds = checkRids;
        }
    });
    return {
        hasCopyModule,
        breakCopy,
    };
};

const copyRow = function (rowId, callback, flag = false) {
    Root.behavior.addBeHavior('copyRow', {
        rowId,
    });
    let tempId = 51,
        rowIds = store.state.responsiveInfo.rowIds,
        footerRowIds = store.state.responsiveInfo.footerRowIds,
        headerRowIds = store.state.responsiveInfo.headerRowIds,
        rowIndex = rowIds.indexOf(rowId),
        rowFooterIndex = footerRowIds.indexOf(rowId),
        rowHeaderIndex = headerRowIds.indexOf(rowId),
        templateRow = store.state.rows[`row${rowId}`],
        newRow,
        newRowCols,
        hasCopyModule = false,
        breakCopy = false;
    _temporary.addRow = _temporary.addRow || [];

    if (_temporary.addRow.length) {
        tempId = _temporary.addRow[_temporary.addRow.length - 1] + 1;
    }

    if (tempId > 100) {
        Message.warning('已经添加50个通栏啦，先保存刷新下吧！');
        return;
    }

    _temporary.addRow.push(tempId); // 克隆样板通栏的数据

    newRow = $.extend(true, {}, templateRow); // 复制的是全站通栏 则需要将其改成普通通栏

    if (store.getters.isFullSiteRow(rowId)) {
        newRow.type = 1;
    }

    newRowCols = newRow.cols;
    newRow.id = tempId;
    let result = null;

    if (store.getters.isCarouselRow(rowId)) {
        newRow?.pattern['pc']?.e?.rbs?.forEach((value) => {
            result = copyRowHandler(value.cols);
            result.breakCopy && (breakCopy = result.breakCopy);
            result.hasCopyModule && (hasCopyModule = result.hasCopyModule);
        });
    } else {
        result = copyRowHandler(newRowCols);
        breakCopy = result.breakCopy;
        hasCopyModule = result.hasCopyModule;
    }

    if (breakCopy && !hasCopyModule) {
        return;
    } // 全站通栏 取消当前栏目 会先复制改通栏再删除 所以名字不能替换

    !flag && setCopyRowAnchorName(newRow);
    Vue.set(store.state.rows, 'row' + tempId, newRow); //供全屏通栏监听代理
    Vue.nextTick(() => {
        gotoFullScreenRowId(tempId);
    });

    if (store.getters.isFirstFullSiteRow(rowId)) {
        rowIds.splice(1, 0, tempId);
        scrollToTarget('#row' + tempId);
    } else if (store.getters.isLastFullSiteRow(rowId)) {
        rowIds.splice(rowIds.length - 1, 0, tempId);
        scrollToTarget('#row' + tempId);
    }

    if (!callback && !store.getters.isFullSiteRow(rowId)) {
        // 检查插入通栏的位置
        if (rowIndex != -1) {
            rowIds.splice(++rowIndex, 0, tempId);
        } else if (rowFooterIndex != -1) {
            footerRowIds.splice(++rowFooterIndex, 0, tempId);
        } else if (rowHeaderIndex != -1) {
            headerRowIds.splice(++rowHeaderIndex, 0, tempId);
        }

        scrollToTarget('#row' + tempId);
    } else {
        // 自定义添加位置
        !store.getters.isFullSiteRow(rowId) && callback(tempId);
        flag && callback(tempId);
    }
};

export { delRow, delRowData, editRow, designRow, editMobiRow, hideMobiRow, hideElAnimate, delCarouselPageCol, copyRow };
