//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MobiRightArrowIcon from '../units/MobiRightArrowIcon.vue';
import MobiLeftArrowIcon from '../units/MobiLeftArrowIcon.vue';
import { encodeHtmlStr } from '@/components/utils.js';
export default {
    name: 'PictureTabs',
    components: { MobiRightArrowIcon, MobiLeftArrowIcon },
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        hovermenu: {
            type: Function,
            default: () => {},
        },
        curIndex: {
            type: Number,
            default: 0,
        },
        isShowArrowLeft: {
            type: Boolean,
            default: false,
        },
        isShowArrowRight: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tabList() {
            return this.module.blob0.tabs;
        },
    },
    methods: {
        isOpenHover(tab) {
            const isPictureStyle3 = this.module.prop2 === 2;
            const isPictureStyle5 = this.module.prop2 === 4;
            let openHover =
                this.module.privatePattern.pc.ph === 1 || (isPictureStyle3 && this.module.privatePattern.pc.ph !== 2);
            if (isPictureStyle5) {
                openHover = false;
            }
            return openHover && tab.picHoverPath;
        },
        encodeHtmlStr,
        clickChangeTab(index) {
            this.$emit('changeTab', index);
        },
        mouseChangeTab(index) {
            this.$emit('mouseChangeTab', index);
        },
        arrowRight(e) {
            this.$emit('arrowRight', e);
        },
        arrowLeft(e) {
            this.$emit('arrowLeft', e);
        },
        updateArrow(e) {
            this.$emit('updateArrow', e);
        },
    },
};
