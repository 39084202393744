//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChildrenCSS from '../components/ChildrenCSS.vue';
import { getBackgroundCss, getFontCss, getBgCss, getBorderCssWithDevice } from '../cssUtils';
import { pictureChangeDefaultImage } from '@/components/modules/tabpack/picture/pictureDefaultImageLogic.js';

export default {
    name: 'TabpackCss',
    components: { ChildrenCSS },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    computed: {
        id() {
            return `#module${this.module.id}`;
        },
        mobiId() {
            return `.jz_screen_mobi #module${this.module.id}`;
        },
        pcId() {
            return `.jz_screen_pc #module${this.module.id}`;
        },
        pcPattern() {
            return this.module.privatePattern.pc;
        },
        mobiPattern() {
            return this.module.privatePattern.mobi;
        },
        isHorizontalTab() {
            return this.module.prop0 === 0;
        },
        isVerticalTab() {
            return this.module.prop0 === 1;
        },
        verticalLayoutCss() {
            if (!this.isVerticalTab) {
                return '';
            }
            if (this.module.blob0.vtl === 1) {
                return `${this.id} .m_tabpack_vertical { flex-direction: row-reverse; }`;
            }
            return '';
        },
        verticalSizeCss() {
            if (!this.isVerticalTab) {
                return '';
            }
            let css = '';
            if (this.pcPattern.p_is.t === 1) {
                if (this.module.prop2 === 3) {
                    css += `${this.pcId} .tabpack_item_list_wrap { width: ${
                        this.pcPattern.p_is.tw * 2
                    }%; flex-shrink: 0; }`;
                } else {
                    css += `${this.pcId} .tabpack_item_list_wrap { width: ${
                        this.pcPattern.p_is.w * 100
                    }%; flex-shrink: 0; }`;
                }
                css += `${this.pcId} .m_tabpack_tab { height: ${this.pcPattern.p_is.h}px; }`;
            }
            return css;
        },
        verticalSpaceCss() {
            if (!this.isVerticalTab) {
                return '';
            }
            let css = '';
            if (this.pcPattern.ts.t === 1) {
                if (this.module.prop2 === 3) {
                    css += `${this.pcId} .m_tabpack_tab:not(:nth-last-of-type(2)):not(:nth-last-of-type(1)) { margin-bottom: ${this.pcPattern.ts.v}px; }`;
                    css += `${this.pcId} .m_tabpack_tab { width: calc(50% - ${this.pcPattern.ts.h / 2}px); }`;
                    css += `${this.pcId} .m_tabpack_tab:nth-of-type(2n - 1) { margin-right: ${this.pcPattern.ts.h}px; }`;
                } else {
                    css += `${this.pcId} .m_tabpack_tab:not(:last-of-type) { margin-bottom: ${this.pcPattern.ts.v}px; }`;
                }
            }
            return css;
        },
        verticalTabWrapBgCss() {
            if (!(this.isVerticalTab || this.isPictureTab)) {
                return '';
            }
            if (this.pcPattern.p_ttbg.y === 0) {
                return '';
            }
            const style = getBackgroundCss(this.pcPattern.p_ttbg);
            return `${this.pcId} .tabpack_item_list_wrap { ${style} }`;
        },

        shadowCss() {
            if (!(this.isVerticalTab || this.pictureTabPicCss)) {
                return '';
            }
            let css = '';
            if (this.pcPattern.p_isw === 1) {
                css += `${this.pcId} .m_tabpack_tab:not(.m_tabpack_tab-active) { box-shadow: none; }`;
            }
            if (this.pcPattern.as === 0) {
                css += `${this.pcId} .m_tabpack_tab-active { box-shadow: none; }`;
            }

            if (this.pictureTabPicCss) {
                // 图片标签mobi需要展示
                if (this.mobiPattern.p_isw === 1) {
                    css += `${this.mobiId} .m_tabpack_tab:not(.m_tabpack_tab-active) { box-shadow: none; }`;
                }
                if (this.mobiPattern.as === 0) {
                    css += `${this.mobiId} .m_tabpack_tab-active { box-shadow: none; }`;
                }
            }
            return css;
        },
        verticalAlignCss() {
            if (!this.isVerticalTab) {
                return '';
            }
            let css = '';
            if (this.pcPattern.p_ta === 1) {
                css += `
                    ${this.pcId} .m_tabpack_vertical .tabpack_item_list_wrap,
                    ${this.pcId} .m_tabpack_vertical .m_tabpack_main { display: flex; flex-direction: column; justify-content: center; }
                `;
            }
            return css;
        },
        isPictureTab() {
            return this.module.prop0 === 2;
        },
        pictureTabPicCss() {
            if (!this.isPictureTab) {
                return '';
            }
            const tabs = this.module.blob0.tabs;
            const isPictureStyle3 = this.module.prop2 === 2;
            const isPictureStyle5 = this.module.prop2 === 4;
            let openHover =
                this.module.privatePattern.pc.ph === 1 || (isPictureStyle3 && this.module.privatePattern.pc.ph !== 2);
            if (isPictureStyle5) {
                openHover = false;
            }
            let css = '';
            css += tabs
                .map((tab) => {
                    let s = '';
                    if (tab.picPath) {
                        s += `${this.id} .j_tabitem_${tab.id} .tabpack_image { background-image: url(${tab.picPath}); }`;
                    }
                    if (openHover) {
                        if (tab.picHoverPath) {
                            s += `${this.id} .j_tabitem_${tab.id} .tabpack_image--hover { background-image: url(${tab.picHoverPath}); }`;
                        }
                    }
                    return s;
                })
                .join('\n');
            return css;
        },
        initialTabFontCss() {
            return getFontCss({
                pcFont: this.pcPattern.its,
                mobiFont: this.mobiPattern.its,
                className: `${this.id} .m_tabpack_tab:not(.m_tabpack_tab-active) .m_tabpack_tabname`,
                isTextNotExtendFontSize: this.isNewModuleAfter20210707,
                needTextAlign: this.isVerticalTab,
            });
        },
        activeTabFontCss() {
            return getFontCss({
                pcFont: this.pcPattern.ats,
                mobiFont: this.mobiPattern.ats,
                className: `${this.id} .m_tabpack_tab.m_tabpack_tab-active .m_tabpack_tabname`,
                isTextNotExtendFontSize: this.isNewModuleAfter20210707,
                needTextAlign: this.isVerticalTab,
            });
        },
        isNewModuleAfter20210707() {
            return this.module.commProp?.unst ?? false;
        },
        initialTabBgCss() {
            if (this.isPictureTab && [2, 3, 4].includes(this.module.prop2)) {
                return '';
            }
            return getBgCss({
                pcBg: this.pcPattern.ibg,
                mobiBg: this.mobiPattern.ibg,
                className: `${this.id} .m_tabpack_tab:not(.m_tabpack_tab-active)`,
            });
        },
        initialTabBorderCss() {
            return getBorderCssWithDevice({
                pcBorder: this.pcPattern.ib,
                mobiBorder: this.mobiPattern.ib,
                className: `${this.id} .m_tabpack_tab:not(.m_tabpack_tab-active)`,
            });
        },
        styleType() {
            if (this.module.prop0 === 1 && this.isMobi) {
                return 5;
            }
            return this.module.prop2;
        },
        activeTabBorderCss() {
            if (this.styleType == 1 && !this.isPictureTab) {
                return '';
            }
            return getBorderCssWithDevice({
                pcBorder: this.pcPattern.ab,
                mobiBorder: this.mobiPattern.ab,
                className: `${this.id} .m_tabpack_tab.m_tabpack_tab-active`,
            });
        },
        activeTabBgCss() {
            if (this.isPictureTab && [2, 3, 4].includes(this.module.prop2)) {
                return '';
            }
            return getBgCss({
                pcBg: this.pcPattern.abg,
                mobiBg: this.mobiPattern.abg,
                className: `${this.id} .m_tabpack_tab.m_tabpack_tab-active`,
            });
        },
        tabWrapBorder() {
            const className = `${this.id} .tabpack_item_list_wrap`;
            const hoverColorCss = this.pcPattern.tb.y
                ? `${className}:hover { border-color: ${this.pcPattern.tb.sc}; }`
                : '';
            return (
                getBorderCssWithDevice({
                    pcBorder: this.pcPattern.tb,
                    mobiBorder: this.mobiPattern.tb,
                    className,
                }) + hoverColorCss
            );
        },
        tabItemRadius() {
            const getCss = (pattern, id) => {
                if (pattern.ir.t === 0) {
                    return '';
                }
                return `
                    ${id} .m_tabpack_tab { border-radius: ${pattern.ir.w}px; }
                `;
            };
            return `${getCss(this.pcPattern, this.id)}${getCss(this.mobiPattern, this.mobiId)}`;
        },
        pictureTabSize() {
            if (!this.isPictureTab) {
                return '';
            }
            const widthData = this.pcPattern.pis;
            if (widthData.t === 0) {
                return '';
            }

            const { w, h } = widthData;
            if (this.pictureHorizontalTab) {
                return `
                    ${this.pcId} .m_tabpack_tab { width: ${w}px; height: ${h}px; }
                `;
            } else {
                return `
                    ${this.pcId} .tabpack_item_list_wrap { width: ${w * 100}%; }
                    ${this.pcId} .m_tabpack_tab { height: ${h}px; }
                `;
            }
        },
        pictureHorizontalTab() {
            return [2, 3].includes(this.module.blob0.ptl);
        },
        pictureTabBorderRadius() {
            if (!this.isPictureTab) {
                return '';
            }
            if (![0, 1].includes(this.module.prop2)) {
                return '';
            }
            const getCss = (pattern, id) => {
                if (pattern.prt === 0) {
                    return '';
                }
                return `
                    ${id} .tabpack_image_wrap { border-radius: ${pattern.pr}px; }
                `;
            };
            return `${getCss(this.pcPattern, this.id)}${getCss(this.mobiPattern, this.mobiId)}`;
        },
        picturePicScale() {
            if (!this.isPictureTab) {
                return '';
            }
            const sizeMap = {
                1: 'cover',
                2: 'contain',
                3: 'initial',
            };
            const getCss = (pattern, id) => {
                if (pattern.ps === 0 || (id === this.id && pattern.ps === 1)) {
                    return '';
                }
                return `
                    ${id} .tabpack_image, ${id} .tabpack_image--hover { background-size: ${sizeMap[pattern.ps]} }
                `;
            };
            return `${getCss(this.pcPattern, this.id)}${getCss(this.mobiPattern, this.mobiId)}`;
        },
        picturePicSize() {
            if (!this.isPictureTab) {
                return '';
            }
            if (![0, 1].includes(this.module.prop2)) {
                return '';
            }
            const getCss = (pattern, id) => {
                if (pattern.pwt === 0) {
                    return '';
                }
                return `
                    ${id} .tabpack_image_wrap { width: ${pattern.pw}px; }
                    ${id} .tabpack_image { padding-top: ${pattern.php * 100}%; }
                `;
            };
            return `${getCss(this.pcPattern, this.id)}${getCss(this.mobiPattern, this.mobiId)}`;
        },
        pictureSpace() {
            if (!this.isPictureTab) {
                return '';
            }
            let css = '';
            if (this.pcPattern.ts.t === 1) {
                if (this.pictureHorizontalTab) {
                    css += `${this.id} .m_tabpack_tab { margin: ${this.pcPattern.ts.v / 2}px ${
                        this.pcPattern.ts.h / 2
                    }px; }`;
                } else {
                    css += `${this.id} .m_tabpack_tab:not(:last-of-type) { margin-bottom: ${this.pcPattern.ts.v}px; }`;
                }
            }

            if (this.mobiPattern.ts.t === 1) {
                css += `${this.mobiId} .m_tabpack_tab { margin: ${this.mobiPattern.ts.v / 2}px ${
                    this.mobiPattern.ts.h / 2
                }px; }`;
            }
            return css;
        },
    },
    created() {
        if (this.module.prop0 === 2) {
            pictureChangeDefaultImage(this);
        }
    },
    methods: {},
};
