//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import photoDescription from './photoDescription.vue';
import { linkTarget, linkNofollow } from './utils.js';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { photoSettingMixin } from '../photoSettingMixin';
const manageUse = {};
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { getImgComponetOption, imgOptsComputed } from '@/components/modules/shared/imgEffect.js';
import { Comm } from '../../utils.js';
export default {
    components: {
        photoDescription,
        ImageMainViewer,
        ImageComponent,
    },
    mixins: [slidesshowDataMixin, photoSettingMixin],
    props: ['photoList', 'module', 'moduleStyle', 'pattern', 'usePhotoGroup', 'allPhotoList'],
    data() {
        return {
            showCopyPhotoList: false,
            isInitPureFullScreen: false,
        };
    },
    computed: {
        ...Vuex.mapState(['manageMode', 'fullScreen', 'jzVersion']),
        ...Vuex.mapGetters(['hashRemoved']),
        imgOptsComputed,
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-列表多图',
                    jz_version: this.jzVersion,
                },
            };
        },
        descStyle() {
            return this.device === 'mobi' ? this.module.privatePattern.mobi.pds : this.module.privatePattern.pc.pds;
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.psrc;
                previewSrcItem.description = value.desc;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
        wrapperClasses() {
            switch (this.moduleStyle) {
                case 0:
                    return ['list_photos_list_style'];
                case 1:
                    return ['list_photos_slider_style'];
            }
            return [];
        },
        photosInRow() {
            var len = 4;
            if (this.isMobi) {
                len = 2; // 默认一行2个
                if (this.pattern.prlt === 1) {
                    if (this.pattern.prl === 3) {
                        len = 1;
                    }
                }
            } else {
                if (this.pattern.prlt === 1) {
                    len = this.pattern.prl;
                }
            }
            return len;
        },
        swipeMarQueeId() {
            return `marquee${this.module.id}`;
        },
        modulePattern() {
            return this.module.pattern[this.device];
        },
        moduleBorder() {
            return this.modulePattern.b || {};
        },
        device() {
            return this.$store.state.device;
        },
    },
    watch: {
        // 图片宽度
        async 'model.ppw'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        // 图片个数
        async photoList() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        // 模块间距
        async 'modulePattern.mt'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'modulePattern.mr'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'modulePattern.mb'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'modulePattern.ml'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        // 模块边框
        async 'moduleBorder.w'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'moduleBorder.t'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'moduleBorder.l'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'moduleBorder.r'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
        async 'moduleBorder.b'() {
            await Vue.nextTick();
            this.loadPhotoMarquee(this.module.id);
        },
    },
    async mounted() {
        await this.columnInitLoadPhotoMarquee();
        this.$device.on(this.$device.EVENTS.ON_CHANGE_AFTER_TRANSITION, () => {
            this.loadPhotoMarquee(this.module.id);
        });
    },
    methods: {
        getImgComponetOption,
        jumpOnclickStr(item) {
            const { reqArgs } = item.link;
            return Comm.createUrlArgsOnclickStr(reqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },
        imgOriginalSrc(photoItem) {
            return photoItem.psrc || '';
        },
        photoItemStyle() {
            const style = {
                //"transition": "padding 0.1s,width 0.1s",
            };

            let pattern = this.model;

            // 宽度
            if (this.model.ppwt === 0) {
                style.width = this.device === 'mobi' ? `${100 / 2.6}%` : `${100 / 3.5}%`;
            } else {
                style.width = `${this.model.ppw}%`;
            }

            if (!this.manageMode) {
                return style;
            }

            if (pattern.ppmt === 1) {
                //自定义图片间距
                style['padding-left'] = `${parseInt(pattern.ppmh / 2)}px`;
                style['padding-right'] = `${parseInt(pattern.ppmh / 2)}px`;
                style['padding-top'] = `${parseInt(pattern.ppmv / 2)}px`;
                style['padding-bottom'] = `${parseInt(pattern.ppmv / 2)}px`;
            }

            return style;
        },
        photoWrapStyle() {
            const style = {};
            if (this.model.ppht === 1) {
                //自定义图片高度
                style['padding-bottom'] = `${this.model.pph * 100}%`;
            } else if (this.isMobi && this.model.ppht == 0) {
                style['padding-bottom'] = `${this.pcPrivatePattern.pph * 100}%`;
            }
            return style;
        },
        photoStyle() {
            const style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }

            return style;
        },
        linkTarget(isBlank) {
            return linkTarget(isBlank);
        },
        linkNofollow(nofollow) {
            return linkNofollow(nofollow);
        },
        async loadPhotoMarquee(id) {
            var module = $(`#module${id}`);

            // 先清理上次的动画
            {
                this.showCopyPhotoList = false;
                await Vue.nextTick();
            }

            var totalWidth = 0;

            // 计算总宽度
            {
                var targetModule = module;
                let isInFreeLayout = module.parents('.s_freelayout__wrap').length,
                    cloneFreelayoutWrap = null;
                // 子级导航由于自模块是display:none;所以计算高度的时候要先克隆，展示到页面后计算完成后删除节点。不然计算有误
                if (isInFreeLayout) {
                    cloneFreelayoutWrap = module.parents('.s_freelayout__wrap').clone();
                    cloneFreelayoutWrap
                        .css('opacity', 0)
                        .appendTo(this.isMobi ? '.jz_nav_list' : '#jzRoot')
                        .show();
                    targetModule = cloneFreelayoutWrap;
                }
                var formList = targetModule.find('.photo_item_li_origin'); //每个为图片盒子的最大盒子div（图片描述与图片的父盒子）
                if (this.module.id == 3057) {
                    console.log(formList);
                }
                formList.each(function () {
                    var form = $(this);
                    var width = form.outerWidth(true);
                    totalWidth += width;
                });
                // 删除克隆的子级导航节点。
                if (isInFreeLayout) {
                    cloneFreelayoutWrap.remove();
                }
            }

            const $photoDisplayArea = module.find('.photo_display_area');

            const photoDisplayAreaWidth = $photoDisplayArea.width();

            // 没有溢出则不滚动
            if (photoDisplayAreaWidth > totalWidth) {
                Fai.stopInterval(this.swipeMarQueeId);
                $photoDisplayArea.css('transform', `translateX(0px)`);
                return;
            }

            this.showCopyPhotoList = true;

            let left = 0;
            Fai.stopInterval(this.swipeMarQueeId);
            // 按照速度移动
            Fai.addInterval(
                this.swipeMarQueeId,
                () => {
                    left++;
                    $photoDisplayArea.css('transform', `translateX(${-left}px)`);
                    if (left >= totalWidth) {
                        left = 0;
                    }
                },
                !this.isMobi ? 17 : 19
            );

            Fai.startInterval(this.swipeMarQueeId);
        },

        startSwipe() {
            if ((this.device === 'mobi' && !this.manageMode) || !this.showCopyPhotoList) {
                return;
            }
            Fai.startInterval(this.swipeMarQueeId);
        },
        stopSwipe() {
            if (this.device === 'mobi' && !this.manageMode) {
                return;
            }
            Fai.stopInterval(this.swipeMarQueeId);
        },
        loadPhotoMarqueeByCondition() {
            if (!this.manageMode) {
                return;
            }
            if (this.fullScreen) {
                if (this.isInitPureFullScreen) {
                    this.loadPhotoMarquee(this.module.id);
                }
            } else {
                this.loadPhotoMarquee(this.module.id);
            }
        },
        async columnInitLoadPhotoMarquee() {
            await Vue.nextTick();

            // 滚动通栏未在视口的通栏默认是display:none, 需要监听滚动渲染完成之后再初始化轮播
            if (this.fullScreen) {
                // 等到JZ.pureFullScreen初始化
                window.addEventListener('pureFullScreenInit', () => {
                    // 滚动到通栏才初始化
                    eventBus.$on('fullScreenAfterEnter', async () => {
                        await Vue.nextTick();
                        if (!this.isInitPureFullScreen) {
                            // 等待滚动结束渲染完成
                            // 判断该模块是否在可视区内
                            if (this.$refs.swipeListPhoto.offsetWidth) {
                                this.loadPhotoMarquee(this.module.id);
                                this.isInitPureFullScreen = true;
                            }
                        }
                    });
                });
                // 直接添加到当前滚动通栏
                if (this.$refs.swipeListPhoto.offsetWidth) {
                    this.loadPhotoMarquee(this.module.id);
                    this.isInitPureFullScreen = true;
                }
            } else {
                this.loadPhotoMarquee(this.module.id);
            }
        },
        hovermenu: manageUse.hovermenu || function () {},
    },
};
