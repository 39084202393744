var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.animateName } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFooter,
            expression: "showFooter",
          },
          {
            name: "hovermenu",
            rawName: "v-hovermenu",
            value: _vm.hovermenu,
            expression: "hovermenu",
          },
        ],
        staticClass: "jz_web_footer g_footer",
        class: _vm.activeFooterClass,
        style: _vm.footerStyle,
        attrs: { id: "gridFooter" },
      },
      [_c("jz-web-rows", { attrs: { ids: _vm.footerRowIds } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }