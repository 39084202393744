var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline_text_vertical_cross" },
    _vm._l(_vm.timelineItems, function (item, index) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "timeline_text_cross_item",
          class: { "timeline_text_cross_item--even": index % 2 === 1 },
        },
        [
          _c("div", { staticClass: "timeline_text_cross_item_left" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline_text_cross_item_center" },
            [
              _c("timeline-item-line", {
                staticClass: "timeline_text_cross_item_line",
              }),
              _vm._v(" "),
              _c("timeline-item-connect-content-line", {
                staticClass: "timeline_text_cross_item_content_line",
              }),
              _vm._v(" "),
              _c("timeline-item-dot", {
                staticClass: "timeline_text_cross_item_dot",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline_text_cross_item_right" },
            [
              _c("timeline-item-time", {
                attrs: { id: item.id, value: item.time },
              }),
              _vm._v(" "),
              _c("timeline-item-title", {
                class: item.time ? "timeline_cross_title" : "",
                attrs: { id: item.id, value: item.title },
              }),
              _vm._v(" "),
              _c("timeline-item-desc", {
                staticClass: "timeline_cross_desc",
                attrs: { id: item.id, value: item.desc },
              }),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }