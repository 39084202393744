//
//
//
//
//
//
//

export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        textStyle: {
            type: Object,
        },
    },
};
