//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message } from '@shared/manage/componMessage/index.js';
import { createDialog } from '@/site/shared/dialog/index.js';

export default {
    name: 'JzShareSsrCompon',
    props: ['list', 'shareParamList'],
    computed: {
        ...Vuex.mapState(['manageMode', 'LS']),
        shareList() {
            let newShareList = this.shareParamList.filter((item) => {
                return item.linkIsShow == true;
            });
            return newShareList;
        },
    },
    methods: {
        preventMoveCross(e) {
            e.preventDefault();
        },

        shareItemClick(shareItemObj) {
            if (this.manageMode) {
                Message.warning('管理态不能分享哦！');
            } else {
                this.openShareLink(shareItemObj);
            }
        },

        openShareLink(shareItemObj) {
            let shareName = shareItemObj.name;

            if (shareName == this.LS.wechat) {
                // 微信
                Site.wxShareAlter(shareItemObj.open, true);
                return false;
            } else if (shareName == 'QQ' || shareName == this.LS.qqSpace) {
                // this.LS.qqSpace qq空间
                Site.qqShareAlter(shareItemObj.open, true);
                return false;
            } else if (shareName == this.LS.tieBa) {
                // 百度贴吧
                const { isFaiHost, shareNot, link } = shareItemObj;

                if (isFaiHost) {
                    createDialog(shareNot);
                } else {
                    window.open(link);
                }

                return false;
            } else if (shareName == this.LS.copyLink) {
                // 复制链接
                Site.copyWebSite();
            } else if (shareName == 'Pinterest') {
                this.shareToPinterest();
            } else {
                window.open(shareItemObj.open);
            }
        },

        shareToPinterest() {
            this.$emit('share-to-pinterest');
        },
    },
};
