//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dayjs } from '@jz/utils';
export default {
    props: {
        item: Object,
        value: {
            type: Object,
            default: () => ({}),
        },
        module: Object,
        requiredIcon: Boolean,
        itemInputStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        dateSetting() {
            return this.item.dateSetting || {};
        },
    },
    watch: {
        dateSetting: {
            deep: true,
            handler() {
                Vue.nextTick(() => {
                    this.initDatepicker();
                    if (this.dateSetting.type == 1) {
                        this.initDatepicker(true);
                    }
                });
            },
        },
    },
    mounted() {
        this.initDatepicker();
        if (this.dateSetting.type == 1) {
            this.initDatepicker(true);
        }
    },
    methods: {
        isOpenDay(date) {
            // 哪些日期不可选
            var open = true,
                day = date.getDay(),
                time = date.getTime();
            const dateSetting = this.dateSetting;

            // 默认全开启
            if (dateSetting.od.t == 0) {
                return [true, ''];
            }

            if (dateSetting.banAll == 1) {
                open = false;
            }

            if (day == 0 || day == 6) {
                if (dateSetting.banHoliday == 1) {
                    open = false;
                } else {
                    open = true;
                }
            }

            if (dateSetting.od.t == 1 && $.inArray(time, dateSetting.od.unSelectDay) > -1) {
                open = false;
            }

            if (dateSetting.od.t == 1 && $.inArray(time, dateSetting.od.openDay) > -1) {
                open = true;
            }

            return [open, ''];
        },
        initDatepicker(isEnd) {
            const dateSetting = this.dateSetting;
            const $startTime = $(this.$refs.startTime);
            const $endTime = $(this.$refs.endTime);
            const $el = !isEnd ? $startTime : $endTime;
            const yearRange = '1900:' + (parseInt(dayjs(new Date(), 'yyyy')) + 10);

            const showTimepicker = dateSetting.type == 0 && dateSetting.accuracy == 1,
                now = new Date(),
                nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()),
                // todayStartTime = nowDate.getTime(),  // 今天凌晨12点
                showButtonPanel = this.isOpenDay(nowDate)[0], // 今天若屏蔽了也不能显示“今天”按钮
                unSelectTimeClone = dateSetting.od.unSelectDay
                    ? dateSetting.od.unSelectDay.concat([]).sort(function (a, b) {
                          return a - b;
                      })
                    : []; // 复制数组并在新数组中从小到大排序

            let unSelectTimeList = [];
            if (dateSetting.ot.t == 1) {
                unSelectTimeList = dateSetting.ot.unSelectTime.slice();
            }

            let minHour = 0;
            let today = dateSetting.banPassDate == 1 ? nowDate : new Date(1900, 0, 1); // 产经改需求说不屏蔽今天之前的时间了，但是逻辑已经写好， 这里先用1900代替，便于以后拓展

            // 如果在ban的列表没有找到，代表这是最小可以显示的时间
            for (var i = 0; i < 24; i++) {
                if ($.inArray(i, unSelectTimeList) == -1) {
                    minHour = i;
                    break;
                }
            }

            $el.datetimepicker('destroy').datetimepicker({
                dateFormat: 'yy-mm-dd',
                changeYear: true,
                changeMonth: true,
                showButtonPanel,
                yearRange,
                zIndex: 9035,
                hour: minHour,
                controlType: 'fkControl',
                showMinute: false,
                showTime: false,
                hourText: '',
                hourAccuracy: true, // 精确到时，分秒毫秒等都是0
                minDate: today, // 今天之前的日期不可选
                showTimepicker, // 是否有“时”选择 且是 具体日期型的则有“时”选择
                autoChangeMonthYear: false, // 切换年月时不自动改变当前选择的时间,主要是覆盖timepicker默认的onChangeMonthYear
                currentText: window._store.state.lcid == '2052' ? '今天' : 'Now',
                beforeShow($el, inst) {
                    // 标记是哪里的日期控件
                    inst.dpDiv.addClass('fk-siteFormDatePicker fk-moduleFormDatePicker');
                    // 选择结束日期，且选了开始日期后，再次点击开始日期，日期范围不受结束日期影响
                    if (!isEnd && inst.startDateNotLimit) {
                        inst.settings.minDate = today;
                        inst.settings.maxDate = null;
                        inst.startDateNotLimit = false;
                    }
                },
                afterShow($el, inst) {
                    var starTime;
                    if (isEnd) {
                        starTime = new Date($startTime.val());
                        if (starTime.getTime()) {
                            $.datepicker._selectToMonthYearDate(
                                '#' + $el.id,
                                inst,
                                starTime.getMonth(),
                                starTime.getFullYear(),
                                starTime.getDate()
                            );
                        }
                    }
                },
                onAfterUpdatePicker(inst) {
                    // 注意：在这里不能用dateTimePicker("option")的方式修改picker的设置，否则会跳入无限循环
                    var todayTd = inst.dpDiv.find('.ui-datepicker-today'),
                        today = todayTd.find('.ui-state-default'),
                        hasSelectTime = inst.currentYear != 0 || inst.currentMonth != 0 || inst.currentDay != 0;

                    // 当没有选择日期时才高亮当天
                    if (today.hasClass('ui-state-hover') && !hasSelectTime) {
                        today.addClass('fk-todayDefaultStyle');
                    } else {
                        inst.dpDiv.find('.ui-state-hover').removeClass('ui-state-hover'); // 刚刷新了面板 没有Hover,应该把ui-state-hover删掉
                    }

                    //inst.dpDiv.css("z-index","9029");
                },
                beforeShowTime(index) {
                    // 哪些时间不可选
                    if (unSelectTimeList.length > 0 && $.inArray(index, unSelectTimeList) > -1) {
                        return false;
                    }
                    return true;
                },
                beforeShowDay: (date) => {
                    return this.isOpenDay(date);
                },
                onSelect: (dateStr, inst) => {
                    var date = dateStr.replace(/-/g, '/'), // 由2017-08-29 转换成 2017/08/29 ,因为IE678不识别“-”的格式
                        selDate = new Date(date),
                        dateTime = new Date(selDate.getFullYear(), selDate.getMonth(), selDate.getDate()).getTime(), // 当天00:00的时间
                        option = {
                            minDate: today,
                            maxDate: null,
                            currentText: window._store.state.lcid == '2052' ? '今天' : 'Now',
                        },
                        eageIdx;

                    if (!isEnd) {
                        // 结束日期最远能选择到(一年内)最近的一个不可选日期前一天
                        if (dateSetting.banAll == 1 || dateSetting.banHoliday == 1 || unSelectTimeClone.length > 0) {
                            for (i = 0; i < 365; i++) {
                                let nextDay = new Date(dateTime + i * 86400000);
                                if (!this.isOpenDay(nextDay)[0]) {
                                    option.maxDate = nextDay;
                                    break;
                                }
                            }
                        }

                        inst.startDateNotLimit = true;
                        option.minDate = new Date(date);
                        $endTime.datetimepicker('option', option); // 联动：结束日期控件不显示开始日期前的日期

                        this.$emit(
                            'input',
                            Object.assign({}, this.value, {
                                startTime: $el.val(),
                            })
                        );
                    } else if (isEnd) {
                        // 开始日期最远能选择到最近的一个不可选日期后一天
                        for (var l = unSelectTimeClone.length, i = l; i >= 0; i--) {
                            if (dateTime >= unSelectTimeClone[i]) {
                                eageIdx = i;
                                break;
                            }
                        }

                        if (typeof eageIdx == 'number' && dateSetting.od.t == 1) {
                            option.minDate =
                                today.getTime() < unSelectTimeClone[eageIdx]
                                    ? new Date(unSelectTimeClone[eageIdx])
                                    : today;
                        }

                        inst.startDateNotLimit = false;
                        option.maxDate = new Date(date);
                        $startTime.datetimepicker('option', option);

                        this.$emit(
                            'input',
                            Object.assign({}, this.value, {
                                endTime: $el.val(),
                            })
                        );
                    }

                    if (!showTimepicker) {
                        inst.inline = false; // 精确到日期时 选择日期后让面板消失
                    }
                },
                onAfterSelect(_, inst) {
                    // 选择完开始日期 立马弹出 结束日期
                    if (!inst.inline && !isEnd) {
                        $endTime.focus();
                    }
                },
            });

            if (isEnd) {
                // 点击结束日期 先弹出开始日期 选择开始日期后 再自动弹出结束日期
                $endTime.off('click.openStart').on('click.openStart', function () {
                    $startTime.focus();
                });
            }
        },
    },
};
