import { Module } from '@shared/visitor/module/index.js';

//轮播多图模块
const CarouselPhotos = class CarouselPhotos extends Module {
    constructor(...args) {
        super(...args);
        this._selectVal = 0;
        this._prevSelect = this._selectVal;
        this.init();
    }

    get photoCount() {
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                return parseInt(this.$el.find('.module_content_detail').attr('data-photo-count'));
            } else {
                return this.conf.photoCount;
            }
        } else {
            return parseInt(this.$el.find('.module_content_detail').attr('data-photo-count'));
        }
    }

    get select() {
        return this._selectVal;
    }

    get moduleStyle() {
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                //if(_state.device === "mobi") return this.conf.prop2;
                return this.conf.prop0;
            } else {
                return this.conf.style;
            }
        } else {
            return this.conf.prop0;
        }
    }

    set select(selectVal) {
        if (selectVal < 0) selectVal = 0;
        if (selectVal + 1 > this.photoCount) selectVal = this.photoCount - 1;
        this._selectVal = selectVal;
    }

    get $photoList() {
        return this.$el.find('.photo_display_list');
    }

    init() {
        this.$el.off('click.prev').on('click.prev', '.prev', (e) => {
            e.stopPropagation();
            this.prev();
        });
        this.$el.off('click.next').on('click.next', '.next', (e) => {
            e.stopPropagation();
            this.next();
        });
        this.$el.off('click.select_photo').on('click.select_photo', '.select_photo', (e) => {
            e.stopPropagation();
            this.selectPhoto($(e.currentTarget).index());
        });

        this.$el.off('swipeleft.l').on('swipeleft.l', '.photo_display_area', () => {
            //左滑
            this.next();
        });
        this.$el.off('swiperight.r').on('swiperight.r', '.photo_display_area', () => {
            //右滑
            this.prev();
        });

        this.$el.off('mouseover.mo').on('mouseover.mo', '.photo_display_list li', () => {
            this.mouseOnPhoto = true;
        });
        this.$el.off('mouseleave.ml').on('mouseleave.ml', '.photo_display_list li', () => {
            this.mouseOnPhoto = false;
        });

        this.autoPlay();
        this.update();
    }

    update() {
        if (this.moduleStyle === 0 || this.moduleStyle === 1 || this.moduleStyle === 2) {
            //前三种样式
            this.$photoList.stop(true, true).css({
                left: 0,
            });
            this.$el
                .find('.photo_display_list li')
                .hide()
                .eq(this.select + 1)
                .show();
        } else if (this.moduleStyle === 3) {
            //三图样式
            //this.select = Math.ceil(this.photoCount / 2) - 1;
            //this._prevSelect = this.select;
            this.$photoList.css({
                left: -((this.select + 2) * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
            });
        }

        // 只有一张图片时隐藏缩略图，小箭头等指示器
        var selectors = [
            '.jz_scroll_wrap',
            '.prev, .next',
            '.dot_list',
            '.prev, .next, .photo_display_list li:nth-child(2), .photo_display_list li:nth-child(4)',
        ];
        var $indicators = $(this.$el).find(selectors[this.moduleStyle]);
        // moduleStyle为3时会隐藏图片，切换到别的样式时要重新把图片显示出来
        $(this.$el).find(selectors[3]).css('visibility', 'visible');

        if (this.photoCount <= 1) {
            if (this.moduleStyle === 3) {
                $indicators.css('visibility', 'hidden');
            } else {
                $indicators.hide();
            }
        } else {
            if (this.moduleStyle === 3) {
                $indicators.css('visibility', 'visible');
            } else {
                $indicators.show();
            }
        }

        this.updateSelectPhotoItem();
    }

    updateSelectPhotoItem() {
        this.$el.find('.select_photo').removeClass('active').eq(this.select).addClass('active');
    }

    prev() {
        if (this.photoCount <= 1) return;
        if (this.select - 1 < 0) {
            this.selectPhoto(this.photoCount - 1, true);
        } else {
            this.selectPhoto(this.select - 1);
        }
        this.resetAutoPlay();
    }

    next() {
        if (this.pauseAutoPlay) {
            return;
        }
        if (this.photoCount <= 1) return;
        if (this.select + 1 > this.photoCount - 1) {
            this.selectPhoto(0, true);
        } else {
            this.selectPhoto(this.select + 1);
        }
        this.resetAutoPlay();
    }

    selectPhoto(...args) {
        if (this.moduleStyle !== 3) {
            this._selectPhotoBasicStyle(...args);
        } else {
            this._selectPhotoThreePhotoStyle(...args);
        }
        this.updateSelectPhotoItem();
    }

    _selectPhotoBasicStyle(index, manual) {
        if (this.select === index) return;

        const $photoList = this.$photoList;
        const $photoListChild = $photoList.children('li');
        if ($photoList.is(':animated')) return;

        this._prevSelect = this.select;
        this.select = index;

        {
            let $oldPhotoItem = $photoListChild.eq(this._prevSelect + 1);
            let $newPhotoItem = $photoListChild.eq(this.select + 1);
            const toFirst = this.select === 0 && manual; //滚到第一个
            const toLast = this.select === this.photoCount - 1 && manual; //滚到最后一个
            $photoListChild.hide();
            $oldPhotoItem.show();
            $newPhotoItem.show();
            if ((this._prevSelect < this.select || toFirst) && !toLast) {
                if (toFirst) {
                    //拿到展示列表最后一项,即第一项的克隆项
                    $newPhotoItem.hide();
                    $newPhotoItem = $photoListChild.eq(-1);
                    $newPhotoItem.show();
                }
                //往右边
                $photoList.stop(true, true).animate(
                    {
                        left: '-100%',
                    },
                    this.switchTime,
                    () => {
                        $oldPhotoItem.hide();
                        $photoList.css('left', '');
                    }
                );
            } else {
                if (toLast) {
                    //拿到展示列表第一项,即最后一项的克隆项
                    $newPhotoItem.hide();
                    $newPhotoItem = $photoListChild.eq(0);
                    $newPhotoItem.show();
                }
                //往左边
                $photoList
                    .css({
                        left: '-100%',
                    })
                    .stop(true, true)
                    .animate(
                        {
                            left: 0,
                        },
                        this.switchTime
                    );
            }
        }

        if (this.moduleStyle === 0) {
            //下面的列表
            const $photo = this.$el.find('.photo_list_area').children().eq(this.select),
                $list = $photo.parent(),
                photo_pos = $photo.position().left,
                photo_width = $photo.outerWidth(true),
                list_width = $list.outerWidth(true);

            if (photo_pos >= list_width - photo_width * 2) {
                //右边元素超出可视区
                $list.animate(
                    {
                        scrollLeft: (index + 2) * photo_width - list_width - 18, //18像素为每一个元素左右的间距,跟css关联
                    },
                    this.switchTime
                );
            } else if (photo_pos < photo_width) {
                //左边元素超出可视区
                $list.animate(
                    {
                        scrollLeft: $list.scrollLeft() + photo_pos - photo_width + 9, //9像素为第一个元素左边少去的边距,跟css关联
                    },
                    this.switchTime
                );
            }
        }
    }

    _selectPhotoThreePhotoStyle(index) {
        const $photoList = this.$photoList;
        if (this.$photoList.is(':animated')) return;

        this._prevSelect = this.select;
        this.select = index;

        const toFirst = this.select === 0 && this._prevSelect === this.photoCount - 1; //从最后一个滚到第一个
        const toLast = this.select === this.photoCount - 1 && this._prevSelect === 0; //从第一个滚到最后一个

        if (!toFirst && !toLast) {
            $photoList.stop(true, true).animate(
                {
                    left: -((this.select + 2) * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
                },
                this.switchTime
            );
        } else {
            if (toLast) {
                $photoList.stop(true, true).animate(
                    {
                        left: -(1 * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
                    },
                    this.switchTime,
                    () => {
                        $photoList.css({
                            left: -((this.photoCount + 1) * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
                        });
                    }
                );
            } else if (toFirst) {
                $photoList.stop(true, true).animate(
                    {
                        left: -((this.photoCount + 2) * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
                    },
                    this.switchTime,
                    () => {
                        $photoList.css({
                            left: -(2 * CarouselPhotos.THREE_ONE_PHOTO_OFFSET) + '%',
                        });
                    }
                );
            }
        }
    }

    get showTime() {
        if (this.isDefaultPlay) return 4000;
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                return this.conf.privatePattern.pc.pcst * 1000;
            } else {
                return this.conf.showTime * 1000;
            }
        } else {
            return this.conf.privatePattern.pc.pcst * 1000;
        }
    }

    get switchTime() {
        if (this.isDefaultPlay) return 800;
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                return this.conf.privatePattern.pc.pcswt * 1000;
            } else {
                return this.conf.switchTime * 1000;
            }
        } else {
            return this.conf.privatePattern.pc.pcswt * 1000;
        }
    }

    get isAutoPlay() {
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                return this.conf.privatePattern.pc.pct !== 2;
            } else {
                return this.conf.isAutoPlay;
            }
        } else {
            return this.conf.privatePattern.pc.pct !== 2;
        }
    }

    get isDefaultPlay() {
        if (this.conf.isSSR) {
            if (window._store.state.manageMode) {
                return this.conf.privatePattern.pc.pct === 0;
            } else {
                return this.conf.isDefaultPlay;
            }
        } else {
            return this.conf.privatePattern.pc.pct === 0;
        }
    }

    get pauseAutoPlay() {
        if (!!this.timerId && this.mouseOnPhoto) {
            return true;
        }
        return false;
    }

    autoPlay() {
        if (!this.isAutoPlay) {
            this.timerId && Fai.stopInterval(this.timerId);
            return;
        }
        this.timerId = `marquee-${this.moduleId}`;

        this.resetAutoPlay();
    }

    resetAutoPlay() {
        if (!this.timerId) return;
        Fai.stopInterval(this.timerId);
        Fai.addInterval(
            this.timerId,
            () => {
                this.next();
            },
            this.showTime
        );
        Fai.startInterval(this.timerId);
    }
};

CarouselPhotos.THREE_ONE_PHOTO_OFFSET = 84; //三图样式单图位移百分比，跟css图片大小关联

export { CarouselPhotos };
