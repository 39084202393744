import { isBrowserEnv } from '@jz/utils';

export const getState = (key = '') => {
    if (!isBrowserEnv()) {
        return undefined;
    }
    let keyArr = key?.split('/');
    let result = keyArr.length > 1 ? window?._store?.state : window?._store?.state?.[keyArr[0]];
    if (keyArr.length > 1) {
        for (const key of keyArr) {
            result = result?.[key];
        }
    }
    return result;
};
